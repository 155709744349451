import Header from "../navigation/header";
import {
  StyleSheet,
  TouchableOpacity,
  TextInput as TextInputRn,
  Modal,
  Button,
  ActivityIndicator,
  Switch,
  ScrollView,
  FlatList,
  Dimensions,
} from "react-native";
import { TextInput } from "react-native-paper";

import { useFocusEffect } from "@react-navigation/native";
import IconEdit from "react-native-vector-icons/FontAwesome";
import IconCommon from "react-native-vector-icons/Entypo";
import { Text, View } from "../../../components/Themed";
import { RootStackScreenProps } from "../../../types"; // @ts-ignore
import Icon from "react-native-vector-icons/AntDesign";
import React, { useEffect, useState, useMemo, useRef } from "react";
import IconCross from "react-native-vector-icons/Entypo";
import { baseUrl, postData } from "../../../networking/api";
// import { DataTable, Provider as PaperProvider } from "react-native-paper";
import DataTable from "react-data-table-component";
import SuccessPopup from "../../../components/SuccessPopup";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

export default function UserType() {
  var [counter, setCounter] = useState(1);
  const [searchData, setSearch] = useState("");
  const [filterData, setFilteredDataSource] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const [UserTypeNameData, setUserTypeNameData] = useState([]);
  const [UserTypeByName, setUserTypeByName] = useState("");
  const [UserTypeId, setUserTypeId] = useState(false);
  const [UserTypeLength, setUserTypeLength] = useState(0);
  const [sort, setsort] = useState(0);
  const [editpopup, setEditPopup] = useState(false);
  const [popid, setpopid] = useState("");
  const [popindex, setpopindex] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [isEdit, setIsEdit] = useState(false);
  var alpha_pattern = new RegExp(/^[a-zA-Z]+(\s[a-zA-Z]+)?$/);
  const [issuccessVisible, setissuccessVisible] = useState(false);
  const [responsestatus, setresponsestatus] = useState("");
  const [PopupMessage, setPopupMessage] = useState("");

  // Datatable document column and style

  const customStyles = {
    rows: {
      style: {
        // maxWidth: 50,
        minHeight: "30px", // override the row height
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        backgroundColor: "#319ef0a3",
        minHeight: "40px",
        fontSize: 16,
        fontWeight: "600",
      },
    },
    headCells: {
      style: {
        // paddingLeft: '8px', // override the cell padding for head cells
        // paddingRight: '8px',
        justifyContent: "center",
        minHeight: "20px",

        // borderRightWidth: 2,
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          // fontSize: 16,
          // fontWeight: '600',
          // borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
        justifyContent: "center",

        "&:not(:last-of-type)": {
          textTransform: "Capitalize",

          borderRightStyle: "solid",
          borderRightWidth: "1px",
          fontSize: 16,
          fontWeight: "400",
          // borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };
  const caseInsensitiveSort = (rowA, rowB) => {
    const a = rowA.user_type.toLowerCase();
    const b = rowB.user_type.toLowerCase();

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };
  const columns = [
    {
      name: "Sr.No",
      selector: (row) => row.index,
      //cell: (row, index) => index + 1,

      // sortable: true,
      width: "100px",
    },
    {
      name: "User Type",
      selector: (row) => row.user_type,
      sortable: true,
      sortFunction: caseInsensitiveSort,
    },
    {
      name: "Action",
      // selector: "country",
      width: "100px",

      // sortable: true
      cell: (item) => (
        <TouchableOpacity onPress={() => isEditable(item.id, item.user_type)}>
          <IconEdit
            name="pencil"
            size={13}
            color="#fff"
            style={{
              padding: 6,
              backgroundColor: "#08588E",
              borderRadius: 12,
            }}
          />
        </TouchableOpacity>
      ),
    },
    {
      name: "Status",
      width: "100px",

      cell: (item, index) => (
        <Switch
          trackColor={{ false: "#767577", true: "#1DA1F2" }}
          thumbColor={item.active == 1 ? "#fff" : "#f4f3f4"}
          activeThumbColor="#fff"
          onValueChange={() => {
            // console.log("toggle index", index);
            setEditPopup(true);
            setpopid(item.id);
            setpopindex(index);
          }}
          value={item.active == 1 ? true : false}
        />
      ),
    },
  ];
  const toggleSwitch = () => {
    var tempIndex = UserTypeNameData.findIndex((item) => item.id === popid);

    if (UserTypeNameData[tempIndex].active == 1) {
      UserTypeNameData[tempIndex].active = 0;
      activeUserType(popid, 0);
    } else {
      UserTypeNameData[tempIndex].active = 1;
      activeUserType(popid, 1);
    }
    setUserTypeNameData(UserTypeNameData);
  };
  const [error, setError] = useState("");
  const textUserType = useRef("");

  //Pagination

  // const optionsPerPage = [10, 25, 50, 100];
  const optionsPerPage = [10, 25, 50, 100];

  const [page, setPage] = React.useState(0);
  const [itemsPerPage, setItemsPerPage] = React.useState(optionsPerPage[0]);
  const from = page * itemsPerPage;
  const to = Math.min((page + 1) * itemsPerPage, UserTypeLength);
  React.useEffect(() => {
    setPage(0);
    setpopindex("");
    setEditPopup(false);
    setpopid("");
  }, [itemsPerPage]);

  useFocusEffect(
    React.useCallback(() => {
      getUserTypeList([]);
      searchFilterFunction("");
    }, [])
  );
  const textToUserType = (text) => {
    textUserType.current = text.nativeEvent.text;
    // setUserTypeByName(text.nativeEvent.text);
  };

  const showUserType = () => {
    return textUserType.current;
  };
  const searchFilterFunction = (text) => {
    // Check if searched text is not blank
    if (text) {
      // Inserted text is not blank
      // Filter the masterDataSource
      // Update FilteredDataSource
      const newData = UserTypeNameData.filter(function (item) {
        const itemData = item.user_type
          ? item.user_type.toUpperCase()
          : "".toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      // console.log("new data search---->", newData);
      setFilteredDataSource(newData);
      setSearch(text);
      setPage(0);
    } else {
      // Inserted text is blank
      // Update FilteredDataSource with masterDataSource
      setFilteredDataSource(UserTypeNameData);
      setSearch(text);
      setPage(0);
    }
  };
  const CheckEnteredData = async () => {
    var UserTypes = showUserType() !== "" ? showUserType() : UserTypeByName;

    //setUserType(UserTypes);
    if (UserTypes == "") {
      // //alert("pls enter");
      setError("Please enter UserType");
      // console.log("empty");
      // setIsVisible(false);
    } else if (alpha_pattern.test(UserTypes)) {
      {
        isEdit == true && createUserType(UserTypes);
        setIsVisible(false);
      }

      {
        isEdit == false && updateUserType(UserTypes);
        setIsVisible(false);
      }
    } else {
      // console.log("falseśśś");
      // //alert("only character:", UserTypes);
      setError("Please enter Character only");
      // setIsVisible(false);
    }

    // navigation.replace('DrawerNavigation');
  };

  //Usertype create API CALL
  const createUserType = async (UserTypes) => {
    // console.log("UserTypes", UserTypes);
    setIsLoading(true);
    try {
      var UserTypeData = JSON.stringify({
        module: "user_type",
        data: {
          user_type: UserTypes,
          active: 1,
        },
      });
      const UserTypeResponse = await postData(
        UserTypeData,
        "masters/masterCreateUpdate"
      );

      setIsLoading(false);

      var code = UserTypeResponse.code;
      if (code == 1) {
        setPopupMessage("Record Created Successfully");
        setissuccessVisible(true);
        setresponsestatus("success");
        setIsVisible(false);
        getUserTypeList([]);
        setUserTypeByName("");
        textUserType.current = "";

        // console.log("Added", UserTypeResponse.data);
      } else {
        setIsLoading(false);
        setissuccessVisible(true);
        setresponsestatus("failed");
        setPopupMessage(UserTypeResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };
  //isActive DocumentType
  const activeUserType = async (userTypeId, isActive) => {
    setIsLoading(true);
    try {
      var countryData = JSON.stringify({
        id: userTypeId,
        module: "user_type",
        data: {
          active: isActive,
        },
      });
      const activeUserTypeResponse = await postData(
        countryData,
        "masters/masterCreateUpdate"
      );

      setIsLoading(false);

      var code = activeUserTypeResponse.code;
      if (code == 1) {
        // //alert(activeUserTypeResponse.message);
        setIsVisible(false);
        getUserTypeList([]);
        // console.log("status changed", activeUserTypeResponse.data);
      } else {
        setIsLoading(false);
        // //alert(activeUserTypeResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };
  //Usertype Update API
  const updateUserType = async (UserTypeNameData) => {
    // console.log("UserTypeNameData", UserTypeNameData);
    setIsLoading(true);
    try {
      var UserTypeData = JSON.stringify({
        id: UserTypeId,
        module: "user_type",
        data: {
          user_type: UserTypeNameData,
          active: 1,
        },
      });
      // console.log("user_type:", UserTypeData);
      const UserTypeResponse = await postData(
        UserTypeData,
        "masters/masterCreateUpdate"
      );

      setIsLoading(false);

      var code = UserTypeResponse.code;
      if (code == 1) {
        setPopupMessage("Record Updated Successfully");
        setissuccessVisible(true);
        setresponsestatus("success");
        setIsVisible(false);
        getUserTypeList([]);
        // console.log("Updated UserTypeResponse", UserTypeResponse.data);
      } else {
        setIsLoading(false);
        setissuccessVisible(true);
        setresponsestatus("failed");
        setPopupMessage(UserTypeResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };
  // Usertype Get Api
  const getUserTypeList = async (sortdata: never[]) => {
    setIsLoading(true);

    if (sortdata.length > 0) {
      setUserTypeLength(sortdata.length);
      setUserTypeNameData(sortdata);
      setFilteredDataSource(sortdata);
    } else {
      try {
        var UserTypeData = JSON.stringify({
          module: "user_type",
          relation: [],
        });
        const UserTypeList = await postData(
          UserTypeData,
          "masters/getMasterList"
        );
        setIsLoading(false);
        var code = UserTypeList.code;
        if (code == 1) {
          // console.log("Userlist api====>", UserTypeList.data);
          setUserTypeLength(UserTypeList.data.length);
          setUserTypeNameData(UserTypeList.data);
          setFilteredDataSource(UserTypeList.data);
        } else {
          setIsLoading(false);
          //alert(UserTypeList.message);
        }
      } catch (error) {
        setIsLoading(false);
        // console.log(error);
      }
    }
  };

  const isCreatable = () => {
    setIsVisible(true);
    setIsEdit(true);
  };
  const isEditable = (docTypeId, user_type_name) => {
    setIsVisible(true);
    setIsEdit(false);
    // getUserTypeById(docTypeId);
    setUserTypeByName(user_type_name);
    // textUserType.current = user_type_name;
    setUserTypeId(docTypeId);
  };

  function UserTypeModal() {
    return (
      <>
        <Modal
          onRequestClose={() => setIsVisible(false)}
          transparent
          visible={isVisible}
        >
          <View
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",
              backgroundColor: "rgba(100,100,100, 0.5)",
            }}
          >
            <View style={isMobile ? {
            // alignItems: "center",
            backgroundColor: "white",
            borderColor: "#eee",
            borderRadius: 10,
            borderWidth: 1,
            // justifyContent: "center",
            height: "auto",
            margin: "auto",
            padding: 30,
            shadowOffset: {
              width: 0,
              height: 2,
            },
            shadowOpacity: 0.25,
            shadowRadius: 4,
            elevation: 5,
            // marginRight: 10,
            // marginRight: 1,
            width: "80%",
          } : [styles.containeralt]}>
              <TouchableOpacity
                style={{ alignSelf: "flex-end" }}
                onPress={() => setIsVisible(false)}
              >
                <IconCross
                  name="circle-with-cross"
                  size={20}
                  color="#000"
                  style={{ bottom: 5, top: 10 }}
                />
              </TouchableOpacity>
              <View style={{ width: "100%" }}>
                <Text style={{ fontWeight: "600" }}>User Type Name</Text>
                {isEdit == true && (
                  <TextInputRn
                    style={styles.input}
                    placeholder=""
                    autoFocus={true}
                    onChange={textToUserType}
                  />
                )}

                {isEdit == false && (
                  <TextInputRn
                    style={styles.input}
                    placeholder=""
                    value={UserTypeByName}
                    key="Edit"
                    autoFocus
                    onChangeText={(text) => {
                      setUserTypeByName(text);
                    }}
                  />
                )}
                <Text style={[styles.error_text]}>{error}</Text>
              </View>
              {isEdit == true && (
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                    marginHorizontal: 20,
                  }}
                >
                  <TouchableOpacity
                    style={styles.saveBtn}
                    onPress={() => CheckEnteredData()}
                  >
                    <Text style={styles.btnText}>Save</Text>
                  </TouchableOpacity>

                  <TouchableOpacity
                    style={styles.saveBtn}
                    onPress={() => {
                      setIsVisible(false), setError("");
                    }}
                  >
                    <Text style={styles.btnText}>Cancel</Text>
                  </TouchableOpacity>
                </View>
              )}
              {isEdit == false && (
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                    marginHorizontal: 20,
                  }}
                >
                  <TouchableOpacity
                    style={styles.saveBtn}
                    onPress={() => CheckEnteredData()}
                  >
                    <Text style={styles.btnText}>Update</Text>
                  </TouchableOpacity>

                  <TouchableOpacity
                    style={styles.saveBtn}
                    onPress={() => setIsVisible(false)}
                  >
                    <Text style={styles.btnText}>Cancel</Text>
                  </TouchableOpacity>
                </View>
              )}
              {/* </View>
            {isLoading ? (
              <ActivityIndicator
                //visibility of Overlay Loading Spinner
                visible={isLoading}
                //Text with the Spinner
                textContent={"Loading..."}
                //  //Text style of the Spinner Text
                //  textStyle={styles.spinnerTextStyle}
              />
            ) : (
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  marginHorizontal: 20,
                }}
              >
                <TouchableOpacity
                  style={styles.saveBtn}
                  onPress={() => CheckEnteredData()}
                >
                  <Text style={styles.btnText}>Save</Text>
                </TouchableOpacity>

                <TouchableOpacity
                  style={styles.saveBtn}
                  onPress={() => setIsVisible(false)}
                >
                  <Text style={styles.btnText}>Cancel</Text>
                </TouchableOpacity>
              </View>
            )} */}

              {/* <Gap />
          {isVisible ? <Modalception depth={depth + 1} /> : null}
          <Gap /> */}
            </View>
          </View>
        </Modal>
      </>
    );
  }
  const sortFun = () => {
    const sortData = filterData.sort((item, b) =>
      sort == 1
        ? item.user_type.localeCompare(b.user_type)
        : b.user_type.localeCompare(item.user_type)
    );
    getUserTypeList(sortData);
    setFilteredDataSource(sortData);
  };

  const downloadExcelReport = async () => {
    const filteredData = UserTypeNameData.map(({user_type,active }, index) => ({
      'Sr No': index + 1,
      'User Type': user_type,
      'Active': active == 1 ? 'Active': 'InActive',
    }));
    // const filteredData = UserTypeNameData.map(({ createdAt, updatedAt, id, created_by, updated_by, ...rest }) => rest);
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, {
      type: 'array',
      bookType: 'xlsx',
    });
    const excelData = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(excelData, 'user_type_master.xlsx');
  }
  const screenwidth = Dimensions.get('window').width
  const isMobile = screenwidth < 768;

  return (
    <View style={styles.container}>
      <UserTypeModal />
      <SuccessPopup
        visible={issuccessVisible}
        PopupMessage={PopupMessage}
        Responsestatus={responsestatus}
        onChange={() => setissuccessVisible(false)}
      />

      <Modal
        visible={editpopup}
        animationType={"slide"}
        transparent={true}
        onRequestClose={() => {
          setEditPopup(false);
        }}
      >
        <View style={isMobile ? {
            // alignItems: "center",
            backgroundColor: "white",
            borderColor: "#eee",
            borderRadius: 10,
            borderWidth: 1,
            // justifyContent: "center",
            height: "auto",
            margin: "auto",
            padding: 30,
            shadowOffset: {
              width: 0,
              height: 2,
            },
            shadowOpacity: 0.25,
            shadowRadius: 4,
            elevation: 5,
            // marginRight: 10,
            // marginRight: 1,
            width: "80%",
          } : [styles.contanerSwitch]}>
          <TouchableOpacity
            style={{ alignSelf: "flex-end" }}
            onPress={() => setEditPopup(false)}
          >
            <IconCross
              name="circle-with-cross"
              size={20}
              color="#000"
              style={{ bottom: 5, top: 0 }}
            />
          </TouchableOpacity>

          <Text style={[styles.tableHead, { marginBottom: 20 }]}>
            Do You Want Update Status
          </Text>
          <View
            style={{
              flexDirection: "row",
              paddingRight: 10,
              justifyContent: "space-between",
            }}
          >
            <TouchableOpacity
              onPress={() => {
                setEditPopup(false), toggleSwitch();
              }}
              style={styles.saveBtn}
            >
              <Text style={{ color: "white" }}>Yes</Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                setEditPopup(false);
              }}
              style={styles.saveBtn}
            >
              <Text style={{ color: "white" }}>No</Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>
      {/* <Header /> */}
      {/* <Text style={styles.title}>This screen is Profile screeen.</Text> */}
      <View style={isMobile ? { width: "100%", marginTop: 180 } : { flexDirection: "row", width: "100%", marginTop: "10%" }}>
        <View style={{ flex: 1, margin: 10 }}>
          <Text style={styles.lableText}>Master {">"} UserType</Text>
        </View>
        <View style={isMobile ? { alignItems: "center", marginTop: 20  , marginLeft:'-35%', width:"170%", flex:2 } : { alignItems: "center", flex: 2 }}>
          <TextInput
            style={isMobile ? {
              height: 35,
              borderWidth: 2,
              paddingLeft: 10,
              borderColor: "#319ef0",
              backgroundColor: "#FFFFFF",
              width: "50%",
              borderRadius: 5,
              color: "#000",
              fontWeight: "400",
            } : styles.textInputStyle}
            onChangeText={(text) => searchFilterFunction(text)}
            value={searchData}
            underlineColor={"white"}
            underlineColorAndroid="transparent"
            placeholder="Search User type"
            theme={{
              colors: {
                text: "black",
                primary: "white",
                background: "transparent",
              },
            }}
            left={
              <TextInput.Icon
                name={() => <IconEdit name="search" size={20} color="#000" />} // where <Icon /> is any component from vector-icons or anything else
              />
            }
          />
        </View>
        <View style={{ flex: 1, alignItems: "flex-end" }}>
          <TouchableOpacity
            style={isMobile ? {
              alignItems: "center",
              backgroundColor: "#006EB9",
              padding: 10,
              borderWidth: 1,
              borderColor: "#006EB9",
              width: "50%",
              // marginTop: 20,
            } : styles.addDocTypebtn}
            onPress={() => {
              isCreatable(), setError("");
            }}
          >
            <Text style={styles.btnText}>
              <Icon name="plus" size={15} color="#fff" /> Add User Type
            </Text>
          </TouchableOpacity>
        </View>
      </View>
      <View style={isMobile ? { width: '100%', alignItems: "flex-end", marginTop:"-10%", marginLeft:"-120%" } : { width: '100%', alignItems: "flex-end" }}>
        <TouchableOpacity
          style={isMobile ? [styles.addCountry, { width: '45%',marginRight:-10 }] : [styles.addCountry, { width: '10%' }]}
          onPress={() => {
            downloadExcelReport();
          }}
        >
          <Text style={styles.btnText}>
            <Icon name="exclefile1" size={15} color="#fff" /> Download
          </Text>
        </TouchableOpacity>
      </View>
      {/* <ScrollView
        style={[styles.card, styles.shadowProp]}
        contentContainerStyle={{ flexGrow: 1 }}
      > */}
      <View style={isMobile ? [styles.card, { flexGrow: 1, marginTop:50, paddingBottom:10 }] : [styles.card, { flexGrow: 1 }]}>
        <View
          style={[
            styles.shadowProp,
            {
              backgroundColor: "white",
              borderRadius: 8,
              paddingVertical: 0,
              paddingHorizontal: 0,
            },
          ]}
        >
          <View
            style={{
              width: "100%",
              // flex: 1,
              backgroundColor: "#F6F9F9",
              borderRadius: 8,
              // paddingVertical: 45,
              // paddingHorizontal: 100,
            }}
          >
            <View
              style={isMobile ? {
                borderWidth: 1,
                borderColor: "#e3e0e0",
                borderRadius: 2,
                width: "190%",
                alignSelf: "center",
              } : {
                borderWidth: 1,
                borderColor: "#e3e0e0",
                borderRadius: 2,
                width: "100%",
                alignSelf: "center",
              }}
            >
              <View>
                <DataTable
                  // title="Country"
                  columns={columns}
                  data={filterData}
                  customStyles={customStyles}
                  pagination
                />
              </View>
              {/* prvider datatable code here */}

              {/* ended */}
            </View>
          </View>
          {/* </ScrollView> */}
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    // marginTop: 300,
  },
  textInputStyle: {
    height: 35,
    borderWidth: 2,
    paddingLeft: 10,
    borderRadius: 5,
    opacity: 2,
    borderColor: "#319ef0",
    backgroundColor: "#FFFFFF",
    width: "40%",
    borderRadius: 5,
    color: "#000",
    fontWeight: "400",
  },
  containeralt: {
    // alignItems: "center",
    backgroundColor: "white",
    borderColor: "#eee",
    borderRadius: 10,
    borderWidth: 1,
    // justifyContent: "center",
    height: "auto",
    margin: "auto",
    padding: 30,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    // marginRight: 10,
    // marginRight: 1,
    width: "30%",
  },
  contanerSwitch: {
    backgroundColor: "white",
    borderColor: "#eee",
    borderRadius: 5,
    alignSelf: "center",
    borderWidth: 1,
    // justifyContent: "center",
    height: "25%",
    margin: "auto",
    padding: 10,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    // marginRight: 10,
    // marginRight: 1,
    width: "30%",
  },
  tableHeader: {
    color: "#000",
    fontSize: 14,
    /* FontFamily: 'Muli' ,*/
    fontWeight: "400",
  },
  tableHead: {
    fontSize: 14,
    /* FontFamily: 'Muli' ,*/
    fontWeight: "700",
    color: "#000000",
  },
  input: {
    borderColor: "gray",
    width: "100%",
    borderWidth: 1,
    borderRadius: 2,

    padding: 10,
    marginTop: 20,
    marginBottom: 5,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  addDocTypebtn: {
    alignItems: "center",
    backgroundColor: "#006EB9",
    padding: 10,
    borderWidth: 1,
    borderColor: "#006EB9",
    width: "60%",
    // marginTop: 20,
  },
  saveBtn: {
    alignItems: "center",
    backgroundColor: "#006EB9",
    padding: 10,
    borderWidth: 1,
    borderColor: "#006EB9",
    width: "50%",
    marginRight: 10,
  },
  error_text: {
    color: "red",
    fontSize: 14,
    fontWeight: "600",
    width: "70%",
    marginLeft: 0,
    marginBottom: 20,
  },
  lableText: {
    color: "#484848",
    fontWeight: "700",
    marginTop: 18,
    // fontSize: 21,
  },
  btnText: {
    color: "#fff",
    fontWeight: "600",
  },
  card: {
    width: "50%",
    flex: 1,
    marginVertical: 10,
  },
  shadowProp: {
    shadowColor: "#171717",
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 15,
  },

  link: {
    marginTop: 15,
    paddingVertical: 15,
  },
  linkText: {
    fontSize: 14,
    color: "#2e78b7",
  },
  addCountry: {
    alignItems: "center",
    backgroundColor: "#006EB9",
    padding: 10,
    borderWidth: 1,
    borderColor: "#006EB9",
    width: "50%",
    // marginTop: 20,
  },
});
