import Header from "../navigation/header";
import {
    StyleSheet,
    TouchableOpacity,
    TextInput,
    Modal,
    ScrollView,
    Alert,
    ActivityIndicator,
    Switch,
    useWindowDimensions,
    Image,
} from "react-native";
import IconRefresh from "react-native-vector-icons/FontAwesome";
import IconEdit from "react-native-vector-icons/FontAwesome";

import DataTable from "react-data-table-component";

import { RadioButton, Button } from "react-native-paper";
import moment from "moment";
import { TabView, SceneMap, TabBar } from "react-native-tab-view";

import { CheckBox } from "react-native-elements";
import AsyncStorage from "@react-native-async-storage/async-storage";

import { DatePickerModal } from "react-native-paper-dates";
import { Dropdown } from "react-native-element-dropdown";

import { Text, View } from "../../../components/Themed";

import Icon from "react-native-vector-icons/AntDesign";
import React, { useState, useRef, useEffect } from "react";
import IconCross from "react-native-vector-icons/Entypo";
import { baseUrl, baseUrlimage, postData } from "../../../networking/api";
import { useFocusEffect } from "@react-navigation/native";

export default function ViewProjectProfile({ navigation, route }) {
    const { Projects_Id } = route?.params;
    // console.log("project id- View screen", Projects_Id);

    const [isVisible, setIsVisible] = useState(false);
    const [isEnabled, setIsEnabled] = useState(false);
    const [animating, setAnimating] = useState(true);
    const [isAdd, setisAdd] = useState(false);
    const [ProjectNameData, setProjectNameData] = useState([]);
    const [filterData, setFilteredDataSource] = useState([]);


    const [Lat, setLatitude] = useState("");
    const [Long, setLongitude] = useState("");

    const [isLoading, setIsLoading] = useState(false);
    const [projectProfile, setProjectProfile] = useState(null)

    const [ProjectID, setProjectID] = useState("");
    const [isProjectIDValid, setProjectIDValid] = useState(true);
    const [isProjectIDEntered, setProjectIDEntered] = useState(false);

    const [ProjectName, setProjectName] = useState("");
    const [isProjectNameValid, setProjectNameValid] = useState(true);
    const [isProjectNameEntered, setProjectNameEntered] = useState(false);

    const [ContractorName, setContractorName] = useState("");
    const [isContractorNameValid, setContractorNameValid] = useState(true);
    const [isContractorNameEntered, setContractorNameEntered] = useState(false);

    const [ClientName, setClientName] = useState("");
    const [isClientNameValid, setClientNameValid] = useState(true);
    const [isClientNameEntered, setClientNameEntered] = useState(false);

    const [ProjectDetails, setProjectDetails] = useState("");
    const [isProjectDetailsValid, setProjectDetailsValid] = useState(true);
    const [isProjectDetailsEntered, setProjectDetailsEntered] = useState(false);

    const [OmegaIdData, setOmegaIdData] = useState([]);
    const [OmegaDetails, setOmegaDetails] = useState([]);
    const [AnalogInput, setAnalogInput] = useState([]);
    const [DigitalInput, setDigitalInput] = useState([]);

    const [OmegavalueId, setOmegaValueiD] = useState("");
    const [shouldShow, setShouldShow] = useState(true);

    const [ProjectTypeData, setProjectTypeData] = useState([]);
    const [ProjectTypevalueId, setProjectTypeValueiD] = useState();


    const [isFocus, setIsFocus] = useState(false);
    const [editInput, setEditInput] = useState(false);


    // Master Id's and names
    const [countryValue, setCountryValue] = useState("");
    const [countryValId, setCountryValId] = useState("");
    const [stateValue, setStateValue] = useState("");
    const [stateValId, setStateValId] = useState("");
    const [cityValue, setCityValue] = useState("");
    const [cityValId, setCityValId] = useState("");
    const [tahasilValue, setTahasilValue] = useState("");
    const [tahasilValId, setTahasilValId] = useState("");
    const [VillageValue, setVillageValue] = useState("");
    const [VillageValId, setVillageValId] = useState("");
    const [KhasaraValue, setKhasaraValue] = useState("");
    const [KhasaraValId, setKhasaraValId] = useState("");

    //Dropdown value pickup
    const [OmegaIdValue, setOmegaIdValue] = useState(null);
    const [ProjectTypeValue, setProjectTypeValue] = useState(null);


    //Error message
    const [ProjectIdError, setProjectIDError] = useState("");

    const [ProjectNameError, setProjectNameError] = useState("");
    const [ClientnameError, setClientnameError] = useState("");
    const [ProjectDetailsError, setProjectDetailsError] = useState("");
    const [ProjectTypeError, setProjectTypeError] = useState("");

    const [ContractorError, setContractorError] = useState("");



    const [pinError, setPinError] = useState("");
    const [khasaraError, setkhasaraError] = useState("");
    const [OmegaError, setOmegaError] = useState("");


    const [qualification_Error, setQualificationError] = useState("");
    //Dropdown 

    const dropdownRefOmegaiD = useRef();
    const dropdownRefProjectType = useRef();


    const closeMenu = () => {

        dropdownRefOmegaiD.current.close();
        dropdownRefProjectType.current.close();
        //return calls;
        // // console.log("on scroll close in dropdown")
    };

    useEffect(() => {
        window.addEventListener("scroll", closeMenu);
        return () => window.removeEventListener("scroll", closeMenu);
    }, [])

    const radioButtonsData = [
        {
            id: "1",
            label: "Male",
            value: 1,
            color: "red",
            selected: true,
        },
        {
            id: "2",
            label: "Female",
            value: 2,
            color: "red",
            selected: false,
        },
    ];

    const toggleSwitch = () => setIsEnabled((previousState) => !previousState);

    //DOb
    const [date, setDate] = React.useState<Date | undefined>(undefined);
    const [open, setOpen] = React.useState(false);

    const onDismissSingle = React.useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const onConfirmSingle = React.useCallback(
        (params) => {
            setOpen(false);
            setDate(params.date);
        },
        [setOpen, setDate]
    );

    //DOJ
    const [joinDate, setJoinDate] = React.useState<Date | undefined>(undefined);
    const [joinOpen, setJoinOpen] = React.useState(false);

    const onDismissJoin = React.useCallback(() => {
        setOpen(false);
    }, [setJoinOpen]);

    const onConfirmJoin = React.useCallback(
        (params) => {
            setJoinOpen(false);
            setJoinDate(params.date);
        },
        [setJoinOpen, setJoinDate]
    );
    //Regex
    var alpha_pattern = new RegExp(/^[a-zA-Z]+(\s[a-zA-Z]+)?$/);
    // var alpha_numeric = new RegExp(/^[a-zA-Z0-9]*$/);
    var alpha_numeric = new RegExp(/^[a-z0-9][a-z0-9 ]{1,48}[a-z0-9]$/i);


    var Email_Id_pattern = new RegExp(
        /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w\w+)+$/
    );
    var password_pattern = new RegExp(
        /^[a-zA-Z0-9]+$/
        // /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&^_-]{8,}$/
    );
    // var MobileNo_pattern = new RegExp(/^[0]?[789]\d{9}$/);
    // var number_regex = new RegExp(/^[0-9]*$/);
    const regex = /^[0-9]*$/;

    const customStyles = {

        rows: {
            style: {
                // maxWidth: 50,
                minHeight: '30px', // override the row height
            },
        },
        headRow: {
            style: {
                borderTopStyle: "solid",
                borderTopWidth: "1px",
                backgroundColor: "#319ef0a3",
                minHeight: '40px',
                fontSize: 16,
                fontWeight: '600',


            },
        },
        headCells: {
            style: {
                // paddingLeft: '8px', // override the cell padding for head cells
                // paddingRight: '8px',
                justifyContent: 'center',
                minHeight: '20px',

                // borderRightWidth: 2,
                '&:not(:last-of-type)': {
                    borderRightStyle: 'solid',
                    borderRightWidth: '1px',
                    // fontSize: 16,
                    // fontWeight: '600',
                    // borderRightColor: defaultThemes.default.divider.default,
                },

            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
                justifyContent: 'center',

                '&:not(:last-of-type)': {
                    borderRightStyle: 'solid',
                    borderRightWidth: '1px',
                    fontSize: 16,
                    fontWeight: '400',
                    // borderRightColor: defaultThemes.default.divider.default,
                },

            },
        },
    };
    const columns = [
        // {
        //   name: "Sr.No",
        //   selector: "id",
        //   sortable: true
        // },
        {
            name: "Output number",
            // selector: row => !editInput ? row.project_name : <TextInput value='helllo'></TextInput>,
            selector: row => row.value,
            sortable: true
        },
        {
            name: "Output Name",
            selector: row => row.name,
            sortable: true
        },
        // {
        //   name: "Area",
        //   selector: "area",
        //   sortable: true
        // },
        // {
        //   name: "Water Demand",
        //   selector: "water_demand",
        //   sortable: true
        // },


    ];
    const columnsAnalog = [
        // {
        //   name: "Sr.No",
        //   selector: "id",
        //   sortable: true
        // },
        {
            name: "Output number",
            // selector: row => !editInput ? row.project_name : <TextInput value='helllo'></TextInput>,
            selector: row => row.value,
            sortable: true
        },
        {
            name: "Output Name",
            selector: row => row.name,
            sortable: true
        },
        // {
        //   name: "Area",
        //   selector: "area",
        //   sortable: true
        // },
        // {
        //   name: "Water Demand",
        //   selector: "water_demand",
        //   sortable: true
        // },



    ];

    const columnDigitalInput = [
        // {
        //   name: "Sr.No",
        //   selector: "id",
        //   sortable: true
        // },
        {
            name: "Output number",
            // selector: row => !editInput ? row.project_name : <TextInput value='helllo'></TextInput>,
            selector: row => row.value,
            sortable: true
        },
        {
            name: "Output Name",
            selector: row => row.name,
            sortable: true
        },
        // {
        //   name: "Area",
        //   selector: "area",
        //   sortable: true
        // },
        // {
        //   name: "Water Demand",
        //   selector: "water_demand",
        //   sortable: true
        // },


    ];


    const DigitalOutputRoute = () => (
        <View style={{ flex: 1, justifyContent: 'flex-start', backgroundColor: "#F6F9F9" }}>
            {" "}
            <View
                style={[styles.card, { flexGrow: 1 }]}
            >
                <View style={[styles.shadowProp, {
                    backgroundColor: "white",
                }]}>
                    <View
                        style={{
                            width: "100%",
                            // flex: 1,
                            backgroundColor: "#F6F9F9",
                        }}
                    >


                        <View
                            style={{
                                borderWidth: 1,
                                borderColor: "#e3e0e0",
                            }}
                        >
                            <View>

                                <DataTable
                                    // title="Country"
                                    columns={columns}
                                    data={OmegaDetails}

                                    customStyles={customStyles}
                                    pagination

                                />

                            </View>
                        </View>
                    </View>
                    {/* </ScrollView> */}
                </View>
            </View>
        </View>
    );

    const AnalogInputRoute = () => (
        <View style={{ flex: 1, justifyContent: 'flex-start', backgroundColor: "#F6F9F9" }}>
            {" "}
            <View
                style={[styles.card, { flexGrow: 1 }]}
            >
                <View style={[styles.shadowProp, {
                    backgroundColor: "white",
                }]}>
                    <View
                        style={{
                            width: "100%",
                            // flex: 1,
                            backgroundColor: "#F6F9F9",
                        }}
                    >


                        <View
                            style={{
                                borderWidth: 1,
                                borderColor: "#e3e0e0",
                            }}
                        >
                            <View>

                                <DataTable
                                    // title="Country"
                                    columns={columnsAnalog}
                                    data={AnalogInput}

                                    customStyles={customStyles}
                                    pagination

                                />

                            </View>
                        </View>
                    </View>
                    {/* </ScrollView> */}
                </View>
            </View>
        </View>
    );
    const DigitalInputRoute = () => (
        <View style={{ flex: 1, justifyContent: 'flex-start', backgroundColor: "#F6F9F9" }}>
            {" "}
            <View
                style={[styles.card, { flexGrow: 1 }]}
            >
                <View style={[styles.shadowProp, {
                    backgroundColor: "white",
                }]}>
                    <View
                        style={{
                            width: "100%",
                            // flex: 1,
                            backgroundColor: "#F6F9F9",
                        }}
                    >


                        <View
                            style={{
                                borderWidth: 1,
                                borderColor: "#e3e0e0",
                            }}
                        >
                            <View>

                                <DataTable
                                    // title="Country"
                                    columns={columnDigitalInput}
                                    data={DigitalInput}

                                    customStyles={customStyles}
                                    pagination

                                />

                            </View>
                        </View>
                    </View>
                    {/* </ScrollView> */}
                </View>
            </View>
        </View>
    );

    const renderScene = SceneMap({
        DigitalOutput: DigitalOutputRoute,
        DigitalInput: DigitalInputRoute,
        AnalogInput: AnalogInputRoute,
    });
    const layout = useWindowDimensions();

    const [index, setIndex] = React.useState(0);
    const [routes] = React.useState([
        { key: "DigitalOutput", title: "Digital Output" },
        { key: "DigitalInput", title: "Digital Input" },
        { key: "AnalogInput", title: "Analog Input" },

    ]);

    useFocusEffect(
        React.useCallback(() => {
            getAdminData();

            // getOmegaIdList();
            getProjectProfileById();
            // getAllProjectData([]);
        }, [])
    );

    const getAdminData = async () => {
        let jsonValue = await AsyncStorage.getItem("userData");
        // console.log("jsonValue:", jsonValue);
        if (jsonValue != null) {
            var userDetailsresponse = JSON.parse(jsonValue);
            // console.log("userDetailsresponse==>", userDetailsresponse);

            // setloginId(userDetailsresponse.email_id);
            // setEmpCode(userDetailsresponse.employee_code);
        }
    };

    const renderOmegaIdListItems = (item: any) => {
        return (
            <View style={styles.item}>
                {isAdd == true && <Text style={styles.textItem}>{item.omega_id}</Text>}
                {isAdd == false && <Text style={styles.textItem}>{item.omega_id}</Text>}
                {item.id === OmegavalueId && (
                    <Icon style={styles.icon} color="black" name="Safety" size={20} />
                )}
            </View>
        );
    };
    const renderProjectTypeListItems = (item: any) => {
        return (
            <View style={styles.item}>
                {isAdd == true && <Text style={styles.textItem}>{item.type}</Text>}
                {isAdd == false && <Text style={styles.textItem}>{item.type}</Text>}
                {item.id === ProjectTypevalueId && (
                    <Icon style={styles.icon} color="black" name="Safety" size={20} />
                )}
            </View>
        );
    };


    const CheckEnteredData = async () => {
        setProjectNameEntered(true);

        setContractorNameEntered(true);
        setProjectDetailsEntered(true);
        setClientNameEntered(true);
        // console.log("check nenter data")

        //setOmegaId(OmegaIds);
        if (
            isProjectIDValid &&
            isProjectNameValid &&
            isContractorNameValid &&
            isClientNameValid &&
            isProjectDetailsValid


        ) {
            // console.log("success");
            updateProjectProfileData();
            setProjectIDError("");

            setProjectNameError("");
            setProjectTypeError("");
            setContractorError("");
            setClientnameError("");
            setProjectDetailsError("");

        } else {
            // console.log("error ELSE");
            if (ProjectIdError == "") {
                setProjectIDError("");
            }
            if (ProjectName == "") {
                setProjectNameError("Please enter first name");
            }
            //project type 
            if (ProjectTypevalueId == "") {
                setProjectTypeError("Please select Project Type");
            }

            //Contractor name
            if (ContractorName == "") {
                setContractorError("Please enter Contractor name ");
            }


            //omega id 
            if (OmegavalueId == "") {
                setOmegaError("Please select Omega Type");
            }

            //  //Pro id 
            //  if (OmegavalueId == "") {
            //   setOmegaError("Please select Omega Type");
            // }


            //client name 
            if (ClientName == "") {
                setClientnameError("Please select Client name");
            }

            //Project details 
            if (ProjectDetails == "") {
                setProjectDetailsError("Please select Project details");
            }

        }
    };



    //Get project profile  data by id
    const getProjectProfileById = async () => {
        // console.log("INSIDE VIEW OF PROJECT PROFILE")
        setIsLoading(true);
        try {
            var ProfileData = JSON.stringify({

                "module": "project",
                "relation": [
                    {
                        "module": "project_type"
                    },
                    {
                        "module": "project_devices",
                        "subModule": "devices_master"
                    }
                ],
                "id": Projects_Id

            });
            // console.log("ProfileData id", ProfileData)
            const ProjectProfileDataById = await postData(
                ProfileData,
                "/masters/getMasterList"
            );
            setIsLoading(false);
            var code = ProjectProfileDataById.code;
            if (code == 1) {
                //code by RohitB
                const projectData = ProjectProfileDataById.data[0];
                //console.log("Project Name:", projectData.project_name);
                setProjectName(projectData.project_name);
                //console.log("projectData.project_code",projectData.project_code);
                setProjectID(projectData.project_code);
                //console.log("projectData.contractor",projectData.contractor);
                setContractorName(projectData.contractor);
                // console.log("projectData.project_type_id",projectData.project_type.type); 
                // setProjectTypeValue(projectData.project_type_id);
                setProjectTypeValue(projectData.project_type.type);
                //console.log("Project Type:", projectData.project_type.type);
                //console.log("projectData.client",projectData.client);
                setClientName(projectData.client);
                console.log("projectData.project_details",projectData.project_details);
                setProjectDetails(projectData.project_details);
                // setLatitude(projectData.project_devices[0].devices_master.lat);
                // setLongitude(projectData.project_devices[0].devices_master.long);
                setOmegaIdValue(projectData.project_devices[0].device_code); 
                getOmegaDetails(projectData.project_devices[0].device_id);
                 console.log("Project profile data list api====>", ProjectProfileDataById.data);
                var profileUrl = baseUrlimage + 'uploads/project_profile/' + projectData.profile_pic;
                console.log("profileUrl", profileUrl);
                setProjectProfile(profileUrl);
               
                //console.log("projectData.project_devices[0].devices_master.id",projectData.project_devices[0].devices_master.id);
                //console.log("projectData.project_devices[0].devices_master.device_name",projectData.project_devices[0].devices_master.device_name);
                //setOmegaIdValue(projectData.project_devices[0].devices_master.device_name);  
                // console.log("Project profile data list api====>", ProjectProfileDataById.data);
                // var profileUrl = baseUrlimage + 'uploads/project_profile/' + ProjectProfileDataById.data[0].profile_pic;
                // // console.log("profileUrl", profileUrl);
                // setProjectProfile(profileUrl);
                // getOmegaDetails(ProjectProfileDataById.data[0].project_devices[0].devices_master.id);
                // setProjectID(ProjectProfileDataById.data[0].project_code)
                // setProjectName(ProjectProfileDataById.data[0].project_name);
                // // console.log("ProjectProfileDataById.data.project_name", ProjectProfileDataById.data[0].project_devices[0].devices_master.device_name)
                // setOmegaIdValue(ProjectProfileDataById.data[0].project_devices[0].devices_master.device_name);
                // setProjectTypeValue(ProjectProfileDataById.data[0].project_type_id);
                // setContractorName(ProjectProfileDataById.data[0].contractor);
                // setClientName(ProjectProfileDataById.data[0].client);
                // setProjectDetails(ProjectProfileDataById.data[0].project_details);
                // setLatitude(ProjectProfileDataById.data[0].project_devices[0].devices_master.lat);
                // setLongitude(ProjectProfileDataById.data[0].project_devices[0].devices_master.long);
            } else {
                setIsLoading(false);
                //alert(ProjectProfileDataById.message);
            }
        } catch (error) {
            setIsLoading(false);
            // console.log(error);
        }
    };
    //Get details when click on omega id
    const getOmegaDetails = async (deviceId) => {
        setIsLoading(true);
        try {
            var OmegaDetails = JSON.stringify({
                "device_id": deviceId,

            });
            const OmegaDetailsResponse = await postData(
                OmegaDetails,
                "masters/getOmegaDetails"
            );
            setIsLoading(false);
            var code = OmegaDetailsResponse.code;
            if (code == 1) {
                // console.log("Omega details api====>", OmegaDetailsResponse.data);
                // setShouldShow(true);
                setOmegaDetails(OmegaDetailsResponse.data[0].valves);
                setAnalogInput(OmegaDetailsResponse.data[0].analogInput);
                setDigitalInput(OmegaDetailsResponse.data[0].digitalInput);
            } else {
                setIsLoading(false);
                //alert(OmegaDetailsResponse.message);
            }
        } catch (error) {
            setIsLoading(false);
            // console.log(error);
        }
    };

    //Project Profile update Api
    const updateProjectProfileData = async () => {
        setIsLoading(true);
        try {
            var ProjectMasterData = JSON.stringify({
                module: "project",
                id: Projects_Id,
                data: {
                    project_type: ProjectTypevalueId,
                    project_name: ProjectName,
                    client: ClientName,
                    contractor: ContractorName,
                    omega_id: OmegaIdValue,
                    active: 1,
                },
            });
            // console.log("Project payload===", ProjectMasterData);
            const FaarmerProfileCreateResponse = await postData(
                ProjectMasterData,
                "masters/masterCreateUpdate"
            );

            setIsLoading(false);

            var code = FaarmerProfileCreateResponse.code;
            if (code == 1) {
                //alert(FaarmerProfileCreateResponse.message);
                // setIsVisible(false);
                // console.log("Project profile added", FaarmerProfileCreateResponse.data);
                navigation.navigate("ProjectProfile");
            } else {
                setIsLoading(false);
                //alert(FaarmerProfileCreateResponse.message);
            }
        } catch (error) {
            setIsLoading(false);
            // console.log(error);
        }
    };

    return (
        <View style={styles.container}>
            <View style={{ flexDirection: "row", width: "100%", marginTop: "5%" }}>        <View style={{ flex: 4, margin: 10 }}>
                <Text style={styles.lableText}>
                    Profile {">"} Project Profile {">"} View Project Profile
                </Text>
            </View>
                {/* <View style={{ flex: 1 }}>
          <TouchableOpacity
            style={styles.addCountry}
            onPress={() => setIsVisible(true)}
          >
            <Text style={styles.btnText}>
              <Icon name="plus" size={15} color="#fff" /> Add Users
            </Text>
          </TouchableOpacity>
        </View> */}
            </View>
            {/* <ScrollView
        style={[styles.card, styles.shadowProp]}
        contentContainerStyle={{ flexGrow: 1 }}
      > */}
            <View
                style={[styles.card, { flexGrow: 1 }]}
            >
                <View style={[styles.shadowProp, {
                    backgroundColor: "white",
                    borderRadius: 8,
                    paddingVertical: 0,
                    paddingHorizontal: 25,

                }]}>
                    <View
                        style={{
                            flex: 1,
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "row",
                        }}
                    >
                        <View style={{ flex: 1, marginLeft: '10%' }}>
                            <View
                                style={{
                                    // backgroundColor: "#F2F2F2",
                                    borderRadius: 10,
                                    padding: 10,
                                    margin: 7,


                                }}
                            >
                                <View style={{ flex: 1, alignItems: 'flex-start', padding: 10, }}>
                                    {/* <Button title='Pick an image from camera roll'   color="#000000" 
 onPress={pickImage} /> */}
                                    <Image
                                        source={projectProfile ? { uri: projectProfile } : require('../../../assets/images/projectImg.png')}
                                        style={{ width: 100, height: 100, borderWidth:1 }}
                                    />

                                </View>
                                <Text style={styles.lableText}>Project Id</Text>

                                {/* <View> */}
                                <Text style={[styles.input]}>{ProjectID}</Text>
                                {/* </View> */}

                                <Text style={styles.lableText}>Project Type</Text>
                                <View>

                                    <Text style={[styles.input]}>{ProjectTypeValue}</Text>

                                </View>

                                <Text style={styles.lableText}>Contractor Name</Text>
                                <Text
                                    style={styles.input}>{ContractorName}</Text>

                                <Text style={styles.lableText}>Omega Id</Text>
                                <View style={{ flexDirection: 'row', }} >
                                    <View style={{ width: '100%' }}>
                                        <View>
                                            <Text style={[styles.input]}>{OmegaIdValue !== null ? OmegaIdValue : "-"}</Text>

                                        </View>
                                    </View>


                                </View>

                            </View>

                        </View>
                        <View
                            style={{
                                flex: 1,
                                marginTop: 53,
                                // backgroundColor: "#F2F2F2",
                                borderRadius: 10,
                                padding: 10,
                                margin: 7,
                                marginBottom: '0%'

                            }}
                        >

                            <View >
                                <Text style={styles.lableText}>Project Name</Text>

                                {/* <View> */}
                                <Text style={[styles.input]}>{ProjectName}</Text>
                                {/* </View> */}
                            </View>
                            <Text style={styles.lableText}>Client Name</Text>

                            {/* <View> */}
                            <Text style={[styles.input]}>{ClientName}</Text>
                            {/* </View> */}
                            <Text style={styles.lableText}>Project Details</Text>

                            {/* <View> */}
                            <Text style={[styles.input]}>{ProjectDetails}</Text>


                            {/* <View>
                                <Text style={styles.lableText}>Location </Text>
                                <View style={{ flexDirection: "row" }}>

                                    <Text
                                        style={styles.input}

                                    >{Lat}</Text>
                                    <Text
                                        style={styles.input}

                                    >{Long}</Text>
                                </View>


                            </View> */}
                        </View>
                    </View>



                    {/* <View style={{ marginTop: 20, width: '40%', alignSelf: 'center' }}>
                        <TabView
                            navigationState={{ index, routes }}
                            renderScene={renderScene}
                            onIndexChange={setIndex}
                            initialLayout={{ width: layout.width }}
                            // renderLabel={({ route, color }) => (
                            //   <Text style={{ color: 'black', margin: 8 }}>
                            //     {route.title}
                            //   </Text>
                            // )}
                            renderTabBar={props => <TabBar {...props}
                                indicatorStyle={{ backgroundColor: '#2c7bbe', height: 50 }}

                                renderLabel={({ route, color }) => (
                                    <Text style={{ color: 'black', margin: 8 }}>
                                        {route.title}
                                    </Text>
                                )}
                                style={{ backgroundColor: '#F6F9F9', }} />
                            } // <-- add this line

                        />
                    </View> */}
                    <View
                        style={{
                            flexDirection: "row",
                            justifyContent: "center",
                            marginHorizontal: 20,
                            marginBottom: 20
                        }}
                    >


                        <TouchableOpacity
                            style={styles.saveBtn}
                            onPress={() => navigation.navigate("ProjectProfile")}
                        >
                            <Text style={styles.btnText}>Cancel</Text>
                        </TouchableOpacity>
                    </View>

                    {/* </ScrollView> */}
                </View>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        padding: 20,
        // marginTop: 150,
    },
    genderNames: {
        margin: 7,
    },
    statusNames: {
        margin: 7,
    },
    containeralt: {
        // alignItems: "center",
        backgroundColor: "white",
        borderColor: "#eee",
        borderRadius: 10,
        borderWidth: 1,
        // justifyContent: "center",
        height: "100%",
        marginRight: "10%",
        marginTop: "0%",
        margin: "auto",
        padding: 30,
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
        // marginRight: 10,
        // marginRight: 1,
        width: "70%",
    },
    input: {
        // borderColor: "#c3c3c3",
        width: "70%",
        // borderWidth: 1,
        // borderRadius: 4,
        padding: 2,
        marginTop: 4,
        marginBottom: 6,
    },
    title: {
        fontSize: 20,
        fontWeight: "bold",
    },
    addCountry: {
        alignItems: "center",
        backgroundColor: "#006EB9",
        padding: 10,
        borderWidth: 1,
        borderColor: "#006EB9",
        width: "70%",
        // marginTop: 20,
    },
    saveBtn: {
        alignItems: "center",
        backgroundColor: "#006EB9",
        padding: 10,
        borderWidth: 1,
        borderColor: "#006EB9",
        width: "20%",
        marginRight: 10,
    },
    btnText: {
        color: "#fff",
        fontWeight: "600",
    },
    datePicker: {
        justifyContent: "center",
        alignItems: "flex-start",
        width: 320,
        height: 260,
        display: "flex",
    },
    card: {
        // backgroundColor: "white",
        // borderRadius: 8,
        // paddingVertical: 45,
        // paddingHorizontal: 25,
        // width: "100%",
        // flex: 1,
        // marginVertical: 10,
        // paddingBottom: "48%",

        width: "100%",
        flex: 1,
        marginVertical: 0,

    },
    shadowProp: {
        shadowColor: "#171717",
        shadowOffset: { width: -2, height: 4 },
        shadowOpacity: 0.2,
        shadowRadius: 25,
    },

    link: {
        marginTop: 15,
        paddingVertical: 15,
    },
    error_text: {
        color: "red",
        fontSize: 14,
        fontWeight: "600",
        width: "70%",
        marginLeft: 0,
        marginBottom: 10,
    },
    linkText: {
        fontSize: 14,
        color: "#2e78b7",
    },

    // DropDown CSS
    dropdown: {
        borderColor: "#c3c3c3",
        width: "70%",
        borderWidth: 1,
        borderRadius: 2,
        height: 40,

        padding: 10,
        marginTop: 5,
        marginBottom: 5,
        // borderRadius: 10,
        // padding: 12,
        // shadowColor: "#000",
        // shadowOffset: {
        //   width: 0,
        //   height: 1,
        // },
        // shadowOpacity: 0.2,
        // shadowRadius: 1.41,

        // elevation: 2,
    },
    icon: {
        marginRight: 5,
    },
    item: {
        padding: 17,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    textItem: {
        flex: 1,
        fontSize: 16,
    },
    placeholderStyle: {
        fontSize: 16,
    },
    selectedTextStyle: {
        fontSize: 16,
    },
    iconStyle: {
        width: 20,
        height: 20,
    },
    inputSearchStyle: {
        height: 40,
        fontSize: 16,
    },
    lableText: {
        fontSize: 16,
        color: "#484848",
        fontWeight: "700",
        marginTop: 18,
        // fontSize: 21,
    },
});
