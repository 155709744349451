import Updating from "./Updating";
import projectIdUpdate from "./ProjectIdUpdate"
import projectIdCreate from "./ProjectIdCreate";
import projectCodes from "./ProjectCode";
import projectName from "./ProjectName";
import { combineReducers } from "redux"

const allReducers = combineReducers({

    isUpdating: Updating,
    idUpdating: projectIdUpdate,
    idCreating: projectIdCreate,
    projectCodeId: projectCodes,
    projectName: projectName
});




export default allReducers
