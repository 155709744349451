/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
// import { Ionicons } from "@expo/vector-icons/Ionicons";
import Icon from "react-native-vector-icons/FontAwesome";
import IconMenu from "react-native-vector-icons/Entypo";
import IconEdit from "react-native-vector-icons/FontAwesome";
import IconLogout from "react-native-vector-icons/AntDesign";

import {
  Image,
  Text,
  View,
  StyleSheet,
  TouchableOpacity,
  TextInput,
  Modal,
  Button,
  Switch,
  Alert,
} from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";

import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import moment from "moment";
import { Dropdown } from "react-native-element-dropdown";

import {
  NavigationContainer,
  DefaultTheme,
  DarkTheme,
  CommonActions,
} from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import React, { useState, useRef, useEffect } from "react";
import { ColorSchemeName, Pressable } from "react-native";

import Colors from "../constants/Colors";
import useColorScheme from "../hooks/useColorScheme"; // @ts-ignore
import HomeScreen from "../screens/Home/HomeScreen";
import DeviceProfile from "../screens/Profile/DeviceProfile/DeviceProfile";

import LoginScreen from "../screens/Login/LoginScreen"; // @ts-ignore
import ModalScreen from "../screens/ModalScreen";
import NotFoundScreen from "../screens/NotFoundScreen";
import ResetPass from "../screens/ResetPassword/ResetPass"; // @ts-ignore
import Sidebar from "./sidebar";
import SidebarNew from "./sidebarNew";
import SidebarDrawer from "./SidebarDrawer";
import IconRefresh from "react-native-vector-icons/FontAwesome";

import { RootStackParamList } from "../types";
import LinkingConfiguration from "./LinkingConfiguration";

import { useSelector, useDispatch } from "react-redux";
import {
  projectIdUpdate,
  projectCodes,
  projectName,
  projectIdCreate,
} from "../components/redux/actions";
import { ActivityIndicator } from "react-native-paper";
import Zone from "../screens/Monitors/Zone";
import Subzone from "../screens/Monitors/Subzone";
import ValveView from "../screens/Monitors/ValveView";
import ValveDetailsReport from "../screens/Home/ValveDetailsReport";
import SensorDetailsReport from "../screens/Home/SensorDetailsReport";
import DeviceDetailsReport from "../screens/Home/DeviceDetailsReport";
import { ScrollView } from "react-native-gesture-handler";
import EditFarmerDetails from "../screens/FarmerDetails/EditFarmerDetails";
import { baseUrlimage, postData } from "../networking/api";

const userIcon = require("../assets/images/usericon.png");
const projectIcon = require("../assets/images/pricon.png");
export default function HeaderFarmer({
  colorScheme,
  navigation,
  route,
}: {
  colorScheme: ColorSchemeName;
}) {
  // const { farmerId } = route?.params;
  // // console.log("farmerId===== header farmer", farmerId);

  const [isVisible, setIsVisible] = useState(false);
  const [error, setError] = useState("");
  const [username, setUsername] = useState("");
  const [farmerName, setFarmerName] = useState("");
  const [farmerUniqueId, setFarmerUniqueId] = useState("");

  const [userId, setUserId] = useState("");
  const [projectId, setprojectId] = useState("");

  const [profileImage, setProfileImage] = useState("");
  const [projectProfile, setProjectProfile] = useState(null);

  const [projectTitle, setProjectTitle] = useState("");
  const [projectManager, setProjectManager] = useState("");
  const [clientName, setClientName] = useState("");

  const [projectSelected, setProjectSelected] = useState("");
  var dispatch = useDispatch();
  const [isAdd, setisAdd] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingProject, setIsLoadingProject] = useState(false);

  var alpha_pattern = new RegExp(/^[a-zA-Z]+(\s[a-zA-Z]+)?$/);

  const [ProjectTypeNameData, setProjectTypeNameData] = useState([]);
  const [ProjectsData, setProjectData] = useState([]);
  const [projectTypeValue, setProjectTypeValue] = useState("");
  const [projectTypeValId, setProjectTypeValueId] = useState("");

  const [projectValue, setProjectValue] = useState("");
  const [projectValId, setProjectValueId] = useState("");
  const [isLoadingSync, setIsLoadingSync] = useState(false);

  const [userTypeValue, setUserTypeValue] = useState("");

  const [isLoginToken, setLoginToken] = useState(null);
  useEffect(() => {
    getAdminData();
    getProjectTypeList();
    // navigation.navigate("EditFarmerDetails");
  }, []);
  // useEffect(() => {
  //   // console.log("isLoginToken", isLoginToken)
  //   if (isLoginToken === null) {
  //     navigation.navigate('Login')
  //   }
  // }, [isLoginToken]);
  const buttonPressed = useSelector((state) => state.isUpdating);
  // const createProjId = useSelector((state) => state.idCreating)

  useEffect(() => {
    // console.log("is press");
    getAdminData();
  }, [buttonPressed]);

  const getAdminData = async () => {
    let jsonValue = await AsyncStorage.getItem("farmerData");

    let farmerTokenData = await AsyncStorage.getItem("farmerToken");
    // let projValue = await AsyncStorage.getItem("projectData");
    let farmerTypes = await AsyncStorage.getItem("farmerTypes");
    let projectIDS = await AsyncStorage.getItem("projectids");
    let farmerIds = await AsyncStorage.getItem("farmerIds");
    setFarmerUniqueId(farmerIds);
    // console.log("farmerIds", farmerIds);
    if (projectIDS != null) {
      setUserTypeValue(farmerTypes);
      setprojectId(projectIDS);
      getProjectProfileById(projectIDS);
      setProjectSelected(projectIDS);
    } else if (jsonValue != null) {
      var farmerDetailsresponse = JSON.parse(jsonValue);
      // console.log("farmerDetailsresponse==>1", farmerDetailsresponse);
      setprojectId(farmerDetailsresponse.project_selected);
      setUserTypeValue(farmerTypes);
      setFarmerName(farmerDetailsresponse.farmer_name);
      setProjectSelected(farmerDetailsresponse.project_id);

      getProjectProfileById(farmerDetailsresponse.project_id);
    }

    // console.log("farmerTokenData", farmerTokenData);

    if (farmerTokenData === null) {
      navigation.navigate("FarmerLogin");
    } else {
      if (jsonValue != null) {
        var farmerDetailsresponse = JSON.parse(jsonValue);
        // console.log("farmerDetailsresponse==>2", farmerDetailsresponse);

        setFarmerName(farmerDetailsresponse.farmer_name);
        setProfileImage(
          baseUrlimage +
          "uploads/profile_picture/" +
          farmerDetailsresponse.profile_pic
        );

        setUserId(farmerDetailsresponse.id);
        setprojectId(farmerDetailsresponse.project_selected);

        // setLoginToken(farmerTokenData);

        // // console.log("isLoginToken", isLoginToken)
        // console.log("farmerDetailsresponse.token", farmerDetailsresponse.token);
        // if (farmerDetailsresponse.token === null) {
        //   navigation.navigate('Login')
        // }
        // setProjectTitle("Project 1");
        // setProjectManager("Mr.Basu");


        // getProjectProfileById(farmerDetailsresponse.project_selected);
        setProjectSelected(farmerDetailsresponse.project_id);
      }
    }
  };
  // dashboard data when click on refresh button
  const getDashboardRefresh = async () => {
    setIsLoadingSync(true);
    try {
      var dashSyncData = JSON.stringify({
        project_id: projectId,
      });
      // console.log("dashSyncData payload", dashSyncData);
      const dashSyncResponse = await postData(
        dashSyncData,
        "masters/liveMonitoring"
      );
      setIsLoadingSync(false);
      var code = dashSyncResponse.code;
      if (code == 1) {
        // console.log("dashboard response", dashSyncResponse.message);
        // //alert(dashSyncResponse.message);
      } else {
        setIsLoadingSync(false);
        // //alert(dashSyncResponse.message);
      }
    } catch (error) {
      setIsLoadingSync(false);
      // console.log(error);
    }
  };

  //Get project profile  data by id
  const getProjectProfileById = async (id) => {
    // console.log("INSIDE VIEW OF PROJECT PROFILE", id);
    setIsLoading(true);
    try {
      var ProfileData = JSON.stringify({
        module: "project",
        relation: [
          {
            module: "project_type",
          },
          {
            module: "project_devices",
            subModule: "devices_master",
          },
        ],
        id: id,
      });
      // console.log("ProfileData id", ProfileData);

      const ProjectProfileDataById = await postData(
        ProfileData,
        "/masters/getMasterList"
      );
      setIsLoading(false);
      var code = ProjectProfileDataById.code;
      // console.log("Project profile data====>", ProjectProfileDataById);
      if (code == 1) {


        var profileUrl =
          baseUrlimage +
          "uploads/project_profile/" +
          ProjectProfileDataById.data[0].profile_pic;
        setProjectProfile(profileUrl);
        setProjectTitle(ProjectProfileDataById.data[0].project_name);
        setProjectManager(ProjectProfileDataById.data[0].contractor);
        setClientName(ProjectProfileDataById.data[0].client);
        dispatch(projectCodes());
        dispatch(projectName());
        AsyncStorage.setItem(
          "projectCode",
          JSON.stringify(ProjectProfileDataById.data[0].project_code)
        );
        AsyncStorage.setItem(
          "projectName",
          JSON.stringify(ProjectProfileDataById.data[0].project_name)
        );
      } else {
        setIsLoading(false);
        // //alert(ProjectProfileDataById.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  const isCreatable = () => {
    setIsVisible(true);
    setisAdd(true);
  };
  // const isAddable = (projectTypeId, projectTypename, project_name) => {
  //   setIsVisible(true);
  //   setisAdd(false);

  //   //Set name to update
  //   // setProjectTypeValue(projectTypename);
  //   setProjectValue(project_name);

  //   //set id to update
  //   setProjectTypeValueId(projectTypename);
  //   setProjectValueId(project_name);
  // };

  const isAddable = () => {
    setIsVisible(true);
    setisAdd(false);
  };

  // project type Get Api
  const getProjectTypeList = async () => {
    setIsLoadingProject(true);
    try {
      var ProjectTypeData = JSON.stringify({
        module: "project_type",
        relation: [],
      });
      const ProjectTypeList = await postData(
        ProjectTypeData,
        "masters/getMasterList"
      );
      setIsLoadingProject(false);
      var code = ProjectTypeList.code;
      if (code == 1) {
        // console.log("project list api====>", ProjectTypeList.data);
        setProjectTypeNameData(ProjectTypeList.data);
      } else {
        setIsLoadingProject(false);
        // //alert(ProjectTypeList.message);
      }
    } catch (error) {
      setIsLoadingProject(false);
      // console.log(error);
    }
  };

  // Get dependent project by project type
  const getDependentProjectTypeMaster = async (project_type_id) => {
    // console.log("masters", project_type_id);
    setIsLoading(true);

    try {
      var dependentData = JSON.stringify({
        user_id: userId,
        project_type_id: project_type_id,
        // data: {
        //   column: "project_type",
        //   value: project_type_id,
        // },
      });
      // console.log("dependentData===>", dependentData);
      const dependentProjectTypeDataResponse = await postData(
        dependentData,
        "masters/getProjectDataByUserId"
      );
      setIsLoading(false);
      var code = dependentProjectTypeDataResponse.code;
      if (code == 1) {

        // setCountryLength(dependentProjectTypeDataResponse.data.length);
        setProjectData(dependentProjectTypeDataResponse.data);
      } else {
        setIsLoading(false);
        // //alert(dependentProjectTypeDataResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };
  const renderProjectTypeItem = (item: any) => {
    // console.log("item---value", item.id);
    return (
      <View style={styles.item}>
        <Text style={styles.textItem}>{item.type}</Text>
        {item.id === projectTypeValId && (
          <Icon style={styles.icon} color="black" name="Safety" size={20} />
        )}
      </View>
    );
  };

  const renderProjectItem = (item: any) => {
    // console.log("item---value project name", item.id);
    return (
      <View style={styles.item}>
        <Text style={styles.textItem}>{item.project_name}</Text>
        {item.id === projectValId && (
          <Icon style={styles.icon} color="black" name="Safety" size={20} />
        )}
      </View>
    );
  };

  const CheckEnteredData = async () => {
    if (projectTypeValue == "" && projectValue == "") {
      setError("Please select project type");
      // console.log("empty");
      // setIsVisible(false);
    } else if (projectTypeValue && projectValue) {
      {
        isAdd == true && createProjectType();
        setIsLoading(true);
        setIsVisible(false);
      }

      {
        isAdd == false && updateProjectType();
        setIsVisible(false);
      }
    } else {
      // console.log("falseśśś");
      // setError("Please enter Character Only.");
    }
  };

  //Project Type create API
  const createProjectType = async () => {
    setIsLoading(true);
    try {
      var projectTypeData = JSON.stringify({
        module: "user_master",
        id: userId,
        data: {
          project_selected: projectValId,
        },
      });

      // console.log("Project Type:", projectTypeData);
      const projectTypeResponse = await postData(
        projectTypeData,
        "masters/masterCreateUpdate"
      );

      setIsLoading(false);

      var code = projectTypeResponse.code;
      // console.log("projectTypeResponse", projectTypeResponse);
      if (code == 1) {
        // //alert(projectTypeResponse.message);
        setIsVisible(false);
        // getProjectTypeList();
        setProjectTypeValue("");
        // dispatch(projectIdCreate())

        AsyncStorage.setItem("projectSelect", JSON.stringify(projectValId));
        setProjectSelected(projectValId);

        // console.log("Added", projectTypeResponse.data);
      } else {
        setIsLoading(false);
        // //alert(projectTypeResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  //project type Update API
  const updateProjectType = async () => {

    setIsLoading(true);
    try {
      var ProjectTypeData = JSON.stringify({
        module: "user_master",
        id: userId,
        data: {
          project_selected: projectValId,
        },
      });

      await AsyncStorage.setItem("projectData", JSON.stringify(projectValId));
      getProjectProfileById(projectValId);
      // console.log("project_type payload update", ProjectTypeData);
      const ProjectTypeResponse = await postData(
        ProjectTypeData,
        "masters/masterCreateUpdate"
      );

      setIsLoading(false);

      var code = ProjectTypeResponse.code;
      if (code == 1) {
        // //alert(ProjectTypeResponse.message);
        setIsVisible(false);
        getProjectTypeList();

        dispatch(projectIdUpdate());
        // setProjectSelected(ProjectTypeResponse.data[0].id)
        // console.log("Updated ProjectTypeResponse", ProjectTypeResponse.data);
      } else {
        setIsLoading(false);
        // //alert(ProjectTypeResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };
  function ProjectTypeModal() {
    return (
      <>
        <Modal
          onRequestClose={() => setIsVisible(false)}
          transparent
          visible={isVisible}
        >
          <View
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",
              backgroundColor: "rgba(100,100,100, 0.5)",
            }}
          >
            <View
              style={{
                backgroundColor: "white",
                borderColor: "#eee",
                borderRadius: 10,
                borderWidth: 1,
                height: "40%",
                margin: "auto",
                padding: 30,
                shadowOffset: {
                  width: 0,
                  height: 2,
                },
                shadowOpacity: 0.25,
                shadowRadius: 4,
                elevation: 5,

                width: "30%",
              }}
            >
              <View style={{ width: "100%" }}>
                <Text style={{ fontWeight: "500" }}>Project Type</Text>
                {isAdd == true && (
                  <View>
                    <Dropdown
                      style={styles.dropdown}
                      placeholderStyle={styles.placeholderStyle}
                      selectedTextStyle={styles.selectedTextStyle}
                      inputSearchStyle={styles.inputSearchStyle}
                      iconStyle={styles.iconStyle}
                      data={ProjectTypeNameData}
                      search
                      maxHeight={300}
                      labelField="type"
                      valueField="type"
                      placeholder="Select Project Type"
                      searchPlaceholder="Search..."
                      value={projectTypeValue}
                      onChange={(item) => {
                        setProjectTypeValue(item.type);
                        setProjectTypeValueId(item.id);
                        getDependentProjectTypeMaster(item.id);
                        // console.log("value===>", item.type);
                      }}
                      renderItem={renderProjectTypeItem}
                    />
                    <Text
                      style={{
                        color: "red",
                        fontSize: 14,
                        fontWeight: "600",
                        width: "70%",
                        marginLeft: 0,
                      }}
                    >
                      {error}
                    </Text>
                  </View>
                )}
                {isAdd == false && (
                  <View>
                    <Dropdown
                      style={styles.dropdown}
                      placeholderStyle={styles.placeholderStyle}
                      selectedTextStyle={styles.selectedTextStyle}
                      inputSearchStyle={styles.inputSearchStyle}
                      iconStyle={styles.iconStyle}
                      data={ProjectTypeNameData}
                      search
                      maxHeight={300}
                      labelField="type"
                      valueField="type"
                      placeholder="Select Project Type"
                      searchPlaceholder="Search..."
                      value={projectTypeValue}
                      onChange={(item) => {
                        setProjectTypeValue(item.type);
                        setProjectTypeValueId(item.id);
                        getDependentProjectTypeMaster(item.id);
                        // console.log("value===> project value", item.type);
                      }}
                      renderItem={renderProjectTypeItem}
                    />
                    <Text
                      style={{
                        color: "red",
                        fontSize: 14,
                        fontWeight: "600",
                        width: "70%",
                        marginLeft: 0,
                      }}
                    >
                      {error}
                    </Text>
                  </View>
                )}
              </View>
              <View style={{ width: "100%" }}>
                <Text style={{ fontWeight: "500" }}>Project</Text>
                {isAdd == true && (
                  <View>
                    <Dropdown
                      style={styles.dropdown}
                      placeholderStyle={styles.placeholderStyle}
                      selectedTextStyle={styles.selectedTextStyle}
                      inputSearchStyle={styles.inputSearchStyle}
                      iconStyle={styles.iconStyle}
                      data={ProjectsData}
                      search
                      maxHeight={300}
                      labelField="project_name"
                      valueField="project_name"
                      placeholder="Select Project"
                      searchPlaceholder="Search..."
                      value={projectValue}
                      onChange={(item) => {
                        setProjectValue(item.project_name);
                        setProjectValueId(item.id);
                        // console.log("project name", item);

                        // console.log("value===> project name", item.id);
                      }}
                      renderItem={renderProjectItem}
                    />
                    <Text
                      style={{
                        color: "red",
                        fontSize: 14,
                        fontWeight: "600",
                        width: "70%",
                        marginLeft: 0,
                      }}
                    >
                      {error}
                    </Text>
                  </View>
                )}

                {isAdd == false && (
                  <View>
                    <Dropdown
                      style={styles.dropdown}
                      placeholderStyle={styles.placeholderStyle}
                      selectedTextStyle={styles.selectedTextStyle}
                      inputSearchStyle={styles.inputSearchStyle}
                      iconStyle={styles.iconStyle}
                      data={ProjectsData}
                      search
                      maxHeight={300}
                      labelField="project_name"
                      valueField="project_name"
                      placeholder="Select Project"
                      searchPlaceholder="Search..."
                      value={projectValue}
                      onChange={(item) => {
                        setProjectValue(item.project_name);
                        setProjectValueId(item.id);

                      }}
                      renderItem={renderProjectItem}
                    />
                    <Text
                      style={{
                        color: "red",
                        fontSize: 14,
                        fontWeight: "600",
                        width: "70%",
                        marginLeft: 0,
                      }}
                    >
                      {error}
                    </Text>
                  </View>
                )}
              </View>
              {isAdd == true && (
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                    marginHorizontal: 20,
                    marginTop: 20,
                  }}
                >
                  <TouchableOpacity
                    style={{
                      alignItems: "center",
                      backgroundColor: "#006EB9",
                      padding: 10,
                      borderWidth: 1,
                      borderColor: "#006EB9",
                      width: "50%",
                      marginRight: 10,
                    }}
                    onPress={() => CheckEnteredData()}
                  >
                    <Text style={{ color: "#fff", fontWeight: "600" }}>
                      Save
                    </Text>
                  </TouchableOpacity>

                  <TouchableOpacity
                    style={{
                      alignItems: "center",
                      backgroundColor: "#006EB9",
                      padding: 10,
                      borderWidth: 1,
                      borderColor: "#006EB9",
                      width: "50%",
                      marginRight: 10,
                    }}
                    onPress={() => setIsVisible(false)}
                  >
                    <Text style={{ color: "#fff", fontWeight: "600" }}>
                      Cancel
                    </Text>
                  </TouchableOpacity>
                </View>
              )}
              {isAdd == false && (
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                    marginHorizontal: 20,
                    marginTop: 20,
                  }}
                >
                  <TouchableOpacity
                    style={{
                      alignItems: "center",
                      backgroundColor: "#006EB9",
                      padding: 10,
                      borderWidth: 1,
                      borderColor: "#006EB9",
                      width: "50%",
                      marginRight: 10,
                    }}
                    onPress={() => CheckEnteredData()}
                  >
                    <Text style={{ color: "#fff", fontWeight: "600" }}>
                      Update
                    </Text>
                  </TouchableOpacity>

                  <TouchableOpacity
                    style={{
                      alignItems: "center",
                      backgroundColor: "#006EB9",
                      padding: 10,
                      borderWidth: 1,
                      borderColor: "#006EB9",
                      width: "50%",
                      marginRight: 10,
                    }}
                    onPress={() => setIsVisible(false)}
                  >
                    <Text style={{ color: "#fff", fontWeight: "600" }}>
                      Cancel
                    </Text>
                  </TouchableOpacity>
                </View>
              )}

              {/* <Gap />
          {isVisible ? <Modalception depth={depth + 1} /> : null}
          <Gap /> */}
            </View>
          </View>
        </Modal>
      </>
    );
  }
  const logout = () => {
    AsyncStorage.clear();
    navigation.dispatch(
      CommonActions.reset({
        index: 1,
        routes: [{ name: "FarmerLogin" }],
      })
    );
  };
  return (
    <View

      style={{
        flex: 1,
        alignItems: "flex-start", // ignore this - we'll come back to it
        justifyContent: "flex-start", // ignore this - we'll come back to it
        flexDirection: "row",
        backgroundColor: "#fff",
        //   padding: 10,
      }}
    >
      {isLoading}
      {/* Sidebar  */}
      {/* <Sidebar /> */}
      {/* <SidebarNew /> */}
      {/* {userTypeValue == "1" && <SidebarDrawer />} */}
      {/* Header  */}
      <View
        style={{
          flex: 4,
          // height: '100%',
          // display: 'flex',
          // flexDirection: 'column'
        }}
      >
        <View
          style={{
            flexDirection: "row",
            height: "100%",
            backgroundColor: "#EDEDED",
          }}
        >
          <View style={{ flex: 1, margin: 0 }}>
            <Image
              source={require("../assets/images/bermad.png")}
              style={{
                height: 44,
                width: 150,
                margin: 5,
              }}
            />
          </View>
          <View
            style={{
              flexDirection: "row",
              flex: 1,
              justifyContent: "flex-end",
            }}
          >
            <Text style={{ margin: 10, color: "#7E7E7E" }}>
              <Icon name="calendar" size={20} color="#7E7E7E" />{" "}
              {moment().format("Do MMM YYYY")}
            </Text>
            <Text style={{ margin: 10, color: "#7E7E7E" }}>
              <Icon name="clock-o" size={20} color="#7E7E7E" />{" "}
              {moment().format("LT")}
            </Text>
          </View>
        </View>
        {/* start of Header title */}
        {projectSelected == null ? (
          <View
            style={{
              // flex: 2,
              flexDirection: "row",
              alignItems: "center",
              backgroundColor: "#009DF0",
              justifyContent: "flex-start",
              // marginBottom: "30%",
            }}
          >
            <View
              style={{
                marginRight: 10,
                marginLeft: 5,
              }}
            ></View>
            <View
              style={{
                flex: 0.2,

                marginTop: 5,
                marginBottom: 5,
              }}
            >
              <Text
                // onPress={() => setIsVisible(true)}
                style={{ margin: 10, color: "#fff" }}
              ></Text>
            </View>
            <View
              style={{
                flex: 1,
              }}
            >
              <Text style={{ margin: 15, color: "#fff" }}></Text>
            </View>

            {/* End of project title */}

            {/* Username  */}
            <View
              style={{
                justifyContent: "flex-end",
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  flex: 1,
                }}
              >
                {/* <Image
                  source={userIcon}
                  style={{
                    height: 38,
                    width: 28,
                    margin: 5,
                  }}
                /> */}
                <Image
                  source={
                    profileImage
                      ? { uri: profileImage }
                      : require("../assets/images/projectImg.png")
                  }
                  // source={{ uri: profileImage }}
                  style={{ width: 28, height: 38, margin: 6 }}
                />
              </View>
              <View>
                <Text style={{ margin: 15, color: "#fff" }}>{farmerName}</Text>
              </View>
            </View>
            {/* username end */}
          </View>
        ) : isLoadingProject ? (
          <ActivityIndicator
            //visibility of Overlay Loading Spinner
            color="#1DA1F2"
            visible={isLoadingProject}
            //Text with the Spinner
            textContent={"Loading..."}
            size={40}
          //  //Text style of the Spinner Text
          //  textStyle={styles.spinnerTextStyle}
          />
        ) : (
          <View
            style={{
              // flex: 2,
              flexDirection: "row",
              alignItems: "center",
              backgroundColor: "#009DF0",
              justifyContent: "flex-start",
              // marginBottom: "30%",
            }}
          >
            <View
              style={{
                marginRight: 10,
                marginLeft: 5,
              }}
            >
              <Image
                source={
                  projectProfile
                    ? projectProfile
                    : require("../assets/images/projectImg.png")
                }
                style={{
                  height: 44,
                  width: 43,
                  margin: 5,
                }}
              />
            </View>
            <View
              style={{
                flex: 0.6,
                borderRightWidth: 2,

                borderRightColor: "#fff",
                marginTop: 5,
                marginBottom: 5,
              }}
            >
              <Text
                // onPress={() => setIsVisible(true)}
                style={{ margin: 2, color: "#fff" }}
              >
                {projectTitle}
              </Text>
              <Text
                // onPress={() => setIsVisible(true)}
                style={{ margin: 1, color: "#fff" }}
              >
                {clientName}
              </Text>
            </View>
            <View
              style={{
                // flex: 0.5,
                marginTop: 3,
                borderRightWidth: 2,

                borderRightColor: "#fff",
              }}
            >
              <Text
                onPress={() => setIsVisible(true)}
                style={{ margin: 15, color: "#fff" }}
              >
                Managed By {projectManager}
              </Text>
            </View>
            <View
              style={{
                flex: 1,
                marginTop: 3,
                flexDirection: "column",
              }}
            >
              {isLoadingSync ? (
                <ActivityIndicator
                  //visibility of Overlay Loading Spinner
                  color="#fff"
                  visible={isLoadingSync}
                  //Text with the Spinner
                  textContent={"Loading..."}
                  size={40}
                //  //Text style of the Spinner Text
                //  textStyle={{color:'#fff'}}
                />
              ) : null}
            </View>

            {/* End of project title */}

            {/* Username  */}
            <View
              style={{
                justifyContent: "flex-end",
                flexDirection: "row",
                marginTop: 3,
              }}
            >
              <View
                style={{
                  flex: 1,
                  alignItems: "center",
                }}
              >
                {/* <Image
                  source={userIcon}
                  style={{
                    height: 38,
                    width: 28,
                    margin: 5,
                  }}
                  /> */}
                <Image
                  // source={{ uri: profileImage }}

                  source={
                    profileImage
                      ? { uri: profileImage }
                      : require("../assets/images/pricon.png")
                  }
                  style={{ width: 28, height: 38, margin: 6 }}
                />
              </View>
              <View
                style={{
                  borderRightWidth: 2,

                  borderRightColor: "#fff",
                  // marginTop: 5,
                  // marginBottom: 5,
                }}
              >
                <Text style={{ margin: 15, color: "#fff" }}>{farmerName} </Text>
              </View>
              <View
                style={{
                  // flex: 1,
                  margin: 15,
                }}
              >
                <Text
                  onPress={() => logout()}
                  // onPress={() => navigation.navigate("Login")}
                  style={{ color: "#fff", marginTop: -3 }}
                >
                  <TouchableOpacity
                    onPress={() => logout()}

                  // onPress={() => navigation.navigate("Login")}
                  >
                    <IconLogout
                      name="poweroff"
                      size={13}
                      color="#fff"
                      style={{
                        padding: 6,
                        // backgroundColor: "#08588E",
                        // borderRadius: 12,
                      }}
                    />
                  </TouchableOpacity>
                  Logout
                </Text>
              </View>
            </View>
            {/* username end */}
          </View>
        )}

        <View>
          {/* <RootNavigator /> */}
          <View style={{ flex: 1, backgroundColor: "#fff" }}>
            <RootNavigator farmerId={farmerUniqueId} />
            {/* <ProjectTypeModal /> */}
            {/* <Text>dsfsdf</Text> */}
          </View>
        </View>
      </View>
    </View>
  );
}
const Stack = createNativeStackNavigator<RootStackParamList>();

// function FarmerDetailsStack() {
//   return (
//     <Stack.Navigator initialRouteName="FarmerDetails">
//       <Stack.Screen
//         name="EditFarmerDetails"
//         component={EditFarmerDetails}
//         options={{ title: "Edit Farmer Details", headerShown: false }}
//       />
//     </Stack.Navigator>
//   );
// }

function RootNavigator(farmerId) {
  // const farmerIds = await AsyncStorage.getItem("farmerIds");

  // var farmerIds = farmerId.farmerId;
  // // console.log("====================================");
  // console.log("farmerIds======idddd00", farmerId.farmerId);

  // // console.log("====================================");
  // setFarmerUniqueId(farmerIds);
  return (
    <Stack.Navigator>
      {/* <Stack.Screen
        name="FarmerDetails"
        component={FarmerDetailsStack}
        options={{ title: "Farmer Details", headerShown: false }}
      /> */}
      <Stack.Screen
        name="EditFarmerDetails"
        component={EditFarmerDetails}
        // initialParams={{ farmerId: farmerId.farmerId }}
        options={{ title: "farmer Details", headerShown: false }}
      />

      <Stack.Group screenOptions={{ presentation: "modal" }}>
        <Stack.Screen name="Modal" component={ModalScreen} />
      </Stack.Group>
    </Stack.Navigator>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    // marginTop: 200,
  },

  // DropDown CSS
  dropdown: {
    borderColor: "#c3c3c3",
    width: "100%",
    borderWidth: 1,
    borderRadius: 2,
    height: 40,
    padding: 10,
    marginTop: 5,
    marginBottom: 5,
    // borderRadius: 10,
    // padding: 12,
    // shadowColor: "#000",
    // shadowOffset: {
    //   width: 0,
    //   height: 1,
    // },
    // shadowOpacity: 0.2,
    // shadowRadius: 1.41,

    // elevation: 2,
  },
  icon: {
    marginRight: 5,
  },
  item: {
    padding: 9,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  textItem: {
    flex: 1,
    fontSize: 16,
  },
  placeholderStyle: {
    fontSize: 16,
  },
  selectedTextStyle: {
    fontSize: 16,
  },
  iconStyle: {
    width: 20,
    height: 20,
  },
  inputSearchStyle: {
    height: 40,
    fontSize: 16,
    outlineStyle: "none",
  },
});
