import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  View,
  Text,
  MapView,
  Image,
  TouchableOpacity,
  Pressable,
  ActivityIndicator,
  Modal,
  //   Animated,
} from "react-native";
// import MapView from 'react-native-maps';
import IconCross from "react-native-vector-icons/Entypo";
import DoubleClick from "react-native-double-tap";
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withDelay,
  withRepeat,
  withTiming,
  interpolate,
} from "react-native-reanimated";
import { Marker } from "react-native-maps";
import GoogleMapReact from "google-map-react";
import MarkerClusterer from "@googlemaps/markerclustererplus";

import { useFocusEffect } from "@react-navigation/native";
import { postData } from "../../networking/api";
import { useHover } from "@react-native-aria/interactions";

import AsyncStorage from "@react-native-async-storage/async-storage";
import Popover, {
  PopoverMode,
  PopoverPlacement,
} from "react-native-popover-view";
import DataTable from "react-data-table-component";
import Icon from "react-native-vector-icons/FontAwesome";
// import { keyframes, stagger } from 'popmotion';
import { FontAwesome } from "@expo/vector-icons";

// import { MotiView } from '@motify/components'
// import { MotiView } from 'moti'

// const omegaLogo = require("../assets/images/omega.png");

// const AnyReactComponent = ({ text }) =>
//     <View>
//         <Text>{ text}</Text>
//         <Image source={omegaLogo}></Image>
//     </View>

const handleEvent = (id) => {
  <View>
    <Text>id here {id}</Text>
  </View>;
};

// const COUNT = 5;
// const DURATION = 4000;
// const initialPhase = { scale: 0, opacity: 1 };
// const constructAnimations = () =>
//     [...Array(COUNT).keys()].map(() => initialPhase);

export default function MapViewMonitor({ navigation }) {
  const [isLoading, setIsLoading] = useState(false);
  const [ScheduleProgramDataLength, setScheduleProgramDataLength] = useState(0);
  const [ScheduleProgramNameData, setScheduleProgramNameData] = useState([]);
  const ref = React.useRef();
  // const { isHovered, hoverProps } = useHover({}, ref);
  const [isHovered, setIsHovered] = useState(null);

  // console.log("====================================");
  // console.log("isHovered", isHovered);
  // console.log("====================================");
  //dropdown omega id
  const [isVisible, setIsVisible] = useState(false);

  const [OmegaIdData, setOmegaIdData] = useState([]);
  const [showPopover, setShowPopover] = useState(false);
  const [popupInfo, setPopupInfo] = useState(false);
  const [showIsOnline, setShowIsOnline] = useState(true);

  const [OmegaIdGet, setOmegaIdGet] = useState("");
  const [uniqueOmegaIdGet, setUniqueOmegaIdGet] = useState("");

  const [centerLat, setCenterLat] = useState(0);
  const [centerLong, setCenterLong] = useState(0);
  const [isLoadingSync, setIsLoadingSync] = useState(false);
  const [projectId, setProjectId] = useState("");
  //   const [animations, setAnimations] = useState(constructAnimations());

  const [digitalInputDetails, setDigitalInputDetails] = useState([]);
  const [centerLatLong, setCenterLatLong] = useState({});
  const [mapViewPopupDetails, setMapViewPopupDetails] = useState({});


  let defaultProps = {};

  useEffect(() => {
    // console.log("centerLat, centerLong", centerLat, centerLong);
  }, [centerLat, centerLong]);

  useFocusEffect(
    React.useCallback(() => {
      getAdminData();
      // getAllScheduleProgramProfileData([]);
    }, [])
  );
  const Ring = ({ delay }) => {
    const ring = useSharedValue(0);

    const ringStyle = useAnimatedStyle(() => {
      return {
        opacity: 0.8 - ring.value,
        transform: [
          {
            scale: interpolate(ring.value, [0, 1], [0, 4]),
          },
        ],
      };
    });
    useEffect(() => {
      ring.value = withDelay(
        delay,
        withRepeat(
          withTiming(1, {
            duration: 4000,
          }),
          -1,
          false
        )
      );
    }, []);
    return <Animated.View style={[styles.ring, ringStyle]} />;
  };

  const animateCircles = () => {
    const actions = Array(COUNT).fill(
      keyframes({
        values: [initialPhase, { scale: 2, opacity: 0 }],
        duration: DURATION,
        loop: Infinity,
        yoyo: Infinity,
      })
    );

    stagger(actions, DURATION / COUNT).start((animations) => {
      setAnimations({ animations });
    });
  };
  const getAdminData = async () => {
    let jsonValue = await AsyncStorage.getItem("userData");
    // console.log("jsonValue:", jsonValue);
    let projValue = await AsyncStorage.getItem("projectData");

    // console.log("jsonValue & projValue:", jsonValue, projValue);
    if (projValue != null) {
      // console.log("project id select in zone create", projValue);
      setProjectId(projValue);
      getOmegaDependentByProjectId(projValue);
    } else if (jsonValue != null) {
      var userDetailsresponse = JSON.parse(jsonValue);
      // console.log("userDetailsresponse in zone ==>", userDetailsresponse);
      setProjectId(userDetailsresponse.project_selected);
      getOmegaDependentByProjectId(userDetailsresponse.project_selected);
    }
  };

  const customStyles = {
    rows: {
      style: {
        // maxWidth: 50,
        minHeight: "30px", // override the row height
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        backgroundColor: "#319ef0a3",
        minHeight: "40px",
        fontSize: 16,
        fontWeight: "600",
      },
    },
    headCells: {
      style: {
        // paddingLeft: '8px', // override the cell padding for head cells
        // paddingRight: '8px',
        justifyContent: "center",
        minHeight: "20px",

        // borderRightWidth: 2,
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          // fontSize: 16,
          // fontWeight: '600',
          // borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
        justifyContent: "center",

        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          fontSize: 16,
          fontWeight: "400",
          textTransform: "Capitalize",

          // borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };
  const caseInsensitiveSort = (rowA, rowB) => {
    const a = rowA.country.toLowerCase();
    const b = rowB.country.toLowerCase();

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };

  // filterData.forEach((id, index) => { filterData.id = index + 1; });
  const flows = "03min";
  const pressures = "10pa";
  const statuses = "Open";
  const columns = [
    {
      name: "Valve",
      selector: (row) => row.index,
      selector: (row) => row.name,
      sortable: true,
      width: "100px",
    },
    {
      name: "Status",
      // selector: row => statuses,
      // sortable: true,
      // sortFunction: caseInsensitiveSort,
      width: "100px",
      cell: () => (
        <View style={{ flexDirection: "row" }}>
          <Text>Open</Text>
          <Icon color="#18CE00" name="circle" size={20} />
        </View>
      ),
    },

    {
      name: "Flow",
      width: "100px",
      //   cell: (item, index) =>
      selector: (row) => flows,
    },
    {
      name: "Pressure",
      width: "100px",
      selector: (row) => pressures,
    },
  ];

  //Get details when click on omega id
  const getValveOmegaDetails = async (deviceId) => {
    setIsLoadingSync(true);
    setMapViewPopupDetails({})
    try {
      var OmegaDetails = JSON.stringify({
        project_id: projectId,
        device_id: deviceId,
      });
      const OmegaDetailsResponse = await postData(
        OmegaDetails,
        "masters/getUnitStatusAPI"
      );

      setIsLoadingSync(false);
      var code = OmegaDetailsResponse.code;
      if (code == 1) {
        setShowPopover(OmegaDetailsResponse.data[0].omega_id == deviceId ? true : false);
        setShowIsOnline(OmegaDetailsResponse.data[0].isonline);
        setDigitalInputDetails(OmegaDetailsResponse.data[0].watermeter);
        setMapViewPopupDetails(OmegaDetailsResponse.data[0])
      } else {
        setIsLoadingSync(false);
      }
    } catch (error) {
      setIsLoadingSync(false);
      // console.log(error);
    }
  };
  //Get Dependent omega  by project id
  const getOmegaDependentByProjectId = async (project_id) => {
    setIsLoading(true);
    try {
      var OmegaIdData = JSON.stringify({
        column: "project_id",
        value: project_id,
        module: "devices_master",
      });
      const OmegaIdList = await postData(
        OmegaIdData,
        "masters/mapDependentList"
        // "masters/getOmegaFromBermad"
      );
      setIsLoading(false);
      var code = OmegaIdList.code;
      if (code == 1) {
        // console.log("Bermad schduelar omega id in mapview", OmegaIdList.data);
        var latitude = 0;
        var longnitude = 0;
        for (let i = 0; i < OmegaIdList.data.length; ++i) {
          latitude += Number(OmegaIdList.data[i].lat);
          longnitude += Number(OmegaIdList.data[i].long);
        }
        // console.log("lat and long average", latitude, "&&&", longnitude);

        latitude /= OmegaIdList.data.length;
        longnitude /= OmegaIdList.data.length;

        // console.log("lat and long center point now", latitude, longnitude);
        setCenterLat(latitude);
        setCenterLong(longnitude);
        setCenterLatLong({
          lat: latitude,
          lng: longnitude,
        });
        defaultProps = {
          center: {
            // lat: 22.021671815,
            // lng: 76.86369279
            lat: latitude,
            lng: longnitude,
          },
          zoom: 6,
        };
        // defaultProps.center.lat = latitude;
        // defaultProps.center.lng = longnitude;
        setOmegaIdData(OmegaIdList.data);
      } else {
        setIsLoading(false);
        // //alert(OmegaIdList.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };
  // var markerCluster = new MarkerClusterer(map, clusterMarkers,
  //   {
  //     imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'
  //   });

  const getMapOptions = (maps: Maps) => {
    return {
      streetViewControl: true,
      scaleControl: true,
      fullscreenControl: true,
      // styles: [{
      //     featureType: "poi.business",
      //     elementType: "labels",
      //     stylers: [{
      //         visibility: "off"
      //     }]
      // }],
      gestureHandling: "greedy",
      disableDoubleClickZoom: false,
      // minZoom: 15,
      // maxZoom: 22,

      mapTypeControl: true,
      mapTypeId: maps.MapTypeId.SATELLITE,
      mapTypeControlOptions: {
        style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: maps.ControlPosition.BOTTOM_CENTER,
        mapTypeIds: [
          maps.MapTypeId.ROADMAP,
          maps.MapTypeId.SATELLITE,
          maps.MapTypeId.HYBRID,
        ],
      },

      zoomControl: true,
      clickableIcons: true,
    };
  };
  const setGoogleMapRef = (map: any, maps: any) => {
    // googleMapRef = map

    let locations = [
      { lat: -31.56391, lng: 147.154312 },
      { lat: -33.718234, lng: 150.363181 },
      { lat: -33.727111, lng: 150.371124 },
    ];
    let markers =
      locations &&
      locations.map((location) => {
        return new maps.Marker({ position: location.position });
      });
    let markerCluster = new MarkerClusterer(map, markers, {
      imagePath: "https://googlemaps.github.io/js-marker-clusterer/images/m",
      gridSize: 10,
      minimumClusterSize: 2,
    });
  };

  const AnyReactComponent = ({ text, id, omega_id, active, isOnline }) => (
    <View
      style={{
        flexDirection: "column",
        // alignItems: "center",
        // justifyContent: "center",
      }}
    >
      <DoubleClick
        singleTap={() => {
          // console.log("single tap");
          setOmegaIdGet(omega_id),
            getValveOmegaDetails(omega_id),
            setIsVisible(true);
        }}
        doubleTap={() => {
          // console.log("double tap");
          setIsVisible(false);
          navigation.navigate("ValveView", { OmegaId: omega_id });
        }}
        delay={200}
      >
        <Image
          style={{
            height: 50,
            width: 50,
            zIndex: 1,
            resizeMode: "contain",
          }}
          source={require("../../assets/images/omega.png")}
        />
        {(isOnline == null || false) && (
          <View>
            <Ring delay={0} />
            <Ring delay={1000} />
            <Ring delay={2000} />
            <Ring delay={3000} />
            <Ring delay={4000} />
          </View>
        )}
        <View style={{ width: "200px", flexDirection: "row" }}>
          <Text
            style={{
              backgroundColor: "#fff",
              color: "black",
              padding: 0,
              margin: 0,
              textAlign: "center",
            }}
          >
            {text}
          </Text>
        </View>
      </DoubleClick>

      {omega_id == OmegaIdGet && (
        <Modal
          style={{ zIndex: 999, backgroundColor: "red" }}
          onRequestClose={() => setIsVisible(false)}
          transparent={true}
          visible={isVisible}
        >
          <View
            style={[
              styles.contanerSwitch,
              { width: digitalInputDetails.length > 0 ? "30%" : "25%" },
            ]}
          >
            <View style={{ flexDirection: "row" }}>
              <Text
                onPress={() => {
                  setIsVisible(false);
                  navigation.navigate("ValveView", { OmegaId: omega_id });
                }}
                style={{
                  textAlign: "center",
                  padding: 5,
                  color: "blue",
                  flex: 1,
                }}
              >
                {text}
              </Text>
              <TouchableOpacity
                style={{ alignSelf: "flex-end" }}
                onPress={() => setIsVisible(false)}
              >
                <IconCross
                  name="circle-with-cross"
                  size={20}
                  color="#000"
                  style={{ bottom: 5, top: 0 }}
                />
              </TouchableOpacity>
            </View>

            <View style={{ flexDirection: "row", marginBottom: 10 }}>
              {mapViewPopupDetails.valves !== undefined && mapViewPopupDetails.valves !== null && mapViewPopupDetails.valves.some(item => item.state === 'open') && (
                <Image
                  style={{
                    height: 20,
                    width: 40,
                    resizeMode: "contain",
                  }}
                  source={require("../../assets/images/valves.png")}
                />
              )}
              {mapViewPopupDetails.valves !== undefined &&
                mapViewPopupDetails.valves !== null &&
                mapViewPopupDetails.valves.map((item, key) => (
                  <View style={{ flexDirection: "row" }}>
                    {item.state === "open" && (
                      <Text key={key}>{" "}
                        {item.name}</Text>
                    )}

                    {mapViewPopupDetails.valves.length - 1 !== key && item.state === "open" && (
                      <Text key={key}>, </Text>
                    )}
                  </View>

                ))}
            </View>
            <View style={{ flexDirection: "row", marginBottom: 10 }}>
              {mapViewPopupDetails.analogs !== undefined && mapViewPopupDetails.analogs !== null && (
                <Image
                  style={{
                    height: 25,
                    width: 45,
                    resizeMode: "contain",
                  }}
                  source={require("../../assets/images/sensorLogo.png")}
                />
              )}
              {mapViewPopupDetails.analogs !== undefined &&
                mapViewPopupDetails.analogs !== null &&
                mapViewPopupDetails.analogs.map((item, key) => (
                  <View style={{ flexDirection: "row" }}>
                    <Text key={key}>
                      {" "}
                      PT {item.index} : {item.value} {item.unitOfMeasure}
                    </Text>
                    {mapViewPopupDetails.analogs.length - 1 !== key && (
                      <Text key={key}>, </Text>
                    )}
                  </View>
                ))}
            </View>
            <View style={{ flexDirection: "row", marginBottom: 10 }}>
              {mapViewPopupDetails.watermeter !== undefined &&
                mapViewPopupDetails.watermeter !== null &&
                mapViewPopupDetails.watermeter.some(item => item.inputNumber === '1' || item.inputNumber === '2') && (
                  <Image
                    style={{
                      height: 25,
                      width: 45,
                      resizeMode: "contain",
                    }}
                    source={require("../../assets/images/Watermeter.png")}
                  />
                )}

              {mapViewPopupDetails.watermeter !== undefined &&
                mapViewPopupDetails.watermeter !== null && (
                  <>
                    {mapViewPopupDetails.watermeter.map((item, key) => (
                      item.inputNumber === '1' || item.inputNumber === '2' ? (
                        <View style={{ flexDirection: "row" }} key={key}>
                          <Text>
                            {" "}
                            WM {key + 1}
                            {item.value} : {item.flow} m³/h{" "}
                          </Text>
                          {mapViewPopupDetails.watermeter.length > 1 && item.inputNumber === '1' || item.inputNumber === '2' && (
                            <Text>, </Text>
                          )}
                        </View>
                      ) : null
                    ))}
                  </>
                )
              }


            </View>

            <View style={{ flexDirection: "row", marginBottom: 10 }}>
              {mapViewPopupDetails.watermeter !== undefined &&
                mapViewPopupDetails.watermeter !== null &&
                mapViewPopupDetails.watermeter.length > 0 &&
                mapViewPopupDetails.watermeter.some(item => item.inputNumber > "2") && (
                  <Image
                    style={{
                      height: 25,
                      width: 45,
                      resizeMode: "contain",
                    }}
                    source={require("../../assets/images/Digitalinput3-8.jpg")}
                  />
                )}

              {mapViewPopupDetails.watermeter !== undefined &&
                mapViewPopupDetails.watermeter !== null &&
                mapViewPopupDetails.watermeter.length > 0 &&
                mapViewPopupDetails.watermeter.map((item, key) => {
                  return (
                    <View style={{ flexDirection: "row" }}>
                      {item.inputNumber > "2" ? (
                        <Text>
                          {item.name} - {item.status === 0 ? "Close" : "Open"}
                        </Text>
                      ) : null}
                      {mapViewPopupDetails.watermeter.length - 1 !== key && item.inputNumber >= "3" && (
                        <Text key={key}>, </Text>
                      )}
                    </View>
                  );
                })}
            </View>

          </View>
        </Modal>
      )}
      {/* </Popover> */}
    </View>
  );

  const handleHoverMarker = (text, omega_id) => {
    // console.log("text======", text, omega_id == OmegaIdGet, isVisible);

    return (
      omega_id == OmegaIdGet && (
        <Modal
          onRequestClose={() => setIsVisible(false)}
          transparent={true}
          visible={isVisible}
        >
          <View style={{ backgroundColor: "red" }}>
            <Text>HELLOOOOO MODAL</Text>
            <Text style={{ textAlign: "center", padding: 5 }}>{text}</Text>
            <View style={{ flexDirection: "row", marginBottom: 10 }}>
              {mapViewPopupDetails.valves !== undefined &&
                mapViewPopupDetails.valves.map((item, key) => (
                  <View style={{ flexDirection: "row" }}>
                    <Text key={key}> Valve{item.index} </Text>
                    {mapViewPopupDetails.valves.length - 1 !== key && (
                      <Text key={key}>, </Text>
                    )}
                  </View>
                ))}
            </View>
            <View style={{ flexDirection: "row", marginBottom: 10 }}>
              {mapViewPopupDetails.analogs !== undefined &&
                mapViewPopupDetails.analogs.map((item, key) => (
                  <View style={{ flexDirection: "row" }}>
                    <Text key={key}> Analog{item.index} </Text>
                    {mapViewPopupDetails.analogs.length - 1 !== key && (
                      <Text key={key}>, </Text>
                    )}
                  </View>
                ))}
            </View>
            <View style={{ flexDirection: "row", marginBottom: 10 }}>
              {mapViewPopupDetails.watermeter !== undefined &&
                mapViewPopupDetails.watermeter.map((item, key) => (
                  <View style={{ flexDirection: "row" }}>
                    <Text key={key}> Digital{item.flow} </Text>
                    {mapViewPopupDetails.watermeter.length - 1 !== key && (
                      <Text key={key}>, </Text>
                    )}
                  </View>
                ))}
            </View>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                width: "100%",
              }}
            >
              <View
                style={{
                  flex: 1,
                  margin: 10,
                  flexDirection: "row",
                  alignItems: "flex-start",
                }}
              >
                <View style={{ alignItems: "flex-start" }}></View>
                <View style={{ alignItems: "flex-start" }}></View>
              </View>
            </View>
            <TouchableOpacity onPress={() => setShowPopover(false)}>
              <Text
                style={{
                  backgroundColor: "#fff",
                  color: "black",
                  padding: 10,
                  margin: 10,
                  textAlign: "center",
                }}
              >
                dismiss
              </Text>
            </TouchableOpacity>
          </View>
        </Modal>
      )
    );
  };
  return (
    <View style={styles.MainContainer}>
      <View style={{ flexDirection: "row", width: "100%" }}>
        <View style={{ margin: 10 }}>
          <Text style={styles.lableText}>Monitor {">"} Map View</Text>
        </View>
      </View>
      <View style={{ flexDirection: "row", width: "100%", height: "80vh" }}>
        <GoogleMapReact
          // apiKey={'AIzaSyATARbxSEQ3jSYNcKxQLIYc1ZCA3Lf7gK0'}
          bootstrapURLKeys={{ key: "AIzaSyATARbxSEQ3jSYNcKxQLIYc1ZCA3Lf7gK0" }}
          // bootstrapURLKeys={{ key: "" }}
          defaultCenter={centerLatLong}
          center={centerLatLong}
          // defaultCenter={{
          //     lat: centerLat,
          //     lng: centerLong
          // }}
          defaultZoom={6}
          onGoogleApiLoaded={({ map, maps }) => setGoogleMapRef(map, maps)}
          options={getMapOptions}
        // isHovered={isHovered}
        >
        {OmegaIdData.map((item, key) => (
          <AnyReactComponent
            id={item.id}
            omega_id={item.omega_id}
            lat={item.lat}
            lng={item.long}
            text={item.device_name}
            animateImg={item.active}
            tooltip={item.id}
            isOnline={item.isonline}

          // onClick={() => setPopupInfo(item)}
          />
        ))}
        </GoogleMapReact>

        {/* {popupInfo && (
          <Text
            style={{ position: "absolute", top: 0, left: 0, width: "200px" }}
          >
            {popupInfo}
          </Text>
        )} */}
      </View>

      {/* <View
        style={{
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Image
          style={{
            height: 50,
            width: 50,
            resizeMode: "contain",
          }}
          source={require("../../assets/images/omega.png")}
        />
        <Ring delay={0} />
        <Ring delay={1000} />
        <Ring delay={2000} />
        <Ring delay={3000} />
      </View> */}
    </View>
  );
}
const getCircle = (radius, backgroundColor = "gold") => ({
  backgroundColor,
  width: radius * 2,
  height: radius * 2,
  borderRadius: radius,
  position: "absolute",
});

const styles = StyleSheet.create({
  contanerSwitch: {
    zIndex: 999,
    backgroundColor: "white",
    borderColor: "#eee",
    borderRadius: 5,
    alignSelf: "center",
    borderWidth: 1,
    // justifyContent: "center",
    height: "25%",
    margin: "auto",
    padding: 10,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    // marginRight: 10,
    // marginRight: 1,
    //width: "30%",
  },
  ring: {
    position: "absolute",
    width: 30,
    height: 30,
    borderRadius: 40,
    borderColor: "#E75480",
    borderWidth: 10,
  },
  icon: {
    color: "white",
    fontSize: 42,
    marginBottom: 5,
  },
  text: {
    color: "white",
    fontSize: 18,
  },
  circle: getCircle(100),
  midCircle: {
    ...getCircle(75),
    alignItems: "center",
    justifyContent: "center",
  },
  btnTextCancel: {
    color: "#747474",
    fontWeight: "600",
    textAlign: "center",
  },
  map: {
    height: 150,
    margin: 10,
    borderWidth: 1,
    borderColor: "#000000",
  },
  shape: {
    justifyContent: "center",
    height: 250,
    width: 250,
    borderRadius: 25,
    marginRight: 10,
    backgroundColor: "white",
  },
  MainContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    // alignItems: 'center',
    // justifyContent: 'flex-end',
    flex: 1,
    // alignItems: "center",
    // justifyContent: "center",
    padding: 20,
  },
  mapStyle: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  lableText: {
    color: "#484848",
    fontWeight: "700",
    // marginTop: 18,
    // fontSize: 21,
  },
});

function _onRegionChange(region: any) {
  throw new Error("Function not implemented.");
}

function region(region: any) {
  throw new Error("Function not implemented.");
}

function _onRegionChangeComplete(region: any) {
  throw new Error("Function not implemented.");
}
