export const projectHeader = () => {
    return {
        type:'UPDATE'
    }
}

export const projectIdUpdate = () => {
    return {
        type:'IDUPDATE'
    }
}
export const projectIdCreate = () => {
    return {
        type:'IDCREATE'
    }
}

export const projectCodes = () => {
    return {
        type:'CODEPROJECT'
    }
}
export const projectName = () => {
    return {
        type:'NAMEPROJECT'
    }
}