import Header from "../navigation/header";
import {
  StyleSheet,
  TouchableOpacity,
  TextInput as TextInputRN,
  Modal,
  Button,
  Switch,
  FlatList,
  Alert,
  ScrollView,
} from "react-native";
import {
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger,
} from "react-native-popup-menu";
import { useFocusEffect } from "@react-navigation/native";
import { Dropdown } from "react-native-element-dropdown";

import { Text, View } from "../../../components/Themed";
import { RootStackScreenProps } from "../../../types"; // @ts-ignore
import { DataTable } from "react-native-paper";
import Icon from "react-native-vector-icons/AntDesign";
import IconEdit from "react-native-vector-icons/FontAwesome";
import IconEye from "react-native-vector-icons/Entypo";
import React, { useEffect, useState, useMemo, useRef } from "react";
import IconCross from "react-native-vector-icons/Entypo";
import AsyncStorage from "@react-native-async-storage/async-storage";
import AwesomeAlert from "react-native-awesome-alerts";
import { baseUrl, postData } from "../../../networking/api";
import IconCheck from "react-native-vector-icons/MaterialCommunityIcons";
import { Provider as PaperProvider, Checkbox } from "react-native-paper";
import { TextInput } from "react-native-paper";
import SuccessPopup from "../../../components/SuccessPopup";

export default function ProjectLevel({ navigation }) {
  const [isVisible, setIsVisible] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAdd, setisAdd] = useState(false);
  var [counter, setCounter] = useState(1);
  const [iconTick, seticonTick] = useState("checkbox-blank-outline");
  const [havingSubLevel, setHavingSubLevel] = useState(0);
  const [havingDevice, setHavingDevice] = useState(0);
  const [activeStatus, setActiveStatus] = useState(0);
  const [ProjectLevelData, setProjectLevelData] = useState([]);
  const [ProjectTypeNameData, setProjectTypeNameData] = useState([]);
  const [ProjectsData, setProjectData] = useState([]);
  const [projectTypeValue, setProjectTypeValue] = useState("");
  const [projectTypeValId, setProjectTypeValueId] = useState();
  const [error, setError] = useState("");
  const [sequenceErr, setSequenceErr] = useState("");
  const [issuccessVisible, setissuccessVisible] = useState(false);
  const [responsestatus, setresponsestatus] = useState("");
  const [PopupMessage, setPopupMessage] = useState("");

  const textCountryName = useRef("");
  const [countryLength, setCountryLength] = useState(0);
  const [searchData, setSearch] = useState("");
  const [filterData, setFilteredDataSource] = useState([]);

  const [LevelValue, setLevelValue] = useState("");
  const [LevelId, setLevelId] = useState();
  const [isLevelValid, setLevelValid] = useState(false);

  const [SequenceValue, setSequenceValue] = useState("");
  const [isSequenceValId, setSequenceValId] = useState(false);
  const [sort, setsort] = useState(0);
  const textLevel = useRef("");
  const textSequence = useRef("");
  const [editpopup, setEditPopup] = useState(false);
  const [popid, setpopid] = useState("");
  const [popindex, setpopindex] = useState("");

  // const toggleSwitch = () => setIsActive(0);
  const toggleSwitch = () => {
    var tempIndex = ProjectLevelData.findIndex((item) => item.id === popid);

    if (ProjectLevelData[tempIndex].active == 1) {
      ProjectLevelData[tempIndex].active = 0;
      activeProjectLevel(popid, 0);
    } else {
      ProjectLevelData[tempIndex].active = 1;
      activeProjectLevel(popid, 1);
    }
    setProjectLevelData(ProjectLevelData);
  };

  //Pagination
  const optionsPerPage = [10, 25, 50, 100];

  const [page, setPage] = React.useState(0);
  const [itemsPerPage, setItemsPerPage] = React.useState(optionsPerPage[0]);
  const from = page * itemsPerPage;
  const to = Math.min((page + 1) * itemsPerPage, countryLength);
  React.useEffect(() => {
    setPage(0);
    setpopindex("");
    setEditPopup(false);
    setpopid("");
  }, [itemsPerPage]);
  const isCreatable = () => {
    setProjectTypeValue("");
    setProjectTypeValueId;
    setLevelValue("");
    setSequenceValue("");
    setHavingDevice(0);
    setHavingSubLevel(0);
    setActiveStatus(0);
    setIsVisible(true);
    setisAdd(true);
  };
  useFocusEffect(
    React.useCallback(() => {
      getProjectLevelData([]);
    }, [])
  );
  const isAddable = (
    LevelId,
    project_type_id,
    project_type_name,
    level_title,
    deviceRequired,
    sub_level,
    sequence,
    active
  ) => {
    setIsVisible(true);
    setisAdd(false);
    // getProjectLevelDataById(countryId);
    // setCountryByName(country_name);
    // textCountryName.current = country_name;

    setLevelId(LevelId);
    setProjectTypeValueId(project_type_id);
    setProjectTypeValue(project_type_name);
    setLevelValue(level_title);
    setSequenceValue(sequence);
    setHavingDevice(deviceRequired);
    setHavingSubLevel(sub_level);
    setActiveStatus(active);
  };
  const searchFilterFunction = (text) => {
    // Check if searched text is not blank
    if (text) {
      // Inserted text is not blank
      // Filter the masterDataSource
      // Update FilteredDataSource
      const newData = ProjectLevelData.filter(function (item) {
        const itemData = item.project_type.type
          ? item.project_type.type.toUpperCase()
          : "".toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      // console.log("new data search---->", newData);
      setFilteredDataSource(newData);
      setSearch(text);
      setPage(0);
    } else {
      // Inserted text is blank
      // Update FilteredDataSource with masterDataSource
      setFilteredDataSource(ProjectLevelData);
      setSearch(text);
      setPage(0);
    }
  };

  const _renderItem = (item, index) => (
    <DataTable.Row style={{ flex: 1, minHeight: 35 }} key={item.id}>
      <DataTable.Cell
        style={{
          flex: 0.2,
          borderRightWidth: 0.5,
          borderColor: "grey",
          justifyContent: "center",
        }}
      >
        <Text style={styles.tableHeader}>{counter++}</Text>
      </DataTable.Cell>
      <DataTable.Cell
        style={{
          flex: 0.2,
          borderRightWidth: 0.5,
          borderColor: "grey",
          justifyContent: "center",
        }}
      >
        <Text style={styles.tableHeader}>{item.project_type.type}</Text>
      </DataTable.Cell>
      <DataTable.Cell
        style={{
          flex: 0.2,
          borderRightWidth: 0.5,
          borderColor: "grey",
          justifyContent: "center",
        }}
      >
        <Text style={styles.tableHeader}>{item.level_title}</Text>
      </DataTable.Cell>
      <DataTable.Cell
        style={{
          flex: 0.2,
          borderRightWidth: 0.5,
          borderColor: "grey",
          justifyContent: "center",
        }}
      >
        <Text style={styles.tableHeader}>{item.sequence}</Text>
      </DataTable.Cell>
      <DataTable.Cell
        style={{
          flex: 0.2,
          borderRightWidth: 0.5,
          borderColor: "grey",
          justifyContent: "center",
        }}
      >
        <Text style={styles.tableHeader}>
          {item.is_device_required == 1 ? "Yes" : "No"}
        </Text>
      </DataTable.Cell>
      <DataTable.Cell
        style={{
          flex: 0.2,
          borderRightWidth: 0.5,
          borderColor: "grey",
          justifyContent: "center",
        }}
      >
        <Text style={styles.tableHeader}>
          {item.is_sub_level == 1 ? "Yes" : "No"}
        </Text>
      </DataTable.Cell>
      <DataTable.Cell
        style={{
          flex: 0.2,
          borderRightWidth: 0.5,
          borderColor: "grey",
          justifyContent: "center",
        }}
      >
        <Text style={styles.tableHeader}>
          {item.parent_id == 1 ? "Yes" : "No"}
        </Text>
      </DataTable.Cell>

      <DataTable.Cell
        style={{
          flex: 0.2,
          borderRightWidth: 0.5,
          borderColor: "grey",
          justifyContent: "center",
        }}
        numeric
      >
        {" "}
        <TouchableOpacity
          onPress={() => {
            // console.log("item---VIEW", item);
            navigation.navigate("ProjectLevelView", { levelId: item.id });
          }}
          style={{ marginRight: 10 }}
        >
          <IconEye
            name="eye"
            size={13}
            color="#fff"
            style={{
              padding: 6,
              backgroundColor: "#08588E",
              borderRadius: 12,
            }}
          />
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() =>
            isAddable(
              item.id,
              item.project_type_id,
              item.project_type.type,
              item.level_title,
              item.is_device_required,
              item.is_sub_level,
              item.sequence,
              item.active
            )
          }
        >
          <IconEdit
            name="pencil"
            size={13}
            color="#fff"
            style={{
              padding: 6,
              backgroundColor: "#08588E",
              borderRadius: 12,
            }}
          />
        </TouchableOpacity>
      </DataTable.Cell>
      <DataTable.Cell style={{ flex: 0.2, justifyContent: "center" }} numeric>
        <Switch
          trackColor={{ false: "#767577", true: "#12DFC3" }}
          thumbColor={item.active == 1 ? "#fff" : "#f4f3f4"}
          onValueChange={() => {
            setEditPopup(true);
            setpopid(item.id);
            setpopindex(index);
          }}
          value={item.active == 1 ? true : false}
        />
      </DataTable.Cell>
    </DataTable.Row>
  );

  var alpha_pattern = new RegExp(/^[a-zA-Z]+(\s[a-zA-Z]+)?$/);
  // var alpha_pattern = new RegExp(/^[a-zA-Z]+(\s[a-zA-Z]+)?$/);
  var number_regex = new RegExp(/^[0-9]*$/);

  // var alpha_pattern = new RegExp(/^(?!\s*$).+/);

  useFocusEffect(
    React.useCallback(() => {
      getProjectTypeList();
    }, [])
  );

  const textToLevel = (text) => {
    textLevel.current = text.nativeEvent.text;
    setLevelValue(text.nativeEvent.text);
  };

  const showLevel = () => {
    return textLevel.current;
  };
  const textToSequence = (text) => {
    textSequence.current = text.nativeEvent.text;
    setSequenceValue(text.nativeEvent.text);
  };
  const showSequence = () => {
    return textSequence.current;
  };

  const CheckEnteredData = async () => {
    var levels = "";
    var sequences = "";

    levels = showLevel() !== "" ? showLevel() : LevelValue;
    sequences = showSequence() !== "" ? showSequence() : SequenceValue;
    // console.log("levels", levels, "sequences", sequences);
    //setUserType(userTypes);
    if (levels === "") {
      // //alert("pls enter");
      setError("Please enter level");
    } else if (sequences === "") {
      setSequenceErr("Please enter sequence ");
    } else if (alpha_pattern.test(levels) && number_regex.test(sequences)) {
      if (isAdd == true) {
        setError("");
        setSequenceErr("");
        createProjectlevel(levels, sequences);
        setIsVisible(false);
      } else {
        setError("");
        setSequenceErr("");
        // console.log("update api calling");
        updateProjectLevelData(levels, sequences);
        setIsVisible(false);
      }
    } else {
      // console.log("falseśśś");
    }

    // navigation.replace('DrawerNavigation');
  };

  //Level Configurator  Add Api
  const createProjectlevel = async (levelTitle, sequence) => {
    // console.log("===========createProjectlevel-----------");
    setIsLoading(true);
    try {
      var levelprojectData = JSON.stringify({
        module: "project_type_level",
        data: {
          project_type_id: projectTypeValId,
          level_title: levelTitle,
          is_device_required: havingDevice,
          is_sub_level: havingSubLevel,
          is_child: 0,
          sequence: sequence,
          parent_id: 0,
          active: activeStatus,
          created_by: 1,
        },
      });


      const LevelProjectResponse = await postData(
        levelprojectData,
        "masters/masterCreateUpdate"
      );

      setIsLoading(false);

      var code = LevelProjectResponse.code;
      if (code == 1) {
        setPopupMessage("Record Created Successfully");
        setissuccessVisible(true);
        setresponsestatus("success");
        // console.log("level 1");
        setIsVisible(false);
        getProjectLevelData([]);
        // setLevelValue("");
        // setSequenceValue("");
        textLevel.current = "";
        textSequence.current = "";

        // console.log("Added", LevelProjectResponse.data);
      } else {
        setIsLoading(false);
        setissuccessVisible(true);
        setresponsestatus("failed");
        setPopupMessage(LevelProjectResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  const updateProjectLevelData = async (levelTitle, sequence) => {
    setIsLoading(true);
    try {
      var LevelData = JSON.stringify({
        id: LevelId,
        module: "project_type_level",
        data: {
          project_type: projectTypeValue,
          level_title: levelTitle,
          is_device_required: havingDevice,
          is_sub_level: havingSubLevel,
          is_child: 1,
          sequence: sequence,
          parent_id: 0,
          active: activeStatus,
          created_by: 1,
        },
      });
      // console.log("Payload for update level", LevelData);
      const updateLevelRestponse = await postData(
        LevelData,
        "masters/masterCreateUpdate"
      );

      setIsLoading(false);

      var code = updateLevelRestponse.code;
      if (code == 1) {
        setPopupMessage("Record Updated Successfully");
        setissuccessVisible(true);
        setresponsestatus("success");
        // console.log("level update");
        setIsVisible(false);
        getProjectLevelData([]);
        // setLevelId('');

        // setLevelValue("");
        // setSequenceValue("");
        textLevel.current = "";
        textSequence.current = "";

        // console.log("Updated level response", updateLevelRestponse.data);
      } else {
        setIsLoading(false);
        setissuccessVisible(true);
        setresponsestatus("failed");
        setPopupMessage(updateLevelRestponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  // Project Level Get ApiS
  const getProjectLevelData = async (sortdata: never[]) => {
    setIsLoading(true);
    if (sortdata.length > 0) {
      setCountryLength(sortdata.length);
      setProjectLevelData(sortdata);
      setFilteredDataSource(sortdata);
    } else {
      try {
        var LevelData = JSON.stringify({
          module: "project_type_level",
          relation: [
            {
              module: "project_type",
            },
          ],
        });
        const projectlevelRes = await postData(
          LevelData,
          "masters/getMasterList"
        );
        setIsLoading(false);
        var code = projectlevelRes.code;
        // console.log("projectlevelResprojectlevelRes", projectlevelRes);

        if (code == 1) {
          // console.log("projectlevelRes list====>", projectlevelRes.data);
          setCountryLength(projectlevelRes.data.length);
          setProjectLevelData(projectlevelRes.data);
          setFilteredDataSource(projectlevelRes.data);
        } else {
          setIsLoading(false);
          //alert(projectlevelRes.message);
        }
      } catch (error) {
        setIsLoading(false);
        // console.log(error);
      }
    }
  };

  //isActive Country
  const activeProjectLevel = async (level_id, isActive) => {
    setIsLoading(true);
    try {
      var LevelData = JSON.stringify({
        id: level_id,
        module: "project_type_level",
        data: {
          active: isActive,
        },
      });
      const activeProjectLevelResponse = await postData(
        LevelData,
        "masters/masterCreateUpdate"
      );

      setIsLoading(false);

      var code = activeProjectLevelResponse.code;
      if (code == 1) {
        // //alert(activeProjectLevelResponse.message);
        setIsVisible(false);
        getProjectLevelData([]);
        // console.log("status changed", activeProjectLevelResponse.data);
      } else {
        setIsLoading(false);
        // //alert(activeProjectLevelResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };
  // project type Get Api
  const getProjectTypeList = async () => {
    setIsLoading(true);
    try {
      var ProjectTypeData = JSON.stringify({
        module: "project_type",
        relation: [],
      });
      const ProjectTypeList = await postData(
        ProjectTypeData,
        "masters/getMasterList"
      );
      setIsLoading(false);
      var code = ProjectTypeList.code;
      if (code == 1) {
        // console.log("project list api====>", ProjectTypeList.data);
        setProjectTypeNameData(ProjectTypeList.data);
      } else {
        setIsLoading(false);
        //alert(ProjectTypeList.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };
  const renderProjectTypeItem = (item: any) => {
    // console.log("item---value", item.id);
    return (
      <View style={styles.item}>
        <Text style={styles.textItem}>{item.type}</Text>
        {item.id === projectTypeValId && (
          <Icon style={styles.icon} color="black" name="Safety" size={20} />
        )}
      </View>
    );
  };

  const sortFun = () => {
    const sortData = filterData.sort((item, b) =>
      sort == 1
        ? item.project_type.type.localeCompare(b.project_type.type)
        : b.project_type.type.localeCompare(item.project_type.type)
    );
    getProjectLevelData(sortData);
    setFilteredDataSource(sortData);
  };

  return (
    <View style={styles.container}>
      <SuccessPopup
        visible={issuccessVisible}
        PopupMessage={PopupMessage}
        Responsestatus={responsestatus}
        onChange={() => setissuccessVisible(false)}
      />

      <Modal
        onRequestClose={() => setIsVisible(false)}
        transparent
        visible={isVisible}
      >
        <View
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
            backgroundColor: "rgba(100,100,100, 0.5)",
          }}
        >
          <View style={[styles.containeralt]}>
            <TouchableOpacity
              style={{ alignSelf: "flex-end" }}
              onPress={() => setIsVisible(false)}
            >
              <IconCross
                name="circle-with-cross"
                size={20}
                color="#000"
                style={{ bottom: 5, top: 10 }}
              />
            </TouchableOpacity>
            <View style={{ flexDirection: "row", flex: 1 }}>
              <Text style={{ margin: 20 }}>Project Type</Text>
              {isAdd == true && (
                <Dropdown
                  style={styles.dropdown}
                  placeholderStyle={styles.placeholderStyle}
                  selectedTextStyle={styles.selectedTextStyle}
                  inputSearchStyle={styles.inputSearchStyle}
                  iconStyle={styles.iconStyle}
                  data={ProjectTypeNameData}
                  search
                  maxHeight={300}
                  labelField="type"
                  valueField="type"
                  placeholder="Select Project type"
                  searchPlaceholder="Search..."
                  value={projectTypeValue}
                  onChange={(item) => {
                    setProjectTypeValue(item.type);
                    setProjectTypeValueId(item.id);
                    // console.log("value id project type id===>", item.id);
                  }}
                  renderItem={renderProjectTypeItem}
                />
              )}

              {isAdd == false && (
                <Dropdown
                  style={styles.dropdown}
                  placeholderStyle={styles.placeholderStyle}
                  selectedTextStyle={styles.selectedTextStyle}
                  inputSearchStyle={styles.inputSearchStyle}
                  iconStyle={styles.iconStyle}
                  data={ProjectTypeNameData}
                  search
                  maxHeight={300}
                  labelField="type"
                  valueField="type"
                  placeholder="Select Project type"
                  searchPlaceholder="Search..."
                  value={projectTypeValue}
                  onChange={(item) => {
                    setProjectTypeValue(item.type);
                    setProjectTypeValueId(item.id);

                  }}
                  renderItem={renderProjectTypeItem}
                />
              )}
            </View>
            <View style={{ flexDirection: "row", flex: 1 }}>
              <View style={{ width: "30%" }}>
                <Text style={{ margin: 20 }}>Level</Text>
              </View>
              <View>
                {isAdd == true && (
                  <TextInputRN
                    style={styles.input}
                    placeholder=""
                    value={LevelValue}
                    //
                    // autoFocus={true}
                    onChange={textToLevel}
                  // ref={inputEl2}
                  // onSubmitEditing={() => textLevel.current.focus()}

                  // onChangeText={(text) => {
                  //   setLevelValue(text);
                  // }
                  //}
                  />
                )}
              </View>

              <View>
                {isAdd == false && (
                  <TextInputRN
                    style={styles.input}
                    placeholder=""
                    value={LevelValue}
                    key="Edit"
                    autoFocus
                    onChangeText={(text) => {
                      setLevelValue(text);

                      // console.log("Kept LevelValue value:", LevelValue);
                      if (text.length == 0) {
                        setError("Please enter level ");
                      } else if (!alpha_pattern.test(text)) {
                        setError("Please enter character only");
                      } else {
                        setLevelValid(true);
                        setError("");
                      }
                    }}
                  />
                )}
              </View>
              <Text style={[styles.error_text]}>{error}</Text>
            </View>
            <View style={{ flexDirection: "row", flex: 1 }}>
              <View style={{ width: "30%" }}>
                {" "}
                <Text style={{ margin: 20 }}>Sequence</Text>
              </View>
              <View>
                {isAdd == true && (
                  <TextInputRN
                    style={styles.input}
                    placeholder=""
                    value={SequenceValue}
                    //
                    // autoFocus={true}
                    onChange={textToSequence}
                  // onChangeText={(text) => {
                  //   setSequenceValue(text);
                  // }
                  // }
                  />
                )}
              </View>
              <View>
                {isAdd == false && (
                  <TextInputRN
                    style={styles.input}
                    placeholder=""
                    value={SequenceValue}
                    key="Edit"
                    autoFocus
                    onChangeText={(text) => {
                      setSequenceValue(text);
                      setSequenceErr("");
                      // console.log("Kept SequenceValue", SequenceValue);

                      if (text.length == 0) {
                        setSequenceErr("Please enter sequence ");
                      } else if (!number_regex.test(text)) {
                        setSequenceErr("Please enter number only");
                      } else {
                        setSequenceValId(true);
                        setSequenceErr("");
                      }
                    }}
                  />
                )}
              </View>
              <Text style={[styles.error_text]}>{sequenceErr}</Text>
            </View>
            <View
              style={{ flexDirection: "row", alignItems: "center", flex: 1 }}
            >
              <View style={{ width: "30%" }}>
                <Text style={{ margin: 20 }}>Having Sub Level</Text>
              </View>
              {isAdd == true && (
                <Checkbox
                  color="#009DF0"
                  status={havingSubLevel ? "checked" : "unchecked"}
                  onPress={() => {
                    if (!havingSubLevel) {
                      setHavingSubLevel(1);
                    } else {
                      setHavingSubLevel(0);
                    }
                  }}
                />
              )}
              {isAdd == false && (
                <Checkbox
                  color="#009DF0"
                  status={havingSubLevel ? "checked" : "unchecked"}
                  onPress={() => {
                    if (!havingSubLevel) {
                      setHavingSubLevel(1);
                    } else {
                      setHavingSubLevel(0);
                    }
                  }}
                />
              )}
            </View>
            <View style={{ flexDirection: "row", flex: 1 }}>
              <View style={{ width: "30%" }}>
                <Text style={{ margin: 20 }}>Having Device</Text>
              </View>
              {isAdd == true && (
                <Checkbox
                  color="#009DF0"
                  status={havingDevice ? "checked" : "unchecked"}
                  onPress={() => {
                    if (!havingDevice) {
                      setHavingDevice(1);
                    } else {
                      setHavingDevice(0);
                    }
                  }}
                />
              )}
              {isAdd == false && (
                <Checkbox
                  color="#009DF0"
                  status={havingDevice ? "checked" : "unchecked"}
                  onPress={() => {
                    if (!havingDevice) {
                      setHavingDevice(1);
                    } else {
                      setHavingDevice(0);
                    }
                  }}
                />
              )}
            </View>
            <View style={{ flexDirection: "row", flex: 1 }}>
              <View style={{ width: "30%" }}>
                <Text style={{ margin: 20 }}>Active</Text>
              </View>
              {isAdd == true && (
                <Checkbox
                  color="#009DF0"
                  status={activeStatus ? "checked" : "unchecked"}
                  onPress={() => {
                    if (!activeStatus) {
                      setActiveStatus(1);
                    } else {
                      setActiveStatus(0);
                    }
                  }}
                />
              )}
              {isAdd == false && (
                <Checkbox
                  color="#009DF0"
                  status={activeStatus ? "checked" : "unchecked"}
                  onPress={() => {
                    if (!activeStatus) {
                      setActiveStatus(1);
                    } else {
                      setActiveStatus(0);
                    }
                  }}
                />
              )}
            </View>
            {isAdd == true && (
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  marginHorizontal: 20,
                }}
              >
                <TouchableOpacity
                  style={styles.saveBtn}
                  onPress={() => CheckEnteredData()}
                >
                  <Text style={styles.btnText}>Save</Text>
                </TouchableOpacity>

                <TouchableOpacity
                  style={styles.saveBtn}
                  onPress={() => setIsVisible(false)}
                >
                  <Text style={styles.btnText}>Cancel</Text>
                </TouchableOpacity>
              </View>
            )}
            {isAdd == false && (
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  marginHorizontal: 20,
                }}
              >
                <TouchableOpacity
                  style={styles.saveBtn}
                  onPress={() => CheckEnteredData()}
                >
                  <Text style={styles.btnText}>Update</Text>
                </TouchableOpacity>

                <TouchableOpacity
                  style={styles.saveBtn}
                  onPress={() => setIsVisible(false)}
                >
                  <Text style={styles.btnText}>Cancel</Text>
                </TouchableOpacity>
              </View>
            )}
          </View>
        </View>
      </Modal>
      <Modal
        visible={editpopup}
        animationType={"slide"}
        transparent={true}
        onRequestClose={() => {
          setEditPopup(false);
        }}
      >
        <View style={[styles.contanerSwitch]}>
          <TouchableOpacity
            style={{ alignSelf: "flex-end" }}
            onPress={() => setEditPopup(false)}
          >
            <IconCross
              name="circle-with-cross"
              size={20}
              color="#000"
              style={{ bottom: 5, top: 0 }}
            />
          </TouchableOpacity>

          <Text style={[styles.tableHead, { marginBottom: 20 }]}>
            Do You Want Update Status
          </Text>
          <View
            style={{
              flexDirection: "row",
              paddingRight: 10,
              justifyContent: "space-between",
            }}
          >
            <TouchableOpacity
              onPress={() => {
                setEditPopup(false), toggleSwitch();
              }}
              style={styles.saveBtn}
            >
              <Text style={{ color: "white" }}>Yes</Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                setEditPopup(false);
              }}
              style={styles.saveBtn}
            >
              <Text style={{ color: "white" }}>No</Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>

      <View style={{ flexDirection: "row", width: "100%", marginTop: "5%" }}>
        <View style={{ flex: 1, margin: 10 }}>
          <Text style={styles.lableText}>Master {">"} Level Configurator </Text>
        </View>
        <View style={{ alignSelf: "center", alignItems: "center", flex: 2 }}>
          {/* <TouchableOpacity onPress={() => exportCsvZoneData()}><Text>Download CSV</Text></TouchableOpacity> */}
          <TextInput
            style={styles.textInputStyle}
            onChangeText={(text) => searchFilterFunction(text)}
            value={searchData}
            underlineColorAndroid="transparent"
            underlineColor={"white"}
            placeholder="Search Level"
            theme={{
              colors: {
                text: "black",
                primary: "white",
                background: "transparent",
              },
            }}
            left={
              <TextInput.Icon
                name={() => <IconEdit name="search" size={20} color="#000" />} // where <Icon /> is any component from vector-icons or anything else
              />
            }
          />
        </View>
        <View style={{ flex: 1, alignItems: "flex-end" }}>
          <TouchableOpacity
            style={styles.addProjectLevelbtn}
            onPress={() => isCreatable()}
          >
            <Text style={styles.btnText}>
              <Icon name="plus" size={15} color="#fff" /> Create Project Level
            </Text>
          </TouchableOpacity>
        </View>
      </View>

      <View style={[styles.card, { flexGrow: 1 }]}>
        <View
          style={[
            styles.shadowProp,
            {
              backgroundColor: "white",
            },
          ]}
        >
          <View
            style={{
              width: "100%",
              // flex: 1,
              backgroundColor: "#F6F9F9",
            }}
          >
            <View
              style={{
                borderWidth: 1,
                borderColor: "#e3e0e0",
              }}
            >
              <PaperProvider>
                <DataTable>
                  <DataTable.Header style={{ backgroundColor: "#319ef0a3" }}>
                    <DataTable.Title
                      style={{
                        flex: 0.2,
                        borderRightWidth: 0.5,
                        borderColor: "grey",
                        justifyContent: "center",
                      }}
                    >
                      <Text style={styles.tableHead}> Sr.No</Text>
                    </DataTable.Title>
                    <DataTable.Title
                      sortDirection={sort == 0 ? "ascending" : "descending"}
                      onPress={() => {
                        setsort(sort == 0 ? 1 : 0), sortFun();
                      }}
                      style={{
                        flex: 0.2,
                        borderRightWidth: 0.5,
                        borderColor: "grey",
                        justifyContent: "center",
                      }}
                    >
                      <Text style={styles.tableHead}> Project Type</Text>
                    </DataTable.Title>
                    <DataTable.Title
                      style={{
                        flex: 0.2,
                        borderRightWidth: 0.5,
                        borderColor: "grey",
                        justifyContent: "center",
                      }}
                    >
                      <Text style={styles.tableHead}>Level</Text>
                    </DataTable.Title>
                    <DataTable.Title
                      style={{
                        flex: 0.2,
                        borderRightWidth: 0.5,
                        borderColor: "grey",
                        justifyContent: "center",
                      }}
                    >
                      <Text style={styles.tableHead}> Sequence</Text>
                    </DataTable.Title>
                    <DataTable.Title
                      style={{
                        flex: 0.2,
                        borderRightWidth: 0.5,
                        borderColor: "grey",
                        justifyContent: "center",
                      }}
                    >
                      <Text style={styles.tableHead}> Having Device</Text>
                    </DataTable.Title>
                    <DataTable.Title
                      style={{
                        flex: 0.2,
                        borderRightWidth: 0.5,
                        borderColor: "grey",
                        justifyContent: "center",
                      }}
                    >
                      <Text style={styles.tableHead}>
                        {" "}
                        Having Sublevel {""}{" "}
                      </Text>
                    </DataTable.Title>
                    <DataTable.Title
                      style={{
                        flex: 0.2,
                        borderRightWidth: 0.5,
                        borderColor: "grey",
                        justifyContent: "center",
                      }}
                    >
                      <Text style={styles.tableHead}> Parent</Text>
                    </DataTable.Title>
                    <DataTable.Title
                      style={{
                        flex: 0.2,
                        borderRightWidth: 0.5,
                        borderColor: "grey",
                        justifyContent: "center",
                      }}
                      numeric
                    >
                      <Text style={styles.tableHead}>Action</Text>
                    </DataTable.Title>

                    <DataTable.Title
                      style={{ flex: 0.2, justifyContent: "center" }}
                      numeric
                    >
                      <Text style={styles.tableHead}>Status</Text>
                    </DataTable.Title>
                  </DataTable.Header>
                  {/* <FlatList
                scrollEnabled={isScrollEnabled}
                data={CountryName}
                renderItem={_renderItem}
              /> */}

                  {filterData
                    .slice(
                      page * itemsPerPage,
                      page * itemsPerPage + itemsPerPage
                    )
                    .map((row, index) => _renderItem(row, index))}

                  <DataTable.Pagination
                    page={page}
                    numberOfPages={Math.ceil(countryLength / itemsPerPage)}
                    onPageChange={(page) => setPage(page)}
                    label={`${from + 1}-${to} of ${countryLength}`}
                    showFastPaginationControls
                    // optionsPerPage={optionsPerPage}
                    // itemsPerPage={itemsPerPage}
                    // setItemsPerPage={setItemsPerPage}
                    // showFastPagination
                    // optionsLabel={"Rows per page"}
                    numberOfItemsPerPageList={optionsPerPage}
                    numberOfItemsPerPage={itemsPerPage}
                    onItemsPerPageChange={setItemsPerPage}
                    selectPageDropdownLabel={"Rows per page"}
                  />
                </DataTable>
              </PaperProvider>
            </View>
          </View>
        </View>
        {/* </ScrollView> */}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    // marginTop: 350,
    height: "100%",
  },
  itemStyle: {
    padding: 10,
  },
  textInputStyle: {
    height: 35,
    borderWidth: 2,
    paddingLeft: 20,
    borderColor: "#319ef0",
    backgroundColor: "#FFFFFF",
    width: "45%",
    borderRadius: 5,
    fontWeight: "400",
  },
  tableHeader: {
    color: "#000",
    fontSize: 14,
    /* FontFamily: 'Muli' ,*/
    fontWeight: "400",
  },
  containeralt: {
    // alignItems: "center",
    backgroundColor: "white",
    borderColor: "#eee",
    borderRadius: 10,
    borderWidth: 1,
    // justifyContent: "center",
    height: 500,
    margin: "auto",
    padding: 30,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    // marginRight: 10,
    // marginRight: 1,
    width: "60%",
  },
  contanerSwitch: {
    backgroundColor: "white",
    borderColor: "#eee",
    borderRadius: 5,
    alignSelf: "center",
    borderWidth: 1,
    // justifyContent: "center",
    height: "25%",
    margin: "auto",
    padding: 10,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    // marginRight: 10,
    // marginRight: 1,
    width: "30%",
  },
  input: {
    borderColor: "gray",
    width: "100%",
    borderWidth: 1,
    borderRadius: 2,

    padding: 10,
    marginTop: 20,
    marginBottom: 20,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  addProjectLevelbtn: {
    alignItems: "center",
    backgroundColor: "#006EB9",
    padding: 10,
    borderWidth: 1,
    borderColor: "#006EB9",
    width: "65%",
    // marginTop: 20,
  },
  saveBtn: {
    alignItems: "center",
    backgroundColor: "#006EB9",
    padding: 10,
    borderWidth: 1,
    borderColor: "#006EB9",
    width: "30%",
    marginRight: 10,
  },
  btnText: {
    color: "#fff",
    fontWeight: "600",
  },
  card: {
    width: "100%",
    flex: 1,
    marginVertical: 10,
  },
  shadowProp: {
    shadowColor: "#171717",
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 15,
  },
  tableHead: {
    fontSize: 14,
    /* FontFamily: 'Muli' ,*/
    fontWeight: "700",
    color: "#000000",
  },
  // DropDown CSS
  dropdown: {
    borderColor: "#c3c3c3",
    width: "50%",
    borderWidth: 1,
    borderRadius: 2,
    height: 40,

    padding: 10,
    marginTop: 5,
    marginBottom: 5,
    // borderRadius: 10,
    // padding: 12,
    // shadowColor: "#000",
    // shadowOffset: {
    //   width: 0,
    //   height: 1,
    // },
    // shadowOpacity: 0.2,
    // shadowRadius: 1.41,

    // elevation: 2,
  },
  icon: {
    marginRight: 5,
  },
  item: {
    padding: 9,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  textItem: {
    flex: 1,
    fontSize: 16,
  },
  placeholderStyle: {
    fontSize: 16,
  },
  selectedTextStyle: {
    fontSize: 16,
  },
  iconStyle: {
    width: 20,
    height: 20,
  },
  inputSearchStyle: {
    height: 40,
    fontSize: 16,
    outlineStyle: "none",
  },

  // Error message css
  error_text: {
    color: "red",
    fontSize: 14,
    fontWeight: "600",
    width: "70%",
    marginLeft: 0,
    marginBottom: 10,
  },

  link: {
    marginTop: 15,
    paddingVertical: 15,
  },
  linkText: {
    fontSize: 14,
    color: "#2e78b7",
  },
});
