import Header from "../navigation/header";
import {
  StyleSheet,
  TouchableOpacity,
  Modal,
  Button,
  Switch,
  TextInput as TextInputRN,
  FlatList,
  Alert,
  ScrollView,
  SafeAreaView,
  ActivityIndicator,
  Dimensions,
} from "react-native";
import { useFocusEffect } from "@react-navigation/native";
import { Dropdown } from "react-native-element-dropdown";
import { DatePickerModal } from "react-native-paper-dates";

import { Text, View } from "../../components/Themed";
import { RootStackScreenProps } from "../../types"; // @ts-ignore
// import { DataTable } from "react-native-paper";
import Icon from "react-native-vector-icons/AntDesign";
import IconEdit from "react-native-vector-icons/FontAwesome";
import IconEye from "react-native-vector-icons/Entypo";
import React, { useEffect, useState, useMemo, useRef } from "react";
import IconCross from "react-native-vector-icons/Entypo";
import AsyncStorage from "@react-native-async-storage/async-storage";
import AwesomeAlert from "react-native-awesome-alerts";
import { TextInput } from "react-native-paper";
import { baseUrl, postData } from "../../networking/api";
import { Provider as PaperProvider } from "react-native-paper";
import { Item } from "react-native-paper/lib/typescript/components/List/List";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import moment from "moment";

export default function AlertScreen({ navigation }) {
  const [isLoadingSync, setIsLoadingSync] = useState(false);

  const [isVisible, setIsVisible] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [OmegeDeviceIds, setOmegeDeviceId] = useState();
  const [isAdd, setisAdd] = useState(false);
  var [counter, setCounter] = useState(1);
  const [sort, setsort] = useState(0);
  const [editpopup, setEditPopup] = useState(false);
  const [popid, setpopid] = useState("");
  const [popindex, setpopindex] = useState("");
  const [projectId, setProjectId] = useState("");
  const [projectCodeID, setProjectCode] = useState("");

  //omega id dropdown
  const [OmegaIdData, setOmegaIdData] = useState([]);
  const [OmegavalueId, setOmegaValueiD] = useState("");
  const [OmegaId, setOmegaId] = useState("");

  const [OmegaIdValue, setOmegaIdValue] = useState(null);
  const [OmegaError, setOmegaError] = useState("");
  const [isFocus, setIsFocus] = useState(false);

  //alert type dropdown
  const [alertTypeValue, setAlertTypeValue] = useState("");

  // const toggleSwitch = () => setIsActive(0);
  var idProject = useSelector((state) => state.idUpdating);
  var projectCodeId = useSelector((state) => state.projectCodeId);

  const [showAlert, setAlert] = useState(false);
  const [isOmegeDeviceNameValid, setOmegeDeviceNameDataValid] = useState(false);
  const [isOmegeDeviceNameEntered, setOmegeDeviceNameDataEntered] =
    useState(false);
  const [OmegeDeviceName, setOmegeDeviceNameData] = useState([]);
  const [OmegeDeviceByName, setOmegeDeviceByName] = useState("");
  const [error, setError] = useState("");
  const textOmegeDeviceName = useRef("");
  const [OmegeDeviceLength, setOmegeDeviceLength] = useState(0);
  const [searchData, setSearch] = useState("");
  const [filterData, setFilteredDataSource] = useState([]);
  const [alertData, setAlertData] = useState([
    {
      name: "All",
      value: "",
    },
    {
      name: "General Error",
      value: "general_error",
    },
    {
      name: "Reset Reason",
      value: "unit_reset",
    },
    {
      name: "Low Battery",
      value: "low_battery",
    },
    {
      name: "Critical Low Battery",
      value: "critical_low_battery",
    },
    {
      name: "Modem Error",
      value: "modem_error",
    },
    {
      name: "SIM Error",
      value: "sim_error",
    },
    {
      name: "RSSI + Cellular provider",
      value: "cellular_provider_rssi",
    },
    {
      name: "Start FOTA",
      value: "start_fota",
    },
    {
      name: "End FOTA",
      value: "end_fota",
    },
    {
      name: "Modem Connect",
      value: "modem_connect",
    },
    {
      name: "Modem Disconnect",
      value: "modem_disconnect",
    },
    {
      name: "Configuration Error",
      value: "configuration_error",
    },
    {
      name: "Unit Started",
      value: "unit_started",
    },
    {
      name: "Program enqueue",
      value: "program_enqueue",
    },
    {
      name: "Program dequeued",
      value: "program_dequeued",
    },
    {
      name: "Capacitor failure",
      value: "capacitor_failure",
    },
    {
      name: "Battery check",
      value: "battery_voltage",
    },
    {
      name: "Analog Error28",
      value: "analog_error",
    },
    {
      name: "Unit disconnect from the server",
      value: "unit_started",
    },
    {
      name: "Modbus Error",
      value: "modbus_error",
    },
    {
      name: "Open Valve",
      value: "valve_open",
    },
    {
      name: "Close Valve",
      value: "valve_close",
    },
    {
      name: "Open Fertigation",
      value: "fertigation_open",
    },
    {
      name: "Close Fertigation",
      value: "fertigation_close",
    },
  ]);

  const [isPressAlert, setIsPressAlert] = useState(false);
  const [isPressOmega, setIsPressOmega] = useState(false);

  //Error message
  const [FromDateError, setFromDateError] = useState("");
  const [ToDateError, setToDateError] = useState("");

  //Pagination
  const optionsPerPage = [10, 25, 50, 100];

  const [page, setPage] = React.useState(0);
  const [itemsPerPage, setItemsPerPage] = React.useState(optionsPerPage[0]);
  const from = page * itemsPerPage;
  const to = Math.min((page + 1) * itemsPerPage, OmegeDeviceLength);
  React.useEffect(() => {
    setIsPressOmega(true)
    setPage(0);
    setpopindex("");
    setEditPopup(false);
    setpopid("");
  }, [itemsPerPage]);
  const isCreatable = () => {
    setIsVisible(true);
    setisAdd(true);
  };

  // var alpha_pattern = new RegExp(/^[a-zA-Z]+(\s[a-zA-Z]+)?$/);
  var alpha_pattern = new RegExp(/^[a-zA-Z]+(\s[a-zA-Z]+)?$/);
  // var alpha_pattern = new RegExp(/^(?!\s*$).+/);

  const [FromDate, setFromDate] = React.useState<Date | undefined>(new Date());
  const [FromOpen, setFromOpen] = React.useState(false);

  const onDismissFrom = React.useCallback(() => {
    setFromOpen(false);
  }, [setFromOpen]);

  const onConfirmFrom = React.useCallback(
    (params: { date: React.SetStateAction<Date | undefined> }) => {
      setFromOpen(false);
      setFromDateError("");
      setFromDate(params.date);
    },
    [setFromOpen, setFromDate]
  );

  const [ToDate, setToDate] = React.useState<Date | undefined>(new Date());
  const [ToOpen, setToOpen] = React.useState(false);

  const onDismissTo = React.useCallback(() => {
    setToOpen(false);
  }, [setToOpen]);

  const onConfirmTo = React.useCallback(
    (params: { date: React.SetStateAction<Date | undefined> }) => {
      setToOpen(false);
      setToDateError("");
      setToDate(params.date);
    },
    [setToOpen, setToDate]
  );

  const dropdownRefOmegaiD = useRef();
  const closeMenu = () => {
    dropdownRefOmegaiD.current.close();
  };

  useEffect(() => {
    window.addEventListener("scroll", closeMenu);
    return () => window.removeEventListener("scroll", closeMenu);
  }, []);

  useFocusEffect(
    React.useCallback(() => {
      getAdminData();
      // getOmegaDeviceData("");
      setIsPressOmega(true);
      searchFilterFunction("");
    }, [projectCodeId, projectCodeID])
  );

  const getAdminData = async () => {
    let jsonValue = await AsyncStorage.getItem("userData");
    let proCodeId = await AsyncStorage.getItem("projectCode");
    let proCode = JSON.parse(proCodeId);
    setProjectCode(proCode);
    // getOmegaDeviceData("","")
    // console.log("proCodeId:", proCode);
    let projValue = await AsyncStorage.getItem("projectData");

    // console.log("jsonValue & projValue:", jsonValue, projValue, proCodeId);
    if (projValue != null) {
      // console.log("project id select in zone create", projValue, proCodeId);
      setProjectId(projValue);
      getOmegaDependentByProjectId(projValue);
    } else if (jsonValue != null) {
      var userDetailsresponse = JSON.parse(jsonValue);
      // console.log("userDetailsresponse in zone ==>", userDetailsresponse);
      // setloginId(userDetailsresponse.email_id);
      // setProjectId("cl86ts8rj217140xp5138s7iqm")

      setProjectId(userDetailsresponse.project_selected);
      // setEmpCode(userDetailsresponse.employee_code);
      getOmegaDependentByProjectId(userDetailsresponse.project_selected);
    }
  };

  const getDownloadExcel = async () => {
    var fromD = moment(FromDate).format("YYYY-MM-DDT00:00:19+00:00");
    var toD = moment(ToDate).format("YYYY-MM-DDT23:59:00+00:00");
    var omegaIds = OmegaId == "" ? 0 : OmegaId;
    var alertTypes = alertTypeValue == "" ? 0 : alertTypeValue;


    //GET request
    window.open(
      baseUrl +
      "masters/exportSystemLogs/" +
      fromD +
      "/" +
      toD +
      "/" +
      projectCodeID +
      "/" +
      omegaIds +
      "/" +
      alertTypes
    );
    return false;
  };

  const getOmegaDependentByProjectId = async (projectID) => {
    setIsLoading(true);
    try {
      var OmegaIdData = JSON.stringify({
        column: "project_id",
        value: projectID,
        module: "devices_master",
      });
      const OmegaIdList = await postData(
        OmegaIdData,
        "masters/getDependentMasterList"
        // "masters/getOmegaFromBermad"
      );
      setIsLoading(false);
      var code = OmegaIdList.code;
      if (code == 1) {
        // console.log(" Omega list api====>", OmegaIdList.data);
        var mergeData = OmegaIdList.data;
        mergeData.unshift({
          id: 0,
          project_id: projectId,
          omega_id: "",
          device_name: "All",
          lat: "",
          long: "",
          active: 1,
        });
        setOmegaIdData(mergeData);
        setOmegaIdValue("All")
      } else {
        setIsLoading(false);
        // //alert(OmegaIdList.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };
  const renderOmegaIdListItems = (item: any) => {
    return (
      <View style={styles.item}>
        {isAdd == true && (
          <Text style={styles.textItem}>{item.device_name}</Text>
        )}
        {isAdd == false && (
          <Text style={styles.textItem}>{item.device_name}</Text>
        )}
        {item.id === OmegavalueId && (
          <Icon style={styles.icon} color="black" name="Safety" size={20} />
        )}
      </View>
    );
  };
  const renderAlertTypeList = (item: any) => {
    return (
      <View style={styles.item}>
        {isAdd == true && <Text style={styles.textItem}>{item.name}</Text>}
        {isAdd == false && <Text style={styles.textItem}>{item.name}</Text>}
        {item.value === alertTypeValue && (
          <Icon style={styles.icon} color="black" name="Safety" size={20} />
        )}
      </View>
    );
  };
  const searchFilterFunction = (text) => {
    // Check if searched text is not blank
    if (text) {
      // Inserted text is not blank
      // Filter the masterDataSource
      // Update FilteredDataSource
      const newData = OmegeDeviceName.filter(function (item) {
        const itemData = item.device_name
          ? item.device_name.toUpperCase()
          : "".toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      // console.log("new data search---->", newData);
      setFilteredDataSource(newData);
      setSearch(text);
      setPage(0);
    } else {
      // Inserted text is blank
      // Update FilteredDataSource with masterDataSource
      setFilteredDataSource(OmegeDeviceName);
      setSearch(text);
      setPage(0);
    }
  };
  var counter = 1;
  const customStyles = {
    rows: {
      style: {
        // maxWidth: 50,
        minHeight: "30px", // override the row height
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        backgroundColor: "#319ef0a3",
        minHeight: "40px",
        fontSize: 16,
        fontWeight: "600",
      },
    },
    headCells: {
      style: {
        // paddingLeft: '8px', // override the cell padding for head cells
        // paddingRight: '8px',
        justifyContent: "center",
        minHeight: "20px",

        // borderRightWidth: 2,
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          // fontSize: 16,
          // fontWeight: '600',
          // borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
        justifyContent: "center",

        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          fontSize: 16,
          fontWeight: "400",
          textTransform: "Capitalize",

          // borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };
  const caseInsensitiveSort = (rowA, rowB) => {
    const a = rowA.OmegeDevice.toLowerCase();
    const b = rowB.OmegeDevice.toLowerCase();

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };

  // filterData.forEach((id, index) => { filterData.id = index + 1; });

  const columnsAlert = [
    {
      name: "Alert Type",
      selector: (row) => row.index,
      // //cell: (row, index) => index + 1,
      // sortable: true,
      // width: "100px"
    },
    {
      name: "Device",
      selector: (row) => row.index,
      // //cell: (row, index) => index + 1,
      // sortable: true,
      // width: "100px"
    },
    {
      name: "Project",
      selector: (row) => row.OmegeDevice,
      sortable: true,
      sortFunction: caseInsensitiveSort,
      // width: "100px",
    },
    {
      name: "Timestamp",
      selector: (row) => row.OmegeDevice,
      sortable: true,
      sortFunction: caseInsensitiveSort,
      // width: "100px",
    },
    {
      name: "Status",
      // width: "100px",
      cell: (item, index) => (
        <Switch
          trackColor={{ false: "#767577", true: "#1DA1F2" }}
          thumbColor={item.active == 1 ? "#fff" : "#f4f3f4"}
          activeThumbColor="#fff"
          onValueChange={() => {
            // console.log("toggle index", index);
            setEditPopup(true);
            setpopid(item.id);
            setpopindex(index);
          }}
          value={item.active == 1 ? true : false}
        />
      ),
    },
    {
      name: "Alerts",
      selector: (row) => row.OmegeDevice,
      // width: "100px",
      // sortable: true
      // cell: (item) =>
      //   <TouchableOpacity onPress={() => isAddable(item.id, item.OmegeDevice)}>
      //     <IconEdit
      //       name="pencil"
      //       size={13}
      //       color="#fff"
      //       style={{
      //         padding: 6,
      //         backgroundColor: "#08588E",
      //         borderRadius: 12,
      //       }}
      //     />
      //   </TouchableOpacity>
    },
  ];
  const columnsOmega = [
    {
      name: "Date",
      selector: (row) => {
        return `${row.datetimelocal.split("T")[0]} ${row.datetimelocal.split("T")[1].split("+")[0]}`
      },
      sortable: true,
      sortFunction: caseInsensitiveSort,
      // width: "100px",
    },

    {
      name: "Omega name",
      selector: (row) => row.unit_name,
      // //cell: (row, index) => index + 1,
      // sortable: true,
      // width: "100px"
    },
    {
      name: "Event",
      selector: (row) => row.line,
      sortable: true,
      sortFunction: caseInsensitiveSort,
      // width: "100px",
    },

    // {
    //   name: "Status",
    //   cell: () =>
    //     <View style={{ flexDirection: 'row' }}>
    //       <Text>Unresponsive</Text>
    //       {/* <Icon color="#18CE00" name="circle" size={20} /> */}
    //     </View>,

    // },
    {
      name: "Data",
      selector: (row) => row.key + ":" + row.value,
      // width: "100px",
      // sortable: true
      // cell: (item) =>
      //   <TouchableOpacity onPress={() => isAddable(item.id, item.OmegeDevice)}>
      //     <IconEdit
      //       name="pencil"
      //       size={13}
      //       color="#fff"
      //       style={{
      //         padding: 6,
      //         backgroundColor: "#08588E",
      //         borderRadius: 12,
      //       }}
      //     />
      //   </TouchableOpacity>
    },
  ];

  // useEffect(() => {
  //   // console.log("OmegaId====", OmegaId);
  //   getOmegaDeviceData(OmegaId);
  // }, [FromDate, ToDate]);

  useFocusEffect(
    React.useCallback(() => {
      getOmegaDeviceData(OmegaId,alertTypeValue);
      // getOmegaDeviceData("");
    }, [FromDate, ToDate])
  );

  // OmegeDevice Get ApiS
  const getOmegaDeviceData = async (omegaId, alertValue) => {
    var fromD = moment(FromDate).format("YYYY-MM-DD");
    var toD = moment(ToDate).format("YYYY-MM-DD");
    let proCodeId = await AsyncStorage.getItem("projectCode");
    let proCode = JSON.parse(proCodeId);
    setIsLoadingSync(true);
    try {
      var OmegeDeviceData = JSON.stringify({
        from_date: fromD,
        to_date: toD,
        unit_id: omegaId,
        project_id: proCode,
        alert_type: alertValue == null ? alertTypeValue : alertValue ,
      });
      // console.log("getlogsystem api payload", OmegeDeviceData);

      const OmegeDeviceNameResponse = await postData(
        OmegeDeviceData,
        "masters/getLogsSystem"
      );
      setIsLoadingSync(false)
      var code = OmegeDeviceNameResponse.code;
      if (code == 1) {

        // setIsPress//alert(false),
        // setIsLoadingSync(true);
        setIsPressOmega(true);
        setOmegeDeviceNameData(OmegeDeviceNameResponse.data);
        console.log("OmegeDeviceNameResponse.data)", OmegeDeviceNameResponse.data);

        // setFilteredDataSource(OmegeDeviceNameResponse.data);
      } else {
        setIsLoadingSync(false);
        setOmegeDeviceNameData([])
        // alert(OmegeDeviceNameResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };
  const screenwidth = Dimensions.get('window').width
  const isMobile = screenwidth < 768;
  return (
    <View style={isMobile ? {
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      paddingTop: 75,
      marginTop: 50,
      height: "100%",
    } : styles.container}>
      <View style={isMobile ? { flexDirection: "row", width: "100%", marginTop: "10%" } : { flexDirection: "row", width: "100%", marginTop: "10%" }}>
        <View style={isMobile ? { marginLeft: 10, paddingTop: -20 } : { margin: 10 }}>
          <Text style={isMobile ? [styles.lableText, { paddingTop: -20 }] : styles.lableText}>Alerts </Text>
        </View>
      </View>
      <View
        style={isMobile ? {
          flexDirection: "row",
          width: "100%",
          marginTop: "5%",
          marginLeft: 20,
        } : {
          flexDirection: "row",
          width: "100%",
          marginTop: "5%",
          marginLeft: 20,
        }}
      >
        {/* <TouchableOpacity
            // style={styles.alertBtn}
            style={isPressAlert ? styles.alertBtn : styles.alertBtnAfter}
            onPress={() => { setIsPress//alert(true), setIsPressOmega(false) }}

          >
            <Text style={isPressAlert ? styles.btnText : styles.btnTextAfter} >
              Alert Type
            </Text>
          </TouchableOpacity> */}
        <View style={isMobile ? { width: "38%", marginLeft: 10 } : { width: "17%" }}>
          <Text>From Date</Text>
          <View style={{ flexDirection: "column", marginTop: 7 }}>
            <Text style={styles.input} onPress={() => setFromOpen(true)}>
              {/* {moment(FromDate).format("DD/MM/YYYY") ==
              moment(new Date()).format("DD/MM/YYYY")
                ? "From Date "
                : 
                moment(FromDate).format("DD/MM/YYYY")
                } */}
              {isMobile ? moment(FromDate).format("DD/MM/YY") : moment(FromDate).format("DD/MM/YYYY")}
            </Text>
            <Text style={styles.error_text}>{FromDateError}</Text>
          </View>
        </View>

        {/* <TouchableOpacity></TouchableOpacity> */}
        {/* <Button uppercase={false} mode="outlined">
                Pick single date
              </Button> */}
        <DatePickerModal
          locale="en"
          mode="single"
          visible={FromOpen}
          onDismiss={onDismissFrom}
          date={FromDate}
          onConfirm={onConfirmFrom}
          validRange={{
            endDate: new Date(), // optional
          }}
          // onChange={} // same props as onConfirm but triggered without confirmed by user
          // saveLabel="Save" // optional
          // saveLabelDisabled={true} // optional, default is false
          // uppercase={false} // optional, default is true
          label="Select date" // optional
          // animationType="slide" // optional, default is 'slide' on ios/android and 'none' on web
          // startYear={2000} // optional, default is 1800
          // endYear={2100} // optional, default is 2200
          closeIcon="close" // optional, default is "close"
          // editIcon="pencil" // optional, default is "pencil"
          calendarIcon="calendar" // optional, default is "calendar"
        />
        <View style={isMobile ? { width: "38%", marginLeft: -20 } : { width: "17%" }}>
          <Text>To Date</Text>
          <View style={{ flexDirection: "column", marginTop: 7 }}>
            <Text style={styles.input} onPress={() => setToOpen(true)}>
              {/* {moment(ToDate).format("DD/MM/YYYY") ==
              moment(new Date()).format("DD/MM/YYYY")
                ? "To Date "
                : moment(ToDate).format("DD/MM/YYYY")} */}
              {isMobile ? moment(ToDate).format("DD/MM/YY") : moment(ToDate).format("DD/MM/YYYY")}
            </Text>
            <Text style={styles.error_text}>{ToDateError}</Text>
          </View>
        </View>
        {/* <TouchableOpacity></TouchableOpacity> */}
        {/* <Button uppercase={false} mode="outlined">
                Pick single date
              </Button> */}
        <DatePickerModal
          locale="en"
          mode="single"
          visible={ToOpen}
          onDismiss={onDismissTo}
          date={ToDate}
          onConfirm={onConfirmTo}
          validRange={{
            endDate: new Date(), // optional
          }}
          // onChange={} // same props as onConfirm but triggered without confirmed by user
          // saveLabel="Save" // optional
          // saveLabelDisabled={true} // optional, default is false
          // uppercase={false} // optional, default is true
          label="Select date" // optional
          // animationType="slide" // optional, default is 'slide' on ios/android and 'none' on web
          // startYear={2000} // optional, default is 1800
          // endYear={2100} // optional, default is 2200
          closeIcon="close" // optional, default is "close"
          // editIcon="pencil" // optional, default is "pencil"
          calendarIcon="calendar" // optional, default is "calendar"
        />
        <View style={isMobile ? { width: "35%", marginLeft: -20 } : { width: "17%" }}>
          <Text>Select Event</Text>
          <Dropdown
            ref={dropdownRefOmegaiD}
            style={styles.dropdown}
            placeholderStyle={styles.placeholderStyle}
            selectedTextStyle={styles.selectedTextStyle}
            inputSearchStyle={styles.inputSearchStyle}
            iconStyle={styles.iconStyle}
            data={alertData}
            search
            maxHeight={300}
            labelField="name"
            valueField="value"
            placeholder={alertTypeValue ? alertTypeValue : "Event"}
            searchPlaceholder="Search..."
            value={alertTypeValue}
            onChange={(item) => {
              setAlertTypeValue(item.value);
              // setOmegaValueiD(item.id);
              getOmegaDeviceData(OmegaId, item.value);
              // setIsPress//alert(true), setIsPressOmega(false);
              // console.log("alert type value---", item.value);
            }}
            renderItem={renderAlertTypeList}
          />
        </View>

        {!isMobile && (
          <View style={{ width: "17%", marginLeft: 10 }}>
            <Text>Select Omega</Text>
            <Dropdown
              ref={dropdownRefOmegaiD}
              style={styles.dropdown}
              placeholderStyle={styles.placeholderStyle}
              selectedTextStyle={styles.selectedTextStyle}
              inputSearchStyle={styles.inputSearchStyle}
              iconStyle={styles.iconStyle}
              data={OmegaIdData}
              search
              maxHeight={300}
              labelField="device_name"
              valueField="device_name"
              placeholder={!isFocus ? "Omega Device" : "...."}
              searchPlaceholder="Search..."
              value={OmegaIdValue}
              onChange={(item) => {
                setOmegaIdValue(item.device_name);
                setOmegaValueiD(item.id);
                if (FromDate === undefined && ToDate === undefined) {
                  setFromDateError("Please select from data");
                  setToDateError("Please select to date");

                } else {
                  setOmegaId(item.omega_id);
                  getOmegaDeviceData(item.omega_id, alertTypeValue);
                  setFromDateError("");
                  setToDateError("");
                  // setIsPress//alert(false), setIsPressOmega(true);
                  // console.log("FromDate && ToDate", FromDate, ToDate);
                }

                // console.log("omega id value---", item.id);
              }}
              renderItem={renderOmegaIdListItems}
            />
          </View>)}

        {!isMobile && (
          <View style={isMobile ? { marginTop: "30%", flex: 1, marginLeft: "10%" } : { alignItems: "flex-end", margin: 27, flex: 1 }}>
            {OmegeDeviceName.length > 0 && (
              <TouchableOpacity
                style={isMobile ? {
                  alignItems: "center",
                  backgroundColor: "#747474",
                  padding: 10,
                  borderWidth: 1,
                  borderColor: "#747474",
                  width: "70%",
                  // marginTop: 20,
                  borderRadius: 10,
                } : styles.alertBtn}
                onPress={() => {
                  getDownloadExcel();
                }}
              >
                <Text style={styles.btnText}>
                  <Icon name="exclefile1" size={15} color="#fff" /> Download
                </Text>
              </TouchableOpacity>
            )}
          </View>)}

      </View>
      {isMobile && (
        <View style={isMobile ? {
          flexDirection: "row", width: "100%",
          marginLeft: 40,
        } : null}>
          <View style={isMobile ? { width: "40%" } : { width: "17%", marginLeft: 10 }}>
            <Text>Select Omega</Text>
            <Dropdown
              ref={dropdownRefOmegaiD}
              style={styles.dropdown}
              placeholderStyle={styles.placeholderStyle}
              selectedTextStyle={styles.selectedTextStyle}
              inputSearchStyle={styles.inputSearchStyle}
              iconStyle={styles.iconStyle}
              data={OmegaIdData}
              search
              maxHeight={300}
              labelField="device_name"
              valueField="device_name"
              placeholder={!isFocus ? "Omega Device" : "...."}
              searchPlaceholder="Search..."
              value={OmegaIdValue}
              onChange={(item) => {
                setOmegaIdValue(item.device_name);
                setOmegaValueiD(item.id);
                if (FromDate === undefined && ToDate === undefined) {
                  setFromDateError("Please select from data");
                  setToDateError("Please select to date");

                } else {
                  setOmegaId(item.omega_id);
                  getOmegaDeviceData(item.omega_id, alertTypeValue);
                  setFromDateError("");
                  setToDateError("");
                  // setIsPress//alert(false), setIsPressOmega(true);
                  // console.log("FromDate && ToDate", FromDate, ToDate);
                }

                // console.log("omega id value---", item.id);
              }}
              renderItem={renderOmegaIdListItems}
            />
          </View>

          <View style={isMobile ? { marginTop: 25, marginLeft: "18%" } : { alignItems: "flex-end", margin: 27, flex: 1 }}>
            {OmegeDeviceName.length > 0 && (
              <TouchableOpacity
                style={isMobile ? {
                  alignItems: "center",
                  backgroundColor: "#747474",
                  padding: 10,
                  borderWidth: 1,
                  borderColor: "#747474",
                  width: "100%",
                  // marginTop: 20,
                  borderRadius: 10,
                } : styles.alertBtn}
                onPress={() => {
                  getDownloadExcel();
                }}
              >
                <Text style={styles.btnText}>
                  <Icon name="exclefile1" size={15} color="#fff" /> Download
                </Text>
              </TouchableOpacity>
            )}
          </View>
        </View>
      )}

      {/* <ScrollView
        style={[styles.card, styles.shadowProp]}
        contentContainerStyle={{ flexGrow: 1 }}
      > */}

      <View style={[styles.card, { flexGrow: 1 }]}>
        <View
          style={[
            styles.shadowProp,
            {
              backgroundColor: "white",
            },
          ]}
        >
          <View
            style={{
              width: "100%",
              // flex: 1,
              backgroundColor: "#F6F9F9",
            }}
          >
            <View
              style={
                {
                  // borderWidth: 1,
                  // borderColor: "#e3e0e0",
                }
              }
            >
              <View>
                {isPressAlert == true && (
                  <DataTable
                    // title="OmegeDevice"
                    columns={columnsAlert}
                    data={filterData}
                    customStyles={customStyles}
                    pagination
                    defaultSortFieldId={"OmegeDevice"}
                    // striped
                    defaultSortAsc={true}
                  />
                )}
                {
                  isLoadingSync ? (
                    <ActivityIndicator
                      //visibility of Overlay Loading Spinner
                      color="#1DA1F2"
                      visible={isLoadingSync}
                      //Text with the Spinner
                      textContent={"Loading..."}
                      size={100}
                    //  //Text style of the Spinner Text
                    //  textStyle={styles.spinnerTextStyle}
                    />
                  ) : (
                    (OmegeDeviceName.length > 0 ?
                      (
                        <DataTable
                          // title="OmegeDevice"
                          columns={columnsOmega}
                          data={OmegeDeviceName}
                          customStyles={customStyles}
                          pagination
                          defaultSortFieldId={"OmegeDevice"}
                          // striped
                          defaultSortAsc={true}
                        />
                      ) : (
                        // valveDetails.length == 0 && (
                        <View style={{ justifyContent: "center", backgroundColor: "#fff" }}>
                          <Text
                            style={[
                              styles.emptyListStyle,
                              { alignSelf: "center", marginTop: 20, color: "#000000" },
                            ]}
                          >
                            No Program data Found{" "}
                          </Text>
                        </View>
                        //
                      )
                    )
                  )}
              </View>
              {/* prvider datatable code here */}

              {/* ended */}
            </View>
          </View>
        </View>
        {/* </ScrollView> */}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    marginTop: "2%",
    height: "100%",
  },
  itemStyle: {
    padding: 10,
  },
  textInputStyle: {
    height: 35,
    borderWidth: 2,
    paddingLeft: 20,
    borderRadius: 5,
    borderColor: "#319ef0",
    backgroundColor: "#FFFFFF",
    width: "40%",
    fontWeight: "400",
  },
  tableHead: {
    fontSize: 14,
    /* FontFamily: 'Muli' ,*/
    fontWeight: "700",
    color: "#000000",
  },

  containeralt: {
    // alignItems: "center",
    backgroundColor: "white",
    borderColor: "#eee",
    borderRadius: 10,
    borderWidth: 1,
    // justifyContent: "center",
    height: 300,
    margin: "auto",
    padding: 30,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    // marginRight: 10,
    // marginRight: 1,
    width: "30%",
  },
  contanerSwitch: {
    backgroundColor: "white",
    borderColor: "#eee",
    borderRadius: 5,
    alignSelf: "center",
    borderWidth: 1,
    // justifyContent: "center",
    height: "25%",
    margin: "auto",
    padding: 10,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    // marginRight: 10,
    // marginRight: 1,
    width: "30%",
  },
  tableHeader: {
    color: "#000",
    fontSize: 14,
    /* FontFamily: 'Muli' ,*/
    fontWeight: "400",
  },
  input: {
    borderColor: "#c3c3c3",
    width: "70%",
    borderWidth: 1,
    borderRadius: 4,
    marginRight: 10,
    padding: 10,
    // marginTop: 4,
    marginBottom: 6,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  alertBtn: {
    alignItems: "center",
    backgroundColor: "#747474",
    padding: 10,
    borderWidth: 1,
    borderColor: "#747474",
    width: "50%",
    // marginTop: 20,
    borderRadius: 10,
  },
  alertBtnAfter: {
    alignItems: "center",
    backgroundColor: "#fff",
    padding: 10,
    borderWidth: 1,
    borderColor: "#747474",
    width: "33%",
    // marginTop: 20,
    borderRadius: 10,
  },
  saveBtn: {
    alignItems: "center",
    backgroundColor: "#006EB9",
    padding: 10,
    borderWidth: 1,
    borderColor: "#006EB9",
    width: "50%",
    marginRight: 10,
  },
  btnText: {
    color: "#fff",
    fontWeight: "600",
  },
  btnTextAfter: {
    color: "#000",
    fontWeight: "600",
  },
  card: {
    width: "70%",
    flex: 1,
    marginVertical: 10,
  },
  shadowProp: {
    shadowColor: "#171717",
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 15,
  },
  // Error message css
  error_text: {
    color: "red",
    fontSize: 14,
    fontWeight: "600",
    width: "70%",
    marginLeft: 0,
    marginBottom: 10,
  },

  link: {
    marginTop: 15,
    paddingVertical: 15,
  },
  linkText: {
    fontSize: 14,
    color: "#2e78b7",
  },

  dropdown: {
    borderColor: "#c3c3c3",
    width: "80%",
    borderWidth: 1,
    borderRadius: 2,
    height: 40,
    marginLeft: 0,
    outlineStyle: "none",
    padding: 10,
    marginTop: 7,
    marginBottom: 5,
    // borderRadius: 10,
    // padding: 12,
    // shadowColor: "#000",
    // shadowOffset: {
    //   width: 0,
    //   height: 1,
    // },
    // shadowOpacity: 0.2,
    // shadowRadius: 1.41,

    // elevation: 2,
  },
  icon: {
    marginRight: 5,
  },
  item: {
    padding: 9,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  textItem: {
    flex: 1,
    fontSize: 16,
  },
  placeholderStyle: {
    fontSize: 16,
  },
  selectedTextStyle: {
    fontSize: 16,
  },
  iconStyle: {
    width: 20,
    height: 20,
  },
  inputSearchStyle: {
    height: 40,
    fontSize: 16,
    outlineStyle: "none",
  },
  lableText: {
    color: "#484848",
    fontWeight: "700",
    marginTop: 18,
    // fontSize: 21,
  },
  emptyListStyle: {
    padding: 20,
    fontSize: 20,
    textAlign: "center",
  },
});
