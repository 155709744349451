import Header from "../navigation/header";
import {
  StyleSheet,
  TouchableOpacity,
  ScrollView,
  Switch,
  Modal,
  Dimensions,
  // TextInput
} from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Text, View } from "../../../components/Themed";
import { jsonToCSV } from "react-native-csv";

import { RootStackScreenProps } from "../../../types"; // @ts-ignore
import React, { useEffect, useState } from "react";
import Icon from "react-native-vector-icons/AntDesign";
import { useFocusEffect } from "@react-navigation/native";
import { postData } from "../../../networking/api";
// import { DataTable } from "react-native-paper";
import DataTable from "react-data-table-component";

import { TextInput } from "react-native-paper";
import { Provider as PaperProvider } from "react-native-paper";
import IconEdit from "react-native-vector-icons/FontAwesome";
import IconCross from "react-native-vector-icons/Entypo";
import IconEye from "react-native-vector-icons/Entypo";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

export default function FarmerProfile({ navigation }) {
  const [text, setText] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  var [counter, setCounter] = useState(1);

  const [projectId, setProjectId] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [FarmerDataLength, setFarmerDataLength] = useState(0);
  const [FarmerNameData, setFarmerNameData] = useState([]);

  const [searchData, setSearch] = useState("");
  const [filterData, setFilteredDataSource] = useState([]);
  const [isFarmerNameDataValid, setFarmerNameDataValid] = useState(false);
  const [isFarmerNameDataEntered, setFarmerNameDataEntered] = useState(false);
  const [sort, setsort] = useState(0);

  const [editpopup, setEditPopup] = useState(false);
  const [popid, setpopid] = useState("");
  const [popindex, setpopindex] = useState("");
  // DATATABLE PROJECT TYPE COLUMN AND STYLE
  const customStyles = {
    rows: {
      style: {
        // maxWidth: 50,
        minHeight: "30px", // override the row height
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        backgroundColor: "#319ef0a3",
        minHeight: "40px",
        fontSize: 16,
        fontWeight: "600",
      },
    },
    headCells: {
      style: {
        // paddingLeft: '8px', // override the cell padding for head cells
        // paddingRight: '8px',
        justifyContent: "center",
        minHeight: "20px",

        // borderRightWidth: 2,
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          // fontSize: 16,
          // fontWeight: '600',
          // borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
        justifyContent: "center",

        "&:not(:last-of-type)": {
          // textTransform: 'Capitalize',
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          fontSize: 16,
          fontWeight: "400",
          // borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };
  const caseInsensitiveSort = (rowA, rowB) => {
    const a = rowA.farmer_name.toLowerCase();
    const b = rowB.farmer_name.toLowerCase();

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };
  const columns = [
    {
      name: "Sr.No",
      selector: (row) => row.index,
      //cell: (row, index) => index + 1,

      // sortable: true,
      width: "100px",
    },
    {
      name: " Name",
      selector: "farmer_name",
      sortable: true,
      sortFunction: caseInsensitiveSort,
      style: { textTransform: "Capitalize" },
    },
    {
      name: "Email",
      selector: "email_id",
      sortable: true,
    },
    {
      name: "Mobile",
      selector: "mobile_number",
      sortable: true,
    },
    {
      name: "Address",
      selector: "address",
      sortable: true,
      style: { textTransform: "Capitalize" },
    },
    {
      name: "No. of Field",
      selector: (row) => (row.field_id_count == null ? "" : row.field_id_count),
      sortable: true,
      // style: { textTransform: 'Capitalize' }
    },
    {
      name: "Area",
      selector: (row) =>
        row.cultivated_area == null ? "" : row.cultivated_area + " h",
      sortable: true,
      // style: { textTransform: 'Capitalize' }
    },
    {
      name: "Water Demand",
      selector: (row) =>
        row.water_demand == null ? "" : row.water_demand + " m³",
      sortable: true,
      // style: { textTransform: 'Capitalize' }
    },
    {
      name: "Action",
      // selector: "country",
      width: "100px",

      // sortable: true
      cell: (item) => (
        <TouchableOpacity
          style={{ marginRight: 10 }}
          onPress={() => {
            // console.log("item---edit", item);
            navigation.navigate("EditFarmerProfile", { farmerId: item.id });
          }}
        >
          <IconEdit
            name="pencil"
            size={13}
            color="#fff"
            style={{
              padding: 6,
              backgroundColor: "#08588E",
              borderRadius: 12,
            }}
          />
        </TouchableOpacity>
      ),
    },
    {
      name: "Status",
      width: "100px",

      cell: (item, index) => (
        <Switch
          trackColor={{ false: "#767577", true: "#1DA1F2" }}
          thumbColor={item.active == 1 ? "#fff" : "#f4f3f4"}
          activeThumbColor="#fff"
          onValueChange={() => {
            // console.log("toggle index", index);
            setEditPopup(true);
            setpopid(item.id);
            setpopindex(index);
          }}
          value={item.active == 1 ? true : false}
        />
      ),
    },
  ];
  const toggleSwitch = () => {
    var tempIndex = FarmerNameData.findIndex((item) => item.id === popid);

    if (FarmerNameData[tempIndex].active == 1) {
      FarmerNameData[tempIndex].active = 0;
      activeFarmer(popid, 0);
    } else {
      FarmerNameData[tempIndex].active = 1;
      activeFarmer(popid, 1);
    }
    setFarmerNameData(FarmerNameData);
  };
  //Pagination
  const optionsPerPage = [10, 25, 50, 100];

  const [page, setPage] = React.useState(0);
  const [itemsPerPage, setItemsPerPage] = React.useState(optionsPerPage[0]);
  const from = page * itemsPerPage;
  const to = Math.min((page + 1) * itemsPerPage, FarmerDataLength);
  React.useEffect(() => {
    setPage(0);
    setpopindex("");
    setEditPopup(false);
    setpopid("");
  }, [itemsPerPage]);

  useFocusEffect(
    React.useCallback(() => {
      getAdminData();
    }, [])
  );

  const getAdminData = async () => {
    let jsonValue = await AsyncStorage.getItem("userData");
    // console.log("jsonValue:", jsonValue);
    let projValue = await AsyncStorage.getItem("projectData");

    // console.log("jsonValue & projValue:", jsonValue, projValue);
    if (projValue != null) {
      // console.log("project id select in subzone create", projValue);
      setProjectId(projValue);
      getAllFarmerData([], projValue);
    } else if (jsonValue != null) {
      var userDetailsresponse = JSON.parse(jsonValue);
      // console.log("userDetailsresponse==>", userDetailsresponse);

      setProjectId(userDetailsresponse.project_selected);
      // console.log("json value id", userDetailsresponse.project_selected);
      getAllFarmerData([], userDetailsresponse.project_selected);
    }
  };

  const searchFilterFunction = (text) => {
    // Check if searched text is not blank
    if (text) {
      // Inserted text is not blank
      // Filter the masterDataSource
      // Update FilteredDataSource
      const newData = FarmerNameData.filter(function (item) {
        const itemData = item.farmer_name
          ? item.farmer_name.toUpperCase()
          : "".toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      // console.log("new data search---->", newData);
      setFilteredDataSource(newData);
      setSearch(text);
      setPage(0);
    } else {
      // Inserted text is blank
      // Update FilteredDataSource with masterDataSource
      setFilteredDataSource(FarmerNameData);
      setSearch(text);
      setPage(0);
    }
  };
  //isActive State
  const activeFarmer = async (fId, isActive) => {
    setIsLoading(true);
    try {
      var farmerData = JSON.stringify({
        id: fId,
        module: "farmer_master",
        data: {
          active: isActive,
        },
      });
      // console.log(" farmer_master listing", farmerData);
      const activeFarmerResponse = await postData(
        farmerData,
        "masters/masterCreateUpdate"
      );

      setIsLoading(false);

      var code = activeFarmerResponse.code;
      if (code == 1) {
        // //alert(activeFarmerResponse.message);
        setIsVisible(false);
        getAllFarmerData([], projectId);
        // console.log("farmer status changed", activeFarmerResponse.data);
      } else {
        setIsLoading(false);
        // //alert(activeFarmerResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };
  const exportCsvFarmerData = async () => {
    setIsLoading(true);
    try {
      var getFarmerData = JSON.stringify({
        module: "farmer_master",
      });
      const FarmerNameResponse = await postData(
        getFarmerData,
        "masters/exportFile"
      );
      setIsLoading(false);
      var code = FarmerNameResponse.code;
      if (code == 1) {
        // console.log("FarmerNameResponse list ====>", FarmerNameResponse.data);

        // setFarmerDataLength(FarmerNameResponse.data.length);
        setFarmerNameData(FarmerNameResponse.data);
        // setFilteredDataSource(FarmerNameResponse.data)
        // const results = jsonToCSV(FarmerNameResponse.data);
        // // console.log("csv==", results);
      } else {
        setIsLoading(false);
        //alert(FarmerNameResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };
  // Get all Farmer list Api
  const getAllFarmerData = async (sortdata: never[], pid) => {
    setIsLoading(true);
    if (sortdata.length > 0) {
      setFarmerDataLength(sortdata.length);
      setFarmerNameData(sortdata);
      setFilteredDataSource(sortdata);
    } else {
      try {
        var getFarmerData = JSON.stringify({
          project_id: pid
        });
        const FarmerNameResponse = await postData(
          getFarmerData,
          "masters/getFarmerProfilelist"
        );
        // console.log("getFarmerData", getFarmerData);
        setIsLoading(false);
        var code = FarmerNameResponse.code;
        if (code == 1) {
          // console.log("FarmerNameResponse list====>", FarmerNameResponse.data);

          setFarmerDataLength(FarmerNameResponse.data.length);
          setFarmerNameData(FarmerNameResponse.data);
          setFilteredDataSource(FarmerNameResponse.data);
          const results = jsonToCSV(FarmerNameResponse.data);
          // console.log("csv==", results);
        } else {
          setIsLoading(false);
          //alert(FarmerNameResponse.message);
        }
      } catch (error) {
        setIsLoading(false);
        // console.log(error);
      }
    }
  };
  const sortFun = () => {
    const sortData = filterData.sort((item, b) =>
      sort == 1
        ? item.farmer_name.localeCompare(b.farmer_name)
        : b.farmer_name.localeCompare(item.farmer_name)
    );
    getAllFarmerData(sortData, projectId);
    setFilteredDataSource(sortData);
  };
  const screenwidth = Dimensions.get('window').width
  const isMobile = screenwidth < 768;

  return (
    <View style={styles.container}>
      <View style={isMobile ? { width: "100%", marginTop: 200 } : { flexDirection: "row", width: "100%", marginTop: "10%" }}>
        <View style={{ flex: 1, margin: 10 }}>
          <Text style={styles.lableText}>Profile {">"} Farmer Profile</Text>
        </View>
        <Modal
          visible={editpopup}
          animationType={"slide"}
          transparent={true}
          onRequestClose={() => {
            setEditPopup(false);
          }}
        >
          <View style={isMobile ? {
            backgroundColor: "white",
            borderColor: "#eee",
            borderRadius: 5,
            alignSelf: "center",
            borderWidth: 1,
            // justifyContent: "center",
            height: "25%",
            margin: "auto",
            padding: 10,
            shadowOffset: {
              width: 0,
              height: 2,
            },
            shadowOpacity: 0.25,
            shadowRadius: 4,
            elevation: 5,
            // marginRight: 10,
            // marginRight: 1,
            width: "60%",
          } : [styles.contanerSwitch]}>
            <TouchableOpacity
              style={{ alignSelf: "flex-end" }}
              onPress={() => setEditPopup(false)}
            >
              <IconCross
                name="circle-with-cross"
                size={20}
                color="#000"
                style={{ bottom: 5, top: 0 }}
              />
            </TouchableOpacity>

            <Text style={[styles.tableHeader, { marginBottom: 20 }]}>
              Do You Want Update Status
            </Text>
            <View
              style={{
                flexDirection: "row",
                paddingRight: 10,
                justifyContent: "space-between",
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  setEditPopup(false), toggleSwitch();
                }}
                style={styles.saveBtn}
              >
                <Text style={{ color: "white" }}>Yes</Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  setEditPopup(false);
                }}
                style={styles.saveBtn}
              >
                <Text style={{ color: "white" }}>No</Text>
              </TouchableOpacity>
            </View>
          </View>
        </Modal>
        <View style={isMobile ? { alignItems: "center", marginTop: 20  , marginLeft:'-35%', width:"170%", flex:2 } : { alignItems: "center", flex: 2 }}>
          <TextInput
            style={isMobile ? {
              height: 35,
              borderWidth: 2,
              paddingLeft: 10,
              borderColor: "#319ef0",
              backgroundColor: "#FFFFFF",
              width: "50%",
              borderRadius: 5,
              color: "#000",
              fontWeight: "400",
            } : styles.textInputStyle}
            onChangeText={(text) => searchFilterFunction(text)}
            value={searchData}
            underlineColorAndroid="transparent"
            underlineColor={"white"}
            placeholder="Search farmers"
            theme={{
              colors: {
                text: "black",
                primary: "white",
                background: "transparent",
              },
            }}
            left={
              <TextInput.Icon
                name={() => <IconEdit name="search" size={20} color="#000" />} // where <Icon /> is any component from vector-icons or anything else
              />
            }
          />
        </View>
        <View style={{ flex: 1, alignItems: "flex-end" }}>
          <TouchableOpacity
            style={isMobile ? {
              alignItems: "center",
              backgroundColor: "#006EB9",
              padding: 10,
              borderWidth: 1,
              borderColor: "#006EB9",
              width: "35%",
              // marginTop: 20,
            } : styles.addDevice}
            onPress={() => {
              navigation.navigate("CreateFarmerProfile");
            }}
          >
            <Text style={styles.btnText}>
              <Icon name="plus" size={15} color="#fff" /> Create
            </Text>
          </TouchableOpacity>
        </View>
      </View>
      <View style={{ flexDirection: "row", width: "100%" }}>
        <View style={{ flex: 1 }}>
          {/* <TouchableOpacity
            style={styles.addDevice}
            // onPress={() => isCreatable()}
          >
            <Text style={styles.btnText}>
              <Icon name="plus" size={15} color="#fff" /> Add Country
            </Text>
          </TouchableOpacity> */}
        </View>
      </View>
      {/* <ScrollView
        style={[styles.card, styles.shadowProp]}
        contentContainerStyle={{ flexGrow: 1 }}
      > */}
      <View style={isMobile ? [styles.card, { flexGrow: 1, marginTop:50, paddingBottom:10 }] : [styles.card, { flexGrow: 1 }]}>
        <View
          style={[
            styles.shadowProp,
            {
              backgroundColor: "white",
            },
          ]}
        >
          <View
            style={{
              width: "100%",
              // flex: 1,
              backgroundColor: "#F6F9F9",
            }}
          >
            <View
              style={isMobile ? {
                borderWidth: 1,
                borderColor: "#e3e0e0",
                width: "100%",
                alignSelf: "center",
              }: {
                borderWidth: 1,
                borderColor: "#e3e0e0",
              }}
            >
              <View>
                <DataTable
                  // title="Country"
                  columns={columns}
                  data={filterData}
                  customStyles={customStyles}
                  pagination
                />
              </View>
              {/* prvider datatable code here */}

              {/* ended */}
            </View>
          </View>
          {/* </ScrollView> */}
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
  },
  input: {
    borderColor: "gray",
    width: "60%",
    borderWidth: 1,
    borderRadius: 10,

    padding: 10,
  },
  tableHead: {
    fontSize: 14,
    /* FontFamily: 'Muli' ,*/
    fontWeight: "400",
    color: "#000",
  },
  addDevice: {
    alignItems: "center",
    backgroundColor: "#006EB9",
    padding: 10,
    borderWidth: 1,
    borderColor: "#006EB9",
    width: "50%",
    // marginTop: 20,
  },
  contanerSwitch: {
    backgroundColor: "white",
    borderColor: "#eee",
    borderRadius: 5,
    alignSelf: "center",
    borderWidth: 1,
    // justifyContent: "center",
    height: "25%",
    margin: "auto",
    padding: 10,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    // marginRight: 10,
    // marginRight: 1,
    width: "30%",
  },
  saveBtn: {
    alignItems: "center",
    backgroundColor: "#006EB9",
    padding: 10,
    borderWidth: 1,
    borderColor: "#006EB9",
    width: "50%",
    marginRight: 10,
  },
  tableHeader: {
    color: "#000",
    fontSize: 14,
    /* FontFamily: 'Muli' ,*/
    fontWeight: "700",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  btnText: {
    color: "#fff",
    fontWeight: "600",
  },
  card: {
    width: "100%",
    flex: 1,
    marginVertical: 10,
  },
  shadowProp: {
    shadowColor: "#171717",
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 25,
  },
  link: {
    marginTop: 15,
    paddingVertical: 15,
  },
  linkText: {
    fontSize: 14,
    color: "#2e78b7",
  },
  textInputStyle: {
    height: 35,
    borderWidth: 2,
    paddingLeft: 10,
    borderColor: "#319ef0",
    backgroundColor: "#FFFFFF",
    width: "40%",
    borderRadius: 5,
    color: "#000",
    fontWeight: "400",
  },
  lableText: {
    color: "#484848",
    fontWeight: "700",
    marginTop: 18,
    // fontSize: 21,
  },
});
