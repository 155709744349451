import Header from "../navigation/header";
import {
    StyleSheet,
    TouchableOpacity,
    ScrollView,
    Switch,
    Modal,
    TextInput,
    Pressable,
    FlatList,
    Alert,
    ListRenderItemInfo,
    ActivityIndicator,
    Platform,
    Keyboard,
    Dimensions,
} from "react-native";
import CryptoJS from "react-native-crypto-js";

// import { TimePicker, ValueMap } from 'react-native-simple-time-picker';
// import { styles } from "./style";
import { TimePickerModal } from "react-native-paper-dates";

import SuccessPopup from "../../components/SuccessPopup";

import { RadioButton, Button } from "react-native-paper";
import { SwiperFlatList } from "react-native-swiper-flatlist";
import {
    MenuProvider,
    MenuContext,
    Menu,
    MenuOptions,
    MenuOption,
    MenuTrigger,
} from "react-native-popup-menu";
import { DatePickerModal } from "react-native-paper-dates";
import moment from "moment";
import AsyncStorage from "@react-native-async-storage/async-storage";
// import { MultiSelects } from '../../components/MultiSelects'
import { Dropdown } from "react-native-element-dropdown";
import MultiSelect from "react-native-multiple-select";
import IconRefresh from "react-native-vector-icons/FontAwesome";

import { Text, View } from "../../components/Themed";
import { jsonToCSV } from "react-native-csv";
import { RootStackScreenProps } from "../../types"; // @ts-ignore
import React, { useEffect, useState, useRef } from "react";
import Icon from "react-native-vector-icons/FontAwesome";
import IconPlusMinus from "react-native-vector-icons/AntDesign";
import IconArrow from "react-native-vector-icons/Entypo";

import DataTable from "react-data-table-component";
import IconTimer from "react-native-vector-icons/Entypo";
import IconSelect from "react-native-vector-icons/AntDesign";

import { useFocusEffect } from "@react-navigation/native";
import { postData, baseUrlSocket } from "../../networking/api";
// import { DataTable } from "react-native-paper";
import { Provider as PaperProvider } from "react-native-paper";
import IconEdit from "react-native-vector-icons/FontAwesome";
// import { TextInput } from 'react-native-paper';
import IconCross from "react-native-vector-icons/Entypo";

import IconEye from "react-native-vector-icons/Entypo";
import { color } from "react-native-elements/dist/helpers";
import Swiper from "react-native-web-swiper";
import {
    CreateResponsiveStyle,
    DEVICE_SIZES,
    useDeviceSize,
} from "rn-responsive-styles";

// import Swiper from 'react-native-swiper';
import { useSelector, useDispatch } from "react-redux";
import socketServices from "../../constants/utils/socketService";
import { err } from "react-native-svg/lib/typescript/xml";
import crypto from 'crypto'
import { enableLegacyWebImplementation } from "react-native-gesture-handler";

export default function ScheduleProgram({ navigation }) {
    var dispatch = useDispatch();
    const styles = useStyles();

    var idProject = useSelector((state) => state.idUpdating);

    const [text, setText] = useState("");
    const [isVisible, setIsVisible] = useState(false);
    const [isCreateProgSuccessVisible, setisCreateProgSuccessVisible] =
        useState(false);
    const [isProgramLimit, setIsProgramLimit] = useState(false);
    const [ProgramLimitError, setProgramLimitError] = useState("");

    const [updatedata, setupdatedata] = useState(false);
    const [ValveIsVisible, setValveIsVisible] = useState(false);
    const [isFocus, setIsFocus] = useState(false);
    const [isAdd, setisAdd] = useState(false);
    const [programIndex, setProgramIndex] = useState(0);

    const [programsId, setProgramsId] = useState();
    const [BermadProgramsId, setBermadProgramId] = useState("");
    const [socketDetails, setSocketDetails] = useState([]);
    const [UpdateProgramIndex, setUpdateProgramIndex] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingSync, setIsLoadingSync] = useState(false);
    const [Omegaerror, setOmegaaError] = useState("");

    const [ScheduleProgramDataLength, setScheduleProgramDataLength] = useState(0);
    const [ScheduleProgramNameData, setScheduleProgramNameData] = useState([]);
    const [projectId, setProjectId] = useState("");

    const [issuccessVisible, setissuccessVisible] = useState(false);
    const [responsestatus, setresponsestatus] = useState("");
    const [PopupMessage, setPopupMessage] = useState("");
    //dropdown omega id
    const [OmegaIdData, setOmegaIdData] = useState([]);
    const [deviceNameData, setDeviceNameData] = useState([]);
    const [isProgStart, setIsProgStart] = useState(false);

    const [shouldShow, setShouldShow] = useState(true);
    const [breckloop, setbreckloop] = useState(false);
    const [OmegaDetails, setOmegaDetails] = useState([]);
    const [ProgramAmountDetails, setProgramAmountDetails] = useState([]);
    const [refreshflatlist, setrefreshflatlist] = useState(false);
    const [valveDetails, setValveDetails] = useState([]);
    const [valveArr, setValveArr] = useState([]);
    const [sensorTypeDetails, setSensorTypeDetails] = useState([]);
    const [selectedValve, setSelectedValve] = useState();
    const [ValveSelected, setValveSelected] = useState({});
    const [newProjectName, setProjectName] = useState("");
    const [SensorvalueType, setSensorvalueType] = useState("");
    const [sensorStatusId, setSensorStatusiD] = useState("");
    const [sensorStatusIdValue, setSensorStatusIdValue] = useState(null);
    const [indexforconfirmstarttime, setindexforconfirmstarttime] = useState(null); //set index to null
    const [indexforvalve, setIndexValve] = useState(0);
    const [selectedValveItems, setSelectedValveItems] = useState([]);

    // error
    const [progNameError, setprogNameError] = useState("");
    const [programTypeError, setprogramTypeError] = useState("");

    const [progDaysError, setProgDaysError] = useState("");
    const [programTimeError, setprogramTimeError] = useState("");

    const [programStrtTimeError, setprogramStrtTimeError] = useState("");
    const [programEndTimeError, setprogramEndTimeError] = useState("");
    const [programHoursError, setprogramHoursError] = useState("");

    const [AddValveError, setAddValveError] = useState("");
    const [progActionError, setprogActionError] = useState("");

    const [deviceError, setDeviceError] = useState("");
    const [StatusError, setStatusError] = useState("");
    const [valveError, setValveError] = useState("");

    const flatListRef = useRef(FlatList);
    var finaldata: {
        amount: any;
        name: any;
        order: number;
        valve: { connect: { id: any } };
    }[] = [];

    // console.log("ProgramAmountDetails=====>>>>>", ProgramAmountDetails);

    setIndexValve;
    const [sensorStatus, setSensorStatus] = useState([
        {
            id: 1,
            name: "Open",
        },
        {
            id: 2,
            name: "Close",
        },
    ]);

    const [mainindexmodal, setmainindexmodal] = useState("");

    const [OmegaAddvalueId, setOmegaAddValueiD] = useState("");
    // console.log("OmegaAddvalueId", OmegaAddvalueId);
    const [omegaNewid, setomegaNewid] = useState("");
    const [OmegaAddIdValue, setOmegaAddIdValue] = useState(null);
    const [OmegaIdName, setOmegaIdName] = useState(null);
    const [defaultOmegaId, setDefaultOmegaId] = useState("");
    const [defaultOmegavalueId, setdefaultOmegaValueiD] = useState("");

    const [OmegaIdValue, setOmegaIdValue] = useState(null);
    const [OmegavalueId, setOmegaValueiD] = useState("");

    const [DeviceNameIdValue, setDeviceNameIdValue] = useState(null);
    const [DeviceNamevalueId, setDeviceNameValueiD] = useState("");

    const [omegaid, setomegaid] = useState("");
    const [OmegaError, setOmegaError] = useState("");
    const [isopen, setisopen] = useState(false);
    const [programAmount, setprogramAmount] = useState([]);
    const [measurementType, setmeasurementType] = useState("");

    const [SensorvalueId, setSensorValueiD] = useState("");
    const [SensorIdValue, setSensorIdValue] = useState(null);

    const [hours, setHours] = React.useState(0);
    const [minutes, setMinutes] = React.useState(0);
    const [seconds, setSeconds] = React.useState(0);
    const [selectedDays, setSelectedDays] = useState([]);
    const [selectedDaysId, setSelectedDaysId] = useState([]);
    const [binaryToDecimal, setBinaryToDecimal] = useState();

    const [AddselectedDays, setAddSelectedDays] = useState([]);
    const [AddselectedDaysId, setAddSelectedDaysId] = useState([]);
    const [AddbinaryToDecimal, setAddBinaryToDecimal] = useState();

    // var array1 = [0, 0, 0, 0, 0, 0, 0];
    const [arrayDays, setArrayDays] = useState([0, 0, 0, 0, 0, 0, 0]);
    const [arrayDecToBin, setArrayDecToBin] = useState([]);

    const [AddarrayDays, setAddArrayDays] = useState([0, 0, 0, 0, 0, 0, 0]);
    const [programArray, setProgramArray] = useState([])

    const handleChange = (value: {
        hours: number;
        minutes: number;
        seconds: number;
    }) => {
        setHours(value.hours);
        setMinutes(value.minutes);
        setSeconds(value.seconds);
    };
    const handleReset = () => {
        setHours(0);
        setMinutes(0);
        setSeconds(0);
    };

    // Days week
    const [daysData, setDaysData] = useState([
        { id: 0, name: "Saturday" },
        { id: 1, name: "Friday" },
        { id: 2, name: "Thursday" },
        { id: 3, name: "Wednesday" },
        { id: 4, name: "Tuesday" },
        { id: 5, name: "Monday" },
        { id: 6, name: "Sunday" },
    ]);
    // Program type
    const [ProgramTypeIdData, setProgramTypeIdData] = useState([
        {
            id: 1,
            name: "CYCLIC",
        },
        {
            id: 2,
            name: "WEEKLY",
        },
    ]);

    // for (let i = 0; i <= ProgramTypeIdData.length; i++) {
    //     const prgTypeidDataLower = ProgramTypeIdData[i].name.toLowerCase();
    //     // console.log("prg type id lower case", prgTypeidDataLower);
    //     const prgTimefirstLetter = prgTypeidDataLower.charAt(0).toUpperCase() + prgTypeidDataLower.slice(1);
    //     // console.log("prgTimefirstLetter", prgTimefirstLetter);
    // }

    const [programTypeAdd, setProgramTypeAdd] = useState("");
    const [programActionsSelect, setprogramActionsSelect] = useState("None");
    const [programActionsSelectId, setprogramActionsSelectId] = useState("");

    const [programActions, setProgramActions] = useState([
        {
            id: 1,
            name: "None",
        },
        {
            id: 2,
            name: "Pause",
        },
    ]);

    // Irrigation start value
    const [IrrigationStartData, setIrrigationStartData] = useState([
        {
            id: 1,
            name: "Cyclic",
        },
        {
            id: 2,
            name: "Hours",
        },
    ]);

    const [IrrigationStartSelect, setIrrigationStartSelect] = useState("");
    const [IrrigationNewStartSelect, setIrrigationNewStartSelect] = useState("");
    const [IrrigationNewStartSelectId, setIrrigationNewStartSelectId] =
        useState("");

    const [programTypevalueId, setprogramTypeValueiD] = useState("");
    const [programTypeIdValue, setprogramTypeIdValue] = useState("");

    const [daysvalueId, setDaysValueiD] = useState("");
    const [daysIdValue, setDaysIdValue] = useState(null);

    const [irrigationValue, setIrrigationValue] = useState(null);

    const [rename, setrename] = useState(false);
    const [renameEditable, setrenameEditable] = useState();

    const [searchData, setSearch] = useState("");
    const [filterData, setFilteredDataSource] = useState([]);
    const [isScheduleProgramNameDataValid, setScheduleProgramNameDataValid] =
        useState(false);
    const [isScheduleProgramNameDataEntered, setScheduleProgramNameDataEntered] =
        useState(false);
    const [sort, setsort] = useState(0);
    const [isstatuschecked, setStatusChecked] = useState(1);
    const [isMeasureType, setMeasureType] = useState("Duration");
    const [CountryName, setCountryNameData] = useState([]);

    const [AddValveItems, setAddValveItems] = useState([]);
    const [valveid, setvalveid] = useState("");
    const [valveUniqueId, setvalveUniqueId] = useState("");


    const [selectedId, setSelectedId] = useState(null);
    const [refresh, setrefresh] = useState(false);



    //  Update Variable
    const [updateValveid, setUpdateValveid] = useState("");
    const [updateProjectName, setUpdateProjectName] = useState("");
    const [updatetimeStartValue, setUpdatetimeStartValue] = useState("");
    const [updatetimeEndValue, setUpdatetimeEndValue] = useState("");
    const [updateAddbinaryToDecimal, setUpdateAddbinaryToDecimal] = useState("")






    //DATE
    const [date, setDate] = React.useState<Date | undefined>(undefined);
    const [open, setOpen] = React.useState(false);

    const onDismissSingle = React.useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const checkOpenDate = (index: any) => {

        // setindexforconfirmstarttime(index);
        setOpen(true);
    };
    const onConfirmSingle = React.useCallback(
        (params: { date: React.SetStateAction<Date | undefined> }) => {
            setOpen(false);
            setDate(params.date);




            OmegaDetails[indexforconfirmstarttime].startDate = params.date;
            setrefreshflatlist(!refreshflatlist);
        },
        [setOpen, setDate, indexforconfirmstarttime]
    );
    const onConfirmNewDate = React.useCallback(
        (params: { date: React.SetStateAction<Date | undefined> }) => {
            setOpen(false);
            setDate(params.date);

        },
        [setOpen]
    );

    //Valve timepicker
    const [ValveAmountTime, setValveAmountTime] = useState("");
    const [ValveAmountTimeValue, setValveAmountTimeValue] = useState("");
    const [prgValveAmountData, setValveAmountData] = useState([]);

    const [ValveAmountTimeVisible, setValveAmountTimeVisible] =
        React.useState(false);
    const onDismissValveAmountTime = React.useCallback(() => {
        setValveAmountTimeVisible(false);
    }, [setValveAmountTimeVisible]);

    const checkValveAmountVisible = (mainindex: any, index: any) => {

        // setindexforconfirmstarttime(index);
        setValveAmountTimeVisible(true);
    };

    // const onConfirmValveAmountTime = React.useCallback(
    //     ({ hours, minutes }) => {
    //         setValveAmountTimeVisible(false);
    //         setValveAmountTime({ hours, minutes });
    //         const d = moment({ hours, minutes }).format("HH:mm:ss"); // your input string
    //         const a = d.split(":"); // split it at the colons
    //         const result = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
    //         setValveAmountTimeValue(result);
    //         
    //     },
    //     [setValveAmountTimeVisible, indexforconfirmstarttime, indexforvalve]
    // );

    //Timepicker
    // const [times, setTimes] = React.useState<Time | undefined>(undefined);

    const onConfirmValveAmountTime = React.useCallback(
        (valveIndex, itemIndex) => {
            var hours = valveIndex.hours
            var minutes = valveIndex.minutes

            setValveAmountTimeVisible(false);
            setValveAmountTime({ hours, minutes });
            const d = moment({ hours, minutes }).format("HH:mm:ss"); // your input string
            const a = d.split(":"); // split it at the colons
            const result = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
            setValveAmountTimeValue(result);

            var length = OmegaDetails[indexforconfirmstarttime].ProgramsAmountData.length;

            OmegaDetails[indexforconfirmstarttime].ProgramsAmountData[indexforvalve].amount = result

        },
        [setValveAmountTimeVisible, indexforconfirmstarttime, indexforvalve, OmegaDetails]
    );

    const [times, setTimes] = useState("");

    // start time programs
    const [programStartTime, setprogramStartTime] = useState("");
    const [programStartValue, setprogramStartValue] = useState("");
    const [prgStartData, setPrgStartData] = useState([]);
    const [newprgStartData, setNewPrgStartData] = useState([]);
    const [newprgStartData1, setNewPrgStartData1] = useState([]);
    const [programStartTimeVisible, setprogramStartTimeVisible] =
        React.useState(false);
    const onDismissProgramStartTime = React.useCallback(() => {
        setprogramStartTimeVisible(false);
    }, [setprogramStartTimeVisible]);

    const checkProgramVisible = (index: any) => {
        // console.log("program start time", index);
        // setindexforconfirmstarttime(index);
        setprogramStartTimeVisible(true);
    };

    const onConfirmProgramStartTime = React.useCallback(({ hours, minutes }) => {
        setprogramStartTimeVisible(false);
        setprogramStartTime({ hours, minutes });
        const d = moment({ hours, minutes }).format("HH:mm:ss");
        const a = d.split(":");
        const result = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
        setprogramStartValue(result);
        var hrDataLen = OmegaDetails[indexforconfirmstarttime].cycleTypeHours.length;
        OmegaDetails[indexforconfirmstarttime].cycleTypeHours[hrDataLen] = result;
        setPrgStartData(OmegaDetails[indexforconfirmstarttime].cycleTypeHours);
        setrefreshflatlist(!refreshflatlist)
    },
        [setprogramStartTimeVisible, indexforconfirmstarttime]
    );

    const onConfirmNewProgramStartTime = React.useCallback(
        ({ hours, minutes }) => {
            setprogramStrtTimeError("")

            setprogramStartTimeVisible(false);
            setprogramStartTime({ hours, minutes });
            // var timeParts = times.split(":");
            // // console.log('hh to ms---', (+timeParts[0] * (60000 * 60)) + (+timeParts[1] * 60000));
            // console.log("start index time", indexforconfirmstarttime);

            const d = moment({ hours, minutes }).format("HH:mm:ss"); // your input string
            const a = d.split(":"); // split it at the colons
            const result = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
            // console.log("result===>", result);

            setprogramStartValue(result);
            // console.log("newprgStartData1", newprgStartData1);
            // newprgStartData.push(result);
            // var tempPrgArr = [];
            var programstartdatalength = newprgStartData.length;
            // tempPrgArr.push({ item: result, index: programstartdatalength + 1 });
            // newprgStartData.push({ timeP: d });
            // newprgStartData[programstartdatalength] = result;
            // setNewPrgStartData(result);

            var tempPrgArr = [];
            var programstartdatalength = newprgStartData1.length;
            var arrayPrg = tempPrgArr.push(result);
            newprgStartData1[programstartdatalength] = result;
            // console.log("prgStartData", newprgStartData1);
            // setPrgStartData([...prgStartData, result]);
            // console.log("time result prg", arrayPrg, result);
            // console.log("timeeeeee  HI", { hours, minutes });
            // console.log("newprgStartData2", newprgStartData1);
            setNewPrgStartData1(newprgStartData1);
            setrefreshflatlist(!refreshflatlist)
        },
        []
    );
    // console.log("newprgStartData1newprgStartData1newprgStartData1newprgStartData1newprgStartData1,---", newprgStartData1);

    const onConfirmNewProgramStartTime1 = React.useCallback(
        ({ hours, minutes }) => {
            const d = moment({ hours, minutes }).format("HH:mm:ss"); // your input string
            newprgStartData1.push({ d });
            // console.log("hh mm", newprgStartData1);
            setNewPrgStartData1(newprgStartData1);
            setprogramStartTimeVisible(false);
        },
        []
    );

    // Time end
    const [timeVisible, setTimeVisible] = React.useState(false);
    const [timeEndValue, setTimeEndValue] = useState(0);

    const onDismissTime = React.useCallback(() => {
        setTimeVisible(false);
    }, [setTimeVisible]);

    const checktimeEndvisible = (index: any) => {
        // console.log("indexindex end time", index);
        // setindexforconfirmstarttime(index);
        setTimeVisible(true);
    };
    const onConfirmTime = React.useCallback(
        ({ hours, minutes }) => {
            setTimeVisible(false);
            setTimes({ hours, minutes });
            // var timeParts = times.split(":");
            // // console.log('hh to ms---', (+timeParts[0] * (60000 * 60)) + (+timeParts[1] * 60000));
            // console.log("OmegaDetailsindex end time", indexforconfirmstarttime);

            const d = moment({ hours, minutes }).format("HH:mm:ss"); // your input string
            const a = d.split(":"); // split it at the colons

            // const timstr = moment({ hours, minutes }).format("HH:mm:ss");
            const result = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
            setTimeEndValue(result);
            // console.log("time result end", result);
            // console.log("timeeeeee end", { hours, minutes });
            OmegaDetails[indexforconfirmstarttime].hourlyEnd = result;
            // setrefreshflatlist(!refreshflatlist)
            // console.log("afterchangeOmegaDetails endd time", OmegaDetails);
            setrefreshflatlist(!refreshflatlist);
        },
        [setTimeVisible, indexforconfirmstarttime]
    );

    const onConfirmNewEndTime = React.useCallback(
        ({ hours, minutes }) => {
            setprogramEndTimeError("")
            setTimeVisible(false);
            setTimes({ hours, minutes });
            // var timeParts = times.split(":");
            // // console.log('hh to ms---', (+timeParts[0] * (60000 * 60)) + (+timeParts[1] * 60000));

            const d = moment({ hours, minutes }).format("HH:mm:ss"); // your input string
            const a = d.split(":"); // split it at the colons

            // const timstr = moment({ hours, minutes }).format("HH:mm:ss");
            const result = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
            setTimeEndValue(result);
            // console.log("time result end", result);
            // console.log("timeeeeee end", { hours, minutes });

            setrefreshflatlist(!refreshflatlist);
        },
        [setTimeVisible]
    );
    // start time
    const [timeStart, setTimeStart] = useState("");
    const [timeStartValue, setTimeStartValue] = useState(0);
    const [indexvalue, setvalueindex] = useState();

    const [timeStartVisible, setTimeStartVisible] = React.useState(false);
    const onDismissStartTime = React.useCallback(() => {
        setTimeStartVisible(false);
    }, [setTimeStartVisible]);

    // console.log("OmegaDetails", OmegaDetails);

    const checktimestartvisible = (index: any) => {
        // console.log("indexindex", index);
        // setindexforconfirmstarttime(index);
        setTimeStartVisible(true);
    };

    const onConfirmStartTime = React.useCallback(
        ({ hours, minutes }) => {
            setTimeStartVisible(false);
            setTimeStart({ hours, minutes });
            onconfirmstarttimeresult();
            // console.log("OmegaDetailsindex", indexforconfirmstarttime);

            const d = moment({ hours, minutes }).format("HH:mm:ss"); // your input string
            const a = d.split(":"); // split it at the colons
            // const timstr = moment({ hours, minutes }).format("HH:mm:ss");
            const result = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
            setTimeStartValue(result);
            // console.log("time result", result);
            // console.log("beforechangeOmegaDetails", OmegaDetails);

            // console.log("timeeeeee start", hours, ":", minutes);
            OmegaDetails[indexforconfirmstarttime].hourlyStart = result;
            // setrefreshflatlist(!refreshflatlist)
            // console.log("afterchangeOmegaDetails", OmegaDetails);
        },
        [setTimeStartVisible, indexforconfirmstarttime]
    );

    const onnewConfirmStartTime = React.useCallback(
        ({ hours, minutes }) => {
            setprogramStrtTimeError("")
            setTimeStartVisible(false);
            setTimeStart({ hours, minutes });

            const d = moment({ hours, minutes }).format("HH:mm:ss"); // your input string
            const a = d.split(":"); // split it at the colons
            // const timstr = moment({ hours, minutes }).format("HH:mm:ss");
            const result = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
            setTimeStartValue(result);
            // console.log("time result", result);

            // console.log("timeeeeee start", hours, ":", minutes);
        },
        [setTimeStartVisible]
    );

    const onconfirmstarttimeresult = () => {
        // console.log("OmegaDetailsindex start time", indexforconfirmstarttime);
    };

    //hour interval
    const [timeHoursInterval, setTimeHoursInterval] = useState("");
    const [timeHoursIntervalValue, setTimeHoursIntervalValue] = useState(0);

    const [timeHIVisible, setTimeHIVisible] = React.useState(false);
    const onDismissHITime = React.useCallback(() => {
        setTimeHIVisible(false);
    }, [setTimeHIVisible]);

    const checkTimeIntervalvisible = (index: any) => {
        // console.log("indexindex hours interval time", index);
        // setindexforconfirmstarttime(index);
        setTimeHIVisible(true);
    };
    const onConfirmHITime = React.useCallback(
        ({ hours, minutes }) => {
            setTimeHIVisible(false);
            setTimeHoursInterval({ hours, minutes });
            // var timeParts = times.split(":");
            // // console.log('hh to ms---', (+timeParts[0] * (60000 * 60)) + (+timeParts[1] * 60000));
            // console.log("OmegaDetailsindex hours time", indexforconfirmstarttime);

            const d = moment({ hours, minutes }).format("HH:mm:ss"); // your input string
            const a = d.split(":"); // split it at the colons
            // const timstr = moment({ hours, minutes }).format("HH:mm:ss");
            const result = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
            setTimeHoursIntervalValue(result);
            // console.log("time result hi", result);
            // console.log("timeeeeee  HI", { hours, minutes });
            OmegaDetails[indexforconfirmstarttime].cycleIntervalHours = result;

            // setrefreshflatlist(!refreshflatlist)
        },
        [setTimeHIVisible, indexforconfirmstarttime]
    );
    const onConfirmNewHITime = React.useCallback(
        ({ hours, minutes }) => {
            setprogramHoursError("")
            setTimeHIVisible(false);
            setTimeHoursInterval({ hours, minutes });

            const d = moment({ hours, minutes }).format("HH:mm:ss"); // your input string
            const a = d.split(":"); // split it at the colons
            // const timstr = moment({ hours, minutes }).format("HH:mm:ss");
            const result = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
            setTimeHoursIntervalValue(result);
            // console.log("time result hi", result);
            // console.log("timeeeeee  HI", { hours, minutes });

            // setrefreshflatlist(!refreshflatlist)
        },
        [setTimeHIVisible]
    );

    useEffect(() => {
        setisopen(false);
        setrename(false);
        setbreckloop(false);
        setOmegaDetails([]);
        setNewPrgStartData1([]);
        setProjectName("");
        setValveSelected([]);
        setAddSelectedDays([]);
        setprogramTypeIdValue("");
        setprogramTypeValueiD("");
        setIrrigationStartSelect("");
        setDate(undefined);
        setcount(0);
        setIrrigationNewStartSelect("Cyclic");
        setTimeStart("");
        setTimes("");
        setTimeHoursInterval("");
        setAddValveItems([]);
        setOmegaAddIdValue(null);
        setOmegaAddValueiD("");
        setSensorIdValue(null);
        setSensorValueiD("");
        setSensorvalueType("");
        setSensorStatusIdValue(null);
        setSensorStatusiD("");
        setrefresh(false);
    }, [refresh]);

    //Day's interval
    const [counter, setCounter] = useState();

    const [count, setcount] = useState(0);
    const [timeHr, settimeHr] = useState(0);
    const [timemin, settimemin] = useState(0);
    const [timesec, settimesec] = useState(0);

    // console.log("counter", counter);

    const [timehms, settimehms] = useState();

    const [valveamount, setvalveamount] = useState<Number>(0);

    const timer = `${timeHr < 10 ? `0${timeHr}` : timeHr}:${timemin < 10 ? `0${timemin}` : timemin
        }:${timesec < 10 ? `0${timesec}` : timesec}`;

    const dropdownRefOmega = useRef();
    const dropdownRefPT = useRef();
    const dropdownRefIS = useRef();
    const dropdownRefDI = useRef();
    const dropdownRefST = useRef();
    const dropdownRefVS = useRef();

    const closeMenu = () => {
        dropdownRefOmega.current.close();
        dropdownRefPT.current.close();
        dropdownRefIS.current.close();
        dropdownRefDI.current.close();
        dropdownRefST.current.close();
        dropdownRefVS.current.close();

        //return calls;
        // // console.log("on scroll close in dropdown")
    };

    useEffect(() => {
        window.addEventListener("scroll", closeMenu);
        return () => window.removeEventListener("scroll", closeMenu);
    }, []);

    let socket;

    useEffect(() => {
        const connectWebSocket = () => {
            // console.log("inside connect")
            socket = new WebSocket(baseUrlSocket);

            socket.onopen = () => {
                sendMessage();
                // console.log('WebSocket connection established');
            };

            socket.onmessage = (event) => {
                const message = event.data;
                setSocketDetails(JSON.parse(message));
                // console.log('Received message:', message);
                // let bytes = CryptoJS.AES.decrypt(message, "fJ6RCwj4r1!#");
                // console.log("bytes", bytes);
                // let originalText = bytes.toString(CryptoJS.enc.Utf8);
                // console.log("originalText", originalText);
                // console.log("originalText==>", JSON.parse(originalText));
                // const encryptionAlgorithm = 'aes-256-cbc';
                // // const encryptionKey = 'bd22ff3670e69fe2ef5777dd2845dd8a9b0743b9946a9ef6cf504a9be851e604';
                // // const encryptionIv = 'fd5528e4cf1001c85d2ded05cbd434e8';
                // const encryptedData = Buffer.from(message, 'hex');
                // console.log('encryptedData:', encryptedData);

                // // const encryptionKey = crypto.randomBytes(32); // 32 bytes = 256 bits
                // // const encryptionIv = crypto.randomBytes(16); // 16 bytes = 128 bits
                // const encryptionKey = "4f36f1856b8c0dc4f4efd10898370ec19334cead413441c53d63e04bdfa77587"; // 32 bytes = 256 bits
                // const encryptionIv = "7758064cc553be82cbfe0a964e914232"; // 16 bytes = 128 bits
                // const encryptionKeyBuffer = Buffer.from(encryptionKey, 'utf8');
                // const encryptionIvBuffer = Buffer.from(encryptionIv, 'utf8');

                // const decipher = crypto.createDecipheriv(encryptionAlgorithm, encryptionKeyBuffer, encryptionIvBuffer);
                // const decryptedBuffer = Buffer.concat([decipher.update(encryptedData), decipher.final()]);
                // console.log('decryptedBuffer message (Buffer):', decryptedBuffer);
                // return decryptedBuffer;


                // // 16 bytes = 128 bits
                // const decipher = CryptoJS.AES.decrypt(message, encryptionKey, {
                //     iv: encryptionIv,
                //     mode: CryptoJS.mode.CBC,
                //     padding: CryptoJS.pad.Pkcs7,
                // });
                // console.log("decipher", decipher);
                // let originalText = decipher.toString(CryptoJS.enc.Utf8);
                // console.log("originalText", originalText);

            };

            socket.onerror = (error) => {
                // console.log('WebSocket error:', error);
            };

            socket.onclose = (event) => {
                // console.log('WebSocket connection closed:', event.code, event.reason);
            };
        };

        connectWebSocket();
        setInterval(() => {
            sendMessage()
        }, 5000)

        // Clean up the WebSocket connection when the component unmounts
        return () => {
            if (socket) {
                socket.close();
            }
        };
    }, [omegaid]);

    const sendMessage = async () => {
        if (socket && socket.readyState === WebSocket.OPEN) {
            // console.log("socket==>", socket);
            let jsonValue = await AsyncStorage.getItem("userData");
            let projValue = await AsyncStorage.getItem("projectData");

            if (projValue != null) {
                const data = projValue + "-" + omegaid;
                // console.log("data===>", data)
                socket.send(data);
            } else if (jsonValue != null) {
                var userDetailsresponse = JSON.parse(jsonValue);
                const data = userDetailsresponse.project_selected + "-" + omegaid;
                // console.log("data===>", data)
                socket.send(data);
            }
            // let projValue = await AsyncStorage.getItem("projectData");
            // const data = {
            //     project_id: projValue,
            //     device_id: omegaid, //device_id string
            //     id: `${Math.random()}` //omega_id string
            //     // socketID: socketServices.socket.id
            // };
        }
    };

    // useEffect(
    //     () => {
    //         console.log("omegaid", omegaid)
    //         try {
    //             //disconnect socket
    //             socketServices.disconnect();
    //         } catch (error) {
    //             console.log("error", error);
    //         }
    //         socketDataRun(omegaid, projectId)
    //         // console.log("omegaid omegaid omegaid-----", omegaid, defaultOmegaId, omegaid ? omegaid : defaultOmegaId);
    //         // console.log("newprgStartData1===>", newprgStartData1);

    //         // socketDataRun(omegaid ? omegaid : defaultOmegaId, projectId)
    //         // var interval = setInterval(() => {
    //         //     socketDataRun(omegaid, projectId)
    //         //     console.log("call socket", interval)
    //         //     return () => clearInterval(interval);
    //         // }, 10000);


    //         // return () => (
    //         //     socketServices.off('disconnect', () => {
    //         //         // socketServices.emit("disconnect");
    //         //         console.log("socket disconnected2222");
    //         //         socketServices.disconnect();
    //         //         // socketServices.close();

    //         //     })
    //         // )
    //     },
    //     // []

    //     [omegaid]
    // )
    useEffect(() => {
        const b = timer.split(":"); // split it at the colons
        const result = +b[0] * 60 * 60 + +b[1] * 60 + +b[2];
        setvalveamount(result);
    }, [timer]);

    // useEffect(() => {
    //     for (let i = 0; i < arrayDays.length; i++) {
    //         arrayDays[i] = 0;

    //     }
    //     for (let i = 0; i < selectedDays.length; i++) {
    //         var indexDays = selectedDays[i] - 1;
    //         arrayDays[indexDays] = 1;

    //     }

    //     var toBinary = arrayDays.join('');
    //     var toBinaryToDec = parseInt(toBinary, 2)
    //     setBinaryToDecimal(toBinaryToDec);
    //     // console.log("arrayDays loop", arrayDays, toBinary, toBinaryToDec);

    // }, [selectedDays])

    useEffect(() => {
        // console.log("is press schdular");
        getAdminData();
    }, [idProject]);

    useEffect(() => {

        for (let i = 0; i < AddarrayDays.length; i++) {
            AddarrayDays[i] = 0;
        }

        for (let i = 0; i < AddselectedDays.length; i++) {
            var indexDays = AddselectedDays[i];
            AddarrayDays[indexDays] = 1;
        }

        var toBinary = AddarrayDays.join("");

        var toBinaryToDec = parseInt(toBinary, 2);

        setAddBinaryToDecimal(toBinaryToDec);
        // console.log("AddarrayDays loop", AddarrayDays, toBinary, toBinaryToDec);

        // setArrayDecToBin(OmegaDetails[indexforconfirmstarttime].irrigationDays.toString(2).split(""));
    }, [AddselectedDays]);

    // useFocusEffect(
    //     React.useCallback(() => {
    //         getAdminData();
    //         // getAllScheduleProgramProfileData([]);
    //     }, [])
    // );
    // useEffect(() => {
    //     socketServices.initializeSocket()
    //     socketServices.on('connect', () => {
    //         // console.log("socketServices.id5", socketServices.socket.id, projectId, omegaid);
    //         //payload device_id and project_id
    //         socketServices.emit("message", {
    //             project_id: projectId,
    //             device_id: omegaid,   //device_id string
    //             id: `${Math.random()}`, //omega_id string
    //             socketID: socketServices.socket.id,
    //         });
    //         socketServices.on("receive_message", (data: any) =>

    //             // console.log("receive_message =====>", data)

    //         );

    //     })
    //     // // console.log("socket data response", users)
    // }, [socketServices, projectId, omegaid]);
    // socketServices.initializeSocket();
    // // console.log("====================================");
    // // console.log("socket services", socketServices, socketServices.socket.id);
    // // console.log("====================================");

    useFocusEffect(
        React.useCallback(() => {

            getAdminData();
            // console.log(
            // "socketServices.id4",
            //     socketServices,
            //     socketServices.socket,
            //     socketServices.socket.id,
            //     projectId,
            //     omegaid
            // );


            // socketDataRun(omegaid, projectId)
            // // console.log("socket data run 1", omegaid);
        }, [projectId])
    );
    // useFocusEffect(

    //     React.useCallback(() => {
    //         socketServices.initializeSocket()
    //         getAdminData();

    //     }, [socketServices, projectId, omegaid])
    // );

    useEffect(() => {
        //response
        // socketFun();
        // socketServices.on('disconnect', () => {
        //     // console.log("disconnectted");
        // })
    }, [])


    // const socketFun = () => {
    //     socketServices.on("receive_message", (data: any) =>

    //         // console.log("receive_message =====>", data)

    //     );
    // }
    const socketDataRun = (omegaId, project_Id) => {
        socketServices.on("connect", () => {

            //payload device_id and project_id
            // var interval = setInterval(() => {
            if (
                socketServices.socket.id != undefined &&
                project_Id != "" &&
                omegaId != ""
            ) {

                socketServices.emit("message", {
                    project_id: project_Id,
                    device_id: omegaId, //device_id string
                    id: `${Math.random()}`, //omega_id string
                    socketID: socketServices.socket.id,
                });
            }

            socketServices.on("receive_message", (data: any) => {
                let bytes = CryptoJS.AES.decrypt(data, "secret key 123");
                let originalText = bytes.toString(CryptoJS.enc.Utf8);

                // console.log("dycrpted text", originalText);

                setSocketDetails(JSON.parse(originalText));
            });

            //     return () => clearInterval(interval);
            // }, 6000);
        });
    };
    const onSelectedItemsChange = (
        valueDays: string | any[] | ((prevState: never[]) => never[]),
        index: any
    ) => {
        // valueDays.toString(2).split("");

        // setrefreshflatlist(!refreshflatlist);
        // var uniqueName = Array.from(new Set(idarr.concat(valueDays)));

        // setSelectedDays(valueDays)

        OmegaDetails[index].idarr = valueDays;

        for (let i = 0; i < arrayDays.length; i++) {
            arrayDays[i] = 0;
        }

        for (let i = 0; i < OmegaDetails[index].idarr.length; i++) {
            var indexDays = OmegaDetails[index].idarr[i];
            arrayDays[indexDays] = 1;
        }

        var toBinary = arrayDays.join("");

        var toBinaryToDec = parseInt(toBinary, 2);

        setBinaryToDecimal(toBinaryToDec);
        // console.log("Tobinary", arrayDays, toBinary, toBinaryToDec);
        setrefreshflatlist(!refreshflatlist);
        // console.log("selectedDays", valueDays);

        var id = 0;
        for (let i = 0; i < valueDays.length; i++) {
            var tempItem = daysData.find((item) => item.id === valueDays[i]);
            // console.log("tempItem", tempItem);
            id = tempItem.id - 1;
            // console.log("ID DAYS", id);
            // setArrayDays()
        }
    };

    const onSelectedAddItemsChange = (
        selectedDays: string | any[] | ((prevState: never[]) => never[])
    ) => {
        setProgDaysError("")

        setAddSelectedDays(selectedDays);
        // console.log("AddselectedDays", selectedDays);
        var id = 0;
        for (let i = 0; i < selectedDays.length; i++) {
            var tempItem = daysData.find((item) => item.id === selectedDays[i]);
            // console.log("tempItem", tempItem);
            id = tempItem.id - 1;
            // console.log("ID DAYS", id);
            // setArrayDays()
        }

        // console.log("array1", AddarrayDays);
    };

    const CheckEnteredData = async () => {

        if (
            valveid == "" &&
            timeStartValue == 0 &&
            timeEndValue == 0 &&
            timeHoursInterval == "" &&
            AddbinaryToDecimal == " " &&
            newProjectName == "" &&
            AddValveError == ""



            // DeviceNamevalueId == "" &&
            // SensorvalueId == "" &&
            // sensorStatusId == ""
        ) {

            setAddValveError("Select Valve");
            setprogNameError("select name");
            setprogramStrtTimeError("select program time");
            setProgDaysError("select days");
            setprogramEndTimeError("select end time");
            setprogramHoursError("select hours")

            // setDeviceError("select device");
            // setStatusError("select sensor");
            // setValveError("select value");
        } else {
            isopen ? createProgramData() : updateProgramData();
        }
    };




    const CheckUpdateEnteredData = (index, pid) => {
        // console.log("im here");
        updateProgramData(index, pid);

    }
    const EmptyListMessage = () => {
        return (
            <View style={{ justifyContent: "center" }}>
                <Text style={styles.emptyListStyle}>No Program Data Found</Text>
            </View>
        );
    };
    const getAdminData = async () => {
        let jsonValue = await AsyncStorage.getItem("userData");
        // console.log("jsonValue:", jsonValue);
        let projValue = await AsyncStorage.getItem("projectData");

        // console.log("jsonValue & projValue:", jsonValue, projValue);
        if (projValue != null) {
            // console.log("project id select in schedule ", projValue);
            setProjectId(projValue);
            getOmegaDependentByProjectId(projValue);
            getDeviceDependentByProjectId(projValue);
            // getOmegaDetails(projValue, '')
        } else if (jsonValue != null) {
            var userDetailsresponse = JSON.parse(jsonValue);
            // console.log("userDetailsresponse in zone ==>", userDetailsresponse);
            setProjectId(userDetailsresponse.project_selected);
            // setEmpCode(userDetailsresponse.employee_code);
            getOmegaDependentByProjectId(userDetailsresponse.project_selected);
            getDeviceDependentByProjectId(userDetailsresponse.project_selected);
        }
    };
    //create program data
    const createProgramData = async () => {
        setIsLoading(true);

        try {
            if (!newProjectName) {
                setIsLoading(false);
                setprogNameError("select name");
                return;
            }
            const programAmount = programAmountFunction(selectedValveItems);
            var ProgramData = JSON.stringify({
                module: "programs",
                data: {
                    omega_id: omegaid,
                    bermad_valve_id: valveid,
                    device_id: OmegaAddvalueId == "" ? null : OmegaAddvalueId,
                    valve_id: valveUniqueId,
                    project_id: projectId,
                    allowedHoursEnd: 0,
                    allowedHoursStart: 0,
                    cycleDayStartHour: 1400,
                    cycleIntervalDays: count,
                    cycleIntervalHours: timeHoursIntervalValue,
                    cyclePerDay: 3,
                    cycleTypeIsCyclic:
                        IrrigationNewStartSelect == "Cyclic" ? true : false,
                    cycleTypeHours: newprgStartData1,
                    endTimeMode: false,
                    hourlyEnd: timeEndValue,
                    hourlyStart: timeStartValue,
                    irrigationDays: AddbinaryToDecimal,
                    measurementType: isMeasureType,
                    program_name: newProjectName,
                    amount: parseInt(valveamount),
                    order: 0,
                    startDate: date,
                    status: "ACTIVE",
                    type: programTypeIdValue,
                    programAmount: programAmount
                },
            });


            const AddProgramResponse = await postData(
                ProgramData,
                "masters/createUpdatePrograms"
            );

            setIsLoading(false);

            var code = AddProgramResponse.code;
            if (code == 1) {
                // //alert(AddProgramResponse.message);
                // setIsVisible(false);
                // console.log("Program added success", AddProgramResponse.data);
                navigation.navigate("ScheduleProgram");
                getOmegaDetails(projectId, omegaid);
                setrefresh(true);
                setisopen(false);
                // setisCreateProgSuccessVisible(true)
                setPopupMessage("Program Created Successfully");
                setissuccessVisible(true);
                setresponsestatus("success");
                setrefreshflatlist(!refreshflatlist);
                // navigation.navigate("ProjectProfile");
            } else {
                setIsLoading(false);
                setissuccessVisible(true);
                setresponsestatus("failed");
                setPopupMessage(AddProgramResponse.message);
                // setisCreateProgSuccessVisible(false)
                // //alert(AddProgramResponse.message);
            }
        } catch (error) {
            setIsLoading(false);
            // console.log(error);
        }
    };

    const programAmountFunction = (selectedValveItems) => {
        const programAmount = selectedValveItems.map((valve, index) => ({
            amount: valve.amount,
            order: index,
            valve: {
                connect: {
                    id: valve.id
                }
            }
        }));

        return programAmount;
    };

    function getIrrigationDaysById(id) {
        console.log("id", id);
        const filteredItem = programArray.find(item => item.id === id);
        console.log("filteredItem", filteredItem);

        if (filteredItem) {
            return filteredItem.irrigationDays;
        }
        // Return a default value or handle the case where the id is not found
        return null; // You can change this to a default value or handle it as needed
    }

    //update program data  abcd
    const updateProgramData = async (index, pid) => {
        setIsLoading(true);
        try {

            const programsAmountData = OmegaDetails[index].ProgramsAmountData;
            const inputArray = programsAmountData !== undefined ? programsAmountData : ProgramAmountDetails;
            const outputArray = inputArray.map(item => {

                let valveId;
                if (item.valve && item.valve.connect && item.valve.connect.id) {
                    valveId = item.valve.connect.id;
                } else if (item.bermad_valve_id) {
                    valveId = item.bermad_valve_id;
                } else {
                    valveId = null;
                }
                return {
                    amount: item.amount,
                    order: item.order,
                    valve: { connect: { id: valveId } }
                };
            });

            const outputJSON = JSON.parse(JSON.stringify(outputArray, null, 2));
            console.log("programArray", programArray);

            const irregretionDays = await getIrrigationDaysById(pid)
            console.log("irregretionDays", irregretionDays);
            console.log("binaryToDecimal", binaryToDecimal);



            if (prgStartData.length === 0 && OmegaDetails[index].cycleTypeHours.length === 0) {
                setIsLoading(false);
                setissuccessVisible(true);
                setresponsestatus("failed");
                setPopupMessage("Add Start Time");
                return;
            }

            if (outputJSON.length === 0) {
                setIsLoading(false);
                setissuccessVisible(true);
                setresponsestatus("failed");
                setPopupMessage("Add at least One Valve");
                return;
            }

            var ProgramData = JSON.stringify({
                id: pid,
                module: "programs",
                data: {
                    allowedHoursStart: 0,
                    allowedHoursEnd: 0,
                    omega_id: omegaid,
                    measurementType: isMeasureType ? isMeasureType : OmegaDetails[index].measurementType,
                    startDate: date ? date : OmegaDetails[index].startDate,
                    cycleIntervalDays: counter
                        ? counter
                        : OmegaDetails[index].cyclePerDay,
                    irrigationDays: binaryToDecimal != undefined ? binaryToDecimal : irregretionDays,
                    program_index: 1234,
                    cycleDayStartHour: 1400,
                    cyclePerDay: 3,
                    hourlyStart: timeStartValue
                        ? timeStartValue
                        : OmegaDetails[index].hourlyStart,
                    cycleTypeHours: prgStartData.length > 0
                        ? prgStartData
                        : OmegaDetails[index].cycleTypeHours,
                    hourlyEnd: timeEndValue
                        ? timeEndValue
                        : OmegaDetails[index].hourlyEnd,
                    endTimeMode: false,
                    cycleIntervalHours: timeHoursIntervalValue
                        ? timeHoursIntervalValue
                        : OmegaDetails[index].cycleIntervalHours,
                    cycleTypeIsCyclic: IrrigationStartSelect == "Cyclic" ? true : false,
                    // programAmount: ProgramAmountDetails.length != 0
                    //     ? ProgramAmountDetails
                    //     : OmegaDetails[index].ProgramsAmountData,
                    programAmount: outputJSON,
                    // "amount": parseInt(valveamount) ? parseInt(valveamount) : OmegaDetails[index].ProgramsAmountData[0].valve.amount,
                    // "valveId": valveid ? valveid : OmegaDetails[index].ProgramsAmountData[0].id,
                    program_name: updateProjectName
                        ? updateProjectName
                        : OmegaDetails[index].program_name,
                    description: "updated valuesss now",
                    status: "ACTIVE",
                    type: programTypeIdValue
                        ? programTypeIdValue
                        : OmegaDetails[index].type,
                    device_id: DeviceNamevalueId == "" ? null : DeviceNamevalueId,
                    valve_id: valveUniqueId,
                    bermad_program_id: OmegaDetails[index].bermad_program_id,
                    program_id: programsId,
                    project_id: projectId,
                },
            });


            const UpdateProgramResponse = await postData(
                ProgramData,
                "masters/createUpdatePrograms"
            );


            setIsLoading(false);

            var code = UpdateProgramResponse.code;
            if (code == 1) {
                // //alert(UpdateProgramResponse.message);
                // setIsVisible(false);
                // console.log("Program added success", UpdateProgramResponse.data);
                navigation.navigate("ScheduleProgram");
                // navigation.navigate("ProjectProfile");
                getOmegaDetails(projectId, omegaid);

                setPopupMessage("Program Updated Successfully");
                setissuccessVisible(true);
                setresponsestatus("success");
                setrefreshflatlist(!refreshflatlist);
            } else {
                setIsLoading(false);
                setissuccessVisible(true);
                setresponsestatus("failed");
                setPopupMessage(UpdateProgramResponse.message);
                // //alert(UpdateProgramResponse.message);
            }
        } catch (error) {
            setIsLoading(false);
            console.log("error", error);
        }
    };
    //Get details when click on omega id
    const getOmegaDetails = async (project_Id: string, deviceId: string) => {
        // console.log("device name data & projectId", deviceId, projectId);
        // setrefresh(true);
        setProjectName("");
        setOmegaDetails([]);
        setIsLoading(true);

        try {
            var OmegaDetails = JSON.stringify({
                project_id: project_Id,
                omega_id: deviceId,
            });


            const OmegaDetailsResponse = await postData(
                OmegaDetails,
                "masters/getPrograms"
            );
            setIsLoading(false);
            var code = OmegaDetailsResponse.code;


            if (code == 1) {

                // socketDataRun(deviceId, project_Id);
                // getOmegaDetails(project_Id, deviceId)
                setbreckloop(false);
                // setShouldShow(false)
                setShouldShow(false);
                setrefreshflatlist(!refreshflatlist);
                setProgramArray(OmegaDetailsResponse.data)
                // getOmegaDetails(deviceId)
                var data = OmegaDetailsResponse.data;
                for (var i = 0; i < data.length; i++) {
                    // var onSelectArr = data[i].irrigationDays
                    var idarr = [];
                    var temp = data[i].irrigationDays != undefined ? data[i].irrigationDays.toString(2).split("") : " "
                    // console.log("omega temp", temp);

                    for (var j = 0; j < temp.length; j++) {
                        if (temp[j] == 1) {
                            idarr.push(j);
                        }
                    }
                    data[i].idarr = idarr;
                }

                // console.log("length of omega details", data.length == 7, data.length);

                // setOmegaDetails([]);
                setOmegaDetails(
                    data.filter((rp: { status: string }) => rp.status != "DELETED")
                );
                return data;

                // setProgramAmountDetails(OmegaDetailsResponse.data.ProgramsAmountData)
            } else {
                setIsLoading(false);
                setProgramLimitError(OmegaDetailsResponse.message);

                return "";
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    };

    //Sync program data from beramd cloud when click on refresh button
    const getProgramSync = async () => {
        setIsLoadingSync(true);
        try {


            var programSyncData = JSON.stringify({
                project_id: projectId,
                // omega_id: defaultOmegaId ? defaultOmegaId : omegaid,
                // device_id: defaultOmegavalueId ? defaultOmegavalueId : OmegavalueId,
                omega_id: omegaid,
                device_id: OmegavalueId,
            });
            // console.log("programSyncData payload", programSyncData);
            const programSyncResponse = await postData(
                programSyncData,
                "masters/ProgramsSynchronise"
            );


            setIsLoadingSync(false);
            var code = programSyncResponse.code;
            if (code == 1) {
                // console.log("Bermad sync program====>", programSyncResponse.message);
                getOmegaDetails(projectId, omegaid);
            } else {
                setIsLoadingSync(false);
                //alert(programSyncResponse.message);
            }
        } catch (error) {
            setIsLoadingSync(false);
            // console.log(error);
        }
    };

    //Delete Program
    const deleteProgram = async (programId: any, omega_id) => {
        // console.log("Program id", programId);
        setIsLoading(true);
        try {
            var DeleteProgram = JSON.stringify({
                id: programId,
                module: "programs",
                data: {
                    Omega_id: omega_id,
                    status: "DELETED",
                },
            });
            // console.log("delete program payload", DeleteProgram);
            const DeleteProgramResponse = await postData(
                DeleteProgram,
                "masters/deleteProgram"
            );
            setIsLoading(false);
            var code = DeleteProgramResponse.code;
            if (code == 1) {
                getOmegaDetails(projectId, omega_id);

                // console.log("Delete Program", DeleteProgramResponse.data);
                //alert("Program deleted");
                // setShouldShow(false)
                // setDeleteProgram(DeleteProgramResponse.data.unit.programs);
            } else {
                setIsLoading(false);
                //alert(DeleteProgramResponse.message);
            }
        } catch (error) {
            setIsLoading(false);
            // console.log(error);
        }
    };

    //start Program
    const startProgram = async (
        programIndex: any,
        BprogramId: string,
        omega_id
    ) => {
        // console.log("Program id", programIndex);
        // console.log("BprogramId", BprogramId);

        setIsLoading(true);
        try {
            var startProgram = JSON.stringify({
                index: programIndex,
                bermad_program_id: BprogramId,
                omega_id: omega_id,
            });
            // console.log("update program payload", startProgram);
            const startProgramResponse = await postData(
                startProgram,
                "masters/startProgram"
            );
            setIsLoading(false);
            var code = startProgramResponse.code;
            if (code == 1) {
                // console.log("START Program");

                // console.log("socket data run 3", omega_id);

                // socketDataRun(omega_id, projectId);
                getOmegaDetails(projectId, omega_id);

                // setShouldShow(false)
                // setstartProgram(startProgramResponse.data.unit.programs);
            } else {
                setIsLoading(false);
                //alert(startProgramResponse.message);
            }
            setrefreshflatlist(!refreshflatlist);
        } catch (error) {
            setIsLoading(false);
            // console.log(error);
        }
    };
    //stop Program
    const stopProgram = async (
        programIndex: any,
        BprogramId: string,
        omega_id
    ) => {
        // console.log("Program id", programIndex);
        // console.log("BprogramId", BprogramId);

        setIsLoading(true);
        try {
            var stopProgram = JSON.stringify({
                index: programIndex,
                bermad_program_id: BprogramId,
                omega_id: omega_id,
            });
            // console.log("stop program payload", stopProgram);
            const stopProgramResponse = await postData(
                stopProgram,
                "masters/stopProgram"
            );
            setIsLoading(false);
            var code = stopProgramResponse.code;
            if (code == 1) {
                // console.log("START stop", stopProgramResponse.data);
                getOmegaDetails(projectId, omega_id);
                // setShouldShow(false)
                // setstopProgram(stopProgramResponse.data.unit.programs);
            } else {
                setIsLoading(false);
                //alert(stopProgramResponse.message);
            }
            setrefreshflatlist(!refreshflatlist);
        } catch (error) {
            setIsLoading(false);
            // console.log(error);
        }
    };

    //pause Program
    const pauseProgram = async (
        programIndex: any,
        BprogramId: string,
        omega_id
    ) => {
        // console.log("Program id", programIndex);
        // console.log("BprogramId", BprogramId);

        setIsLoading(true);
        try {
            var pauseProgram = JSON.stringify({
                index: programIndex,
                bermad_program_id: BprogramId,
                omega_id: omega_id,
            });
            // console.log("pause program payload", pauseProgram);
            const pauseProgramResponse = await postData(
                pauseProgram,
                "masters/pauseProgram"
            );
            setIsLoading(false);
            var code = pauseProgramResponse.code;
            if (code == 1) {
                // console.log("pause Program", pauseProgramResponse.data);
                getOmegaDetails(projectId, omega_id);
                // setShouldShow(false)
                // setpauseProgram(pauseProgramResponse.data.unit.programs);
            } else {
                setIsLoading(false);
                //alert(pauseProgramResponse.message);
            }
            setrefreshflatlist(!refreshflatlist);
        } catch (error) {
            setIsLoading(false);
            // console.log(error);
        }
    };

    //resume Program
    const resumeProgram = async (
        programIndex: any,
        BprogramId: string,
        omega_id
    ) => {
        // console.log("Program id", programIndex);
        // console.log("BprogramId", BprogramId);

        setIsLoading(true);
        try {
            var resumeProgram = JSON.stringify({
                index: programIndex,
                bermad_program_id: BprogramId,
                omega_id: omega_id,
            });
            // console.log("pause program payload", resumeProgram);
            const resumeProgramResponse = await postData(
                resumeProgram,
                "masters/resumeProgram"
            );
            setIsLoading(false);
            var code = resumeProgramResponse.code;
            if (code == 1) {
                // console.log("resume Program", resumeProgramResponse.data);
                getOmegaDetails(projectId, omegaid);
                // setShouldShow(false)
                // setresumeProgram(resumeProgramResponse.data.unit.programs);
            } else {
                setIsLoading(false);
                //alert(resumeProgramResponse.message);
            }
            setrefreshflatlist(!refreshflatlist);
        } catch (error) {
            setIsLoading(false);
            // console.log(error);
        }
    };

    //Update Program
    const updatePrograms = async (programId: any, status: string) => {
        // console.log("Program id", programId);
        // console.log("statusstatus", status);

        setIsLoading(true);
        try {
            var updatePrograms = JSON.stringify({
                id: programId,
                module: "programs",
                data: {
                    Omega_id: omegaid,
                    status: status,
                },
            });
            // console.log("update program payload", updatePrograms);
            const updateProgramsResponse = await postData(
                updatePrograms,
                "masters/updateProgramstatus"
            );
            setIsLoading(false);
            var code = updateProgramsResponse.code;
            if (code == 1) {
                // console.log("Update Program", updateProgramsResponse.data);
                getOmegaDetails(projectId, omegaid);
                // setShouldShow(false)
                // setupdatePrograms(updateProgramsResponse.data.unit.programs);
            } else {
                setIsLoading(false);
                //alert(updateProgramsResponse.message);
            }
            setrefreshflatlist(!refreshflatlist);
        } catch (error) {
            setIsLoading(false);
            // console.log(error);
        }
    };

    //Get details when click on omega id
    const getValveOmegaDetails = async (deviceId: any) => {
        // console.log("device_id===", deviceId);
        setIsLoading(true);
        try {
            var OmegaDetails = JSON.stringify({
                device_id: deviceId,
            });
            // console.log("OmegaDetails", OmegaDetails);
            const OmegaDetailsResponse = await postData(
                OmegaDetails,
                "masters/getOmegaDetails"
            );
            setIsLoading(false);
            var code = OmegaDetailsResponse.code;
            if (code == 1) {
                // console.log("Omega details valve api====>", OmegaDetailsResponse.data);
                // console.log("valves", OmegaDetailsResponse.data[0].valves);
                const mergeResult = OmegaDetailsResponse.data[0].digitalInput.concat(
                    OmegaDetailsResponse.data[0].analogInput
                );


                // setShouldShow(false)
                // setSensorTypeDetails(mergeResult);
                setValveDetails(OmegaDetailsResponse.data[0].valves);
            } else {
                setIsLoading(false);
                //alert(OmegaDetailsResponse.message);
            }
        } catch (error) {
            setIsLoading(false);
            // console.log(error);
        }
    };

    //Get details when click on device name
    const getSensorOmegaDetails = async (deviceId: any) => {
        // console.log("device_id===sensor", deviceId);

        try {
            var OmegaDetails = JSON.stringify({
                device_id: deviceId,
            });
            // console.log("OmegaDetails devicename sensor", OmegaDetails);
            const OmegaDetailsResponse = await postData(
                OmegaDetails,
                "masters/getOmegaDetails"
            );
            setIsLoading(false);
            var code = OmegaDetailsResponse.code;
            if (code == 1) {

                const mergeResult = OmegaDetailsResponse.data[0].digitalInput.concat(
                    OmegaDetailsResponse.data[0].analogInput
                );

                // setShouldShow(false)
                mergeResult.push({
                    digital_id: null,
                    id: 0,
                    name: "None",
                    type: 0,
                    value: "0",
                });
                // console.log("mergeResult of digital input n analog input", mergeResult);

                setSensorTypeDetails(mergeResult);
            } else {
                setIsLoading(false);
                //alert(OmegaDetailsResponse.message);
            }
        } catch (error) {
            setIsLoading(false);
            // console.log(error);
        }
    };

    //Get Dependent omega  by project id
    const getOmegaDependentByProjectId = async (project_id: any) => {
        setIsLoading(true);
        try {
            var OmegaIdData = JSON.stringify({
                column: "project_id",
                value: project_id,
                module: "devices_master",
            });
            const OmegaIdList = await postData(
                OmegaIdData,
                "masters/getDependentMasterList"
                // "masters/getOmegaFromBermad"
            );
            setIsLoading(false);
            var code = OmegaIdList.code;

            if (code == 1) {
                // console.log("Bermad schduelar omega id", OmegaIdList.data);


                setOmegaIdData(OmegaIdList.data);
                // socketDataRun(omegaid, project_id)

                var omgData = await getOmegaDetails(project_id, OmegaIdList.data[0].omega_id)
                if (omgData != undefined && omgData.length > 0) {
                    setomegaid(OmegaIdList.data[0].omega_id);
                    setOmegaValueiD(OmegaIdList.data[0].id);
                    setOmegaAddValueiD(OmegaIdList.data[0].id);
                    setOmegaIdName(OmegaIdList.data[0].device_name);
                    getValveOmegaDetails(OmegaIdList.data[0].id);
                }

                // for (var i = 0; i < OmegaIdList.data.length; i++) {
                //     var omgIdToCheck = OmegaIdList.data[i].omega_id;
                //     // console.log("omgIdToCheck", omgIdToCheck);
                //     var omgData = await getOmegaDetails(project_id, omgIdToCheck);

                //     // console.log("omegadetails filter", omgData);
                //     // console.log("SELECT OMEGA ID", omegaid);

                //     if (omgData != undefined && omgData.length > 0) {


                //         console.log(
                //             "inside if filter",
                //             OmegaIdList.data[i].omega_id,
                //             project_id,
                //             omegaid
                //         );
                //         // socketDataRun(
                //         //     OmegaIdList.data[i].omega_id
                //         //         ? OmegaIdList.data[i].omega_id
                //         //         : omegaid,
                //         //     project_id
                //         // );
                //         setomegaid(OmegaIdList.data[i].omega_id);
                //         setOmegaValueiD(OmegaIdList.data[i].id);
                //         // setDefaultOmegaId(OmegaIdList.data[i].omega_id);
                //         // setdefaultOmegaValueiD(OmegaIdList.data[i].id);
                //         setOmegaIdName(OmegaIdList.data[i].device_name);
                //         getValveOmegaDetails(OmegaIdList.data[i].id);
                //         break;
                //     } else {
                //         break;
                //     }

                //     // if (OmegaIdList.data[i].omega_id !== "") {
                //     //     // if(OmegaDetails.length >)

                //     //     // console.log("socket run default", project_id, OmegaIdList.data[i].omega_id, OmegaDetails);

                //     //     // socketDataRun(OmegaIdList.data[i].omega_id, project_id);
                //     //     //  setomegaid(OmegaIdList.data[i].omega_id);
                //     //     setOmegaIdName(OmegaIdList.data[i].device_name)
                //     //     getOmegaDetails(project_id, OmegaIdList.data[i].omega_id)

                //     //     break;
                //     // } else {
                //     //     // console.log("false data");
                //     //     // continue;
                //     // }
                // }
            } else {
                setIsLoading(false);
                //alert(OmegaIdList.message);
            }
        } catch (error) {
            setIsLoading(false);
            // console.log(error);
        }
    };

    const getDeviceDependentByProjectId = async (project_id: any) => {
        setIsLoading(true);
        try {
            var OmegaIdData = JSON.stringify({
                column: "project_id",
                value: project_id,
                module: "devices_master",
            });
            const OmegaIdList = await postData(
                OmegaIdData,
                "masters/getDependentMasterList"
                // "masters/getOmegaFromBermad"
            );
            setIsLoading(false);
            var code = OmegaIdList.code;
            if (code == 1) {
                // console.log("DEVICE NAME", OmegaIdList.data);

                var arrData = OmegaIdList.data.push({
                    id: 0,
                    project_id: projectId,
                    omega_id: null,
                    device_name: "None",
                    lat: null,
                    long: null,
                    active: 1,
                });
                // console.log("arrData", arrData);
                setDeviceNameData(OmegaIdList.data);
            } else {
                setIsLoading(false);
                //alert(OmegaIdList.message);
            }
        } catch (error) {
            setIsLoading(false);
            // console.log(error);
        }
    };

    // Get all ScheduleProgram list Api
    const getAllScheduleProgramProfileData = async (sortdata: never[]) => {
        setIsLoading(true);
        if (sortdata.length > 0) {
            setScheduleProgramDataLength(sortdata.length);
            setScheduleProgramNameData(sortdata);
            setFilteredDataSource(sortdata);
        } else {
            try {
                var getScheduleProgramData = JSON.stringify({
                    module: "ScheduleProgram_master",
                    relation: [],
                });
                const ScheduleProgramNameResponse = await postData(
                    getScheduleProgramData,
                    "masters/getMasterList"
                );
                setIsLoading(false);
                var code = ScheduleProgramNameResponse.code;
                if (code == 1) {

                    setScheduleProgramDataLength(ScheduleProgramNameResponse.data.length);
                    setScheduleProgramNameData(ScheduleProgramNameResponse.data);
                    setFilteredDataSource(ScheduleProgramNameResponse.data);
                    const results = jsonToCSV(ScheduleProgramNameResponse.data);
                    // console.log("csv==", results);
                } else {
                    setIsLoading(false);
                    //alert(ScheduleProgramNameResponse.message);
                }
            } catch (error) {
                setIsLoading(false);
                // console.log(error);
            }
        }
    };

    // Get all ScheduleProgram list Api
    const updateProgramName = async (bermadProgramId) => {
        try {
            var getScheduleProgramData = JSON.stringify({
                bermad_program_id: bermadProgramId,
                program_name: updateProjectName,
            });
            const renameProgramNameResponse = await postData(
                getScheduleProgramData,
                "masters/renameProgram"
            );
            setIsLoading(false);
            var code = renameProgramNameResponse.code;

        } catch (error) {
            setIsLoading(false);

        }
    };
    const isCreatable = (index: React.SetStateAction<string>) => {
        setmainindexmodal(index);
        setIsVisible(true);
        setisAdd(true);
    };

    const isvalveCreatable = () => {
        setValveIsVisible(true);
        setisAdd(true);
    };
    var flatIndexNext = 0;
    const nextPress = () => {
        // if (index <= 2) {
        if (OmegaDetails.length > flatIndexNext) {
            flatIndexNext = flatIndexNext + 4;
            flatListRef?.current?.scrollToIndex({
                animated: true,
                index: flatIndexNext,
            });
        }

        // }
    };

    const backPress = () => {
        if (flatIndexNext >= 4) {
            flatIndexNext = flatIndexNext - 4;
            flatListRef?.current?.scrollToIndex({
                animated: true,
                index: flatIndexNext,
            });
        }
    };
    const renderSensorType = (item: any) => {
        return (
            <View style={styles.item}>
                {isAdd == true && <Text style={styles.textItem}>{item.name}</Text>}
                {isAdd == false && <Text style={styles.textItem}>{item.name}</Text>}
                {item.id === sensorStatusId && (
                    <IconSelect
                        style={styles.icon}
                        color="black"
                        name="Safety"
                        size={20}
                    />
                )}
            </View>
        );
    };

    const renderOmegaIdListItems = (item: any) => {
        return (
            <View style={styles.item}>
                {isAdd == true && (
                    <Text style={styles.textItem}>{item.device_name}</Text>
                )}
                {isAdd == false && (
                    <Text style={styles.textItem}>{item.device_name}</Text>
                )}
                {item.id === OmegavalueId && (
                    <IconSelect
                        style={styles.icon}
                        color="black"
                        name="Safety"
                        size={20}
                    />
                )}
            </View>
        );
    };
    const renderAddOmegaIdListItems = (item: any) => {
        return (
            <View style={styles.item}>
                {isAdd == true && (
                    <Text style={styles.textItem}>{item.device_name}</Text>
                )}
                {isAdd == false && (
                    <Text style={styles.textItem}>{item.device_name}</Text>
                )}
                {item.id === OmegaAddvalueId && (
                    <IconSelect
                        style={styles.icon}
                        color="black"
                        name="Safety"
                        size={20}
                    />
                )}
            </View>
        );
    };

    const renderDeviceNameListItems = (item: any) => {
        return (
            <View style={styles.item}>
                {isAdd == true && (
                    <Text style={styles.textItem}>{item.device_name}</Text>
                )}
                {isAdd == false && (
                    <Text style={styles.textItem}>{item.device_name}</Text>
                )}
                {item.id === DeviceNamevalueId && (
                    <IconSelect
                        style={styles.icon}
                        color="black"
                        name="Safety"
                        size={20}
                    />
                )}
            </View>
        );
    };
    const renderSensorListItems = (item: any) => {
        return (
            <View style={styles.item}>
                {isAdd == true && <Text style={styles.textItem}>{item.name}</Text>}
                {isAdd == false && <Text style={styles.textItem}>{item.name}</Text>}
                {item.id === SensorvalueId && (
                    <IconSelect
                        style={styles.icon}
                        color="black"
                        name="Safety"
                        size={20}
                    />
                )}
            </View>
        );
    };
    const renderProgramType = (item: any) => {
        return (
            <View style={styles.item}>
                {isAdd == true && (
                    <Text style={styles.textItem}>
                        {item.name.charAt(0).toUpperCase() +
                            item.name.slice(1).toLowerCase()}
                    </Text>
                )}
                {isAdd == false && (
                    <Text style={styles.textItem}>
                        {item.name.charAt(0).toUpperCase() +
                            item.name.slice(1).toLowerCase()}
                    </Text>
                )}
                {item.id === programTypevalueId && (
                    <IconSelect
                        style={styles.icon}
                        color="black"
                        name="Safety"
                        size={20}
                    />
                )}
            </View>
        );
    };

    const renderIrrigation = (item: any) => {
        // console.log("irrigation ", item);
        return (
            <View style={styles.item}>
                {isAdd == true && <Text style={styles.textItem}>{item.name}</Text>}
                {isAdd == false && <Text style={styles.textItem}>{item.name}</Text>}
                {item.id === IrrigationNewStartSelectId && (
                    <IconSelect
                        style={styles.icon}
                        color="black"
                        name="Safety"
                        size={20}
                    />
                )}
            </View>
        );
    };
    const renderProgAction = (item: any) => {
        // console.log("program action ", item);
        return (
            <View style={styles.item}>
                {isAdd == true && <Text style={styles.textItem}>{item.name}</Text>}
                {isAdd == false && <Text style={styles.textItem}>{item.name}</Text>}
                {item.id === programActionsSelectId && (
                    <IconSelect
                        style={styles.icon}
                        color="black"
                        name="Safety"
                        size={20}
                    />
                )}
            </View>
        );
    };

    const renderDaysType = (item: any) => {
        return (
            <View style={styles.item}>
                {isAdd == true && <Text style={styles.textItem}>{item.name}</Text>}
                {isAdd == false && <Text style={styles.textItem}>{item.name}</Text>}
                {item.id === daysvalueId && (
                    <IconSelect
                        style={styles.icon}
                        color="black"
                        name="Safety"
                        size={20}
                    />
                )}
            </View>
        );
    };

    const renderItemScedular = ({ item, index }) => {
        var valve_error = "";
        setAddValveError(valve_error);
        var mainindex = index;
        var leftover = 0;
        var state = 0;
        var valveIndex = 0;
        var mainsIndex = "";
        var colorGray = "#0089d1";
        // console.log("socketDetails.length2", socketDetails[0]);

        const tempArr = []
        try {
            // console.log("program_index", OmegaDetails[mainindex].program_index);
            if (socketDetails[0].programs && socketDetails[0].programs.length > 0) {
                for (let j = 0; j < socketDetails[0].programs.length; j++) {
                    // console.log("inside omega j loop index", socketDetails[0].programs[j].index);
                    if (socketDetails[0].programs[j].index === OmegaDetails[mainindex].program_index) {
                        tempArr.push(socketDetails[0].programs[j].index)
                        mainsIndex = socketDetails[0].programs[j].index;
                        valveIndex = socketDetails[0].programs[j].valve;
                        leftover = socketDetails[0].programs[j].leftover;
                        state = socketDetails[0].programs[j].state;
                        if (state == 1) {
                            // colorGray = "#0fbf00"; //green color
                            colorGray = "#5afc4c"; //green color
                        }
                        if (state == 2) {
                            colorGray = "#ffc401"; //orange
                        }
                        if (state == 3) {
                            colorGray = "#ffc401"; //gray
                        }
                        if (state == 4) {
                            colorGray = "#bababa"; //gray
                        }
                        if (state == 5) {
                            colorGray = "#bababa"; //gray
                        }
                    }
                    else {
                        // console.log("tempArr", tempArr)
                        if (tempArr.length > 0) {
                            for (let k = 0; j < tempArr.length; j++) {
                                if (tempArr[k] === OmegaDetails[mainindex].program_index) {
                                    mainsIndex = socketDetails[0].programs[j].index;
                                    valveIndex = socketDetails[0].programs[j].valve;
                                    leftover = socketDetails[0].programs[j].leftover;
                                    state = socketDetails[0].programs[j].state;
                                }
                            }
                        } else {
                            // console.log("inside else else", socketDetails[0].programs[j].index)
                            leftover = 0;
                            state = 0;
                            colorGray = "#bababa";
                        }

                    }
                }
            } else {
                leftover = 0;
                state = 0;
                colorGray = "#bababa";
            }
        } catch (error) {
            // console.log("socket disconnected121212", error);
        }

        return (
            <View style={{ width: "100%" }}>
                <View
                    style={{
                        minHeight: "5%",
                        borderRightWidth: 3,
                        borderRightColor: "#CCC",
                        alignSelf: "flex-start",
                        width: "100%",
                        flexDirection: "row",
                    }}
                >
                    <View
                        style={{
                            backgroundColor: "#0089d1",
                            width: "100%",
                            flexDirection: "row",
                        }}
                    >
                        <Icon
                            color={item.status == "NON-ACTIVE" ? "#000000" : colorGray}
                            name="circle"
                            size={20}
                            style={{ margin: 10 }}
                        />

                        {rename && renameEditable == index ? (

                            <View
                                style={{
                                    backgroundColor: "#0089d1",
                                    flexDirection: "row",
                                }}
                            >
                                <TextInput
                                    style={[styles.input, { width: "70%", color: "white" }]}
                                    placeholder="Enter Program Name"
                                    value={updateProjectName}
                                    onChangeText={(value) => {
                                        setUpdateProjectName(value);
                                        // console.log("project name", value);
                                    }}
                                />

                                <Icon
                                    // color={item.status == "NON-ACTIVE" ? "#000000" : colorGray}
                                    color={"#70c270"}
                                    name="check-circle-o"
                                    size={30}
                                    style={{ margin: 5 }}
                                    onPress={() => {
                                        if (updateProjectName !== "") {
                                            setrename(false)
                                            item.program_name = updateProjectName;
                                            updateProgramName(item.bermad_program_id)
                                        }
                                    }}
                                />

                                <Icon
                                    // color={item.status == "NON-ACTIVE" ? "#000000" : colorGray}
                                    color={"#fa776b"}
                                    name="times-circle-o"
                                    size={30}
                                    style={{ margin: 5 }}
                                    onPress={() => {
                                        setrename(false)
                                        // setrenameEditable();
                                    }}
                                />
                            </View>
                        ) : (
                            <Text
                                style={{
                                    marginVertical: 10,
                                    color: "white",
                                    width: "90%",
                                    textAlign: "center",
                                    fontWeight: "600",
                                    fontSize: 18,
                                }}
                            >
                                {item.program_name}
                            </Text>
                        )}
                        {!rename &&
                            <View
                                style={{
                                    width: "10%",
                                    top: "10px",
                                    backgroundColor: "#0089d1",
                                }}
                            >
                                <Menu>
                                    <MenuTrigger
                                        text=""
                                        customStyles={{
                                            triggerWrapper: {
                                                width: "100px",
                                            },
                                        }}
                                        onPress={() => setSelectedId(item.id)}
                                    >
                                        <IconCross
                                            color="#fff"
                                            name="dots-three-vertical"
                                            size={25}
                                        />
                                    </MenuTrigger>

                                    <MenuOptions
                                        customStyles={{}}
                                        optionsContainerStyle={{
                                            // marginTop: -180,
                                            marginLeft: -250,
                                            borderWidth: 1,
                                            borderColor: "#7b757573",
                                        }}
                                    >
                                        <MenuOption
                                            style={{
                                                borderBottomWidth: 1,
                                                borderBottomColor: "#dacfcf",
                                            }}
                                            onSelect={() => deleteProgram(item.id, item.omega_id)}
                                        >
                                            <Text style={{ color: "red" }}>Delete</Text>
                                        </MenuOption>
                                        {/* {item.program_status == null && ( */}
                                        {colorGray === "#bababa" && (
                                            <MenuOption
                                                style={{
                                                    borderBottomWidth: 1,
                                                    borderBottomColor: "#dacfcf",
                                                }}
                                                onSelect={() =>
                                                    startProgram(
                                                        item.program_index,
                                                        item.bermad_program_id,
                                                        item.omega_id
                                                    )
                                                }
                                                disabled={false}
                                                text={"Start Program"}
                                            />
                                        )}
                                        {/* {item.program_status === 0 && (
                                        <MenuOption
                                            style={{
                                                borderBottomWidth: 1,
                                                borderBottomColor: "#dacfcf",
                                            }}
                                            onSelect={() =>
                                                startProgram(
                                                    item.program_index,
                                                    item.bermad_program_id,
                                                    item.omega_id
                                                )
                                            }
                                            disabled={false}
                                            text={"Start Program"}
                                        />
                                    )} */}

                                        {/* {item.program_status === 1 && ( */}
                                        {colorGray === "#5afc4c" && (
                                            <View>
                                                <MenuOption
                                                    style={{
                                                        borderBottomWidth: 1,
                                                        borderBottomColor: "#dacfcf",
                                                    }}
                                                    onSelect={() =>
                                                        stopProgram(
                                                            item.program_index,
                                                            item.bermad_program_id,
                                                            item.omega_id
                                                        )
                                                    }
                                                    disabled={false}
                                                    text={"Stop Program"}
                                                />
                                                <MenuOption
                                                    style={{
                                                        borderBottomWidth: 1,
                                                        borderBottomColor: "#dacfcf",
                                                    }}
                                                    onSelect={() =>
                                                        pauseProgram(
                                                            item.program_index,
                                                            item.bermad_program_id,
                                                            item.omega_id
                                                        )
                                                    }
                                                    disabled={false}
                                                    text={"Pause Program"}
                                                />
                                            </View>
                                        )}
                                        {/* {item.program_status === 3 && ( */}
                                        {/* {colorGray === "#0fbf00" && (
                                        <View>
                                            <MenuOption
                                                style={{
                                                    borderBottomWidth: 1,
                                                    borderBottomColor: "#dacfcf",
                                                }}
                                                onSelect={() =>
                                                    stopProgram(
                                                        item.program_index,
                                                        item.bermad_program_id,
                                                        item.omega_id
                                                    )
                                                }
                                                disabled={false}
                                                text={"Stopped Program"}
                                            />
                                            <MenuOption
                                                style={{
                                                    borderBottomWidth: 1,
                                                    borderBottomColor: "#dacfcf",
                                                }}
                                                onSelect={() =>
                                                    pauseProgram(
                                                        item.program_index,
                                                        item.bermad_program_id,
                                                        item.omega_id
                                                    )
                                                }
                                                disabled={false}
                                                text={"Pause Program"}
                                            />
                                        </View>
                                    )} */}
                                        {/* {item.program_status === 2 && ( */}
                                        {colorGray === "#ffc401" && (
                                            <View>
                                                <MenuOption
                                                    style={{
                                                        borderBottomWidth: 1,
                                                        borderBottomColor: "#dacfcf",
                                                    }}
                                                    onSelect={() =>
                                                        stopProgram(
                                                            item.program_index,
                                                            item.bermad_program_id,
                                                            item.omega_id
                                                        )
                                                    }
                                                    disabled={false}
                                                    text={"Stop Program"}
                                                />
                                                <MenuOption
                                                    style={{
                                                        borderBottomWidth: 1,
                                                        borderBottomColor: "#dacfcf",
                                                    }}
                                                    onSelect={() =>
                                                        resumeProgram(
                                                            item.program_index,
                                                            item.bermad_program_id,
                                                            item.omega_id
                                                        )
                                                    }
                                                    disabled={false}
                                                    text={"Resume Program"}
                                                />
                                            </View>
                                        )}

                                        <MenuOption
                                            style={{
                                                borderBottomWidth: 1,
                                                borderBottomColor: "#dacfcf",
                                            }}
                                            onSelect={() =>
                                                updatePrograms(
                                                    item.id,
                                                    item.status == "NON-ACTIVE" ? "ACTIVE" : "NON-ACTIVE"
                                                )
                                            }
                                            disabled={false}
                                            text={item.status == "NON-ACTIVE" ? "Enable" : "Disabled"}
                                        />
                                        <MenuOption
                                            style={{
                                                borderBottomWidth: 1,
                                                borderBottomColor: "#dacfcf",
                                            }}
                                            onSelect={() => {
                                                setrename(true), setrenameEditable(index);
                                            }}
                                        >
                                            <Text style={{ color: "black" }}>Rename</Text>
                                        </MenuOption>
                                    </MenuOptions>
                                </Menu>
                            </View>
                        }
                    </View>

                    {/* block  row 1 col 3 */}
                </View>
                <View
                    style={{
                        alignSelf: "flex-start",
                        width: "100%",
                        marginTop: 0,
                        flexDirection: "row",
                    }}
                >
                    {/* block  row 2 col 1 */}
                    {/* block  row 2 col 2 */}
                    <View style={{ borderRightWidth: 3, borderRightColor: "#DEDEDE" }}>
                        {/* <View style={{ height: '20%' }}> */}
                        <View
                            style={{
                                alignItems: "center",
                                marginHorizontal: 5,
                                flexDirection: "row",
                                marginVertical: 5,
                            }}
                        >
                            <Text style={[{ flex: 0.9 }, styles.schedulHead]}>
                                Program Type
                            </Text>
                            <Dropdown
                                ref={dropdownRefPT}
                                style={{
                                    borderColor: "#c3c3c3",
                                    // width: '50%',
                                    width: "43%",
                                    alignItems: "center",
                                    borderWidth: 1,
                                    borderRadius: 5,
                                    height: 25,
                                    padding: 10,
                                }}
                                // placeholderStyle={styles.placeholderStyle}
                                // selectedTextStyle={styles.selectedTextStyle}
                                inputSearchStyle={styles.inputSearchStyle}
                                // iconStyle={styles.iconStyle}
                                search
                                data={ProgramTypeIdData}
                                maxHeight={300}
                                labelField="name"
                                valueField="name"
                                placeholder={
                                    item.type.charAt(0).toUpperCase() +
                                    item.type.slice(1).toLowerCase()
                                }
                                // placeholderStyle={{ textTransform: 'capitalize' }}
                                searchPlaceholder="Search..."
                                value={
                                    OmegaDetails[index].type.charAt(0).toUpperCase() +
                                    OmegaDetails[index].type.slice(1).toLowerCase()
                                }
                                onChange={(item) => {
                                    // var dpIndex = ProgramTypeIdData.findIndex(data => data.name === item.name)
                                    // ProgramTypeIdData[dpIndex].name = item.name
                                    OmegaDetails[index].type = item.name;
                                    // // console.log('dpIndex', dpIndex)
                                    setprogramTypeIdValue(item.name);
                                    setprogramTypeValueiD(item.id);
                                    // getOmegaDetails(item.omega_id);

                                    setOmegaError("");
                                    // getOmegaDetails(item.id);
                                    // setProjectOmegaId(item.project_id)
                                    // console.log("omega id value---", item.name);
                                    setrefreshflatlist(!refreshflatlist);
                                }}
                                renderItem={renderProgramType}
                            />
                        </View>
                        <View style={{ marginTop: 5 }}>
                            {item.type === "WEEKLY" && (
                                <View
                                    style={{
                                        alignItems: "center",
                                        marginHorizontal: 5,
                                        flexDirection: "row",
                                    }}
                                >
                                    <Text style={[{ flex: 0.9 }, styles.schedulHead]}>
                                        Program Days
                                    </Text>

                                    <MultiSelect
                                        hideTags
                                        fixedHeight={true}
                                        items={daysData}
                                        uniqueKey="id"
                                        onSelectedItemsChange={(value) =>
                                            onSelectedItemsChange(value, mainindex)
                                        }
                                        selectedItems={item.idarr}
                                        // selectedItems={(value: any) => SelectedDayitem(value, mainindex)}
                                        // selectedItems={(value) =>SelectedDayitem(value,mainindex)}

                                        selectText="Select"
                                        searchInputPlaceholderText="Search Items Here..."

                                        tagRemoveIconColor="#CCC"
                                        tagBorderColor="#CCC"
                                        tagTextColor="#CCC"
                                        selectedItemTextColor="#009DF0"
                                        selectedItemIconColor="#CCC"
                                        itemTextColor="#000"
                                        displayKey="name"
                                        submitButtonColor="#fff"
                                        submitButtonText="Submit"
                                        searchInputStyle={styles.inputSearchStyle}
                                        styleDropdownMenu={{ backgroundColor: "#fff" }}

                                    />
                                </View>
                            )}

                            {item.type === "CYCLIC" && (
                                <View
                                    style={{
                                        marginTop: 1,
                                        height: 90,

                                        width: "95%",
                                        alignSelf: "center",
                                        // backgroundColor: "grey",
                                    }}
                                >
                                    <View
                                        style={{
                                            alignItems: "center",
                                            flexDirection: "row",
                                            marginTop: 5,
                                        }}
                                    >
                                        <Text style={[{ flex: 0.9 }, styles.schedulHead]}>
                                            Start Date
                                        </Text>
                                        <View style={{ width: "43%" }}>
                                            <Text
                                                style={{
                                                    borderColor: "gray",
                                                    borderWidth: 1,
                                                    textAlign: "center",
                                                    // marginHorizontal:10,
                                                    borderRadius: 5,
                                                    // marginLeft: 20,
                                                    padding: 2,
                                                }}
                                                onPress={() => {
                                                    setindexforconfirmstarttime(index);
                                                    checkOpenDate(index);
                                                }}
                                            >
                                                {/* {item.startDate ? moment(item.startDate).format("DD/MM/YYYY") : "Select Date "} */}
                                                {/* {moment(date).format("DD/MM/YYYY") == moment(new Date()).format("DD/MM/YYYY") ? "Select Date " : moment(date).format("DD/MM/YYYY")} */}
                                                {/* {open === true ? moment(date).format("YYYY-MM-DD") : moment(item.startDate).format("YYYY-MM-DD")} */}
                                                {moment(item.startDate).format("YYYY-MM-DD")}
                                            </Text>
                                            <DatePickerModal
                                                locale="en"
                                                mode="single"
                                                visible={open}
                                                onDismiss={onDismissSingle}
                                                date={date}
                                                onConfirm={onConfirmSingle}
                                                validRange={{
                                                    endDate: new Date(), // optional
                                                }}
                                                label="Select date" // optional
                                                closeIcon="close" // optional, default is "close"
                                                calendarIcon="calendar" // optional, default is "calendar"
                                            />
                                        </View>
                                    </View>
                                    <View
                                        style={{
                                            flex: 1,
                                            alignItems: "center",
                                            flexDirection: "row",
                                        }}
                                    >
                                        <Text style={[{ flex: 0.9 }, styles.schedulHead]}>
                                            Day's Interval
                                        </Text>
                                        <View style={{ flexDirection: "row", width: "37%" }}>
                                            <TouchableOpacity
                                                onPress={() => {
                                                    setCounter(item.cycleIntervalDays),
                                                        item.cycleIntervalDays > 0 &&
                                                        (item.cycleIntervalDays =
                                                            item.cycleIntervalDays - 1),
                                                        setrefreshflatlist(!refreshflatlist);
                                                }}
                                            >
                                                <Text style={{ marginHorizontal: 5 }}>
                                                    <IconPlusMinus
                                                        color="#767676"
                                                        name="minuscircleo"
                                                        size={20}
                                                    />
                                                </Text>
                                            </TouchableOpacity>

                                            <Text
                                                style={{
                                                    borderWidth: 1,
                                                    marginHorizontal: 5,
                                                    paddingHorizontal: 5,
                                                    borderColor: "#707070",
                                                    borderRadius: 6,
                                                }}
                                            >
                                                {item.cycleIntervalDays}{" "}
                                            </Text>

                                            <TouchableOpacity
                                                onPress={() => {
                                                    setCounter(item.cycleIntervalDays),
                                                        item.cycleIntervalDays < 15 &&
                                                        (item.cycleIntervalDays =
                                                            item.cycleIntervalDays + 1),
                                                        setrefreshflatlist(!refreshflatlist);
                                                }}
                                            >
                                                <Text style={{ marginHorizontal: 5 }}>
                                                    <IconPlusMinus
                                                        color="#767676"
                                                        name="pluscircleo"
                                                        size={20}
                                                    />
                                                </Text>
                                            </TouchableOpacity>
                                        </View>
                                    </View>
                                </View>
                            )}
                        </View>

                        {/* </View> */}

                        <View style={{ height: "36%" }}>
                            <View
                                style={{
                                    alignItems: "center",
                                    marginHorizontal: 5,
                                    flexDirection: "row",
                                    marginTop: 10,
                                }}
                            >
                                <Text style={[{ flex: 0.9 }, styles.schedulHead]}>
                                    Program Start
                                </Text>
                                {/* <View style={{ borderWidth: 1, borderColor: 'grey', borderRadius: 5 }}> */}
                                <Dropdown
                                    ref={dropdownRefIS}
                                    style={{
                                        borderColor: "#c3c3c3",
                                        width: "40%",
                                        alignItems: "center",
                                        borderWidth: 1,
                                        borderRadius: 5,
                                        height: 30,
                                        padding: 10,
                                    }}
                                    // placeholderStyle={styles.placeholderStyle}
                                    // selectedTextStyle={styles.selectedTextStyle}
                                    inputSearchStyle={styles.inputSearchStyle}
                                    // iconStyle={styles.iconStyle}
                                    search
                                    data={IrrigationStartData}
                                    maxHeight={300}
                                    labelField="name"
                                    valueField="name"
                                    placeholder={"select "}
                                    searchPlaceholder="Search..."
                                    value={
                                        OmegaDetails[index].cycleTypeIsCyclic == true
                                            ? "Cyclic"
                                            : "Hours"
                                    }
                                    onChange={(item) => {
                                        // console.log("itemitem", item);

                                        // OmegaDetails[index].cycleTypeIsCyclic = item.name == 'Hours' ? true : false;
                                        OmegaDetails[index].cycleTypeIsCyclic =
                                            item.id == 1 ? true : false;

                                        setIrrigationStartSelect(item.name);

                                        // setprogramTypeValueiD(item.id);
                                    }}
                                    renderItem={renderIrrigation}
                                />

                                {/* </View> */}
                            </View>
                            {OmegaDetails[index].cycleTypeIsCyclic == false && (
                                <View>
                                    <View
                                        style={{
                                            alignItems: "center",
                                            marginHorizontal: 5,
                                            flexDirection: "row",
                                            marginTop: 5,
                                        }}
                                    >
                                        <Text style={[{ flex: 0.7 }, styles.schedulHead]}>
                                            Program Start Times
                                        </Text>

                                        {OmegaDetails[index].cycleTypeHours.length <= 3 ? (
                                            <Pressable
                                                onPress={() => {
                                                    setindexforconfirmstarttime(index),
                                                        checkProgramVisible(index);
                                                }}
                                                style={{
                                                    borderWidth: 1,
                                                    width: "40%",
                                                    marginBottom: 20,
                                                    marginRight: 20,
                                                    borderColor: "#707070",
                                                    borderRadius: 5,
                                                    backgroundColor: "#EBEBEB",
                                                    padding: 5,
                                                    alignItems: "center",
                                                    alignSelf: "flex-end",
                                                    marginRight: -50,
                                                    marginTop: 15
                                                }}
                                            >
                                                <Text
                                                    style={[
                                                        styles.valvebtnText,
                                                        { textAlign: "center", fontWeight: "400" },
                                                    ]}
                                                >
                                                    Add start time
                                                </Text>
                                            </Pressable>
                                        ) : (
                                            <Pressable
                                                disabled
                                                onPress={() => {
                                                    setindexforconfirmstarttime(index),
                                                        checkProgramVisible(index);
                                                }}
                                                style={{
                                                    backgroundColor: "#EBEBEB",
                                                    width: "40%",
                                                    marginRight: 20,
                                                    marginBottom: 20,
                                                    padding: 5,
                                                    alignItems: "center",
                                                    borderWidth: 1,
                                                    borderRadius: 5,
                                                    borderColor: "#707070",
                                                    alignSelf: "flex-end",
                                                    marginRight: -50,
                                                    marginTop: 15

                                                }}
                                            >
                                                <Text
                                                    style={{
                                                        fontWeight: "400",
                                                        textAlign: "center",
                                                        color: "#CCC",
                                                    }}
                                                >
                                                    Add start time
                                                </Text>
                                            </Pressable>
                                        )}
                                        <TimePickerModal
                                            visible={programStartTimeVisible}
                                            onDismiss={onDismissProgramStartTime}
                                            onConfirm={onConfirmProgramStartTime}
                                            // data={new Date(item.hourlyEnd * 1000).toISOString().slice(11, 19)}
                                            data={programStartTime}
                                            hours={0o0} // default: current hours
                                            minutes={0o0} // default: current minutes
                                            locale="de"
                                        // label="hh:mm:ss" // optional, default 'Select time'
                                        // uppercase={false} // optional, default is true
                                        // cancelLabel="Cancel" // optional, default: 'Cancel'
                                        // confirmLabel="Ok" // optional, default: 'Ok'
                                        // animationType="fade" // optional, default is 'none'
                                        // locale="en" // optional, default is automically detected by your system
                                        // keyboardIcon="keyboard-outline" // optional, default is "keyboard-outline"
                                        // clockIcon="clock-outline" // optional, default is "clock-outline"
                                        />

                                        {/* </View> */}
                                    </View>
                                    <View>
                                        <FlatList
                                            style={{ width: "100%", marginTop: -10 }}
                                            extraData={refreshflatlist}
                                            contentContainerStyle={{ height: 90 }}
                                            data={OmegaDetails[index].cycleTypeHours}
                                            renderItem={(item) =>
                                                renderProgramStartTimeItems(item, index)
                                            }
                                            keyExtractor={(item, index) => item.index}
                                        />
                                    </View>

                                </View>
                            )}
                            {OmegaDetails[index].cycleTypeIsCyclic == true && (
                                <View>
                                    <View
                                        style={{
                                            flex: 1,
                                            alignItems: "center",
                                            marginHorizontal: 5,
                                            flexDirection: "row",
                                            marginTop: 5,
                                        }}
                                    >
                                        <Text style={[{ flex: 0.9 }, styles.schedulHead]}>
                                            Program Start Time
                                        </Text>
                                        <View
                                            style={{
                                                borderWidth: 1,
                                                borderColor: "grey",
                                                width: "40%",
                                                height: 30,
                                                alignSelf: "center",
                                                alignItems: "center",
                                                borderRadius: 5,
                                            }}
                                        >
                                            <Text
                                                style={{ margin: 5 }}
                                                onPress={() => {
                                                    setindexforconfirmstarttime(index);
                                                    checktimestartvisible(index);
                                                }}
                                            >
                                                {/* {timeStart ? moment(timeStart).format("HH:mm:ss") : moment(item.hourlyStart).format("HH:mm:ss")} */}
                                                {new Date(item.hourlyStart * 1000)
                                                    .toISOString()
                                                    .slice(11, 19)}
                                            </Text>

                                            <TimePickerModal
                                                visible={timeStartVisible}
                                                onDismiss={onDismissStartTime}
                                                onConfirm={onConfirmStartTime}
                                                // data={new Date(item.hourlyEnd * 1000).toISOString().slice(11, 19)}
                                                data={timeStart}
                                                hours={0o0} // default: current hours
                                                minutes={0o0} // default: current minutes
                                                locale="de"
                                            // label="hh:mm:ss" // optional, default 'Select time'
                                            // uppercase={false} // optional, default is true
                                            // cancelLabel="Cancel" // optional, default: 'Cancel'
                                            // confirmLabel="Ok" // optional, default: 'Ok'
                                            // animationType="fade" // optional, default is 'none'
                                            // locale="en" // optional, default is automically detected by your system
                                            // keyboardIcon="keyboard-outline" // optional, default is "keyboard-outline"
                                            // clockIcon="clock-outline" // optional, default is "clock-outline"
                                            />
                                        </View>
                                    </View>
                                    <View
                                        style={{
                                            flex: 1,
                                            alignItems: "center",
                                            marginHorizontal: 5,
                                            flexDirection: "row",
                                            marginTop: 5,
                                        }}
                                    >
                                        <Text style={[{ flex: 0.9 }, styles.schedulHead]}>
                                            Program End Time
                                        </Text>
                                        <View
                                            style={{
                                                borderWidth: 1,
                                                borderColor: "grey",
                                                width: "40%",
                                                height: 30,
                                                alignSelf: "center",
                                                alignItems: "center",
                                                borderRadius: 5,
                                            }}
                                        >
                                            <Text
                                                style={{ margin: 5 }}
                                                onPress={() => {
                                                    setindexforconfirmstarttime(index);
                                                    checktimeEndvisible(index);
                                                }}
                                            >
                                                {/* {new Date(item.hourlyEnd * 1000).toISOString().slice(11, 19)} */}
                                                {/* {moment(item.hourlyEnd).format("HH:mm:ss")} */}
                                                {new Date(item.hourlyEnd * 1000)
                                                    .toISOString()
                                                    .slice(11, 19)}

                                                {/* {item.hourlyEnd ? moment(item.hourlyEnd).format("HH:mm:ss") : "Select Time "} */}
                                                {/* {timeVisible === true ? moment(times).format("HH:mm:ss") : moment(item.hourlyEnd).format("HH:mm:ss")} */}
                                            </Text>

                                            <TimePickerModal
                                                visible={timeVisible}
                                                onDismiss={onDismissTime}
                                                onConfirm={onConfirmTime}
                                                // data={new Date(item.hourlyEnd * 1000).toISOString().slice(11, 19)}
                                                data={times}
                                                hours={0o0} // default: current hours
                                                minutes={0o0} // default: current minutes
                                                locale="de"
                                            // label="hh:mm:ss" // optional, default 'Select time'
                                            // uppercase={false} // optional, default is true
                                            // cancelLabel="Cancel" // optional, default: 'Cancel'
                                            // confirmLabel="Ok" // optional, default: 'Ok'
                                            // animationType="fade" // optional, default is 'none'
                                            // locale="en" // optional, default is automically detected by your system
                                            // keyboardIcon="keyboard-outline" // optional, default is "keyboard-outline"
                                            // clockIcon="clock-outline" // optional, default is "clock-outline"
                                            />
                                        </View>
                                    </View>
                                    <View
                                        style={{
                                            flex: 1,
                                            alignItems: "center",
                                            marginHorizontal: 5,
                                            flexDirection: "row",
                                            marginTop: 5,
                                        }}
                                    >
                                        <Text style={[{ flex: 0.9 }, styles.schedulHead]}>
                                            Hours Interval
                                        </Text>
                                        <View
                                            style={{
                                                borderWidth: 1,
                                                borderColor: "grey",
                                                width: "40%",
                                                height: 30,
                                                alignSelf: "center",
                                                alignItems: "center",
                                                borderRadius: 5,
                                            }}
                                        >
                                            {/* {new Date(item.cycleIntervalHours * 1000).toISOString().slice(11, 19)} */}

                                            <Text
                                                style={{ margin: 5 }}
                                                onPress={() => {
                                                    setindexforconfirmstarttime(index);
                                                    checkTimeIntervalvisible(index);
                                                }}
                                            >
                                                {/* {new Date(item.hourlyEnd * 1000).toISOString().slice(11, 19)} */}
                                                {/* {moment(item.hourlyEnd).format("HH:mm:ss")} */}
                                                {/* {item.hourlyEnd ? moment(item.hourlyEnd).format("HH:mm:ss") : "Select Time "} */}
                                                {/* {timeHIVisible === true ? moment(timeHoursInterval).format("HH:mm:ss") : moment(item.cycleIntervalHours).format("HH:mm:ss")} */}
                                                {new Date(item.cycleIntervalHours * 1000)
                                                    .toISOString()
                                                    .slice(11, 19)}
                                            </Text>

                                            <TimePickerModal
                                                visible={timeHIVisible}
                                                onDismiss={onDismissHITime}
                                                onConfirm={onConfirmHITime}
                                                // data={new Date(item.hourlyEnd * 1000).toISOString().slice(11, 19)}
                                                data={timeHoursInterval}
                                                hours={0o0} // default: current hours
                                                minutes={0o0} // default: current minutes
                                                locale="de"
                                            // label="hh:mm:ss" // optional, default 'Select time'
                                            // uppercase={false} // optional, default is true
                                            // cancelLabel="Cancel" // optional, default: 'Cancel'
                                            // confirmLabel="Ok" // optional, default: 'Ok'
                                            // animationType="fade" // optional, default is 'none'
                                            // locale="en" // optional, default is automically detected by your system
                                            // keyboardIcon="keyboard-outline" // optional, default is "keyboard-outline"
                                            // clockIcon="clock-outline" // optional, default is "clock-outline"
                                            />
                                        </View>
                                    </View>
                                </View>
                            )}
                        </View>

                        <View
                            style={{
                                marginTop: 6,
                                height: 0.5,
                                width: "100%",
                                alignSelf: "center",
                                backgroundColor: "grey",
                            }}
                        />
                        {/* <View style={{ alignItems: 'center', marginHorizontal: 5, flexDirection: 'row', marginTop: 10 }}>
                            <View style={{ flexDirection: "row", flex: 0.75, alignItems: 'center' }}>
                                <RadioButton
                                    value={item.measurementype}
                                    status={item.measurementype === "Quantity" ? "checked" : "unchecked"}
                                    onPress={() => {
                                        // console.log('measurementType=11=>', OmegaDetails[index].measurementType)
                                        OmegaDetails[index].measurementType = "Quantity"
                                        // console.log('measurementType=33=>', OmegaDetails[index].measurementType)
                                        setOmegaDetails(OmegaDetails),
                                            setMeasureType(OmegaDetails[index].measurementType)
                                        setrefreshflatlist(!refreshflatlist)
                                    }}
                                />
                                <Text style={{ fontSize: 12, color: "#707070", }}>
                                    Quantity
                                </Text>
                                <RadioButton
                                    value={item.measurementype}
                                    status={item.measurementype === "Duration" ? "checked" : "unchecked"}
                                    onPress={() => {
                                        // console.log('measurementType=22=>', OmegaDetails[index].measurementType)
                                        OmegaDetails[index].measurementType = "Duration"
                                        // console.log('measurementType=44=>', OmegaDetails[index].measurementType)
                                        setOmegaDetails(OmegaDetails)
                                        setMeasureType(OmegaDetails[index].measurementType)
                                        setrefreshflatlist(!refreshflatlist)
                                    }}
                                />
                                <Text style={{ fontSize: 12, color: "#707070" }}>
                                    Duration
                                </Text>
                            </View>
                        </View> */}
                        <View
                            style={{
                                height:
                                    item.ProgramsAmountData.length == 0
                                        ? 130
                                        : item.ProgramsAmountData.length == 1
                                            ? 200
                                            : item.ProgramsAmountData.length == 2
                                                ? 250
                                                : item.ProgramsAmountData.length > 2
                                                    ? 250
                                                    : 250,
                            }}
                        >
                            <View
                                style={{
                                    alignItems: "center",
                                    marginHorizontal: 5,
                                    flexDirection: "row",
                                    marginTop: 10,
                                }}
                            >
                                <View
                                    style={{
                                        flexDirection: "row",
                                        flex: 1,
                                        alignItems: "center",
                                    }}
                                >
                                    <Text style={[{ flex: 0.7 }, styles.schedulHead]}>
                                        Measurement Type
                                    </Text>
                                    {OmegaDetails[index].measurementype && (
                                        <Text
                                            style={{
                                                textAlign: "center",
                                                fontSize: 14,
                                                flex: 0.3,
                                                color: "black",
                                                borderWidth: 1,
                                                borderColor: "grey",
                                                padding: 3,
                                                borderRadius: 3,
                                            }}
                                        >
                                            {OmegaDetails[index].measurementype}
                                        </Text>
                                    )}
                                </View>
                            </View>

                            `   <View style={{ marginHorizontal: 5, marginTop: -5 }}>
                                <View style={{ flexDirection: "row" }}>
                                    <Text style={[{ flex: 1 }, styles.schedulHead]}>
                                        Valve Sequence in Program{" "}
                                    </Text>
                                    <Pressable
                                        onPress={() => {
                                            setProgramAmountDetails(
                                                OmegaDetails[index].ProgramsAmountData

                                            );
                                            // console.log("OmegaDetails[index].ProgramsAmountData-=======22", item.ProgramsAmountData, OmegaDetails[index].ProgramsAmountData);

                                            setmeasurementType(item.measurementype), setvalveamount(0);
                                            setprogramAmount(item.ProgramsAmountData.sort()),
                                                isCreatable(index);
                                            setupdatedata(
                                                ProgramAmountDetails.length > 0 ? false : true
                                            );
                                            OmegaDetails[index].ProgramsAmountData = [];
                                        }}
                                        style={{
                                            flexDirection: "row",
                                            backgroundColor: "#EBEBEB",
                                            padding: 5,
                                            borderWidth: 1,
                                            marginRight: 5,
                                            borderColor: "#707070",
                                            borderRadius: 5,
                                            alignItems: "center",
                                            alignSelf: "flex-end",
                                            marginVertical: 10,
                                            marginTop: -5,
                                            marginLeft: 40
                                        }}
                                    >
                                        <Icon
                                            color="#747474"
                                            name="plus"
                                            size={15}
                                            style={{ marginRight: 4 }}
                                        />
                                        <Text style={styles.valvebtnText}>Add Valve</Text>
                                    </Pressable>
                                </View>
                                <FlatList
                                    // horizontal
                                    contentContainerStyle={{
                                        // height:
                                        //     item.ProgramsAmountData.length == 0
                                        //         ? 20
                                        //         : item.ProgramsAmountData.length == 1
                                        //             ? 50
                                        //             : item.ProgramsAmountData.length == 2
                                        //                 ? 90
                                        //                 : item.ProgramsAmountData.length > 2
                                        //                     ? 100
                                        //                     : "auto",
                                        height: item.ProgramsAmountData.length == 0
                                            ? 20
                                            : item.ProgramsAmountData.length == 1
                                                ? 50
                                                : item.ProgramsAmountData.length == 2
                                                    ? 90
                                                    : item.ProgramsAmountData.length > 4
                                                        ? 170
                                                        : 170,
                                    }}
                                    data={item.ProgramsAmountData}
                                    renderItem={({ item, index }) =>
                                        renderValveItems(
                                            item,
                                            index,
                                            mainindex,
                                            OmegaDetails[mainindex].measurementype,
                                            OmegaDetails[mainindex].program_index,
                                            leftover,
                                            mainsIndex,
                                            valveIndex
                                        )
                                    }
                                    extraData={refreshflatlist}
                                    // keyExtractor={item => item.id}
                                    // numColumns={3}
                                    keyExtractor={(item, index) => item.index}
                                    scrollEnabled={item.ProgramsAmountData.length > 4}
                                />`

                                <Text style={[styles.error_text]}>{valve_error}</Text>
                            </View>
                        </View>
                    </View>
                </View>
                {/* block  row 3 col 1,2,3,4,5 */}

                {/* block  row 4 col 1,2,3,4,5 */}
                <View
                    style={{
                        marginVertical: 10,
                        height: 0.5,
                        width: "100%",
                        alignSelf: "center",
                        backgroundColor: "grey",
                    }}
                />
                <View
                    style={{
                        minHeight: "30%",
                        alignSelf: "flex-start",
                        marginTop: 0,
                        width: "100%",
                        flexDirection: "row",
                    }}
                >
                    {/* block  row 4 col 1 */}
                    {/* block  row 4 col 2 */}
                    <View
                        style={{
                            width: "100%",
                            borderRightWidth: 3,
                            borderLeftWidth: 3,
                            borderBottomWidth: 1,
                            borderBottomColor: "#DEDEDE",
                            borderRightColor: "#DEDEDE",
                            borderLeftColor: "#DEDEDE",
                        }}
                    >
                        <View
                            style={{
                                alignItems: "center",
                                flexDirection: "row",
                                marginBottom: 0,
                            }}
                        >
                            <Text style={[{ flex: 0.9, marginLeft: 5 }, styles.schedulHead]}>
                                Program Action
                            </Text>
                            <Dropdown
                                style={{
                                    borderColor: "#c3c3c3",
                                    width: "45%",
                                    borderWidth: 1,
                                    borderRadius: 5,
                                    height: 20,
                                    padding: 10,
                                }}
                                placeholderStyle={styles.placeholderStyle}
                                selectedTextStyle={styles.selectedTextStyle}
                                inputSearchStyle={styles.inputSearchStyle}
                                iconStyle={styles.iconStyle}
                                search
                                data={programActions}
                                maxHeight={300}
                                labelField="name"
                                valueField="name"
                                placeholder={
                                    programActionsSelect ? programActionsSelect : "select action"
                                }
                                searchPlaceholder="Search..."
                                value={programActionsSelect}
                                onChange={(item) => {
                                    setprogramActionsSelect(item.name);
                                    setprogramActionsSelectId(item.id);
                                    // setIrrigationNewStartSelectId(item.id);
                                    // setIrrigationStartSelect(item.name);
                                    // setprogramTypeValueiD(item.id);
                                }}
                                renderItem={renderProgAction}
                            />
                        </View>
                        {/* <View style={{ flex: 1, alignItems: 'center', flexDirection: 'row', marginTop: 0 }}>
                            <Pressable style={{ flex: 0.45, borderRadius: 5, alignItems: 'center', backgroundColor: '#0089D1' }}><Text style={{ fontSize: 10, color: 'white', marginVertical: 5 }}>PAUSE SETTINGS</Text></Pressable>
                            <Pressable style={{ flex: 0.45, borderRadius: 5, alignItems: 'center', marginLeft: 10, backgroundColor: '#0089D1' }}><Text style={{ fontSize: 10, color: 'white', marginVertical: 5 }}>RESUME SETTINGS</Text></Pressable>
                        </View> */}
                        {programActionsSelect === "None" ? null :
                            <View>
                                <View
                                    style={{
                                        flex: 1,
                                        alignItems: "center",
                                        marginHorizontal: 5,
                                        flexDirection: "row",
                                        marginTop: 10,
                                    }}
                                >
                                    <View style={{ flex: 0.85 }}>
                                        <Text
                                            style={[{ flex: 0.9, marginBottom: 5 }, styles.schedulHead]}
                                        >
                                            Device
                                        </Text>
                                        <Dropdown
                                            ref={dropdownRefDI}
                                            style={{
                                                borderColor: "#c3c3c3",
                                                width: "80%",
                                                borderWidth: 1,
                                                borderRadius: 5,
                                                height: 25,
                                                padding: 10,
                                            }}
                                            data={deviceNameData}
                                            search
                                            maxHeight={300}
                                            labelField="device_name"
                                            valueField="device_name"
                                            placeholder={!isFocus ? "Select" : "...."}
                                            searchPlaceholder="Search..."
                                            // value={item.device_name}
                                            value={item.device_name}
                                            onChange={(item) => {
                                                // item.device_name = value.device_name
                                                setDeviceNameIdValue(item.device_name);
                                                setDeviceNameValueiD(item.id);
                                                // // getOmegaDetails(item.omega_id);
                                                getSensorOmegaDetails(item.id);
                                                setOmegaError("");
                                                // getOmegaDetails(item.id);
                                                // setProjectOmegaId(item.project_id)
                                                // console.log("device id value---", item.id);
                                                // setrefreshflatlist(!refreshflatlist)
                                            }}
                                            renderItem={renderDeviceNameListItems}
                                        />
                                        <Text style={[styles.error_text]}>{deviceError}</Text>
                                    </View>
                                    {/* <View style={{ flex: 0.5 }}>
                                <Text style={[{ flex: 0.9 }, styles.schedulHead]}>Device</Text>
                                <Dropdown
                                    style={{
                                        borderColor: "#c3c3c3",
                                        width: '80%',
                                        borderWidth: 1,
                                        borderRadius: 5,
                                        height: 20,
                                        padding: 10,
                                    }}
                                    placeholderStyle={styles.placeholderStyle}
                                    selectedTextStyle={styles.selectedTextStyle}
                                    inputSearchStyle={styles.inputSearchStyle}
                                    iconStyle={styles.iconStyle}
                                    search
                                    maxHeight={300}
                                    labelField="zone_name"
                                    valueField="zone_name"
                                    placeholder={!isFocus ? "dev_0155" : "...."}
                                    searchPlaceholder="Search..."
                                />
                            </View> */}
                                </View>
                                <View
                                    style={{
                                        flex: 1,
                                        alignItems: "center",
                                        marginHorizontal: 5,
                                        flexDirection: "row",
                                        marginTop: 10,
                                    }}
                                >
                                    <View style={{ flex: 0.85 }}>
                                        <Text
                                            style={[{ flex: 0.9, marginBottom: 5 }, styles.schedulHead]}
                                        >
                                            Sensor name
                                        </Text>
                                        <Dropdown
                                            ref={dropdownRefST}
                                            style={{
                                                borderColor: "#c3c3c3",
                                                width: "80%",
                                                borderWidth: 1,
                                                borderRadius: 5,
                                                height: 20,
                                                padding: 10,
                                            }}
                                            data={sensorTypeDetails}
                                            search
                                            maxHeight={300}
                                            labelField="name"
                                            valueField="name"
                                            placeholder={DeviceNameIdValue == "None" ? "None " : "Select"}
                                            searchPlaceholder="Search..."
                                            value={item.name}
                                            onChange={(value) => {
                                                item.name = value.name;
                                                setSensorIdValue(item.name);
                                                setSensorValueiD(item.id);
                                                setSensorvalueType(item.type);
                                                // getOmegaDetails(item.id);
                                                // setProjectOmegaId(item.project_id)
                                                // console.log("setSensorvalueType id value---", item.id);
                                                setrefreshflatlist(!refreshflatlist);
                                            }}
                                            renderItem={renderSensorListItems}
                                        />
                                        <Text style={[styles.error_text]}>{StatusError}</Text>
                                    </View>
                                </View>
                                <View
                                    style={{
                                        flex: 1,
                                        alignItems: "center",
                                        marginHorizontal: 5,
                                        flexDirection: "row",
                                        marginVertical: 10,
                                    }}
                                >
                                    <View style={{ flex: 0.85 }}>
                                        <Text
                                            style={[{ flex: 0.9, marginBottom: 5 }, styles.schedulHead]}
                                        >
                                            Value
                                        </Text>
                                        <View
                                            style={{
                                                flexDirection: "row",
                                                borderRadius: 5,
                                                width: "80%",
                                                borderColor: "#707070",
                                            }}
                                        >
                                            {SensorvalueType == 2 ? (
                                                <View
                                                    style={{
                                                        height: 20,
                                                        width: "80%",
                                                        marginHorizontal: 2,
                                                        // alignSelf: "center",
                                                        // backgroundColor: "grey",
                                                    }}
                                                >
                                                    <TextInput value=""></TextInput>
                                                </View>
                                            ) : (
                                                <View>
                                                    <Dropdown
                                                        ref={dropdownRefVS}
                                                        style={{
                                                            borderColor: "#c3c3c3",
                                                            width: "100%",
                                                            borderWidth: 1,
                                                            borderRadius: 5,
                                                            height: 20,
                                                            padding: 10,
                                                        }}
                                                        // placeholderStyle={styles.placeholderStyle}
                                                        // selectedTextStyle={styles.selectedTextStyle}
                                                        // inputSearchStyle={styles.inputSearchStyle}
                                                        // iconStyle={styles.iconStyle}
                                                        search
                                                        data={sensorStatus}
                                                        maxHeight={300}
                                                        labelField="name"
                                                        valueField="id"
                                                        placeholder={"Status"}
                                                        searchPlaceholder="Search..."
                                                        value={SensorIdValue}
                                                        onChange={(item) => {
                                                            setSensorStatusIdValue(item.name);
                                                            setSensorStatusiD(item.id);
                                                            // console.log("SensorIdValue id", item.id);
                                                            setrefreshflatlist(!refreshflatlist);
                                                        }}
                                                        renderItem={renderSensorType}
                                                    />
                                                    <Text style={[styles.error_text]}>{valveError}</Text>
                                                </View>
                                            )}
                                        </View>
                                    </View>
                                </View>
                            </View>
                        }

                        <View
                            style={{
                                alignSelf: "center",
                                marginBottom: 10,
                                marginTop: 5,
                                paddingTop: 2,
                                alignItems: "center",
                            }}
                        >
                            <TouchableOpacity
                                style={styles.addDevice}
                                // onPress={() => {
                                //     navigation.navigate("CreateScheduleProgramProfile");
                                // }}
                                onPress={() => {
                                    setProgramsId(item.id);
                                    setBermadProgramId(item.bermad_program_id);
                                    setUpdateProgramIndex(index);
                                    CheckUpdateEnteredData(index, item.id);
                                }}
                            >
                                <Text style={styles.btnText}>Save Changes</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>
            </View>
        );
    };

    const deleteSelectedElement = (
        id: any,
        name: any,
        mainindex: string | number
    ) => {
        // const filteredData = OmegaDetails[mainindex].ProgramsAmountData.filter((item: { valve: { id: any; }; }) => item.valve.id !== id);
        const filteredData = OmegaDetails[mainindex].ProgramsAmountData.filter(
            (item: { name: any }) => item.name !== name
        );

        //Updating List Data State with NEW Data.
        // console.log("filteredData", filteredData);
        // setValveDetails(filteredData);
        OmegaDetails[mainindex].ProgramsAmountData = filteredData;
        setrefreshflatlist(!refreshflatlist);
    };
    const deleteAddValveItems = (id: any, name: any) => {
        // console.log("AddValveItems", AddValveItems);

        const filteredvalueData = AddValveItems.filter(
            (item: { valve: { id: any } }) => item.valve.id !== id
        );
        setAddValveItems(filteredvalueData);
    };
    const deleteProgramStartTime = (id: any, index: string | number) => {
        // console.log("idid", id, index);
        // console.log("idid", OmegaDetails[index].cycleTypeHours);
        // const filteredData = OmegaDetails[index].cycleTypeHours.filter((item: any) => item !== id);
        // OmegaDetails[index].cycleTypeHours = filteredData
        OmegaDetails[index].cycleTypeHours.splice(id, 1);

        // console.log("CYCLE TYPE HOURS ==>", OmegaDetails[index].cycleTypeHours);

        setPrgStartData(OmegaDetails[index].cycleTypeHours);
        setrefreshflatlist(!refreshflatlist);
    };
    const deleteAddprogramItems = (id: any) => {
        // console.log("start time data", newprgStartData1);

        // console.log("id", id);

        newprgStartData1.splice(id, 1);

        // console.log("newPrgStartData1 =1=>", newprgStartData1);

        setNewPrgStartData1(newprgStartData1);
        setrefreshflatlist(!refreshflatlist);
        //     // console.log("id delete", id);

        //     // console.log("newPrgStartData1 =1=>", newprgStartData1);

        //     newprgStartData1.removeByValue(id);

        //     // console.log('newPrgStartData1 =2=>', newprgStartData1)

        //     const tempArr = [...newprgStartData1]

        //     setNewPrgStartData1(tempArr)

        // }

        // Array.prototype.removeByValue = function (val) {

        //     for (var i = 0; i < newprgStartData1.length; i++) {

        //         if (newprgStartData1[i] === val) {

        //             newprgStartData1.splice(i, 1);

        //             i--;

        //         }

        //     }

        // return newprgStartData1;
    };

    const renderValveItems = (
        item: {
            name:
            | string
            | number
            | boolean
            | React.ReactElement<any, string | React.JSXElementConstructor<any>>
            | React.ReactFragment
            | React.ReactPortal
            | null
            | undefined;
            amount: string | number | undefined;
            id: any;
            input_index: string | number;
        },
        index: number,
        mainindex: string | number,
        radiostatus: string,
        program_index: string | number,
        leftover: number,
        pindex: string | number,
        valveIndex: number
    ) => {
        // console.log("iitem", index);

        // console.log(
        //     "radiostatus ====programs indexs",

        //     program_index,
        //     pindex,
        //     program_index == pindex,

        //     "Valve indexs======",


        //     item.input_index,
        //     valveIndex,
        //     item.input_index == valveIndex,
        //     new Date(leftover * 1000).toISOString().slice(11, 19),
        //     radiostatus
        // );
        // console.log("item.amount", item.amount)
        return (
            <View
                style={{
                    flexDirection: "row",
                    borderRadius: 5,
                    marginTop: 5,
                    borderWidth: 1,
                    alignItems: "center",
                    borderColor: "#DEDEDE",
                }}
            >
                <View
                    style={{
                        borderRightWidth: 1,
                        flex: 0.4,
                        alignItems: "center",
                        borderColor: "#DEDEDE",
                        height: 35,
                    }}
                >
                    <Text style={{ flex: 0.25, marginVertical: 8 }}>{item.name}</Text>
                </View>

                {radiostatus === "Quantity" ? (
                    <View
                        style={{
                            flexDirection: "row",
                            borderRightWidth: 1,
                            borderRightColor: "#dedede",
                            margin: 2,
                        }}
                    >
                        <TextInput
                            style={{
                                width: 60,
                                textAlign: "center",
                                alignItems: "center",
                                borderColor: "transparent",
                                height: 35,
                            }}
                            value={item.amount}
                            onChangeText={(text) => {

                                (OmegaDetails[mainindex].ProgramsAmountData[index].amount =
                                    text),
                                    setvalveamount(text),
                                    setrefreshflatlist(!refreshflatlist);
                            }}
                        />

                        {/* <Text>{(() => sub('m', '3'))()}</Text> */}
                        <Text style={{ alignSelf: "center" }}>m³</Text>
                    </View>

                ) : (
                    <Pressable
                        onPress={() => {
                            setindexforconfirmstarttime(mainindex);
                            setIndexValve(index);
                            checkValveAmountVisible(mainindex, index);
                        }}
                        style={{
                            borderRightWidth: 1,
                            flex: 0.4,
                            alignItems: "center",
                            borderColor: "#DEDEDE",
                            height: 35,
                        }}
                    >
                        <Text style={{ flex: 0.25, marginVertical: 8 }}>
                            {new Date(item.amount * 1000).toISOString().slice(11, 19)}

                            {/* {ValveAmountTimeValues !== null ? new Date(ValveAmountTimeValues * 1000).toISOString().slice(11, 19) : new Date(item.amount * 1000).toISOString().slice(11, 19) } */}
                        </Text>
                    </Pressable>
                )}
                <View
                    style={{
                        borderRightWidth: 1,
                        flex: 0.45,
                        borderColor: "#DEDEDE",
                        alignItems: "center",
                        height: 27,
                    }}
                >
                    <Text style={{ flex: 0.25, fontSize: 10 }}>Left Over</Text>
                    {item.input_index == valveIndex && leftover > 0 ?
                        <Text style={{ flex: 0.25 }}>
                            {radiostatus === "Duration"
                                ? new Date(leftover * 1000).toISOString().slice(11, 19)
                                : leftover}
                        </Text> :
                        <Text style={{ flex: 0.25 }}>0</Text>
                    }

                </View>
                <View
                    style={{
                        flex: 0.2,
                        alignItems: "center",
                        borderColor: "#DEDEDE",
                        height: 23,
                    }}
                >
                    <IconCross
                        name="circle-with-cross"
                        color="red"
                        size={20}
                        onPress={() => deleteSelectedElement(item.id, item.name, mainindex)}
                    />
                </View>
                <TimePickerModal
                    visible={ValveAmountTimeVisible}
                    onDismiss={onDismissValveAmountTime}
                    onConfirm={(valveIndex) => onConfirmValveAmountTime(valveIndex, index)}
                    data={ValveAmountTime}
                    hours={0o0} // default: current hours
                    minutes={0o0} // default: current minutes
                    locale="de"

                // label="h:mm:ss" // optional, default 'Select time'
                // uppercase={false} // optional, default is true
                // cancelLabel="Cancel" // optional, default: 'Cancel'
                // confirmLabel="Ok" // optional, default: 'Ok'
                // animationType="fade" // optional, default is 'none'
                // locale="en" // optional, default is automically detected by your system
                // keyboardIcon="keyboard-outline" // optional, default is "keyboard-outline"
                // clockIcon="clock-outline" // optional, default is "clock-outline"
                />
            </View>
        );
    };

    const renderProgramStartTimeItems = (
        item: ListRenderItemInfo<any>,
        index: any
    ) => {
        var timeP = new Date(item.item * 1000).toISOString().slice(11, 19);
        // console.log("program time AND ITM.ITM", timeP, item);
        return (
            <View
                style={{
                    flexDirection: "row",
                    width: "68%",
                    alignSelf: "center",
                    marginLeft: 17,
                    borderRadius: 5,
                    marginTop: 5,
                    borderWidth: 1,
                    alignItems: "flex-start",
                    borderColor: "#DEDEDE",
                }}
            >
                <View
                    style={{
                        borderRightWidth: 1,
                        flex: 1,
                        alignItems: "center",
                        borderColor: "#DEDEDE",
                        height: 35,
                    }}
                >
                    <Text style={{ marginVertical: 8 }}>{timeP}</Text>
                </View>
                <View
                    style={{
                        alignItems: "center",
                        padding: 5,
                        borderColor: "#DEDEDE",
                        height: 27,
                    }}
                >
                    <IconCross
                        name="circle-with-cross"
                        color="red"
                        size={20}
                        onPress={() => deleteProgramStartTime(item.index, index)}
                    />
                </View>
            </View>
        );
    };
    const renderProgramAddStartTimeItems = (
        item: { item: number; index: any },
        index: any

    ) => {
        // // console.log("item======>data", data);
        // console.log("item program times", item);

        var timeP = new Date(item.item * 1000).toISOString().slice(11, 19);
        // // console.log("item program times", item.item, item, item.index);
        return (
            <View
                style={{
                    flexDirection: "row",
                    borderRadius: 5,
                    marginTop: 5,
                    borderWidth: 1,
                    alignItems: "center",
                    borderColor: "#DEDEDE",
                }}
            >
                <View
                    style={{
                        borderRightWidth: 1,
                        flex: 1,
                        alignItems: "center",
                        borderColor: "#DEDEDE",
                        height: 35,
                    }}
                >
                    <Text style={{ flex: 0.4, marginVertical: 8 }}>{timeP}</Text>
                </View>
                <View
                    style={{
                        flex: 0.25,
                        alignItems: "center",
                        borderColor: "#DEDEDE",
                        height: 27,
                    }}
                >
                    <IconCross
                        name="circle-with-cross"
                        color="red"
                        size={25}
                        onPress={() => deleteAddprogramItems(item.index)}
                    />
                </View>
            </View>
        );
    };

    const renderAddValveItems = ({ item, index }) => {
        // console.log("rendervalve add", item);

        return (
            <View
                style={{
                    flexDirection: "row",
                    borderRadius: 5,
                    marginTop: 5,
                    borderWidth: 1,
                    alignItems: "center",
                    borderColor: "#DEDEDE",
                }}
            >
                <View
                    style={{
                        borderRightWidth: 1,
                        flex: 0.25,
                        alignItems: "center",
                        borderColor: "#DEDEDE",
                        height: 35,
                    }}
                >
                    <Text style={{ flex: 0.25, marginVertical: 8 }}>
                        {item.valve.name}
                    </Text>
                </View>

                <View
                    style={{
                        borderRightWidth: 1,
                        flex: 0.6,
                        alignItems: "center",
                        borderColor: "#DEDEDE",
                        height: 35,
                    }}
                >
                    {isMeasureType === "Duration" ? (
                        <Text style={{ flex: 0.25, marginVertical: 8 }}>
                            {new Date(item.amount * 1000).toISOString().slice(11, 19)}
                        </Text>
                    ) : (
                        <Text style={{ flex: 0.25, marginVertical: 8 }}>
                            {item.amount}
                            <Text style={{ alignSelf: "center" }}>m³</Text>
                        </Text>
                    )}
                </View>
                {/* <View style={{ borderRightWidth: 1, flex: 0.25, borderColor: '#DEDEDE', height: 35 }}><Text style={{ flex: 0.25, fontSize: 10 }}>status</Text><Text style={{ flex: 0.25 }}>00:01:00</Text></View> */}
                <View
                    style={{
                        flex: 0.25,
                        alignItems: "center",
                        borderColor: "#DEDEDE",
                        height: 35,
                    }}
                >
                    <IconCross
                        name="circle-with-cross"
                        color="red"
                        size={30}
                        onPress={() => deleteAddValveItems(item.valve.id, item.valve.name)}
                    />
                </View>
            </View>
        );
    };

    const screenwidth = Dimensions.get('window').width
    const isMobile = screenwidth < 768;
    return (
        <View style={styles.container}>
            <Modal
                onRequestClose={() => setIsVisible(false)}
                transparent={true}
                visible={isVisible}
            >
                <View
                    style={{
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        alignContent: "center",
                        backgroundColor: "rgba(100,100,100, 0.5)",
                    }}
                >
                    <View style={isMobile ? {

                        // alignItems: "center",
                        backgroundColor: "#F8F8F8",
                        borderColor: "#eee",
                        borderRadius: 10,
                        borderWidth: 1,
                        // justifyContent: "center",
                        margin: "auto",
                        padding: 30,
                        shadowOffset: {
                            width: 0,
                            height: 2,
                        },
                        shadowOpacity: 0.25,
                        shadowRadius: 4,
                        elevation: 5,
                        width: "80%",

                    } : [styles.containeralt]}>
                        {/* <TouchableOpacity style={{ alignSelf: 'flex-end' }} onPress={() => {
                            setIsVisible(false),
                                updatedata ?
                                    // ProgramAmountDetails.filter(it => // console.log("test", it.name, item.name))
                                    ProgramAmountDetails.map((Fdata) => {
                                        // console.log("finaldata", updatedata, Fdata);
                                        return (
                                            finaldata.push({
                                                "amount": Fdata.amount,
                                                "name": Fdata.name,
                                                "order": 0,
                                                "valve": {
                                                    "connect": {
                                                        "id": Fdata.bermad_valve_id
                                                    }
                                                }
                                            })
                                        )
                                    }) :
                                    ProgramAmountDetails.map((Fdata) => {
                                        // console.log("finaldata", updatedata);
                                        return (
                                            finaldata.push({
                                                "amount": Fdata.amount,
                                                "name": Fdata.name,
                                                "order": 0,
                                                "valve": {
                                                    "connect": {
                                                        "id": Fdata.valve.connect.id
                                                    }
                                                }
                                            })
                                        )
                                    })
                            OmegaDetails[mainindexmodal].ProgramsAmountData.push(...finaldata),
                                setupdatedata(false);
                            setSelectedValve(item),
                                setValveSelected(item)
                        }}>
                            <IconCross
                                name="circle-with-cross"
                                size={20}
                                color="#000"
                                style={{ bottom: 5, top: 10 }}
                            />
                        </TouchableOpacity> */}

                        <Text
                            style={{
                                alignSelf: "center",
                                color: "#484848",
                                fontSize: 14,
                                fontWeight: "700",
                            }}
                        >
                            Set Amount
                        </Text>
                        <FlatList
                            data={valveDetails}
                            numColumns={2}
                            style={{ width: "100%" }}
                            renderItem={({ item }) => {
                                // console.log("itemitemitem", item, programAmount);
                                var finaldata: {
                                    amount: any;
                                    name: any;
                                    order: number;
                                    valve: { connect: { id: any } };
                                }[] = [];
                                return (
                                    <Pressable
                                        disabled={
                                            programAmount
                                                .map((itemss) => {
                                                    return itemss.name;
                                                })
                                                .includes(item.name)
                                                ? true
                                                : false
                                        }
                                        onPress={() => {
                                            updatedata
                                                ? // ProgramAmountDetails.filter(it => // console.log("test", it.name, item.name))
                                                ProgramAmountDetails.map((Fdata) => {
                                                    // console.log("finaldata11", updatedata, Fdata);
                                                    return finaldata.push({
                                                        amount: Fdata.amount,
                                                        name: Fdata.name,
                                                        order: 0,
                                                        valve: {
                                                            connect: {
                                                                id: Fdata.bermad_valve_id,
                                                            },
                                                        },
                                                    });
                                                })
                                                : ProgramAmountDetails.map((Fdata) => {
                                                    // console.log("finaldata22", updatedata);
                                                    //add null check for Fdata.valve - code by RohitB
                                                    const valveId = Fdata.valve && Fdata.valve.connect ? Fdata.valve.connect.id : null;
                                                    return finaldata.push({
                                                        amount: Fdata.amount,
                                                        name: Fdata.name,
                                                        order: 0,
                                                        valve: {
                                                            connect: {
                                                                //id: Fdata.valve.connect.id,
                                                                id:valveId,
                                                            },
                                                        },
                                                    });
                                                });
                                            OmegaDetails[mainindexmodal].ProgramsAmountData.push(
                                                ...finaldata
                                            ),
                                                setupdatedata(false);
                                            setSelectedValve(item), setValveSelected(item);
                                        }}
                                        style={isMobile ? {
                                            borderWidth: 1,
                                            width: "40%",
                                            borderColor:
                                                programAmount
                                                    .map((itemss) => {
                                                        return itemss.name;
                                                    })
                                                    .includes(item.name) || item == ValveSelected
                                                    ? "#009DF0"
                                                    : "#707070",
                                            margin: 10,
                                            alignItems: "center",
                                            backgroundColor:
                                                programAmount
                                                    .map((itemss) => {
                                                        return itemss.name;
                                                    })
                                                    .includes(item.name) || item == ValveSelected
                                                    ? "#F8F8F8"
                                                    : "white",
                                            justifyContent: "space-between",
                                        } : {
                                            borderWidth: 1,
                                            width: "45%",
                                            borderColor:
                                                programAmount
                                                    .map((itemss) => {
                                                        return itemss.name;
                                                    })
                                                    .includes(item.name) || item == ValveSelected
                                                    ? "#009DF0"
                                                    : "#707070",
                                            margin: 10,
                                            alignItems: "center",
                                            backgroundColor:
                                                programAmount
                                                    .map((itemss) => {
                                                        return itemss.name;
                                                    })
                                                    .includes(item.name) || item == ValveSelected
                                                    ? "#F8F8F8"
                                                    : "white",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Text
                                            style={{
                                                marginVertical: 10,
                                                color: "black",
                                                textAlign: "center",
                                            }}
                                        >
                                            {item.name}
                                        </Text>
                                    </Pressable>
                                );
                            }}
                        />

                        <View
                            style={{ width: "90%", alignSelf: "center", marginBottom: 10 }}
                        >
                            {measurementType == "Duration" || isMeasureType == "Duration" ? (
                                <View
                                    style={{
                                        flexDirection: "row",
                                        alignSelf: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <View
                                        style={{ flexDirection: "column", alignItems: "center" }}
                                    >
                                        <TouchableOpacity
                                            onPress={() => timeHr < 23 && settimeHr(timeHr + 1)}
                                        >
                                            <Text style={{ margin: 5 }}>
                                                <IconTimer
                                                    color="#767676"
                                                    name="chevron-thin-up"
                                                    size={20}
                                                />
                                            </Text>
                                        </TouchableOpacity>

                                        <Text
                                            style={{
                                                borderWidth: 1,
                                                margin: 5,
                                                width: 40,
                                                alignContent: "center",
                                                textAlign: "center",
                                                alignItems: "center",
                                                alignSelf: "center",
                                                borderColor: "#707070",
                                                borderRadius: 6,
                                            }}
                                        >
                                            {timeHr < 10 ? `0${timeHr}` : timeHr}{" "}
                                        </Text>

                                        <TouchableOpacity
                                            onPress={() => timeHr > 0 && settimeHr(timeHr - 1)}
                                        >
                                            <Text style={{ margin: 5 }}>
                                                <IconTimer
                                                    color="#767676"
                                                    name="chevron-thin-down"
                                                    size={20}
                                                />
                                            </Text>
                                        </TouchableOpacity>
                                    </View>
                                    <Text style={{ marginHorizontal: 5 }}>:</Text>
                                    <View
                                        style={{ flexDirection: "column", alignItems: "center" }}
                                    >
                                        <TouchableOpacity
                                            onPress={() => timemin < 59 && settimemin(timemin + 1)}
                                        >
                                            <Text style={{ margin: 5 }}>
                                                <IconTimer
                                                    color="#767676"
                                                    name="chevron-thin-up"
                                                    size={20}
                                                />
                                            </Text>
                                        </TouchableOpacity>

                                        <Text
                                            style={{
                                                borderWidth: 1,
                                                margin: 5,
                                                width: 40,
                                                alignContent: "center",
                                                textAlign: "center",
                                                alignItems: "center",
                                                borderColor: "#707070",
                                                borderRadius: 6,
                                            }}
                                        >
                                            {timemin < 10 ? `0${timemin}` : timemin}
                                        </Text>

                                        <TouchableOpacity
                                            onPress={() => timemin > 0 && settimemin(timemin - 1)}
                                        >
                                            <Text style={{ margin: 5 }}>
                                                <IconTimer
                                                    color="#767676"
                                                    name="chevron-thin-down"
                                                    size={20}
                                                />
                                            </Text>
                                        </TouchableOpacity>
                                    </View>
                                    <Text style={{ marginHorizontal: 5 }}>:</Text>
                                    <View
                                        style={{ flexDirection: "column", alignItems: "center" }}
                                    >
                                        <TouchableOpacity
                                            onPress={() => timesec < 59 && settimesec(timesec + 1)}
                                        >
                                            <Text style={{ margin: 5 }}>
                                                <IconTimer
                                                    color="#767676"
                                                    name="chevron-thin-up"
                                                    size={20}
                                                />
                                            </Text>
                                        </TouchableOpacity>

                                        <Text
                                            style={{
                                                borderWidth: 1,
                                                margin: 5,
                                                width: 40,
                                                alignContent: "center",
                                                textAlign: "center",
                                                alignItems: "center",
                                                borderColor: "#707070",
                                                borderRadius: 6,
                                            }}
                                        >
                                            {timesec < 10 ? `0${timesec}` : timesec}
                                        </Text>

                                        <TouchableOpacity
                                            onPress={() => timesec > 0 && settimesec(timesec - 1)}
                                        >
                                            <Text style={{ margin: 5 }}>
                                                <IconTimer
                                                    color="#767676"
                                                    name="chevron-thin-down"
                                                    size={20}
                                                />
                                            </Text>
                                        </TouchableOpacity>
                                    </View>
                                </View>
                            ) : (
                                <TextInput
                                    style={styles.input}
                                    placeholder="Enter Amount"
                                    value={valveamount}
                                    autoFocus={true}
                                    onChangeText={(text) => setvalveamount(text)}
                                />
                            )}
                        </View>
                        {isAdd == true && (
                            <View
                                style={{
                                    flexDirection: "row",
                                    justifyContent: "space-evenly",
                                    marginHorizontal: 20,
                                }}
                            >
                                <TouchableOpacity
                                    style={styles.saveBtn}
                                    // disabled={ValveSelected.name == null ? true : false}
                                    onPress={() => {
                                        setupdatedata(false);
                                        setIsVisible(false), setvalveid(ValveSelected.valve_id);
                                        setvalveUniqueId(ValveSelected.id)
                                        OmegaDetails[mainindexmodal].ProgramsAmountData.push({
                                            amount: valveamount,
                                            name: ValveSelected.name,
                                            order: 0,
                                            valve: {
                                                connect: {
                                                    id: ValveSelected.valve_id,
                                                },
                                            },
                                        });
                                        setValveSelected({});
                                    }}
                                >
                                    <Text style={styles.btnText}>Update</Text>
                                </TouchableOpacity>

                                <TouchableOpacity
                                    style={styles.saveBtn}
                                    onPress={() => {
                                        setupdatedata(false),
                                            setIsVisible(false),
                                            updatedata
                                                ? // ProgramAmountDetails.filter(it => // console.log("test", it.name, item.name))
                                                ProgramAmountDetails.map((Fdata) => {
                                                    // console.log("finaldata222", updatedata, Fdata);
                                                    return finaldata.push({
                                                        amount: Fdata.amount,
                                                        name: Fdata.name,
                                                        order: 0,
                                                        valve: {
                                                            connect: {
                                                                id: Fdata.bermad_valve_id,
                                                            },
                                                        },
                                                    });
                                                })
                                                : ProgramAmountDetails.map((Fdata) => {
                                                    // console.log("finaldata111", updatedata);
                                                    return finaldata.push({
                                                        amount: Fdata.amount,
                                                        name: Fdata.name,
                                                        order: 0,
                                                        valve: {
                                                            connect: {
                                                                id: Fdata.valve.connect.id,
                                                            },
                                                        },
                                                    });
                                                });
                                        OmegaDetails[mainindexmodal].ProgramsAmountData.push(
                                            ...finaldata
                                        ),
                                            setupdatedata(false);
                                        setSelectedValve(item), setValveSelected(item);
                                    }}
                                >
                                    <Text style={styles.btnText}>Cancel</Text>
                                </TouchableOpacity>
                            </View>
                        )}
                        {isAdd == false && (
                            <View
                                style={{
                                    flexDirection: "row",
                                    justifyContent: "space-evenly",
                                    marginHorizontal: 20,
                                }}
                            >
                                <TouchableOpacity
                                    style={styles.saveBtn}
                                // onPress={() => CheckEnteredData()}
                                >
                                    <Text style={styles.btnText}>Update</Text>
                                </TouchableOpacity>

                                <TouchableOpacity
                                    style={styles.saveBtn}
                                    onPress={() => setIsVisible(false)}
                                >
                                    <Text style={styles.btnText}>Cancel</Text>
                                </TouchableOpacity>
                            </View>
                        )}
                    </View>
                </View>
            </Modal>
            <SuccessPopup
                visible={issuccessVisible}
                PopupMessage={PopupMessage}
                Responsestatus={responsestatus}
                onChange={() => setissuccessVisible(false)}
            />
            <Modal
                // animationType={"fade"}
                transparent={true}
                visible={isProgramLimit}
                onRequestClose={() => {
                    // console.log("limit exceeded.");
                }}
            >
                {/*All views of Modal*/}
                <View style={styles.containeralt}>
                    <Text style={{ textAlign: "center" }}>
                        <Icon color="#eed202" name="warning" size={20} /> Maximum limit of
                        program creation is 8 only
                    </Text>
                    <TouchableOpacity
                        onPress={() => {
                            setIsProgramLimit(false);
                        }}
                        style={styles.okBtn}
                    >
                        <Text style={{ color: "white" }}>Okay</Text>
                    </TouchableOpacity>
                </View>
            </Modal>

            <Modal
                onRequestClose={() => setValveIsVisible(false)}
                transparent={true}
                visible={ValveIsVisible}
            >
                <View
                    style={isMobile ? {
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        alignContent: "center",
                        backgroundColor: "rgba(100,100,100, 0.5)",
                    } : {
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        alignContent: "center",
                        backgroundColor: "rgba(100,100,100, 0.5)",
                    }}
                >
                    <View style={isMobile ? {

                        // alignItems: "center",
                        backgroundColor: "#F8F8F8",
                        borderColor: "#eee",
                        borderRadius: 10,
                        borderWidth: 1,
                        // justifyContent: "center",
                        margin: "auto",
                        padding: 30,
                        shadowOffset: {
                            width: 0,
                            height: 2,
                        },
                        shadowOpacity: 0.25,
                        shadowRadius: 4,
                        elevation: 5,
                        width: "80%",

                    } : [styles.containeralt]}>
                        <TouchableOpacity
                            style={{ alignSelf: "flex-end" }}
                            onPress={() => setValveIsVisible(false)}
                        >
                            <IconCross
                                name="circle-with-cross"
                                size={20}
                                color="#000"
                                style={{ bottom: 5, top: 10 }}
                            />
                        </TouchableOpacity>

                        <Text style={{ alignSelf: "center", fontWeight: "700" }}>
                            Set Amount
                        </Text>

                        <FlatList
                            data={valveDetails}
                            numColumns={2}
                            style={{ width: "100%" }}
                            renderItem={({ item }) => {
                                // console.log("itemitemitem", item);
                                return (
                                    <Pressable
                                        disabled={
                                            programAmount
                                                .map((itemss) => {
                                                    return itemss.valve.name;
                                                })
                                                .includes(item.name)
                                                ? true
                                                : false
                                        }
                                        onPress={() => {
                                            setValveSelected(item), setSelectedValve(item); setAddValveError("")
                                        }}
                                        style={isMobile ? {
                                            borderWidth: 1,
                                            width: "40%",
                                            borderColor:
                                                programAmount
                                                    .map((itemss) => {
                                                        return itemss.valve.name;
                                                    })
                                                    .includes(item.name) || item == ValveSelected
                                                    ? "#009DF0"
                                                    : "#707070",
                                            margin: 10,
                                            alignItems: "center",
                                            backgroundColor:
                                                programAmount
                                                    .map((itemss) => {
                                                        return itemss.valve.name;
                                                    })
                                                    .includes(item.name) || item == ValveSelected
                                                    ? "#F8F8F8"
                                                    : "white",
                                            justifyContent: "space-between",
                                        } : {
                                            borderWidth: 1,
                                            width: "45%",
                                            borderColor:
                                                programAmount
                                                    .map((itemss) => {
                                                        return itemss.valve.name;
                                                    })
                                                    .includes(item.name) || item == ValveSelected
                                                    ? "#009DF0"
                                                    : "#707070",
                                            margin: 10,
                                            alignItems: "center",
                                            backgroundColor:
                                                programAmount
                                                    .map((itemss) => {
                                                        return itemss.valve.name;
                                                    })
                                                    .includes(item.name) || item == ValveSelected
                                                    ? "#F8F8F8"
                                                    : "white",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Text
                                            style={{
                                                marginVertical: 10,
                                                color: "black",
                                                textAlign: "center",
                                            }}
                                        >
                                            {item.name}
                                        </Text>
                                    </Pressable>
                                );
                            }}
                        />

                        <View
                            style={{ width: "90%", alignSelf: "center", marginBottom: 10 }}
                        >
                            {isMeasureType == "Duration" ? (
                                <View
                                    style={{
                                        flexDirection: "row",
                                        alignSelf: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <View
                                        style={{ flexDirection: "column", alignItems: "center" }}
                                    >
                                        <TouchableOpacity
                                            onPress={() => timeHr < 23 && settimeHr(timeHr + 1)}
                                        >
                                            <Text style={{ margin: 5 }}>
                                                <IconTimer
                                                    color="#767676"
                                                    name="chevron-thin-up"
                                                    size={20}
                                                />
                                            </Text>
                                        </TouchableOpacity>

                                        <Text
                                            style={{
                                                borderWidth: 1,
                                                margin: 5,
                                                width: 40,
                                                alignContent: "center",
                                                textAlign: "center",
                                                alignItems: "center",
                                                alignSelf: "center",
                                                borderColor: "#707070",
                                                borderRadius: 6,
                                            }}
                                        >
                                            {timeHr < 10 ? `0${timeHr}` : timeHr}{" "}
                                        </Text>

                                        <TouchableOpacity
                                            onPress={() => timeHr > 0 && settimeHr(timeHr - 1)}
                                        >
                                            <Text style={{ margin: 5 }}>
                                                <IconTimer
                                                    color="#767676"
                                                    name="chevron-thin-down"
                                                    size={20}
                                                />
                                            </Text>
                                        </TouchableOpacity>
                                    </View>
                                    <Text style={{ marginHorizontal: 5 }}>:</Text>
                                    <View
                                        style={{ flexDirection: "column", alignItems: "center" }}
                                    >
                                        <TouchableOpacity
                                            onPress={() => timemin < 59 && settimemin(timemin + 1)}
                                        >
                                            <Text style={{ margin: 5 }}>
                                                <IconTimer
                                                    color="#767676"
                                                    name="chevron-thin-up"
                                                    size={20}
                                                />
                                            </Text>
                                        </TouchableOpacity>

                                        <Text
                                            style={{
                                                borderWidth: 1,
                                                margin: 5,
                                                width: 40,
                                                alignContent: "center",
                                                textAlign: "center",
                                                alignItems: "center",
                                                borderColor: "#707070",
                                                borderRadius: 6,
                                            }}
                                        >
                                            {timemin < 10 ? `0${timemin}` : timemin}
                                        </Text>

                                        <TouchableOpacity
                                            onPress={() => timemin > 0 && settimemin(timemin - 1)}
                                        >
                                            <Text style={{ margin: 5 }}>
                                                <IconTimer
                                                    color="#767676"
                                                    name="chevron-thin-down"
                                                    size={20}
                                                />
                                            </Text>
                                        </TouchableOpacity>
                                    </View>
                                    <Text style={{ marginHorizontal: 5 }}>:</Text>
                                    <View
                                        style={{ flexDirection: "column", alignItems: "center" }}
                                    >
                                        <TouchableOpacity
                                            onPress={() => timesec < 59 && settimesec(timesec + 1)}
                                        >
                                            <Text style={{ margin: 5 }}>
                                                <IconTimer
                                                    color="#767676"
                                                    name="chevron-thin-up"
                                                    size={20}
                                                />
                                            </Text>
                                        </TouchableOpacity>

                                        <Text
                                            style={{
                                                borderWidth: 1,
                                                margin: 5,
                                                width: 40,
                                                alignContent: "center",
                                                textAlign: "center",
                                                alignItems: "center",
                                                borderColor: "#707070",
                                                borderRadius: 6,
                                            }}
                                        >
                                            {timesec < 10 ? `0${timesec}` : timesec}
                                        </Text>

                                        <TouchableOpacity
                                            onPress={() => timemin > 0 && settimesec(timesec - 1)}
                                        >
                                            <Text style={{ margin: 5 }}>
                                                <IconTimer
                                                    color="#767676"
                                                    name="chevron-thin-down"
                                                    size={20}
                                                />
                                            </Text>
                                        </TouchableOpacity>
                                    </View>
                                </View>
                            ) : (
                                <TextInput
                                    style={styles.input}
                                    placeholder="Enter Amount"
                                    value={valveamount}
                                    autoFocus={true}
                                    onChangeText={(text) => {
                                        setvalveamount(text)
                                        setAddValveError("");

                                    }
                                    }
                                />
                            )}
                        </View>
                        {isAdd == true && (
                            <View
                                style={{
                                    flexDirection: "row",
                                    justifyContent: "space-evenly",
                                    marginHorizontal: 20,
                                }}
                            >
                                <TouchableOpacity
                                    style={styles.saveBtn}
                                    onPress={() => {
                                        setValveIsVisible(false),
                                            setvalveid(ValveSelected.valve_id),
                                            setvalveUniqueId(ValveSelected.id)
                                        setAddValveItems([
                                            ...AddValveItems,
                                            {
                                                amount: valveamount,
                                                valve: ValveSelected,
                                            },
                                        ]);
                                        setSelectedValveItems((prevSelectedValveItems) => [
                                            ...prevSelectedValveItems,
                                            {
                                                amount: valveamount,
                                                id: ValveSelected.valve_id,
                                            },
                                        ]);
                                    }}
                                >
                                    <Text style={styles.btnText}>Save</Text>
                                </TouchableOpacity>

                                <TouchableOpacity
                                    style={styles.saveBtn}
                                    onPress={() => {
                                        setValveIsVisible(false);
                                    }}
                                >
                                    <Text style={styles.btnText}>Cancel</Text>
                                </TouchableOpacity>
                            </View>
                        )}
                        {isAdd == false && (
                            <View
                                style={{
                                    flexDirection: "row",
                                    justifyContent: "space-evenly",
                                    marginHorizontal: 20,
                                }}
                            >
                                <TouchableOpacity
                                    style={styles.saveBtn}
                                // onPress={() => CheckEnteredData()}
                                >
                                    <Text style={styles.btnText}>Update</Text>
                                </TouchableOpacity>

                                <TouchableOpacity
                                    style={styles.saveBtn}
                                    onPress={() => setIsVisible(false)}
                                >
                                    <Text style={styles.btnText}>Cancel</Text>
                                </TouchableOpacity>
                            </View>
                        )}
                    </View>
                </View>
            </Modal>

            <View
                style={{
                    flexDirection: "row",
                    width: "100%",
                    marginBottom: 10,
                    marginTop: -50,
                    alignItems: "center",
                    // height:'48%'
                }}
            >
                <View style={{ flex: 1, marginHorizontal: 10, marginTop: 10 }}>
                    <View style={{ flexDirection: "row", width: "60%" }}>
                        <Dropdown
                            ref={dropdownRefOmega}
                            style={isMobile ? {
                                borderColor: "#c3c3c3",
                                width: "30%",
                                backgroundColor: "#fff",
                                borderWidth: 1,
                                borderRadius: 5,
                                height: 40,
                                padding: 10,
                                marginLeft: -15
                            } : {
                                flex: 0.6,
                                borderColor: "#c3c3c3",
                                width: "100%",
                                backgroundColor: "#fff",
                                borderWidth: 1,
                                borderRadius: 5,
                                // outlineStyle: 0,
                                height: 40,
                                padding: 10,
                            }}
                            placeholderStyle={isMobile ? {
                                fontSize: 12,
                                color: "black",
                            } : {
                                fontSize: 16,
                                color: "black",
                            }}
                            selectedTextStyle={styles.selectedTextStyle}
                            inputSearchStyle={styles.inputSearchStyle}
                            data={OmegaIdData}
                            search
                            // keyboardAvoiding={true}
                            maxHeight={300}
                            labelField="device_name"
                            valueField="device_name"
                            placeholder={!OmegaIdName ? "Select Omega" : "...."}
                            searchPlaceholder="Search"
                            value={OmegaIdName != "" ? OmegaIdName : OmegaIdValue}
                            onChange={(item) => {
                                Keyboard.dismiss();
                                // console.log("omega item", item);
                                setOmegaIdValue(item.device_name);
                                setOmegaValueiD(item.id);
                                setomegaid(item.omega_id);
                                // socketDataRun(item.omega_id)
                                setisopen(false);
                                getOmegaDetails(projectId, item.omega_id);
                                getValveOmegaDetails(item.id);
                                setOmegaError("");
                                // getOmegaDetails(item.id);
                                // setProjectOmegaId(item.project_id)
                                setrefreshflatlist(!refreshflatlist);
                                // console.log("socket run 4 via select");
                            }}
                            renderItem={renderOmegaIdListItems}
                        />
                        {/* <Text style={[styles.error_text]}>{OmegaError}</Text> */}
                        <View style={{ marginRight: 5 }}>
                            {isLoadingSync ? (
                                <ActivityIndicator
                                    //visibility of Overlay Loading Spinner
                                    color="#1DA1F2"
                                    visible={isLoadingSync}
                                    //Text with the Spinner
                                    textContent={"Loading..."}
                                    size={40}
                                //  //Text style of the Spinner Text
                                //  textStyle={styles.spinnerTextStyle}
                                />
                            ) : (
                                <View style={{ width: 25, height: 25 }}>
                                    <TouchableOpacity style={{ backgroundColor: 'red', margin: 15, marginBottom: 20 }} onPress={() => getProgramSync()}>
                                        <IconRefresh
                                            name="refresh"
                                            size={25}

                                        />
                                    </TouchableOpacity>
                                </View>


                            )}
                        </View>
                    </View>
                    <View>
                        <Text style={isMobile ? {
                            color: "red",
                            fontSize: 10,
                            fontWeight: "600",
                            width: "70%",
                            marginLeft: 0,
                            marginBottom: 10,
                        } : styles.error_text}>{OmegaError}</Text>
                    </View>
                </View>

                <View
                    style={isMobile ? {
                        alignItems: "center",
                        flexDirection: "row",
                        marginLeft: -300,
                        marginRight: 85,
                        marginTop: -40
                    } : {
                        alignItems: "center",
                        flexDirection: "row",
                        marginRight: 20,
                    }}
                >
                    <Icon color="#5afc4c" name="square" size={isMobile ? 12 : 20} />
                    <Text style={isMobile ? {
                        color: "#747474",
                        fontWeight: "600",
                        textAlign: "center",
                        fontSize: 12, marginLeft: 3
                    } : [styles.btnTextCancel, { marginLeft: 5 }]}>Running</Text>
                </View>
                <View
                    style={isMobile ? {
                        alignItems: "center",
                        flexDirection: "row",
                        marginLeft: -80,
                        marginRight: -10,
                        marginTop: -40
                    } : {
                        alignItems: "center",
                        flexDirection: "row",
                        marginRight: 20,
                    }}
                >
                    <Text style={isMobile ? {
                        color: "#747474",
                        fontWeight: "600",
                        textAlign: "center",
                        fontSize: 12, marginLeft: 3
                    } : [styles.btnTextCancel, { marginLeft: 5 }]}>
                        <Icon color="#BABABA" name="square" size={isMobile ? 12 : 20} /> Closed
                    </Text>
                </View>
                <View
                    style={isMobile ? {
                        alignItems: "center",
                        flexDirection: "row",
                        marginLeft: -110,
                        marginRight: 20,
                        marginTop: -10
                    } : {
                        alignItems: "center",
                        flexDirection: "row",
                        marginRight: 20,
                    }}
                >
                    <Text style={isMobile ? {
                        color: "#747474",
                        fontWeight: "600",
                        textAlign: "center",
                        fontSize: 12, marginLeft: 3
                    } : [styles.btnTextCancel, { marginLeft: 5 }]}>
                        <Icon color="#FFC400" name="square" size={isMobile ? 12 : 20} /> Running with Alert
                    </Text>
                </View>
                <View
                    style={isMobile ? {
                        alignItems: "center",
                        flexDirection: "row",
                        marginLeft: -140,
                        marginRight: 20,
                        marginTop: 20
                    } : {
                        alignItems: "center",
                        flexDirection: "row",
                        marginRight: 20,
                    }}
                >
                    <Text style={isMobile ? {
                        color: "#747474",
                        fontWeight: "600",
                        textAlign: "center",
                        fontSize: 12, marginLeft: 3
                    } : [styles.btnTextCancel, { marginLeft: 5 }]}>
                        <Icon color="#FF675A" name="square" size={isMobile ? 12 : 20} /> Closed with Alert
                    </Text>
                </View>
                 <View
                    style={isMobile ? {
                        alignItems: "center",
                        flexDirection: "row",
                        marginLeft: -140,
                        marginRight: 20,
                        marginTop: 20
                    } : {
                        alignItems: "center",
                        flexDirection: "row",
                        marginRight: 20,
                    }}
                >
                    <Text style={isMobile ? {
                        color: "#747474",
                        fontWeight: "600",
                        textAlign: "center",
                        fontSize: 12, marginLeft: 3
                    } : [styles.btnTextCancel, { marginLeft: 5 }]}>
                        <Icon color="#000000" name="square" size={isMobile ? 12 : 20} /> Disabled
                    </Text>
                </View> 
                {!isMobile && (
                    <View
                        style={{
                            alignItems: "center",
                            flexDirection: "row",
                            marginRight: 10,
                        }}
                    >
                        {OmegaDetails.length > 0 && (
                            <View
                                style={{
                                    flexDirection: "row",
                                    marginLeft: -5,
                                    justifyContent: "flex-end",
                                }}
                            >
                                <TouchableOpacity
                                    onPress={() => {
                                        backPress();
                                    }}
                                >
                                    <Text style={{ marginHorizontal: 5 }}>
                                        <IconArrow color="#767676" name="chevron-left" size={isMobile ? 12 : 20} />
                                    </Text>
                                </TouchableOpacity>
                                <Text
                                    style={{
                                        paddingHorizontal: 5,
                                        borderColor: "#707070",
                                        borderRadius: 6,
                                    }}
                                >
                                    {OmegaDetails.length} Programs{" "}
                                </Text>

                                <TouchableOpacity
                                    onPress={() => {
                                        nextPress();
                                    }}
                                >
                                    <Text style={{ marginHorizontal: 5 }}>
                                        <IconArrow color="#767676" name="chevron-right" size={isMobile ? 12 : 20} />
                                    </Text>
                                </TouchableOpacity>
                            </View>
                        )}
                    </View>
                )}

                <View style={isMobile ? { alignItems: 'flex-end' } : { alignItems: "flex-start" }}>
                    <TouchableOpacity
                        style={isMobile ? {
                            alignItems: "center",
                            backgroundColor: "#006EB9",
                            padding: 10,
                            marginLeft: -5,
                            height: 30,
                            borderWidth: 1,
                            borderRadius: 5,
                            borderColor: "#747474",
                            width: "80%",
                            paddingTop: 3

                        } : styles.addDevice1}
                        onPress={() => {
                            OmegaDetails.length >= 8
                                ? setIsProgramLimit(true)
                                : OmegaIdValue || OmegaIdName
                                    ? setisopen(true)
                                    : setOmegaError("Please select Omega ");
                        }}
                    >
                        <Text style={styles.btnText}>Add </Text>
                    </TouchableOpacity>
                </View>
            </View>

            {/* <View
                style={{ flexDirection: "row", alignItems: "center", width: "100%" }}
            >
                <View
                    style={{
                        flex: 1,
                        margin: 10,
                        flexDirection: "row",
                        alignItems: "flex-start",
                    }}
                >
                    {OmegaDetails.length > 0 && (
                        <View
                            style={{
                                flexDirection: "row",
                                marginLeft: 15,
                                justifyContent: "flex-end",
                            }}
                        >
                            <TouchableOpacity
                                onPress={() => {
                                    backPress();
                                }}
                            >
                                <Text style={{ marginHorizontal: 5 }}>
                                    <IconArrow color="#767676" name="chevron-left" size={20} />
                                </Text>
                            </TouchableOpacity>
                            <Text
                                style={{
                                    paddingHorizontal: 5,
                                    borderColor: "#707070",
                                    borderRadius: 6,
                                }}
                            >
                                {OmegaDetails.length} Programs{" "}
                            </Text>

                            <TouchableOpacity
                                onPress={() => {
                                    nextPress();
                                }}
                            >
                                <Text style={{ marginHorizontal: 5 }}>
                                    <IconArrow color="#767676" name="chevron-right" size={20} />
                                </Text>
                            </TouchableOpacity>
                        </View>
                    )}
                </View>
            </View> */}
            {/* block  row 1 col 1,2,3,4,5 */}
            <View
                style={{
                    flexDirection: "row",
                    alignSelf: "flex-start",
                    backgroundColor: "#fff",
                    width: "100%",
                    borderWidth: OmegaDetails.length > 0 ? 3 : 0,
                    borderColor: "#EBEBEB",
                    marginTop: -10
                }}
            >
                {isLoading ? (
                    <ActivityIndicator
                        color="#1DA1F2"
                        visible={isLoading}
                        textContent={"Loading..."}
                        size={40}
                    />
                ) : (
                    <MenuProvider style={styles.menuprovider}>
                        {OmegaDetails.length > 0 && (
                            <FlatList
                                horizontal
                                ref={flatListRef}
                                data={OmegaDetails}
                                extraData={refreshflatlist}
                                renderItem={renderItemScedular}
                                keyExtractor={(item) => item.index}
                                // ListEmptyComponent={EmptyListMessage}

                                style={{
                                    flex: 1,
                                    height: "100%",
                                    width: isopen ? "100%" : "100%",
                                }}
                            />
                        )}
                        {OmegaDetails.length == 0 && (
                            <View style={{ justifyContent: "center" }}>
                                <Text
                                    style={[styles.emptyListStyle, { alignSelf: "flex-start" }]}
                                >
                                    No Program Data Found{" "}
                                </Text>
                            </View>
                        )}
                    </MenuProvider>
                )}
                {isopen && (
                    <View style={isMobile ? { width: "85%" } : { width: "23%" }}>
                        <View
                            style={{
                                height: "5%",
                                alignSelf: "flex-start",
                                width: "100%",
                                flexDirection: "row",
                            }}
                        >
                            <TextInput
                                style={styles.input}
                                placeholder="Program Name"
                                value={newProjectName}
                                onChangeText={(value) => {
                                    setProjectName(value);
                                    setprogNameError("")
                                    // console.log("program name", value);
                                }}
                            />
                        </View>
                        <Text style={[styles.error_text]}>{progNameError}</Text>

                        <View
                            style={{
                                alignSelf: "flex-start",
                                width: "100%",
                                marginTop: 0,
                                flexDirection: "row",
                            }}
                        >
                            <View
                                style={{
                                    borderRightWidth: 1,
                                    borderLeftWidth: 3,
                                    borderLeftColor: "#dedede",
                                    width: "100%",
                                    borderRightColor: "#DEDEDE",
                                }}
                            >
                                <View
                                    style={{
                                        marginTop: 10,
                                        maxHeight: "10%",
                                        marginHorizontal: 5,
                                        flexDirection: "row",
                                        marginBottom: -10
                                    }}
                                >
                                    <Text style={[{ flex: 0.85 }, styles.schedulHead]}>
                                        Program Type
                                    </Text>
                                    <Dropdown
                                        ref={dropdownRefPT}
                                        style={{
                                            borderColor: "#c3c3c3",
                                            width: "35%",
                                            borderWidth: 1,
                                            borderRadius: 5,
                                            height: 10,
                                            padding: 10,
                                        }}
                                        // placeholderStyle={styles.placeholderStyle}
                                        // selectedTextStyle={styles.selectedTextStyle}
                                        // inputSearchStyle={styles.inputSearchStyle}
                                        // iconStyle={styles.iconStyle}
                                        search
                                        data={ProgramTypeIdData}
                                        maxHeight={300}
                                        labelField="name"
                                        valueField="name"
                                        placeholder={
                                            programTypeIdValue == ""
                                                ? "select"
                                                : programTypeIdValue.charAt(0).toUpperCase() +
                                                programTypeIdValue.slice(1).toLowerCase()
                                        }
                                        searchPlaceholder="Search"
                                        value={
                                            programTypeIdValue.charAt(0).toUpperCase() +
                                            programTypeIdValue.slice(1).toLowerCase()
                                        }
                                        onChange={(item) => {
                                            setprogramTypeIdValue(item.name);
                                            setprogramTypeValueiD(item.id);
                                            // getOmegaDetails(item.omega_id);

                                            setOmegaError("");
                                            // getOmegaDetails(item.id);
                                            // setProjectOmegaId(item.project_id)
                                            // console.log("omega id value---", item.id);
                                            setrefreshflatlist(!refreshflatlist);
                                        }}
                                        renderItem={renderProgramType}
                                    />
                                </View>
                                <Text style={[styles.error_text]}>{programTypeError}</Text>

                                {programTypeIdValue == "CYCLIC" ? (
                                    <View style={{ maxHeight: "20%" }}>
                                        <View
                                            style={{
                                                flex: 1,
                                                alignItems: "center",
                                                marginHorizontal: 5,
                                                flexDirection: "row",
                                                marginBottom: 10,
                                                marginTop: 10,
                                            }}
                                        >
                                            <Text style={[{ flex: 0.95 }, styles.schedulHead]}>
                                                Start Date
                                            </Text>
                                            <View>
                                                <Text
                                                    style={{
                                                        borderColor: "gray",
                                                        borderWidth: 1,
                                                        textAlign: "center",
                                                        // marginHorizontal:10,
                                                        paddingHorizontal: 7,
                                                        borderRadius: 5,
                                                        marginRight: 20,
                                                        padding: 2,
                                                    }}
                                                    onPress={() => setOpen(true)}
                                                >
                                                    {date
                                                        ? moment(date).format("DD/MM/YYYY")
                                                        : "Select Date "}
                                                </Text>
                                                <DatePickerModal
                                                    locale="en"
                                                    mode="single"
                                                    visible={open}
                                                    onDismiss={onDismissSingle}
                                                    date={date}
                                                    onConfirm={onConfirmNewDate}
                                                    validRange={{
                                                        endDate: new Date(), // optional
                                                    }}
                                                    label="Select date" // optional
                                                    closeIcon="close" // optional, default is "close"
                                                    calendarIcon="calendar" // optional, default is "calendar"
                                                />
                                            </View>
                                        </View>
                                        <View
                                            style={{
                                                flex: 1,
                                                alignItems: "center",
                                                marginHorizontal: 5,
                                                flexDirection: "row",
                                                marginBottom: 5,
                                            }}
                                        >
                                            <Text style={[{ flex: 0.95 }, styles.schedulHead]}>
                                                Day's Interval
                                            </Text>
                                            <View style={{ flexDirection: "row", width: "37%" }}>
                                                <TouchableOpacity
                                                    onPress={() => count > 0 && setcount(count - 1)}
                                                >
                                                    <Text style={{ margin: 5 }}>
                                                        <IconPlusMinus
                                                            color="#767676"
                                                            name="minuscircleo"
                                                            size={20}
                                                        />
                                                    </Text>
                                                </TouchableOpacity>

                                                {/* <Text style={{ borderWidth: 1, padding: 5, borderColor: '#707070', borderRadius: 6 }}>{counter <= 1 ? 1 : counter} </Text> */}

                                                <Text
                                                    style={{
                                                        borderWidth: 1,
                                                        padding: 5,
                                                        marginHorizontal: 5,
                                                        borderColor: "#707070",
                                                        borderRadius: 6,
                                                    }}
                                                >
                                                    {count}{" "}
                                                </Text>

                                                <TouchableOpacity
                                                    onPress={() => count < 15 && setcount(count + 1)}
                                                >
                                                    <Text style={{ margin: 5 }}>
                                                        <IconPlusMinus
                                                            color="#767676"
                                                            name="pluscircleo"
                                                            size={20}
                                                        />
                                                    </Text>
                                                </TouchableOpacity>
                                            </View>
                                        </View>
                                    </View>
                                ) : (
                                    <View
                                        style={{
                                            alignItems: "center",
                                            marginHorizontal: 5,
                                            flexDirection: "row",
                                            marginVertical: 10,
                                            marginBottom: -10,
                                            marginTop: 10
                                        }}
                                    >
                                        <Text style={[{ flex: 0.9 }, styles.schedulHead]}>
                                            Program Days
                                        </Text>
                                        <MultiSelect
                                            hideTags
                                            fixedHeight={true}
                                            items={daysData}
                                            uniqueKey="id"
                                            onSelectedItemsChange={onSelectedAddItemsChange}
                                            selectedItems={AddselectedDays}
                                            selectText="Select days"
                                            searchInputPlaceholderText="Search"
                                            tagRemoveIconColor="#CCC"
                                            tagBorderColor="#CCC"
                                            tagTextColor="#CCC"
                                            selectedItemTextColor="#009DF0"
                                            selectedItemIconColor="#CCC"
                                            itemTextColor="#000"
                                            displayKey="name"
                                            submitButtonColor="#fff"
                                            submitButtonText="Submit"
                                            searchInputStyle={isMobile ? { height: 40, fontSize: 16, outlineStyle: "none", width: 100 } : styles.inputSearchStyle}
                                            styleDropdownMenu={{ backgroundColor: "#fff" }}
                                        />
                                    </View>
                                )}
                                <Text style={[styles.error_text, { marginTop: 8 }]}>{progDaysError}</Text>
                                <View
                                    style={{
                                        alignItems: "flex-start",
                                        marginHorizontal: 5,
                                        marginTop: 5,
                                        flexDirection: "row",

                                    }}
                                >
                                    <Text style={[{ flex: 0.9 }, styles.schedulHead]}>
                                        Program Start
                                    </Text>
                                    {/* <View style={{ borderWidth: 1, borderColor: 'grey', borderRadius: 5 }}> */}
                                    <Dropdown
                                        ref={dropdownRefIS}
                                        style={{
                                            borderColor: "#c3c3c3",
                                            width: "40%",
                                            borderWidth: 1,
                                            borderRadius: 5,
                                            alignItems: "center",
                                            height: 20,
                                            padding: 10,
                                        }}
                                        // placeholderStyle={styles.placeholderStyle}
                                        // selectedTextStyle={styles.selectedTextStyle}
                                        inputSearchStyle={styles.inputSearchStyle}
                                        // iconStyle={styles.iconStyle}
                                        search
                                        data={IrrigationStartData}
                                        maxHeight={300}
                                        labelField="name"
                                        valueField="name"
                                        placeholder={
                                            IrrigationNewStartSelect
                                                ? IrrigationNewStartSelect
                                                : "Select"
                                        }
                                        searchPlaceholder="Search"
                                        value={IrrigationNewStartSelect}
                                        onChange={(item) => {
                                            setIrrigationNewStartSelect(item.name);
                                            setIrrigationNewStartSelectId(item.id);
                                            // setIrrigationStartSelect(item.name);
                                            // setprogramTypeValueiD(item.id);
                                        }}
                                        renderItem={renderIrrigation}
                                    />
                                </View>
                                <Text style={[styles.error_text]}>{programTimeError}</Text>

                                {IrrigationNewStartSelect === "Hours" && (
                                    <>
                                        <View
                                            style={{
                                                // flex: 1,
                                                alignItems: "flex-start",
                                                marginHorizontal: 5,
                                                flexDirection: "row",
                                                marginTop: 10
                                            }}
                                        >
                                            <Text style={[{ flex: 0.9 }, styles.schedulHead]}>
                                                Program Start Times
                                            </Text>
                                            <Pressable
                                                disabled={newprgStartData1.length <= 3 ? false : true}
                                                onPress={() => {
                                                    setprogramStartTimeVisible(true);
                                                }}
                                                style={{
                                                    flexDirection: "row",
                                                    borderWidth: 1,
                                                    marginRight: 5,
                                                    borderColor: "#707070",
                                                    borderRadius: 5,
                                                    backgroundColor: "#EBEBEB",
                                                    padding: 5,
                                                    alignItems: "center",
                                                    alignSelf: "flex-end",
                                                    marginVertical: 10,
                                                    marginTop: -5
                                                }}
                                            >
                                                <Text
                                                    style={
                                                        prgStartData.length <= 3
                                                            ? styles.valvebtnText
                                                            : { fontWeight: "400", color: "#CCC" }
                                                    }
                                                >
                                                    Add start times
                                                </Text>
                                            </Pressable>



                                            <TimePickerModal
                                                visible={programStartTimeVisible}
                                                onDismiss={onDismissProgramStartTime}
                                                onConfirm={onConfirmNewProgramStartTime}
                                                // data={new Date(item.hourlyEnd * 1000).toISOString().slice(11, 19)}
                                                data={programStartTime}
                                                hours={0o0} // default: current hours
                                                minutes={0o0} // default: current minutes
                                                locale="de"
                                            // label="hh:mm:ss" // optional, default 'Select time'
                                            // uppercase={false} // optional, default is true
                                            // cancelLabel="Cancel" // optional, default: 'Cancel'
                                            // confirmLabel="Ok" // optional, default: 'Ok'
                                            // animationType="fade" // optional, default is 'none'
                                            // locale="en" // optional, default is automically detected by your system
                                            // keyboardIcon="keyboard-outline" // optional, default is "keyboard-outline"
                                            // clockIcon="clock-outline" // optional, default is "clock-outline"
                                            />
                                        </View>

                                        {newprgStartData1.length > 0 && newprgStartData1 != undefined && (
                                            <FlatList
                                                // horizontal
                                                // extraData={refreshflatlist}
                                                style={{ width: "60%", alignSelf: 'center' }}
                                                contentContainerStyle={{ height: 90 }}
                                                data={newprgStartData1}
                                                // renderItem={renderProgramAddStartTimeItems(item, index)}
                                                // data={OmegaDetails[index].cycleTypeHours}
                                                // renderItem={renderProgramAddStartTimeItems}
                                                renderItem={(item, index) =>
                                                    renderProgramAddStartTimeItems(item, index)
                                                }
                                                keyExtractor={(item, index) => item.index}
                                            />
                                        )}
                                    </>
                                )}
                                {IrrigationNewStartSelect === "Cyclic" && (
                                    <View>
                                        <View
                                            style={{
                                                flex: 1,
                                                marginHorizontal: 5,
                                                flexDirection: "row",
                                            }}
                                        >
                                            <Text style={[{ flex: 0.9 }, styles.schedulHead]}>
                                                Program Start time
                                            </Text>
                                            <View
                                                style={{
                                                    borderWidth: 1,
                                                    borderColor: "grey",
                                                    alignItems: "center",
                                                    borderRadius: 5,
                                                    width: "40%",
                                                }}
                                            >
                                                <Text
                                                    style={{ margin: 5 }}
                                                    onPress={() => setTimeStartVisible(true)}
                                                >
                                                    {/* {new Date(item.hourlyEnd * 1000).toISOString().slice(11, 19)} */}
                                                    {timeStart
                                                        ? moment(timeStart).format("HH:mm:ss")
                                                        : "hh:mm:ss "}
                                                    {/* {item.hourlyEnd ? moment(item.hourlyEnd).format("HH:mm:ss") : "Select Time "} */}
                                                    {/* {timeStartVisible === true ? moment(timeStart).format("HH:mm:ss") : moment(item.hourlyStart).format("HH:mm:ss")} */}
                                                </Text>

                                                <TimePickerModal
                                                    visible={timeStartVisible}
                                                    onDismiss={onDismissStartTime}
                                                    onConfirm={onnewConfirmStartTime}
                                                    // data={new Date(item.hourlyEnd * 1000).toISOString().slice(11, 19)}
                                                    data={timeStart}
                                                    hours={12} // default: current hours
                                                    minutes={14} // default: current minutes
                                                    locale="de"
                                                // label="hh:mm:ss" // optional, default 'Select time'
                                                // uppercase={false} // optional, default is true
                                                // cancelLabel="Cancel" // optional, default: 'Cancel'
                                                // confirmLabel="Ok" // optional, default: 'Ok'
                                                // animationType="fade" // optional, default is 'none'
                                                // locale="en" // optional, default is automically detected by your system
                                                // keyboardIcon="keyboard-outline" // optional, default is "keyboard-outline"
                                                // clockIcon="clock-outline" // optional, default is "clock-outline"
                                                />
                                            </View>
                                        </View>
                                        <Text style={[styles.error_text]}>
                                            {programStrtTimeError}
                                        </Text>
                                        <View
                                            style={{
                                                flex: 1,
                                                alignItems: "center",
                                                marginHorizontal: 5,
                                                flexDirection: "row",
                                            }}
                                        >
                                            <Text style={[{ flex: 0.9 }, styles.schedulHead]}>
                                                Program End Time
                                            </Text>
                                            <View
                                                style={{
                                                    borderWidth: 1,
                                                    borderColor: "grey",
                                                    borderRadius: 5,
                                                    alignItems: "center",
                                                    width: "40%",
                                                }}
                                            >
                                                <Text
                                                    style={{ margin: 5 }}
                                                    onPress={() => setTimeVisible(true)}
                                                >
                                                    {/* {new Date(item.hourlyEnd * 1000).toISOString().slice(11, 19)} */}
                                                    {times
                                                        ? moment(times).format("HH:mm:ss")
                                                        : "hh:mm:ss "}
                                                    {/* {item.hourlyEnd ? moment(item.hourlyEnd).format("HH:mm:ss") : "Select Time "} */}
                                                    {/* {timeVisible === true ? moment(times).format("HH:mm:ss") : moment(item.hourlyEnd).format("HH:mm:ss")} */}
                                                </Text>

                                                <TimePickerModal
                                                    visible={timeVisible}
                                                    onDismiss={onDismissTime}
                                                    onConfirm={onConfirmNewEndTime}
                                                    // data={new Date(item.hourlyEnd * 1000).toISOString().slice(11, 19)}
                                                    data={times}
                                                    hours={12} // default: current hours
                                                    minutes={14} // default: current minutes
                                                    locale="de"
                                                // label="hh:mm:ss" // optional, default 'Select time'
                                                // uppercase={false} // optional, default is true
                                                // cancelLabel="Cancel" // optional, default: 'Cancel'
                                                // confirmLabel="Ok" // optional, default: 'Ok'
                                                // animationType="fade" // optional, default is 'none'
                                                // locale="en" // optional, default is automically detected by your system
                                                // keyboardIcon="keyboard-outline" // optional, default is "keyboard-outline"
                                                // clockIcon="clock-outline" // optional, default is "clock-outline"
                                                />
                                            </View>
                                        </View>
                                        <Text style={[styles.error_text]}>
                                            {programEndTimeError}
                                        </Text>
                                        <View
                                            style={{
                                                flex: 1,
                                                alignItems: "center",
                                                marginHorizontal: 5,
                                                flexDirection: "row",
                                            }}
                                        >
                                            <Text style={[{ flex: 0.9 }, styles.schedulHead]}>
                                                Hours Interval
                                            </Text>
                                            <View
                                                style={{
                                                    borderWidth: 1,
                                                    borderColor: "grey",
                                                    borderRadius: 5,
                                                    alignItems: "center",
                                                    width: "40%",
                                                }}
                                            >
                                                <Text
                                                    style={{ margin: 5 }}
                                                    onPress={() => setTimeHIVisible(true)}
                                                >
                                                    {/* {new Date(item.hourlyEnd * 1000).toISOString().slice(11, 19)} */}
                                                    {timeHoursInterval
                                                        ? moment(timeHoursInterval).format("HH:mm:ss")
                                                        : "hh:mm:ss "}
                                                    {/* {item.hourlyEnd ? moment(item.hourlyEnd).format("HH:mm:ss") : "Select Time "} */}
                                                    {/* {timeHIVisible === true ? moment(timeHoursInterval).format("HH:mm:ss") : moment(item.cycleIntervalHours).format("HH:mm:ss")} */}
                                                </Text>
                                                <Text style={[styles.error_text]}>
                                                    {programHoursError}
                                                </Text>

                                                <TimePickerModal
                                                    visible={timeHIVisible}
                                                    onDismiss={onDismissHITime}
                                                    onConfirm={onConfirmNewHITime}
                                                    // data={new Date(item.hourlyEnd * 1000).toISOString().slice(11, 19)}
                                                    data={timeHoursInterval}
                                                    hours={12} // default: current hours
                                                    minutes={14} // default: current minutes
                                                    locale="de"
                                                // label="hh:mm:ss" // optional, default 'Select time'
                                                // uppercase={false} // optional, default is true
                                                // cancelLabel="Cancel" // optional, default: 'Cancel'
                                                // confirmLabel="Ok" // optional, default: 'Ok'
                                                // animationType="fade" // optional, default is 'none'
                                                // locale="en" // optional, default is automically detected by your system
                                                // keyboardIcon="keyboard-outline" // optional, default is "keyboard-outline"
                                                // clockIcon="clock-outline" // optional, default is "clock-outline"
                                                />
                                            </View>
                                        </View>
                                        <Text style={[styles.error_text]}>
                                            {programEndTimeError}
                                        </Text>
                                    </View>
                                )}

                                <View
                                    style={{
                                        marginTop: 10,
                                        height: 0.5,
                                        width: "95%",
                                        alignSelf: "center",
                                        backgroundColor: "grey",
                                    }}
                                />
                                <View
                                    style={{
                                        alignItems: "center",
                                        marginHorizontal: 5,
                                        flexDirection: "row",
                                        marginTop: 10,
                                    }}
                                >
                                    <View
                                        style={{
                                            flexDirection: "row",
                                            flex: 1,
                                            alignItems: "center",
                                        }}
                                    >
                                        {/* asd */}
                                        <Text style={isMobile ? { color: "#0089D1", textTransform: "capitalize", fontWeight: "700", marginTop: -40 } : [{ flex: 0.7 }, styles.schedulHead]}>
                                            Measurement Type
                                        </Text>
                                        <View
                                            style={isMobile ? {
                                                flexDirection: "row",
                                                flex: 0.75,
                                                alignItems: "center",
                                                marginLeft: -90,
                                                marginTop: 20,
                                            } : {
                                                flexDirection: "row",
                                                flex: 0.75,
                                                alignItems: "center",
                                                marginLeft: -10
                                            }}
                                        >
                                            <RadioButton
                                                uncheckedColor={"#F0F0F0"}
                                                color={"#707070"}
                                                value="Duration"
                                                status={
                                                    isMeasureType == "Duration" ? "checked" : "unchecked"
                                                }
                                                onPress={() => setMeasureType("Duration")}
                                            />
                                            <Text style={{ fontSize: 12, color: "#707070" }}>
                                                Duration
                                            </Text>
                                            <RadioButton
                                                color={"#707070"}
                                                uncheckedColor={"#F0F0F0"}
                                                value="Quantity"
                                                status={
                                                    isMeasureType == "Quantity" ? "checked" : "unchecked"
                                                }
                                                onPress={() => setMeasureType("Quantity")}
                                            />
                                            <Text style={{ fontSize: 12, color: "#707070" }}>
                                                Quantity
                                            </Text>

                                        </View>
                                    </View>
                                </View>
                                <View style={{ marginHorizontal: 5, marginTop: 10 }}>
                                    <View style={{ flexDirection: 'row' }}>
                                        <Text style={[{ flex: 1 }, styles.schedulHead]}>
                                            Valve Program in Sequence
                                        </Text>
                                        <Pressable
                                            // disabled={AddValveItems.length == 1 ? true : false}
                                            onPress={() => {
                                                isvalveCreatable(), setvalveamount("");
                                            }}
                                            style={{
                                                flexDirection: "row",
                                                borderWidth: 1,
                                                marginRight: 5,
                                                borderColor: "#707070",
                                                borderRadius: 5,
                                                backgroundColor:
                                                    AddValveItems.length == 1 ? "#EBEBEB" : "#CCC",
                                                padding: 5,
                                                alignItems: "center",
                                                alignSelf: "flex-end",
                                                marginVertical: 10,
                                                marginTop: -5
                                            }}
                                        >
                                            <Icon
                                                color="#747474"
                                                name="plus"
                                                size={15}
                                                style={{ marginRight: 4 }}
                                            />
                                            <Text style={styles.valvebtnText}>Add Valve</Text>
                                        </Pressable>
                                    </View>
                                    <FlatList
                                        // horizontal
                                        data={AddValveItems}
                                        renderItem={renderAddValveItems}
                                        // keyExtractor={item => item.id}
                                        // numColumns={3}
                                        keyExtractor={(item, index) => item.index}
                                    />
                                    <Text style={[styles.error_text]}>{AddValveError}</Text>
                                </View>
                            </View>
                        </View>
                        {/* block  row 3 col 1,2,3,4,5 */}

                        {/* block  row 4 col 1,2,3,4,5 */}
                        <View
                            style={{
                                marginTop: 10,
                                height: 0.5,
                                width: "100%",
                                alignSelf: "center",
                                backgroundColor: "grey",
                            }}
                        />
                        <View
                            style={{
                                height: "35%",
                                alignSelf: "flex-start",
                                marginTop: 0,
                                width: "100%",
                            }}
                        >
                            <View
                                style={{
                                    width: "100%",
                                    borderRightWidth: 3,
                                    borderLeftWidth: 3,
                                    borderBottomWidth: 1,
                                    borderBottomColor: "#DEDEDE",
                                    borderRightColor: "#DEDEDE",
                                    borderLeftColor: "#DEDEDE",
                                }}
                            >
                                <View
                                    style={{
                                        flex: 1,
                                        alignItems: "center",
                                        marginHorizontal: 5,
                                        flexDirection: "row",
                                        marginVertical: 5,
                                    }}
                                >
                                    <Text style={[{ flex: 0.9 }, styles.schedulHead]}>
                                        Program Action
                                    </Text>
                                    <Dropdown
                                        style={{
                                            borderColor: "#c3c3c3",
                                            width: "40%",
                                            borderWidth: 1,
                                            borderRadius: 5,
                                            height: 20,
                                            padding: 10,
                                        }}
                                        placeholderStyle={styles.placeholderStyle}
                                        selectedTextStyle={styles.selectedTextStyle}
                                        inputSearchStyle={styles.inputSearchStyle}
                                        iconStyle={styles.iconStyle}
                                        search
                                        data={programActions}
                                        maxHeight={300}
                                        labelField="name"
                                        valueField="name"
                                        placeholder={
                                            programActionsSelect
                                                ? programActionsSelect
                                                : "select action"
                                        }
                                        searchPlaceholder="Search"
                                        value={programActionsSelect}
                                        onChange={(item) => {
                                            setprogramActionsSelect(item.name);
                                            setprogramActionsSelectId(item.id);
                                            // setIrrigationNewStartSelectId(item.id);
                                            // setIrrigationStartSelect(item.name);
                                            // setprogramTypeValueiD(item.id);
                                        }}
                                        renderItem={renderProgAction}
                                    />
                                </View>

                                {/* <View style={{ flex: 1, alignItems: 'center', marginHorizontal: 5, flexDirection: 'row', marginTop: 10 }}>
                                        <Pressable style={{ flex: 0.45, borderRadius: 5, alignItems: 'center', backgroundColor: '#0089D1' }}><Text style={{ fontSize: 10, color: 'white', marginVertical: 5 }}>PAUSE SETTINGS</Text></Pressable>
                                        <Pressable style={{ flex: 0.45, borderRadius: 5, alignItems: 'center', marginLeft: 10, backgroundColor: '#0089D1' }}><Text style={{ fontSize: 10, color: 'white', marginVertical: 5 }}>RESUME SETTINGS</Text></Pressable>
                                    </View> */}

                                {programActionsSelect === "None" ? null :
                                    <>
                                        <View style={{ flex: 1, marginHorizontal: 5 }}>
                                            <Text style={[{ flex: 0.9 }, styles.schedulHead]}>
                                                Device
                                            </Text>
                                            <Dropdown
                                                ref={dropdownRefDI}
                                                style={{
                                                    borderColor: "#c3c3c3",
                                                    width: "95%",
                                                    marginTop: 5,
                                                    borderWidth: 1,
                                                    borderRadius: 5,
                                                    height: 30,
                                                    padding: 10,
                                                }}
                                                data={deviceNameData}
                                                search
                                                maxHeight={300}
                                                labelField="device_name"
                                                valueField="device_name"
                                                placeholder={!isFocus ? "Select" : "...."}
                                                searchPlaceholder="Search"
                                                value={OmegaAddIdValue}
                                                onChange={(item) => {
                                                    setOmegaAddIdValue(item.device_name);
                                                    setOmegaAddValueiD(item.id);
                                                    // getOmegaDetails(item.omega_id);
                                                    getSensorOmegaDetails(item.id);
                                                    setOmegaError("");
                                                    // getOmegaDetails(item.id);
                                                    // setProjectOmegaId(item.project_id)
                                                    // console.log("omega id value---", item.id);
                                                    setrefreshflatlist(!refreshflatlist);
                                                }}
                                                renderItem={renderAddOmegaIdListItems}
                                            />
                                        </View>
                                        <View style={{ flex: 1, marginHorizontal: 5, marginTop: 20 }}>
                                            <Text style={[{ flex: 0.9 }, styles.schedulHead]}>
                                                Sensor name
                                            </Text>
                                            <Dropdown
                                                ref={dropdownRefST}
                                                style={{
                                                    borderColor: "#c3c3c3",
                                                    width: "95%",
                                                    borderWidth: 1,
                                                    borderRadius: 5,
                                                    marginTop: 5,
                                                    height: 30,
                                                    padding: 10,
                                                }}
                                                data={sensorTypeDetails}
                                                search
                                                maxHeight={300}
                                                labelField="name"
                                                searchPlaceholder
                                                valueField="name"
                                                placeholder={!isFocus ? "select " : "...."}
                                                searchPlaceholder="Search"
                                                value={SensorIdValue}
                                                onChange={(item) => {
                                                    setSensorIdValue(item.name);
                                                    setSensorValueiD(item.id);
                                                    setSensorvalueType(item.type);
                                                    // getOmegaDetails(item.id);
                                                    // setProjectOmegaId(item.project_id)
                                                    // console.log("omega id value---", item.id);
                                                    setrefreshflatlist(!refreshflatlist);
                                                }}
                                                renderItem={renderSensorListItems}
                                            />
                                        </View>
                                        <View style={{ flex: 1, marginHorizontal: 5, marginTop: 15 }}>
                                            <Text style={[{ flex: 0.9 }, styles.schedulHead]}>Value</Text>
                                            <View
                                                style={{
                                                    flexDirection: "row",
                                                    borderRadius: 5,
                                                    marginTop: 5,
                                                    width: "95%",
                                                    borderColor: "#707070",
                                                }}
                                            >
                                                {SensorvalueType == 2 ? (
                                                    <View
                                                        style={{
                                                            height: 30,
                                                            width: "100%",
                                                            marginHorizontal: 2,
                                                            // alignSelf: "center",
                                                            // backgroundColor: "grey",
                                                        }}
                                                    >
                                                        <TextInput value=""></TextInput>
                                                    </View>
                                                ) : (
                                                    <Dropdown
                                                        ref={dropdownRefVS}
                                                        style={{
                                                            borderColor: "#c3c3c3",
                                                            width: "100%",
                                                            borderWidth: 1,
                                                            borderRadius: 5,
                                                            height: 20,
                                                            padding: 10,
                                                        }}
                                                        // placeholderStyle={styles.placeholderStyle}
                                                        // selectedTextStyle={styles.selectedTextStyle}
                                                        // inputSearchStyle={styles.inputSearchStyle}
                                                        // iconStyle={styles.iconStyle}
                                                        search
                                                        data={sensorStatus}
                                                        maxHeight={300}
                                                        labelField="name"
                                                        valueField="id"
                                                        placeholder={"Status"}
                                                        searchPlaceholder="Search"
                                                        value={sensorStatusIdValue}
                                                        onChange={(item) => {
                                                            setSensorStatusIdValue(item.name);
                                                            setSensorStatusiD(item.id);
                                                            setrefreshflatlist(!refreshflatlist);
                                                        }}
                                                        renderItem={renderSensorType}
                                                    />
                                                )}
                                            </View>
                                        </View>
                                    </>
                                }
                                <View
                                    style={{
                                        flexDirection: "row",
                                        alignItems: "center",
                                        marginVertical: 15,
                                        width: "100%",
                                    }}
                                >
                                    <TouchableOpacity
                                        style={styles.cancleDevice}
                                        onPress={() => {
                                            setisopen(false);
                                            setrefresh(false);
                                            // navigation.navigate("CreateScheduleProgramProfile");
                                        }}
                                    >
                                        <Text style={styles.btnTextCancel}>Cancel</Text>
                                    </TouchableOpacity>

                                    <TouchableOpacity
                                        style={[styles.addDevice, { width: "48%" }]}
                                        onPress={() => {
                                            // setrefresh(true),
                                            CheckEnteredData();
                                        }}
                                    >
                                        <Text style={styles.btnText}>Save</Text>
                                    </TouchableOpacity>
                                </View>
                            </View>
                        </View>
                    </View>
                )}
            </View>
        </View>
    );
}

const useStyles = CreateResponsiveStyle(
    {
        container: {
            alignItems: "center",
            justifyContent: "center",
            padding: 10,
        },
        menuCon: {
            // flex: 1,
            alignSelf: "flex-end",
            justifyContent: "flex-end",
            // paddingTop: 50,
            backgroundColor: "#ecf0f1",
        },
        input: {
            borderColor: "gray",
            width: "100%",
            borderWidth: 1,
            borderRadius: 10,
            // marginLeft: 20,
            padding: 10,
        },
        tableHeader: {
            color: "#000",
            fontSize: 14,
            /* FontFamily: 'Muli' ,*/
            fontWeight: "700",
        },
        tableHead: {
            fontSize: 14,
            /* FontFamily: 'Muli' ,*/
            fontWeight: "400",
            color: "#000000",
        },
        contanerSwitch: {
            backgroundColor: "white",
            borderColor: "#eee",
            borderRadius: 5,
            alignSelf: "center",
            borderWidth: 1,
            // justifyContent: "center",
            height: "25%",
            margin: "auto",
            padding: 10,
            shadowOffset: {
                width: 0,
                height: 2,
            },
            shadowOpacity: 0.25,
            shadowRadius: 4,
            elevation: 5,
            // marginRight: 10,
            // marginRight: 1,
            width: "30%",
        },
        clickBtn: {
            backgroundColor: "#0089D1",
            padding: 10,
            borderWidth: 1,
            borderColor: "#006EB9",
            width: "90%",
            marginRight: 20,
            alignItems: "center",
        },
        // Error message css
        error_text: {
            color: "red",
            fontSize: 14,
            fontWeight: "600",
            width: "70%",
            marginLeft: 0,
            marginBottom: 10,
        },
        saveBtn: {
            alignItems: "center",
            backgroundColor: "#006EB9",
            padding: 10,
            borderWidth: 1,
            borderColor: "#006EB9",
            width: "50%",
            marginRight: 10,
        },
        okBtn: {
            alignItems: "center",
            justifyContent: "center",
            alignSelf: "center",
            backgroundColor: "#006EB9",
            marginTop: 20,
            padding: 10,
            borderWidth: 1,
            borderColor: "#006EB9",
            width: "50%",
        },
        addValvebtn: {
            alignItems: "center",
            backgroundColor: "#EBEBEB",
            padding: 10,
            borderWidth: 1,
            borderColor: "#EBEBEB",
            width: "20%",
            marginRight: 10,
        },
        addDevice: {
            alignItems: "center",
            backgroundColor: "#006EB9",
            padding: 10,
            marginLeft: 5,
            height: 40,
            borderWidth: 1,
            borderRadius: 5,
            borderColor: "#747474",
            width: "93%",
            marginRight: 20,

            // marginTop: 20,
        },
        addDevice1: {
            alignItems: "center",
            backgroundColor: "#006EB9",
            padding: 10,
            marginLeft: -5,
            height: 40,
            borderWidth: 1,
            borderRadius: 5,
            borderColor: "#747474",
            width: "93%",
            marginRight: 40,

            // marginTop: 20,
        },
        cancleDevice: {
            alignItems: "center",
            backgroundColor: "#fff",
            padding: 10,
            height: 40,
            borderRadius: 5,
            borderWidth: 1,
            borderColor: "#747474",
            width: "48%",
        },
        title: {
            fontSize: 20,
            fontWeight: "bold",
        },
        valvebtnText: {
            color: "#747474",
            fontWeight: "600",
        },
        btnText: {
            color: "#fff",
            fontWeight: "600",
        },
        btnTextCancel: {
            color: "#747474",
            fontWeight: "600",
            textAlign: "center",
        },
        menuprovider: {
            flex: 1,
            width: "100%",
            backgroundColor: "#fff",
            justifyContent: "center",
            // alignItems: "center",
            // padding: 30,
            flexDirection: "column",
        },
        containeralt: {
            // alignItems: "center",
            backgroundColor: "#F8F8F8",
            borderColor: "#eee",
            borderRadius: 10,
            borderWidth: 1,
            // justifyContent: "center",
            margin: "auto",
            padding: 30,
            shadowOffset: {
                width: 0,
                height: 2,
            },
            shadowOpacity: 0.25,
            shadowRadius: 4,
            elevation: 5,
            width: "30%",
        },
        card: {
            width: "100%",
            flex: 1,
            marginVertical: 10,
        },
        shadowProp: {
            shadowColor: "#171717",
            shadowOffset: { width: -2, height: 4 },
            shadowOpacity: 0.2,
            shadowRadius: 25,
        },
        link: {
            marginTop: 15,
            paddingVertical: 15,
        },
        linkText: {
            fontSize: 14,
            color: "#2e78b7",
        },
        textInputStyle: {
            height: 40,
            borderWidth: 2,
            paddingLeft: 10,
            margin: 5,
            opacity: 2,
            borderRadius: 5,
            borderColor: "#319ef0",
            backgroundColor: "#FFFFFF",
            width: "40%",
            color: "#000",
            fontWeight: "600",
        },
        counterText: {
            borderWidth: 1,
            padding: 15,
            borderColor: "#707070",
            borderRadius: 6,
        },
        leftMenu: { color: "#000", margin: 10, fontWeight: "500", fontSize: 16 },
        schedulHead: {
            color: "#0089D1",
            textTransform: "capitalize",
            fontWeight: "700",
        },
        emptyListStyle: {
            padding: 10,
            fontSize: 10,
            textAlign: "center",
        },
        // DropDown CSS

        dropdown: {
            borderColor: "#c3c3c3",
            width: "100%",
            borderWidth: 1,
            borderRadius: 2,
            height: 40,

            padding: 10,
            marginTop: 5,
            marginBottom: 5,
            // borderRadius: 10,
            // padding: 12,
            // shadowColor: "#000",
            // shadowOffset: {
            //   width: 0,
            //   height: 1,
            // },
            // shadowOpacity: 0.2,
            // shadowRadius: 1.41,

            // elevation: 2,
        },

        icon: {
            marginRight: 5,
        },
        item: {
            padding: 17,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
        },
        textItem: {
            flex: 1,
            fontSize: 16,
        },
        placeholderStyle: {
            fontSize: 12,
            alignSelf: "center",
        },
        selectedTextStyle: {
            fontSize: 16,
            alignSelf: "center",
        },
        // iconStyle: {
        //     width: 20,
        //     height: 20,
        // },
        iconStyle: {
            width: 0,
            height: 0,
        },
        inputSearchStyle: {
            height: 40,
            fontSize: 16,
            outlineStyle: "none",
        },
        lableText: {
            color: "#484848",
            fontWeight: "700",
            marginTop: 18,
            // fontSize: 21,
        },
        labelTitleTBD: {
            color: "#707070",
            padding: 10,
            fontSize: 20,
        },
        labelTitle: {
            padding: 15,
            color: "#0089D1",
            fontWeight: "600",
            textTransform: "Capitalize",
        },
    },
    {
        [DEVICE_SIZES.XL]: {
            container: {
                alignItems: "center",
                justifyContent: "center",
                padding: 10,
            },
        },
        [DEVICE_SIZES.SM]: {
            container: {
                alignItems: "center",
                justifyContent: "center",
                padding: 10,
            },
        },
    }
);

const optionsStyles = {
    optionsContainer: {
        //backgroundColor: "green",
        width: "100px",
        padding: 5,
    },
    optionsWrapper: {
        //   backgroundColor: "purple",
    },
    optionWrapper: {
        //  backgroundColor: "yellow",
        margin: 5,
    },
    optionTouchable: {
        //   underlayColor: "gold",
        activeOpacity: 70,
    },
    optionText: {
        //  color: "brown",
    },
};