import Header from "../navigation/header";
import {
  StyleSheet,
  TouchableOpacity,
  TextInput,
  Modal,
  Button,
  Switch,
  FlatList,
  Alert,
  ScrollView,
  Dimensions,
} from "react-native";
import { useFocusEffect } from "@react-navigation/native";
import { Dropdown } from "react-native-element-dropdown";
import { List } from "react-native-paper";

import {
  Collapse,
  CollapseHeader,
  CollapseBody,
} from "accordion-collapse-react-native";
import { Text, View } from "../../components/Themed";
import { RootStackScreenProps } from "../../types"; // @ts-ignore
import { DataTable } from "react-native-paper";
import Icon from "react-native-vector-icons/AntDesign";
import IconEdit from "react-native-vector-icons/FontAwesome";
import IconEye from "react-native-vector-icons/Entypo";
import React, { useEffect, useState, useMemo, useRef } from "react";
import IconCross from "react-native-vector-icons/Entypo";
import IconCheck from "react-native-vector-icons/MaterialCommunityIcons";

import AsyncStorage from "@react-native-async-storage/async-storage";
import AwesomeAlert from "react-native-awesome-alerts";
import { baseUrl, postData } from "../../networking/api";
import { Provider as PaperProvider, Checkbox } from "react-native-paper";
import { relativeTimeRounding } from "moment";
import SuccessPopup from "../../components/SuccessPopup";

export default function ProjectAccess({ navigation }) {
  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [shouldShow, setShouldShow] = useState(true);
  const [iconTick, seticonTick] = useState("checkbox-blank-outline");
  const [iconTickvalId, setIconTickvalId] = useState(0);

  const [openMenu, setOpenMenu] = useState("chevron-up");

  // const toggleSwitch = () => setIsActive(0);
  const [checked, setChecked] = React.useState(false);
  const [expandedProfile, setExpandedProfile] = useState(true);

  const [giveAccessNameData, setgiveAccessNameData] = useState([]);
  const [AccessNameData, setAccessNameData] = useState([]);
  const [UserNameData, setUserNameData] = useState([]);
  const [ChildUserNameData, setChildUserNameData] = useState([]);

  const [accessValue, setaccessValue] = useState("");
  const [accessValId, setaccessValId] = useState("");
  const [error, setError] = useState("");
  const [ProjectNameData, setProjectNameData] = useState([]);

  const [projectValue, setProjectValue] = useState(null);
  const [projectValid, setProjectValId] = useState(false);
  const [createAccess, setCreateAccess] = useState(0);
  const [viewAccess, setViewAccess] = useState(0);
  const [access, setAccess] = useState(0);
  const [issuccessVisible, setissuccessVisible] = useState(false);
  const [responsestatus, setresponsestatus] = useState("");
  const [PopupMessage, setPopupMessage] = useState("");

  const [isFocus, setIsFocus] = useState(false);
  const items = [
    { title: "Country ", body: "hello" },
    { title: "City", body: "hello2" },
  ];

  const profileItem = [
    { title: "Device Profile ", body: "hello" },
    { title: "Sub Zone", body: "hello2" },
    { title: "Zone/Group Profile", body: "hello" },
    { title: "Project Profile", body: "hello2" },
    { title: "Farmer Profile", body: "hello2" },
  ];

  const setIconValue = (
    item,
    accessType,
    index,
    parent_id,
    accessTypeValue
  ) => {
    // console.log("all values", accessType);
    // console.log("accesstype values", item, "and and ", accessTypeValue);
    if (accessTypeValue == 0 || accessTypeValue == null) {
      // console.log("inside if");
      seticonTick("checkbox-marked");
      setIconTickvalId(1);
      var renderData = [...UserNameData];
      for (let data of renderData) {
        // console.log("user_id ", data.user_id);
        // console.log("parent_id", parent_id, "menu child name ", item.name);

        if (data.user_id == parent_id) {
          // console.log("accessType", accessType);
          // console.log("index", index);
          if (accessType === "access") {
            UserNameData[index].access = 1;
            // console.log("rend some data 1", renderData);
          } else {
            UserNameData[index].access = 1;
            // console.log("rend some data alll dattaaaaa", renderData);
          }
          // console.log("renderData==>", renderData);
          setUserNameData(renderData);
          return false;
        }
      }

      //item.accessType = 1;

      // console.log(iconTickvalId);
    } else {
      // console.log("else me gaya");
      seticonTick("checkbox-blank-outline");
      setIconTickvalId(0);
      var renderData = [...UserNameData];

      for (let data of renderData) {
        // console.log("user_id", data.user_id);
        // console.log("parent_id", parent_id);
        if (data.user_id == parent_id) {
          var index = UserNameData.findIndex(
            (item) => item.user_id === data.user_id
          );
          // console.log("index", index);
          // console.log("accessType", accessType);
          // console.log("index", index);
          if (accessType === "access") {
            UserNameData[index].access = 0;
          } else {
            UserNameData[index].access = 0;
          }
          setUserNameData(renderData);
          return false;
        }
      }

      // console.log(iconTickvalId);
    }
  };
  const setIconMenu = () => {
    if (openMenu == "chevron-up") {
      setOpenMenu("chevron-down");
      // console.log("ON");
    } else {
      setOpenMenu("chevron-up");
      // console.log("OFFFFFFFFFFFF");
    }
  };
  //useracesss  Add Api
  const saveAccessData = async () => {
    setIsLoading(true);
    try {
      // var tempAccessData = [];
      // tempAccessData.push(UserNameData);
      var projectAccessData = JSON.stringify({
        project_id: projectValid,
        accessDetails: UserNameData,
      });
      // console.log("access setting payload===", projectAccessData);
      const projectAccessMasterResponse = await postData(
        projectAccessData,
        "masters/updateProjectWiseAccess"
      );
      setIsLoading(false);
      var code = projectAccessMasterResponse.code;
      if (code == 1) {
        // //alert(projectAccessMasterResponse.message);
        // setIsVisible(false);
        setShouldShow(true);
        setProjectValue(null);
        setProjectValId(false);
        setPopupMessage("Access given Successfully");
        setissuccessVisible(true);
        setresponsestatus("success");

        // navigation.navigate("Access");
      } else {
        setIsLoading(false);
        setPopupMessage("Failed to give access");
        setissuccessVisible(true);
        setresponsestatus("fail");
        // //alert(projectAccessMasterResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  const getAccessProjectDetails = async (projectId) => {
    setIsLoading(true);
    try {
      var projectListData = JSON.stringify({
        project_id: projectId,
      });
      const projectAccessResponse = await postData(
        projectListData,
        "masters/getProjectWiseAccess"
        //"masters/getMasterList"
      );

      setIsLoading(false);

      var code = projectAccessResponse.code;
      if (code == 1) {
        // //alert(projectAccessResponse.message);
        // console.log("projectAccessResponse ===>", projectAccessResponse.data);
        setShouldShow(false);

        setUserNameData(projectAccessResponse.data);
        // var tempArr = [];

        // for (var j = 0; j < projectAccessResponse.data.length; j++) {
        //   tempArr.push(projectAccessResponse.data[j].childMenu);
        // }
        // setChildUserNameData(tempArr);

        // console.log("response of access details", projectAccessResponse.data);
      } else {
        setIsLoading(false);

        // //alert(UserTypeResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  const renderMasterItem = (item, index) => {
    // console.log("item======>", item.name);
    return (
      <DataTable.Row>
        <DataTable.Cell>{item.name}</DataTable.Cell>

        <DataTable.Cell style={{ justifyContent: "space-between" }}>
          <TouchableOpacity
            onPress={() => {
              // console.log("project based item LISTSSSSSS acces", item);
              setIconValue(item, "access", index, item.user_id, item.access);
            }}
          >
            <IconCheck
              color="#1868AE"
              name={
                item.access === 1 ? "checkbox-marked" : "checkbox-blank-outline"
              }
              size={30}
            />
          </TouchableOpacity>
        </DataTable.Cell>
      </DataTable.Row>
    );
  };

  var alpha_pattern = new RegExp(/^[a-zA-Z]+(\s[a-zA-Z]+)?$/);

  useFocusEffect(
    React.useCallback(() => {
      getProjectList();
    }, [accessValue])
  );

  const renderProjectListItems = (item: any) => {
    // console.log("item---value", item.id, "--------", accessValue);
    return (
      <View>
        <View style={styles.item}>
          <Text style={styles.textItem}>{item.project_name}</Text>
          {item.id === projectValid && (
            <Icon style={styles.icon} color="black" name="Safety" size={20} />
          )}
        </View>
      </View>
    );
  };

  // Project Get Api
  const getProjectList = async () => {
    setIsLoading(true);
    try {
      var projectListData = JSON.stringify({
        module: "project",
        data: {
          active: 1,
        },
        relation: [],
      });

      const projectListResponse = await postData(
        projectListData,
        "masters/getMasterList"
      );
      setIsLoading(false);
      var code = projectListResponse.code;
      if (code == 1) {
        // console.log("Project name api====>", projectListResponse.data);
        setProjectNameData(projectListResponse.data);
      } else {
        setIsLoading(false);
        // //alert(projectListResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  const screenwidth = Dimensions.get('window').width
  const isMobile = screenwidth < 768;

  return (
    <View style={styles.container}>
      <SuccessPopup
        visible={issuccessVisible}
        PopupMessage={PopupMessage}
        Responsestatus={responsestatus}
        onChange={() => setissuccessVisible(false)}
      />

      <View style={isMobile ? { flexDirection: "row", width: "100%", marginTop: 150 } : { flexDirection: "row", width: "100%", marginTop: "5%" }}>
        <View style={{ margin: 10 }}>
          <Text style={styles.lableText}>Access {">"} Project Access </Text>
        </View>
      </View>
      <View style={isMobile ? { width: "100%", marginTop: "0%" } : { flexDirection: "row", width: "100%", marginTop: "0%" }}>
        {" "}
        <View style={{ margin: 10, flex: 0.5 }}>
          <Text style={isMobile ? { fontWeight: "700", marginLeft: -10, fontSize: 18 } : { fontWeight: "700", margin: 12, fontSize: 14 }}>
            Project{" "}
          </Text>
        </View>
        <View style={{ flex: 3, margin: 10, marginRight: "6%" }}>
          <Dropdown
            style={isMobile ? {
            borderColor: "#c3c3c3",
            width: "100%",
            borderWidth: 1,
            borderRadius: 2,
            height: 40,
            outlineStyle: "none",
            padding: 10,
            marginTop: 5,
            marginBottom: 5,} : styles.dropdown}
            placeholderStyle={styles.placeholderStyle}
            selectedTextStyle={styles.selectedTextStyle}
            inputSearchStyle={styles.inputSearchStyle}
            iconStyle={styles.iconStyle}
            data={ProjectNameData}
            search
            maxHeight={300}
            labelField="project_name"
            valueField="project_name"
            placeholder={!isFocus ? "Select Project " : "...."}
            searchPlaceholder="Search..."
            value={projectValue}
            onChange={(item) => {
              setProjectValue(item.project_name);
              setProjectValId(item.id);
              // console.log("user type value---", item.project_name);
            }}
            renderItem={renderProjectListItems}
          />
        </View>
        <View style={isMobile ? { flex: 1, marginLeft: 50 } : { flex: 1, margin: 10 }}>
          <TouchableOpacity
            style={styles.addAccess}
            onPress={() => getAccessProjectDetails(projectValid)}
          // onPress={() => getAccessProjectDetails()}
          >
            <Text style={styles.btnText}>Give Access</Text>
          </TouchableOpacity>
        </View>
      </View>
      {shouldShow ? null : (
        // <ScrollView
        //   style={[styles.card, styles.shadowProp]}
        //   contentContainerStyle={{ flexGrow: 1 }}
        // >
        <View style={[styles.card, { flexGrow: 1 }]}>
          <View
            style={[
              styles.shadowProp,
              {
                backgroundColor: "white",
                borderRadius: 8,
                paddingVertical: 45,
                paddingHorizontal: 25,
              },
            ]}
          >
            <View
              style={{
                width: "100%",
                // flex: 1,
                // backgroundColor: "#F6F9F9",
                borderRadius: 8,
                // paddingVertical: 45,
                // paddingHorizontal: 100,
              }}
            >
              <View
                style={{
                  borderWidth: 1,
                  borderColor: "#e3e0e0",
                  borderRadius: 2,
                }}
              >
                <PaperProvider>
                  <DataTable>
                    <DataTable.Header>
                      <DataTable.Title style={{ flex: 1 }}>
                        User
                      </DataTable.Title>
                      <DataTable.Title>Access</DataTable.Title>

                      {/* <DataTable.Title>Create</DataTable.Title>
                      <DataTable.Title>List</DataTable.Title>

                      <DataTable.Title>View</DataTable.Title> */}
                    </DataTable.Header>

                    {UserNameData.map((item, index) => {
                      return renderMasterItem(item, index);
                    })}

                    {/* </View> */}
                  </DataTable>
                </PaperProvider>
              </View>
            </View>

            <View
              style={{
                flex: 1,
                width: "100%",
                alignItems: "center",
                marginTop: 10,
              }}
            >
              <TouchableOpacity
                style={styles.saveBtn}
                onPress={() => saveAccessData()}
              >
                <Text style={styles.btnText}>Save</Text>
              </TouchableOpacity>
            </View>

            {/* </ScrollView> */}
          </View>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    // marginTop: 350,
    height: "100%",
  },
  containeralt: {
    // alignItems: "center",
    backgroundColor: "white",
    borderColor: "#eee",
    borderRadius: 10,
    borderWidth: 1,
    // justifyContent: "center",
    height: 300,
    margin: "auto",
    padding: 30,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    // marginRight: 10,
    // marginRight: 1,
    width: "30%",
  },
  input: {
    borderColor: "gray",
    width: "100%",
    borderWidth: 1,
    borderRadius: 2,

    padding: 10,
    marginTop: 20,
    marginBottom: 20,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  addAccess: {
    alignItems: "center",
    backgroundColor: "#006EB9",
    padding: 10,
    borderWidth: 1,
    borderColor: "#006EB9",
    width: "70%",
    // marginTop: 20,
  },

  saveBtn: {
    alignItems: "center",
    backgroundColor: "#006EB9",
    padding: 10,
    borderWidth: 1,
    borderColor: "#006EB9",
    width: "20%",
    marginRight: 10,
  },
  btnText: {
    color: "#fff",
    fontWeight: "600",
  },
  card: {
    width: "100%",
    flex: 1,
    marginVertical: 10,
  },
  accessCard: {
    width: "100%",
    flex: 1,
    marginVertical: 10,
  },
  shadowProp: {
    shadowColor: "#171717",
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 10,
  },
  // DropDown CSS
  dropdown: {
    borderColor: "#c3c3c3",
    width: "30%",
    borderWidth: 1,
    borderRadius: 2,
    height: 40,
    outlineStyle: "none",

    padding: 10,
    marginTop: 5,
    marginBottom: 5,
    // borderRadius: 10,
    // padding: 12,
    // shadowColor: "#000",
    // shadowOffset: {
    //   width: 0,
    //   height: 1,
    // },
    // shadowOpacity: 0.2,
    // shadowRadius: 1.41,

    // elevation: 2,
  },
  icon: {
    marginRight: 5,
  },
  item: {
    padding: 17,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  textItem: {
    flex: 1,
    fontSize: 16,
  },
  placeholderStyle: {
    fontSize: 16,
  },
  selectedTextStyle: {
    fontSize: 16,
  },
  iconStyle: {
    width: 20,
    height: 20,
  },
  inputSearchStyle: {
    height: 40,
    fontSize: 16,
    outlineStyle: "none",
  },
  // Error message css
  error_text: {
    color: "red",
    fontSize: 14,
    fontWeight: "600",
    width: "70%",
    marginLeft: 0,
    marginBottom: 10,
  },
  lableText: {
    color: "#484848",
    fontWeight: "700",
    marginTop: 18,
    // fontSize: 21,
  },

  link: {
    marginTop: 15,
    paddingVertical: 15,
  },
  linkText: {
    fontSize: 14,
    color: "#2e78b7",
  },
});
