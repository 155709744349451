import Header from "../navigation/header";
import {
  StyleSheet,
  TouchableOpacity,
  Modal,
  ScrollView,
  Button,
  TextInput as TextInputRN,
  Switch,
} from "react-native-web";
import { useFocusEffect } from "@react-navigation/native";

import { Dropdown } from "react-native-element-dropdown";
import { Text, View } from "../../../components/Themed";
import { RootStackScreenProps } from "../../../types"; // @ts-ignore
import Icon from "react-native-vector-icons/AntDesign";
import React, { useEffect, useState, useMemo, useRef } from "react";
import IconCross from "react-native-vector-icons/Entypo";
import { postData } from "../../../networking/api";
// import { DataTable } from "react-native-paper";
import DataTable from "react-data-table-component";

import { Provider as PaperProvider } from "react-native-paper";
import IconEdit from "react-native-vector-icons/FontAwesome";
import IconEye from "react-native-vector-icons/Entypo";
import { TextInput } from "react-native-paper";
import SuccessPopup from "../../../components/SuccessPopup";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { Dimensions } from "react-native";

export default function Tehasil() {
  var [counter, setCounter] = useState(1);

  const [isVisible, setIsVisible] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const [isAdd, setisAdd] = useState(false);
  const [error, setError] = useState("");
  const textTehasilName = useRef("");
  const [tahasilLength, setTehasilLength] = useState(0);
  const [countryData, setCountryData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [CountryName, setCountryName] = useState([]);
  const [StateName, setStateName] = useState([]);
  const [CityNameData, setCityNameData] = useState([]);
  const [TehasilNameData, setTehasilNameData] = useState([]);
  const [tahsilNameListExport, setTahsilNameListExport] = useState([]);
  const [statesId, setStateId] = useState();
  const [cityId, setCityId] = useState();

  const [countrysIds, setCountryId] = useState();
  const [value, setValue] = useState(null);

  const [countryValue, setCountryValue] = useState("");
  const [countryValId, setCountryValId] = useState("");
  const [stateValue, setStateValue] = useState("");
  const [stateValId, setStateValId] = useState("");
  const [cityValue, setCityValue] = useState("");
  const [cityValId, setCityValId] = useState("");
  const [tahasilValue, setTahasilValue] = useState("");
  const [TahasilValId, setTahasilValId] = useState("");

  const [isFocus, setIsFocus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchData, setSearch] = useState("");
  const [filterData, setFilteredDataSource] = useState([]);

  const [issuccessVisible, setissuccessVisible] = useState(false);
  const [responsestatus, setresponsestatus] = useState("");
  const [PopupMessage, setPopupMessage] = useState("");

  const [countryError, setcountryError] = useState("");
  const [stateError, setstateError] = useState("");
  const [cityError, setcityError] = useState("");
  const [tahasilError, setTahasilError] = useState("");
  const [sort, setsort] = useState(0);
  const [editpopup, setEditPopup] = useState(false);
  const [popid, setpopid] = useState("");
  const [popindex, setpopindex] = useState("");

  var alpha_pattern = new RegExp(/^[a-zA-Z]+(\s[a-zA-Z]+)?$/);

  const dropdownRefcountry = useRef();
  const dropdownRefState = useRef();
  const dropdownRefCity = useRef();

  const closeMenu = () => {
    dropdownRefcountry.current.close();
    dropdownRefState.current.close();
    dropdownRefCity.current.close();
  };
  useEffect(() => {
    window.addEventListener("scroll", closeMenu);
    return () => window.removeEventListener("scroll", closeMenu);
  }, []);

  //Pagination
  const optionsPerPage = [10, 25, 50, 100];

  const [page, setPage] = React.useState(0);
  const [itemsPerPage, setItemsPerPage] = React.useState(optionsPerPage[0]);
  const from = page * itemsPerPage;
  const to = Math.min((page + 1) * itemsPerPage, tahasilLength);
  React.useEffect(() => {
    setPage(0);
    setpopindex("");
    setEditPopup(false);
    setpopid("");
  }, [itemsPerPage]);

  const isCreatable = () => {
    setIsVisible(true);
    setisAdd(true);
    setCountryValue("");
    setStateValue("");
    setCityValue("");
    setTahasilValue("");
  };

  //Datatable tahasil column and styles
  const customStyles = {
    rows: {
      style: {
        // maxWidth: 50,
        minHeight: "30px", // override the row height
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        backgroundColor: "#319ef0a3",
        minHeight: "40px",
        fontSize: 16,
        fontWeight: "600",
      },
    },
    headCells: {
      style: {
        // paddingLeft: '8px', // override the cell padding for head cells
        // paddingRight: '8px',
        justifyContent: "center",
        minHeight: "20px",

        // borderRightWidth: 2,
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          // fontSize: 16,
          // fontWeight: '600',
          // borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
        justifyContent: "center",

        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          fontSize: 16,
          fontWeight: "400",
          textTransform: "Capitalize",

          // borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };
  const caseInsensitiveSortTahasil = (rowA, rowB) => {
    const a = rowA.tahasil_name.toLowerCase();
    const b = rowB.tahasil_name.toLowerCase();

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };
  const caseInsensitiveSortCity = (rowA, rowB) => {
    const a = rowA.city_master.city.toLowerCase();
    const b = rowB.city_master.city.toLowerCase();

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };
  const caseInsensitiveSortState = (rowA, rowB) => {
    const a = rowA.state_master.state.toLowerCase();
    const b = rowB.state_master.state.toLowerCase();

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };
  const caseInsensitiveSortCountry = (rowA, rowB) => {
    const a = rowA.country_master.country.toLowerCase();
    const b = rowB.country_master.country.toLowerCase();

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };
  const columns = [
    {
      name: "Sr.No",
      selector: (row) => row.index,
      // //cell: (row, index) => index + 1,

      // sortable: true,
      width: "100px",
    },
    {
      name: "Country",
      selector: (row) => row.country_master.country,
      sortable: true,
      sortFuntion: caseInsensitiveSortCountry,
    },
    {
      name: "State",
      selector: (row) => row.state_master.state,
      sortable: true,
      sortFuntion: caseInsensitiveSortState,
    },
    {
      name: "City",
      selector: (row) => row.city_master.city,
      sortable: true,

      sortFuntion: caseInsensitiveSortCity,
    },
    {
      name: "Tahasil",
      selector: (row) => row.tahasil_name,
      sortable: true,
      sortFuntion: caseInsensitiveSortTahasil,
    },
    {
      name: "Action",
      // selector: "country",
      // sortable: true
      width: "100px",
      cell: (item) => (
        <TouchableOpacity
          onPress={() =>
            isAddable(
              item.id,
              item.country_id,
              item.country_master.country,
              item.state_id,
              item.state_master.state,
              item.city_id,
              item.city_master.city,
              item.tahasil_name
            )
          }
        >
          <IconEdit
            name="pencil"
            size={13}
            color="#fff"
            style={{
              padding: 6,
              backgroundColor: "#08588E",
              borderRadius: 12,
            }}
          />
        </TouchableOpacity>
      ),
    },
    {
      name: "Status",
      width: "100px",
      cell: (item, index) => (
        <Switch
          trackColor={{ false: "#767577", true: "#1DA1F2" }}
          thumbColor={item.active == 1 ? "#fff" : "#f4f3f4"}
          activeThumbColor="#fff"
          onValueChange={() => {
            // console.log("toggle index", index);
            setEditPopup(true);
            setpopid(item.id);
            setpopindex(index);
          }}
          value={item.active == 1 ? true : false}
        />
      ),
      // sortable: true,
    },
  ];

  const toggleSwitch = () => {
    var tempIndex = TehasilNameData.findIndex((item) => item.id === popid);

    if (TehasilNameData[tempIndex].active == 1) {
      TehasilNameData[tempIndex].active = 0;
      activeTehasil(popid, 0);
    } else {
      TehasilNameData[tempIndex].active = 1;
      activeTehasil(popid, 1);
    }
    setTehasilNameData(TehasilNameData);
  };
  const isAddable = (
    tahasil_id,
    country_id,
    country_name,
    state_id,
    state_name,
    city_id,
    city_name,
    tahasil_name
  ) => {
    setIsVisible(true);
    setisAdd(false);
    // getStateById(stateId);

    // Value set for edit
    setCountryValue(country_name);
    setStateValue(state_name);
    setCityValue(city_name);
    setTahasilValue(tahasil_name);

    //id set for edit
    setCountryValId(country_id);
    setStateValId(state_id);
    setCityValId(city_id);
    setTahasilValId(tahasil_id);

    getDependentCountryMaster(country_id);
    getDependentStateMaster(state_id);
  };

  useFocusEffect(
    React.useCallback(() => {
      getTehasil([]);
      getTahsilListForExport()
      searchFilterFunction("");

      getCountry();
    }, [])
  );

  const textToTehasilName = (text) => {
    textTehasilName.current = text.nativeEvent.text;
    setTahasilValue(text.nativeEvent.text);
    if (text.nativeEvent.text == "") {
      setTahasilError("Please enter tahasil");
    } else {
      setTahasilError("");
    }
  };

  const showDataTahasilName = () => {
    return textTehasilName.current;
  };
  const searchFilterFunction = (text) => {
    // Check if searched text is not blank
    if (text) {
      // Inserted text is not blank
      // Filter the masterDataSource
      // Update FilteredDataSource
      const newData = TehasilNameData.filter(function (item) {
        const itemData = item.tahasil_name
          ? item.tahasil_name.toUpperCase()
          : "".toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      // console.log("new data search---->", newData);
      setFilteredDataSource(newData);
      setSearch(text);
      setPage(0);
    } else {
      // Inserted text is blank
      // Update FilteredDataSource with masterDataSource
      setFilteredDataSource(TehasilNameData);
      setSearch(text);
      setPage(0);
    }
  };
  const CheckEnteredData = async () => {
    var TehasilNameDatas =
      showDataTahasilName() !== "" ? showDataTahasilName() : tahasilValue;
    //setUserType(userTypes);

    if (countryValue == "") {
      setcountryError("Please enter country");
    }
    if (stateValue == "") {
      setstateError("Please enter state");
    }
    if (cityValue == "") {
      setcityError("Please enter city");
    }
    if (tahasilValue == "") {
      setTahasilError("Please enter tahasil");
    }

    if (
      TehasilNameDatas == "" &&
      countryValue == "" &&
      stateValue == "" &&
      cityValue == ""
    ) {
      // //alert("pls enter");
      setError("Please enter required field Name");
      // console.log("empty");
      // setIsVisible(false);
    } else if (alpha_pattern.test(TehasilNameDatas)) {
      {
        isAdd == true && createTehasilDependent(TehasilNameDatas);
        setIsVisible(false);
      }

      {
        isAdd == false && updateTehasilDependent(TehasilNameDatas);
        setIsVisible(false);
      }
    } else {
      // console.log("falseśśś");
      // setError("Please enter Character Only.");
      // //alert("only character:", TehasilNameDatas);
      // setIsVisible(false);
    }

    // navigation.replace('DrawerNavigation');
  };

  //Tahasil Add Api
  const createTehasilDependent = async (tehasilName) => {
    // console.log("tehasilName", tehasilName);
    setIsLoading(true);
    try {
      var tahasilData = JSON.stringify({
        module: "tahasil_master",
        data: {
          country_id: countryValId,
          state_id: stateValId,
          city_id: cityValId,
          tahasil_name: tehasilName,
          active: 1,
        },
      });
      const TehasilNameDataResponse = await postData(
        tahasilData,
        "masters/masterCreateUpdate"
      );
      setIsLoading(false);
      var code = TehasilNameDataResponse.code;
      if (code == 1) {
        setPopupMessage("Record Created Successfully");
        setissuccessVisible(true);
        setresponsestatus("success");
        setIsVisible(false);
        getTehasil([]);
        getTahsilListForExport()
        setTahasilValue("");
        textTehasilName.current = "";
        // console.log("Added Tahasil", TehasilNameDataResponse.data);
      } else {
        setIsLoading(false);
        setissuccessVisible(true);
        setresponsestatus("failed");
        setPopupMessage(TehasilNameDataResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };
  const updateTehasilDependent = async (tehasilName) => {
    // console.log("UPDATE --tehasilName", tehasilName);
    setIsLoading(true);
    try {
      var tahasilData = JSON.stringify({
        id: TahasilValId,
        module: "tahasil_master",
        data: {
          country_id: countryValId,
          state_id: stateValId,
          city_id: cityValId,
          tahasil_name: tehasilName,
          active: 1,
        },
      });
      // console.log("tahasilData-----", tahasilData);
      const TahasilNameResponseUpdateData = await postData(
        tahasilData,
        "masters/masterCreateUpdate"
      );

      setIsLoading(false);

      var code = TahasilNameResponseUpdateData.code;
      if (code == 1) {
        setPopupMessage("Record Updated Successfully");
        setissuccessVisible(true);
        setresponsestatus("success");
        setIsVisible(false);
        getTehasil([]);
        getTahsilListForExport()
        setTahasilValue("");
        textTehasilName.current = "";
        // console.log("tahasil Updated", TahasilNameResponseUpdateData.data);
      } else {
        setIsLoading(false);
        setissuccessVisible(true);
        setresponsestatus("failed");
        setPopupMessage(TahasilNameResponseUpdateData.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };
  // Get dependent state by country
  const getDependentCountryMaster = async (country_id) => {
    // console.log("country_id masters", country_id);
    setIsLoading(true);

    try {
      var dependentData = JSON.stringify({
        column: "country_id",
        value: country_id,
        module: "state_master",
      });
      const dependentCountryDataResponse = await postData(
        dependentData,
        "masters/getDependentMasterList"
      );
      setIsLoading(false);
      var code = dependentCountryDataResponse.code;
      if (code == 1) {

        // setCountryLength(dependentCountryDataResponse.data.length);
        setIsLoading(false);
        setStateData(dependentCountryDataResponse.data);
      } else {
        setIsLoading(false);
        //alert(dependentCountryDataResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  // Get dependent City by state
  const getDependentStateMaster = async (state_id) => {
    // console.log("state_id masters===", state_id);
    setIsLoading(true);
    try {
      var dependentData = JSON.stringify({
        column: "state_id",
        value: state_id,
        module: "city_master",
      });
      const dependentStateDataResponse = await postData(
        dependentData,
        "masters/getDependentMasterList"
      );
      setIsLoading(false);
      var code = dependentStateDataResponse.code;
      if (code == 1) {

        // setCountryLength(dependentStateDataResponse.data.length);
        setCityNameData(dependentStateDataResponse.data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        //alert(dependentStateDataResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };
  //isActive State
  const activeTehasil = async (cityId, isActive) => {
    setIsLoading(true);
    try {
      var countryData = JSON.stringify({
        id: cityId,
        module: "tahasil_master",
        data: {
          active: isActive,
        },
      });
      const activeTehasilResponse = await postData(
        countryData,
        "masters/masterCreateUpdate"
      );

      setIsLoading(false);

      var code = activeTehasilResponse.code;
      if (code == 1) {
        // //alert(activeTehasilResponse.message);
        setIsVisible(false);
        // console.log("status changed", activeTehasilResponse.data);
        getTehasil([]);
        getTahsilListForExport()
      } else {
        setIsLoading(false);
        // //alert(activeTehasilResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };
  // Country Get Api
  const getCountry = async () => {
    setIsLoading(true);
    try {
      var countryData = JSON.stringify({
        module: "country_master",
        data: {
          active: 1,
        },
        relation: [],
      });
      const countryNameResponse = await postData(
        countryData,
        "masters/getMasterList"
      );
      setIsLoading(false);
      var code = countryNameResponse.code;
      if (code == 1) {
        // console.log("Country list====>", countryNameResponse.data);
        // setCountryLength(countryNameResponse.data.length);
        setCountryName(countryNameResponse.data);
      } else {
        setIsLoading(false);
        //alert(countryNameResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };
  // Get all Tahasil list Api
  const getTehasil = async (sortdata: never[]) => {
    setIsLoading(true);
    if (sortdata.length > 0) {
      setTehasilLength(sortdata.length);
      setTehasilNameData(sortdata);
      setFilteredDataSource(sortdata);
    } else {
      try {
        var getTahasilData = JSON.stringify({
          module: "tahasil_master",
          // "relation": []
          relation: [
            {
              module: "country_master",
            },
            {
              module: "state_master",
            },
            {
              module: "city_master",
            },
          ],
        });
        const TahasilNameResponse = await postData(
          getTahasilData,
          "masters/getMasterList"
        );
        setIsLoading(false);
        var code = TahasilNameResponse.code;
        if (code == 1) {


          setTehasilLength(TahasilNameResponse.data.length);
          setTehasilNameData(TahasilNameResponse.data);
          setFilteredDataSource(TahasilNameResponse.data);
        } else {
          setIsLoading(false);
          //alert(TahasilNameResponse.message);
        }
      } catch (error) {
        setIsLoading(false);
        // console.log(error);
      }
    }
  };

  const getTahsilListForExport = async () => {
    setIsLoading(true);
    try {
      var stateData = JSON.stringify({
        modelName: "tahasil_master"
      });
      const tahsilNameDataResponse = await postData(
        stateData,
        "masters/dependentListExport"
      );
      setIsLoading(false);
      var code = tahsilNameDataResponse.code;
      console.log('tahsilNameDataResponse==>', tahsilNameDataResponse);
      if (code == 1) {
        setTahsilNameListExport(tahsilNameDataResponse.data);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const renderCountryList = (item: any) => {
    return (
      <View style={styles.item}>
        {isAdd == true && (
          <Text
            style={styles.textItem}
          // onPress={() => getDependentCountryMaster(item.id)}
          >
            {item.country}
          </Text>
        )}
        {isAdd == false && (
          <Text
            style={styles.textItem}
          // onPress={() => getDependentCountryMaster(item.id)}
          >
            {item.country}
          </Text>
        )}
        {item.id === countryValId && (
          <Icon style={styles.icon} color="black" name="Safety" size={20} />
        )}
      </View>
    );
  };
  const renderStateListItems = (item) => {
    // // console.log("State id item", item.id);
    return (
      <View style={styles.item}>
        {isAdd == true && (
          <Text
            // onPress={() => getDependentStateMaster(item.id)}
            style={styles.textItem}
          >
            {item.state}
          </Text>
        )}
        {isAdd == false && (
          <Text
            // onPress={() => getDependentStateMaster(item.id)}
            style={styles.textItem}
          >
            {item.state}
          </Text>
        )}
        {item.id === stateValId && (
          <Icon style={styles.icon} color="black" name="Safety" size={20} />
        )}
      </View>
    );
  };
  const renderCityListItems = (item) => {
    // console.log("city item name-", item.city);
    return (
      <View style={styles.item}>
        {isAdd == true && <Text style={styles.textItem}>{item.city}</Text>}
        {isAdd == false && <Text style={styles.textItem}>{item.city}</Text>}
        {item.id === cityValId && (
          <Icon style={styles.icon} color="black" name="Safety" size={20} />
        )}
      </View>
    );
  };

  function CityModal() {
    // const [isVisible, setIsVisible] = useState(false);

    return (
      <>
        <Modal
          onRequestClose={() => setIsVisible(false)}
          transparent
          visible={isVisible}
        >
          {" "}
          <View
            style={{
              position: "absolute",
              width: "100%",
              height: "120%",
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",
              backgroundColor: "rgba(100,100,100, 0.5)",
            }}
          >
            <View style={[styles.containeralt]}>
              <TouchableOpacity
                style={{ alignSelf: "flex-end" }}
                onPress={() => setIsVisible(false)}
              >
                <IconCross
                  name="circle-with-cross"
                  size={20}
                  color="#000"
                  style={{ bottom: 5, top: 10 }}
                />
              </TouchableOpacity>

              <View style={{ width: "100%" }}>
                <Text style={{ fontWeight: "600" }}>Country Name</Text>
                {isAdd == true && (
                  <>
                    <Dropdown
                      ref={dropdownRefcountry}
                      style={styles.dropdown}
                      placeholderStyle={styles.placeholderStyle}
                      selectedTextStyle={styles.selectedTextStyle}
                      inputSearchStyle={styles.inputSearchStyle}
                      iconStyle={styles.iconStyle}
                      data={CountryName}
                      search
                      maxHeight={300}
                      labelField="country"
                      valueField="country"
                      placeholder={!isFocus ? "Select Country" : "...."}
                      searchPlaceholder="Search..."
                      value={countryValue}
                      onChange={(item) => {
                        setCountryValue(item.country);
                        setCountryValId(item.id);
                        getDependentCountryMaster(item.id);
                        // console.log("country id val T", item);
                        if (item.country == "") {
                          setcountryError("Please enter country");
                        } else {
                          setcountryError("");
                        }
                      }}
                      renderItem={renderCountryList}
                    />
                    <Text style={[styles.error_text]}>{countryError}</Text>
                  </>
                )}
                {isAdd == false && (
                  <>
                    <Dropdown
                      ref={dropdownRefcountry}
                      style={styles.dropdown}
                      placeholderStyle={styles.placeholderStyle}
                      selectedTextStyle={styles.selectedTextStyle}
                      inputSearchStyle={styles.inputSearchStyle}
                      iconStyle={styles.iconStyle}
                      data={CountryName}
                      search
                      maxHeight={300}
                      labelField="country"
                      valueField="country"
                      placeholder={!isFocus ? "Select Country" : "...."}
                      searchPlaceholder="Search..."
                      value={countryValue}
                      onChange={(item) => {
                        setCountryValue(item.country);
                        setCountryValId(item.id);
                        getDependentCountryMaster(item.id);
                        // console.log("country id val T update", item);
                        if (item.country == "") {
                          setcountryError("Please enter country");
                        } else {
                          setcountryError("");
                        }
                      }}
                      renderItem={renderCountryList}
                    />
                    <Text style={[styles.error_text]}>{countryError}</Text>
                  </>
                )}
              </View>
              <View style={{ width: "100%" }}>
                <Text style={{ fontWeight: "600" }}>State Name</Text>
                {isAdd == true && (
                  <>
                    <Dropdown
                      ref={dropdownRefState}
                      style={styles.dropdown}
                      placeholderStyle={styles.placeholderStyle}
                      selectedTextStyle={styles.selectedTextStyle}
                      inputSearchStyle={styles.inputSearchStyle}
                      iconStyle={styles.iconStyle}
                      data={stateData}
                      search
                      maxHeight={300}
                      labelField="state"
                      valueField="state"
                      placeholder={!isFocus ? "Select State" : "...."}
                      searchPlaceholder="Search..."
                      value={stateValue}
                      onChange={(item) => {
                        setStateValue(item.state);
                        setStateValId(item.id);
                        getDependentStateMaster(item.id);
                        // console.log("state create value---add", item.id);
                        if (item.state == "") {
                          setstateError("Please enter state");
                        } else {
                          setstateError("");
                        }
                      }}
                      renderItem={renderStateListItems}
                    />
                    <Text style={[styles.error_text]}>{stateError}</Text>
                  </>
                )}
                {isAdd == false && (
                  <>
                    <Dropdown
                      ref={dropdownRefState}
                      style={styles.dropdown}
                      placeholderStyle={styles.placeholderStyle}
                      selectedTextStyle={styles.selectedTextStyle}
                      inputSearchStyle={styles.inputSearchStyle}
                      iconStyle={styles.iconStyle}
                      data={stateData}
                      search
                      maxHeight={300}
                      labelField="state"
                      valueField="state"
                      placeholder={!isFocus ? "Select State" : "...."}
                      searchPlaceholder="Search..."
                      value={stateValue}
                      onChange={(item) => {
                        setStateValue(item.state);
                        setStateValId(item.id);
                        getDependentStateMaster(item.id);
                        // console.log("state create value---update", item.id);
                        if (item.state == "") {
                          setstateError("Please enter state");
                        } else {
                          setstateError("");
                        }
                      }}
                      renderItem={renderStateListItems}
                    />
                    <Text style={[styles.error_text]}>{stateError}</Text>
                  </>
                )}
              </View>
              <View style={{ width: "100%" }}>
                <Text style={{ fontWeight: "600" }}>City Name</Text>
                {isAdd == true && (
                  <>
                    <Dropdown
                      ref={dropdownRefCity}
                      style={styles.dropdown}
                      placeholderStyle={styles.placeholderStyle}
                      selectedTextStyle={styles.selectedTextStyle}
                      inputSearchStyle={styles.inputSearchStyle}
                      iconStyle={styles.iconStyle}
                      data={CityNameData}
                      search
                      maxHeight={300}
                      labelField="city"
                      valueField="city"
                      placeholder={!isFocus ? "Select City" : "...."}
                      searchPlaceholder="Search..."
                      value={cityValue}
                      onChange={(item) => {
                        setCityValue(item.city);
                        setCityValId(item.id);
                        // console.log("city value---add", item.id);
                        if (item.city == "") {
                          setcityError("Please enter city");
                        } else {
                          setcityError("");
                        }
                      }}
                      renderItem={renderCityListItems}
                    />
                    <Text style={[styles.error_text]}>{cityError}</Text>
                  </>
                )}
                {isAdd == false && (
                  <>
                    <Dropdown
                      ref={dropdownRefCity}
                      style={styles.dropdown}
                      placeholderStyle={styles.placeholderStyle}
                      selectedTextStyle={styles.selectedTextStyle}
                      inputSearchStyle={styles.inputSearchStyle}
                      iconStyle={styles.iconStyle}
                      data={CityNameData}
                      search
                      maxHeight={300}
                      labelField="city"
                      valueField="city"
                      placeholder={!isFocus ? "Select city" : "...."}
                      searchPlaceholder="Search..."
                      value={cityValue}
                      onChange={(item) => {
                        setCityValue(item.city);

                        setCityValId(item.id);

                        // console.log("city value---add", item.id);
                        if (item.city == "") {
                          setcityError("Please enter city");
                        } else {
                          setcityError("");
                        }
                      }}
                      renderItem={renderCityListItems}
                    />
                    <Text style={[styles.error_text]}>{cityError}</Text>
                  </>
                )}
              </View>
              <View style={{ width: "100%" }}>
                <Text style={{ fontWeight: "600" }}>Tahasil Name</Text>
                {isAdd == true && (
                  <>
                    <TextInputRN
                      style={styles.input}
                      placeholder=""
                      //
                      value={tahasilValue}
                      autoFocus={true}
                      onChange={textToTehasilName}
                    />
                    <Text style={[styles.error_text]}>{tahasilError}</Text>
                  </>
                )}

                {isAdd == false && (
                  <>
                    <TextInputRN
                      style={styles.input}
                      placeholder=""
                      value={tahasilValue}
                      key="Edit"
                      autoFocus
                      onChangeText={(text) => {
                        setTahasilValue(text);
                        if (text == "") {
                          setTahasilError("Please enter tahasil");
                        } else {
                          setTahasilError("");
                        }
                      }}
                    />
                    <Text style={[styles.error_text]}>{tahasilError}</Text>
                  </>
                )}
              </View>

              {isAdd == true && (
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                    marginHorizontal: 20,
                  }}
                >
                  <TouchableOpacity
                    style={styles.saveBtn}
                    onPress={() => CheckEnteredData()}
                  >
                    <Text style={styles.btnText}>Save</Text>
                  </TouchableOpacity>

                  <TouchableOpacity
                    style={styles.saveBtn}
                    onPress={() => {
                      setIsVisible(false),
                        setcountryError(""),
                        setcityError(""),
                        setstateError(""),
                        setTahasilError("");
                    }}
                  >
                    <Text style={styles.btnText}>Cancel</Text>
                  </TouchableOpacity>
                </View>
              )}
              {isAdd == false && (
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                    marginHorizontal: 20,
                  }}
                >
                  <TouchableOpacity
                    style={styles.saveBtn}
                    onPress={() => CheckEnteredData()}
                  >
                    <Text style={styles.btnText}>Update</Text>
                  </TouchableOpacity>

                  <TouchableOpacity
                    style={styles.saveBtn}
                    onPress={() => setIsVisible(false)}
                  >
                    <Text style={styles.btnText}>Cancel</Text>
                  </TouchableOpacity>
                </View>
              )}

              {/* <Gap />
          {isVisible ? <Modalception depth={depth + 1} /> : null}
          <Gap /> */}
            </View>
          </View>
        </Modal>
      </>
    );
  }

  const sortFun = () => {
    const sortData = filterData.sort((item, b) =>
      sort == 1
        ? item.country_master.country.localeCompare(b.country_master.country)
        : b.country_master.country.localeCompare(item.country_master.country)
    );
    getTehasil(sortData);
    setFilteredDataSource(sortData);
  };

  const downloadExcelReport = async () => {
    const filteredData = tahsilNameListExport.map(({ country, state, city, tahasil_name, active }, index) => ({
      'Sr No': index + 1,
      'Country': country,
      'State': state,
      'City': city,
      'Tahasil': tahasil_name,
      'Active': active == 1 ? 'Active': 'InActive',
    }));
    // const filteredData = CountryName.map(({ createdAt, updatedAt, id, ...rest }) => rest);
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, {
      type: 'array',
      bookType: 'xlsx',
    });
    const excelData = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(excelData, 'tahasil_master.xlsx');
  }
  const screenwidth = Dimensions.get('window').width
  const isMobile = screenwidth < 768;
  return (
    <View style={styles.container}>
      <SuccessPopup
        visible={issuccessVisible}
        PopupMessage={PopupMessage}
        Responsestatus={responsestatus}
        onChange={() => setissuccessVisible(false)}
      />

      <Modal
        onRequestClose={() => setIsVisible(false)}
        transparent
        visible={isVisible}
      >
        {" "}
        <View
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
            backgroundColor: "rgba(100,100,100, 0.5)",
          }}
        >
          <View style={isMobile ? {
            // alignItems: "center",
            backgroundColor: "white",
            borderColor: "#eee",
            borderRadius: 10,
            borderWidth: 1,
            // justifyContent: "center",
            height: "auto",
            margin: "auto",
            padding: 30,
            shadowOffset: {
              width: 0,
              height: 2,
            },
            shadowOpacity: 0.25,
            shadowRadius: 4,
            elevation: 5,
            // marginRight: 10,
            // marginRight: 1,
            width: "80%",
          } : [styles.containeralt]}>
            <TouchableOpacity
              style={{ alignSelf: "flex-end" }}
              onPress={() => {
                setIsVisible(false),
                  setcountryError(""),
                  setcityError(""),
                  setstateError(""),
                  setTahasilError("");
              }}
            >
              <IconCross
                name="circle-with-cross"
                size={20}
                color="#000"
                style={{ bottom: 5, top: 10 }}
              />
            </TouchableOpacity>

            <View style={{ width: "100%" }}>
              <Text style={{ fontWeight: "600" }}>Country Name</Text>
              {isAdd == true && (
                <>
                  <Dropdown
                    ref={dropdownRefcountry}
                    style={styles.dropdown}
                    placeholderStyle={styles.placeholderStyle}
                    selectedTextStyle={styles.selectedTextStyle}
                    inputSearchStyle={styles.inputSearchStyle}
                    iconStyle={styles.iconStyle}
                    data={CountryName}
                    search
                    maxHeight={300}
                    labelField="country"
                    valueField="country"
                    placeholder={!isFocus ? "Select Country" : "...."}
                    searchPlaceholder="Search..."
                    value={countryValue}
                    onChange={(item) => {
                      setCountryValue(item.country);
                      setCountryValId(item.id);
                      getDependentCountryMaster(item.id);
                      // console.log("country id val T", item);
                      if (item.country == "") {
                        setcountryError("Please enter country");
                      } else {
                        setcountryError("");
                      }
                    }}
                    renderItem={renderCountryList}
                  />
                  <Text style={[styles.error_text]}>{countryError}</Text>
                </>
              )}
              {isAdd == false && (
                <>
                  <Dropdown
                    ref={dropdownRefcountry}
                    style={styles.dropdown}
                    placeholderStyle={styles.placeholderStyle}
                    selectedTextStyle={styles.selectedTextStyle}
                    inputSearchStyle={styles.inputSearchStyle}
                    iconStyle={styles.iconStyle}
                    data={CountryName}
                    search
                    maxHeight={300}
                    labelField="country"
                    valueField="country"
                    placeholder={!isFocus ? "Select Country" : "...."}
                    searchPlaceholder="Search..."
                    value={countryValue}
                    onChange={(item) => {
                      setCountryValue(item.country);
                      setCountryValId(item.id);
                      getDependentCountryMaster(item.id);
                      // console.log("country id val T update", item);
                      if (item.country == "") {
                        setcountryError("Please enter country");
                      } else {
                        setcountryError("");
                      }
                    }}
                    renderItem={renderCountryList}
                  />
                  <Text style={[styles.error_text]}>{countryError}</Text>
                </>
              )}
            </View>
            <View style={{ width: "100%" }}>
              <Text style={{ fontWeight: "600" }}>State Name</Text>
              {isAdd == true && (
                <>
                  <Dropdown
                    ref={dropdownRefState}
                    style={styles.dropdown}
                    placeholderStyle={styles.placeholderStyle}
                    selectedTextStyle={styles.selectedTextStyle}
                    inputSearchStyle={styles.inputSearchStyle}
                    iconStyle={styles.iconStyle}
                    data={stateData}
                    search
                    maxHeight={300}
                    labelField="state"
                    valueField="state"
                    placeholder={!isFocus ? "Select State" : "...."}
                    searchPlaceholder="Search..."
                    value={stateValue}
                    onChange={(item) => {
                      setStateValue(item.state);
                      setStateValId(item.id);
                      getDependentStateMaster(item.id);
                      // console.log("state create value---add", item.id);
                      if (item.state == "") {
                        setstateError("Please enter state");
                      } else {
                        setstateError("");
                      }
                    }}
                    renderItem={renderStateListItems}
                  />
                  <Text style={[styles.error_text]}>{stateError}</Text>
                </>
              )}
              {isAdd == false && (
                <>
                  <Dropdown
                    ref={dropdownRefState}
                    style={styles.dropdown}
                    placeholderStyle={styles.placeholderStyle}
                    selectedTextStyle={styles.selectedTextStyle}
                    inputSearchStyle={styles.inputSearchStyle}
                    iconStyle={styles.iconStyle}
                    data={stateData}
                    search
                    maxHeight={300}
                    labelField="state"
                    valueField="state"
                    placeholder={!isFocus ? "Select State" : "...."}
                    searchPlaceholder="Search..."
                    value={stateValue}
                    onChange={(item) => {
                      setStateValue(item.state);
                      setStateValId(item.id);
                      getDependentStateMaster(item.id);
                      // console.log("state create value---update", item.id);
                      if (item.state == "") {
                        setstateError("Please enter state");
                      } else {
                        setstateError("");
                      }
                    }}
                    renderItem={renderStateListItems}
                  />
                  <Text style={[styles.error_text]}>{stateError}</Text>
                </>
              )}
            </View>
            <View style={{ width: "100%" }}>
              <Text style={{ fontWeight: "600" }}>City Name</Text>
              {isAdd == true && (
                <>
                  <Dropdown
                    ref={dropdownRefCity}
                    style={styles.dropdown}
                    placeholderStyle={styles.placeholderStyle}
                    selectedTextStyle={styles.selectedTextStyle}
                    inputSearchStyle={styles.inputSearchStyle}
                    iconStyle={styles.iconStyle}
                    data={CityNameData}
                    search
                    maxHeight={300}
                    labelField="city"
                    valueField="city"
                    placeholder={!isFocus ? "Select City" : "...."}
                    searchPlaceholder="Search..."
                    value={cityValue}
                    onChange={(item) => {
                      setCityValue(item.city);
                      setCityValId(item.id);
                      // console.log("city value---add", item.id);
                      if (item.city == "") {
                        setcityError("Please enter city");
                      } else {
                        setcityError("");
                      }
                    }}
                    renderItem={renderCityListItems}
                  />
                  <Text style={[styles.error_text]}>{cityError}</Text>
                </>
              )}
              {isAdd == false && (
                <>
                  <Dropdown
                    ref={dropdownRefCity}
                    style={styles.dropdown}
                    placeholderStyle={styles.placeholderStyle}
                    selectedTextStyle={styles.selectedTextStyle}
                    inputSearchStyle={styles.inputSearchStyle}
                    iconStyle={styles.iconStyle}
                    data={CityNameData}
                    search
                    maxHeight={300}
                    labelField="city"
                    valueField="city"
                    placeholder={!isFocus ? "Select city" : "...."}
                    searchPlaceholder="Search..."
                    value={cityValue}
                    onChange={(item) => {
                      setCityValue(item.city);

                      setCityValId(item.id);

                      // console.log("city value---add", item.id);
                      if (item.city == "") {
                        setcityError("Please enter city");
                      } else {
                        setcityError("");
                      }
                    }}
                    renderItem={renderCityListItems}
                  />
                  <Text style={[styles.error_text]}>{cityError}</Text>
                </>
              )}
            </View>
            <View style={{ width: "100%" }}>
              <Text style={{ fontWeight: "600" }}>Tahasil Name</Text>
              {isAdd == true && (
                <>
                  <TextInputRN
                    style={styles.input}
                    placeholder=""
                    //
                    value={tahasilValue}
                    autoFocus={true}
                    onChange={textToTehasilName}
                  />
                  <Text style={[styles.error_text]}>{tahasilError}</Text>
                </>
              )}

              {isAdd == false && (
                <>
                  <TextInputRN
                    style={styles.input}
                    placeholder=""
                    value={tahasilValue}
                    key="Edit"
                    autoFocus
                    onChangeText={(text) => {
                      setTahasilValue(text);
                      if (text == "") {
                        setTahasilError("Please enter tahasil");
                      } else {
                        setTahasilError("");
                      }
                    }}
                  />
                  <Text style={[styles.error_text]}>{tahasilError}</Text>
                </>
              )}
            </View>

            {isAdd == true && (
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  marginHorizontal: 20,
                }}
              >
                <TouchableOpacity
                  style={styles.saveBtn}
                  onPress={() => CheckEnteredData()}
                >
                  <Text style={styles.btnText}>Save</Text>
                </TouchableOpacity>

                <TouchableOpacity
                  style={styles.saveBtn}
                  onPress={() => {
                    setIsVisible(false), setError("");
                  }}
                >
                  <Text style={styles.btnText}>Cancel</Text>
                </TouchableOpacity>
              </View>
            )}
            {isAdd == false && (
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  marginHorizontal: 20,
                }}
              >
                <TouchableOpacity
                  style={styles.saveBtn}
                  onPress={() => CheckEnteredData()}
                >
                  <Text style={styles.btnText}>Update</Text>
                </TouchableOpacity>

                <TouchableOpacity
                  style={styles.saveBtn}
                  onPress={() => {
                    setIsVisible(false),
                      setcountryError(""),
                      setcityError(""),
                      setstateError(""),
                      setTahasilError("");
                  }}
                >
                  <Text style={styles.btnText}>Cancel</Text>
                </TouchableOpacity>
              </View>
            )}

            {/* <Gap />
          {isVisible ? <Modalception depth={depth + 1} /> : null}
          <Gap /> */}
          </View>
        </View>
      </Modal>
      {/* <Header /> */}
      {/* <Text style={styles.title}>This screen is Profile screeen.</Text> */}
      <Modal
        visible={editpopup}
        animationType={"slide"}
        transparent={true}
        onRequestClose={() => {
          setEditPopup(false);
        }}
      >
        <View style={isMobile ? {
            // alignItems: "center",
            backgroundColor: "white",
            borderColor: "#eee",
            borderRadius: 10,
            borderWidth: 1,
            // justifyContent: "center",
            height: "auto",
            margin: "auto",
            padding: 30,
            shadowOffset: {
              width: 0,
              height: 2,
            },
            shadowOpacity: 0.25,
            shadowRadius: 4,
            elevation: 5,
            // marginRight: 10,
            // marginRight: 1,
            width: "80%",
          } : [styles.contanerSwitch]}>
          <TouchableOpacity
            style={{ alignSelf: "flex-end" }}
            onPress={() => {
              setEditPopup(false),
                setcountryError(""),
                setcityError(""),
                setstateError(""),
                setTahasilError("");
            }}
          >
            <IconCross
              name="circle-with-cross"
              size={20}
              color="#000"
              style={{ bottom: 5, top: 0 }}
            />
          </TouchableOpacity>

          <Text style={[styles.tableHead, { marginBottom: 20 }]}>
            Do You Want Update Status
          </Text>
          <View
            style={{
              flexDirection: "row",
              paddingRight: 10,
              justifyContent: "space-between",
            }}
          >
            <TouchableOpacity
              onPress={() => {
                setEditPopup(false), toggleSwitch();
              }}
              style={styles.saveBtn}
            >
              <Text style={{ color: "white" }}>Yes</Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                setEditPopup(false);
              }}
              style={styles.saveBtn}
            >
              <Text style={{ color: "white" }}>No</Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>
      <View style={isMobile ? { width: "100%", marginTop: 180 } : { flexDirection: "row", width: "100%", marginTop: "10%" }}>
        <View style={{ flex: 1, margin: 10 }}>
          <Text style={styles.lableText}>Master {">"} Tahasil Master</Text>
        </View>
        <View style={isMobile ? { alignItems: "center", marginTop: 20  , marginLeft:'-35%', width:"170%", flex:2 } : { alignSelf: "center", alignItems: "center", flex: 2 }}>
          {/* <TouchableOpacity onPress={() => exportCsvZoneData()}><Text>Download CSV</Text></TouchableOpacity> */}
          <TextInput
            style={isMobile ? {
              height: 35,
              borderWidth: 2,
              paddingLeft: 10,
              borderColor: "#319ef0",
              backgroundColor: "#FFFFFF",
              width: "50%",
              borderRadius: 5,
              color: "#000",
              fontWeight: "400",
            } : styles.textInputStyle}
            onChangeText={(text) => searchFilterFunction(text)}
            value={searchData}
            underlineColorAndroid="transparent"
            underlineColor={"white"}
            placeholder="Search tahasil"
            theme={{
              colors: {
                text: "black",
                primary: "white",
                background: "transparent",
              },
            }}
            left={
              <TextInput.Icon
                name={() => <IconEdit name="search" size={20} color="#000" />} // where <Icon /> is any component from vector-icons or anything else
              />
            }
          />
        </View>
        <View style={{ flex: 1, alignItems: "flex-end" }}>
          <TouchableOpacity
            style={styles.addCountry}
            onPress={() => {
              isCreatable(),
                setError(""),
                setcountryError(""),
                setcityError(""),
                setstateError(""),
                setTahasilError("");
            }}
          >
            <Text style={styles.btnText}>
              <Icon name="plus" size={15} color="#fff" /> Add Tahasil
            </Text>
          </TouchableOpacity>
        </View>
      </View>

      <View style={isMobile ? { width: '100%', alignItems: "flex-end", marginTop:"-10%", marginLeft:"-120%" } : { width: '100%', alignItems: "flex-end" }}>
        <TouchableOpacity
          style={isMobile ? [styles.addCountry, { width: '45%',marginRight:-10 }] : [styles.addCountry, { width: '10%' }]}
          onPress={() => {
            downloadExcelReport();
          }}
        >
          <Text style={styles.btnText}>
            <Icon name="exclefile1" size={15} color="#fff" /> Download
          </Text>
        </TouchableOpacity>
      </View>
      {/* <ScrollView
        style={[styles.card, styles.shadowProp]}
        contentContainerStyle={{ flexGrow: 1 }}
      > */}
      <View style={isMobile ? [styles.card, { flexGrow: 1, marginTop:50, paddingBottom:10 }] : [styles.card, { flexGrow: 1 }]}>
        <View
          style={[
            styles.shadowProp,
            {
              backgroundColor: "white",
            },
          ]}
        >
          <View
            style={{
              width: "100%",
              // flex: 1,
              backgroundColor: "#F6F9F9",
            }}
          >
            <View
              style={isMobile ? {
                borderWidth: 1,
                borderColor: "#e3e0e0",
                width: "120%",
                alignSelf: "center",
              }: {
                borderWidth: 1,
                borderColor: "#e3e0e0",
              }}
            >
              <View>
                <DataTable
                  // title="Tahasil"
                  columns={columns}
                  data={filterData}
                  customStyles={customStyles}
                  pagination
                />
              </View>
              {/* prvider datatable code here */}

              {/* ended */}
            </View>
          </View>
          {/* </ScrollView> */}
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    // marginTop: 300,
  },
  containeralt: {
    // alignItems: "center",
    backgroundColor: "white",
    borderColor: "#eee",
    borderRadius: 10,
    borderWidth: 1,
    // justifyContent: "center",
    height: "auto",
    margin: "auto",
    padding: 30,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    // marginRight: 10,
    // marginRight: 1,
    width: "30%",
  },
  contanerSwitch: {
    backgroundColor: "white",
    borderColor: "#eee",
    borderRadius: 5,
    alignSelf: "center",
    borderWidth: 1,
    // justifyContent: "center",
    height: "25%",
    margin: "auto",
    padding: 10,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    // marginRight: 10,
    // marginRight: 1,
    width: "30%",
  },
  tableHeader: {
    color: "#000",
    fontSize: 14,
    /* FontFamily: 'Muli' ,*/
    fontWeight: "400",
  },
  input: {
    borderColor: "#c3c3c3",
    width: "100%",
    borderWidth: 1,
    borderRadius: 2,

    padding: 10,
    marginTop: 10,
    marginBottom: 0,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  addCountry: {
    alignItems: "center",
    backgroundColor: "#006EB9",
    padding: 10,
    borderWidth: 1,
    borderColor: "#006EB9",
    width: "50%",
    // marginTop: 20,
  },
  saveBtn: {
    alignItems: "center",
    backgroundColor: "#006EB9",
    padding: 10,
    borderWidth: 1,
    borderColor: "#006EB9",
    width: "50%",
    marginRight: 10,
  },
  btnText: {
    color: "#fff",
    fontWeight: "600",
  },
  card: {
    width: "90%",
    flex: 1,
    marginVertical: 10,
  },
  shadowProp: {
    shadowColor: "#171717",
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 25,
  },

  link: {
    marginTop: 15,
    paddingVertical: 15,
  },
  linkText: {
    fontSize: 14,
    color: "#2e78b7",
  },
  textInputStyle: {
    height: 35,
    borderWidth: 2,
    paddingLeft: 20,
    borderRadius: 5,
    borderColor: "#319ef0",
    backgroundColor: "#FFFFFF",
    width: "35%",
    fontWeight: "400",
  },
  tableHead: {
    fontSize: 14,
    /* FontFamily: 'Muli' ,*/
    fontWeight: "700",
    color: "#000000",
  },
  // DropDown CSS
  dropdown: {
    borderColor: "#c3c3c3",
    width: "100%",
    borderWidth: 1,
    borderRadius: 2,
    height: 40,
    outlineStyle: "none",

    padding: 10,
    marginTop: 5,
    marginBottom: 5,
    // borderRadius: 10,
    // padding: 12,
    // shadowColor: "#000",
    // shadowOffset: {
    //   width: 0,
    //   height: 1,
    // },
    // shadowOpacity: 0.2,
    // shadowRadius: 1.41,

    // elevation: 2,
  },
  lableText: {
    color: "#484848",
    fontWeight: "700",
    marginTop: 18,
    // fontSize: 21,
  },
  icon: {
    marginRight: 5,
  },
  item: {
    padding: 9,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  textItem: {
    flex: 1,
    fontSize: 16,
  },
  placeholderStyle: {
    fontSize: 16,
  },
  selectedTextStyle: {
    fontSize: 16,
  },
  iconStyle: {
    width: 20,
    height: 20,
  },
  inputSearchStyle: {
    height: 40,
    fontSize: 16,
    outlineStyle: "none",
  },
  error_text: {
    color: "red",
    fontSize: 14,
    fontWeight: "600",
    width: "70%",
    marginLeft: 0,
    marginBottom: 10,
  },
});
