import AsyncStorage from "@react-native-async-storage/async-storage";
import { useFocusEffect } from "@react-navigation/native";
import moment from "moment";
import IconMaterial from "react-native-vector-icons/MaterialCommunityIcons";

import React, { Component, useState } from "react";
import {
  StyleSheet,
  View,
  Text,
  FlatList,
  Image,
  TouchableOpacity,
  ActivityIndicator,
  Dimensions,
} from "react-native";
import { Avatar, Button, Card, Title, Paragraph } from "react-native-paper";
import Icon from "react-native-vector-icons/FontAwesome5";
import { useSelector } from "react-redux";
import { postData } from "../../networking/api";

export default function Subzone({ navigation, route }) {
  const { Zoneid, ZoneName, SubzoneId, SubZoneName, OmegaName } = route?.params;

  var projectName = useSelector((state) => state.projectName);
  const [project_name, setProjectName] = useState("");
  const [projectId, setProjectId] = useState("");
  const [isLoadingSync, setIsLoadingSync] = useState(false);
  const [zoneGridDetails, setZoneGridDetails] = useState([]);

  const DATA = [
    {
      id: "bd7acbea-c1b1-46c2-aed5-3ad53abb28ba",
      title: "Next Irrigation",
      level: "12568 m³/h",
      wifi: "12/100",
    },
    {
      id: "3ac68afc-c605-48d3-a4f8-fbd91aa97f63",
      title: "Last Irrigation",
      level: "12568 m³/h",
      wifi: "12/100",
    },
    {
      id: "58694a0f-3da1-471f-bd96-145571e29d72",
      title: "Last Connection",
      level: "12568 m³/h",
      wifi: "12/100",
    },
    {
      id: "bd7acbea-c1b1-46c2-aed5-3ad53abb28ba",
      title: "fourth Item",
      level: "12568 m³/h",
      wifi: "12/100",
    },
    {
      id: "3ac68afc-c605-48d3-a4f8-fbd91aa97f63",
      title: "fifth Item",
      level: "12568 m³/h",
      wifi: "12/100",
    },
    {
      id: "58694a0f-3da1-471f-bd96-145571e29d72",
      title: "sixth Item",
      level: "12568 m³/h",
      wifi: "12/100",
    },
  ];

  useFocusEffect(
    React.useCallback(() => {
      getAdminData();
      // searchFilterFunction("");
      // console.log("projectName gridview", projectName);
    }, [projectName])
  );

  const getAdminData = async () => {
    let jsonValue = await AsyncStorage.getItem("userData");
    let proCodeId = await AsyncStorage.getItem("projectCode");
    let proName = await AsyncStorage.getItem("projectName");

    setProjectName(JSON.parse(proName));
    let proCode = JSON.parse(proCodeId);
    // setProjectCode(proCode);
    // console.log("proCodeId:&& proName", proCode, proName);
    let projValue = await AsyncStorage.getItem("projectData");

    // console.log("jsonValue & projValue:", jsonValue, projValue, proCodeId);
    if (projValue != null) {
      // console.log("project id select in zone create", projValue, proCodeId);
      setProjectId(projValue);
      getZoneGridMonitor(projValue);
    } else if (jsonValue != null) {
      var userDetailsresponse = JSON.parse(jsonValue);
      // console.log("userDetailsresponse in zone ==>", userDetailsresponse);
      // setloginId(userDetailsresponse.email_id);
      // setProjectId("cl86ts8rj217140xp5138s7iqm")

      setProjectId(userDetailsresponse.project_selected);
      // setEmpCode(userDetailsresponse.employee_code);
      getZoneGridMonitor(userDetailsresponse.project_selected);
    }
  };

  const screenwidth = Dimensions.get('window').width
  const isMobile = screenwidth < 768;

  //Get details when click on card id
  const getZoneGridMonitor = async (proId) => {
    // console.log("proId===", proId);
    setIsLoadingSync(true);
    try {
      var OmegaDetails = JSON.stringify({
        // omega_id: "clahuvov851900yu4aikt9sxv",
        project_id: proId,
        zone_id: Zoneid,
        sub_zone_id: SubzoneId,
      });
      // console.log("subZoneMonitorGridView payload", OmegaDetails);
      const zoneMonitorGridViewResponse = await postData(
        OmegaDetails,
        "dashboards/subSubZoneMonitoringData"
      );
      // console.log("====================================");
      // console.log("subZoneMonitorGridView", zoneMonitorGridViewResponse);
      // console.log("====================================");
      setIsLoadingSync(false);
      var code = zoneMonitorGridViewResponse.code;
      if (code == 1) {
        console.log("zoneMonitorGridViewResponse.data,", zoneMonitorGridViewResponse.data);

        setZoneGridDetails(zoneMonitorGridViewResponse.data);
      } else {
        setIsLoadingSync(false);
        //alert(zoneMonitorGridViewResponse.message);
      }
    } catch (error) {
      setIsLoadingSync(false);
      // console.log(error);
    }
  };

  const renderItem = ({ item, index }) => {
    console.log("item.based_on--------------------?", item);
    return (
      // <View style={{ flexDirection: "column" }}>
      <View style={isMobile ? {
        width: "54%",
        marginVertical: 5,
        padding: 10,
        borderRadius: 10,
        marginLeft: -5
      } : [styles.card]}>
        <View
          style={[
            styles.shadowProp,
            {
              backgroundColor: "#009df01f",
            },
          ]}
        >
          <View style={{ flexDirection: "row" }}>
            <View style={{ flex: 1, margin: 10 }}>
              <Image
                style={{
                  height: 35,
                  width: 25,
                  resizeMode: "contain",
                }}
                source={item.omega_id === null ? "" : require("../../assets/images/omega.png")}
              />
              {/* <Image style={{}} /> */}
            </View>
            <View style={{ flex: 1, margin: 10 }}>
              <Text style={{ fontSize: '20', fontWeight: '700' }}
                onPress={() => {
                  item.omega_id === null ? "" : (
                    navigation.navigate("ValveView", {
                      OmegaId: item.omega_id,
                      OmegaName: item.device_name,
                      basedOn: item.based_on,
                    }));
                }}
              >
                {item.sub_sub_zone_name}
              </Text>
            </View>
            <View style={{ flex: 1, alignItems: "flex-end", margin: 10 }}>
              <Icon name={item.omega_id === null ? "" : "wifi"} color={item.isonline == true ? "#228B22" : "#FF0000"} size={20} />
            </View>
          </View>
          {item.omega_id === null ?
            <View style={{ flexDirection: "column", padding: 5 }}>
              <View style={{ flexDirection: "row" }}>
                <Text
                  style={{
                    // backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                  }}
                >

                </Text>

                <Text
                  style={{
                    // backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                    justifyContent: "flex-end",
                  }}
                >

                  {/* {item.nextIrrigationTime} */}
                </Text>
              </View>

              <View style={{ flexDirection: "row" }}>
                <Text
                  style={{
                    // backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                  }}
                >

                </Text>

                <Text
                  style={{
                    // backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                    justifyContent: "flex-end",
                  }}
                >

                </Text>
              </View>

              <View style={{ flexDirection: "row" }}>
                <Text
                  style={{
                    // backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                  }}
                >

                </Text>

                <Text
                  style={{
                    // backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                    justifyContent: "flex-end",
                  }}
                >
                </Text>
              </View>
            </View> :
            <View style={{ flexDirection: "column", padding: 5 }}>
              <View style={{ flexDirection: "row" }}>
                <Text
                  style={isMobile ? {
                    backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                    fontSize: 12,
                  } : {
                    backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                  }}
                >
                  {item.project_type_id == 6 ? "Next water supply" : "Next irrigation"}
                </Text>

                <Text
                  style={{
                    backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                    justifyContent: "flex-end",
                  }}
                >
                {item.nextIrrigationTime}
                </Text>
              </View>

              <View style={{ flexDirection: "row" }}>
                <Text
                  style={isMobile ? {
                    backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                    fontSize: 12,
                  } : {
                    backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                  }}
                >
                  {item.project_type_id == 6 ? "Last water supply" : "Last irrigation"}
                </Text>

                <Text
                  style={isMobile ? {
                    backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    paddingTop: 11,
                    justifyContent: "flex-end",
                    fontSize: 12,
                    textAlign: "center"
                  } : {
                    backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                    justifyContent: "flex-end",
                  }}
                >
                  {item.lastIrrigationTime}
                </Text>
              </View>

              <View style={{ flexDirection: "row" }}>
                <Text
                  style={isMobile ? {
                    backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                    fontSize: 12,
                  } : {
                    backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                  }}
                >
                  Last Connection
                </Text>

                <Text
                  style={isMobile ? {
                    backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    paddingTop: 15,
                    justifyContent: "flex-end",
                  } : {
                    backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                    justifyContent: "flex-end",
                  }}
                >
                  {item.isonline == true ?
                    "Online" :
                    item.lastConnection}
                </Text>
              </View>
            </View>
          }

          <View style={{ flexDirection: "row" }}>
            {/* <View style={{ flex: 1, margin: 10 }}>
              <Icon name="cloud-download-alt" color="#000" size={20} />
            </View> */}
            <View style={{ flex: 3, margin: 10, maxWidth: 300 }}>
              <Text>
                {" "}
                {item.omega_id !== null &&
                  <Image
                    style={{
                      height: 17,
                      width: 25,
                      resizeMode: "contain",
                    }}
                    source={require("../../assets/images/valves.png")}
                  />
                }
                {item.valve_name == undefined ? "" : item.valve_name.join(", ")}              </Text>
            </View>
            <View style={{ flex: 1, alignItems: "flex-end", margin: 10 }}>
              {item.omega_id === null ? " " :
                <View style={isMobile ? { flexDirection: "row" } : { flexDirection: "row" }}>
                  <Text style={{ marginTop: -17 }}> {item.error}
                    {" "}
                  </Text>
                  <View style={isMobile ? null : { marginTop: -15 }}>
                    <IconMaterial name="bell-badge" color="red" size={20} />
                  </View>
                </View>

              }
            </View>
          </View>
          {/* <View style={{ flexDirection: "row", backgroundColor: "#D4E3EB" }}>
            <View style={{ flex: 1, margin: 10 }}>
              <Text>
                {" "}
                <Icon name="water" color="#009DF0" size={20} /> {item.level}
              </Text>
            </View>

            <View style={{ flex: 1, alignItems: "flex-end", margin: 10 }}>
              <Text>
                {" "}
                <Icon name="wifi" color="#009DF0" size={20} /> {item.wifi}
              </Text>
            </View>
          </View> */}
        </View>
      </View> // </View>
    );
  };

  return (
    <View style={styles.MainContainer}>
      <View style={{ flexDirection: "row", width: "100%", marginTop: "0%" }}>
        <View style={{ flex: 1, margin: 10 }}>
          <Text style={styles.lableText}>
            Monitor {">"}
            <Text onPress={() => navigation.navigate("GridView")}>
              Grid View
            </Text>
            {">"} {project_name} {">"} {ZoneName} {">"} {SubZoneName} {">"}{" "}
            {OmegaName}
          </Text>
        </View>
      </View>

      <View>
        {isLoadingSync ? (
          <ActivityIndicator
            //visibility of Overlay Loading Spinner
            color="#1DA1F2"
            visible={isLoadingSync}
            //Text with the Spinner
            textContent={"Loading..."}
            size={100}
          //  //Text style of the Spinner Text
          //  textStyle={styles.spinnerTextStyle}
          />
        ) : (
          <View
            style={{
              flexDirection: "row",
              marginTop: "0%",
              flexWrap: "wrap-reverse",
            }}
          >
            {/* <View style={{ flex: 1, margin: 10 }}>
                              <Text>Monitor {">"} Grid View {">"} {project_name} </Text>
                          </View> */}
            {zoneGridDetails.length > 0 ? (
              // zoneGridDetails.map((item, index) => {
              //   return renderItem(item, index);
              // })
              <FlatList
                numColumns={isMobile ? 2 : 4}
                // style={{ width: "75%" }}
                contentContainerStyle={{
                  padding: 5,
                }}
                data={zoneGridDetails}
                renderItem={renderItem}
              />
            )
              :
              <View style={{ justifyContent: "center" }}>
                <Text
                  style={[
                    styles.emptyListStyle,
                    { alignSelf: "center", marginTop: 20 },
                  ]}
                >
                  No Data Found{" "}
                </Text>
              </View>
            }

            {/* {zoneGridDetails.map((item, index) => {
              return renderItem(item, index);
            })} */}
          </View>
        )}
      </View>

      {/* <View
        style={{
          flexDirection: "row",
          marginTop: "0%",
          flexWrap: "wrap-reverse",
        }}
      >
        {zoneGridDetails.map((item, index) => {
          return renderItem(item, index);
        })}
      </View> */}
    </View>
  );
}

const styles = StyleSheet.create({
  MainContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    // alignItems: 'center',
    // justifyContent: 'flex-end',
    flex: 1,
    // alignItems: "center",
    // justifyContent: "center",
    padding: 20,
  },
  mapStyle: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  card: {
    width: "25%",
    // flex: 0.5,

    marginVertical: 10,
    padding: 10,
    // flexDirection: 'row'
    borderRadius: 10,
  },
  shadowProp: {
    shadowColor: "#171717",
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 5,
  },
  lableText: {
    color: "#484848",
    fontWeight: "700",
    // marginTop: 18,
    // fontSize: 21,
  },
  emptyListStyle: {
    padding: 10,
    fontSize: 20,
    textAlign: "center",
  },
});
