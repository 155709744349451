import Header from "../navigation/header";
import {
  StyleSheet,
  TouchableOpacity,
  Modal,
  Button,
  Switch,
  TextInput as TextInputRN,
  FlatList,
  Alert,
  ScrollView,
  SafeAreaView,
  Dimensions,
} from "react-native";
import { useFocusEffect } from "@react-navigation/native";

import { Text, View } from "../../../components/Themed";
import { RootStackScreenProps } from "../../../types"; // @ts-ignore
// import { DataTable } from "react-native-paper";
import Icon from "react-native-vector-icons/AntDesign";
import IconEdit from "react-native-vector-icons/FontAwesome";
import IconEye from "react-native-vector-icons/Entypo";
import React, { useEffect, useState, useMemo, useRef } from "react";
import IconCross from "react-native-vector-icons/Entypo";
import AsyncStorage from "@react-native-async-storage/async-storage";
import AwesomeAlert from "react-native-awesome-alerts";
import { TextInput } from "react-native-paper";
import { baseUrl, postData } from "../../../networking/api";
import { Provider as PaperProvider } from "react-native-paper";
import { Item } from "react-native-paper/lib/typescript/components/List/List";
import DataTable from "react-data-table-component";
import SuccessPopup from "../../../components/SuccessPopup";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

export default function Country({ navigation }) {
  const [isVisible, setIsVisible] = useState(false);
  const [issuccessVisible, setissuccessVisible] = useState(false);
  const [responsestatus, setresponsestatus] = useState("");
  const [PopupMessage, setPopupMessage] = useState("");
  const [isEnabled, setIsEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [countryIds, setCountryId] = useState();
  const [isAdd, setisAdd] = useState(false);
  var [counter, setCounter] = useState(1);
  const [sort, setsort] = useState(0);
  const [editpopup, setEditPopup] = useState(false);
  const [popid, setpopid] = useState("");
  const [popindex, setpopindex] = useState("");

  // const toggleSwitch = () => setIsActive(0);

  const [showAlert, setAlert] = useState(false);
  const [isCountryNameValid, setCountryNameDataValid] = useState(false);
  const [isCountryNameEntered, setCountryNameDataEntered] = useState(false);
  const [CountryName, setCountryNameData] = useState([]);
  const [CountryByName, setCountryByName] = useState("");
  const [error, setError] = useState("");
  const textCountryName = useRef("");
  const [countryLength, setCountryLength] = useState(0);
  const [searchData, setSearch] = useState("");
  const [filterData, setFilteredDataSource] = useState([]);

  //Pagination
  const optionsPerPage = [10, 25, 50, 100];

  const [page, setPage] = React.useState(0);
  const [itemsPerPage, setItemsPerPage] = React.useState(optionsPerPage[0]);
  const from = page * itemsPerPage;
  const to = Math.min((page + 1) * itemsPerPage, countryLength);
  React.useEffect(() => {
    setPage(0);
    setpopindex("");
    setEditPopup(false);
    setpopid("");
  }, [itemsPerPage]);
  const isCreatable = () => {
    setIsVisible(true);
    setisAdd(true);
  };
  const toggleSwitch = () => {
    var tempIndex = CountryName.findIndex((item) => item.id === popid);

    if (CountryName[tempIndex].active == 1) {
      CountryName[tempIndex].active = 0;
      activeCountry(popid, 0);
    } else {
      CountryName[tempIndex].active = 1;
      activeCountry(popid, 1);
    }
    setCountryNameData(CountryName);
  };
  const isAddable = (countryId, country_name) => {
    setIsVisible(true);
    setisAdd(false);
    // getCountryById(countryId);
    setCountryByName(country_name);
    // textCountryName.current = country_name;
    setCountryId(countryId);
  };

  // var alpha_pattern = new RegExp(/^[a-zA-Z]+(\s[a-zA-Z]+)?$/);
  var alpha_pattern = new RegExp(/^[a-zA-Z]+(\s[a-zA-Z]+)?$/);
  // var alpha_pattern = new RegExp(/^(?!\s*$).+/);

  useFocusEffect(
    React.useCallback(() => {
      getCountry([]);
      searchFilterFunction("");
    }, [])
  );

  const textToCountryName = (text) => {
    textCountryName.current = text.nativeEvent.text;
    // setCountryByName(text.nativeEvent.text);
  };

  const showCountryName = () => {
    return textCountryName.current;
  };
  const searchFilterFunction = (text) => {
    // Check if searched text is not blank
    if (text) {
      // Inserted text is not blank
      // Filter the masterDataSource
      // Update FilteredDataSource
      const newData = CountryName.filter(function (item) {
        const itemData = item.country
          ? item.country.toUpperCase()
          : "".toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      // console.log("new data search---->", newData);
      setFilteredDataSource(newData);
      setSearch(text);
      setPage(0);
    } else {
      // Inserted text is blank
      // Update FilteredDataSource with masterDataSource
      setFilteredDataSource(CountryName);
      setSearch(text);
      setPage(0);
    }
  };
  var counter = 1;
  const customStyles = {
    rows: {
      style: {
        // maxWidth: 50,
        minHeight: "30px", // override the row height
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        backgroundColor: "#319ef0a3",
        minHeight: "40px",
        fontSize: 16,
        fontWeight: "600",
      },
    },
    headCells: {
      style: {
        // paddingLeft: '8px', // override the cell padding for head cells
        // paddingRight: '8px',
        justifyContent: "center",
        minHeight: "20px",

        // borderRightWidth: 2,
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          // fontSize: 16,
          // fontWeight: '600',
          // borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
        justifyContent: "center",

        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          fontSize: 16,
          fontWeight: "400",
          textTransform: "Capitalize",

          // borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };
  const caseInsensitiveSort = (rowA, rowB) => {
    const a = rowA.country.toLowerCase();
    const b = rowB.country.toLowerCase();

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };

  // filterData.forEach((id, index) => { filterData.id = index + 1; });

  const columns = [
    {
      name: "Sr.No",
      selector: (row) => row.index,
      // //cell: (row, index) => index + 1,
      // sortable: true,
      width: "100px",
    },
    {
      name: "Country",
      selector: (row) => row.country,
      sortable: true,
      sortFunction: caseInsensitiveSort,
      // width: "100px",
    },
    {
      name: "Action",
      // selector: "country",
      width: "100px",
      // sortable: true
      cell: (item) => (
        <TouchableOpacity onPress={() => isAddable(item.id, item.country)}>
          <IconEdit
            name="pencil"
            size={13}
            color="#fff"
            style={{
              padding: 6,
              backgroundColor: "#08588E",
              borderRadius: 12,
            }}
          />
        </TouchableOpacity>
      ),
    },
    {
      name: "Status",
      width: "100px",
      cell: (item, index) => (
        <Switch
          trackColor={{ false: "#767577", true: "#1DA1F2" }}
          thumbColor={item.active == 1 ? "#fff" : "#f4f3f4"}
          activeThumbColor="#fff"
          onValueChange={() => {
            // console.log("toggle index", index);
            setEditPopup(true);
            setpopid(item.id);
            setpopindex(index);
          }}
          value={item.active == 1 ? true : false}
        />
      ),
    },
  ];

  const CheckEnteredData = async () => {
    var countryNames =
      showCountryName() !== "" ? showCountryName() : CountryByName;
    // console.log("showCountryName", showCountryName());

    // console.log("CountryByName", CountryByName);
    //setUserType(userTypes);
    if (countryNames == "") {
      // //alert("pls enter");
      setError("Please enter Country Name");
      // console.log("empty");
      // setIsVisible(false);
    } else if (alpha_pattern.test(countryNames)) {
      {
        isAdd == true && createCountry(countryNames);
        setIsVisible(false);
      }

      {
        isAdd == false && updateCountry(countryNames);
        setIsVisible(false);
      }
    } else {
      // console.log("falseśśś");
      setError("Please enter Character Only.");
      // //alert("only character:", countryNames);
      // setIsVisible(false);
    }

    // navigation.replace('DrawerNavigation');
  };

  //Country Add Api
  const createCountry = async (countryNames) => {
    setCountryNameDataEntered(true);

    // console.log("countryNames", countryNames);
    setIsLoading(true);
    try {
      var countryData = JSON.stringify({
        module: "country_master",
        data: {
          country: countryNames,
          active: 1,
        },
      });
      const countryNameResponse = await postData(
        countryData,
        "masters/masterCreateUpdate"
      );

      setIsLoading(false);

      var code = countryNameResponse.code;
      if (code == 1) {
        setPopupMessage("Record Created Successfully");
        setissuccessVisible(true);
        setresponsestatus("success");
        setIsVisible(false);
        getCountry([]);
        setCountryByName("");
        textCountryName.current = "";
        // console.log("Added", countryNameResponse.data);
      } else {
        setIsLoading(false);
        setissuccessVisible(true);
        setresponsestatus("failed");
        setPopupMessage(countryNameResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  const updateCountry = async (countryNames) => {
    setCountryNameDataEntered(true);

    // console.log("countryNames", countryNames);
    setIsLoading(true);
    try {
      var countryData = JSON.stringify({
        id: countryIds,
        module: "country_master",
        data: {
          country: countryNames,
          active: 1,
        },
      });
      const countryNameResponse = await postData(
        countryData,
        "masters/masterCreateUpdate"
      );

      setIsLoading(false);

      var code = countryNameResponse.code;
      if (code == 1) {
        setPopupMessage("Record Updated Successfully");
        setissuccessVisible(true);
        setresponsestatus("success");
        setIsVisible(false);
        getCountry([]);
        setCountryByName("");
        textCountryName.current = "";
        // console.log("Updated", countryNameResponse.data);
      } else {
        setIsLoading(false);
        setissuccessVisible(true);
        setresponsestatus("failed");
        setPopupMessage(countryNameResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  // Country Get ApiS
  const getCountry = async (sortdata: never[]) => {
    setIsLoading(true);
    if (sortdata.length > 0) {
      setCountryLength(sortdata.length);
      setCountryNameData(sortdata);
      setFilteredDataSource(sortdata);
    } else {
      try {
        var countryData = JSON.stringify({
          module: "country_master",
          relation: [],
        });
        const countryNameResponse = await postData(
          countryData,
          "masters/getMasterList"
        );
        setIsLoading(false);
        var code = countryNameResponse.code;
        if (code == 1) {
          // console.log("list====>", countryNameResponse.data);
          setCountryLength(countryNameResponse.data.length);
          setCountryNameData(countryNameResponse.data);
          setFilteredDataSource(countryNameResponse.data);
        } else {
          setIsLoading(false);
          //alert(countryNameResponse.message);
        }
      } catch (error) {
        setIsLoading(false);
        // console.log(error);
      }
    }
  };

  //isActive Country
  const activeCountry = async (countryId, isActive) => {
    setIsLoading(true);
    try {
      var countryData = JSON.stringify({
        id: countryId,
        module: "country_master",
        data: {
          active: isActive,
        },
      });
      const activeCountryResponse = await postData(
        countryData,
        "masters/masterCreateUpdate"
      );

      setIsLoading(false);

      var code = activeCountryResponse.code;
      if (code == 1) {
        //alert(activeCountryResponse.message);
        setIsVisible(false);
        // console.log("status changed", activeCountryResponse.data);
        getCountry([]);
      } else {
        setIsLoading(false);
        // //alert(activeCountryResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  const downloadExcelReport = async () => {

    const filteredData = CountryName.map(({ country, active }, index) => ({
      'Sr No': index + 1,
      'Country': country,
      'Active': active == 1 ? 'Active' : 'InActive',
    }));
    // Assuming you already have the filteredData as described in your code

    const worksheet = XLSX.utils.json_to_sheet(filteredData);

    // Modify the style of the top row (index 0) to have a background color
    const range = XLSX.utils.decode_range(worksheet['!ref']);

    // Iterate through the columns in the top row (index 0)
    for (let col = range.s.c; col <= range.e.c; col++) {
      const cellAddress = XLSX.utils.encode_cell({ r: 0, c: col });
      const cell = worksheet[cellAddress];

      // Create a new style object if the cell does not have one
      if (!cell.s) {
        cell.s = {};
      }

      // Apply the background color only to the top row
      cell.s.fill = {
        fgColor: { rgb: 'FFFF00' }, // 'FFFF00' represents yellow
      };
    }

    // Create a new workbook and add the modified worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Convert the workbook to an Excel buffer
    const excelBuffer = XLSX.write(workbook, {
      type: 'array',
      bookType: 'xlsx',
    });

    // Convert the buffer to a Blob and save the file
    const excelData = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(excelData, 'country_master.xlsx');

  }

  // const downloadExcelReport = async () => {
  //   const filteredData = CountryName.map(({ country, active }, index) => ({
  //     'Sr No': index + 1,
  //     'Country': country,
  //     'Active': active == 1 ? 'Active': 'InActive',
  //   }));
  //   // const filteredData = CountryName.map(({ createdAt, updatedAt, index, ...rest }) => rest);
  //   const worksheet = XLSX.utils.json_to_sheet(filteredData);
  //   const workbook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  //   const excelBuffer = XLSX.write(workbook, {
  //     type: 'array',
  //     bookType: 'xlsx',
  //   });
  //   const excelData = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  //   saveAs(excelData, 'coutry_master.xlsx');
  // }


  const screenwidth = Dimensions.get('window').width
  const isMobile = screenwidth < 768;

  return (
    <View style={styles.container}>
      <SuccessPopup
        visible={issuccessVisible}
        PopupMessage={PopupMessage}
        Responsestatus={responsestatus}
        onChange={() => setissuccessVisible(false)}
      />
      <Modal
        onRequestClose={() => setIsVisible(false)}
        transparent={true}
        visible={isVisible}
      >
        <View
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
            backgroundColor: "rgba(100,100,100, 0.5)",
          }}
        >
          <View style={isMobile ? {
            // alignItems: "center",
            backgroundColor: "white",
            borderColor: "#eee",
            borderRadius: 10,
            borderWidth: 1,
            // justifyContent: "center",
            height: "auto",
            margin: "auto",
            padding: 30,
            shadowOffset: {
              width: 0,
              height: 2,
            },
            shadowOpacity: 0.25,
            shadowRadius: 4,
            elevation: 5,
            // marginRight: 10,
            // marginRight: 1,
            width: "80%",
          } : [styles.containeralt]}>
            <TouchableOpacity
              style={{ alignSelf: "flex-end" }}
              onPress={() => setIsVisible(false)}
            >
              <IconCross
                name="circle-with-cross"
                size={20}
                color="#000"
                style={{ bottom: 5, top: 10 }}
              />
            </TouchableOpacity>

            <View style={{ width: "100%" }}>
              <Text style={{ fontWeight: "600" }}>Country Name</Text>
              {isAdd == true && (
                <TextInputRN
                  style={styles.input}
                  placeholder=""
                  // value={CountryByName}
                  autoFocus={true}
                  onChange={textToCountryName}
                />
              )}

              {isAdd == false && (
                <TextInputRN
                  style={styles.input}
                  placeholder=""
                  value={CountryByName}
                  key="Edit"
                  autoFocus
                  onChangeText={(text) => {
                    setCountryByName(text);
                  }}
                />
              )}
              <Text style={[styles.error_text]}>{error}</Text>
            </View>
            {isAdd == true && (
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  marginHorizontal: 20,
                }}
              >
                <TouchableOpacity
                  style={styles.saveBtn}
                  onPress={() => CheckEnteredData()}
                >
                  <Text style={styles.btnText}>Save</Text>
                </TouchableOpacity>

                <TouchableOpacity
                  style={styles.saveBtn}
                  onPress={() => {
                    setIsVisible(false), setError("");
                  }}
                >
                  <Text style={styles.btnText}>Cancel</Text>
                </TouchableOpacity>
              </View>
            )}
            {isAdd == false && (
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  marginHorizontal: 20,
                }}
              >
                <TouchableOpacity
                  style={styles.saveBtn}
                  onPress={() => CheckEnteredData()}
                >
                  <Text style={styles.btnText}>Update</Text>
                </TouchableOpacity>

                <TouchableOpacity
                  style={styles.saveBtn}
                  onPress={() => setIsVisible(false)}
                >
                  <Text style={styles.btnText}>Cancel</Text>
                </TouchableOpacity>
              </View>
            )}

            {/* <Gap />
{isVisible ? <Modalception depth={depth + 1} /> : null}
<Gap /> */}
          </View>
        </View>
      </Modal>
      <Modal
        visible={editpopup}
        animationType={"slide"}
        transparent={true}
        onRequestClose={() => {
          setEditPopup(false);
        }}
      >
        <View style={isMobile ? {
            // alignItems: "center",
            backgroundColor: "white",
            borderColor: "#eee",
            borderRadius: 10,
            borderWidth: 1,
            // justifyContent: "center",
            height: "auto",
            margin: "auto",
            padding: 30,
            shadowOffset: {
              width: 0,
              height: 2,
            },
            shadowOpacity: 0.25,
            shadowRadius: 4,
            elevation: 5,
            // marginRight: 10,
            // marginRight: 1,
            width: "80%",
          } : [styles.contanerSwitch]}>
          <TouchableOpacity
            style={{ alignSelf: "flex-end" }}
            onPress={() => setEditPopup(false)}
          >
            <IconCross
              name="circle-with-cross"
              size={20}
              color="#000"
              style={{ bottom: 5, top: 0 }}
            />
          </TouchableOpacity>

          <Text style={[styles.tableHead, { marginBottom: 20 }]}>
            Do You Want Update Status
          </Text>
          <View
            style={{
              flexDirection: "row",
              paddingRight: 10,
              justifyContent: "space-between",
            }}
          >
            <TouchableOpacity
              onPress={() => {
                setEditPopup(false), toggleSwitch();
              }}
              style={styles.saveBtn}
            >
              <Text style={{ color: "white" }}>Yes</Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                setEditPopup(false);
              }}
              style={styles.saveBtn}
            >
              <Text style={{ color: "white" }}>No</Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>

      <View style={isMobile ? { width: "100%", marginTop: 180 } : { flexDirection: "row", width: "100%", marginTop: "10%" }}>
        <View style={{ flex: 1, margin: 10 }}>
          <Text style={styles.lableText}>Master {">"} Country Master</Text>
        </View>
        <View style={isMobile ? { alignItems: "center", marginTop: 20  , marginLeft:'-35%', width:"170%", flex:2 } : { alignItems: "center", flex: 2 }}>
          <TextInput
            style={isMobile ? {
              height: 35,
              borderWidth: 2,
              paddingLeft: 10,
              borderColor: "#319ef0",
              backgroundColor: "#FFFFFF",
              width: "50%",
              borderRadius: 5,
              color: "#000",
              fontWeight: "400",
            } : styles.textInputStyle}
            onChangeText={(text) => searchFilterFunction(text)}
            value={searchData}
            underlineColorAndroid="transparent"
            underlineColor={"white"}
            placeholder="Search Country"
            theme={{
              colors: {
                text: "black",
                primary: "white",
                background: "transparent",
              },
            }}
            left={
              <TextInput.Icon
                name={() => <IconEdit name="search" size={20} color="#000" />} // where <Icon /> is any component from vector-icons or anything else
              />
            }
          />
        </View>
        <View style={{ flex: 1, alignItems: "flex-end" }}>
          <TouchableOpacity
            style={styles.addCountry}
            onPress={() => {
              isCreatable(), setError("");
            }}
          >
            <Text style={styles.btnText}>
              <Icon name="plus" size={15} color="#fff" /> Add Country
            </Text>
          </TouchableOpacity>
        </View>
      </View>


      <View style={isMobile ? { width: '100%', alignItems: "flex-end", marginTop:"-10%", marginLeft:"-120%" } : { width: '100%', alignItems: "flex-end" }}>
        <TouchableOpacity
          style={isMobile ? [styles.addCountry, { width: '45%',marginRight:-10 }] : [styles.addCountry, { width: '10%' }]}
          onPress={() => {
            downloadExcelReport();
          }}
        >
          <Text style={styles.btnText}>
            <Icon name="exclefile1" size={15} color="#fff" /> Download
          </Text>
        </TouchableOpacity>
      </View>

      <View style={isMobile ? [styles.card, { flexGrow: 1, marginTop:50, paddingBottom:10 }] : [styles.card, { flexGrow: 1 }]}>
        <View
          style={[
            styles.shadowProp,
            {
              backgroundColor: "white",
            },
          ]}
        >

          <View
            style={{
              width: "100%",
              // flex: 1,
              backgroundColor: "#F6F9F9",
            }}
          >
            <View
              style={isMobile ? {
                borderWidth: 1,
                borderColor: "#e3e0e0",
                width: "225%",
                alignSelf: "center",
              }: {
                borderWidth: 1,
                borderColor: "#e3e0e0",
              }}
            >
              <View>
                <DataTable
                  // title="Country"
                  columns={columns}
                  data={filterData}
                  customStyles={customStyles}
                  pagination
                  defaultSortFieldId={"country"}
                  // striped
                  defaultSortAsc={true}

                // defaultSortDsc={false}
                />
              </View>
              {/* prvider datatable code here */}

              {/* ended */}
            </View>
          </View>
        </View>
        {/* </ScrollView> */}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    // marginTop: 350,
    height: "100%",
  },
  itemStyle: {
    padding: 10,
  },
  textInputStyle: {
    height: 35,
    borderWidth: 2,
    paddingLeft: 20,
    borderRadius: 5,
    borderColor: "#319ef0",
    backgroundColor: "#FFFFFF",
    width: "40%",
    fontWeight: "400",
  },
  tableHead: {
    fontSize: 14,
    /* FontFamily: 'Muli' ,*/
    fontWeight: "700",
    color: "#000000",
  },

  containeralt: {
    // alignItems: "center",
    backgroundColor: "white",
    borderColor: "#eee",
    borderRadius: 10,
    borderWidth: 1,
    // justifyContent: "center",
    height: "auto",
    margin: "auto",
    padding: 30,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    // marginRight: 10,
    // marginRight: 1,
    width: "30%",
  },
  contanerSwitch: {
    backgroundColor: "white",
    borderColor: "#eee",
    borderRadius: 5,
    alignSelf: "center",
    borderWidth: 1,
    // justifyContent: "center",
    height: "25%",
    margin: "auto",
    padding: 10,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    // marginRight: 10,
    // marginRight: 1,
    width: "30%",
  },
  tableHeader: {
    color: "#000",
    fontSize: 14,
    /* FontFamily: 'Muli' ,*/
    fontWeight: "400",
  },
  input: {
    borderColor: "gray",
    width: "100%",
    borderWidth: 1,
    borderRadius: 2,

    padding: 10,
    marginTop: 20,
    marginBottom: 20,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  addCountry: {
    alignItems: "center",
    backgroundColor: "#006EB9",
    padding: 10,
    borderWidth: 1,
    borderColor: "#006EB9",
    width: "50%",
    // marginTop: 20,
  },
  saveBtn: {
    alignItems: "center",
    backgroundColor: "#006EB9",
    padding: 10,
    borderWidth: 1,
    borderColor: "#006EB9",
    width: "50%",
    marginRight: 10,
  },
  btnText: {
    color: "#fff",
    fontWeight: "600",
  },
  card: {
    width: "45%",
    flex: 1,
    marginVertical: 10,
  },
  shadowProp: {
    shadowColor: "#171717",
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 15,
  },
  // Error message css
  error_text: {
    color: "red",
    fontSize: 14,
    fontWeight: "600",
    width: "70%",
    marginLeft: 0,
    marginBottom: 10,
  },
  lableText: {
    color: "#484848",
    fontWeight: "700",
    marginTop: 18,
    // fontSize: 21,
  },
  link: {
    marginTop: 15,
    paddingVertical: 15,
  },
  linkText: {
    fontSize: 14,
    color: "#2e78b7",
  },
});
