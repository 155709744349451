const projectIdUpdate = (state = true, action) => {

    switch (action.type) {

        case "IDUPDATE":

            return !state

        default:

            return state

    }

}

export default projectIdUpdate