import React, { Component, useEffect, useState } from "react";
import {
  StyleSheet,
  View,
  Image,
  Text,
  Modal,
  TouchableOpacity,
} from "react-native";
import IconCross from "react-native-vector-icons/Entypo";
// import DocumentPicker from 'react-native-document-picker';
import * as DocumentPicker from "expo-document-picker";
import { useFocusEffect } from "@react-navigation/native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { baseUrl, postData } from "../../networking/api";
import SuccessPopup from "../../components/SuccessPopup";

export default function UploadSchedule({ navigation }) {
  const [isVisible, setIsVisible] = useState(true);
  const [Documents, setDocuments] = React.useState("");
  const [name, setName] = React.useState("");
  const [fileType, setType] = React.useState("");
  const [singleFile, setSingleFile] = useState({});
  const [projectId, setProjectId] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [issuccessVisible, setissuccessVisible] = useState(false);
  const [responsestatus, setresponsestatus] = useState("");
  const [PopupMessage, setPopupMessage] = useState("");
  const [filedata, setFiledata] = useState({ file: [] });
  // const _pickDocument = async () => {

  //     // console.log("inside upload");

  //     try {
  //         let result = await DocumentPicker.pickSingle({
  //             type: [
  //                 DocumentPicker.types.audio,
  //                 DocumentPicker.types.pdf,
  //                 DocumentPicker.types.doc,
  //                 DocumentPicker.types.docx,
  //                 DocumentPicker.types.ppt,
  //                 DocumentPicker.types.pptx,
  //                 DocumentPicker.types.xls,
  //                 DocumentPicker.types.xlsx,
  //             ],
  //         });
  //         if (result) {
  //             setDocuments(result.uri);
  //             setName(result.name);
  //             setType(result.type);
  //         }
  //     } catch (err) {
  //         // console.log(err);
  //     }
  // };

  useFocusEffect(
    React.useCallback(() => {
      getAdminData();
    }, [projectId])
  );
  const getAdminData = async () => {
    let jsonValue = await AsyncStorage.getItem("userData");
    // console.log("jsonValue:", jsonValue);
    let projValue = await AsyncStorage.getItem("projectData");

    // console.log("jsonValue & projValue:", jsonValue, projValue);
    if (projValue != null) {
      // console.log("project id select in schedule ", projValue);
      setProjectId(projValue);

      // getOmegaDetails(projValue, '')
    } else if (jsonValue != null) {
      var userDetailsresponse = JSON.parse(jsonValue);
      // console.log("userDetailsresponse in zone ==>", userDetailsresponse);
      setProjectId(userDetailsresponse.project_selected);
    }
  };

  //   upload  Add Api
  const uploadImage = async () => {
    // console.log("singleFile==file==------", singleFile);

    if (singleFile != null) {
      // If file selected then create FormData
      //   const fileToUpload = singleFile;

      const data = new FormData();
      // data.append("file", singleFile.uri);
      data.append("file", singleFile.file);

      //// console.log("singleFile==== formdata", JSON.stringify(data));

      //   data.append("file_attachment", fileToUpload);
      try {
        // Please change file upload URL
        let res = await fetch(baseUrl + "reports/importProgram/" + projectId, {
          method: "POST",
          body: data,
          // headers: {
          //   // Accept: "application/json",
          //   "Content-Type": "multipart/form-data",
          //   // "Content-Type": "multipart/form-data;boundary=myBoundary",
          // },
        });
        let responseJson = await res.json();
        // console.log("====================================");
        // console.log("responseJson====>,", responseJson);
        // console.log("====================================");
        if (responseJson.code == 1) {
          setPopupMessage("File Uploaded Successfully");
          setissuccessVisible(true);
          setresponsestatus("success");
          setIsVisible(false);
          // //alert("Upload Successful");
        } else {
          setIsLoading(false);
          setIsVisible(false);
          setissuccessVisible(true);
          setresponsestatus("failed");
          setPopupMessage(responseJson.message);
        }
      } catch (error) {
        // console.log("error", error);
      }
    } else {
      // If no file selected the show alert
      //alert("Please Select File first");
    }
  };

  const createUploadProgram = async () => {
    setIsLoading(true);
    try {
      const uploadProgResponse = await postData(
        "",
        "reports/importProgram/" + projectId
      );

      setIsLoading(false);

      var code = uploadProgResponse.code;
      if (code == 1) {
        setPopupMessage("File Uploaded Successfully");
        setissuccessVisible(true);
        setresponsestatus("success");
        setIsVisible(false);
        // setIsVisible(false);
        // console.log("Zone profile added", uploadProgResponse.message);
        // navigation.navigate("ZoneProfile");
      } else {
        setIsLoading(false);
        setIsVisible(false);
        setissuccessVisible(true);
        setresponsestatus("failed");
        setPopupMessage(uploadProgResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  const pickDocument = async () => {
    let result = await DocumentPicker.getDocumentAsync({ type: "*/*" });
    // console.log("pick document ", result.uri);
    // console.log("pick result ", result);
    // type: [DocumentPicker.types.allFiles],
    setSingleFile(result);
    setName(result.name);
    // setFiledata(...filedata,result)
    setFiledata((filedata) => ({
      ...filedata,
      file: result.file,
    }));
  };

  useEffect(() => {
    // console.log("filedata===>", filedata);
  }, [filedata]);

  return (
    <View style={styles.MainContainer}>
      <SuccessPopup
        visible={issuccessVisible}
        PopupMessage={PopupMessage}
        Responsestatus={responsestatus}
        onChange={() => setissuccessVisible(false)}
      />
      <Modal
        onRequestClose={() => setIsVisible(false)}
        transparent={true}
        visible={isVisible}
      >
        <View
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
            backgroundColor: "rgba(100,100,100, 0.5)",
          }}
        >
          <View style={[styles.containeralt]}>
            <TouchableOpacity
              style={{ alignSelf: "flex-end" }}
              onPress={() => setIsVisible(false)}
            >
              <IconCross
                name="circle-with-cross"
                size={20}
                color="#000"
                style={{ top: 0 }}
              />
            </TouchableOpacity>
            <Image
              source={require("../../assets/images/documentImage.jpeg")}
              style={{
                height: 44,
                width: 43,
                alignSelf: "center",
                margin: 5,
              }}
            />
            <TouchableOpacity
              onPress={() => pickDocument()}
              style={{
                alignSelf: "center",
                padding: 10,
                marginTop: 10,
                backgroundColor: "#D6D6D6",
              }}
            >
              <Text style={{ color: "#333333", fontSize: 16 }}>
                Upload File
              </Text>
            </TouchableOpacity>
            <Text style={{ color: "#000", marginTop: 3, textAlign: "center" }}>
              {name}
            </Text>
            <Text
              style={{
                alignSelf: "center",
                fontSize: 12,
                textAlign: "center",
                marginVertical: 25,
              }}
            >
              {/* Please upload a valid  file. Size of image should not be more
              than 2MB */}
            </Text>
            {/* <Text style={styles.textStyle}>
                            File Name: {singleFile.name ? singleFile.name : ''}
                            {'\n'}
                            Type: {singleFile.type ? singleFile.type : ''}
                            {'\n'}
                            File Size: {singleFile.size ? singleFile.size : ''}
                            {'\n'}
                            URI: {singleFile.uri ? singleFile.uri : ''}
                            {'\n'}
                        </Text> */}
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-evenly",
                marginHorizontal: 20,
              }}
            >
              <TouchableOpacity
                style={styles.saveBtn}
                onPress={() => uploadImage()}
              >
                <Text style={styles.btnText}>Import</Text>
              </TouchableOpacity>

              <TouchableOpacity
                style={styles.saveBtn}
                onPress={() => {
                  setIsVisible(false);
                }}
              >
                <Text style={styles.btnText}>Cancel</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
}

const styles = StyleSheet.create({
  MainContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    flex: 1,
    padding: 20,
  },
  saveBtn: {
    alignItems: "center",
    backgroundColor: "#006EB9",
    padding: 10,
    borderWidth: 1,
    borderColor: "#006EB9",
    width: "50%",
    marginRight: 10,
  },
  btnText: {
    color: "#fff",
    fontWeight: "600",
  },
  textStyle: {
    backgroundColor: "#fff",
    fontSize: 15,
    marginTop: 16,
    color: "black",
  },
  containeralt: {
    backgroundColor: "white",
    borderColor: "#eee",
    borderRadius: 10,
    borderWidth: 1,
    height: 300,
    margin: "auto",
    padding: 10,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: "30%",
  },
});
