import Header from "../navigation/header";
import {
  StyleSheet,
  TouchableOpacity,
  TextInput as TextInputRN,
  Modal,
  ScrollView,
  Button,
  Switch,
  Image,
} from "react-native-web";

import { Dropdown } from "react-native-element-dropdown";
import { Text, View } from "../../../components/Themed";
import { RootStackScreenProps } from "../../../types"; // @ts-ignore
import Icon from "react-native-vector-icons/AntDesign";
import React, { useEffect, useState, useMemo, useRef } from "react";
import IconCross from "react-native-vector-icons/Entypo";
import { postData } from "../../../networking/api";
// import { DataTable } from "react-native-paper";
import DataTable from "react-data-table-component";

import { Provider as PaperProvider } from "react-native-paper";
import IconEdit from "react-native-vector-icons/FontAwesome";
import IconEye from "react-native-vector-icons/Entypo";
import { useFocusEffect } from "@react-navigation/native";
import ImgToBase64 from "react-native-image-base64";
import { TextInput } from "react-native-paper";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { Dimensions } from "react-native";

export default function UserMasters({ navigation }) {
  const [text, setText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [UserMasterLength, setUserMasterLength] = useState(0);
  const [LastId, setLastId] = useState(0);

  const [userMasterData, setUserMasterData] = useState([]);
  const [searchData, setSearch] = useState("");
  const [sort, setsort] = useState(0);
  const [filterData, setFilteredDataSource] = useState([]);
  var counter = 1;

  //Pagination
  const optionsPerPage = [10, 25, 50, 100];

  const [page, setPage] = React.useState(0);
  const [itemsPerPage, setItemsPerPage] = React.useState(optionsPerPage[0]);
  const [editpopup, setEditPopup] = useState(false);
  const [popid, setpopid] = useState("");
  const [popindex, setpopindex] = useState("");
  const from = page * itemsPerPage;
  const to = Math.min((page + 1) * itemsPerPage, UserMasterLength);
  React.useEffect(() => {
    setPage(0);
    setpopindex("");
    setEditPopup(false);
    setpopid("");
  }, [itemsPerPage]);

  useFocusEffect(
    React.useCallback(() => {
      getUserMaster([]);
    }, [])
  );

  // DATATABLE PROJECT TYPE COLUMN AND STYLE
  const customStyles = {
    rows: {
      style: {
        // maxWidth: 50,
        minHeight: "30px", // override the row height
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        backgroundColor: "#319ef0a3",
        minHeight: "40px",
        fontSize: 16,
        fontWeight: "600",
      },
    },
    headCells: {
      style: {
        // paddingLeft: '8px', // override the cell padding for head cells
        // paddingRight: '8px',
        justifyContent: "center",
        minHeight: "20px",

        // borderRightWidth: 2,
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          // fontSize: 16,
          // fontWeight: '600',
          // borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
        justifyContent: "center",

        "&:not(:last-of-type)": {
          // textTransform: 'Capitalize',
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          fontSize: 16,
          fontWeight: "400",
          // borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };

  const caseInsensitiveSortFirstName = (rowA, rowB) => {
    const a = rowA.first_name.toLowerCase();
    const b = rowB.first_name.toLowerCase();

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };
  const caseInsensitiveSortLastName = (rowA, rowB) => {
    const a = rowA.last_name.toLowerCase();
    const b = rowB.last_name.toLowerCase();

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };
  const columns = [
    {
      name: "Sr.No",
      selector: (row) => row.index,
      //cell: (row, index) => index + 1,

      // sortable: true,
      width: "100px",
    },
    {
      name: "User Id",
      selector: (row) => row.employee_code,
      sortable: true,
      sortFunction: caseInsensitiveSortFirstName,
      style: { textTransform: "Capitalize" },
    },
    {
      name: "First Name",
      selector: (row) => row.first_name,
      sortable: true,
      sortFunction: caseInsensitiveSortFirstName,
      style: { textTransform: "Capitalize" },
    },
    {
      name: "Last Name",
      selector: (row) => row.last_name,
      sortable: true,
      // sortFunction: caseInsensitiveSortLastName,
      style: { textTransform: "Capitalize" },
    },
    {
      name: "Email",
      selector: (row) => row.email_id,
      sortable: true,
      // style: { textTransform: 'Lowercase', color: 'orange' },
    },
    {
      name: "Mobile",
      selector: (row) => row.mobile_number,
      sortable: true,
    },
    {
      name: "Action",
      // selector: "country",
      width: "100px",

      // sortable: true
      cell: (item) => (
        <TouchableOpacity
          onPress={() => {
            // console.log("item---edit", item);
            navigation.navigate("EditUserMaster", { userId: item.id });
          }}
        >
          <IconEdit
            name="pencil"
            size={13}
            color="#fff"
            style={{
              padding: 6,
              backgroundColor: "#08588E",
              borderRadius: 12,
            }}
          />
        </TouchableOpacity>
      ),
    },
    {
      name: "Status",
      width: "100px",

      cell: (item, index) => (
        <Switch
          trackColor={{ false: "#767577", true: "#1DA1F2" }}
          thumbColor={item.active == 1 ? "#fff" : "#f4f3f4"}
          activeThumbColor="#fff"
          onValueChange={() => {
            // console.log("toggle index", index);
            setEditPopup(true);
            setpopid(item.id);
            setpopindex(index);
          }}
          value={item.active == 1 ? true : false}
        />
      ),
    },
  ];
  const toggleSwitch = () => {
    var tempIndex = userMasterData.findIndex((item) => item.id === popid);

    if (userMasterData[tempIndex].active == 1) {
      userMasterData[tempIndex].active = 0;
      activeUserMaster(popid, 0);
    } else {
      userMasterData[tempIndex].active = 1;
      activeUserMaster(popid, 1);
    }
    setUserMasterData(userMasterData);
  };

  //isActive User
  const activeUserMaster = async (cityId, isActive) => {
    setIsLoading(true);
    try {
      var countryData = JSON.stringify({
        id: cityId,
        module: "user_master",
        data: {
          active: isActive,
        },
      });
      const activeUserMasterResponse = await postData(
        countryData,
        "masters/masterCreateUpdate"
      );

      setIsLoading(false);

      var code = activeUserMasterResponse.code;
      if (code == 1) {
        // //alert(activeUserMasterResponse.message);
        // setIsVisible(false);

        getUserMaster([]);
        // console.log("status changed", activeUserMasterResponse.data);
      } else {
        setIsLoading(false);
        // //alert(activeUserMasterResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  const getUserMaster = async (sortdata: never[]) => {
    setIsLoading(true);
    if (sortdata.length > 0) {
      setUserMasterLength(sortdata.length);
      setUserMasterData(sortdata);
      setFilteredDataSource(sortdata);
    } else {
      try {
        var userMasterData = JSON.stringify({
          module: "user_master",
          relation: [],
        });
        // console.log("userMasterData", userMasterData);
        const userMasterResponse = await postData(
          userMasterData,
          "masters/getMasterList"
        );
        setIsLoading(false);
        var code = userMasterResponse.code;
        if (code == 1) {
          // console.log("user masterlist====>", userMasterResponse.data);
          setUserMasterLength(userMasterResponse.data.length);
          setUserMasterData(userMasterResponse.data);
          setFilteredDataSource(userMasterResponse.data);
          // var lastIdVal = userMasterResponse.data[userMasterResponse.data.length - 1]
          var lastIdVal = userMasterResponse.data[0].id;
          setLastId(lastIdVal);
          // console.log("userid---->", lastIdVal);
        } else {
          setIsLoading(false);
          //alert(userMasterResponse.message);
        }
      } catch (error) {
        setIsLoading(false);
        // console.log(error);
      }
    }
  };
  const searchFilterFunction = (text) => {
    // Check if searched text is not blank
    if (text) {
      // Inserted text is not blank
      // Filter the masterDataSource
      // Update FilteredDataSource
      const newData = userMasterData.filter(function (item) {
        const itemData = item.first_name
          ? item.first_name.toUpperCase()
          : "".toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      // console.log("new data search---->", newData);
      setFilteredDataSource(newData);
      setSearch(text);
      setPage(0);
    } else {
      // Inserted text is blank
      // Update FilteredDataSource with masterDataSource
      setFilteredDataSource(userMasterData);
      setSearch(text);
      setPage(0);
    }
  };

  const sortFun = () => {
    const sortData = filterData.sort((item, b) =>
      sort == 1
        ? item.mobile_number.localeCompare(b.mobile_number)
        : b.mobile_number.localeCompare(item.mobile_number)
    );
    getUserMaster(sortData);
    setFilteredDataSource(sortData);
  };


  const downloadExcelReport = async () => {

    const filteredData = userMasterData.map(({ employee_code, first_name, last_name, email_id, mobile_number, active }, index) => ({
      'Sr No': index + 1,
      'User Id': employee_code,
      'First Name': first_name,
      'Last Name': last_name,
      'Email': email_id,
      'Mobile': mobile_number,
      'Active': active == 1 ? 'Active' : 'InActive',
    }));
    // Create the worksheet with auto-fit column width
    const worksheet = createWorksheetWithAutoFit(filteredData);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, {
      type: 'array',
      bookType: 'xlsx',
    });
    const excelData = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(excelData, 'user_master.xlsx');
  }
  const screenwidth = Dimensions.get('window').width
  const isMobile = screenwidth < 768;
  return (
    <View style={styles.container}>
      <View style={isMobile ? { width: "100%", marginTop: 200 } : { flexDirection: "row", width: "100%", marginTop: "10%" }}>
        <View style={{ flex: 1, margin: 10 }}>
          <Text style={styles.lableText}>Master {">"} User Master</Text>
        </View>
        <Modal
          visible={editpopup}
          animationType={"slide"}
          transparent={true}
          onRequestClose={() => {
            setEditPopup(false);
          }}
        >
          <View style={isMobile ? {
            // alignItems: "center",
            backgroundColor: "white",
            borderColor: "#eee",
            borderRadius: 10,
            borderWidth: 1,
            // justifyContent: "center",
            height: "auto",
            margin: "auto",
            padding: 30,
            shadowOffset: {
              width: 0,
              height: 2,
            },
            shadowOpacity: 0.25,
            shadowRadius: 4,
            elevation: 5,
            // marginRight: 10,
            // marginRight: 1,
            width: "80%",
          } : [styles.contanerSwitch]}>
            <TouchableOpacity
              style={{ alignSelf: "flex-end" }}
              onPress={() => setEditPopup(false)}
            >
              <IconCross
                name="circle-with-cross"
                size={20}
                color="#000"
                style={{ bottom: 5, top: 0 }}
              />
            </TouchableOpacity>

            <Text style={[styles.tableHead, { marginBottom: 20 }]}>
              Do You Want Update Status
            </Text>
            <View
              style={{
                flexDirection: "row",
                paddingRight: 10,
                justifyContent: "space-between",
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  setEditPopup(false), toggleSwitch();
                }}
                style={styles.saveBtn}
              >
                <Text style={{ color: "white" }}>Yes</Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  setEditPopup(false);
                }}
                style={styles.saveBtn}
              >
                <Text style={{ color: "white" }}>No</Text>
              </TouchableOpacity>
            </View>
          </View>
        </Modal>
        <View style={isMobile ? { alignItems: "center", marginTop: 20  , marginLeft:'-35%', width:"170%", flex:2 } : { alignItems: "center", flex: 2 }}>
          {/* <TouchableOpacity onPress={() => exportCsvZoneData()}><Text>Download CSV</Text></TouchableOpacity> */}
          <TextInput
            style={isMobile ? {
              height: 35,
              borderWidth: 2,
              paddingLeft: 10,
              borderColor: "#319ef0",
              backgroundColor: "#FFFFFF",
              width: "50%",
              borderRadius: 5,
              color: "#000",
              fontWeight: "400",
            } : styles.textInputStyle}
            onChangeText={(text) => searchFilterFunction(text)}
            value={searchData}
            underlineColorAndroid="transparent"
            underlineColor={"white"}
            placeholder="Search Users"
            theme={{
              colors: {
                text: "black",
                primary: "white",
                background: "transparent",
              },
            }}
            left={
              <TextInput.Icon
                name={() => <IconEdit name="search" size={20} color="#000" />} // where <Icon /> is any component from vector-icons or anything else
              />
            }
          />
        </View>
        <View style={{ flex: 1, alignItems: "flex-end" }}>
          <TouchableOpacity
            style={styles.addDevice}
            onPress={() => {
              navigation.navigate("CreateUserMaster");
            }}
          >
            <Text style={styles.btnText}>
              <Icon name="plus" size={15} color="#fff" /> Add User
            </Text>
          </TouchableOpacity>
        </View>
      </View>
      <View style={{ flexDirection: "row", width: "100%" }}>
        <View style={{ flex: 1 }}>
          {/* <TouchableOpacity
            style={styles.addDevice}
            // onPress={() => isCreatable()}
          >
            <Text style={styles.btnText}>
              <Icon name="plus" size={15} color="#fff" /> Add Country
            </Text>
          </TouchableOpacity> */}
        </View>
      </View>

      <View style={isMobile ? { width: '100%', alignItems: "flex-end", marginTop:"-8%", marginLeft:"-120%" } : { width: '100%', alignItems: "flex-end", marginTop: 5 }}>
        <TouchableOpacity
          style={isMobile ? [styles.addCountry, { width: '45%',marginRight:-10 }] : [styles.addCountry, { width: '10%' }]}
          onPress={() => {
            downloadExcelReport();
          }}
        >
          <Text style={styles.btnText}>
            <Icon name="exclefile1" size={15} color="#fff" /> Download
          </Text>
        </TouchableOpacity>
      </View>
      {/* <ScrollView
        style={[styles.card, styles.shadowProp]}
        contentContainerStyle={{ flexGrow: 1 }}
      > */}
      <View style={isMobile ? [styles.card, { flexGrow: 1, marginTop:50, paddingBottom:10 }] : [styles.card, { flexGrow: 1 }]}>
        <View
          style={[
            styles.shadowProp,
            {
              backgroundColor: "white",
            },
          ]}
        >
          <View
            style={{
              width: "100%",
              // flex: 1,
              backgroundColor: "#F6F9F9",
            }}
          >
            <View
              style={isMobile ? {
                borderWidth: 1,
                borderColor: "#e3e0e0",
                width: "100%",
                alignSelf: "center",
              }: {
                borderWidth: 1,
                borderColor: "#e3e0e0",
              }}
            >
              <View>
                <DataTable
                  // title="Country"
                  columns={columns}
                  data={filterData}
                  customStyles={customStyles}
                  pagination
                />
              </View>
              {/* prvider datatable code here */}

              {/* ended */}
            </View>
          </View>
          {/* </ScrollView> */}
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
  },
  input: {
    borderColor: "gray",
    width: "60%",
    borderWidth: 1,
    borderRadius: 10,
    padding: 10,
  },
  saveBtn: {
    alignItems: "center",
    backgroundColor: "#006EB9",
    padding: 10,
    borderWidth: 1,
    borderColor: "#006EB9",
    width: "50%",
    marginRight: 10,
  },
  tableHeader: {
    color: "#000",
    fontSize: 14,
    /* FontFamily: 'Muli' ,*/
    fontWeight: "400",
  },
  btnText: {
    color: "#fff",
    fontWeight: "600",
  },
  tableHead: {
    fontSize: 14,
    /* FontFamily: 'Muli' ,*/
    fontWeight: "700",
    color: "#000000",
  },
  addDevice: {
    alignItems: "center",
    backgroundColor: "#006EB9",
    padding: 10,
    borderWidth: 1,
    borderColor: "#006EB9",
    width: "50%",
    // marginTop: 20,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  btnText: {
    color: "#fff",
    fontWeight: "600",
  },
  card: {
    width: "100%",
    flex: 1,
    marginVertical: 10,
  },
  contanerSwitch: {
    backgroundColor: "white",
    borderColor: "#eee",
    borderRadius: 5,
    alignSelf: "center",
    borderWidth: 1,
    // justifyContent: "center",
    height: "25%",
    margin: "auto",
    padding: 10,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    // marginRight: 10,
    // marginRight: 1,
    width: "30%",
  },
  shadowProp: {
    shadowColor: "#171717",
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 25,
  },
  link: {
    marginTop: 15,
    paddingVertical: 15,
  },
  linkText: {
    fontSize: 14,
    color: "#2e78b7",
  },
  textInputStyle: {
    height: 35,
    borderWidth: 2,
    paddingLeft: 10,
    borderRadius: 5,
    borderColor: "#319ef0",
    backgroundColor: "#FFFFFF",
    width: "30%",
    fontWeight: "400",
  },
  addCountry: {
    alignItems: "center",
    backgroundColor: "#006EB9",
    padding: 10,
    borderWidth: 1,
    borderColor: "#006EB9",
    width: "50%",
    // marginTop: 20,
  },
});
