import Header from "../navigation/header";
import {
  StyleSheet,
  TouchableOpacity,
  Modal,
  Image,
  View,
  Text
} from "react-native-web";
import React from "react";
import IconCross from "react-native-vector-icons/Entypo";
import { onChange } from "react-native-reanimated";

export default function SuccessPopup({ visible, PopupMessage, Responsestatus, onChange }) {

  return (
    <View style={styles.container}>
      <Modal
        visible={visible}
        // animationType={"slide"}
        transparent={true}
        onRequestClose={onChange}>
        <View
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
            backgroundColor: 'rgba(100,100,100, 0.5)'
          }}>
          <View style={[styles.contanerSwitch]}>
            <TouchableOpacity style={{ alignSelf: 'flex-end' }} onPress={onChange}>
              <IconCross
                name="circle-with-cross"
                size={20}
                color="#000"
                style={{ bottom: 5, top: 0 }}
              />
            </TouchableOpacity>
            <Image
              source={Responsestatus == "success" ? require('../assets/images/success.png') : require('../assets/images/failed.png')}
              style={{ width: 50, alignSelf: 'center', height: 50 }} />
            <Text style={{ marginBottom: 20, color: Responsestatus == "success" ? 'green' : 'red', alignSelf: 'center', fontSize: 40 }}>{Responsestatus == "success" ? "Success" : "Failed"}!</Text>
            <Text style={{ marginBottom: 20, alignSelf: 'center', fontSize: 20 }}>{PopupMessage}</Text>
          </View>
        </View>
      </Modal>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    // marginTop: 300,
  },
  containeralt: {
    // alignItems: "center",
    backgroundColor: "white",
    borderColor: "#eee",
    borderRadius: 10,
    borderWidth: 1,
    // justifyContent: "center",
    height: "auto",
    margin: "auto",
    padding: 30,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    // marginRight: 10,
    // marginRight: 1,
    width: "30%",
  },
  contanerSwitch: {
    backgroundColor: "white",
    borderColor: "#eee",
    borderRadius: 5,
    alignSelf: 'center',
    borderWidth: 1,
    // justifyContent: "center",
    height: "auto",
    margin: "auto",
    padding: 10,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    // marginRight: 10,
    // marginRight: 1,
    width: "30%",
  },
  input: {
    borderColor: "#c3c3c3",
    width: "100%",
    borderWidth: 1,
    borderRadius: 2,

    padding: 10,
    // marginTop: 20,
    marginBottom: 5,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  addCountry: {
    alignItems: "center",
    backgroundColor: "#006EB9",
    padding: 10,
    borderWidth: 1,
    borderColor: "#006EB9",
    width: "50%",
    // marginTop: 20,
  },
  saveBtn: {
    alignItems: "center",
    backgroundColor: "#006EB9",
    padding: 10,
    borderWidth: 1,
    borderColor: "#006EB9",
    width: "50%",
    marginRight: 10,
  },
  btnText: {
    color: "#fff",
    fontWeight: "600",
  },
  card: {
    width: "75%",
    flex: 1,
    marginVertical: 10,
  },
  shadowProp: {
    shadowColor: "#171717",
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 25,
  },

  link: {
    marginTop: 15,
    paddingVertical: 15,
  },
  linkText: {
    fontSize: 14,
    color: "#2e78b7",
  },
  textInputStyle: {
    outline: 'none',
    height: 35,
    borderWidth: 2,
    paddingLeft: 20,
    borderRadius: 5,
    borderColor: '#319ef0',
    backgroundColor: '#FFFFFF',
    width: '35%',
    fontWeight: '400',
  },
  tableHead: {
    fontSize: 14,
    /* FontFamily: 'Muli' ,*/
    fontWeight: '700',
    color: '#000000'
  },

  // DropDown CSS
  dropdown: {
    borderColor: "#c3c3c3",
    width: "100%",
    borderWidth: 1,
    borderRadius: 2,
    height: 40,
    outlineStyle: 'none',
    outline: 'none',
    padding: 10,
    marginTop: 5,
    marginBottom: 5,
    // borderRadius: 10,
    // padding: 12,
    // shadowColor: "#000",
    // shadowOffset: {
    //   width: 0,
    //   height: 1,
    // },
    // shadowOpacity: 0.2,
    // shadowRadius: 1.41,

    // elevation: 2,
  },
  icon: {
    marginRight: 5,
  },
  tableHeader: {
    color: '#000',
    fontSize: 14,
    /* FontFamily: 'Muli' ,*/
    fontWeight: '400',
  },
  item: {
    padding: 9,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  textItem: {
    flex: 1,
    fontSize: 16,
  },
  placeholderStyle: {
    fontSize: 16,
  },
  selectedTextStyle: {
    fontSize: 16,
  },
  iconStyle: {
    width: 20,
    height: 20,
  },
  inputSearchStyle: {
    height: 40,
    fontSize: 16,
    outlineStyle: 'none'
  },
  // Error message css
  error_text: {
    color: "red",
    fontSize: 14,
    fontWeight: "600",
    width: "70%",
    marginLeft: 0,
    marginBottom: 10,
  },
});
