import Header from "../navigation/header";
import {
  StyleSheet,
  TouchableOpacity,
  TextInput,
  Modal,
  ScrollView,
  Button,
  Alert,
  Image,
  ActivityIndicator,
  Switch,
  Dimensions,
} from "react-native";
import { RadioButton } from "react-native-paper";
import { CheckBox } from "react-native-elements";
import AsyncStorage from "@react-native-async-storage/async-storage";
import moment from "moment";
import { Base64 } from "js-base64";

import { DatePickerModal } from "react-native-paper-dates";

import { Dropdown } from "react-native-element-dropdown";

import { Text, View } from "../../../components/Themed";
import { RootStackScreenProps } from "../../../types"; // @ts-ignore
import DataTable, { COL_TYPES } from "react-native-datatable-component";
import Icon from "react-native-vector-icons/AntDesign";
import React, { useState, useRef, useEffect } from "react";
import IconCross from "react-native-vector-icons/Entypo";
import { baseUrl, baseUrlimage, postData } from "../../../networking/api";
import { useFocusEffect } from "@react-navigation/native";
import * as ImagePicker from "expo-image-picker";
import IconImage from "react-native-vector-icons/Entypo";
import SuccessPopup from "../../../components/SuccessPopup";

export default function EditUserMaster({ navigation, route }) {
  const { userId } = route?.params;
  // // console.log("UserData in edit screen-->", userId);
  const [isVisible, setIsVisible] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const [animating, setAnimating] = useState(true);
  const [isAdd, setisAdd] = useState(false);

  const [genderchecked, setGenderChecked] = useState(1);
  // const [isMarriedchecked, setMaritialChecked] = useState("");
  const [isstatuschecked, setStatusChecked] = useState(1);

  const [isLoading, setIsLoading] = useState(false);

  const [empCode, setEmpCode] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [imageData, setProfileImageData] = useState("");
  const [firstName, setFirstName] = useState("");
  const [isFirstNameValid, setFirstNameValid] = useState(true);
  const [isFirstNameEntered, setFirstNameEntered] = useState(false);

  const [LastName, setLastName] = useState("");
  const [isLastNameValid, setLastNameValid] = useState(true);
  const [isLastNameEntered, setLastNameEntered] = useState(false);

  const [issuccessVisible, setissuccessVisible] = useState(false);
  const [responsestatus, setresponsestatus] = useState("");
  const [PopupMessage, setPopupMessage] = useState("");

  const [EmailId, setEmailId] = useState("");
  const [isEmailIdValid, setEmailIdValid] = useState(true);
  const [isEmailIdEntered, setEmailIdEntered] = useState(false);

  const [MobileNumber, setMobileNumber] = useState("");
  const [isMobileNumberValid, setMobileNumberValid] = useState(true);
  const [isMobileNumberEntered, setMobileNumberEntered] = useState(false);

  const [UserTypeData, setUserTypeData] = useState([]);
  const [UserTypeValid, setUserTypeValid] = useState();
  const [UserTypeEntered, setUserTypeEntered] = useState(false);

  const [Gender, setGender] = useState("");
  const [isGenderValid, setGenderValid] = useState(true);
  const [isGenderEntered, setGenderEntered] = useState(false);

  const [Dob, setDob] = useState("");
  const [isDobValid, setDobValid] = useState(true);
  const [isDobEntered, setDobEntered] = useState(false);

  const [Doj, setDoj] = useState("");
  const [isDojValid, setDojValid] = useState(true);
  const [isDojEntered, setDojEntered] = useState(false);

  const [CountryNameData, setCountryNameData] = useState([]);
  const [isCountryNameDataValid, setCountryNameDataValid] = useState(true);
  const [isCountryNameDataEntered, setCountryNameDataEntered] = useState(false);

  const [StateNameData, setStateNameData] = useState([]);
  const [isStateNameDataValid, setStateNameDataValid] = useState(true);
  const [isStateNameDataEntered, setStateNameDataEntered] = useState(false);

  const [CityNameData, setCityNameData] = useState([]);
  const [isCityNameDataValid, setCityNameDataValid] = useState(true);
  const [isCityNameDataEntered, setCityNameDataEntered] = useState(false);

  const [TahasilNameData, setTahasilNameData] = useState([]);
  const [isTahasilNameDataValid, setTahasilNameDataValid] = useState(true);
  const [isTahasilNameDataEntered, setTahasilNameDataEntered] = useState(false);

  const [VillageNameData, setVillageNameData] = useState([]);
  const [isVillageNameDataValid, setVillageNameDataValid] = useState(true);
  const [isVillageNameDataEntered, setVillageNameDataEntered] = useState(false);

  const [PinCode, setPinCode] = useState("");
  const [isPinCodeValid, setPinCodeValid] = useState(true);
  const [isPinCodeEntered, setPinCodeEntered] = useState(false);

  const [Address, setAddress] = useState("");
  const [isAddressValid, setAddressValid] = useState(true);
  const [isAddressEntered, setAddressEntered] = useState(false);

  const [Qualification, setQualification] = useState("");
  const [isQualificationValid, setQualificationValid] = useState(true);
  const [isQualificationEntered, setQualificationEntered] = useState(false);

  const [loginId, setloginId] = useState("");
  const [isloginIdValid, setloginIdValid] = useState(true);
  const [isloginIdEntered, setloginIdEntered] = useState(false);

  const [Password, setPassword] = useState("");
  // var decodePassword = Base64.decode(Password)
  // // console.log("decoded password", decodePassword)
  const [isPasswordValid, setPasswordValid] = useState(true);
  const [isPasswordEntered, setPasswordEntered] = useState(false);

  const [MaritialStatus, setMaritialStatus] = useState("");
  const [isMaritialStatusValid, setMaritialStatusValid] = useState(true);
  const [isMaritialStatusEntered, setMaritialStatusEntered] = useState(false);

  const [Status, setStatus] = useState("");
  const [isStatusValid, setStatusValid] = useState(false);
  const [isStatusEntered, setStatusEntered] = useState(false);

  const [isFocus, setIsFocus] = useState(false);

  // Master Id's and names
  const [countryValue, setCountryValue] = useState();
  const [countryValId, setCountryValId] = useState();
  const [stateValue, setStateValue] = useState();
  const [stateValId, setStateValId] = useState();
  const [cityValue, setCityValue] = useState();
  const [cityValId, setCityValId] = useState();
  const [tahasilValue, setTahasilValue] = useState();
  const [tahasilValId, setTahasilValId] = useState();
  const [VillageValue, setVillageValue] = useState();
  const [VillageValId, setVillageValId] = useState();

  //Dropdown value pickup
  const [userTypeValue, setUserTypeValue] = useState("");

  //Error message
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailIdError, setEmailIdError] = useState("");
  const [mobileNumError, setMobileNumError] = useState("");
  const [countryError, seTCountryError] = useState("");
  const [usertypeError, seTUserTypeError] = useState("");
  const [stateError, setStateError] = useState("");
  const [cityError, setCityError] = useState("");
  const [tahasilError, setTahasilError] = useState("");
  const [villageError, setVillageError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const [pinError, setPinError] = useState("");
  const [qualification_Error, setQualificationError] = useState("");

  //DOb
  const [date, setDate] = React.useState<Date | undefined>(undefined);
  const [open, setOpen] = React.useState(false);

  //Dropdown
  const dropdownRef = useRef();
  const dropdownRefcountry = useRef();
  const dropdownRefState = useRef();
  const dropdownRefCity = useRef();
  const dropdownRefTahasil = useRef();
  const dropdownRefVillage = useRef();

  const closeMenu = () => {
    dropdownRef.current.close();
    dropdownRefcountry.current.close();
    dropdownRefState.current.close();
    dropdownRefCity.current.close();
    dropdownRefTahasil.current.close();
    dropdownRefVillage.current.close();
    //return calls;
    // // console.log("on scroll close in dropdown")
  };

  useEffect(() => {
    window.addEventListener("scroll", closeMenu);
    return () => window.removeEventListener("scroll", closeMenu);
  }, []);

  const regex = /^[0-9]*$/;

  const onDismissSingle = React.useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const onConfirmSingle = React.useCallback(
    (params) => {
      setOpen(false);
      setDate(params.date);
    },
    [setOpen, setDate]
  );

  //DOJ
  // const [joinDate, setJoinDate] = React.useState<Date | undefined>(undefined);
  const [joinDate, setJoinDate] = React.useState<Date | undefined>(undefined);

  const [joinOpen, setJoinOpen] = React.useState(false);

  const onDismissJoin = React.useCallback(() => {
    setOpen(false);
  }, [setJoinOpen]);

  const onConfirmJoin = React.useCallback(
    (params) => {
      setJoinOpen(false);
      setJoinDate(params.date);
    },
    [setJoinOpen, setJoinDate]
  );
  const radioButtonsData = [
    {
      id: "1",
      label: "Male",
      value: 1,
      color: "red",
      selected: true,
    },
    {
      id: "2",
      label: "Female",
      value: 2,
      color: "red",
      selected: false,
    },
  ];

  const toggleSwitch = () => setIsEnabled((previousState) => !previousState);

  //Regex
  var alpha_pattern = new RegExp(/^[.a-zA-Z]+(\s[.a-zA-Z]+)?$/);
  var Email_Id_pattern = new RegExp(
    /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w\w+)+$/
  );
  var password_pattern = new RegExp(
    /^[a-zA-Z0-9]+$/
    // /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&^_-]{8,}$/
  );
  var MobileNo_pattern = new RegExp(/^[0]?[789]\d{9}$/);
  var number_regex = new RegExp(/^[0-9]*$/);

  useFocusEffect(
    React.useCallback(() => {
      // getAdminData();
      getUserById();
      getUserTypeList();
      getCountry();
    }, [])
  );

  // const getAdminData = async () => {
  //   let jsonValue = await AsyncStorage.getItem("userData");
  //   // console.log("jsonValue:", jsonValue);
  //   if (jsonValue != null) {
  //     var userDetailsresponse = JSON.parse(jsonValue);
  //     // console.log("userDetailsresponse==>", userDetailsresponse);

  //     setloginId(userDetailsresponse.email_id);
  //     setEmpCode(userDetailsresponse.employee_code);
  //   }
  // };
  // Country Get Api
  const getCountry = async () => {
    setIsLoading(true);
    try {
      var countryData = JSON.stringify({
        module: "country_master",
        relation: [],
      });
      const countryNameResponse = await postData(
        countryData,
        "masters/getMasterList"
      );
      setIsLoading(false);
      var code = countryNameResponse.code;
      if (code == 1) {
        // console.log("Country list====>", countryNameResponse.data);
        // setCountryLength(countryNameResponse.data.length);
        setCountryNameData(countryNameResponse.data);
      } else {
        setIsLoading(false);
        //alert(countryNameResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };
  const pickImage = async () => {
    // No permissions request is necessary for launching the image library
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
      base64: true,
    });

    // console.log("result of imggg====", result.uri);
    setProfileImage(result.uri);

    if (!result.cancelled) {
      setProfileImage(result.uri);
    }
  };
  const renderUserTypeListItems = (item: any) => {
    return (
      <View style={styles.item}>
        {isAdd == true && <Text style={styles.textItem}>{item.user_type}</Text>}
        {isAdd == false && (
          <Text style={styles.textItem}>{item.user_type}</Text>
        )}
        {item.id === UserTypeValid && (
          <Icon style={styles.icon} color="black" name="Safety" size={20} />
        )}
      </View>
    );
  };
  const renderCountryList = (item: any) => {
    return (
      <View style={styles.item}>
        {isAdd == true && <Text style={styles.textItem}>{item.country}</Text>}
        {isAdd == false && <Text style={styles.textItem}>{item.country}</Text>}
        {item.id === countryValId && (
          <Icon style={styles.icon} color="black" name="Safety" size={20} />
        )}
      </View>
    );
  };
  const renderStateListItems = (item) => {
    // // console.log("State id item", item.id);
    return (
      <View style={styles.item}>
        {isAdd == true && <Text style={styles.textItem}>{item.state}</Text>}
        {isAdd == false && <Text style={styles.textItem}>{item.state}</Text>}
        {item.id === stateValId && (
          <Icon style={styles.icon} color="black" name="Safety" size={20} />
        )}
      </View>
    );
  };
  const renderCityListItems = (item) => {
    // console.log("city item name-", item.city);
    return (
      <View style={styles.item}>
        {isAdd == true && <Text style={styles.textItem}>{item.city}</Text>}
        {isAdd == false && <Text style={styles.textItem}>{item.city}</Text>}
        {item.id === cityValId && (
          <Icon style={styles.icon} color="black" name="Safety" size={20} />
        )}
      </View>
    );
  };
  const renderTahasilListItems = (item) => {
    // console.log("tahasil item name-", item.tahasil);
    return (
      <View style={styles.item}>
        {isAdd == true && (
          <Text style={styles.textItem}>{item.tahasil_name}</Text>
        )}
        {isAdd == false && (
          <Text style={styles.textItem}>{item.tahasil_name}</Text>
        )}
        {item.id === tahasilValId && (
          <Icon style={styles.icon} color="black" name="Safety" size={20} />
        )}
      </View>
    );
  };
  const renderVillageListItems = (item) => {
    // console.log("village item name-", item.tahasil);
    return (
      <View style={styles.item}>
        {isAdd == true && (
          <Text style={styles.textItem}>{item.village_name}</Text>
        )}
        {isAdd == false && (
          <Text style={styles.textItem}>{item.village_name}</Text>
        )}
        {item.id === VillageValId && (
          <Icon style={styles.icon} color="black" name="Safety" size={20} />
        )}
      </View>
    );
  };

  const CheckEnteredData = async () => {
    setFirstNameEntered(true);
    setLastNameEntered(true);
    setEmailIdEntered(true);
    setMobileNumberEntered(true);
    setPinCodeEntered(true);
    setQualificationEntered(true);
    // setFirstNameEntered(true);

    //setUserType(userTypes);
    if (
      isFirstNameValid &&
      isLastNameValid &&
      isEmailIdValid &&
      isMobileNumberValid &&
      isPinCodeValid &&
      isQualificationValid
    ) {
      // console.log("isFirstNameValid", firstName);
      // console.log("isLastNameValid", LastName);
      // console.log("isEmailIdValid", isEmailIdValid);
      // console.log("isMobileNumberValid", isMobileNumberValid);
      // console.log("isPinCodeValid", isPinCodeValid);
      // console.log("isQualificationValid", isQualificationValid);
      // console.log("success");
      updateUserMaster();

      setFirstNameError("");
      setLastNameError("");
      setEmailIdError("");
      setMobileNumError("");
      setPinError("");
      setQualificationError("");
    } else {
      // console.log("isFirstNameValid", firstName);
      // console.log("isLastNameValid", LastName);
      // console.log("isEmailIdValid", isEmailIdValid);
      // console.log("isMobileNumberValid", isMobileNumberValid);
      // console.log("isPinCodeValid", isPinCodeValid);
      // console.log("isQualificationValid", isQualificationValid);
      // console.log("error");

      if (firstName == "") {
        setFirstNameError("Please enter first name");
      }
      //Lastname  Validation
      if (LastName == "") {
        setLastNameError("Please enter last name");
      }
      //Email Validation
      if (EmailId == "") {
        setEmailIdError("Please enter email id");
      }

      //Mobile Validation
      if (MobileNumber == "") {
        setMobileNumError("Please enter mobile number");
      }
      //Password Validation
      if (Password == "") {
        setPasswordError("Please enter password ");
      }

      if (countryValue == "") {
        seTCountryError("Please select Country");
      }

      if (userTypeValue == "") {
        seTUserTypeError("Please select User Type");
      }
      //State
      if (stateValue == "") {
        setStateError("Please select State");
      }

      //City
      if (cityValue == "") {
        setCityError("Please select City");
      }

      //Tahasil
      if (tahasilValue == "") {
        setTahasilError("Please select Tahasil");
      }

      //Village
      if (VillageValue == "") {
        setVillageError("Please select Village");
      }

      //Pin Validation
      if (PinCode == "") {
        setPinError("Please enter pin ");
      }

      //Qualification Validation
      if (Qualification == "") {
        setQualificationError("Please enter qualification");
      }
    }
  };
  const getUserById = async () => {
    // console.log("getUserById=====");
    setIsLoading(true);
    try {
      var UserDatabyId = JSON.stringify({
        id: userId,
        module: "user_master",
      });
      const UserListById = await postData(
        UserDatabyId,
        "/masters/getMasterData"
      );
      setIsLoading(false);
      var code = UserListById.code;
      if (code == 1) {
        // console.log("User data list api====>", UserListById.data);

        // setUserTypeData(UserListById.data);
        var profileUrl =
          baseUrlimage +
          "/uploads/profile_picture/" +
          UserListById.data.profile_pic;
        setProfileImage(profileUrl);
        setEmpCode(UserListById.data.employee_code);
        setFirstName(UserListById.data.first_name);
        setLastName(UserListById.data.last_name);
        setEmailId(UserListById.data.email_id);
        setMobileNumber(UserListById.data.mobile_number);
        setUserTypeValue(UserListById.data.user_type);
        setGenderChecked(UserListById.data.gender_id);

        setDate(UserListById.data.dob);
        setCountryValue(UserListById.data.country_id);
        setStateValue(UserListById.data.state_id);
        setCityValue(UserListById.data.city_id);
        setTahasilValue(UserListById.data.tahasil_id);
        setVillageValue(UserListById.data.village_id);

        setPinCode(UserListById.data.pin);
        setQualification(UserListById.data.qualification);
        setAddress(UserListById.data.address);
        setJoinDate(UserListById.data.joining_date);
        setMaritialStatus(UserListById.data.marital_status);
        setPassword(UserListById.data.password);
        setStatusChecked(UserListById.data.active);

        getDependentCountryMaster(UserListById.data.country_id);
        getDependentStateMaster(UserListById.data.state_id);
        getDependentCityMaster(UserListById.data.city_id);
        getDependentTahasilMaster(UserListById.data.tahasil_id);
      } else {
        setIsLoading(false);
        //alert(UserListById.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  const getUserTypeList = async () => {
    setIsLoading(true);
    try {
      var UserTypeData = JSON.stringify({
        module: "user_type",
        relation: [],
      });
      const UserTypeList = await postData(
        UserTypeData,
        "masters/getMasterList"
      );
      setIsLoading(false);
      var code = UserTypeList.code;
      if (code == 1) {
        // console.log("User type list api====>", UserTypeList.data);

        setUserTypeData(UserTypeList.data);
      } else {
        setIsLoading(false);
        //alert(UserTypeList.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };
  // Get dependent state by country
  const getDependentCountryMaster = async (country_id) => {
    // console.log("country_id masters", country_id);
    setIsLoading(true);

    try {
      var dependentData = JSON.stringify({
        column: "country_id",
        value: country_id,
        module: "state_master",
      });
      const dependentCountryDataResponse = await postData(
        dependentData,
        "masters/getDependentMasterList"
      );
      setIsLoading(false);
      var code = dependentCountryDataResponse.code;
      if (code == 1) {

        setIsLoading(false);
        // setCountryLength(dependentCountryDataResponse.data.length);
        setStateNameData(dependentCountryDataResponse.data);
      } else {
        setIsLoading(false);
        //alert(dependentCountryDataResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  // Get dependent City by state
  const getDependentStateMaster = async (state_id) => {
    // console.log("state_id masters===", state_id);
    setIsLoading(true);
    try {
      var dependentData = JSON.stringify({
        column: "state_id",
        value: state_id,
        module: "city_master",
      });
      const dependentStateDataResponse = await postData(
        dependentData,
        "masters/getDependentMasterList"
      );
      setIsLoading(false);
      var code = dependentStateDataResponse.code;
      if (code == 1) {

        // setCountryLength(dependentStateDataResponse.data.length);
        setCityNameData(dependentStateDataResponse.data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        //alert(dependentStateDataResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  // Get dependent Tahasil by City
  const getDependentCityMaster = async (city_id) => {
    // console.log("city_id masters===", city_id);
    setIsLoading(true);
    try {
      var dependentData = JSON.stringify({
        column: "city_id",
        value: city_id,
        module: "tahasil_master",
      });
      const dependentCityDataResponse = await postData(
        dependentData,
        "masters/getDependentMasterList"
      );
      setIsLoading(false);
      var code = dependentCityDataResponse.code;
      if (code == 1) {

        // setCountryLength(dependentCityDataResponse.data.length);
        setTahasilNameData(dependentCityDataResponse.data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        //alert(dependentCityDataResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  // Get dependent village by Tahasil
  const getDependentTahasilMaster = async (tahasil_id) => {
    // console.log("tahasil_id masters===", tahasil_id);
    setIsLoading(true);
    try {
      var dependentData = JSON.stringify({
        column: "tahasil_id",
        value: tahasil_id,
        module: "village_master",
      });
      const dependentTahasilDataResponse = await postData(
        dependentData,
        "masters/getDependentMasterList"
      );
      setIsLoading(false);
      var code = dependentTahasilDataResponse.code;
      if (code == 1) {

        // setCountryLength(dependentTahasilDataResponse.data.length);
        setVillageNameData(dependentTahasilDataResponse.data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        //alert(dependentTahasilDataResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  //Usermaster update Api
  const updateUserMaster = async () => {
    setIsLoading(true);
    try {
      var userMasterData = JSON.stringify({
        module: "user_master",
        id: userId,
        data: {
          profile_pic: profileImage,
          user_type: UserTypeValid,
          gender_id: genderchecked,
          first_name: firstName,
          last_name: LastName,
          mobile_number: MobileNumber,
          email_id: EmailId,
          country_id: countryValId,
          state_id: stateValId,
          city_id: cityValId,
          tahasil_id: tahasilValId,
          village_id: VillageValId,
          address: Address,
          dob: date,
          joining_date: joinDate,
          username: EmailId,
          // password: Password,
          project_selected: "1",
          pin: PinCode,
          marital_status: MaritialStatus,

          qualification: Qualification,
          active: isstatuschecked,
        },
      });
      // console.log("User master payload update===", userMasterData);
      const userMasterCreateResponse = await postData(
        userMasterData,
        "masters/masterCreateUpdate"
      );

      setIsLoading(false);

      var code = userMasterCreateResponse.code;
      if (code == 1) {
        setPopupMessage("Record Updated Successfully");
        setissuccessVisible(true);
        setresponsestatus("success");
        setIsVisible(false);
        // console.log("user updated", userMasterCreateResponse.data);
        navigation.navigate("UserMaster");
      } else {
        setIsLoading(false);
        setissuccessVisible(true);
        setresponsestatus("failed");
        setPopupMessage(userMasterCreateResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };
  const screenwidth = Dimensions.get('window').width
  const isMobile = screenwidth < 768;
  return (
    <View style={styles.container}>
      <SuccessPopup
        visible={issuccessVisible}
        PopupMessage={PopupMessage}
        Responsestatus={responsestatus}
        onChange={() => setissuccessVisible(false)}
      />

      <View style={{ flexDirection: "row", width: "100%", marginTop: "5%" }}>
        <View style={{ flex: 4, margin: 10 }}>
          <Text style={styles.lableText}>
            Master {">"}
            <Text onPress={() => navigation.navigate("UserMasters")}>
              {" "}
              User Master{" "}
            </Text>{" "}
            {">"} Create User Master{">"}
          </Text>{" "}
        </View>
        {/* <View style={{ flex: 1 }}>
          <TouchableOpacity
            style={styles.addCountry}
            onPress={() => setIsVisible(true)}
          >
            <Text style={styles.btnText}>
              <Icon name="plus" size={15} color="#fff" /> Add Users
            </Text>
          </TouchableOpacity>
        </View> */}
      </View>
      {/* <ScrollView
        style={[styles.card, styles.shadowProp]}
        contentContainerStyle={{ flexGrow: 1 }}
      > */}
      <View style={[styles.card, { flexGrow: 1 }]}>
        <View
          style={[
            styles.shadowProp,
            {
              backgroundColor: "white",
              borderRadius: 8,
              paddingVertical: 45,
              paddingHorizontal: 25,
            },
          ]}
        >
          <View
            style={isMobile ? {
              // flex: 1,
              // justifyContent: "center",
              // alignItems: "center",
              // flexDirection: "row",
            } : {
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <View style={isMobile ? { flex: 1 } : { flex: 1, marginRight: 100 }}>
              <View style={isMobile ? { flex: 1, alignItems: "flex-start", marginTop: -30, marginLeft: "25%" } : { flex: 1, alignItems: "flex-start", padding: 10 }}>
                {/* <Button title='Pick an image from camera roll'   color="#000000" 
 onPress={pickImage} /> */}
                <TouchableOpacity
                  style={{
                    backgroundColor: profileImage ? "" : "grey",
                    padding: 10,
                  }}
                  onPress={pickImage}
                >
                  <Image
                    source={
                      profileImage
                        ? { uri: profileImage }
                        : require("../../../assets/images/profileAvatar.png")
                    }
                    style={{ width: 100, height: 100 }}
                  />
                </TouchableOpacity>
              </View>
              <View style={isMobile ? { marginTop: 70 } : null}>
                <Text style={styles.lableText}>User Id</Text>
                <TextInput
                  style={isMobile ? {
                    borderColor: "#c3c3c3",
                    width: "100%",
                    borderWidth: 1,
                    borderRadius: 4,
                    padding: 10,
                    marginTop: 4,
                    marginBottom: 15,
                    backgroundColor: "#DEDEDE"
                  } : [
                    styles.input,
                    { marginBottom: 15, backgroundColor: "#DEDEDE" },
                  ]}
                  // placeholder="Enter First name"
                  editable={false}
                  value={empCode}
                />
              </View>

              <Text style={styles.lableText}>First name</Text>
              <TextInput
                style={isMobile ? {
                  borderColor: "#c3c3c3",
                  width: "100%",
                  borderWidth: 1,
                  borderRadius: 4,
                  padding: 10,
                  marginTop: 4,
                  marginBottom: 6,
                } : styles.input}
                placeholder=""
                value={firstName}
                onChangeText={(value) => {
                  setFirstNameValid(false);
                  // console.log("text editied---", value);
                  setFirstName(value);
                  if (value.length == 0) {
                    setFirstNameError("Please enter first name");
                  } else if (!alpha_pattern.test(value)) {
                    setFirstNameError("Please enter character only");
                  } else {
                    setFirstNameError("");
                    setFirstNameValid(true);
                  }
                }}
              />
              <View>
                <Text style={[styles.error_text]}>{firstNameError}</Text>
              </View>

              <Text style={styles.lableText}>Last name</Text>
              <TextInput
                style={isMobile ? {
                  borderColor: "#c3c3c3",
                  width: "100%",
                  borderWidth: 1,
                  borderRadius: 4,
                  padding: 10,
                  marginTop: 4,
                  marginBottom: 6,
                } : styles.input}
                placeholder=""
                value={LastName}
                onChangeText={(value) => {
                  setLastNameValid(false);
                  setLastName(value);
                  if (value.length == 0) {
                    setLastNameError("Please enter last name");
                  } else if (!alpha_pattern.test(value)) {
                    setLastNameError("Please enter character only");
                  } else {
                    setLastNameError("");
                    setLastNameValid(true);
                  }
                }}
              />
              <View>
                <Text style={[styles.error_text]}>{lastNameError}</Text>
              </View>
              <Text style={styles.lableText}>Email Id</Text>
              <TextInput
                style={isMobile ? {
                  borderColor: "#c3c3c3",
                  width: "100%",
                  borderWidth: 1,
                  borderRadius: 4,
                  padding: 10,
                  marginTop: 4,
                  marginBottom: 6,
                } : styles.input}
                placeholder=""
                value={EmailId}
                onChangeText={(value) => {
                  setEmailIdValid(false);
                  setEmailId(value.replace(/\s/g, ""));
                  if (value.length == 0) {
                    setEmailIdError("Please enter email id");
                  } else if (!Email_Id_pattern.test(value)) {
                    setEmailIdError("Please enter valid email id  ");
                  } else {
                    setEmailIdError("");
                    setEmailIdValid(true);
                  }
                }}
              />
              <Text style={[styles.error_text]}>{emailIdError}</Text>

              <Text style={styles.lableText}>Mobile no.</Text>
              <TextInput
                style={isMobile ? {
                  borderColor: "#c3c3c3",
                  width: "100%",
                  borderWidth: 1,
                  borderRadius: 4,
                  padding: 10,
                  marginTop: 4,
                  marginBottom: 6,
                } : styles.input}
                placeholder=""
                maxLength={10}
                value={MobileNumber}
                onChangeText={(value) => {
                  setMobileNumberValid(false);
                  setMobileNumber(value);
                  if (value.length == 0) {
                    setMobileNumError("Please enter mobile number");
                  } else if (!regex.test(value)) {
                    setMobileNumError("Please enter number only");
                  } else if (value.length > 0 && value.length < 10) {
                    setMobileNumError("Please enter 10 digit number");
                  } else {
                    setMobileNumError("");
                    setMobileNumberValid(true);
                  }
                }}
              />
              <Text style={[styles.error_text]}>{mobileNumError}</Text>

              <Text style={styles.lableText}>User Type</Text>
              <View>
                <Dropdown
                  ref={dropdownRef}
                  style={isMobile ? {
                    borderColor: "#c3c3c3",
                    width: "100%",
                    borderWidth: 1,
                    borderRadius: 2,
                    height: 40,
                    outlineStyle: "none",
                    padding: 10,
                    marginTop: 5,
                    marginBottom: 5,
                  } : styles.dropdown}
                  placeholderStyle={styles.placeholderStyle}
                  selectedTextStyle={styles.selectedTextStyle}
                  inputSearchStyle={styles.inputSearchStyle}
                  iconStyle={styles.iconStyle}
                  data={UserTypeData}
                  search
                  maxHeight={300}
                  labelField="user_type"
                  valueField="id"
                  placeholder={!isFocus ? "Select User Type" : "...."}
                  searchPlaceholder="Search..."
                  value={userTypeValue}
                  onChange={(item) => {
                    setUserTypeValue(item.user_type);
                    setUserTypeValid(item.id);
                    seTUserTypeError("");
                    // console.log("user type value---", item.user_type);
                  }}
                  renderItem={renderUserTypeListItems}
                />
                <Text style={[styles.error_text]}>{usertypeError}</Text>
              </View>

              <Text style={styles.lableText}>Gender</Text>
              <View style={isMobile ? {flexDirection:"row", marginLeft:-15} : { flexDirection: "row" }}>
                <Text style={styles.genderNames}>Male</Text>
                <RadioButton
                  value={genderchecked}
                  status={genderchecked == 1 ? "checked" : "unchecked"}
                  onPress={() => setGenderChecked(1)}
                />
                <Text style={styles.genderNames}>Female</Text>
                <RadioButton
                  value={genderchecked}
                  status={genderchecked === 2 ? "checked" : "unchecked"}
                  onPress={() => setGenderChecked(2)}
                />
                <Text style={styles.genderNames}>Other</Text>
                <RadioButton
                  value={genderchecked}
                  status={genderchecked === 3 ? "checked" : "unchecked"}
                  onPress={() => setGenderChecked(3)}
                />
              </View>
              <View>
                <Text style={styles.lableText}>Date of Birth</Text>

                <Text style={isMobile ? {
                  borderColor: "#c3c3c3",
                  width: "100%",
                  borderWidth: 1,
                  borderRadius: 4,
                  padding: 10,
                  marginTop: 4,
                  marginBottom: 6,
                } : styles.input} onPress={() => setOpen(true)}>
                  {/* {userData.dob} */}
                  {moment(date).format("YYYY-MM-DD")}
                </Text>
                {/* <TouchableOpacity></TouchableOpacity> */}
                {/* <Button uppercase={false} mode="outlined">
                Pick single date
              </Button> */}
                <DatePickerModal
                  locale="en"
                  mode="single"
                  visible={open}
                  onDismiss={onDismissSingle}
                  date={date}
                  onConfirm={onConfirmSingle}
                  validRange={{
                    endDate: new Date(), // optional
                  }}
                // validRange={{
                //   startDate: new Date(2021, 1, 2),  // optional
                //   endDate: new Date(), // optional
                //   disabledDates: [new Date()] // optional
                // }}
                // onChange={} // same props as onConfirm but triggered without confirmed by user
                // saveLabel="Save" // optional
                // saveLabelDisabled={true} // optional, default is false
                // uppercase={false} // optional, default is true
                // label="Select date" // optional
                // animationType="slide" // optional, default is 'slide' on ios/android and 'none' on web
                // startYear={2000} // optional, default is 1800
                // endYear={2100} // optional, default is 2200
                // closeIcon="close" // optional, default is "close"
                // editIcon="pencil" // optional, default is "pencil"
                // calendarIcon="calendar" // optional, default is "calendar"
                />
              </View>

              <View>
                <Text style={styles.lableText}>Country</Text>
                <Dropdown
                  ref={dropdownRefcountry}
                  style={isMobile ? {
                    borderColor: "#c3c3c3",
                    width: "100%",
                    borderWidth: 1,
                    borderRadius: 2,
                    height: 40,
                    outlineStyle: "none",
                    padding: 10,
                    marginTop: 5,
                    marginBottom: 5,
                  } : styles.dropdown}
                  placeholderStyle={styles.placeholderStyle}
                  selectedTextStyle={styles.selectedTextStyle}
                  inputSearchStyle={styles.inputSearchStyle}
                  iconStyle={styles.iconStyle}
                  data={CountryNameData}
                  search
                  maxHeight={300}
                  labelField="country"
                  valueField="id"
                  placeholder={!isFocus ? "Select Country" : "...."}
                  searchPlaceholder="Search..."
                  value={countryValue}
                  onChange={(item) => {
                    setCountryValue(item.country);
                    setCountryValId(item.id);
                    seTCountryError("");
                    getDependentCountryMaster(item.id);
                    // console.log("country id user master add", item);
                  }}
                  renderItem={renderCountryList}
                />
                <Text style={[styles.error_text]}>{countryError}</Text>
              </View>
              <View>
                <Text style={styles.lableText}>State</Text>
                <Dropdown
                  ref={dropdownRefState}
                  style={isMobile ? {
                    borderColor: "#c3c3c3",
                    width: "100%",
                    borderWidth: 1,
                    borderRadius: 2,
                    height: 40,
                    outlineStyle: "none",
                    padding: 10,
                    marginTop: 5,
                    marginBottom: 5,
                  } : styles.dropdown}
                  placeholderStyle={styles.placeholderStyle}
                  selectedTextStyle={styles.selectedTextStyle}
                  inputSearchStyle={styles.inputSearchStyle}
                  iconStyle={styles.iconStyle}
                  data={StateNameData}
                  search
                  maxHeight={300}
                  labelField="state"
                  valueField="id"
                  placeholder={!isFocus ? "Select State" : "...."}
                  searchPlaceholder="Search..."
                  value={stateValue}
                  onChange={(item) => {
                    setStateValue(item.state);
                    setStateValId(item.id);
                    setStateError("");
                    getDependentStateMaster(item.id);
                    // console.log("state create value---add", item.id);
                  }}
                  renderItem={renderStateListItems}
                />
                <Text style={[styles.error_text]}>{stateError}</Text>
              </View>
              <View>
                <Text style={styles.lableText}>District / City</Text>
                <Dropdown
                  ref={dropdownRefCity}
                  style={isMobile ? {
                    borderColor: "#c3c3c3",
                    width: "100%",
                    borderWidth: 1,
                    borderRadius: 2,
                    height: 40,
                    outlineStyle: "none",
                    padding: 10,
                    marginTop: 5,
                    marginBottom: 5,
                  } : styles.dropdown}
                  placeholderStyle={styles.placeholderStyle}
                  selectedTextStyle={styles.selectedTextStyle}
                  inputSearchStyle={styles.inputSearchStyle}
                  iconStyle={styles.iconStyle}
                  data={CityNameData}
                  search
                  maxHeight={300}
                  labelField="city"
                  valueField="id"
                  placeholder={!isFocus ? "Select City" : "...."}
                  searchPlaceholder="Search..."
                  value={cityValue}
                  onChange={(item) => {
                    setCityValue(item.city);
                    setCityValId(item.id);
                    setCityError("");
                    getDependentCityMaster(item.id);
                    // console.log("city create value---add", item.id);
                  }}
                  renderItem={renderCityListItems}
                />
                <Text style={[styles.error_text]}>{cityError}</Text>
              </View>
            </View>
            <View style={{ flex: 1 }}>
              <View>
                <Text style={styles.lableText}>Tahasil</Text>
                <Dropdown
                  ref={dropdownRefTahasil}
                  style={isMobile ? {
                    borderColor: "#c3c3c3",
                    width: "100%",
                    borderWidth: 1,
                    borderRadius: 2,
                    height: 40,
                    outlineStyle: "none",
                    padding: 10,
                    marginTop: 5,
                    marginBottom: 5,
                  } : styles.dropdown}
                  placeholderStyle={styles.placeholderStyle}
                  selectedTextStyle={styles.selectedTextStyle}
                  inputSearchStyle={styles.inputSearchStyle}
                  iconStyle={styles.iconStyle}
                  data={TahasilNameData}
                  search
                  maxHeight={300}
                  labelField="tahasil_name"
                  valueField="id"
                  placeholder={!isFocus ? "Select Tahasil" : "...."}
                  searchPlaceholder="Search..."
                  value={tahasilValue}
                  onChange={(item) => {
                    setTahasilValue(item.tahasil_name);
                    setTahasilValId(item.id);
                    setTahasilError("");
                    getDependentTahasilMaster(item.id);

                  }}
                  renderItem={renderTahasilListItems}
                />
                <Text style={[styles.error_text]}>{tahasilError}</Text>
              </View>
              <View>
                <Text style={styles.lableText}>Village</Text>
                <Dropdown
                  ref={dropdownRefVillage}
                  style={isMobile ? {
                    borderColor: "#c3c3c3",
                    width: "100%",
                    borderWidth: 1,
                    borderRadius: 2,
                    height: 40,
                    outlineStyle: "none",
                    padding: 10,
                    marginTop: 5,
                    marginBottom: 5,
                  } : styles.dropdown}
                  placeholderStyle={styles.placeholderStyle}
                  selectedTextStyle={styles.selectedTextStyle}
                  inputSearchStyle={styles.inputSearchStyle}
                  iconStyle={styles.iconStyle}
                  data={VillageNameData}
                  search
                  maxHeight={300}
                  labelField="village_name"
                  valueField="id"
                  placeholder={!isFocus ? "Select Village" : "...."}
                  searchPlaceholder="Search..."
                  value={VillageValue}
                  onChange={(item) => {
                    setVillageValue(item.village_name);
                    setVillageValId(item.id);
                    setVillageError("");
                    // console.log("village value---", item.id);
                  }}
                  renderItem={renderVillageListItems}
                />
                <Text style={[styles.error_text]}>{villageError}</Text>
              </View>
              <Text style={styles.lableText}>Address </Text>
              <TextInput
                multiline
                numberOfLines={5}
                style={isMobile ? {
                  borderColor: "#c3c3c3",
                  width: "100%",
                  borderWidth: 1,
                  borderRadius: 4,
                  padding: 10,
                  marginTop: 4,
                  marginBottom: 0,
                } : [styles.input, { marginBottom: 0 }]}
                // onChangeText={onChangeNumber}
                value={Address}
                placeholder=""
                maxLength={500}
                keyboardType="numeric"
                onChangeText={(value) => {
                  setAddressValid(false);

                  setAddress(value);

                  if (alpha_pattern.test(value)) {
                    setAddressValid(true);

                    //   setButtonDisabled(false);
                  }
                }}
              />{" "}
              <Text style={styles.lableText}>Pin</Text>
              <TextInput
                style={isMobile ? {
                  borderColor: "#c3c3c3",
                  width: "100%",
                  borderWidth: 1,
                  borderRadius: 4,
                  padding: 10,
                  marginTop: 4,
                  marginBottom: 6,
                } : styles.input}
                placeholder=""
                value={PinCode}
                maxLength={6}
                onChangeText={(value) => {
                  setPinCodeValid(false);
                  setPinCode(value);
                  if (value.length == 0) {
                    setPinError("Please enter pin");
                  } else if (!number_regex.test(value)) {
                    setPinError("Please enter number only");
                  } else if (value.length > 0 && value.length < 6) {
                    setPinError("Please enter 6 digit pin");
                  } else {
                    setPinError("");
                    setPinCodeValid(true);
                  }
                }}
              />
              <Text style={[styles.error_text]}>{pinError}</Text>
              <Text style={styles.lableText}>Qualification</Text>
              <TextInput
                style={isMobile ? {
                  borderColor: "#c3c3c3",
                  width: "100%",
                  borderWidth: 1,
                  borderRadius: 4,
                  padding: 10,
                  marginTop: 4,
                  marginBottom: 6,
                } : styles.input}
                placeholder=""
                value={Qualification}
                onChangeText={(value) => {
                  setQualificationValid(false);
                  setQualification(value);
                  if (value.length == 0) {
                    setQualificationError("Please enter qualification");
                  } else if (!alpha_pattern.test(value)) {
                    setQualificationError("Please enter character");
                  } else {
                    setQualificationError("");
                    setQualificationValid(true);
                  }
                }}
              />
              <Text style={[styles.error_text]}>{qualification_Error}</Text>
              <Text style={styles.lableText}>Maritial Status</Text>
              <View style={{ flexDirection: "row" }}>
                <Text style={styles.genderNames}>Married</Text>
                <RadioButton
                  value={MaritialStatus}
                  status={
                    MaritialStatus === "Married" ? "checked" : "unchecked"
                  }
                  onPress={() => setMaritialStatus("Married")}
                />
                <Text style={styles.genderNames}>Unmarried</Text>
                <RadioButton
                  value={MaritialStatus}
                  status={
                    MaritialStatus === "UnMarried" ? "checked" : "unchecked"
                  }
                  onPress={() => setMaritialStatus("UnMarried")}
                />
              </View>
              <View>
                <Text style={styles.lableText}>Joining Date</Text>
                <Text style={isMobile ? {
                  borderColor: "#c3c3c3",
                  width: "100%",
                  borderWidth: 1,
                  borderRadius: 4,
                  padding: 10,
                  marginTop: 4,
                  marginBottom: 6,
                } : styles.input} onPress={() => setJoinOpen(true)}>
                  {moment(joinDate).format("YYYY-MM-DD")}
                </Text>
                {/* <TouchableOpacity></TouchableOpacity> */}
                {/* <Button uppercase={false} mode="outlined">
                Pick single date
              </Button> */}
                <DatePickerModal
                  locale="en"
                  mode="single"
                  visible={joinOpen}
                  onDismiss={onDismissJoin}
                  date={joinDate}
                  onConfirm={onConfirmJoin}
                  validRange={{
                    endDate: new Date(), // optional
                  }}
                // validRange={{
                //   startDate: new Date(2021, 1, 2),  // optional
                //   endDate: new Date(), // optional
                //   disabledDates: [new Date()] // optional
                // }}
                // onChange={} // same props as onConfirm but triggered without confirmed by user
                // saveLabel="Save" // optional
                // saveLabelDisabled={true} // optional, default is false
                // uppercase={false} // optional, default is true
                // label="Select date" // optional
                // animationType="slide" // optional, default is 'slide' on ios/android and 'none' on web
                // startYear={2000} // optional, default is 1800
                // endYear={2100} // optional, default is 2200
                // closeIcon="close" // optional, default is "close"
                // editIcon="pencil" // optional, default is "pencil"
                // calendarIcon="calendar" // optional, default is "calendar"
                />
              </View>
              <View
                style={isMobile ? {
                  backgroundColor: "#F2F2F2",
                  borderRadius: 10,
                  padding: 10,
                  margin: 7,
                  width: "100%",
                } : {
                  backgroundColor: "#F2F2F2",
                  borderRadius: 10,
                  padding: 10,
                  margin: 7,
                  width: "70%",
                }}
              >
                <Text style={styles.lableText}>Login</Text>
                <TextInput
                  style={styles.input1}
                  placeholder=""
                  value={EmailId}
                  editable={false}
                  onChangeText={(value) => {
                    setloginIdValid(false);

                    setloginId(value);

                    if (Email_Id_pattern.test(value)) {
                      setloginIdValid(true);

                      //   setButtonDisabled(false);
                    }
                  }}
                />
                <Text style={styles.lableText}>Password</Text>
                <TextInput
                  placeholder=""
                  placeholderColor="#F0F0F0"
                  style={styles.input1}
                  value={Password}
                  editable={false}
                  secureTextEntry={true}
                  onChangeText={(value) => {
                    setPasswordValid(false);

                    setPassword(value);

                    if (password_pattern.test(value)) {
                      setPasswordValid(true);

                      //   setButtonDisabled(false);
                    }
                  }}
                />{" "}
                <Text style={[styles.error_text]}>{passwordError}</Text>
              </View>
              <Text style={styles.lableText}>Status</Text>
              <View style={{ flexDirection: "row" }}>
                <Text style={styles.statusNames}>Active</Text>
                <RadioButton
                  value={isstatuschecked}
                  status={isstatuschecked === 1 ? "checked" : "unchecked"}
                  onPress={() => setStatusChecked(1)}
                />
                <Text style={styles.statusNames}>InActive</Text>
                <RadioButton
                  value={isstatuschecked}
                  status={isstatuschecked === 0 ? "checked" : "unchecked"}
                  onPress={() => setStatusChecked(0)}
                />{" "}
              </View>
            </View>
          </View>

          <View
            style={{
              flexDirection: "row",
              justifyContent: "center",
              marginHorizontal: 20,
            }}
          >
            <TouchableOpacity
              style={isMobile ? {
                alignItems: "center",
                backgroundColor: "#006EB9",
                padding: 10,
                borderWidth: 1,
                borderColor: "#006EB9",
                width: "40%",
                marginRight: 10,
              } : styles.saveBtn}
              onPress={() => CheckEnteredData()}
            >
              <Text style={styles.btnText}>Update</Text>
            </TouchableOpacity>

            <TouchableOpacity
              style={isMobile ? {
                alignItems: "center",
                backgroundColor: "#006EB9",
                padding: 10,
                borderWidth: 1,
                borderColor: "#006EB9",
                width: "40%",
                marginRight: 10,
              } : styles.saveBtn}
              onPress={() => navigation.navigate("UserMaster")}
            >
              <Text style={styles.btnText}>Cancel</Text>
            </TouchableOpacity>
          </View>
          {/* </ScrollView> */}
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    // marginTop: 150,
  },
  genderNames: {
    margin: 7,
  },
  statusNames: {
    margin: 7,
  },
  containeralt: {
    // alignItems: "center",
    backgroundColor: "white",
    borderColor: "#eee",
    borderRadius: 10,
    borderWidth: 1,
    // justifyContent: "center",
    height: "100%",
    marginRight: "10%",
    marginTop: "0%",
    margin: "auto",
    padding: 30,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    // marginRight: 10,
    // marginRight: 1,
    width: "70%",
  },
  input: {
    borderColor: "#c3c3c3",
    width: "70%",
    borderWidth: 1,
    borderRadius: 4,

    padding: 10,
    marginTop: 2,
    marginBottom: 4,
  },
  input1: {
    borderColor: "#c3c3c3",
    width: "100%",
    borderWidth: 1,
    borderRadius: 4,

    padding: 10,
    marginTop: 20,
    marginBottom: 6,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  addCountry: {
    alignItems: "center",
    backgroundColor: "#006EB9",
    padding: 10,
    borderWidth: 1,
    borderColor: "#006EB9",
    width: "70%",
    // marginTop: 20,
  },
  saveBtn: {
    alignItems: "center",
    backgroundColor: "#006EB9",
    padding: 10,
    borderWidth: 1,
    borderColor: "#006EB9",
    width: "20%",
    marginRight: 10,
  },
  btnText: {
    color: "#fff",
    fontWeight: "600",
  },
  datePicker: {
    justifyContent: "center",
    alignItems: "flex-start",
    width: 320,
    height: 260,
    display: "flex",
  },
  card: {
    // backgroundColor: "white",
    // borderRadius: 8,
    // paddingVertical: 45,
    // paddingHorizontal: 25,
    // width: "100%",
    // flex: 1,

    // marginVertical: 10,
    // paddingBottom: "48%",
    width: "100%",
    flex: 1,
    marginVertical: 0,
  },
  shadowProp: {
    shadowColor: "#171717",
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 20,
  },

  link: {
    marginTop: 15,
    paddingVertical: 15,
  },
  error_text: {
    color: "red",
    fontSize: 14,
    fontWeight: "600",
    width: "70%",
    marginLeft: 0,
    marginBottom: 10,
  },
  linkText: {
    fontSize: 14,
    color: "#2e78b7",
  },
  lableText: {
    color: "#484848",
    fontWeight: "500",
    marginTop: 2,
    // fontSize: 21,
  },

  // DropDown CSS
  dropdown: {
    borderColor: "#c3c3c3",
    width: "70%",
    borderWidth: 1,
    borderRadius: 2,
    height: 40,
    outlineStyle: "none",

    padding: 10,
    marginTop: 5,
    marginBottom: 5,
    // borderRadius: 10,
    // padding: 12,
    // shadowColor: "#000",
    // shadowOffset: {
    //   width: 0,
    //   height: 1,
    // },
    // shadowOpacity: 0.2,
    // shadowRadius: 1.41,

    // elevation: 2,
  },
  icon: {
    marginRight: 5,
  },
  item: {
    padding: 9,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  textItem: {
    flex: 1,
    fontSize: 16,
  },
  placeholderStyle: {
    fontSize: 16,
  },
  selectedTextStyle: {
    fontSize: 16,
  },
  iconStyle: {
    width: 20,
    height: 20,
  },
  inputSearchStyle: {
    height: 40,
    fontSize: 16,
    color: "black",
    fontFamily: "bold",
  },
});
