import Header from "../navigation/header";
import {
  StyleSheet,
  TouchableOpacity,
  TextInput,
  Modal,
  ScrollView,
  Alert,
  ActivityIndicator,
  Switch,
  useWindowDimensions,
  Image,
  Dimensions,
  Pressable,
} from "react-native";
import IconLoc from "react-native-vector-icons/MaterialIcons";

import { RadioButton, Button } from "react-native-paper";
import moment from "moment";
import { TabView, SceneMap, TabBar } from "react-native-tab-view";

import SuccessPopup from "../../../components/SuccessPopup";

import { CheckBox } from "react-native-elements";
import AsyncStorage from "@react-native-async-storage/async-storage";

import { DatePickerModal, TimePickerModal } from "react-native-paper-dates";
import { Dropdown } from "react-native-element-dropdown";

import { Text, View } from "../../../components/Themed";

import Icon from "react-native-vector-icons/AntDesign";
import React, { useState, useRef, useEffect } from "react";
import IconCross from "react-native-vector-icons/Entypo";
import { baseUrl, postData } from "../../../networking/api";
import { useFocusEffect } from "@react-navigation/native";
import DataTable from "react-data-table-component";
import IconRefresh from "react-native-vector-icons/FontAwesome";

export default function CreateDeviceProfile({ navigation }) {
  const [isVisible, setIsVisible] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const [animating, setAnimating] = useState(true);
  const [isAdd, setisAdd] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [genderchecked, setGenderChecked] = useState(1);
  const [isMarriedchecked, setMaritialChecked] = useState(1);
  const [isstatuschecked, setStatusChecked] = useState(1);
  const [isAssignchecked, setAssignChecked] = useState(1);

  const [issuccessVisible, setissuccessVisible] = useState(false);
  const [responsestatus, setresponsestatus] = useState("");
  const [PopupMessage, setPopupMessage] = useState("");

  const [projectId, setProjectId] = useState("");

  const [DeviceProfileName, setDeviceProfileName] = useState("");
  const [isDeviceProfileNameValid, setDeviceProfileNameValid] = useState(false);
  const [isDeviceProfileNameEntered, setDeviceProfileNameEntered] =
    useState(false);

  const [DeviceProfileCode, setDeviceProfileCode] = useState("");
  const [isDeviceProfileCodeValid, setDeviceProfileCodeValid] = useState(false);
  const [isDeviceProfileCodeEntered, setDeviceProfileCodeEntered] =
    useState(false);
const [onlineCheckTime, setOnlineCheckTimeError] = useState("");
  const [Lat, setLatitude] = useState("");
  const [Long, setLongitude] = useState("");

  const [Location, setLocation] = useState("");
  const [isLocationValid, setLocationValid] = useState(false);
  const [isLocationEntered, setLocationEntered] = useState(false);

  const [isZoneIDValid, setZoneIdValid] = useState(false);
  const [isSubZoneIDValid, setSubZoneIDValid] = useState(false);

  const [ZoneId, setZoneId] = useState([]);
  const [ZoneValue, setZoneValue] = useState();
  const [ZoneValueId, setZoneValueId] = useState();

  const [ZoneNameData, setZoneNameData] = useState([]);

  const [SubzoneValue, setSubzoneValue] = useState();
  const [SubzoneValueId, setSubzoneValueId] = useState();
  const [subzoneData, setSubzoneData] = useState([]);

  const [isFocus, setIsFocus] = useState(false);

  //Dropdown value pickup
  const [OmegaIdValue, setOmegaIdValue] = useState("");
  const [OmegaIdData, setOmegaIdData] = useState([]);
  const [OmegavalueId, setOmegaValueiD] = useState("");
  const [OmegaDeviceId, setOmegaDeviceiD] = useState("");

  const [OmegaDetails, setOmegaDetails] = useState([]);
  const [AnalogInput, setAnalogInput] = useState([]);
  const [DigitalInput, setDigitalInput] = useState([]);
  const [DataRecord, setDataRecord] = useState([]);
  const [shouldShow, setShouldShow] = useState(true);
  // isonline state
  const [isChecked, setIsChecked] = useState(false);
  //table state --RohitB
  const [datatableLabel, setDatatableLabel] = useState([]);
  // time picker -- Rohit B
  const [timeVisible, setTimeVisible] = useState(false);
  const [selectedTime, setSelectedTime] = useState(0);


  const onDismissTime = () => {
    setTimeVisible(false);
  };

  const onConfirmTime = (selectedDate) => {
    setSelectedTime(selectedDate);
    setTimeVisible(false);
  };

  const openTimePicker = () => {
    setTimeVisible(true);
  };
  //IMEI Tabview --RohitB
  const [valveData, setValveData] = useState([
    { id: 1, fieldName: "Valve Name", apiname: "name", value: "" },
    { id: 2, fieldName: "Nominal Flow", apiname: "nominal_flow", value: "" },
    {
      id: 3,
      fieldName: "Low Flow",
      apiname: "percentage_for_low_flow",
      value: "",
    },
    {
      id: 4,
      fieldName: "High Flow",
      apiname: "percentage_for_high_flow",
      value: "",
    },
    { id: 5, fieldName: "High Alert", apiname: "highalert", value: "" },
    { id: 6, fieldName: "Low Alert", apiname: "lowalert", value: "" },
    { id: 7, fieldName: "Alert Time", apiname: "alert_time", value: "" },
  ]);
  const [pressureData, setPressureData] = useState([
    { id: 1, fieldName: "AI-2 Input name", apiname: "name", value: "" },
    { id: 2, fieldName: "Unit", apiname: "unit", value: "" },
    { id: 3, fieldName: "Low Pressure", apiname: "low_pressure", value: "" },
    { id: 4, fieldName: "High Pressure", apiname: "high_pressure", value: "" },
    { id: 5, fieldName: "Alert Time", apiname: "alert_time", value: "" },
    { id: 6, fieldName: "4mA", apiname: "4ma", value: "" },
    { id: 7, fieldName: "20mA", apiname: "20ma", value: "" },
  ]);
  const [levelData, setLevelData] = useState([
    { id: 1, fieldName: "AI-1 Input name", apiname: "name", value: "" },
    { id: 2, fieldName: "Unit", apiname: "unit", value: "" },
    { id: 3, fieldName: "Min Level", apiname: "min_level", value: "" },
    { id: 4, fieldName: "Max Level", apiname: "max_level", value: "" },
    { id: 5, fieldName: "Tank Full", apiname: "tank_full", value: "" },
    { id: 6, fieldName: "4mA", apiname: "4ma", value: "" },
    { id: 7, fieldName: "20mA", apiname: "20ma", value: "" },
  ]);
  const [waterData, setWaterData] = useState([
    { id: 1, fieldName: "Water Meter Name", apiname: "name", value: "" },
    { id: 2, fieldName: "Pluse Factor", apiname: "pulse_factor", value: "" },
    {
      id: 3,
      fieldName: "Adj./Set Totalizer",
      apiname: "adj./set_totalizer",
      value: "",
    },
    { id: 4, fieldName: "Unit", apiname: "unit", value: "" },
    { id: 5, fieldName: "Water Leak", apiname: "water_leak", value: "" },
  ]);
  const [doorSwitchData, setDoorSwitchData] = useState([
    { id: 1, fieldName: "Door Name", apiname: "name", value: "" },
    { id: 2, fieldName: "Output", apiname: "output", value: "" },
  ]);
  const [controlData, setControlData] = useState([
    { id: 1, fieldName: "Operation", apiname: "operation", value: "" },
    { id: 2, fieldName: "Start Time (hh:mm)", apiname: "start_time", value: "" },
    { id: 3, fieldName: "Water By", apiname: "water_by", value: "" },
    { id: 4, fieldName: "Set Duration (hh:mm)", apiname: "set_duration", value: "" },
    { id: 5, fieldName: "Set Volume (m3)", apiname: "set_volume", value: "" },
    { id: 6, fieldName: "Channel", apiname: "channel", value: "" },
  ]);
  const valvecolumns = [
    {
      selector: "fieldName",
      sortable: true,
    },
    {
      selector: (row, index) => (
        <TextInput
          style={styles.input}
          defaultValue={valveData[index].value}
          onBlur={(e) => {
            console.log("text", e.nativeEvent.text);
            const updatedData = valveData.map((item) => {
              if (item.id === row.id) {
                return { ...item, value: e.nativeEvent.text };
              }
              return item;
            });
            setValveData(updatedData);
          }}
        />
      ),
    },
  ];

  // Define DataTable data
  const valvedata = [
    { id: 1, fieldName: "Valve Name" },
    { id: 2, fieldName: "Nominal Flow" },
    { id: 3, fieldName: "Low Flow" },
    { id: 4, fieldName: "High Flow" },
    { id: 5, fieldName: "High Alert" },
    { id: 6, fieldName: "Low Alert" },
    { id: 7, fieldName: "Alert Time (min)" },
  ];

  const pressurecolumns = [
    {
      //name: 'Field Name',
      selector: "fieldName",
      sortable: true,
    },

    {
      selector: (row, index) => (
        <TextInput
          style={styles.input}
          defaultValue={pressureData[index].value}
          onBlur={(e) => {
            console.log("text", e.nativeEvent.text);
            const updatedData = pressureData.map((item) => {
              if (item.id === row.id) {
                return { ...item, value: e.nativeEvent.text };
              }
              return item;
            });
            setPressureData(updatedData);
          }}
        />
      ),
    },
  ];
  const pressureTransmitterdata = [
    { id: 1, fieldName: "AI-2 Input name" },
    { id: 2, fieldName: "Unit" },
    { id: 3, fieldName: "Low Pressure " },
    { id: 4, fieldName: "High Pressure " },
    { id: 5, fieldName: "Alert time" },
    { id: 6, fieldName: "4mA" },
    { id: 7, fieldName: "20mA" },
  ];
  const levelIndex = 4;
  const levelcolumns = [
    {
      selector: "fieldName",
      sortable: true,
    },
    {
      selector: (row, index) => (
        <TextInput
          style={styles.input}
          defaultValue={levelData[index].value}
          onBlur={(e) => {
            console.log("text", e.nativeEvent.text);
            const updatedData = levelData.map((item) => {
              if (item.id === row.id) {
                return { ...item, value: e.nativeEvent.text };
              }
              return item;
            });
            setLevelData(updatedData);
          }}
          editable={index === levelIndex ? false : true}
        />
      ),
    },
  ];
  const levelTransmitterdata = [
    { id: 1, fieldName: "AI-1 Input name" },
    { id: 2, fieldName: "Unit" },
    { id: 3, fieldName: "MIN Level" },
    { id: 4, fieldName: "Max Level" },
    { id: 5, fieldName: "Tank Full" },
    { id: 6, fieldName: "4mA" },
    { id: 7, fieldName: "20mA" },
  ];

  const watercolumns = [
    {
      selector: "fieldName",
      sortable: true,
    },
    {
      selector: (row, index) => (
        <TextInput
          style={styles.input}
          defaultValue={waterData[index].value}
          onBlur={(e) => {
            console.log("text", e.nativeEvent.text);
            const updatedData = waterData.map((item) => {
              if (item.id === row.id) {
                return { ...item, value: e.nativeEvent.text };
              }
              return item;
            });
            setWaterData(updatedData);
          }}
        />
      ),
    },
  ];
  const watermeterdata = [
    { id: 1, fieldName: "Water meter name" },
    { id: 2, fieldName: "Pluse Factor" },
    { id: 3, fieldName: "Adj./Set Totalizer" },
    { id: 4, fieldName: "Unit" },
    { id: 5, fieldName: "Water Leak" },
  ];

  const doorcolumns = [
    {
      selector: "fieldName",
      sortable: true,
    },
    {
      selector: (row, index) => (
        <TextInput
          style={styles.input}
          defaultValue={doorSwitchData[index].value}
          onBlur={(e) => {
            console.log("text", e.nativeEvent.text);
            const updatedData = doorSwitchData.map((item) => {
              if (item.id === row.id) {
                return { ...item, value: e.nativeEvent.text };
              }
              return item;
            });
            setDoorSwitchData(updatedData);
          }}
        />
      ),
    },
  ];
  const doorswitchdata = [
    { id: 1, fieldName: "Door name" },
    { id: 2, fieldName: "Output" },
  ];
  const controlIndex = 5;
  const controlcolumns = [
    {
      selector: "fieldName",
      sortable: true,
    },
    {
      selector: (row, index) => (
        controlData[index].id == 1 || controlData[index].id == 3 ? (
          <View style={isMobile ? { marginTop: "-35%" } : null}>
            <View
              style={
                isMobile
                  ? {
                    flexDirection: "row",
                    marginTop: 5,
                    alignItems: "center",
                  }
                  : {
                    flexDirection: "row",
                    marginTop: 10,
                    alignItems: "center",
                    justifyContent: 'center',
                  }
              }
            >
              <Dropdown
                style={
                  isMobile
                    ? {
                      borderColor: "#c3c3c3",
                      width: "200%",
                      borderWidth: 1,
                      borderRadius: 2,
                      height: 40,
                      padding: 10,
                      marginTop: 5,
                      marginBottom: 5,
                    }
                    : [styles.dropdown, { width: "100%" }]
                }
                placeholderStyle={styles.placeholderStyle}
                selectedTextStyle={styles.selectedTextStyle}
                inputSearchStyle={styles.inputSearchStyle}
                iconStyle={styles.iconStyle}
                data={controlData[index].id == 1 ? staticOptionsForOperation : staticOptionsForWaterBy}
                search
                maxHeight={300}
                labelField="label"
                valueField="value"
                searchPlaceholder="Search..."
                value={controlData[index].value}
                onChange={(e) => {
                  const updatedData = controlData.map((item) => {
                    if (item.id === row.id) {
                      return { ...item, value: e.value };
                    }
                    return item;
                  });
                  setControlData(updatedData);
                }}
                renderItem={renderItemForOperation}
              />
            </View>
          </View>
        ) : (
          <TextInput
            style={styles.input}
            defaultValue={controlData[index].value}
            onBlur={(e) => {
              console.log("text", e.nativeEvent.text);
              const updatedData = controlData.map((item) => {
                if (item.id === row.id) {
                  return { ...item, value: e.nativeEvent.text };
                }
                return item;
              });
              setControlData(updatedData);
            }}
            editable={index === controlIndex ? false : true}
          />
        )
      ),
    },
  ];

  const controldata = [
    { id: 1, fieldName: "Operation" },
    { id: 2, fieldName: "Start time (hh:mm)" },
    { id: 3, fieldName: "Water By" },
    { id: 4, fieldName: "Set Duration (hh:mm)" },
    { id: 5, fieldName: "Set Volume (m3)" },
    { id: 6, fieldName: "Channel" },
  ];
  useEffect(() => {
    // Load data from storage when the component mounts
    const storedData = JSON.parse(localStorage.getItem("datatableLabel"));
    if (storedData) {
      setDatatableLabel(storedData);
    }
  }, []);
  const labelvalueChange = (id, type, value) => {
    const updatedDatatableLabel = datatableLabel.map((element) => {
      if (element.id == id) {
        return {
          ...element,
          [type]: value,
        };
      }
      return element;
    });
    console.log("updatedDatatableLabel", updatedDatatableLabel);
    setDatatableLabel(updatedDatatableLabel);
    localStorage.setItem(
      "datatableLabel",
      JSON.stringify(updatedDatatableLabel)
    );
  };
  const renderInputs = (row, type) => (
    <TextInput
      value={type === "label" ? row.label : row.value}
      style={styles.input}
      placeholder={type === "label" ? "Enter Label" : "Enter Value"}
      onChangeText={(text) => {
        labelvalueChange(row.id, type === "label" ? "label" : "value", text);
      }}
    />
  );
  const columnstableName = [
    {
      name: "Sr. No",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Label Name",
      //selector: 'fieldName',
      selector: (row) => row.code,
      sortable: true,
    },
    // {
    //   name: "Label",
    //   selector: row =>
    //     <TextInput
    //       value={row.labelInputValue}
    //       style={styles.input}
    //       placeholder="Enter Label"
    //       onChangeText={(text) => {
    //         labelvalueChange(row.id, "label", text);
    //       }}
    //     />
    //   ,
    //   sortable: true,
    // },
    // {
    //   name: "Value",
    //   selector: row =>
    //     <TextInput
    //       value={row.valueInputValue}
    //       style={styles.input}
    //       placeholder="Enter value"
    //       onChangeText={(text) => {
    //         labelvalueChange(row.id,"value",text);
    //       }}
    //     />
    //   ,
    //   sortable: true,
    // },
    {
      name: "Label",
      selector: (row) => renderInputs(row, "label"),
      sortable: true,
    },
    {
      name: "Value",
      selector: (row) => renderInputs(row, "value"),
      sortable: true,
    },
  ];
  // const omegaData = [
  //   { label: 'OM000000030', value: '1001' },
  //   { label: 'OM000000031', value: '2001' },
  //   { label: 'OM000000032', value: '3001' },
  //   { label: 'OM000000033', value: '4001' },
  //   { label: 'OM000000034', value: '5001' },

  // ];
  const dropdownRefOmegaiD = useRef();
  const dropdownRefZone = useRef();

  const closeMenu = () => {
    dropdownRefOmegaiD.current.close();
    dropdownRefZone.current.close();
  };

  useEffect(() => {
    window.addEventListener("scroll", closeMenu);
    return () => window.removeEventListener("scroll", closeMenu);
  }, []);

  //Error message
  const [ZoneIdError, setZoneIdError] = useState("");
  const [SubZoneIdError, setSubZoneIdError] = useState("");

  const [OmegaError, setOmegaError] = useState("");

  const [DeviceProfileIdError, setDeviceProfileIdError] = useState("");

  const [DeviceProfileNameError, setDeviceProfileNameError] = useState("");
  const [IMEINumError, setIMEINumError] = useState("");
  const [isIMEILoading, setIsIMEILoading] = useState(false);

  const [DeviceProfileCodeError, setDeviceProfileCodeError] = useState("");
  const [DeviceProfileLocationError, setDeviceProfileLocationError] =
    useState("");
  //const [isClickedChecked,setIsClickChecked]=useState(0);
  const [IMEINum, setIMEINum] = useState("");

  const toggleSwitch = () => setIsEnabled((previousState) => !previousState);

  //DOb
  const [date, setDate] = React.useState<Date | undefined>(undefined);
  const [open, setOpen] = React.useState(false);
  const [lat, setLat] = React.useState(null);
  const [long, setLong] = React.useState(null);

  const screenwidth = Dimensions.get("window").width;
  const isMobile = screenwidth < 768;

  const onDismissSingle = React.useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const onConfirmSingle = React.useCallback(
    (params) => {
      setOpen(false);
      setDate(params.date);
    },
    [setOpen, setDate]
  );

  //DOJ
  const [joinDate, setJoinDate] = React.useState<Date | undefined>(undefined);
  const [joinOpen, setJoinOpen] = React.useState(false);

  //Based_on
  const [selectedValue, setSelectedValue] = useState(1);
  const staticOptions = [
    { label: "Omega", value: 1 },
    { label: "IMEI", value: 2 },
  ];
  const staticOptionsForOperation = [
    { label: "Auto", value: 0 },
    { label: "Manual", value: 1 },
  ];
  const staticOptionsForWaterBy = [
    { label: "Valume", value: 0 },
    { label: "Duration", value: 1 },
  ];

  const onDismissJoin = React.useCallback(() => {
    setOpen(false);
  }, [setJoinOpen]);

  const onConfirmJoin = React.useCallback(
    (params) => {
      setJoinOpen(false);
      setJoinDate(params.date);
    },
    [setJoinOpen, setJoinDate]
  );
  //Regex
  // var alpha_pattern = new RegExp(/^[a-zA-Z]+(\s[a-zA-Z]+)?$/);
  // var alpha_pattern = new RegExp(/^[A-Za-z0-9_@./#&+-]*$/);
  // var alpha_pattern = new RegExp(/^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$/); //THIS
  var alpha_pattern = new RegExp(
    /^[a-zA-Z0-9!@#$%^&-]+(\s[a-zA-Z0-9!@#$%^&-]+)?$/
  );

  var Email_Id_pattern = new RegExp(
    /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w\w+)+$/
  );
  var password_pattern = new RegExp(
    /^[a-zA-Z0-9]+$/
    // /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&^_-]{8,}$/
  );
  // var MobileNo_pattern = new RegExp(/^[0]?[789]\d{9}$/);
  // var number_regex = new RegExp(/^[0-9]*$/);
  const regex = /^[0-9]*$/;

  const FirstRoute = () => (
    <View style={{ flex: 1, backgroundColor: "#ff4081" }}>
      {" "}
      <Text>tab1 </Text>
    </View>
  );

  const SecondRoute = () => (
    <View style={{ flex: 1, backgroundColor: "#673ab7" }}>
      <Text>tab2 </Text>
    </View>
  );
  const ThirdRoute = () => (
    <View style={{ flex: 1, backgroundColor: "#eee" }}>
      <Text>tab2 </Text>
    </View>
  );

  const DigitalOutputRoute = () => (
    <View
      style={{
        flex: 1,
        justifyContent: "flex-start",
        backgroundColor: "#F6F9F9",
      }}
    >
      {" "}
      <View style={[styles.card, { flexGrow: 1 }]}>
        <View
          style={[
            styles.shadowProp,
            {
              backgroundColor: "white",
            },
          ]}
        >
          <View
            style={{
              width: "100%",
              // flex: 1,
              backgroundColor: "#F6F9F9",
            }}
          >
            <View
              style={{
                borderWidth: 1,
                borderColor: "#e3e0e0",
              }}
            >
              <View>
                <DataTable
                  // title="Country"
                  columns={columns}
                  data={OmegaDetails}
                  customStyles={customStyles}
                  pagination
                />
              </View>
            </View>
          </View>
          {/* </ScrollView> */}
        </View>
      </View>
    </View>
  );

  const AnalogInputRoute = () => (
    <View
      style={{
        flex: 1,
        justifyContent: "flex-start",
        backgroundColor: "#F6F9F9",
      }}
    >
      {" "}
      <View style={[styles.card, { flexGrow: 1 }]}>
        <View
          style={[
            styles.shadowProp,
            {
              backgroundColor: "white",
            },
          ]}
        >
          <View
            style={{
              width: "100%",
              // flex: 1,
              backgroundColor: "#F6F9F9",
            }}
          >
            <View
              style={{
                borderWidth: 1,
                borderColor: "#e3e0e0",
              }}
            >
              <View>
                <DataTable
                  // title="Country"
                  columns={columnsAnalog}
                  data={AnalogInput}
                  customStyles={customStyles}
                  pagination
                />
              </View>
            </View>
          </View>
          {/* </ScrollView> */}
        </View>
      </View>
    </View>
  );
  const DigitalInputRoute = () => (
    <View
      style={{
        flex: 1,
        justifyContent: "flex-start",
        backgroundColor: "#F6F9F9",
      }}
    >
      {" "}
      <View style={[styles.card, { flexGrow: 1 }]}>
        <View
          style={[
            styles.shadowProp,
            {
              backgroundColor: "white",
            },
          ]}
        >
          <View
            style={{
              width: "100%",
              // flex: 1,
              backgroundColor: "#F6F9F9",
            }}
          >
            <View
              style={{
                borderWidth: 1,
                borderColor: "#e3e0e0",
              }}
            >
              <View>
                <DataTable
                  // title="Country"
                  columns={columnDigitalInput}
                  data={DigitalInput}
                  customStyles={customStyles}
                  pagination
                />
              </View>
            </View>
          </View>
          {/* </ScrollView> */}
        </View>
      </View>
    </View>
  );

  const renderScene = SceneMap({
    DigitalOutput: DigitalOutputRoute,
    DigitalInput: DigitalInputRoute,
    AnalogInput: AnalogInputRoute,
  });
  const layout = useWindowDimensions();

  const [index, setIndex] = React.useState(0);
  const [routes] = React.useState([
    { key: "DigitalOutput", title: "Digital Output" },

    { key: "DigitalInput", title: "Digital Input" },
    { key: "AnalogInput", title: "Analog Input" },
  ]);

  const ValvesRoute = () => (
    <View
      style={{
        flex: 1,
        justifyContent: "flex-start",
        backgroundColor: "#F6F9F9",
      }}
    >
      <View style={[styles.card, { flexGrow: 1 }]}>
        <View style={[styles.shadowProp, { backgroundColor: "white" }]}>
          <View style={{ width: "100%", backgroundColor: "#F6F9F9" }}>
            <View style={{ borderWidth: 1, borderColor: "#e3e0e0" }}>
              <DataTable
                columns={valvecolumns}
                data={valvedata}
                customStyles={customStyles}
              />
            </View>
          </View>
        </View>
      </View>
    </View>
  );

  const PressureTransmitterRoute = () => (
    <View
      style={{
        flex: 1,
        justifyContent: "flex-start",
        backgroundColor: "#F6F9F9",
      }}
    >
      <View style={[styles.card, { flexGrow: 1 }]}>
        <View style={[styles.shadowProp, { backgroundColor: "white" }]}>
          <View style={{ width: "100%", backgroundColor: "#F6F9F9" }}>
            <View style={{ borderWidth: 1, borderColor: "#e3e0e0" }}>
              <DataTable
                columns={pressurecolumns}
                data={pressureTransmitterdata}
                customStyles={customStyles}
              />
            </View>
          </View>
        </View>
      </View>
    </View>
  );

  const LevelTransmitterRoute = () => (
    <View
      style={{
        flex: 1,
        justifyContent: "flex-start",
        backgroundColor: "#F6F9F9",
      }}
    >
      <View style={[styles.card, { flexGrow: 1 }]}>
        <View style={[styles.shadowProp, { backgroundColor: "white" }]}>
          <View style={{ width: "100%", backgroundColor: "#F6F9F9" }}>
            <View style={{ borderWidth: 1, borderColor: "#e3e0e0" }}>
              <DataTable
                columns={levelcolumns}
                data={levelTransmitterdata}
                customStyles={customStyles}
              />
            </View>
          </View>
        </View>
      </View>
    </View>
  );

  const WaterMeterRoute = () => (
    <View
      style={{
        flex: 1,
        justifyContent: "flex-start",
        backgroundColor: "#F6F9F9",
      }}
    >
      <View style={[styles.card, { flexGrow: 1 }]}>
        <View style={[styles.shadowProp, { backgroundColor: "white" }]}>
          <View style={{ width: "100%", backgroundColor: "#F6F9F9" }}>
            <View style={{ borderWidth: 1, borderColor: "#e3e0e0" }}>
              <DataTable
                columns={watercolumns}
                data={watermeterdata}
                customStyles={customStyles}
              />
            </View>
          </View>
        </View>
      </View>
    </View>
  );

  const DoorSwitchRoute = () => (
    <View
      style={{
        flex: 1,
        justifyContent: "flex-start",
        backgroundColor: "#F6F9F9",
      }}
    >
      <View style={[styles.card, { flexGrow: 1 }]}>
        <View style={[styles.shadowProp, { backgroundColor: "white" }]}>
          <View style={{ width: "100%", backgroundColor: "#F6F9F9" }}>
            <View style={{ borderWidth: 1, borderColor: "#e3e0e0" }}>
              <DataTable
                columns={doorcolumns}
                data={doorswitchdata}
                customStyles={customStyles}
              />
            </View>
          </View>
        </View>
      </View>
    </View>
  );

  const ControlParameterRoute = () => (
    <View
      style={{
        flex: 1,
        justifyContent: "flex-start",
        backgroundColor: "#F6F9F9",
      }}
    >
      <View style={[styles.card, { flexGrow: 1 }]}>
        <View style={[styles.shadowProp, { backgroundColor: "white" }]}>
          <View style={{ width: "100%", backgroundColor: "#F6F9F9" }}>
            <View style={{ borderWidth: 1, borderColor: "#e3e0e0" }}>
              <DataTable
                columns={controlcolumns}
                data={controldata}
                customStyles={customStyles}
              />
            </View>
          </View>
        </View>
      </View>
    </View>
  );

  const imeiRenderScene = SceneMap({
    ControlParameter: ControlParameterRoute,
    Valves: ValvesRoute,
    PressureTransmitter: PressureTransmitterRoute,
    LevelTransmitter: LevelTransmitterRoute,
    WaterMeter: WaterMeterRoute,
    DoorSwitch: DoorSwitchRoute,
  });

  const [imeiIndex, setIMEIIndex] = React.useState(0);
  const [imeiroutes] = React.useState([
    { key: "ControlParameter", title: "Control Parameter" },
    { key: "Valves", title: "Valves" },
    { key: "PressureTransmitter", title: "Pressure Transmitter" },
    { key: "LevelTransmitter", title: "Level Transmitter" },
    { key: "WaterMeter", title: "Water Meter" },
    { key: "DoorSwitch", title: "Door Switch" },
  ]);

  useFocusEffect(
    React.useCallback(() => {
      getAdminData();
      getDatatableDetails();
      // getOmegaIdList();
    }, [])
  );

  const getAdminData = async () => {
    let jsonValue = await AsyncStorage.getItem("userData");
    // console.log("jsonValue:", jsonValue);
    let projValue = await AsyncStorage.getItem("projectData");

    // console.log("jsonValue & projValue:", jsonValue, projValue);
    if (projValue != null) {
      // console.log("project id select in zone create", projValue);
      setProjectId(projValue);
      getAllZoneProfileData(projValue);

      getOmegaDependentByProjectId(projValue);
    } else if (jsonValue != null) {
      var userDetailsresponse = JSON.parse(jsonValue);
      // console.log("userDetailsresponse==>", userDetailsresponse);
      getAllZoneProfileData(userDetailsresponse.project_selected);
      setProjectId(userDetailsresponse.project_selected);
      getOmegaDependentByProjectId(userDetailsresponse.project_selected);
      // setEmpCode(userDetailsresponse.employee_code);
    }
  };
  const renderZoneIdListItems = (item: any) => {
    return (
      <View style={styles.item}>
        {isAdd == true && <Text style={styles.textItem}>{item.zone_name}</Text>}
        {isAdd == false && (
          <Text style={styles.textItem}>{item.zone_name}</Text>
        )}
        {item.id === ZoneValueId && (
          <Icon style={styles.icon} color="black" name="Safety" size={20} />
        )}
      </View>
    );
  };

  const renderSubZoneIdListItems = (item: any) => {
    return (
      <View style={styles.item}>
        {isAdd == true && (
          <Text style={styles.textItem}>{item.sub_zone_name}</Text>
        )}
        {isAdd == false && (
          <Text style={styles.textItem}>{item.sub_zone_name}</Text>
        )}
        {item.id === SubzoneValueId && (
          <Icon style={styles.icon} color="black" name="Safety" size={20} />
        )}
      </View>
    );
  };

  const renderOmegaIdListItems = (item: any) => {
    // console.log("omega arraayyyyyy===", item);

    return (
      <View style={styles.item}>
        {OmegaIdData.length > 0 && (
          <Text style={styles.textItem}>{item.device_name}</Text>
        )}
        {/* {OmegaIdData.length == 0 ? (
          <Text style={styles.textItem}>{item.device_name}</Text>) : (<Text style={styles.textItem}>{item.device_name}</Text>
        )} */}

        {item.value === OmegavalueId && (
          <Icon style={styles.icon} color="black" name="Safety" size={20} />
        )}
      </View>
    );
  };
  const renderItem = (item: any) => {
    return (
      <View style={styles.item}>
        <Text style={styles.textItem}>{item.label}</Text>
      </View>
    );
  };
  const renderItemForOperation = (item: any) => {
    return (
      <View style={styles.item}>
        <Text style={styles.textItem}>{item.label}</Text>
      </View>
    );
  };
  const CheckEnteredData = async () => {
    setDeviceProfileNameEntered(true);
    setDeviceProfileCodeEntered(true);
    // selectedValue 1= omega, 2= imei
    if (selectedValue == 1) {
      if (OmegaIdValue == "") {
        setOmegaError("Please enter Omega Id");
        return;
      }
      if (DeviceProfileName == "") {
        setDeviceProfileNameError("Please enter device name");
        return;
      }
      if (isZoneIDValid == false) {
        if (ZoneValue != "") {
          setZoneIdError("Please enter zone name");
        }
        return false;
      }
      if (isSubZoneIDValid == false) {
        if (SubzoneValue != "" && isAssignchecked == 0) {
          setSubZoneIdError("Please Select Subzone name");
          return false;
        }
      }
    }
    if (selectedValue == 2) {
      if (IMEINum == "") {
        setIMEINumError("Please enter IMEI number");
        return;
      }
      if (DeviceProfileName == "") {
        setDeviceProfileNameError("Please enter device name");
        return;
      }
      if (isZoneIDValid == false) {
        if (ZoneValue != "") {
          setZoneIdError("Please enter zone name");
        }
        return false;
      }
      if (isSubZoneIDValid == false) {
        if (SubzoneValue != "" && isAssignchecked == 0) {
          setSubZoneIdError("Please Select Subzone name");
          return false;
        }
      }
       if (!selectedTime) {
        setOnlineCheckTimeError("Please Fill Online Check Time");
        return false;
      }
      if (!lat || !long) {
        setPopupMessage("Please fill lat and long");
        setissuccessVisible(true);
        setresponsestatus("failed");
        return false;
      }
    }

    CreateDeviceProfileData();
    setZoneIdError("");
    setSubZoneIdError("");
    setDeviceProfileNameError("");
    setDeviceProfileCodeError("");
    setIMEINumError("");
  };
  // Get all omega id list Api
  // const getOmegaIdList = async () => {
  //   setIsLoading(true);
  //   try {
  //     var OmegaIdData = JSON.stringify({
  //       module: "devices_master",
  //       relation: [],
  //     });
  //     const OmegaIdList = await postData(
  //       OmegaIdData,
  //       "masters/getMasterList"
  //     );
  //     setIsLoading(false);
  //     var code = OmegaIdList.code;
  //     if (code == 1) {
  //       // console.log("Omega list api====>", OmegaIdList.data);

  //       setOmegaIdData(OmegaIdList.data);

  //     } else {
  //       setIsLoading(false);
  //       //alert(OmegaIdList.message);
  //     }
  //   } catch (error) {
  //     setIsLoading(false);
  //     // console.log(error);
  //   }
  // };

  const getAllZoneProfileData = async (pid) => {
    // console.log("pidpid", pid);
    setIsLoading(true);
    try {
      var getZoneData = JSON.stringify({
        condition: {
          project_id: pid,
        },
        module: "zone_master",
        relation: [],
      });
      const ZoneNameResponse = await postData(
        getZoneData,
        "masters/getMasterList"
      );
      setIsLoading(false);
      var code = ZoneNameResponse.code;
      if (code == 1) {
        // console.log("ZoneNameResponse list====>", ZoneNameResponse.data);

        setZoneNameData(ZoneNameResponse.data);
      } else {
        setIsLoading(false);
        //alert(ZoneNameResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };
  const getDependentZoneMaster = async (zone_id) => {
    // console.log("zone_idmasters", zone_id);
    setIsLoading(true);

    try {
      var dependentData = JSON.stringify({
        column: "zone_id",

        value: zone_id,

        module: "sub_zone_master",
      });
      const dependentZoneRes = await postData(
        dependentData,
        "masters/getDependentMasterList"
      );
      setIsLoading(false);
      var code = dependentZoneRes.code;
      if (code == 1) {
        // console.log("getDependentCountryMaster======", dependentZoneRes.data);
        // setCountryLength(dependentZoneRes.data.length);
        setSubzoneData(dependentZoneRes.data);
      } else {
        setIsLoading(false);
        //alert(dependentZoneRes.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };
  //Get Dependent omega  by project id
  const getOmegaDependentByProjectId = async (project_id) => {
    // console.log("getOmegaDependentByProjectId===", project_id);

    setIsLoading(true);
    try {
      var OmegaIdData = JSON.stringify({
        project_id: project_id,
      });
      const OmegaIdList = await postData(
        OmegaIdData,
        "masters/getUniqueOmegaIds"
        // "masters/getOmegaFromBermad"
      );
      setIsLoading(false);
      var code = OmegaIdList.code;
      if (code == 1) {
        // console.log("Bermad device Omega list api====>", OmegaIdList.data);
        var mergeData = OmegaIdList.data;
        mergeData.unshift({
          id: 0,
          omega_id: "",
          device_name: "None",
          lat: "",
          long: "",
        });
        setOmegaIdData(mergeData);
      } else {
        setIsLoading(false);
        var mergeData = OmegaIdList.data;
        mergeData.unshift({
          id: 0,
          omega_id: "",
          device_name: "None",
          lat: "",
          long: "",
        });
        setOmegaIdData(mergeData);
        //alert(OmegaIdList.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  //DeviceProfile Profile Add Api
  const CreateDeviceProfileData = async () => {
    console.log("projectId", projectId);
    console.log("ValveData", valveData);
    let combinedData = [];
    if (valveData) {
      const convertedData = {
        type: 1,
        index: "1",
      };
      valveData.forEach((item) => {
        convertedData[item.apiname] = item.value;
      });
      combinedData.push(convertedData);
    }
    if (pressureData) {
      const convertedData = {
        type: 2,
        index: "1",
      };
      pressureData.forEach((item) => {
        convertedData[item.apiname] = item.value;
      });
      combinedData.push(convertedData);
    }
    if (levelData) {
      const convertedData = {
        type: 2,
        index: "2",
      };
      levelData.forEach((item) => {
        convertedData[item.apiname] = item.value;
      });
      combinedData.push(convertedData);
    }
    if (waterData) {
      const convertedData = {
        type: 3,
        index: "1",
      };
      waterData.forEach((item) => {
        convertedData[item.apiname] = item.value;
      });
      combinedData.push(convertedData);
    }
    if (doorSwitchData) {
      const convertedData = {
        type: 3,
        index: "2",
      };
      doorSwitchData.forEach((item) => {
        convertedData[item.apiname] = item.value;
      });
      combinedData.push(convertedData);
    }
    if (controlData) {
      const convertedData = {
        type: 4,
        index: "1",
      };
      controlData.forEach((item) => {
        convertedData[item.apiname] = item.value;
      });
      combinedData.push(convertedData);
    }
    console.log("combinedData", combinedData);
    const deviceProfileData = {
      project_id: projectId,
      omega_id: IMEINum,
      based_on: selectedValue,
      isonline: isChecked,
      deviceDetails: combinedData,
    };
    console.log("deviceProfileData", deviceProfileData);
    console.log("datatableLabel", datatableLabel);

    setIsLoading(true);
    try {
      var DeviceProfileMasterData = JSON.stringify({
        isDeviceCreate: selectedValue == 2 ? true : false,
        modelName: "device_profile",
        inputData: {
          zone_id: ZoneValueId,
          project_id: projectId,
          assignment_type: isAssignchecked,
          sub_zone_id: SubzoneValueId,
          device_name: DeviceProfileName,
          active: isstatuschecked,
          based_on: selectedValue,
          online_check_time: selectedTime,
          lat: lat,
          long: long,
          imei_no: selectedValue == 2 ? IMEINum : undefined,
          device_profile_details: [
            {
              device_id: OmegaDeviceId,
              omega_id: selectedValue == 2 ? IMEINum : OmegavalueId,
              active: 1,
            },
          ],
          imei_static_label_data:
            selectedValue == 2 ? datatableLabel : undefined,
        },
        deviceProfileData: selectedValue == 2 ? deviceProfileData : undefined,
        relation: [{ modelName: "device_profile_details" }],
        uniqueNo: {
          field_name: "device_profile_code",
          transactionId: 6,
        },
      });
      console.log("DeviceProfile payload===", DeviceProfileMasterData);
      const FaarmerProfileCreateResponse = await postData(
        DeviceProfileMasterData,
        "masters/profileCreationAndUpdation" //profileCreateUpdate"
      );

      setIsLoading(false);

      var code = FaarmerProfileCreateResponse.code;
      if (code == 1) {
        setPopupMessage("Record Created Successfully");
        setissuccessVisible(true);
        setresponsestatus("success");
        // setIsVisible(false);

        navigation.navigate("DeviceProfile");
      } else {
        setIsLoading(false);
        setissuccessVisible(true);
        setresponsestatus("failed");
        setPopupMessage(FaarmerProfileCreateResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  const getOmegaDetails = async (deviceId) => {
    setIsLoading(true);
    try {
      var OmegaDetails = JSON.stringify({
        device_id: deviceId,
      });
      const OmegaDetailsResponse = await postData(
        OmegaDetails,
        "masters/getOmegaDetails"
      );
      setIsLoading(false);
      var code = OmegaDetailsResponse.code;
      if (code == 1) {
        setShouldShow(false);
        setDataRecord(OmegaDetailsResponse.data);
        setOmegaDetails(OmegaDetailsResponse.data[0].valves);
        setAnalogInput(OmegaDetailsResponse.data[0].analogInput);
        setDigitalInput(OmegaDetailsResponse.data[0].digitalInput);
      } else {
        setDataRecord(OmegaDetailsResponse.data);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };
  const getIMEIDetails = async () => {
    console.log("on click of this refresh IMEI name");
    if (IMEINum == "") {
      setIMEINumError("Please enter IMEI number");
      return;
    }
    setIsIMEILoading(true);
    try {
      const getIMEIData = JSON.stringify({
        module: "devices_master",
        condition: {
          based_on: 2,
          omega_id: IMEINum,
        },
      });

      // Make API call
      const IMEIResponse = await postData(getIMEIData, "masters/getMasterList");
      const code = IMEIResponse.code;
      if (code === 1) {
        if (IMEIResponse.data.length == 0) {
          setIMEINumError(`No Data Found!`);
          levelData[2].value = ''
          levelData[3].value = ''
          levelData[4].value = ''
          waterData[1].value = ''
          waterData[2].value = ''
          controlData[0].value = ''
          controlData[1].value = ''
          controlData[2].value = ''
          controlData[3].value = ''
          controlData[4].value = ''
          controlData[5].value = ''
        }
        // Success case
        // Update state or perform any necessary actions
        console.log("IMEI Name fetched successfully:", IMEIResponse.data);
        const imeiData = JSON.parse(IMEIResponse.data[0].imei_data);
        if (imeiData) {
          setOmegaDeviceiD(IMEIResponse.data[0].id);
          levelData[2].value = imeiData.lmin;
          levelData[3].value = imeiData.lmax;
          levelData[4].value = imeiData.lstat;
          waterData[1].value = imeiData.setpf;
          waterData[2].value = imeiData.setlit;
          controlData[0].value = imeiData.mnl;
          controlData[1].value = imeiData.stm;
          controlData[2].value = imeiData.mode;
          controlData[3].value = imeiData.dur;
          controlData[4].value = imeiData.setpul;
          controlData[5].value = imeiData.chsel;
        }
        setIsIMEILoading(false);
      } else {
        // Error case
        // Update state or perform any necessary actions
        setIsIMEILoading(false);
        console.error("Failed to fetch IMEI Name:", IMEIResponse.message);
        alert(IMEIResponse.message);
      }
    } catch (error) {
      // Error handling for network failures or other exceptions
      setIsIMEILoading(false);
      console.error("Error fetching IMEI Name:", error);
      //alert("Error");
    }
  };
  const getDatatableDetails = async () => {
    try {
      const labelData = JSON.stringify({
        module: "device_profile_static_imei_reports",
      });
      const LabelResponse = await postData(labelData, "masters/getMasterList");

      const code = LabelResponse.code;
      if (code === 1) {
        // Success case
        console.log("DatatableLabelResponse", LabelResponse.data);
        setDatatableLabel(LabelResponse.data);
      } else {
        // Error case
        console.error(
          "Failed to fetch DatatableLabelResponse:",
          LabelResponse.message
        );
      }
    } catch (error) {
      // Error handling for network failures or other exceptions
      console.error("Error fetching DatatableLabelResponse:", error);
      // alert("Error");
    }
  };
  const handleAssignmentTypeChange = (type) => {
    if (type === 1) {
      setSubzoneValue(undefined);
      setSubzoneValueId(undefined);
    }
    setAssignChecked(type);
  };
  return (
    <View style={styles.container}>
      <SuccessPopup
        visible={issuccessVisible}
        PopupMessage={PopupMessage}
        Responsestatus={responsestatus}
        onChange={() => setissuccessVisible(false)}
      />

      <View style={{ flexDirection: "row", width: "100%", marginTop: "0%" }}>
        {" "}
        <View style={{ flex: 4, margin: 10 }}>
          <Text style={styles.lableText}>
            Profile {">"}{" "}
            <Text onPress={() => navigation.navigate("DeviceProfile")}>
              {" "}
              Device Profile{" "}
            </Text>{" "}
            {">"} Create Device Profile
          </Text>
        </View>
        {/* <View style={{ flex: 1 }}>
          <TouchableOpacity
            style={styles.addCountry}
            onPress={() => setIsVisible(true)}
          >
            <Text style={styles.btnText}>
              <Icon name="plus" size={15} color="#fff" /> Add Users
            </Text>
          </TouchableOpacity>
        </View> */}
      </View>
      {/* <ScrollView
        style={[styles.card, styles.shadowProp]}
        contentContainerStyle={{ flexGrow: 1 }}
      > */}
      <View style={[styles.card, { flexGrow: 1 }]}>
        <View
          style={[
            styles.shadowProp,
            {
              backgroundColor: "white",
              borderRadius: 8,
              paddingVertical: 45,
              paddingHorizontal: 25,
            },
          ]}
        >
          <View
            style={{
              flex: 1,
              // justifyContent: "center",
              // alignItems: "center",
              flexDirection: "row",
            }}
          >
            <View
              style={{
                flex: 1,
                // marginRight: 20,
                // marginBottom: '14%'
              }}
            >
              <View style={isMobile ? { marginTop: "-35%" } : null}>
                <Text
                  style={isMobile ? [styles.lableText, {}] : styles.lableText}
                  numberOfLines={1}
                >
                  Based on
                </Text>
                <View
                  style={
                    isMobile
                      ? {
                        flexDirection: "row",
                        marginTop: 5,
                        alignItems: "center",
                      }
                      : {
                        flexDirection: "row",
                        marginTop: 10,
                        alignItems: "center",
                      }
                  }
                >
                  <Dropdown
                    style={
                      isMobile
                        ? {
                          borderColor: "#c3c3c3",
                          width: "200%",
                          borderWidth: 1,
                          borderRadius: 2,
                          height: 40,
                          padding: 10,
                          marginTop: 5,
                          marginBottom: 5,
                        }
                        : styles.dropdown
                    }
                    placeholderStyle={styles.placeholderStyle}
                    selectedTextStyle={styles.selectedTextStyle}
                    inputSearchStyle={styles.inputSearchStyle}
                    iconStyle={styles.iconStyle}
                    data={staticOptions}
                    search
                    maxHeight={300}
                    labelField="label"
                    valueField="value"
                    searchPlaceholder="Search..."
                    value={selectedValue}
                    onChange={(item) => {
                      setSelectedValue(item.value);
                      setOmegaIdValue("");
                      setOmegaDeviceiD("");
                      setOmegaValueiD("");
                      getOmegaDetails("");
                      setLatitude("");
                      setLongitude("");
                      setZoneValue(undefined);
                      setZoneValueId(undefined);
                      setDeviceProfileName("");
                      console.log("DeviceProfileName reset to empty string");
                      setAssignChecked(1);
                    }}
                    renderItem={renderItem}
                  />
                </View>
              </View>
              <View>
                {selectedValue === 2 && (
                  <View style={isMobile ? { marginTop: "0%" } : null}>
                    <Text
                      style={
                        isMobile
                          ? [styles.lableText, { marginTop: "3%" }]
                          : [styles.lableText]
                      }
                    >
                      IMEI No
                    </Text>
                    <View
                      style={
                        isMobile
                          ? { flexDirection: "row", width: "115%" }
                          : { flexDirection: "row" }
                      }
                    >
                      <TextInput
                        style={
                          isMobile
                            ? {
                              flex: 1,
                              borderColor: "#c3c3c3",
                              //width: "100%",
                              ...(isMobile ? {} : { width: "100%" }),
                              borderWidth: 1,
                              borderRadius: 4,
                              padding: 10,
                              marginTop: 4,
                              marginBottom: 6,
                            }
                            : styles.input
                        }
                        placeholder="Enter IMEI number"
                        onChangeText={(value) => {
                          setIMEINum(value);
                          if (value.length == 0) {
                            setIMEINumError("Please enter IMEI number");
                          } else {
                            setIMEINumError("");
                          }
                        }}
                      />
                      <View style={{ marginLeft: 0, padding: 10 }}>
                        {isIMEILoading ? (
                          <ActivityIndicator
                            color="#1DA1F2"
                            visible={isLoading}
                            //Text with the Spinner
                            textContent={"Loading..."}
                            size={40}
                          />
                        ) : (
                          <IconRefresh
                            name="refresh"
                            size={30}
                            onPress={() => getIMEIDetails()}
                          />
                        )}
                      </View>
                    </View>
                    <Text style={[styles.error_text]}>{IMEINumError}</Text>
                  </View>
                )}
                {selectedValue === 1 && (
                  <View style={isMobile ? { marginTop: "0%" } : null}>
                    <Text
                      style={
                        isMobile
                          ? [styles.lableText, { marginTop: "3%" }]
                          : [styles.lableText]
                      }
                    >
                      Omega name
                    </Text>
                    <Dropdown
                      ref={dropdownRefOmegaiD}
                      style={
                        isMobile
                          ? {
                            borderColor: "#c3c3c3",
                            width: "200%",
                            borderWidth: 1,
                            borderRadius: 2,
                            height: 40,
                            padding: 10,
                            marginTop: 5,
                            marginBottom: 5,
                          }
                          : styles.dropdown
                      }
                      placeholderStyle={styles.placeholderStyle}
                      selectedTextStyle={styles.selectedTextStyle}
                      inputSearchStyle={styles.inputSearchStyle}
                      iconStyle={styles.iconStyle}
                      data={OmegaIdData}
                      search
                      maxHeight={300}
                      labelField="device_name"
                      valueField="device_name"
                      placeholder={!isFocus ? "Select Omega name" : "...."}
                      searchPlaceholder="Search..."
                      // value={OmegaIdValue}
                      value={OmegaIdValue}
                      onChange={(item) => {
                        setOmegaIdValue(item.device_name);
                        setOmegaDeviceiD(item.id);
                        setOmegaValueiD(item.omega_id);
                        getOmegaDetails(item.id);
                        setLatitude(item.lat);
                        setLongitude(item.long);
                        setOmegaError("");
                        // console.log("omega id value---", item.id);
                      }}
                      renderItem={renderOmegaIdListItems}
                    />
                    <Text style={[styles.error_text]}>{OmegaError}</Text>
                  </View>
                )}
                <View
                  style={
                    isMobile ? { marginTop: isAssignchecked ? 245 : 165 } : null
                  }
                >
                  <Text style={styles.lableText}>Location </Text>
                  <View
                    style={
                      isMobile
                        ? { flexDirection: "row", width: "200%" }
                        : { flexDirection: "row" }
                    }
                  >
                    <TextInput
                      style={
                        isMobile
                          ? {
                            // borderColor: "#c3c3c3",
                            width: "40%",
                            borderWidth: 1,
                            borderRadius: 4,
                            marginRight: 0,
                            padding: 10,
                            marginTop: 4,
                            marginBottom: 6,
                            // backgroundColor: "#DEDEDE",
                          }
                          : [
                            styles.inputLocation,
                            // { backgroundColor: "#DEDEDE" },
                          ]
                      }
                      onChangeText={(value: any) => {
                        setLat(value);
                      }}
                      placeholder="Lat"
                      maxLength={10}
                      editable={true}
                      keyboardType={"numeric"}
                      value={lat}
                    />
                    <TextInput
                      style={
                        isMobile
                          ? {
                            // borderColor: "#c3c3c3",
                            width: "40%",
                            borderWidth: 1,
                            borderRadius: 4,
                            marginRight: 5,
                            padding: 10,
                            marginTop: 4,
                            marginBottom: 6,
                            marginLeft: "10%",
                            // backgroundColor: "#DEDEDE",
                          }
                          : [
                            styles.inputLocation,
                            // { backgroundColor: "#DEDEDE" },
                          ]
                      }
                      placeholder="Long"
                      maxLength={10}
                      keyboardType={"numeric"}
                      editable={true}
                      keyboardType={"numeric"}
                      value={long}
                      onChangeText={(value: any) => {
                        setLong(value);
                      }}
                    />
                    <IconLoc
                      name="my-location"
                      size={20}
                      style={
                        isMobile
                          ? { margin: 10, marginRight: -100 }
                          : { margin: 10 }
                      }
                    />
                  </View>
                </View>
                {/* {isAssignchecked === 1 && (
                  <View style={isMobile ? { marginTop: "100%" } : null}>
                    <Text style={styles.lableText}>Zone </Text>
                    <View>
                      <Dropdown
                        ref={dropdownRefZone}
                        style={isMobile ? {
                          borderColor: "#c3c3c3",
                          width: "200%",
                          borderWidth: 1,
                          borderRadius: 4,
                          padding: 10,
                          marginTop: 4,
                          marginBottom: -7,
                          height:40
                        } : styles.dropdown}
                        placeholderStyle={styles.placeholderStyle}
                        selectedTextStyle={styles.selectedTextStyle}
                        inputSearchStyle={styles.inputSearchStyle}
                        iconStyle={styles.iconStyle}
                        data={ZoneNameData}
                        search
                        maxHeight={300}
                        labelField="zone_name"
                        valueField="zone_name"
                        placeholder={!isFocus ? "Select Zone Id" : "...."}
                        searchPlaceholder="Search..."
                        value={ZoneValue}
                        onChange={(item) => {
                          setZoneIdValid(true)
                          setZoneValue(item.zone_name);
                          setZoneValueId(item.id);
                          getDependentZoneMaster(item.id);
                          setZoneIdError("");
                          // console.log("zone id value---", item.id);
                        }}
                        renderItem={renderZoneIdListItems}
                      />
                      <Text style={[styles.error_text]}>{ZoneIdError}</Text>
                    </View>
                  </View>
                 )} */}
                {selectedValue === 2 && (
                  <View style={isMobile ? { marginTop: "0%" } : null}>

                    <View style={isMobile ? { marginTop: "0%" } : null}>
                      <Text style={
                        isMobile
                          ? [styles.lableText, { marginTop: "3%" }]
                          : [styles.lableText]
                      }>Online Check Time</Text>
                      <View
                        style={
                          isMobile
                            ? { flexDirection: "row", width: "115%" }
                            : { flexDirection: "row" }
                        }
                      >
                        <TextInput
                          style={
                            isMobile
                              ? {
                                flex: 1,
                                borderColor: "#c3c3c3",
                                //width: "100%",
                                ...(isMobile ? {} : { width: "100%" }),
                                borderWidth: 1,
                                borderRadius: 4,
                                padding: 10,
                                marginTop: 4,
                                marginBottom: 6,
                              }
                              : styles.input
                          }
                          placeholder="Enter Online Check Time"
                          onChangeText={(value) => {
                            setSelectedTime(value);
                            setOnlineCheckTimeError(true)
                          }}
                        />
                        <Text style={[styles.error_text]}>{onlineCheckTime}</Text>
                      </View>
                    </View>
                  </View>
                )}

              </View>

              {selectedValue === 1 && (
                <View>
                  <Text
                    style={
                      isMobile
                        ? [styles.lableText, { marginTop: 0 }]
                        : [styles.lableText]
                    }
                  >
                    Status{" "}
                  </Text>
                  <View style={{ flexDirection: "row" }}>
                    <Text style={[styles.statusNames, { color: "#05D6B3" }]}>
                      Active
                    </Text>
                    <RadioButton
                      value="1"
                      status={isstatuschecked === 1 ? "checked" : "unchecked"}
                      onPress={() => setStatusChecked(1)}
                    />
                    <Text style={[styles.statusNames, { color: "#E11A1A" }]}>
                      InActive
                    </Text>
                    <RadioButton
                      value="0"
                      status={isstatuschecked === 0 ? "checked" : "unchecked"}
                      onPress={() => setStatusChecked(0)}
                    />{" "}
                  </View>
                </View>
              )}
            </View>
            <View
              style={
                isMobile
                  ? { flex: 1, marginTop: 110, marginBottom: "auto", height: 0 }
                  : {
                    flex: 1,
                    // marginBottom: "19%",
                  }
              }
            >
              <View style={{ flex: 1 }}>
                {/* <Text style={styles.lableText}>DeviceProfile Id</Text>
                <TextInput
                  style={styles.input}
                  placeholder="Enter DeviceProfile id"
                  value={DeviceProfileID}
                  onChangeText={(text) => { setDeviceProfileID(text), setDeviceProfileIdError("") }}
                />
                <Text style={[styles.error_text]}>{DeviceProfileIdError}</Text> */}
                <View style={isMobile ? { marginLeft: "-100%" } : null}>
                  <Text
                    style={
                      isMobile
                        ? [styles.lableText, { marginTop: 5 }]
                        : styles.lableText
                    }
                    numberOfLines={1}
                  >
                    {" "}
                    Device Profile Name
                  </Text>
                  <TextInput
                    style={
                      isMobile
                        ? {
                          borderColor: "#c3c3c3",
                          width: "100%",
                          borderWidth: 1,
                          borderRadius: 4,
                          padding: 10,
                          marginTop: 4,
                          marginBottom: -7,
                        }
                        : styles.input
                    }
                    placeholder="Enter Device Name"
                    value={DeviceProfileName}
                    onChangeText={(value) => {
                      setDeviceProfileNameValid(false);
                      setDeviceProfileName(value);
                      if (value.length == 0) {
                        setDeviceProfileNameError("Please enter Device name");
                      } else if (!alpha_pattern.test(value)) {
                        setDeviceProfileNameError(
                          "Please enter valid character only"
                        );
                      } else {
                        setDeviceProfileNameValid(true);
                        setDeviceProfileNameError("");
                      }
                    }}
                  />
                  <Text style={[styles.error_text]}>
                    {DeviceProfileNameError}
                  </Text>
                </View>

                <View style={isMobile ? { marginLeft: "-100%" } : null}>
                  <Text
                    style={
                      isMobile
                        ? {
                          color: "#484848",
                          fontWeight: "700",
                          marginTop: 1,
                          marginLeft: 1,
                        }
                        : styles.lableText
                    }
                  >
                    Assignment Type{" "}
                  </Text>
                  <View
                    style={
                      isMobile
                        ? { flexDirection: "row", marginTop: 5 }
                        : { flexDirection: "row", marginTop: 10 }
                    }
                  >
                    <Text style={[styles.statusNames, { color: "#05D6B3" }]}>
                      Zone
                    </Text>
                    <RadioButton
                      value="1"
                      status={isAssignchecked === 1 ? "checked" : "unchecked"}
                      onPress={() => handleAssignmentTypeChange(1)}
                    />
                    <Text style={[styles.statusNames, { color: "#E11A1A" }]}>
                      SubZone
                    </Text>
                    <RadioButton
                      value="2"
                      status={isAssignchecked === 2 ? "checked" : "unchecked"}
                      onPress={() => handleAssignmentTypeChange(2)}
                    />{" "}
                  </View>
                </View>
                
                  <View style={isMobile ? { marginLeft: "-100%" } : null}>
                    <Text
                      style={
                        isMobile
                          ? [styles.lableText, { marginTop: "-1%" }]
                          : styles.lableText
                      }
                    >
                      Zone{" "}
                    </Text>
                    <View>
                      <Dropdown
                        ref={dropdownRefZone}
                        style={
                          isMobile
                            ? {
                              borderColor: "#c3c3c3",
                              width: "100%",
                              borderWidth: 1,
                              borderRadius: 2,
                              height: 40,
                              padding: 10,
                              marginTop: 5,
                              marginBottom: 5,
                            }
                            : styles.dropdown
                        }
                        placeholderStyle={styles.placeholderStyle}
                        selectedTextStyle={styles.selectedTextStyle}
                        inputSearchStyle={styles.inputSearchStyle}
                        iconStyle={styles.iconStyle}
                        data={ZoneNameData}
                        search
                        maxHeight={300}
                        labelField="zone_name"
                        valueField="zone_name"
                        placeholder={!isFocus ? "Select Zone Id" : "...."}
                        searchPlaceholder="Search..."
                        value={ZoneValue}
                        onChange={(item) => {
                          setZoneIdValid(true);
                          setZoneValue(item.zone_name);
                          setZoneValueId(item.id);
                          getDependentZoneMaster(item.id);
                          setZoneIdError("");
                          // console.log("zone id value---", item.id);
                        }}
                        renderItem={renderZoneIdListItems}
                      />
                      <Text style={[styles.error_text]}>{ZoneIdError}</Text>
                    </View>
                  </View>
                

                {isAssignchecked === 2 && (
                  <View style={isMobile ? { marginLeft: "-100%" } : null}>
                    <Text
                      style={
                        isMobile
                          ? [styles.lableText, { marginTop: "-1%" }]
                          : styles.lableText
                      }
                    >
                      Subzone{" "}
                    </Text>
                    <View>
                      <Dropdown
                        ref={dropdownRefZone}
                        style={
                          isMobile
                            ? {
                              borderColor: "#c3c3c3",
                              width: "100%",
                              borderWidth: 1,
                              borderRadius: 2,
                              height: 40,
                              padding: 10,
                              marginTop: 5,
                              marginBottom: 5,
                            }
                            : styles.dropdown
                        }
                        placeholderStyle={styles.placeholderStyle}
                        selectedTextStyle={styles.selectedTextStyle}
                        inputSearchStyle={styles.inputSearchStyle}
                        iconStyle={styles.iconStyle}
                        data={subzoneData}
                        search
                        maxHeight={300}
                        labelField="sub_zone_name"
                        valueField="sub_zone_name"
                        placeholder={!isFocus ? "Select subzone " : "...."}
                        searchPlaceholder="Search..."
                        value={ZoneValue}
                        onChange={(item) => {
                          setSubZoneIDValid(true);
                          setSubzoneValue(item.sub_zone_name);
                          setSubzoneValueId(item.id);
                          setSubZoneIdError("");
                        }}
                        renderItem={renderSubZoneIdListItems}
                      />
                      <Text style={[styles.error_text]}>{SubZoneIdError}</Text>
                    </View>
                  </View>
                )}
              </View>
            </View>
          </View>
          {shouldShow ? null : (
            <View
              style={
                isMobile
                  ? { marginTop: 20, width: "100%", alignSelf: "center" }
                  : { marginTop: 20, width: "41%", alignSelf: "center" }
              }
            >
              {DataRecord.length > 0 && (
                <TabView
                  navigationState={{ index, routes }}
                  renderScene={renderScene}
                  onIndexChange={setIndex}
                  pressColor="#b3bb"
                  activeColor={"red"}
                  inactiveColor={"white"}
                  initialLayout={{ width: layout.width }}
                  // renderLabel={({ route, color }) => (
                  //   <Text style={{ color: 'black', margin: 8 }}>
                  //     {route.title}
                  //   </Text>
                  // )}
                  renderTabBar={(props) => (
                    <TabBar
                      {...props}
                      indicatorStyle={{ backgroundColor: "yellow", height: 5 }}
                      renderLabel={({ route, color, focused }) => (
                        // <Text style={{ color: "white", margin: 8 }}>
                        //   {route.title}
                        // </Text>
                        <Text
                          style={
                            isMobile
                              ? { color: "white", marginLeft: 10 }
                              : { color: "white", margin: 8 }
                          }
                        >
                          {route.title}
                        </Text>
                      )}
                      style={{ backgroundColor: "#2c7bbe" }}
                    />
                  )}

                // <-- add this line
                />
              )}
            </View>
          )}
          {selectedValue === 2 && (
            <View
              style={
                isMobile
                  ? {
                    marginTop: 20,
                    width: "100%",
                    alignSelf: "center",
                  }
                  : {
                    marginTop: 20,
                    width: "50%",
                    alignSelf: "center",
                  }
              }
            >
              {/* {dataIMEI.length > 0 && */}
              <TabView
                navigationState={{ index: imeiIndex, routes: imeiroutes }}
                renderScene={imeiRenderScene}
                onIndexChange={setIMEIIndex}
                pressColor="#b3bb"
                activeColor={"red"}
                inactiveColor={"white"}
                initialLayout={{ width: layout.width }}
                // renderLabel={({ route, color }) => (
                //   <Text style={{ color: 'black', margin: 8 }}>
                //     {route.title}
                //   </Text>
                // )}
                renderTabBar={(props) => (
                  <TabBar
                    {...props}
                    indicatorStyle={{ backgroundColor: "yellow", height: 5 }}
                    renderLabel={({ route, color, focused }) => (
                      // <Text style={{ color: "white", margin: 8 }}>
                      //   {route.title}
                      // </Text>
                      <Text
                        style={
                          isMobile
                            ? { color: "white", marginLeft: 10 }
                            : { color: "white", margin: 8 }
                        }
                      >
                        {route.title}
                      </Text>
                    )}
                    style={{ backgroundColor: "#2c7bbe" }}
                  />
                )}

              // <-- add this line
              />
              {/* } */}
            </View>
          )}
          {selectedValue === 2 && (
            <View
              style={
                isMobile
                  ? {
                    marginTop: 20,
                    width: "100%",
                    alignSelf: "center",
                    justifyContent: "center",
                    marginRight: -100,
                  }
                  : {
                    marginTop: 20,
                    width: "70%",
                    alignSelf: "center",
                    marginLeft: 260,
                  }
              }
            >
              <View
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#F6F9F9",
                  width: "70%", // Adjusted to fit the entire width
                  marginBottom: 30,
                }}
              >
                <View style={[styles.card, { flexGrow: 1 }]}>
                  <View
                    style={[
                      styles.shadowProp,
                      {
                        backgroundColor: "white",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%", // Adjusted to fit the entire width
                      },
                    ]}
                  >
                    <View
                      style={{
                        width: "100%", // Adjusted to fit the width of the card
                        backgroundColor: "#F6F9F9",
                        borderWidth: 1,
                        borderColor: "#e3e0e0",
                      }}
                    >
                      <DataTable
                        columns={columnstableName}
                        data={datatableLabel}
                        customStyles={customStyles}
                        pagination
                        paginationPerPage={5}
                        paginationRowsPerPageOptions={[5, 10, 15, 20, 25]}
                      />
                    </View>
                  </View>
                </View>
              </View>
            </View>
          )}
          <View
            style={
              isMobile
                ? {
                  flexDirection: "row",
                  justifyContent: "center",
                  marginHorizontal: 20,
                  marginTop: "10%",
                  marginBottom: "-12%",
                }
                : {
                  flexDirection: "row",
                  justifyContent: "center",
                  marginHorizontal: 20,
                }
            }
          >
            <TouchableOpacity
              style={
                isMobile
                  ? {
                    alignItems: "center",
                    backgroundColor: "#006EB9",
                    padding: 10,
                    borderWidth: 1,
                    borderColor: "#006EB9",
                    width: "30%",
                    marginRight: 10,
                  }
                  : styles.saveBtn
              }
              onPress={() => CheckEnteredData()}
            >
              <Text style={styles.btnText}>Save</Text>
            </TouchableOpacity>

            <TouchableOpacity
              style={
                isMobile
                  ? {
                    alignItems: "center",
                    backgroundColor: "#006EB9",
                    padding: 10,
                    borderWidth: 1,
                    borderColor: "#006EB9",
                    width: "30%",
                    marginRight: 10,
                  }
                  : styles.saveBtn
              }
              onPress={() => navigation.navigate("DeviceProfile")}
            >
              <Text style={styles.btnText}>Cancel</Text>
            </TouchableOpacity>
          </View>

          {/* <View style={{ marginTop: 20 }}>
          <TabView
            navigationState={{ index, routes }}
            renderScene={renderScene}
            onIndexChange={setIndex}
            initialLayout={{ width: layout.width }}
          />
        </View> */}
          {/* </ScrollView> */}
        </View>
      </View>
    </View>
  );
}

// DATATABLE PROJECT TYPE COLUMN AND STYLE
const customStyles = {
  rows: {
    style: {
      // maxWidth: 50,
      minHeight: "30px", // override the row height
    },
  },
  headRow: {
    style: {
      borderTopStyle: "solid",
      borderTopWidth: "1px",
      backgroundColor: "#319ef0a3",
      minHeight: "40px",
      fontSize: 16,
      fontWeight: "600",
    },
  },
  headCells: {
    style: {
      // paddingLeft: '8px', // override the cell padding for head cells
      // paddingRight: '8px',
      justifyContent: "center",
      minHeight: "20px",

      // borderRightWidth: 2,
      "&:not(:last-of-type)": {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        // fontSize: 16,
        // fontWeight: '600',
        // borderRightColor: defaultThemes.default.divider.default,
      },
    },
  },
  cells: {
    style: {
      paddingLeft: "8px", // override the cell padding for data cells
      paddingRight: "8px",
      justifyContent: "center",

      "&:not(:last-of-type)": {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        fontSize: 16,
        fontWeight: "400",
        // borderRightColor: defaultThemes.default.divider.default,
      },
    },
  },
};
const columns = [
  // {
  //   name: "Sr.No",
  //   selector: "id",
  //   sortable: true
  // },
  {
    name: "Output number",
    // selector: row => !editInput ? row.project_name : <TextInput value='helllo'></TextInput>,
    selector: (row) => row.value,
    sortable: true,
  },
  {
    name: "Output Name",
    selector: (row) => row.name,
    sortable: true,
  },
  // {
  //   name: "Description",
  //   selector: (row) => row.description != " " && row.description != null ? row.description : "-",
  //   sortable: true,
  // },
];
const columnsAnalog = [
  // {
  //   name: "Sr.No",
  //   selector: "id",
  //   sortable: true
  // },
  {
    name: "Output number",
    // selector: row => !editInput ? row.project_name : <TextInput value='helllo'></TextInput>,
    selector: (row) => row.value,
    sortable: true,
  },
  {
    name: "Output Name",
    selector: (row) => row.name,
    sortable: true,
  },
];

const columnDigitalInput = [
  // {
  //   name: "Sr.No",
  //   selector: "id",
  //   sortable: true
  // },
  {
    name: "Output number",
    // selector: row => !editInput ? row.project_name : <TextInput value='helllo'></TextInput>,
    selector: (row) => row.value,
    sortable: true,
  },
  {
    name: "Output Name",
    selector: (row) => row.name,
    sortable: true,
  },
];

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    // marginTop: 150,
  },
  genderNames: {
    margin: 7,
  },
  statusNames: {
    margin: 7,
  },
  containeralt: {
    // alignItems: "center",
    backgroundColor: "white",
    borderColor: "#eee",
    borderRadius: 10,
    borderWidth: 1,
    // justifyContent: "center",
    height: "100%",
    marginRight: "10%",
    marginTop: "0%",
    margin: "auto",
    padding: 30,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    // marginRight: 10,
    // marginRight: 1,
    width: "70%",
  },
  inputLocation: {
    borderColor: "#c3c3c3",
    width: "33%",
    borderWidth: 1,
    borderRadius: 4,
    marginRight: 5,
    padding: 10,
    marginTop: 4,
    marginBottom: 6,
  },
  input: {
    borderColor: "#c3c3c3",
    width: "70%",
    borderWidth: 1,
    borderRadius: 4,

    padding: 10,
    marginTop: 4,
    marginBottom: 6,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  addCountry: {
    alignItems: "center",
    backgroundColor: "#006EB9",
    padding: 10,
    borderWidth: 1,
    borderColor: "#006EB9",
    width: "70%",
    // marginTop: 20,
  },
  saveBtn: {
    alignItems: "center",
    backgroundColor: "#006EB9",
    padding: 10,
    borderWidth: 1,
    borderColor: "#006EB9",
    width: "15%",
    marginRight: 10,
  },
  btnText: {
    color: "#fff",
    fontWeight: "600",
  },
  datePicker: {
    justifyContent: "center",
    alignItems: "flex-start",
    width: 320,
    height: 260,
    display: "flex",
  },
  card: {
    // backgroundColor: "white",
    // borderRadius: 8,
    // paddingVertical: 45,
    // paddingHorizontal: 25,
    // width: "100%",
    // flex: 1,
    // marginVertical: 10,
    // paddingBottom: "48%",

    width: "100%",
    flex: 1,
    marginVertical: 0,
  },
  shadowProp: {
    shadowColor: "#171717",
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 25,
  },

  link: {
    marginTop: 15,
    paddingVertical: 15,
  },
  error_text: {
    color: "red",
    fontSize: 14,
    fontWeight: "600",
    width: "70%",
    marginLeft: 0,
    marginBottom: 10,
  },
  linkText: {
    fontSize: 14,
    color: "#2e78b7",
  },

  // DropDown CSS
  dropdown: {
    borderColor: "#c3c3c3",
    width: "70%",
    borderWidth: 1,
    borderRadius: 2,
    height: 40,

    padding: 10,
    marginTop: 5,
    marginBottom: 5,
    // borderRadius: 10,
    // padding: 12,
    // shadowColor: "#000",
    // shadowOffset: {
    //   width: 0,
    //   height: 1,
    // },
    // shadowOpacity: 0.2,
    // shadowRadius: 1.41,

    // elevation: 2,
  },
  icon: {
    marginRight: 5,
  },
  item: {
    padding: 9,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  textItem: {
    flex: 1,
    fontSize: 16,
  },
  placeholderStyle: {
    fontSize: 16,
  },
  selectedTextStyle: {
    fontSize: 16,
  },
  iconStyle: {
    width: 20,
    height: 20,
  },
  inputSearchStyle: {
    height: 40,
    fontSize: 16,
    outlineStyle: "none",
  },
  lableText: {
    color: "#484848",
    fontWeight: "700",
    marginTop: 18,
    // fontSize: 21,
  },
});
