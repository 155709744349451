const projectCodes = (state = true, action) => {

    switch (action.type) {

        case "CODEPROJECT":

            return !state

        default:

            return state

    }

}

export default projectCodes