import Header from "../navigation/header";
import {
  StyleSheet,
  TouchableOpacity,
  Modal,
  ScrollView,
  TextInput as TextInputRN,
  Button,
  Switch,
} from "react-native-web";
import { useFocusEffect } from "@react-navigation/native";

import { Dropdown } from "react-native-element-dropdown";
import { Text, View } from "../../../components/Themed";
import { RootStackScreenProps } from "../../../types"; // @ts-ignore
import Icon from "react-native-vector-icons/AntDesign";
import React, { useEffect, useState, useMemo, useRef } from "react";
import IconCross from "react-native-vector-icons/Entypo";
import { postData } from "../../../networking/api";
// import { DataTable } from "react-native-paper";
import { Provider as PaperProvider } from "react-native-paper";
import IconEdit from "react-native-vector-icons/FontAwesome";
import IconEye from "react-native-vector-icons/Entypo";
import { TextInput } from "react-native-paper";
import DataTable from "react-data-table-component";
import SuccessPopup from "../../../components/SuccessPopup";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { Dimensions } from "react-native";

export default function City() {
  var [counter, setCounter] = useState(1);

  const [isVisible, setIsVisible] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const [isAdd, setisAdd] = useState(false);
  const [error, setError] = useState("");
  const textCityName = useRef("");
  const [cityLength, setCityLength] = useState(0);
  const [stateData, setStateData] = useState([]);
  const [CountryName, setCountryName] = useState([]);

  const [CityNameData, setCityNameData] = useState([]);
  const [cityNameListExport, setCityNameListExport] = useState([]);

  const [isFocus, setIsFocus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [countryValue, setCountryValue] = useState("");
  const [countryValId, setCountryValId] = useState("");
  const [stateValue, setStateValue] = useState("");
  const [stateValId, setStateValId] = useState("");
  const [CityValue, setCityValue] = useState("");
  const [cityValId, setCityValId] = useState();

  const [searchData, setSearch] = useState("");
  const [filterData, setFilteredDataSource] = useState([]);

  const [countryError, setcountryError] = useState("");
  const [stateError, setstateError] = useState("");
  const [cityError, setcityError] = useState("");
  const [sort, setsort] = useState(0);
  const [editpopup, setEditPopup] = useState(false);
  const [popid, setpopid] = useState("");
  const [popindex, setpopindex] = useState("");

  const [issuccessVisible, setissuccessVisible] = useState(false);
  const [responsestatus, setresponsestatus] = useState("");
  const [PopupMessage, setPopupMessage] = useState("");

  var alpha_pattern = new RegExp(/^[a-zA-Z]+(\s[a-zA-Z]+)?$/);

  const dropdownRefcountry = useRef();
  const dropdownRefState = useRef();
  const closeMenu = () => {
    dropdownRefcountry.current.close();
    dropdownRefState.current.close();
  };
  useEffect(() => {
    window.addEventListener("scroll", closeMenu);
    return () => window.removeEventListener("scroll", closeMenu);
  }, []);

  //Pagination
  const optionsPerPage = [10, 25, 50, 100];

  const [page, setPage] = React.useState(0);
  const [itemsPerPage, setItemsPerPage] = React.useState(optionsPerPage[0]);
  const from = page * itemsPerPage;
  const to = Math.min((page + 1) * itemsPerPage, cityLength);
  React.useEffect(() => {
    setPage(0);
    setpopindex("");
    setEditPopup(false);
    setpopid("");
  }, [itemsPerPage]);

  const isCreatable = () => {
    setIsVisible(true);
    setisAdd(true);
    setCountryValue("");
    setStateValue("");
    setCityValue("");
  };
  //Datatable city column and styles
  const customStyles = {
    rows: {
      style: {
        // maxWidth: 50,
        minHeight: "30px", // override the row height
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        backgroundColor: "#319ef0a3",
        minHeight: "40px",
        fontSize: 16,
        fontWeight: "600",
      },
    },
    headCells: {
      style: {
        // paddingLeft: '8px', // override the cell padding for head cells
        // paddingRight: '8px',
        justifyContent: "center",
        minHeight: "20px",

        // borderRightWidth: 2,
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          // fontSize: 16,
          // fontWeight: '600',
          // borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
        justifyContent: "center",

        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          fontSize: 16,
          fontWeight: "400",
          textTransform: "Capitalize",
          // borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };
  const caseInsensitiveSortCity = (rowA, rowB) => {
    const a = rowA.city.toLowerCase();
    const b = rowB.city.toLowerCase();

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };
  const caseInsensitiveSortState = (rowA, rowB) => {
    const a = rowA.state_master.state.toLowerCase();
    const b = rowB.state_master.state.toLowerCase();

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };
  const caseInsensitiveSortCountry = (rowA, rowB) => {
    const a = rowA.country_master.country.toLowerCase();
    const b = rowB.country_master.country.toLowerCase();

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };
  const columns = [
    {
      name: "Sr.No",
      selector: (row) => row.index,
      // //cell: (row, index) => index + 1,

      // sortable: true,
      width: "100px",
    },
    {
      name: "Country",
      selector: (row) => row.country_master.country,
      sortable: true,
      sortFunction: caseInsensitiveSortCountry,
    },
    {
      name: "State",
      selector: (row) => row.state_master.state,
      sortable: true,
      sortFunction: caseInsensitiveSortState,
    },
    {
      name: "City",
      selector: (row) => row.city,
      sortable: true,
      sortFunction: caseInsensitiveSortCity,
    },
    {
      name: "Action",
      // selector: "country",
      width: "100px",
      // sortable: true
      cell: (item) => (
        <TouchableOpacity
          onPress={() =>
            isAddable(
              item.id,
              item.country_id,
              item.country_master.country,
              item.state_id,
              item.state_master.state,
              item.city
            )
          }
        >
          <IconEdit
            name="pencil"
            size={13}
            color="#fff"
            style={{
              padding: 6,
              backgroundColor: "#08588E",
              borderRadius: 12,
            }}
          />
        </TouchableOpacity>
      ),
    },
    {
      name: "Status",
      width: "100px",
      cell: (item, index) => (
        <Switch
          trackColor={{ false: "#767577", true: "#1DA1F2" }}
          thumbColor={item.active == 1 ? "#fff" : "#f4f3f4"}
          activeThumbColor="#fff"
          onValueChange={() => {
            // console.log("toggle index", index);
            setEditPopup(true);
            setpopid(item.id);
            setpopindex(index);
          }}
          value={item.active == 1 ? true : false}
        />
      ),
    },
  ];

  const toggleSwitch = () => {
    var tempIndex = CityNameData.findIndex((item) => item.id === popid);
    if (CityNameData[tempIndex].active == 1) {
      CityNameData[tempIndex].active = 0;
      activeCity(popid, 0);
    } else {
      CityNameData[tempIndex].active = 1;
      activeCity(popid, 1);
    }
    setCityNameData(CityNameData);
  };
  const isAddable = (
    cityValId,
    country_id,
    country_name,
    state_id,
    state_name,
    city_name
  ) => {
    setIsVisible(true);
    setisAdd(false);

    //*set name to update */

    setCountryValue(country_name);
    setStateValue(state_name);
    setCityValue(city_name);
    getDependentCountryMaster(country_id);
    //set id to update
    setCountryValId(country_id);
    setStateValId(state_id);
    setCityValId(cityValId);
  };

  useFocusEffect(
    React.useCallback(() => {
      getCities([]);
      getCityListForExport()
      getCountry();
    }, [])
  );

  const textToCityName = (text) => {
    textCityName.current = text.nativeEvent.text;
    setCityValue(text.nativeEvent.text);
    if (text.nativeEvent.text == "") {
      setcityError("Please enter state");
    } else {
      setcityError("");
    }
  };

  const showDataCityName = () => {
    return textCityName.current;
  };
  const searchFilterFunction = (text) => {
    // Check if searched text is not blank
    if (text) {
      // Inserted text is not blank
      // Filter the masterDataSource
      // Update FilteredDataSource
      const newData = CityNameData.filter(function (item) {
        const itemData = item.city ? item.city.toUpperCase() : "".toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      // ("new data search---->", newData);
      setFilteredDataSource(newData);
      setSearch(text);
      setPage(0);
    } else {
      // Inserted text is blank
      // Update FilteredDataSource with masterDataSource
      setFilteredDataSource(CityNameData);
      setSearch(text);
      setPage(0);
    }
  };
  const CheckEnteredData = async () => {
    var CityNameDatas =
      showDataCityName() !== "" ? showDataCityName() : CityValue;
    //setUserType(userTypes);

    if (countryValue == "") {
      setcountryError("Please enter country");
    }
    if (stateValue == "") {
      setstateError("Please enter state");
    }
    if (CityValue == "") {
      setcityError("Please enter city");
    }

    if (CityNameDatas == "" && countryValue == "" && stateValue == "") {
      // console.log("CityNameDatas", CityNameDatas);
      // console.log("countryValue", countryValue);
      // console.log("stateValue", stateValue);
      // //alert("pls enter");
      // setError("Please enter required field Name");
      // console.log("empty");
      // setIsVisible(false);
    } else if (alpha_pattern.test(CityNameDatas)) {
      {
        isAdd == true && createCities(CityNameDatas);
        setIsVisible(false);
      }

      {
        isAdd == false && updateCities(CityNameDatas);
        setIsVisible(false);
      }
    } else {
      // console.log("falseśśś");
      // setError("Please enter Character Only.");
      // //alert("only character:", CityNameDatas);
      // setIsVisible(false);
    }

    // navigation.replace('DrawerNavigation');
  };

  //City Add Api
  const createCities = async (CityNames) => {
    // console.log("CityNames", CityNames);
    setIsLoading(true);
    try {
      var cityData = JSON.stringify({
        module: "city_master",
        data: {
          country_id: countryValId,
          state_id: stateValId,
          city: CityNames,
          active: 1,
        },
      });
      // console.log("payload city", cityData);
      const CityNameDataResponse = await postData(
        cityData,
        "masters/masterCreateUpdate"
      );

      setIsLoading(false);

      var code = CityNameDataResponse.code;
      if (code == 1) {
        setPopupMessage("Record Created Successfully");
        setissuccessVisible(true);
        setresponsestatus("success");
        setIsVisible(false);
        getCities([]);
        getCityListForExport();
        setCityValue("");
        textCityName.current = "";
        // console.log("Added Cities", CityNameDataResponse.data);
      } else {
        setIsLoading(false);
        setissuccessVisible(true);
        setresponsestatus("failed");
        setPopupMessage(CityNameDataResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };
  const updateCities = async (CityNames) => {
    // console.log("CityNames", CityNames);
    setIsLoading(true);
    try {
      var cityData = JSON.stringify({
        id: cityValId,
        module: "city_master",
        data: {
          country_id: countryValId,
          state_id: stateValId,
          city: CityNames,
          active: 1,
        },
      });
      // console.log("payload city", cityData);

      const CityNameResponseUpdateData = await postData(
        cityData,
        "masters/masterCreateUpdate"
      );

      setIsLoading(false);

      var code = CityNameResponseUpdateData.code;
      if (code == 1) {
        setPopupMessage("Record Updated Successfully");
        setissuccessVisible(true);
        setresponsestatus("success");
        setIsVisible(false);
        getCities([]);
        getCityListForExport();
        setCityValue("");
        textCityName.current = "";
        // console.log("City Updated", CityNameResponseUpdateData.data);
      } else {
        setIsLoading(false);
        setissuccessVisible(true);
        setresponsestatus("failed");
        setPopupMessage(CityNameResponseUpdateData.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };
  // Get dependent states by country id
  const getDependentCountryMaster = async (country_id) => {
    // console.log("country_id masters", country_id);
    setIsLoading(true);

    try {
      var dependentData = JSON.stringify({
        column: "country_id",
        value: country_id,
        module: "state_master",
      });
      const dependentCountryDataResponse = await postData(
        dependentData,
        "masters/getDependentMasterList"
      );
      setIsLoading(false);
      var code = dependentCountryDataResponse.code;
      if (code == 1) {

        // setCountryLength(dependentCountryDataResponse.data.length);
        setStateData(dependentCountryDataResponse.data);
      } else {
        setIsLoading(false);
        //alert(dependentCountryDataResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };
  //isActive State
  const activeCity = async (cityValId, isActive) => {
    setIsLoading(true);
    try {
      var countryData = JSON.stringify({
        id: cityValId,
        module: "city_master",
        data: {
          active: isActive,
        },
      });
      const activeCityResponse = await postData(
        countryData,
        "masters/masterCreateUpdate"
      );

      setIsLoading(false);

      var code = activeCityResponse.code;
      if (code == 1) {
        // //alert(activeCityResponse.message);
        setIsVisible(false);
        getCities([]);
        getCityListForExport();
        // console.log("status changed", activeCityResponse.data);
      } else {
        setIsLoading(false);
        // //alert(activeCityResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };
  // Country Get Api
  const getCountry = async () => {
    setIsLoading(true);
    try {
      var countryData = JSON.stringify({
        module: "country_master",
        data: {
          active: 1,
        },
        relation: [],
      });
      const countryNameResponse = await postData(
        countryData,
        "masters/getMasterList"
      );
      setIsLoading(false);
      var code = countryNameResponse.code;
      if (code == 1) {
        // console.log("Country list====>", countryNameResponse.data);
        // setCountryLength(countryNameResponse.data.length);
        setCountryName(countryNameResponse.data);
      } else {
        setIsLoading(false);
        //alert(countryNameResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };
  // Get Cities Api
  const getCities = async (sortdata: never[]) => {
    setIsLoading(true);
    if (sortdata.length > 0) {
      setCityLength(sortdata.length);
      setCityNameData(sortdata);
      setFilteredDataSource(sortdata);
    } else {
      try {
        var getCityData = JSON.stringify({
          module: "city_master",
          relation: [
            {
              module: "country_master",
            },
            {
              module: "state_master",
            },
          ],
        });
        const cityNameResponse = await postData(
          getCityData,
          "masters/getMasterList"
        );
        setIsLoading(false);
        var code = cityNameResponse.code;
        if (code == 1) {
          // console.log("cities list====>", cityNameResponse.data);

          setCityLength(cityNameResponse.data.length);
          setCityNameData(cityNameResponse.data);
          setFilteredDataSource(cityNameResponse.data);
        } else {
          setIsLoading(false);
          //alert(cityNameResponse.message);
        }
      } catch (error) {
        setIsLoading(false);
        // console.log(error);
      }
    }
  };

  const getCityListForExport = async () => {
    setIsLoading(true);
    try {
      var stateData = JSON.stringify({
        modelName: "city_master"
      });
      const CityNameDataResponse = await postData(
        stateData,
        "masters/dependentListExport"
      );
      setIsLoading(false);
      var code = CityNameDataResponse.code;
      console.log('StateNameDataResponse==>', CityNameDataResponse);
      if (code == 1) {
        setCityNameListExport(CityNameDataResponse.data);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const renderCountryList = (item: any) => {
    return (
      <View style={styles.item}>
        {isAdd == true && (
          <Text
            style={styles.textItem}
          // onPress={() => getDependentCountryMaster(item.id)}
          >
            {item.country}
          </Text>
        )}
        {isAdd == false && (
          <Text
            style={styles.textItem}
          // onPress={() => getDependentCountryMaster(item.id)}
          >
            {item.country}
          </Text>
        )}
        {item.id === countryValId && (
          <Icon style={styles.icon} color="black" name="Safety" size={20} />
        )}
      </View>
    );
  };
  const renderStateListItems = (item) => {
    // console.log("state item id-", item);
    return (
      <View style={styles.item}>
        {isAdd == true && <Text style={styles.textItem}>{item.state}</Text>}
        {isAdd == false && <Text style={styles.textItem}>{item.state}</Text>}
        {item.id === stateValId && (
          <Icon style={styles.icon} color="black" name="Safety" size={20} />
        )}
      </View>
    );
  };

  function CityModal() {
    // const [isVisible, setIsVisible] = useState(false);

    return (
      <>
        <Modal
          onRequestClose={() => setIsVisible(false)}
          transparent
          visible={isVisible}
        >
          <View
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",
              backgroundColor: "rgba(100,100,100, 0.5)",
            }}
          >
            <View style={[styles.containeralt]}>
              <TouchableOpacity
                style={{ alignSelf: "flex-end" }}
                onPress={() => setIsVisible(false)}
              >
                <IconCross
                  name="circle-with-cross"
                  size={20}
                  color="#000"
                  style={{ bottom: 5, top: 10 }}
                />
              </TouchableOpacity>

              <View style={{ width: "100%" }}>
                <Text style={{ fontWeight: "600" }}>Country Name</Text>
                {isAdd == true && (
                  <View>
                    <Dropdown
                      ref={dropdownRefcountry}
                      style={styles.dropdown}
                      placeholderStyle={styles.placeholderStyle}
                      selectedTextStyle={styles.selectedTextStyle}
                      inputSearchStyle={styles.inputSearchStyle}
                      iconStyle={styles.iconStyle}
                      data={CountryName}
                      search
                      maxHeight={300}
                      labelField="country"
                      valueField="country"
                      placeholder={!isFocus ? "Select Country" : "...."}
                      searchPlaceholder="Search..."
                      value={countryValue}
                      onChange={(item) => {
                        setCountryValue(item.country);
                        setCountryValId(item.id);
                        getDependentCountryMaster(item.id);
                        // console.log("country id val", item);
                        if (item.country == "") {
                          setcountryError("Please enter country");
                        } else {
                          setcountryError("");
                        }
                      }}
                      renderItem={renderCountryList}
                    />
                    <Text style={[styles.error_text]}>{countryError}</Text>
                  </View>
                )}

                {isAdd == false && (
                  <View>
                    <Dropdown
                      ref={dropdownRefcountry}
                      style={styles.dropdown}
                      placeholderStyle={styles.placeholderStyle}
                      selectedTextStyle={styles.selectedTextStyle}
                      inputSearchStyle={styles.inputSearchStyle}
                      iconStyle={styles.iconStyle}
                      data={CountryName}
                      search
                      maxHeight={300}
                      labelField="country"
                      valueField="country"
                      placeholder={!isFocus ? "Select Country" : "...."}
                      searchPlaceholder="Search..."
                      value={countryValue}
                      onChange={(item) => {
                        // setCountryValue(item.country);
                        // getDependentCountryMaster(item.id);
                        setCountryValue(item.country);
                        setCountryValId(item.id);
                        getDependentCountryMaster(item.id);
                        // console.log("country id val", item);
                        if (item.country == "") {
                          setcountryError("Please enter country");
                        } else {
                          setcountryError("");
                        }
                      }}
                      renderItem={renderCountryList}
                    />
                    {/* {countryValue} */}
                    <Text style={[styles.error_text]}>{countryError}</Text>
                  </View>
                )}
              </View>
              <View style={{ width: "100%" }}>
                <Text style={{ fontWeight: "600" }}>State Name</Text>
                {isAdd == true && (
                  <View>
                    <Dropdown
                      ref={dropdownRefState}
                      style={styles.dropdown}
                      placeholderStyle={styles.placeholderStyle}
                      selectedTextStyle={styles.selectedTextStyle}
                      inputSearchStyle={styles.inputSearchStyle}
                      iconStyle={styles.iconStyle}
                      data={stateData}
                      search
                      maxHeight={300}
                      labelField="state"
                      valueField="state"
                      placeholder={!isFocus ? "Select State" : "...."}
                      searchPlaceholder="Search..."
                      value={stateValue}
                      onChange={(item) => {
                        setStateValue(item.state);
                        setStateValId(item.id);
                        // console.log("state value---", item.state);
                        if (item.state == "") {
                          setstateError("Please enter state");
                        } else {
                          setstateError("");
                        }
                      }}
                      renderItem={renderStateListItems}
                    />
                    <Text style={[styles.error_text]}>{stateError}</Text>
                  </View>
                )}

                {isAdd == false && (
                  <View>
                    <Dropdown
                      ref={dropdownRefState}
                      style={styles.dropdown}
                      placeholderStyle={styles.placeholderStyle}
                      selectedTextStyle={styles.selectedTextStyle}
                      inputSearchStyle={styles.inputSearchStyle}
                      iconStyle={styles.iconStyle}
                      data={stateData}
                      search
                      maxHeight={300}
                      labelField="state"
                      valueField="state"
                      placeholder={!isFocus ? "Select State" : "...."}
                      searchPlaceholder="Search..."
                      value={stateValue}
                      onChange={(item) => {
                        setStateValue(item.state);
                        setStateValId(item.id);
                        // console.log("state value---update", item.id);
                        if (item.state == "") {
                          setstateError("Please enter state");
                        } else {
                          setstateError("");
                        }
                      }}
                      renderItem={renderStateListItems}
                    />
                    <Text style={[styles.error_text]}>{stateError}</Text>
                  </View>
                )}
              </View>
              <View style={{ width: "100%" }}>
                <Text style={{ fontWeight: "600" }}>City Name</Text>
                {isAdd == true && (
                  <View>
                    <TextInputRN
                      style={styles.input}
                      placeholder=""
                      //
                      value={CityValue}
                      autoFocus={true}
                      onChange={textToCityName}
                    />
                    <Text style={[styles.error_text]}>{cityError}</Text>
                  </View>
                )}

                {isAdd == false && (
                  <View>
                    <TextInputRN
                      style={styles.input}
                      placeholder=""
                      value={CityValue}
                      key="Edit"
                      autoFocus
                      onChangeText={(text) => {
                        setCityValue(text);
                        if (text == "") {
                          setcityError("Please enter city");
                        } else {
                          setcityError("");
                        }
                      }}
                    />
                    <Text style={[styles.error_text]}>{cityError}</Text>
                  </View>
                )}
              </View>

              {isAdd == true && (
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                    marginHorizontal: 20,
                  }}
                >
                  <TouchableOpacity
                    style={styles.saveBtn}
                    onPress={() => CheckEnteredData()}
                  >
                    <Text style={styles.btnText}>Save</Text>
                  </TouchableOpacity>

                  <TouchableOpacity
                    style={styles.saveBtn}
                    onPress={() => {
                      setIsVisible(false),
                        setcountryError(""),
                        setcityError(""),
                        setstateError("");
                    }}
                  >
                    <Text style={styles.btnText}>Cancel</Text>
                  </TouchableOpacity>
                </View>
              )}
              {isAdd == false && (
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                    marginHorizontal: 20,
                  }}
                >
                  <TouchableOpacity
                    style={styles.saveBtn}
                    onPress={() => CheckEnteredData()}
                  >
                    <Text style={styles.btnText}>Update</Text>
                  </TouchableOpacity>

                  <TouchableOpacity
                    style={styles.saveBtn}
                    onPress={() => setIsVisible(false)}
                  >
                    <Text style={styles.btnText}>Cancel</Text>
                  </TouchableOpacity>
                </View>
              )}

              {/* <Gap />
          {isVisible ? <Modalception depth={depth + 1} /> : null}
          <Gap /> */}
            </View>
          </View>
        </Modal>
      </>
    );
  }

  const sortFun = () => {
    const sortData = filterData.sort((item, b) =>
      sort == 1
        ? item.country_master.country.localeCompare(b.country_master.country)
        : b.country_master.country.localeCompare(item.country_master.country)
    );
    getCities(sortData);
    setFilteredDataSource(sortData);
  };


  const downloadExcelReport = async () => {
    const filteredData = cityNameListExport.map(({ country, state, city, active }, index) => ({
      'Sr No': index + 1,
      'Country': country,
      'State': state,
      'City': city,
      'Active': active == 1 ? 'Active': 'InActive',
    }));
    // const filteredData = CityNameData.map(({ createdAt, updatedAt, index, ...rest }) => rest);
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, {
      type: 'array',
      bookType: 'xlsx',
    });
    const excelData = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(excelData, 'city_master.xlsx');
  }
  const screenwidth = Dimensions.get('window').width
  const isMobile = screenwidth < 768

  return (
    <View style={styles.container}>
      <SuccessPopup
        visible={issuccessVisible}
        PopupMessage={PopupMessage}
        Responsestatus={responsestatus}
        onChange={() => setissuccessVisible(false)}
      />

      <Modal
        onRequestClose={() => setIsVisible(false)}
        transparent
        visible={isVisible}
      >
        <View
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
            backgroundColor: "rgba(100,100,100, 0.5)",
          }}
        >
          <View style={isMobile ? {
            // alignItems: "center",
            backgroundColor: "white",
            borderColor: "#eee",
            borderRadius: 10,
            borderWidth: 1,
            // justifyContent: "center",
            height: "auto",
            margin: "auto",
            padding: 30,
            shadowOffset: {
              width: 0,
              height: 2,
            },
            shadowOpacity: 0.25,
            shadowRadius: 4,
            elevation: 5,
            // marginRight: 10,
            // marginRight: 1,
            width: "80%",
          } : [styles.containeralt]}>
            <TouchableOpacity
              style={{ alignSelf: "flex-end" }}
              onPress={() => setIsVisible(false)}
            >
              <IconCross
                name="circle-with-cross"
                size={20}
                color="#000"
                style={{ bottom: 5, top: 10 }}
              />
            </TouchableOpacity>

            <View style={{ width: "100%" }}>
              <Text style={{ fontWeight: "600" }}>Country Name</Text>
              {isAdd == true && (
                <View>
                  <Dropdown
                    ref={dropdownRefcountry}
                    style={styles.dropdown}
                    placeholderStyle={styles.placeholderStyle}
                    selectedTextStyle={styles.selectedTextStyle}
                    inputSearchStyle={styles.inputSearchStyle}
                    iconStyle={styles.iconStyle}
                    data={CountryName}
                    search
                    maxHeight={300}
                    labelField="country"
                    valueField="country"
                    placeholder={!isFocus ? "Select Country" : "...."}
                    searchPlaceholder="Search..."
                    value={countryValue}
                    onChange={(item) => {
                      setCountryValue(item.country);
                      setCountryValId(item.id);
                      getDependentCountryMaster(item.id);
                      // console.log("country id val", item);
                      if (item.country == "") {
                        setcountryError("Please enter country");
                      } else {
                        setcountryError("");
                      }
                    }}
                    renderItem={renderCountryList}
                  />
                  <Text style={[styles.error_text]}>{countryError}</Text>
                </View>
              )}

              {isAdd == false && (
                <View>
                  <Dropdown
                    ref={dropdownRefcountry}
                    style={styles.dropdown}
                    placeholderStyle={styles.placeholderStyle}
                    selectedTextStyle={styles.selectedTextStyle}
                    inputSearchStyle={styles.inputSearchStyle}
                    iconStyle={styles.iconStyle}
                    data={CountryName}
                    search
                    maxHeight={300}
                    labelField="country"
                    valueField="country"
                    placeholder={!isFocus ? "Select Country" : "...."}
                    searchPlaceholder="Search..."
                    value={countryValue}
                    onChange={(item) => {
                      // setCountryValue(item.country);
                      // getDependentCountryMaster(item.id);
                      setCountryValue(item.country);
                      setCountryValId(item.id);
                      getDependentCountryMaster(item.id);
                      // console.log("country id val", item);
                      if (item.country == "") {
                        setcountryError("Please enter country");
                      } else {
                        setcountryError("");
                      }
                    }}
                    renderItem={renderCountryList}
                  />
                  {/* {countryValue} */}
                  <Text style={[styles.error_text]}>{countryError}</Text>
                </View>
              )}
            </View>
            <View style={{ width: "100%" }}>
              <Text style={{ fontWeight: "600" }}>State Name</Text>
              {isAdd == true && (
                <View>
                  <Dropdown
                    ref={dropdownRefState}
                    style={styles.dropdown}
                    placeholderStyle={styles.placeholderStyle}
                    selectedTextStyle={styles.selectedTextStyle}
                    inputSearchStyle={styles.inputSearchStyle}
                    iconStyle={styles.iconStyle}
                    data={stateData}
                    search
                    maxHeight={300}
                    labelField="state"
                    valueField="state"
                    placeholder={!isFocus ? "Select State" : "...."}
                    searchPlaceholder="Search..."
                    value={stateValue}
                    onChange={(item) => {
                      setStateValue(item.state);
                      setStateValId(item.id);
                      // console.log("state value---", item.state);
                      if (item.state == "") {
                        setstateError("Please enter state");
                      } else {
                        setstateError("");
                      }
                    }}
                    renderItem={renderStateListItems}
                  />
                  <Text style={[styles.error_text]}>{stateError}</Text>
                </View>
              )}

              {isAdd == false && (
                <View>
                  <Dropdown
                    ref={dropdownRefState}
                    style={styles.dropdown}
                    placeholderStyle={styles.placeholderStyle}
                    selectedTextStyle={styles.selectedTextStyle}
                    inputSearchStyle={styles.inputSearchStyle}
                    iconStyle={styles.iconStyle}
                    data={stateData}
                    search
                    maxHeight={300}
                    labelField="state"
                    valueField="state"
                    placeholder={!isFocus ? "Select State" : "...."}
                    searchPlaceholder="Search..."
                    value={stateValue}
                    onChange={(item) => {
                      setStateValue(item.state);
                      setStateValId(item.id);
                      // console.log("state value---update", item.id);
                      if (item.state == "") {
                        setstateError("Please enter state");
                      } else {
                        setstateError("");
                      }
                    }}
                    renderItem={renderStateListItems}
                  />
                  <Text style={[styles.error_text]}>{stateError}</Text>
                </View>
              )}
            </View>
            <View style={{ width: "100%" }}>
              <Text style={{ fontWeight: "600" }}>City Name</Text>
              {isAdd == true && (
                <View>
                  <TextInputRN
                    style={styles.input}
                    placeholder=""
                    //
                    value={CityValue}
                    autoFocus={true}
                    onChange={textToCityName}
                  />
                  <Text style={[styles.error_text]}>{cityError}</Text>
                </View>
              )}

              {isAdd == false && (
                <View>
                  <TextInputRN
                    style={styles.input}
                    placeholder=""
                    value={CityValue}
                    key="Edit"
                    autoFocus
                    onChangeText={(text) => {
                      setCityValue(text);
                      if (text == "") {
                        setcityError("Please enter city");
                      } else {
                        setcityError("");
                      }
                    }}
                  />
                  <Text style={[styles.error_text]}>{cityError}</Text>
                </View>
              )}
            </View>

            {isAdd == true && (
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  marginHorizontal: 20,
                }}
              >
                <TouchableOpacity
                  style={styles.saveBtn}
                  onPress={() => CheckEnteredData()}
                >
                  <Text style={styles.btnText}>Save</Text>
                </TouchableOpacity>

                <TouchableOpacity
                  style={styles.saveBtn}
                  onPress={() => {
                    setIsVisible(false),
                      setcountryError(""),
                      setcityError(""),
                      setstateError("");
                  }}
                >
                  <Text style={styles.btnText}>Cancel</Text>
                </TouchableOpacity>
              </View>
            )}
            {isAdd == false && (
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  marginHorizontal: 20,
                }}
              >
                <TouchableOpacity
                  style={styles.saveBtn}
                  onPress={() => CheckEnteredData()}
                >
                  <Text style={styles.btnText}>Update</Text>
                </TouchableOpacity>

                <TouchableOpacity
                  style={styles.saveBtn}
                  onPress={() => setIsVisible(false)}
                >
                  <Text style={styles.btnText}>Cancel</Text>
                </TouchableOpacity>
              </View>
            )}

            {/* <Gap />
          {isVisible ? <Modalception depth={depth + 1} /> : null}
          <Gap /> */}
          </View>
        </View>
      </Modal>
      <Modal
        visible={editpopup}
        animationType={"slide"}
        transparent={true}
        onRequestClose={() => {
          setEditPopup(false);
        }}
      >
        <View style={isMobile ? {
            // alignItems: "center",
            backgroundColor: "white",
            borderColor: "#eee",
            borderRadius: 10,
            borderWidth: 1,
            // justifyContent: "center",
            height: "auto",
            margin: "auto",
            padding: 30,
            shadowOffset: {
              width: 0,
              height: 2,
            },
            shadowOpacity: 0.25,
            shadowRadius: 4,
            elevation: 5,
            // marginRight: 10,
            // marginRight: 1,
            width: "80%",
          } : [styles.contanerSwitch]}>
          <TouchableOpacity
            style={{ alignSelf: "flex-end" }}
            onPress={() => {
              setEditPopup(false),
                setcountryError(""),
                setcityError(""),
                setstateError("");
            }}
          >
            <IconCross
              name="circle-with-cross"
              size={20}
              color="#000"
              style={{ bottom: 5, top: 0 }}
            />
          </TouchableOpacity>

          <Text style={[styles.tableHead, { marginBottom: 20 }]}>
            Do You Want Update Status
          </Text>
          <View
            style={{
              flexDirection: "row",
              paddingRight: 10,
              justifyContent: "space-between",
            }}
          >
            <TouchableOpacity
              onPress={() => {
                setEditPopup(false), toggleSwitch();
              }}
              style={styles.saveBtn}
            >
              <Text style={{ color: "white" }}>Yes</Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                setEditPopup(false);
              }}
              style={styles.saveBtn}
            >
              <Text style={{ color: "white" }}>No</Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>
      {/* <Header /> */}
      {/* <Text style={styles.title}>This screen is Profile screeen.</Text> */}
      <View style={isMobile ? { width: "100%", marginTop: 180 } : { flexDirection: "row", width: "100%", marginTop: "10%" }}>
        <View style={{ flex: 1, margin: 10 }}>
          <Text style={styles.lableText}>Master {">"} City Master</Text>
        </View>
        <View style={isMobile ? { alignItems: "center", marginTop: 20  , marginLeft:'-35%', width:"170%", flex:2 } : { alignSelf: "center", alignItems: "center", flex: 2 }}>
          {/* <TouchableOpacity onPress={() => exportCsvZoneData()}><Text>Download CSV</Text></TouchableOpacity> */}
          <TextInput
            style={isMobile ? {
              height: 35,
              borderWidth: 2,
              paddingLeft: 10,
              borderColor: "#319ef0",
              backgroundColor: "#FFFFFF",
              width: "50%",
              borderRadius: 5,
              color: "#000",
              fontWeight: "400",
            } : styles.textInputStyle}
            onChangeText={(text) => searchFilterFunction(text)}
            value={searchData}
            underlineColorAndroid="transparent"
            underlineColor={"white"}
            placeholder="Search City"
            theme={{
              colors: {
                text: "black",
                primary: "white",
                background: "transparent",
              },
            }}
            left={
              <TextInput.Icon
                name={() => <IconEdit name="search" size={20} color="#000" />} // where <Icon /> is any component from vector-icons or anything else
              />
            }
          />
        </View>
        <View style={{ flex: 1, alignItems: "flex-end" }}>
          <TouchableOpacity
            style={styles.addCountry}
            onPress={() => {
              isCreatable(), setError(""), setcityError(""), setstateError("");
            }}
          >
            <Text style={styles.btnText}>
              <Icon name="plus" size={15} color="#fff" /> Add City
            </Text>
          </TouchableOpacity>
        </View>
      </View>

      <View style={isMobile ? { width: '100%', alignItems: "flex-end", marginTop:"-10%", marginLeft:"-120%" } : { width: '100%', alignItems: "flex-end" }}>
        <TouchableOpacity
          style={isMobile ? [styles.addCountry, { width: '45%',marginRight:-10 }] : [styles.addCountry, { width: '10%' }]}
          onPress={() => {
            downloadExcelReport();
          }}
        >
          <Text style={styles.btnText}>
            <Icon name="exclefile1" size={15} color="#fff" /> Download
          </Text>
        </TouchableOpacity>
      </View>
      {/* <ScrollView
        style={[styles.card, styles.shadowProp]}
        contentContainerStyle={{ flexGrow: 1 }}
      > */}
      <View style={isMobile ? [styles.card, { flexGrow: 1, marginTop:50, paddingBottom:10 }] : [styles.card, { flexGrow: 1 }]}>
        <View
          style={[
            styles.shadowProp,
            {
              backgroundColor: "white",
            },
          ]}
        >
          <View
            style={{
              width: "100%",
              // flex: 1,
              backgroundColor: "#F6F9F9",
            }}
          ></View>
          <View
          style={isMobile ? {
            borderWidth: 1,
            borderColor: "#e3e0e0",
            width: "140%",
            alignSelf: "center",
          }: {
            borderWidth: 1,
            borderColor: "#e3e0e0",
          }}
          >
            <View>
              <DataTable
                // title="State"
                columns={columns}
                data={filterData}
                customStyles={customStyles}
                pagination
              />
            </View>
            {/* prvider datatable code here */}

            {/* ended */}
          </View>
        </View>
        {/* </ScrollView> */}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    // marginTop: 300,
  },
  containeralt: {
    // alignItems: "center",
    backgroundColor: "white",
    borderColor: "#eee",
    borderRadius: 10,
    borderWidth: 1,
    // justifyContent: "center",
    height: "auto",
    margin: "auto",
    padding: 30,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    // marginRight: 10,
    // marginRight: 1,
    width: "30%",
  },
  contanerSwitch: {
    backgroundColor: "white",
    borderColor: "#eee",
    borderRadius: 5,
    alignSelf: "center",
    borderWidth: 1,
    // justifyContent: "center",
    height: "25%",
    margin: "auto",
    padding: 10,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    // marginRight: 10,
    // marginRight: 1,
    width: "30%",
  },
  input: {
    borderColor: "#c3c3c3",
    width: "100%",
    borderWidth: 1,
    borderRadius: 2,

    padding: 10,
    // marginTop: 20,
    marginBottom: 5,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  addCountry: {
    alignItems: "center",
    backgroundColor: "#006EB9",
    padding: 10,
    borderWidth: 1,
    borderColor: "#006EB9",
    width: "50%",
    // marginTop: 20,
  },
  saveBtn: {
    alignItems: "center",
    backgroundColor: "#006EB9",
    padding: 10,
    borderWidth: 1,
    borderColor: "#006EB9",
    width: "50%",
    marginRight: 10,
  },
  btnText: {
    color: "#fff",
    fontWeight: "600",
  },
  card: {
    width: "75%",
    flex: 1,
    marginVertical: 10,
  },
  shadowProp: {
    shadowColor: "#171717",
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 25,
  },

  link: {
    marginTop: 15,
    paddingVertical: 15,
  },
  linkText: {
    fontSize: 14,
    color: "#2e78b7",
  },
  textInputStyle: {
    outline: "none",
    height: 35,
    borderWidth: 2,
    paddingLeft: 20,
    borderRadius: 5,
    borderColor: "#319ef0",
    backgroundColor: "#FFFFFF",
    width: "35%",
    fontWeight: "400",
  },
  tableHead: {
    fontSize: 14,
    /* FontFamily: 'Muli' ,*/
    fontWeight: "700",
    color: "#000000",
  },

  // DropDown CSS
  dropdown: {
    borderColor: "#c3c3c3",
    width: "100%",
    borderWidth: 1,
    borderRadius: 2,
    height: 40,
    outlineStyle: "none",
    outline: "none",
    padding: 10,
    marginTop: 5,
    marginBottom: 5,
    // borderRadius: 10,
    // padding: 12,
    // shadowColor: "#000",
    // shadowOffset: {
    //   width: 0,
    //   height: 1,
    // },
    // shadowOpacity: 0.2,
    // shadowRadius: 1.41,

    // elevation: 2,
  },
  icon: {
    marginRight: 5,
  },
  tableHeader: {
    color: "#000",
    fontSize: 14,
    /* FontFamily: 'Muli' ,*/
    fontWeight: "400",
  },
  item: {
    padding: 9,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  textItem: {
    flex: 1,
    fontSize: 16,
  },
  placeholderStyle: {
    fontSize: 16,
  },
  selectedTextStyle: {
    fontSize: 16,
  },
  iconStyle: {
    width: 20,
    height: 20,
  },
  inputSearchStyle: {
    height: 40,
    fontSize: 16,
    outlineStyle: "none",
  },
  lableText: {
    color: "#484848",
    fontWeight: "700",
    marginTop: 18,
    // fontSize: 21,
  },
  // Error message css
  error_text: {
    color: "red",
    fontSize: 14,
    fontWeight: "600",
    width: "70%",
    marginLeft: 0,
    marginBottom: 10,
  },
});
