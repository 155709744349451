import Header from "../navigation/header";
import {
  StyleSheet,
  TouchableOpacity,
  TextInput,
  Modal,
  ScrollView,
  Alert,
  ActivityIndicator,
  Switch,
  Image,
  Dimensions,
} from "react-native";
import { RadioButton, Button } from "react-native-paper";
import moment from "moment";

import { CheckBox } from "react-native-elements";
import AsyncStorage from "@react-native-async-storage/async-storage";

import { DatePickerModal } from "react-native-paper-dates";
import { Dropdown } from "react-native-element-dropdown";

import { Text, View } from "../../../components/Themed";
import ImgToBase64 from "react-native-image-base64";
import * as ImagePicker from "expo-image-picker";

import Icon from "react-native-vector-icons/AntDesign";
import IconImage from "react-native-vector-icons/Entypo";

import React, { useState, useRef, useEffect } from "react";
import IconCross from "react-native-vector-icons/Entypo";
import { baseUrl, postData } from "../../../networking/api";
import { useFocusEffect } from "@react-navigation/native";
import SuccessPopup from "../../../components/SuccessPopup";

export default function CreateUserMaster({ navigation, route }) {
  // var { userId } = route?.params;
  // var uniqueId = userId;
  // // console.log("userId 000", userId);
  // // var num = 1;
  // var uniqueiD = Number(userId) + Number(1);

  // function zeroPad(num) {
  //   return num.toString().padStart(3, "0");
  // }
  // var idNum = zeroPad(Number(uniqueiD));
  // // console.log("zero add==", zeroPad(Number(uniqueiD)));
  // function addLeadingZeros(num, totalLength) {
  //   return String(num).padStart(totalLength, '0');
  // }
  // // console.log("uniqueiD", ('00' + uniqueiD.toString()).slice(-3));
  // // console.log("adding zero ===", addLeadingZeros(uniqueiD, uniqueiD.length + 2));

  // if (userId.length > 3) {
  //   idNum = (uniqueiD.toString()).slice(-3);

  // }
  // else {
  //   idNum = ('00' + uniqueiD.toString()).slice(-3);

  // }

  // const [employeeCode, setEmployeeCode] = useState(0);
  // setEmployeeCode(uniqueiD);

  const [isVisible, setIsVisible] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const [animating, setAnimating] = useState(true);
  const [isAdd, setisAdd] = useState(false);
  const defaultImage = require("../../../assets/images/noprofile.png");

  const [image, setImage] = useState(null);

  const [genderchecked, setGenderChecked] = useState(1);
  const [isMarriedchecked, setMaritialChecked] = useState(1);
  const [isstatuschecked, setStatusChecked] = useState(1);

  const [isLoading, setIsLoading] = useState(false);

  const [empCode, setEmpCode] = useState("");

  const [firstName, setFirstName] = useState("");
  const [isFirstNameValid, setFirstNameValid] = useState(false);
  const [isFirstNameEntered, setFirstNameEntered] = useState(false);

  const [LastName, setLastName] = useState("");
  const [isLastNameValid, setLastNameValid] = useState(false);
  const [isLastNameEntered, setLastNameEntered] = useState(false);

  const [EmailId, setEmailId] = useState("");
  const [isEmailIdValid, setEmailIdValid] = useState(false);
  const [isEmailIdEntered, setEmailIdEntered] = useState(false);

  const [MobileNumber, setMobileNumber] = useState("");
  const [isMobileNumberValid, setMobileNumberValid] = useState(false);
  const [isMobileNumberEntered, setMobileNumberEntered] = useState(false);

  const [UserType, setUserType] = useState("");

  const [UserTypeData, setUserTypeData] = useState([]);
  const [UserTypeValid, setUserTypeValid] = useState(false);
  const [UserTypeEntered, setUserTypeEntered] = useState(false);

  const [issuccessVisible, setissuccessVisible] = useState(false);
  const [responsestatus, setresponsestatus] = useState("");
  const [PopupMessage, setPopupMessage] = useState("");

  const [Gender, setGender] = useState("");
  const [isGenderValid, setGenderValid] = useState(false);
  const [isGenderEntered, setGenderEntered] = useState(false);

  const [Dob, setDob] = useState("");
  const [isDobValid, setDobValid] = useState(false);
  const [isDobEntered, setDobEntered] = useState(false);

  const [CountryNameData, setCountryNameData] = useState([]);
  const [isCountryNameDataValid, setCountryNameDataValid] = useState(false);
  const [isCountryNameDataEntered, setCountryNameDataEntered] = useState(false);

  const [StateNameData, setStateNameData] = useState([]);
  const [isStateNameDataValid, setStateNameDataValid] = useState(false);
  const [isStateNameDataEntered, setStateNameDataEntered] = useState(false);

  const [CityNameData, setCityNameData] = useState([]);
  const [isCityNameDataValid, setCityNameDataValid] = useState(false);
  const [isCityNameDataEntered, setCityNameDataEntered] = useState(false);

  const [TahasilNameData, setTahasilNameData] = useState([]);
  const [isTahasilNameDataValid, setTahasilNameDataValid] = useState(false);
  const [isTahasilNameDataEntered, setTahasilNameDataEntered] = useState(false);

  const [VillageNameData, setVillageNameData] = useState([]);
  const [isVillageNameDataValid, setVillageNameDataValid] = useState(false);
  const [isVillageNameDataEntered, setVillageNameDataEntered] = useState(false);

  const [PinCode, setPinCode] = useState(null);
  const [isPinCodeValid, setPinCodeValid] = useState(false);
  const [isPinCodeEntered, setPinCodeEntered] = useState(false);

  const [Address, setAddress] = useState("");
  const [isAddressValid, setAddressValid] = useState(false);
  const [isAddressEntered, setAddressEntered] = useState(false);

  const [Qualification, setQualification] = useState("");
  const [isQualificationValid, setQualificationValid] = useState(false);
  const [isQualificationEntered, setQualificationEntered] = useState(false);

  const [Doj, setDoj] = useState("");
  const [isDojValid, setDojValid] = useState(false);
  const [isDojEntered, setDojEntered] = useState(false);

  const [loginId, setloginId] = useState("");
  const [isloginIdValid, setloginIdValid] = useState(false);
  const [isloginIdEntered, setloginIdEntered] = useState(false);

  const [Password, setPassword] = useState("");
  const [isPasswordValid, setPasswordValid] = useState(false);
  const [isPasswordEntered, setPasswordEntered] = useState(false);

  const [MaritialStatus, setMaritialStatus] = useState("Married");
  const [isMaritialStatusValid, setMaritialStatusValid] = useState(false);
  const [isMaritialStatusEntered, setMaritialStatusEntered] = useState(false);

  const [Status, setStatus] = useState("");
  const [isStatusValid, setStatusValid] = useState(false);
  const [isStatusEntered, setStatusEntered] = useState(false);

  const [isFocus, setIsFocus] = useState(false);

  // Master Id's and names
  const [countryValue, setCountryValue] = useState(null);
  const [countryValId, setCountryValId] = useState(null);
  const [stateValue, setStateValue] = useState(null);
  const [stateValId, setStateValId] = useState(null);
  const [cityValue, setCityValue] = useState(null);
  const [cityValId, setCityValId] = useState(null);
  const [tahasilValue, setTahasilValue] = useState(null);
  const [tahasilValId, setTahasilValId] = useState(null);
  const [VillageValue, setVillageValue] = useState(null);
  const [VillageValId, setVillageValId] = useState(null);

  //Dropdown value pickup
  const [userTypeValue, setUserTypeValue] = useState("");

  //Error message
  const [imageError, setImageError] = useState("");

  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailIdError, setEmailIdError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [loginIdError, setLoginIdError] = useState("");

  const [mobileNumError, setMobileNumError] = useState("");
  const [countryError, seTCountryError] = useState("");
  const [usertypeError, seTUserTypeError] = useState("");

  const [stateError, setStateError] = useState("");
  const [cityError, setCityError] = useState("");
  const [tahasilError, setTahasilError] = useState("");
  const [villageError, setVillageError] = useState("");
  const [projectId, setProjectId] = useState("");

  const [pinError, setPinError] = useState("");
  const [qualification_Error, setQualificationError] = useState("");
  const regex = /^[0-9]*$/;

  const dropdownRef = useRef();
  const dropdownRefcountry = useRef();
  const dropdownRefState = useRef();
  const dropdownRefCity = useRef();
  const dropdownRefTahasil = useRef();
  const dropdownRefVillage = useRef();

  const closeMenu = () => {
    dropdownRef.current.close();
    dropdownRefcountry.current.close();
    dropdownRefState.current.close();
    dropdownRefCity.current.close();
    dropdownRefTahasil.current.close();
    dropdownRefVillage.current.close();
    //return calls;
    // // console.log("on scroll close in dropdown")
  };

  useEffect(() => {
    window.addEventListener("scroll", closeMenu);
    return () => window.removeEventListener("scroll", closeMenu);
  }, []);

  const radioButtonsData = [
    {
      id: "1",
      label: "Male",
      value: 1,
      color: "red",
      selected: true,
    },
    {
      id: "2",
      label: "Female",
      value: 2,
      color: "red",
      selected: false,
    },
  ];

  const toggleSwitch = () => setIsEnabled((previousState) => !previousState);

  //DOb
  const [date, setDate] = React.useState<Date | undefined>(undefined);
  const [open, setOpen] = React.useState(false);

  const onDismissSingle = React.useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const onConfirmSingle = React.useCallback(
    (params) => {
      setOpen(false);
      setDate(params.date);
    },
    [setOpen, setDate]
  );

  //DOJ
  const [joinDate, setJoinDate] = React.useState<Date | undefined>(undefined);
  const [joinOpen, setJoinOpen] = React.useState(false);


  const onDismissJoin = React.useCallback(() => {
    setJoinOpen(false);
  }, [setJoinOpen]);

  const onConfirmJoin = React.useCallback(
    (params) => {
      setJoinOpen(false);
      setJoinDate(params.date);
    },
    [setJoinOpen, setJoinDate]
  );
  //Regex
  var alpha_pattern = new RegExp(/^[.a-zA-Z]+(\s[.a-zA-Z]+)?$/);
  var Email_Id_pattern = new RegExp(
    /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w\w+)+$/
  );
  var password_pattern = new RegExp(
    /^[a-zA-Z0-9]+$/
    // /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&^_-]{8,}$/
  );
  var MobileNo_pattern = new RegExp(/^[0]?[789]\d{9}$/);
  var number_regex = new RegExp(/^[0-9]*$/);

  useFocusEffect(
    React.useCallback(() => {
      getAdminData();
      getUserTypeList();
      getCountry();
    }, [])
  );

  const getAdminData = async () => {
    let jsonValue = await AsyncStorage.getItem("userData");
    let projValue = await AsyncStorage.getItem("projectData");

    if (jsonValue != null) {
      var userDetailsresponse = JSON.parse(jsonValue);
      setloginId(userDetailsresponse.email_id);
    }
    var userDetailsresponse = JSON.parse(jsonValue);
    console.log("projValue==>", projValue);
    console.log("projValue==>", userDetailsresponse.project_selected);

    if (projValue != null) {
      setProjectId(projValue);
    }
  };

  // Country Get Api
  const getCountry = async () => {
    setIsLoading(true);
    try {
      var countryData = JSON.stringify({
        module: "country_master",
        data: {
          active: 1,
        },
        relation: [],
      });
      const countryNameResponse = await postData(
        countryData,
        "masters/getMasterList"
      );
      setIsLoading(false);
      var code = countryNameResponse.code;
      if (code == 1) {
        // console.log("Country list====>", countryNameResponse.data);
        // setCountryLength(countryNameResponse.data.length);
        setCountryNameData(countryNameResponse.data);
      } else {
        setIsLoading(false);
        //alert(countryNameResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  const isCreatable = () => {
    setIsVisible(true);
    setisAdd(true);
  };
  const isAddable = (
    village_id,
    tahasil_id,
    country_id,
    state_name,
    city_name,
    tahasil_name,
    village_name
  ) => {
    setIsVisible(true);
    setisAdd(false);
    // getStateById(stateId);

    setCountryValId(country_id);
    setStateValId(state_name);
    setCityValId(city_name);
    setTahasilValId(tahasil_name);
    setVillageValId(village_id);

    // setCountryValue(country_id);
    // setStateValue(state_name);
    // setCityValue(city_name);
    // setTahasilValue(tahasil_name);
    setVillageValue(village_name);
  };

  const renderUserTypeListItems = (item: any) => {
    return (
      <View style={styles.item}>
        {isAdd == true && <Text style={styles.textItem}>{item.user_type}</Text>}
        {isAdd == false && (
          <Text style={styles.textItem}>{item.user_type}</Text>
        )}
        {item.id === UserTypeValid && (
          <Icon style={styles.icon} color="black" name="Safety" size={20} />
        )}
      </View>
    );
  };
  const renderCountryList = (item: any) => {
    return (
      <View style={styles.item}>
        {isAdd == true && <Text style={styles.textItem}>{item.country}</Text>}
        {isAdd == false && <Text style={styles.textItem}>{item.country}</Text>}
        {item.id === countryValId && (
          <Icon style={styles.icon} color="black" name="Safety" size={20} />
        )}
      </View>
    );
  };
  const renderStateListItems = (item) => {
    // // console.log("State id item", item.id);
    return (
      <View style={styles.item}>
        {isAdd == true && <Text style={styles.textItem}>{item.state}</Text>}
        {isAdd == false && <Text style={styles.textItem}>{item.state}</Text>}
        {item.id === stateValId && (
          <Icon style={styles.icon} color="black" name="Safety" size={20} />
        )}
      </View>
    );
  };
  const renderCityListItems = (item) => {
    // console.log("city item name-", item.city);
    return (
      <View style={styles.item}>
        {isAdd == true && <Text style={styles.textItem}>{item.city}</Text>}
        {isAdd == false && <Text style={styles.textItem}>{item.city}</Text>}
        {item.id === cityValId && (
          <Icon style={styles.icon} color="black" name="Safety" size={20} />
        )}
      </View>
    );
  };
  const renderTahasilListItems = (item) => {
    // console.log("tahasil item name-", item.tahasil);
    return (
      <View style={styles.item}>
        {isAdd == true && (
          <Text style={styles.textItem}>{item.tahasil_name}</Text>
        )}
        {isAdd == false && (
          <Text style={styles.textItem}>{item.tahasil_name}</Text>
        )}
        {item.id === tahasilValId && (
          <Icon style={styles.icon} color="black" name="Safety" size={20} />
        )}
      </View>
    );
  };
  const renderVillageListItems = (item) => {
    // console.log("village item name-", item.tahasil);
    return (
      <View style={styles.item}>
        {isAdd == true && (
          <Text style={styles.textItem}>{item.village_name}</Text>
        )}
        {isAdd == false && (
          <Text style={styles.textItem}>{item.village_name}</Text>
        )}
        {item.id === VillageValId && (
          <Icon style={styles.icon} color="black" name="Safety" size={20} />
        )}
      </View>
    );
  };

  const CheckEnteredData = async () => {
    setFirstNameEntered(true);
    setLastNameEntered(true);
    setEmailIdEntered(true);
    setMobileNumberEntered(true);
    setPinCodeEntered(true);
    setQualificationEntered(true);
    setPasswordEntered(true);
    // setFirstNameEntered(true);

    //setUserType(userTypes);
    if (
      isFirstNameValid &&
      isLastNameValid &&
      isEmailIdValid &&
      isMobileNumberValid &&
      isPasswordValid && UserTypeValid
    ) {
      // console.log("success");
      setImageError("");
      setFirstNameError("");
      setLastNameError("");
      setEmailIdError("");
      setMobileNumError("");
      setPinError("");
      setQualificationError("");
      setPasswordError("");
      seTCountryError("");
      seTUserTypeError("");
      setStateError("");
      setCityError("");
      setTahasilError("");
      setVillageError("");
      createUserMasterData();
    } else {
      // console.log("error");

      if (firstName == "") {
        setFirstNameError("Please enter first name");
      }
      if (LastName == "") {
        setLastNameError("Please enter last name");
      }

      if (EmailId == "") {
        setEmailIdError("Please enter email id");
      }

      if (EmailId == "") {
        setLoginIdError("Please enter login id ");
      }
      if (Password == "") {
        setPasswordError("Please enter password ");
      }

      if (MobileNumber == "") {
        setMobileNumError("Please enter mobile number");
      }

      //Country
      // if (countryValue == "") {
      //   seTCountryError("Please select Country");
      // }

      if (userTypeValue == "") {
        seTUserTypeError("Please select User Type");
      }

      //State
      // if (stateValue == "") {
      //   setStateError("Please select State");
      // }

      //City
      // if (cityValue == "") {
      //   setCityError("Please select City");
      // }

      //Tahasil
      // if (tahasilValue == "") {
      //   setTahasilError("Please select Tahasil");
      // }
      //Village
      // if (VillageValue == "") {
      //   setVillageError("Please select Village");
      // }
      //Pin Validation
      // if (PinCode == "") {
      //   setPinError("Please enter pin ");
      // }
      // //Qualification Validation
      // if (Qualification == "") {
      //   setQualificationError("Please enter qualification");
      // }
    }
  };

  const getUserTypeList = async () => {
    setIsLoading(true);
    try {
      var UserTypeData = JSON.stringify({
        module: "user_type",
        relation: [],
      });
      const UserTypeList = await postData(
        UserTypeData,
        "masters/getMasterList"
      );
      setIsLoading(false);
      var code = UserTypeList.code;
      if (code == 1) {
        // console.log("User type list api====>", UserTypeList.data);

        setUserTypeData(UserTypeList.data);
      } else {
        setIsLoading(false);
        //alert(UserTypeList.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };
  // Get dependent state by country
  const getDependentCountryMaster = async (country_id) => {
    // console.log("country_id masters", country_id);
    setIsLoading(true);

    try {
      var dependentData = JSON.stringify({
        column: "country_id",
        value: country_id,
        module: "state_master",
      });
      const dependentCountryDataResponse = await postData(
        dependentData,
        "masters/getDependentMasterList"
      );
      setIsLoading(false);
      var code = dependentCountryDataResponse.code;
      if (code == 1) {

        setIsLoading(false);
        // setCountryLength(dependentCountryDataResponse.data.length);
        setStateNameData(dependentCountryDataResponse.data);
      } else {
        setIsLoading(false);
        //alert(dependentCountryDataResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  // Get dependent City by state
  const getDependentStateMaster = async (state_id) => {
    // console.log("state_id masters===", state_id);
    setIsLoading(true);
    try {
      var dependentData = JSON.stringify({
        column: "state_id",
        value: state_id,
        module: "city_master",
      });
      const dependentStateDataResponse = await postData(
        dependentData,
        "masters/getDependentMasterList"
      );
      setIsLoading(false);
      var code = dependentStateDataResponse.code;
      if (code == 1) {

        // setCountryLength(dependentStateDataResponse.data.length);
        setCityNameData(dependentStateDataResponse.data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        //alert(dependentStateDataResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  // Get dependent Tahasil by City
  const getDependentCityMaster = async (city_id) => {
    // console.log("city_id masters===", city_id);
    setIsLoading(true);
    try {
      var dependentData = JSON.stringify({
        column: "city_id",
        value: city_id,
        module: "tahasil_master",
      });
      const dependentCityDataResponse = await postData(
        dependentData,
        "masters/getDependentMasterList"
      );
      setIsLoading(false);
      var code = dependentCityDataResponse.code;
      if (code == 1) {

        // setCountryLength(dependentCityDataResponse.data.length);
        setTahasilNameData(dependentCityDataResponse.data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        //alert(dependentCityDataResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  // Get dependent village by Tahasil
  const getDependentTahasilMaster = async (tahasil_id) => {
    // console.log("tahasil_id masters===", tahasil_id);
    setIsLoading(true);
    try {
      var dependentData = JSON.stringify({
        column: "tahasil_id",
        value: tahasil_id,
        module: "village_master",
      });
      const dependentTahasilDataResponse = await postData(
        dependentData,
        "masters/getDependentMasterList"
      );
      setIsLoading(false);
      var code = dependentTahasilDataResponse.code;
      if (code == 1) {

        // setCountryLength(dependentTahasilDataResponse.data.length);
        setVillageNameData(dependentTahasilDataResponse.data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        //alert(dependentTahasilDataResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  //Usermaster Add Api
  const createUserMasterData = async () => {
    setIsLoading(true);
    // const interval = setInterval(() => {
    //   // setAnimating(!animating);
    //   setIsLoading(true);
    // }, 2000);
    let jsonValue = await AsyncStorage.getItem("userData");
    let projValue = await AsyncStorage.getItem("projectData");
    var userDetails = JSON.parse(jsonValue);
    try {
      var userMasterData = JSON.stringify({
        module: "user_master",
        data: {
          // employee_code: employeeCode,
          profile_pic: image,
          user_type: UserTypeValid,
          gender_id: genderchecked,
          first_name: firstName,
          last_name: LastName,
          mobile_number: MobileNumber,
          email_id: EmailId,
          country_id: countryValId,
          state_id: stateValId,
          city_id: cityValId,
          tahasil_id: tahasilValId,
          village_id: VillageValId,
          address: Address,
          dob: date,
          joining_date: joinDate,
          username: EmailId,
          password: Password,
          project_selected: projValue !== null ? projValue : userDetails.project_selected,
          pin: PinCode,
          marital_status: MaritialStatus,
          qualification: Qualification,
          active: isstatuschecked,
        },
        uniqueNo: {
          field_name: "employee-code",
          transactionId: 4,
        },
      });
      // console.log("User master payload===", userMasterData);
      const userMasterCreateResponse = await postData(
        userMasterData,
        "masters/masterCreateUpdate"
      );
      setIsLoading(false);

      var code = userMasterCreateResponse.code;
      if (code == 1) {
        setPopupMessage("Record Created Successfully");
        setissuccessVisible(true);
        setresponsestatus("success");
        // setIsVisible(false);
        // console.log("user added", userMasterCreateResponse.data);
        navigation.navigate("UserMaster");
      } else {
        setIsLoading(false);
        setissuccessVisible(true);
        setresponsestatus("failed");
        setPopupMessage(userMasterCreateResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  const pickImage = async () => {
    // No permissions request is necessary for launching the image library
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
      base64: true,
    });

    // console.log("result of imggg====", result.uri);
    setImage(result.uri);

    if (!result.cancelled) {
      setImage(result.uri);
    }
  };
  const screenwidth = Dimensions.get('window').width
  const isMobile = screenwidth < 768;
  return (
    <View style={styles.container}>
      <SuccessPopup
        visible={issuccessVisible}
        PopupMessage={PopupMessage}
        Responsestatus={responsestatus}
        onChange={() => setissuccessVisible(false)}
      />

      <View style={{ flexDirection: "row", width: "100%", marginTop: "2%" }}>
        <View style={{ flex: 4, margin: 10 }}>
          <Text style={styles.lableText}>
            Master {">"}{" "}
            <Text onPress={() => navigation.navigate("UserMasters")}>
              {" "}
              User Master{" "}
            </Text>{" "}
            {">"} Create User Master{">"}
          </Text>
        </View>
        {/* <View style={{ flex: 1 }}>
          <TouchableOpacity
            style={styles.addCountry}
            onPress={() => setIsVisible(true)}
          >
            <Text style={styles.btnText}>
              <Icon name="plus" size={15} color="#fff" /> Add Users
            </Text>
          </TouchableOpacity>
        </View> */}
      </View>
      {/* <ScrollView
        style={[styles.card, styles.shadowProp]}
        contentContainerStyle={{ flexGrow: 1 }}
      > */}
      <View style={[styles.card, { flexGrow: 1 }]}>
        <View
          style={[
            styles.shadowProp,
            {
              backgroundColor: "white",
              borderRadius: 8,
              paddingVertical: 45,
              paddingHorizontal: 25,
            },
          ]}
        >
          <View
            style={isMobile ? {
              // flex: 1,
              // justifyContent: "center",
              // alignItems: "center",
              // flexDirection: "row",
            } : {
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <View style={isMobile ? { flex: 1 } : { flex: 1, marginLeft: 100 }}>
              <View style={isMobile ? { flex: 1, alignItems: "flex-start", marginTop: -30, marginLeft: "33%" } : { flex: 1, alignItems: "flex-start", padding: 10 }}>
                {/* <Button title='Pick an image from camera roll'   color="#000000"
                   onPress={pickImage} /> */}
                <TouchableOpacity
                  style={{
                    backgroundColor: image ? "" : "#4848487D",
                    padding: 10,
                    borderRadius: 5,
                  }}
                  onPress={pickImage}
                >
                  <Image
                    source={
                      image
                        ? { uri: image }
                        : require("../../../assets/images/profileAvatar.png")
                    }
                    style={isMobile ? { width: 60, height: 60 } : { width: 100, height: 100 }}
                  />
                </TouchableOpacity>
              </View>
              <Text style={[styles.error_text]}>{imageError}</Text>
              <View style={isMobile ? { marginTop: 70 } : null}>
                <Text style={styles.lableText}>First name <Text style={[styles.lableText, { color: 'red' }]}>*</Text></Text>
                <TextInput
                  style={isMobile ? {
                    borderColor: "#c3c3c3",
                    width: "100%",
                    borderWidth: 1,
                    borderRadius: 4,
                    padding: 10,
                    marginTop: 4,
                    marginBottom: 6,
                  } : styles.input}
                  placeholder="Enter First name"
                  placeholderTextColor={"#484848"}
                  value={firstName}
                  onChangeText={(value) => {
                    setFirstNameValid(false);
                    setFirstName(value);
                    if (value.length == 0) {
                      setFirstNameError("Please enter first name");
                    } else if (!alpha_pattern.test(value)) {
                      setFirstNameError("Please enter character only");
                    } else {
                      setFirstNameValid(true);
                      setFirstNameError("");
                    }
                  }}
                />
                {/* <View> */}
                <Text style={[styles.error_text]}>{firstNameError}</Text>
                {/* </View> */}
              </View>
              <Text style={isMobile ? [styles.lableText, {marginTop:-5}] : styles.lableText}>Last name <Text style={[styles.lableText, { color: 'red' }]}>*</Text></Text>
              <TextInput
                style={isMobile ? {
                  borderColor: "#c3c3c3",
                  width: "100%",
                  borderWidth: 1,
                  borderRadius: 4,
                  padding: 10,
                  marginTop: 4,
                  marginBottom: 6,
                } : styles.input}
                placeholder="Enter Last name"
                placeholderTextColor={"#484848"}
                value={LastName}
                onChangeText={(value) => {
                  setLastNameValid(false);
                  setLastName(value);
                  if (value.length == 0) {
                    setLastNameError("Please enter last name");
                  } else if (!alpha_pattern.test(value)) {
                    setLastNameError("Please enter character only");
                  } else {
                    setLastNameValid(true);
                    setLastNameError("");
                  }
                }}
              />
              <View>
                <Text style={[styles.error_text]}>{lastNameError}</Text>
              </View>
              <Text style={isMobile ? [styles.lableText, {marginTop:-5}] : styles.lableText}>Email Id <Text style={[styles.lableText, { color: 'red' }]}>*</Text></Text>
              <TextInput
                style={isMobile ? {
                  borderColor: "#c3c3c3",
                  width: "100%",
                  borderWidth: 1,
                  borderRadius: 4,
                  padding: 10,
                  marginTop: 4,
                  marginBottom: 6,
                } : styles.input}
                placeholder="Enter Email"
                placeholderTextColor={"#484848"}
                value={EmailId}
                onChangeText={(value) => {
                  setEmailIdValid(false);
                  setEmailId(value.replace(/\s/g, ""));
                  if (value.length == 0) {
                    setEmailIdError("Please enter email id");
                  } else if (!Email_Id_pattern.test(value)) {
                    setEmailIdError("Please enter valid email id  ");
                  } else {
                    setEmailIdValid(true);
                    setEmailIdError("");
                  }
                }}
              />
              <Text style={[styles.error_text]}>{emailIdError}</Text>

              <Text style={isMobile ? [styles.lableText, {marginTop:-5}] : styles.lableText}>Mobile no. <Text style={[styles.lableText, { color: 'red' }]}>*</Text></Text>
              <TextInput
                style={isMobile ? {
                  borderColor: "#c3c3c3",
                  width: "100%",
                  borderWidth: 1,
                  borderRadius: 4,
                  padding: 10,
                  marginTop: 4,
                  marginBottom: 6,
                } : styles.input}
                placeholder="Enter Mobile number"
                placeholderTextColor={"#484848"}
                maxLength={10}
                keyboardType={"numeric"}
                onChangeText={(value) => {
                  setMobileNumberValid(false);
                  setMobileNumber(value);
                  if (value.length == 0) {
                    setMobileNumError("Please enter mobile number");
                  } else if (!regex.test(value)) {
                    setMobileNumError("Please enter number only");
                  } else if (value.length > 0 && value.length <= 9) {
                    setMobileNumError("Please enter 10 digit number");
                  } else {
                    setMobileNumberValid(true);
                    setMobileNumError("");
                  }
                }}
              />
              <Text style={[styles.error_text]}>{mobileNumError}</Text>

              <Text style={isMobile ? [styles.lableText, {marginTop:-5}] : styles.lableText}>User Type <Text style={[styles.lableText, { color: 'red' }]}>*</Text></Text>
              <View>
                <Dropdown
                  ref={dropdownRef}
                  style={isMobile ? {
                    borderColor: "#c3c3c3",
                    width: "100%",
                    borderWidth: 1,
                    borderRadius: 2,
                    height: 40,
                    outlineStyle: "none",
                    padding: 10,
                    marginTop: 5,
                    marginBottom: 5,
                  } : styles.dropdown}
                  placeholderStyle={styles.placeholderStyle}
                  selectedTextStyle={styles.selectedTextStyle}
                  inputSearchStyle={styles.inputSearchStyle}
                  iconStyle={styles.iconStyle}
                  data={UserTypeData}
                  search
                  maxHeight={300}
                  labelField="user_type"
                  valueField="user_type"
                  autoScroll={false}
                  placeholder={!isFocus ? "Select User Type" : "...."}
                  searchPlaceholder="Search..."
                  value={userTypeValue}
                  //onFocus={() => dropdownRef.current.open()}
                  onChange={(item) => {
                    setUserTypeValue(item.user_type);
                    setUserTypeValid(item.id);
                    seTUserTypeError("");
                    // console.log("user type value---", item.user_type);
                  }}
                  renderItem={renderUserTypeListItems}
                />
                <Text style={[styles.error_text]}>{usertypeError}</Text>
              </View>

              <Text style={isMobile ? [styles.lableText, {marginTop:-5}] : styles.lableText}>Gender</Text>
              <View style={{ flexDirection: "row" }}>
                <Text style={styles.genderNames}>Male</Text>
                <RadioButton
                  value="1"
                  status={genderchecked == 1 ? "checked" : "unchecked"}
                  onPress={() => setGenderChecked(1)}
                />
              
                <Text style={styles.genderNames}>Female</Text>
                <RadioButton
                  value="2"
                  status={genderchecked === 2 ? "checked" : "unchecked"}
                  onPress={() => setGenderChecked(2)}
                />
                {/* <Text style={styles.genderNames}>Other</Text>
                <RadioButton
                  value="3"
                  status={genderchecked === 3 ? "checked" : "unchecked"}
                  onPress={() => setGenderChecked(3)}
                /> */}
              </View>
              <View>
                <Text style={isMobile ? [styles.lableText, {marginTop:-5}] : styles.lableText}>Date of Birth</Text>
                <Text style={isMobile ? {
                  borderColor: "#c3c3c3",
                  width: "100%",
                  borderWidth: 1,
                  borderRadius: 4,
                  padding: 10,
                  marginTop: 4,
                  marginBottom: 6,
                } : styles.input} onPress={() => setOpen(true)}>
                  {moment(date).format("DD/MM/YYYY") ==
                    moment(new Date()).format("DD/MM/YYYY")
                    ? "DD/MM/YYYY "
                    : moment(date).format("DD/MM/YYYY")}
                </Text>
                {/* <TouchableOpacity></TouchableOpacity> */}
                {/* <Button uppercase={false} mode="outlined">
                Pick single date
              </Button> */}
                <DatePickerModal
                  locale="en"
                  mode="single"
                  visible={open}
                  onDismiss={onDismissSingle}
                  date={date}
                  onConfirm={onConfirmSingle}
                  validRange={{
                    endDate: new Date(), // optional
                  }}
                  // onChange={} // same props as onConfirm but triggered without confirmed by user
                  // saveLabel="Save" // optional
                  // saveLabelDisabled={true} // optional, default is false
                  // uppercase={false} // optional, default is true
                  label="Select date" // optional
                  // animationType="slide" // optional, default is 'slide' on ios/android and 'none' on web
                  // startYear={2000} // optional, default is 1800
                  // endYear={2100} // optional, default is 2200
                  closeIcon="close" // optional, default is "close"
                  // editIcon="pencil" // optional, default is "pencil"
                  calendarIcon="calendar" // optional, default is "calendar"
                />
              </View>
              <View>
                <Text style={isMobile ? [styles.lableText, {marginTop:-5}] : styles.lableText}>Country</Text>
                <Dropdown
                  ref={dropdownRefcountry}
                  style={isMobile ? {
                    borderColor: "#c3c3c3",
                    width: "100%",
                    borderWidth: 1,
                    borderRadius: 2,
                    height: 40,
                    outlineStyle: "none",
                    padding: 10,
                    marginTop: 5,
                    marginBottom: 5,
                  } : styles.dropdown}
                  placeholderStyle={styles.placeholderStyle}
                  selectedTextStyle={styles.selectedTextStyle}
                  inputSearchStyle={styles.inputSearchStyle}
                  iconStyle={styles.iconStyle}
                  data={CountryNameData}
                  search
                  maxHeight={300}
                  labelField="country"
                  valueField="country"
                  placeholder={!isFocus ? "Select Country" : "...."}
                  searchPlaceholder="Search..."
                  value={countryValue}
                  onChange={(item) => {
                    setCountryValue(item.country);
                    setCountryValId(item.id);
                    seTCountryError("");
                    getDependentCountryMaster(item.id);
                    // console.log("country id user master add", item);
                  }}
                  renderItem={renderCountryList}
                />
                <Text style={[styles.error_text]}>{countryError}</Text>
              </View>
              <View>
                <Text style={isMobile ? [styles.lableText, {marginTop:-5}] : styles.lableText}>State</Text>
                <Dropdown
                  ref={dropdownRefState}
                  style={isMobile ? {
                    borderColor: "#c3c3c3",
                    width: "100%",
                    borderWidth: 1,
                    borderRadius: 2,
                    height: 40,
                    outlineStyle: "none",
                    padding: 10,
                    marginTop: 5,
                    marginBottom: 5,
                  } : styles.dropdown}
                  placeholderStyle={styles.placeholderStyle}
                  selectedTextStyle={styles.selectedTextStyle}
                  inputSearchStyle={styles.inputSearchStyle}
                  iconStyle={styles.iconStyle}
                  data={StateNameData}
                  search
                  maxHeight={300}
                  labelField="state"
                  valueField="state"
                  placeholder={!isFocus ? "Select State" : "...."}
                  searchPlaceholder="Search..."
                  value={stateValue}
                  onChange={(item) => {
                    setStateValue(item.state);
                    setStateValId(item.id);
                    setStateError("");
                    getDependentStateMaster(item.id);
                    // console.log("state create value---add", item.id);
                  }}
                  renderItem={renderStateListItems}
                />
                <Text style={[styles.error_text]}>{stateError}</Text>
              </View>
              <View>
                <Text style={isMobile ? [styles.lableText, {marginTop:-5}] : styles.lableText}>District / City</Text>
                <Dropdown
                  ref={dropdownRefCity}
                  style={isMobile ? {
                    borderColor: "#c3c3c3",
                    width: "100%",
                    borderWidth: 1,
                    borderRadius: 2,
                    height: 40,
                    outlineStyle: "none",
                    padding: 10,
                    marginTop: 5,
                    marginBottom: 5,
                  } : styles.dropdown}
                  placeholderStyle={styles.placeholderStyle}
                  selectedTextStyle={styles.selectedTextStyle}
                  inputSearchStyle={styles.inputSearchStyle}
                  iconStyle={styles.iconStyle}
                  data={CityNameData}
                  search
                  maxHeight={300}
                  labelField="city"
                  valueField="city"
                  placeholder={!isFocus ? "Select City" : "...."}
                  searchPlaceholder="Search..."
                  value={cityValue}
                  onChange={(item) => {
                    setCityValue(item.city);
                    setCityError("");
                    setCityValId(item.id);
                    getDependentCityMaster(item.id);
                    // console.log("city create value---add", item.id);
                  }}
                  renderItem={renderCityListItems}
                />
                <Text style={[styles.error_text]}>{cityError}</Text>
              </View>
            </View>
            <View style={isMobile ? { flex: 1 } : { flex: 1, marginTop: 53 }}>
              <View>
                <Text style={isMobile ? [styles.lableText, {marginTop:-5}] : styles.lableText}>Tahasil</Text>
                <Dropdown
                  ref={dropdownRefTahasil}
                  style={isMobile ? {
                    borderColor: "#c3c3c3",
                    width: "100%",
                    borderWidth: 1,
                    borderRadius: 2,
                    height: 40,
                    outlineStyle: "none",
                    padding: 10,
                    marginTop: 5,
                    marginBottom: 5,
                  } : styles.dropdown}
                  placeholderStyle={styles.placeholderStyle}
                  selectedTextStyle={styles.selectedTextStyle}
                  inputSearchStyle={styles.inputSearchStyle}
                  iconStyle={styles.iconStyle}
                  data={TahasilNameData}
                  search
                  maxHeight={300}
                  labelField="tahasil_name"
                  valueField="tahasil_name"
                  placeholder={!isFocus ? "Select Tahasil" : "...."}
                  searchPlaceholder="Search..."
                  value={tahasilValue}
                  onChange={(item) => {
                    setTahasilValue(item.tahasil_name);
                    setTahasilValId(item.id);
                    getDependentTahasilMaster(item.id);
                    setTahasilError("");

                  }}
                  renderItem={renderTahasilListItems}
                />
                <Text style={[styles.error_text]}>{tahasilError}</Text>
              </View>
              <View>
                <Text style={isMobile ? [styles.lableText, {marginTop:-5}] : styles.lableText}>Village</Text>
                <Dropdown
                  ref={dropdownRefVillage}
                  style={isMobile ? {
                    borderColor: "#c3c3c3",
                    width: "100%",
                    borderWidth: 1,
                    borderRadius: 2,
                    height: 40,
                    outlineStyle: "none",
                    padding: 10,
                    marginTop: 5,
                    marginBottom: 5,
                  } : styles.dropdown}
                  placeholderStyle={styles.placeholderStyle}
                  selectedTextStyle={styles.selectedTextStyle}
                  inputSearchStyle={styles.inputSearchStyle}
                  iconStyle={styles.iconStyle}
                  data={VillageNameData}
                  search
                  maxHeight={300}
                  labelField="village_name"
                  valueField="village_name"
                  placeholder={!isFocus ? "Select Village" : "...."}
                  searchPlaceholder="Search..."
                  value={VillageValue}
                  onChange={(item) => {
                    setVillageValue(item.village_name);
                    setVillageValId(item.id);
                    // console.log("village value---", item.id);
                    setVillageError("");
                  }}
                  renderItem={renderVillageListItems}
                />
                <Text style={[styles.error_text]}>{villageError}</Text>
              </View>
              <Text style={isMobile ? [styles.lableText, {marginTop:-5}] : styles.lableText}>Address </Text>
              <TextInput
                multiline
                numberOfLines={2}
                placeholderTextColor={"#484848"}
                style={isMobile ? {
                  borderColor: "#c3c3c3",
                  width: "100%",
                  borderWidth: 1,
                  borderRadius: 4,
                  padding: 10,
                  marginTop: 4,
                  marginBottom: 6,
                } : [styles.input, { marginBottom: 0 }]}
                // onChangeText={onChangeNumber}
                // value={number}
                placeholder="Enter Address"
                maxLength={500}
                // keyboardType="numeric"
                onChangeText={(value) => {
                  setAddressValid(false);
                  setAddress(value);
                  if (alpha_pattern.test(value)) {
                    setAddressValid(true);
                    //   setButtonDisabled(false);
                  }
                }}
              />{" "}
              <Text style={isMobile ? [styles.lableText, {marginTop:-5}] : styles.lableText}>Pin</Text>
              <TextInput
                style={isMobile ? {
                  borderColor: "#c3c3c3",
                  width: "100%",
                  borderWidth: 1,
                  borderRadius: 4,
                  padding: 10,
                  marginTop: 4,
                  marginBottom: 6,
                } : styles.input}
                maxLength={6}
                placeholder="Enter Pincode"
                placeholderTextColor={"#484848"}
                onChangeText={(value) => {
                  setPinCodeValid(false);
                  setPinCode(value);
                  if (value.length == 0) {
                    setPinError("Please enter pin");
                  } else if (!number_regex.test(value)) {
                    setPinError("Please enter number only");
                  } else if (value.length > 0 && value.length < 6) {
                    setPinError("Please enter 6 digit pin");
                  } else {
                    setPinError("");
                    setPinCodeValid(true);
                  }
                }}
              />
              <Text style={[styles.error_text]}>{pinError}</Text>
              <Text style={isMobile ? [styles.lableText, {marginTop:-5}] : styles.lableText}>Qualification</Text>
              <TextInput
                style={isMobile ? {
                  borderColor: "#c3c3c3",
                  width: "100%",
                  borderWidth: 1,
                  borderRadius: 4,
                  padding: 10,
                  marginTop: 4,
                  marginBottom: 6,
                } : styles.input}
                placeholder="Enter Qualification"
                placeholderTextColor={"#484848"}
                onChangeText={(value) => {
                  setQualificationValid(false);
                  setQualification(value);
                  if (value.length == 0) {
                    setQualificationError("Please enter qualification");
                  } else if (!alpha_pattern.test(value)) {
                    setQualificationError("Please enter character");
                  } else {
                    setQualificationError("");
                    setQualificationValid(true);
                  }
                }}
              />
              <Text style={[styles.error_text]}>{qualification_Error}</Text>
              <Text style={isMobile ? [styles.lableText, {marginTop:-5}] : styles.lableText}>Maritial Status</Text>
              <View style={{ flexDirection: "row" }}>
                <Text style={styles.genderNames}>Married</Text>
                <RadioButton
                  value="Married"
                  status={
                    MaritialStatus === "Married" ? "checked" : "unchecked"
                  }
                  onPress={() => setMaritialStatus("Married")}
                />
                <Text style={styles.genderNames}>Unmarried</Text>
                <RadioButton
                  value="UnMarried"
                  status={
                    MaritialStatus === "UnMarried" ? "checked" : "unchecked"
                  }
                  onPress={() => setMaritialStatus("UnMarried")}
                />
              </View>
              {/* <View>
                <Text style={styles.lableText}>Joining Date </Text>

                <Text style={styles.input} onPress={() => setJoinOpen(true)}>
                  {moment(joinDate).format("DD/MM/YYYY") ==
                    moment(new Date()).format("DD/MM/YYYY")
                    ? "DD/MM/YYYY "
                    : moment(joinDate).format("DD/MM/YYYY")}
                </Text>

                <DatePickerModal
                  locale="en"
                  mode="single"
                  visible={joinOpen}
                  onDismiss={onDismissJoin}
                  date={joinDate}
                  onConfirm={onConfirmJoin}
                  validRange={{
                    endDate: new Date(), // optional
                  }}
                  label="Select date" // optional
                  closeIcon="close" // optional, default is "close"
                  calendarIcon="calendar" // optional, default is "calendar"
                />
              </View> */}
              <View
                style={isMobile ? {
                  backgroundColor: "#F2F2F2",
                  borderRadius: 10,
                  padding: 10,
                  margin: 7,
                  width: "100%",
                } : {
                  backgroundColor: "#F2F2F2",
                  borderRadius: 10,
                  padding: 10,
                  margin: 7,
                  width: "70%",
                }}
              >
                <Text style={isMobile ? [styles.lableText, {marginTop:-5}] : styles.lableText}>Login <Text style={[styles.lableText, { color: 'red' }]}>*</Text></Text>
                <TextInput
                  style={styles.input1}
                  placeholder="Enter Login id"
                  value={EmailId}
                  editable={false}
                  onChangeText={(value) => {
                    setEmailIdValid(false);
                    setEmailId(value.replace(/\s/g, ""));
                    if (value.length == 0) {
                      setEmailIdError("Please enter email id");
                    } else if (!Email_Id_pattern.test(value)) {
                      setEmailIdError("Please enter valid email id  ");
                    } else {
                      setEmailIdValid(true);
                      setEmailIdError("");
                    }
                  }}
                />
                <Text style={[styles.error_text]}>{loginIdError}</Text>
                <Text style={styles.lableText}>Password <Text style={[styles.lableText, { color: 'red' }]}>*</Text></Text>
                <TextInput
                  placeholder="Enter Password"
                  placeholderColor="#F0F0F0"
                  style={styles.input1}
                  secureTextEntry={true}
                  onChangeText={(value) => {
                    setPasswordValid(false);
                    setPassword(value);
                    if (value.length == 0) {
                      setPasswordError("Please enter password");
                    } else {
                      setPasswordError("");
                      setPasswordValid(true);
                    }
                  }}
                />{" "}
                <Text style={[styles.error_text]}>{passwordError}</Text>
              </View>
              <Text style={styles.lableText}>Status</Text>
              <View style={{ flexDirection: "row" }}>
                <Text style={[styles.statusNames, { color: "#05D6B3" }]}>
                  Active
                </Text>
                <RadioButton
                  value="1"
                  status={isstatuschecked === 1 ? "checked" : "unchecked"}
                  onPress={() => setStatusChecked(1)}
                />
                <Text style={[styles.statusNames, { color: "#E11A1A" }]}>
                  InActive
                </Text>
                <RadioButton
                  value="0"
                  status={isstatuschecked === 0 ? "checked" : "unchecked"}
                  onPress={() => setStatusChecked(0)}
                />{" "}
              </View>
            </View>
          </View>
          {isLoading ? (
            <ActivityIndicator
              //visibility of Overlay Loading Spinner
              color="#1DA1F2"
              visible={isLoading}
              //Text with the Spinner
              textContent={"Loading..."}
              size={60}
            //  //Text style of the Spinner Text
            //  textStyle={styles.spinnerTextStyle}
            />
          ) : (
            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                marginHorizontal: 20,
              }}
            >
              <TouchableOpacity
                style={isMobile ? {
                  alignItems: "center",
                  backgroundColor: "#006EB9",
                  padding: 10,
                  borderWidth: 1,
                  borderColor: "#006EB9",
                  width: "40%",
                  marginRight: 10,
                } : styles.saveBtn}
                onPress={() => CheckEnteredData()}
              >
                <Text style={styles.btnText}>Save</Text>
              </TouchableOpacity>

              <TouchableOpacity
                style={isMobile ? {
                  alignItems: "center",
                  backgroundColor: "#006EB9",
                  padding: 10,
                  borderWidth: 1,
                  borderColor: "#006EB9",
                  width: "40%",
                  marginRight: 10,
                } : styles.saveBtn}
                onPress={() => navigation.navigate("UserMaster")}
              >
                <Text style={styles.btnText}>Cancel</Text>
              </TouchableOpacity>
            </View>
          )}
          {/* </ScrollView> */}
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    // marginTop: 150,
  },
  genderNames: {
    margin: 7,
  },
  statusNames: {
    margin: 7,
  },
  containeralt: {
    // alignItems: "center",
    backgroundColor: "white",
    borderColor: "#eee",
    borderRadius: 10,
    borderWidth: 1,
    // justifyContent: "center",
    height: "100%",
    marginRight: "10%",
    marginTop: "0%",
    margin: "auto",
    padding: 30,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    // marginRight: 10,
    // marginRight: 1,
    width: "70%",
  },
  input: {
    borderColor: "#c3c3c3",
    width: "70%",
    borderWidth: 1,
    borderRadius: 4,

    padding: 10,
    marginTop: 4,
    marginBottom: 6,
  },
  input1: {
    borderColor: "#c3c3c3",
    width: "100%",
    borderWidth: 1,
    borderRadius: 4,

    padding: 10,
    marginTop: 4,
    marginBottom: 6,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  addCountry: {
    alignItems: "center",
    backgroundColor: "#006EB9",
    padding: 10,
    borderWidth: 1,
    borderColor: "#006EB9",
    width: "70%",
    // marginTop: 20,
  },
  saveBtn: {
    alignItems: "center",
    backgroundColor: "#006EB9",
    padding: 10,
    borderWidth: 1,
    borderColor: "#006EB9",
    width: "20%",
    marginRight: 10,
  },
  btnText: {
    color: "#fff",
    fontWeight: "600",
  },
  datePicker: {
    justifyContent: "center",
    alignItems: "flex-start",
    width: 320,
    height: 260,
    display: "flex",
  },
  card: {
    // backgroundColor: "white",
    // borderRadius: 8,
    // paddingVertical: 45,
    // paddingHorizontal: 25,
    // width: "100%",
    // flex: 1,

    // marginVertical: 10,
    // paddingBottom: "48%",
    width: "100%",
    flex: 1,
    marginVertical: 0,
  },
  shadowProp: {
    shadowColor: "#171717",
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 25,
  },

  link: {
    marginTop: 15,
    paddingVertical: 15,
  },
  error_text: {
    color: "red",
    fontSize: 14,
    fontWeight: "600",
    width: "70%",
    marginLeft: 0,
    marginBottom: 10,
  },
  linkText: {
    fontSize: 14,
    color: "#2e78b7",
  },

  // DropDown CSS
  dropdown: {
    borderColor: "#c3c3c3",
    width: "70%",
    borderWidth: 1,
    borderRadius: 2,
    height: 40,
    outlineStyle: "none",
    padding: 10,
    marginTop: 5,
    marginBottom: 5,
    // borderRadius: 10,
    // padding: 12,
    // shadowColor: "#000",
    // shadowOffset: {
    //   width: 0,
    //   height: 1,
    // },
    // shadowOpacity: 0.2,
    // shadowRadius: 1.41,

    // elevation: 2,
  },
  icon: {
    marginRight: 5,
  },
  item: {
    padding: 9,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  textItem: {
    flex: 1,
    fontSize: 16,
  },
  placeholderStyle: {
    fontSize: 16,
  },
  selectedTextStyle: {
    fontSize: 16,
  },
  iconStyle: {
    width: 20,
    height: 20,
  },
  inputSearchStyle: {
    height: 40,
    fontSize: 16,
    outlineStyle: "none",
  },
  lableText: {
    color: "#484848",
    fontWeight: "700",
    marginTop: 18,
    // fontSize: 21,
  },
});
