import React, { Component, useEffect } from 'react';
import { StyleSheet, View, Text } from 'react-native';

import { getMethod, postData } from '../../networking/api';


export default function DownloadSchedule({ }) {

    useEffect(() => {
        downloadDocument();
    }, [])

    const downloadDocument = async () => {
        try {
            const downloadDocumentResponse = await getMethod(
                "masters/downloadProgram/1"
            );
            var code = downloadDocumentResponse.code;
            // console.log("downloadDocumentResponse", downloadDocumentResponse);
            if (code == 1) {
                //alert(downloadDocumentResponse.data);
            } else {
                //alert(downloadDocumentResponse.message);
            }
        } catch (error) {
            // console.log(error);
        }
    }


    return (
        <View style={styles.MainContainer}>

        </View>
    );
}


const styles = StyleSheet.create({
    MainContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        // alignItems: 'center',
        // justifyContent: 'flex-end',
        flex: 1,
        // alignItems: "center",
        // justifyContent: "center",
        padding: 20,
    },
    mapStyle: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    },
});  