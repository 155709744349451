import {
  StyleSheet,
  TouchableOpacity,
  ScrollView,
  Switch,
  Modal,
  Dimensions,
} from "react-native";
import { Text, View } from "../../components/Themed";
import { jsonToCSV } from "react-native-csv";
import AsyncStorage from "@react-native-async-storage/async-storage";
import moment from "moment";
import { RootStackScreenProps } from "../../types"; // @ts-ignore
import React, { useEffect, useState, useTransition } from "react";
import Icon from "react-native-vector-icons/AntDesign";
import { useFocusEffect } from "@react-navigation/native";
import IconCross from "react-native-vector-icons/Entypo";
import { baseUrl, postData } from "../../networking/api";
// import { DataTable } from "react-native-paper";
import { Provider as PaperProvider } from "react-native-paper";
import IconEdit from "react-native-vector-icons/FontAwesome";
import { TextInput } from "react-native-paper";

import IconEye from "react-native-vector-icons/Entypo";
import { color } from "react-native-elements/dist/helpers";
import DataTable from "react-data-table-component";
import { PROVIDER_GOOGLE } from "react-native-maps";
import { useSelector } from "react-redux";

export default function Feedback({ navigation }) {
  const [text, setText] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  var [counter, setCounter] = useState(1);

  const [isLoading, setIsLoading] = useState(false);
  const [FeedbackDataLength, setFeedbackDataLength] = useState(0);
  const [FeedbackNameData, setFeedbackNameData] = useState([]);
  const [projectId, setProjectId] = useState("");

  const [searchData, setSearch] = useState("");
  const [filterData, setFilteredDataSource] = useState([]);
  const [isFeedbackNameDataValid, setFeedbackNameDataValid] = useState(false);
  const [isFeedbackNameDataEntered, setFeedbackNameDataEntered] =
    useState(false);
  const [sort, setsort] = useState(0);
  const [editpopup, setEditPopup] = useState(false);
  const [FeedbackPopup, setFeedbackPopup] = useState(false);
  const [feedback, setfeedback] = useState("");
  const [popid, setpopid] = useState("");
  const [popindex, setpopindex] = useState("");

  // Datatable device profile column and style

  const customStyles = {
    rows: {
      style: {
        // maxWidth: 50,
        minHeight: "30px", // override the row height
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        backgroundColor: "#319ef0a3",
        minHeight: "40px",
        fontSize: 16,
        fontWeight: "600",
      },
    },
    headCells: {
      style: {
        // paddingLeft: '8px', // override the cell padding for head cells
        // paddingRight: '8px',
        justifyContent: "center",
        minHeight: "20px",

        // borderRightWidth: 2,
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          // fontSize: 16,
          // fontWeight: '600',
          // borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",

        justifyContent: "center",

        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          fontSize: 16,
          fontWeight: "400",
          // borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };
  const columns = [
    {
      name: "Sr.No",
      selector: (row) => row.index,
      //cell: (row, index) => index + 1,

      // sortable: true,
      width: "100px",
    },
    {
      name: "Farmer Id",
      selector: (row) => row.farmer_master.farmer_id,
      sortable: true,
      width: "100px",
    },
    {
      name: "Farmer Name",
      selector: (row) => row.farmer_master.farmer_name,
      sortable: true,
      width: "190px",
    },
    {
      name: "Mobile Number",
      selector: (row) => row.farmer_master.mobile_number,
      sortable: true,
      width: "170px",
    },
    {
      name: "Created At",
      selector: (row) => moment(row.createdAt).format("DD/MM/yyyy HH:mm"),
      sortable: true,
      width: "170px",
    },


    {
      name: "Feedback",
      selector: (row) => row.feedback,
      sortable: true,
      width: "190px",
    },
    {
      name: "Action",
      // selector: "feedback",
      // sortable: true,
      width: "100px",
      // selector: row => row.feedback,
      cell: (item) => (
        <TouchableOpacity
          onPress={() => {
            setFeedbackPopup(true), setfeedback(item.feedback);
          }}
        >
          <IconEdit
            name="eye"
            size={13}
            color="#fff"
            style={{
              padding: 6,
              backgroundColor: "#08588E",
              borderRadius: 12,
            }}
          />
        </TouchableOpacity>
      ),
    },
  ];
  const toggleSwitch = () => {
    var tempIndex = FeedbackNameData.findIndex((item) => item.id === popid);
    if (FeedbackNameData[tempIndex].active == 1) {
      FeedbackNameData[tempIndex].active = 0;
      activeFeedback(popid, 0);
    } else {
      FeedbackNameData[tempIndex].active = 1;
      activeFeedback(popid, 1);
    }
    setFeedbackNameData(FeedbackNameData);
  };
  //Pagination
  const optionsPerPage = [10, 25, 50, 100];

  const [page, setPage] = React.useState(0);
  const [itemsPerPage, setItemsPerPage] = React.useState(optionsPerPage[0]);
  const from = page * itemsPerPage;
  const to = Math.min((page + 1) * itemsPerPage, FeedbackDataLength);
  var idProject = useSelector((state) => state.idUpdating);

  React.useEffect(() => {
    setPage(0);
    setpopindex("");
    setEditPopup(false);
    setpopid("");
  }, [itemsPerPage]);

  useEffect(() => {
    // console.log("is press schdular");
  }, [idProject]);

  useFocusEffect(
    React.useCallback(() => {
      getAdminData();

      searchFilterFunction("");
    }, [])
  );

  const getAdminData = async () => {
    let jsonValue = await AsyncStorage.getItem("userData");
    // console.log("jsonValue:", jsonValue);
    let projValue = await AsyncStorage.getItem("projectData");

    // console.log("jsonValue & projValue:", jsonValue, projValue);
    if (projValue != null) {
      // console.log("project id select in subzone create", projValue);
      setProjectId(projValue);
      getAllFeedbackData([], projValue);
    } else if (jsonValue != null) {
      var userDetailsresponse = JSON.parse(jsonValue);
      // console.log("userDetailsresponse==>", userDetailsresponse);

      setProjectId(userDetailsresponse.project_selected);
      getAllFeedbackData([], userDetailsresponse.project_selected);
    }
  };
  const searchFilterFunction = (text) => {
    // Check if searched text is not blank
    if (text) {
      // Inserted text is not blank
      // Filter the masterDataSource
      // Update FilteredDataSource
      const newData = FeedbackNameData.filter(function (item) {
        const itemData = item.feedback
          ? item.feedback.toUpperCase()
          : "".toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      // console.log("new data search---->", newData);
      setFilteredDataSource(newData);
      setSearch(text);
      setPage(0);
    } else {
      // Inserted text is blank
      // Update FilteredDataSource with masterDataSource
      setFilteredDataSource(FeedbackNameData);
      setSearch(text);
      setPage(0);
    }
  };
  //isActive State
  const activeFeedback = async (cityId, isActive) => {
    setIsLoading(true);
    try {
      var FeedbackData = JSON.stringify({
        id: cityId,
        module: "feedback",
        data: {
          active: isActive,
        },
      });
      const activeFeedbackResponse = await postData(
        FeedbackData,
        "masters/masterCreateUpdate"
      );

      setIsLoading(false);

      var code = activeFeedbackResponse.code;
      if (code == 1) {
        // //alert(activeFeedbackResponse.message);
        setIsVisible(false);
        getAllFeedbackData([], projectId);
        // console.log("Feedback status changed", activeFeedbackResponse.data);
      } else {
        setIsLoading(false);
        // //alert(activeFeedbackResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };
  const exportCsvFeedbackData = async () => {
    setIsLoading(true);
    try {
      var getFeedbackData = JSON.stringify({
        module: "devices_master",
      });
      const FeedbackNameResponse = await postData(
        getFeedbackData,
        "masters/exportFile"
      );
      setIsLoading(false);
      var code = FeedbackNameResponse.code;
      if (code == 1) {


        // setFeedbackDataLength(FeedbackNameResponse.data.length);
        setFeedbackNameData(FeedbackNameResponse.data);
        // setFilteredDataSource(FeedbackNameResponse.data)
        // const results = jsonToCSV(FeedbackNameResponse.data);
        // // console.log("csv==", results);
      } else {
        setIsLoading(false);
        // //alert(FeedbackNameResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };
  // Get all Feedback list Api
  const getAllFeedbackData = async (sortdata: never[], pid) => {
    // console.log("projectId", pid);
    setIsLoading(true);
    if (sortdata.length > 0) {
      setFeedbackDataLength(sortdata.length);
      setFeedbackNameData(sortdata);
      setFilteredDataSource(sortdata);
    } else {
      try {
        var getFeedbackData = JSON.stringify({
          module: "feedback",
          relation: [
            {
              module: "farmer_master",
            },
          ],
        });
        const FeedbackNameResponse = await postData(
          getFeedbackData,
          "masters/getMasterList"
        );
        setIsLoading(false);
        var code = FeedbackNameResponse.code;
        if (code == 1) {

          setFeedbackDataLength(FeedbackNameResponse.data.length);
          setFeedbackNameData(FeedbackNameResponse.data);
          setFilteredDataSource(FeedbackNameResponse.data);
          const results = jsonToCSV(FeedbackNameResponse.data);
          // console.log("csv==", results);
        } else {
          setIsLoading(false);
          // //alert(FeedbackNameResponse.message);
        }
      } catch (error) {
        setIsLoading(false);
        // console.log(error);
      }
    }
  };
  const getDownloadExcel = async () => {
    // console.log("====================================");
    //GET request
    window.open(baseUrl + "masters/exportFeedback");

    return false;
  };
  const screenwidth = Dimensions.get('window').width
  const isMobile = screenwidth < 768;
  return (
    <View style={styles.container}>
      <View style={isMobile ? { width: "100%", marginTop: "40%" , paddingTop:"-20%"} : { flexDirection: "row", width: "100%", marginTop: "5%" }}>
        <View style={{ flex: 0.6, margin: 10 }}>
          <Text style={styles.lableText}>Feedback</Text>
        </View>
        <Modal
          visible={FeedbackPopup}
          animationType={"slide"}
          transparent={true}
          onRequestClose={() => {
            setFeedbackPopup(false);
          }}
        >
          <View style={[styles.contanerSwitch, { height: "20%" }]}>
            <TouchableOpacity
              style={{ alignSelf: "flex-end" }}
              onPress={() => setFeedbackPopup(false)}
            >
              <IconCross
                name="circle-with-cross"
                size={20}
                color="#000"
                style={{ bottom: 5, top: 0 }}
              />
            </TouchableOpacity>

            <Text style={[styles.tableHead, { marginBottom: 20 }]}>
              {feedback}
            </Text>
          </View>
        </Modal>
        <Modal
          visible={editpopup}
          animationType={"slide"}
          transparent={true}
          onRequestClose={() => {
            setEditPopup(false);
          }}
        >
          <View style={[styles.contanerSwitch]}>
            <TouchableOpacity
              style={{ alignSelf: "flex-end" }}
              onPress={() => setEditPopup(false)}
            >
              <IconCross
                name="circle-with-cross"
                size={20}
                color="#000"
                style={{ bottom: 5, top: 0 }}
              />
            </TouchableOpacity>

            <Text style={[styles.tableHead, { marginBottom: 20 }]}>
              Do You Want Update Status
            </Text>
            <View
              style={{
                flexDirection: "row",
                paddingRight: 10,
                justifyContent: "space-between",
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  setEditPopup(false), toggleSwitch();
                }}
                style={styles.saveBtn}
              >
                <Text style={{ color: "white" }}>Yes</Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  setEditPopup(false);
                }}
                style={styles.saveBtn}
              >
                <Text style={{ color: "white" }}>No</Text>
              </TouchableOpacity>
            </View>
          </View>
        </Modal>
        <View style={isMobile ? { alignItems: "center",marginTop:"10%" } : { alignItems: "center", flex: 0.9 }}>
          {/* <TouchableOpacity onPress={() => exportCsvZoneData()}><Text>Download CSV</Text></TouchableOpacity> */}

          <TextInput
            style={isMobile ? {
              height: 35,
              borderWidth: 2,
              paddingLeft: 10,
              margin: 0,
              borderColor: "#319ef0",
              backgroundColor: "#FFFFFF",
              width: "75%",
              borderRadius: 5,
              color: "#000",
              fontWeight: "400",
            } : styles.textInputStyle}
            onChangeText={(text) => searchFilterFunction(text)}
            value={searchData}
            underlineColor={"white"}
            theme={{
              colors: {
                text: "black",
                primary: "white",
                background: "transparent",
              },
            }}
            underlineColorAndroid="transparent"
            placeholder="Search Feedback"
            left={
              <TextInput.Icon
                name={() => <IconEdit name="search" size={20} color="#000" />} // where <Icon /> is any component from vector-icons or anything else
              />
            }
          />
        </View>

        <View style={{ flex: 1, alignItems: "flex-end" }}>
          {FeedbackNameData.length > 0 && (
            <TouchableOpacity
              style={styles.alertBtn}
              onPress={() => {
                getDownloadExcel();
              }}
            >
              <Text style={styles.btnText}>
                <Icon name="exclefile1" size={15} color="#fff" /> Download
              </Text>
            </TouchableOpacity>
          )}
        </View>
      </View>
      <View style={{ flexDirection: "row", width: "100%" }}>
        <View style={{ flex: 1 }}>
          {/* <TouchableOpacity
            style={styles.addDevice}
            // onPress={() => isCreatable()}
          >
            <Text style={styles.btnText}>
              <Icon name="plus" size={15} color="#fff" /> Add Country
            </Text>
          </TouchableOpacity> */}
        </View>
      </View>
      {/* <ScrollView
        style={[styles.card, styles.shadowProp]}
        contentContainerStyle={{ flexGrow: 1 }}
      > */}
      <View style={[styles.card, { flexGrow: 1 }]}>
        <View
          style={[
            styles.shadowProp,
            {
              backgroundColor: "white",
            },
          ]}
        >
          <View
            style={{
              width: "100%",
              // flex: 1,
              backgroundColor: "#F6F9F9",
              // paddingVertical: 45,
              // paddingHorizontal: 100,
            }}
          >
            <View
              style={{
                borderWidth: 1,
                borderColor: "#e3e0e0",
                width: "100%",
                alignSelf: "center",
              }}
            >
              <View>
                <DataTable
                  // title="Country"
                  columns={columns}
                  data={filterData}
                  customStyles={customStyles}
                  pagination
                />
              </View>
              {/* prvider datatable code here */}

              {/* ended */}
            </View>
          </View>
          {/* </ScrollView> */}
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
  },
  input: {
    borderColor: "gray",
    width: "60%",
    borderWidth: 1,
    borderRadius: 10,

    padding: 10,
  },
  tableHeader: {
    color: "#000",
    fontSize: 14,
    /* FontFamily: 'Muli' ,*/
    fontWeight: "700",
  },
  tableHead: {
    fontSize: 14,
    /* FontFamily: 'Muli' ,*/
    fontWeight: "400",
    color: "#000000",
  },
  addDevice: {
    alignItems: "center",
    backgroundColor: "#006EB9",
    padding: 10,
    borderWidth: 1,
    borderColor: "#006EB9",
    width: "50%",
    // marginTop: 20,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  btnText: {
    color: "#fff",
    fontWeight: "600",
  },
  card: {
    width: "88%",
    flex: 1,
    marginVertical: 10,
  },
  contanerSwitch: {
    backgroundColor: "white",
    borderColor: "#eee",
    borderRadius: 5,
    alignSelf: "center",
    borderWidth: 1,
    // justifyContent: "center",
    height: "25%",
    margin: "auto",
    padding: 10,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    // marginRight: 10,
    // marginRight: 1,
    width: "30%",
  },
  lableText: {
    color: "#484848",
    fontWeight: "700",
    marginTop: 18,
    // fontSize: 21,
  },
  saveBtn: {
    alignItems: "center",
    backgroundColor: "#006EB9",
    padding: 10,
    borderWidth: 1,
    borderColor: "#006EB9",
    width: "50%",
    marginRight: 10,
  },
  shadowProp: {
    shadowColor: "#171717",
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 25,
  },
  link: {
    marginTop: 15,
    paddingVertical: 15,
  },
  linkText: {
    fontSize: 14,
    color: "#2e78b7",
  },
  textInputStyle: {
    height: 35,
    borderWidth: 2,
    paddingLeft: 10,
    margin: 0,
    borderColor: "#319ef0",
    backgroundColor: "#FFFFFF",
    width: "45%",
    borderRadius: 5,
    color: "#000",
    fontWeight: "400",
  },
  alertBtn: {
    alignItems: "center",
    backgroundColor: "#747474",
    padding: 10,
    borderWidth: 1,
    borderColor: "#747474",
    width: "33%",
    // marginTop: 20,
    borderRadius: 10,
  },
});
