import Header from "../navigation/header";
import {
  StyleSheet,
  TouchableOpacity,
  TextInput,
  Modal,
  Button,
  Switch,
  FlatList,
  Alert,
  ScrollView,
} from "react-native";
import {
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger,
} from "react-native-popup-menu";
import { useFocusEffect } from "@react-navigation/native";
import { Dropdown } from "react-native-element-dropdown";

import { Text, View } from "../../../components/Themed";
import { RootStackScreenProps } from "../../../types"; // @ts-ignore
import { DataTable } from "react-native-paper";
import Icon from "react-native-vector-icons/AntDesign";
import IconEdit from "react-native-vector-icons/FontAwesome";
import IconEye from "react-native-vector-icons/Entypo";
import React, { useEffect, useState, useMemo, useRef } from "react";
import IconCross from "react-native-vector-icons/Entypo";
import AsyncStorage from "@react-native-async-storage/async-storage";
import AwesomeAlert from "react-native-awesome-alerts";
import { baseUrl, postData } from "../../../networking/api";
import IconCheck from "react-native-vector-icons/MaterialCommunityIcons";
import { Provider as PaperProvider, Checkbox } from "react-native-paper";

export default function ProjectLevelView({ navigation, route }) {
  const { levelId } = route?.params;

  // console.log("INSIDE VIEWWWWW", levelId);
  const [isVisible, setIsVisible] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAdd, setisAdd] = useState(false);
  var [counter, setCounter] = useState(1);
  const [iconTick, seticonTick] = useState("checkbox-blank-outline");
  const [havingSubLevel, setHavingSubLevel] = useState(0);
  const [havingDevice, setHavingDevice] = useState(0);
  const [activeStatus, setActiveStatus] = useState(0);
  const [ProjectLevelData, setProjectLevelData] = useState([]);
  const [ProjectTypeNameData, setProjectTypeNameData] = useState([]);
  const [ProjectsData, setProjectData] = useState([]);
  const [projectTypeValue, setProjectTypeValue] = useState("");
  const [projectTypeValId, setProjectTypeValueId] = useState("");
  const [error, setError] = useState("");
  const [sequenceErr, setSequenceErr] = useState("");

  const textCountryName = useRef("");
  const [countryLength, setCountryLength] = useState(0);
  const [searchData, setSearch] = useState("");
  const [filterData, setFilteredDataSource] = useState([]);

  const [LevelValue, setLevelValue] = useState("");
  const [LevelId, setLevelId] = useState();
  const [SequenceValue, setSequenceValue] = useState("");
  const [SequenceId, setSequenceId] = useState("");
  const textLevel = useRef("");
  const textSequence = useRef("");
  var levelViewArr = [
    {
      id: 1,
      project_type: 1,
      level_title: "ZONE",
      is_device_required: 1,
      is_sub_level: 1,
      is_child: 1,
      sequence: 1,
      parent_id: 0,
      active: 1,
      created_by: 1,
      created_at: "2022-10-20 11: 11:11",
      updated_by: 1,
      updated_at: "2022-10-20 11: 11:11",
      childData: [
        {
          id: 2,
          project_type: 1,
          level_title: "Sub ZONE",
          is_device_required: 1,
          is_sub_level: 1,
          is_child: 1,
          sequence: 1,
          parent_id: 1,
          active: 1,
          created_by: 1,
          created_at: "2022-10-20 11: 11:11",
          updated_by: 1,
          updated_at: "2022-10-20 11: 11:11",
          childData: [
            {
              id: 3,
              project_type: 1,
              level_title: "Ground Zone",
              is_device_required: 1,
              is_sub_level: 0,
              is_child: 0,
              sequence: 1,
              parent_id: 2,
              active: 1,
              created_by: 1,
              created_at: "2022-10-20 11:11: 11",
              updated_by: 1,
              updated_at: "2022-10-20 11:11: 11",
            },
          ],
        },
      ],
    },
  ];

  var athletes = [
    {
      athlete_id: 123,
      first_name: "john",
      last_name: "doe",
      teams: [
        {
          team_id: 4,
          team_name: "Eagles",

          teams: [{ team_id: 4, team_name: "Sahil" }],
        },
      ],
    },
  ];

  // const toggleSwitch = () => setIsActive(0);
  const toggleSwitch = (id, index) => {
    if (ProjectLevelData[index].active == 1) {
      ProjectLevelData[index].active = 0;
      activeProjectLevel(id, 0);
    } else {
      ProjectLevelData[index].active = 1;
      activeProjectLevel(id, 1);
    }
    setProjectLevelData(ProjectLevelData);
  };

  //Pagination
  const optionsPerPage = [10, 25, 50, 100];

  const [page, setPage] = React.useState(0);
  const [itemsPerPage, setItemsPerPage] = React.useState(optionsPerPage[0]);
  const from = page * itemsPerPage;
  const to = Math.min((page + 1) * itemsPerPage, countryLength);
  React.useEffect(() => {
    setPage(0);
  }, [itemsPerPage]);
  const isCreatable = () => {
    setIsVisible(true);
    setisAdd(true);
  };
  useFocusEffect(
    React.useCallback(() => {
      getProjectSubLevelData();
    }, [])
  );

  const isAddable = (
    ptId,
    project_type,
    deviceRequired,
    level_title,
    sub_level,
    sequence,
    active
  ) => {
    setIsVisible(true);
    setisAdd(false);
    // getProjectSubLevelDataById(countryId);
    // setCountryByName(country_name);
    // textCountryName.current = country_name;
    setProjectTypeValueId(ptId);
    setLevelValue(level_title);
    setSequenceValue(sequence);
    setHavingDevice(deviceRequired);
    setHavingSubLevel(sub_level);
    setActiveStatus(active);
  };
  const searchFilterFunction = (text) => {
    // Check if searched text is not blank
    if (text) {
      // Inserted text is not blank
      // Filter the masterDataSource
      // Update FilteredDataSource
      const newData = ProjectLevelData.filter(function (item) {
        const itemData = item.country
          ? item.country.toUpperCase()
          : "".toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      // console.log("new data search---->", newData);
      setFilteredDataSource(newData);
      setSearch(text);
    } else {
      // Inserted text is blank
      // Update FilteredDataSource with masterDataSource
      setFilteredDataSource(ProjectLevelData);
      setSearch(text);
    }
  };

  const _renderItem = (item, index) => (
    <DataTable.Row key={item.id}>
      <DataTable.Cell>{counter++}</DataTable.Cell>

      <DataTable.Cell>{item.project_type}</DataTable.Cell>
      <DataTable.Cell>{item.level_title}</DataTable.Cell>
      <DataTable.Cell>{item.sequence}</DataTable.Cell>
      <DataTable.Cell>{item.is_device_required}</DataTable.Cell>
      <DataTable.Cell>{item.is_sub_level}</DataTable.Cell>
      <DataTable.Cell>{item.parent_id}</DataTable.Cell>

      <DataTable.Cell numeric>
        {" "}
        <TouchableOpacity
          // onPress={() => setIsVisible(true)}
          style={{ marginRight: 10 }}
        >
          <IconEye
            name="eye"
            size={13}
            color="#fff"
            style={{
              padding: 6,
              backgroundColor: "#08588E",
              borderRadius: 12,
            }}
          />
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() =>
            isAddable(
              item.id,
              item.project_type,
              item.is_device_required,
              item.is_sub_level,
              item.sequence,
              item.active
            )
          }
        >
          <IconEdit
            name="pencil"
            size={13}
            color="#fff"
            style={{
              padding: 6,
              backgroundColor: "#08588E",
              borderRadius: 12,
            }}
          />
        </TouchableOpacity>
      </DataTable.Cell>
      <DataTable.Cell numeric>
        <Switch
          trackColor={{ false: "#767577", true: "#12DFC3" }}
          thumbColor={item.active == 1 ? "#fff" : "#f4f3f4"}
          onValueChange={() => {
            toggleSwitch(item.id, index);
          }}
          value={item.active == 1 ? true : false}
        />
      </DataTable.Cell>
    </DataTable.Row>
  );

  var alpha_pattern = new RegExp(/^[a-zA-Z]+(\s[a-zA-Z]+)?$/);
  // var alpha_pattern = new RegExp(/^[a-zA-Z]+(\s[a-zA-Z]+)?$/);
  var number_regex = new RegExp(/^[0-9]*$/);

  // var alpha_pattern = new RegExp(/^(?!\s*$).+/);

  useFocusEffect(
    React.useCallback(() => {
      getProjectTypeList();
    }, [])
  );

  const textToLevel = (text) => {
    textLevel.current = text.nativeEvent.text;
    // setStateValue(text.nativeEvent.text);
  };

  const showLevel = () => {
    return textLevel.current;
  };
  const textToSequence = (text) => {
    textSequence.current = text.nativeEvent.text;
    // setStateValue(text.nativeEvent.text);
  };
  const showSequence = () => {
    return textSequence.current;
  };

  const CheckEnteredData = async () => {
    var levels = showLevel() !== "" ? showLevel() : textLevel;
    var sequences = showSequence() !== "" ? showSequence() : textSequence;
    //setUserType(userTypes);
    if (levels == "" && sequences == "") {
      //alert("pls enter");
      setError("Please enter level Name");
      setSequenceErr("Please enter sequence Name");

      // console.log("empty");
      // setIsVisible(false);
    } else if (alpha_pattern.test(levels) && number_regex.test(sequences)) {
      {
        isAdd == true && setError("");
        setSequenceErr("");
        createProjectlevel(levels, sequences);
        setIsVisible(false);
      }

      {
        isAdd == false && setError("");
        setSequenceErr("");
        updateProjectLevelData(levels, sequences);
        setIsVisible(false);
      }
    } else {
      // console.log("falseśśś");
      setError("Please enter Character Only.");
      setSequenceErr("Please enter number only");
      // //alert("only character:", countryNames);
      // setIsVisible(false);
    }

    // navigation.replace('DrawerNavigation');
  };

  //Level Configurator  Add Api
  const createProjectlevel = async (levelTitle, sequence) => {
    // console.log("===========createProjectlevel-----------");
    setIsLoading(true);
    try {
      var levelprojectData = JSON.stringify({
        module: "project_type_level",
        data: {
          project_type: projectTypeValue,
          level_title: levelTitle,
          is_device_required: havingDevice,
          is_sub_level: havingSubLevel,
          is_child: 1,
          sequence: sequence,
          parent_id: 0,
          active: activeStatus,
          created_by: 1,
        },
      });


      const LevelProjectResponse = await postData(
        levelprojectData,
        "masters/masterCreateUpdate"
      );

      setIsLoading(false);

      var code = LevelProjectResponse.code;
      if (code == 1) {
        //alert(LevelProjectResponse.message);
        setIsVisible(false);
        getProjectSubLevelData();
        textCountryName.current = "";
        // console.log("Added", LevelProjectResponse.data);
      } else {
        setIsLoading(false);
        //alert(LevelProjectResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  const updateProjectLevelData = async (levelTitle, sequence) => {
    setIsLoading(true);
    try {
      var LevelData = JSON.stringify({
        id: LevelId,
        module: "project_type_level",
        data: {
          project_type: projectTypeValue,
          level_title: levelTitle,
          is_device_required: havingDevice,
          is_sub_level: havingSubLevel,
          is_child: 1,
          sequence: sequence,
          parent_id: 0,
          active: activeStatus,
          created_by: 1,
        },
      });
      // console.log("Payload for update level");
      const updateLevelRestponse = await postData(
        LevelData,
        "masters/masterCreateUpdate"
      );

      setIsLoading(false);

      var code = updateLevelRestponse.code;
      if (code == 1) {
        //alert(updateLevelRestponse.message);
        setIsVisible(false);
        getProjectSubLevelData();
        // setCountryByName("");
        textCountryName.current = "";
        // console.log("Updated level response", updateLevelRestponse.data);
      } else {
        setIsLoading(false);
        //alert(updateLevelRestponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  // Project Level Get ApiS
  const getProjectSubLevelData = async () => {
    setIsLoading(true);
    try {
      var LevelData = JSON.stringify({
        id: levelId,
      });
      const projectLevelRes = await postData(
        LevelData,
        "masters/getProjectTypeLevel"
      );
      setIsLoading(false);
      var code = projectLevelRes.code;
      if (code == 1) {
        // console.log("getProjectSubLevelData list====>", projectLevelRes.data);
        setProjectLevelData(projectLevelRes.data);
        setFilteredDataSource(projectLevelRes.data);
      } else {
        setIsLoading(false);
        //alert(projectLevelRes.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  //isActive Country
  const activeProjectLevel = async (countryId, isActive) => {
    setIsLoading(true);
    try {
      var LevelData = JSON.stringify({
        id: countryId,
        module: "country_master",
        data: {
          active: isActive,
        },
      });
      const activeProjectLevelResponse = await postData(
        LevelData,
        "masters/masterCreateUpdate"
      );

      setIsLoading(false);

      var code = activeProjectLevelResponse.code;
      if (code == 1) {
        // //alert(activeProjectLevelResponse.message);
        setIsVisible(false);
        // console.log("status changed", activeProjectLevelResponse.data);
      } else {
        setIsLoading(false);
        // //alert(activeProjectLevelResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };
  // project type Get Api
  const getProjectTypeList = async () => {
    setIsLoading(true);
    try {
      var ProjectTypeData = JSON.stringify({
        module: "project_type",
      });
      const ProjectTypeList = await postData(
        ProjectTypeData,
        "masters/getMasterList"
      );
      setIsLoading(false);
      var code = ProjectTypeList.code;
      if (code == 1) {
        // console.log("project list api====>", ProjectTypeList.data);
        setProjectTypeNameData(ProjectTypeList.data);
      } else {
        setIsLoading(false);
        //alert(ProjectTypeList.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };
  const renderProjectTypeItem = (item: any) => {
    // console.log("item---value", item.id);
    return (
      <View style={styles.item}>
        <Text style={styles.textItem}>{item.type}</Text>
        {item.id === projectTypeValId && (
          <Icon style={styles.icon} color="black" name="Safety" size={20} />
        )}
      </View>
    );
  };

  function CreateProjectLevelModal() {
    return (
      <>
        <Modal
          onRequestClose={() => setIsVisible(false)}
          transparent
          visible={isVisible}
        >
          <View style={[styles.containeralt]}>
            <TouchableOpacity onPress={() => setIsVisible(false)}>
              <IconCross
                name="circle-with-cross"
                size={20}
                color="#000"
                style={{ marginLeft: "90%", bottom: 5, top: 10 }}
              />
            </TouchableOpacity>
            <View style={{ flexDirection: "row", flex: 1 }}>
              <Text style={{ margin: 20 }}>Project Type</Text>
              {isAdd == true && (
                <Dropdown
                  style={styles.dropdown}
                  placeholderStyle={styles.placeholderStyle}
                  selectedTextStyle={styles.selectedTextStyle}
                  inputSearchStyle={styles.inputSearchStyle}
                  iconStyle={styles.iconStyle}
                  data={ProjectTypeNameData}
                  search
                  maxHeight={300}
                  labelField="type"
                  valueField="type"
                  placeholder="Select Project type"
                  searchPlaceholder="Search..."
                  value={projectTypeValue}
                  onChange={(item) => {
                    setProjectTypeValue(item.type);
                    setProjectTypeValueId(item.id);
                    // console.log("value===>", projectTypeValue);
                  }}
                  renderItem={renderProjectTypeItem}
                />
              )}
              {isAdd == false && (
                <Dropdown
                  style={styles.dropdown}
                  placeholderStyle={styles.placeholderStyle}
                  selectedTextStyle={styles.selectedTextStyle}
                  inputSearchStyle={styles.inputSearchStyle}
                  iconStyle={styles.iconStyle}
                  data={ProjectTypeNameData}
                  search
                  maxHeight={300}
                  labelField="type"
                  valueField="type"
                  placeholder="Select Project type"
                  searchPlaceholder="Search..."
                  value={projectTypeValue}
                  onChange={(item) => {
                    setProjectTypeValue(item.type);
                    setProjectTypeValueId(item.id);
                    // console.log("value===>", projectTypeValue);
                  }}
                  renderItem={renderProjectTypeItem}
                />
              )}
            </View>
            <View style={{ flexDirection: "row", flex: 1 }}>
              <View style={{ width: "30%" }}>
                <Text style={{ margin: 20 }}>Level</Text>
              </View>
              <View>
                {isAdd == true && (
                  <TextInput
                    style={styles.input}
                    placeholder=""
                    //
                    onChange={textToLevel}
                  />
                )}
              </View>

              {isAdd == false && (
                <TextInput
                  style={styles.input}
                  placeholder=""
                  value={LevelValue}
                  key="Edit"
                  autoFocus
                  onChangeText={(text) => {
                    setLevelValue(text);
                    // console.log("Kept LevelValue value:", LevelValue);
                  }}
                />
              )}
              <Text style={[styles.error_text]}>{error}</Text>
            </View>
            <View style={{ flexDirection: "row", flex: 1 }}>
              <View style={{ width: "30%" }}>
                {" "}
                <Text style={{ margin: 20 }}>Sequence</Text>
              </View>
              <View>
                {isAdd == true && (
                  <TextInput
                    style={styles.input}
                    placeholder=""
                    //
                    onChange={textToSequence}
                  />
                )}
              </View>
              {isAdd == false && (
                <TextInput
                  style={styles.input}
                  placeholder=""
                  value={SequenceValue}
                  key="Edit"
                  autoFocus
                  onChangeText={(text) => {
                    setSequenceValue(text);
                    // console.log("Kept SequenceValue", SequenceValue);
                  }}
                />
              )}
              <Text style={[styles.error_text]}>{sequenceErr}</Text>
            </View>
            <View style={{ flexDirection: "row", flex: 1 }}>
              <View style={{ width: "30%" }}>
                <Text style={{ margin: 20 }}>Having Sub Level</Text>
              </View>
              {isAdd == true && (
                <Checkbox
                  color="#009DF0"
                  status={havingSubLevel ? "checked" : "unchecked"}
                  onPress={() => {
                    if (!havingSubLevel) {
                      setHavingSubLevel(1);
                    } else {
                      setHavingSubLevel(0);
                    }
                  }}
                />
              )}
              {isAdd == false && (
                <Checkbox
                  color="#009DF0"
                  status={havingSubLevel ? "checked" : "unchecked"}
                  onPress={() => {
                    if (!havingSubLevel) {
                      setHavingSubLevel(1);
                    } else {
                      setHavingSubLevel(0);
                    }
                  }}
                />
              )}
            </View>
            <View style={{ flexDirection: "row", flex: 1 }}>
              <View style={{ width: "30%" }}>
                <Text style={{ margin: 20 }}>Having Device</Text>
              </View>
              {isAdd == true && (
                <Checkbox
                  color="#009DF0"
                  status={havingDevice ? "checked" : "unchecked"}
                  onPress={() => {
                    if (!havingDevice) {
                      setHavingDevice(1);
                    } else {
                      setHavingDevice(0);
                    }
                  }}
                />
              )}
              {isAdd == false && (
                <Checkbox
                  status={havingDevice ? "checked" : "unchecked"}
                  onPress={() => {
                    if (!havingDevice) {
                      setHavingDevice(1);
                    } else {
                      setHavingDevice(0);
                    }
                  }}
                />
              )}
            </View>
            <View style={{ flexDirection: "row", flex: 1 }}>
              <View style={{ width: "30%" }}>
                <Text style={{ margin: 20 }}>Active</Text>
              </View>
              {isAdd == true && (
                <Checkbox
                  status={activeStatus ? "checked" : "unchecked"}
                  onPress={() => {
                    if (!activeStatus) {
                      setActiveStatus(1);
                    } else {
                      setActiveStatus(0);
                    }
                  }}
                />
              )}
              {isAdd == false && (
                <Checkbox
                  status={activeStatus ? "checked" : "unchecked"}
                  onPress={() => {
                    if (!activeStatus) {
                      setActiveStatus(activeStatus);
                    } else {
                      setActiveStatus(activeStatus);
                    }
                  }}
                />
              )}
            </View>
            {isAdd == true && (
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  marginHorizontal: 20,
                }}
              >
                <TouchableOpacity
                  style={styles.saveBtn}
                  onPress={() => CheckEnteredData()}
                >
                  <Text style={styles.btnText}>Save</Text>
                </TouchableOpacity>

                <TouchableOpacity
                  style={styles.saveBtn}
                  onPress={() => setIsVisible(false)}
                >
                  <Text style={styles.btnText}>Cancel</Text>
                </TouchableOpacity>
              </View>
            )}
            {isAdd == false && (
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  marginHorizontal: 20,
                }}
              >
                <TouchableOpacity
                  style={styles.saveBtn}
                  onPress={() => CheckEnteredData()}
                >
                  <Text style={styles.btnText}>Update</Text>
                </TouchableOpacity>

                <TouchableOpacity
                  style={styles.saveBtn}
                  onPress={() => setIsVisible(false)}
                >
                  <Text style={styles.btnText}>Cancel</Text>
                </TouchableOpacity>
              </View>
            )}
          </View>
        </Modal>
      </>
    );
  }

  return (
    <View style={styles.container}>
      <CreateProjectLevelModal />

      <View style={{ flexDirection: "row", width: "100%", marginTop: "5%" }}>
        {" "}
        <View style={{ flex: 4, margin: 10 }}>
          <Text style={styles.lableText}>
            Master {">"} Level Configurator View{" "}
          </Text>
        </View>
        <View style={{ flex: 1 }}>
          <TouchableOpacity
            style={styles.addProjectLevelbtn}
            onPress={() => isCreatable()}
          >
            <Text style={styles.btnText}>
              <Icon name="plus" size={15} color="#fff" /> Create Project Level
            </Text>
          </TouchableOpacity>
        </View>
      </View>

      <View style={[styles.card, { flexGrow: 1 }]}>
        <View
          style={[
            styles.shadowProp,
            {
              backgroundColor: "white",
              borderRadius: 8,
              paddingVertical: 45,
              paddingHorizontal: 25,
            },
          ]}
        >
          <View
            style={{
              width: "100%",
              // flex: 1,
              backgroundColor: "#F6F9F9",
              borderRadius: 8,
              paddingVertical: 45,
              paddingHorizontal: 100,
            }}
          >
            {/* <View style={{ alignItems: 'flex-end' }}>
              <TextInput
                style={styles.textInputStyle}
                onChangeText={(text) => searchFilterFunction(text)}
                value={searchData}
                underlineColorAndroid="transparent"
                placeholder="Search Level"
              />

            </View> */}

            <View
              style={{
                borderWidth: 1,
                borderColor: "#e3e0e0",
                borderRadius: 2,
              }}
            >
              <View>
                {levelViewArr.map((item, key) => {
                  return (
                    <View key={key}>
                      <View
                        style={[
                          styles.shadowProp,
                          {
                            backgroundColor: "white",
                            borderRadius: 8,
                            paddingVertical: 45,
                            paddingHorizontal: 25,
                            borderWidth: 1,
                            marginEnd: 60,
                            marginBottom: 10,
                          },
                        ]}
                      >
                        <PaperProvider>
                          <DataTable>
                            <DataTable.Header>
                              <DataTable.Title style={{ flex: 1 }}>
                                Sr.No
                              </DataTable.Title>
                              <DataTable.Title style={{ flex: 1 }}>
                                Level
                              </DataTable.Title>
                              <DataTable.Title style={{ flex: 1 }}>
                                Sequence
                              </DataTable.Title>
                              <DataTable.Title style={{ flex: 1 }}>
                                Having Device
                              </DataTable.Title>
                              <DataTable.Title style={{ flex: 1 }}>
                                Having SubLevel
                              </DataTable.Title>
                              <DataTable.Title>Active</DataTable.Title>

                              <DataTable.Title>Sub Level</DataTable.Title>
                            </DataTable.Header>
                            <DataTable.Row key={item.id}>
                              <DataTable.Cell>{counter++}</DataTable.Cell>
                              <DataTable.Cell>
                                {item.level_title}
                              </DataTable.Cell>

                              <DataTable.Cell>{item.sequence}</DataTable.Cell>
                              <DataTable.Cell>
                                {item.is_device_required == 1 ? "Yes" : "No"}
                              </DataTable.Cell>
                              <DataTable.Cell>
                                {item.is_sub_level == 1 ? "Yes" : "No"}
                              </DataTable.Cell>
                              <DataTable.Cell>
                                {item.active == 1 ? "Yes" : "No"}
                              </DataTable.Cell>
                              <DataTable.Cell>
                                {item.is_sub_level == 1 ? (
                                  <Icon
                                    name="plus"
                                    size={30}
                                    color="#000"
                                    onPress={() => isCreatable()}
                                  />
                                ) : null}
                              </DataTable.Cell>
                            </DataTable.Row>
                          </DataTable>
                        </PaperProvider>
                      </View>
                      {item.childData.map((unit, key2) => {
                        return (
                          <View key={key2}>
                            <View
                              style={[
                                styles.shadowProp,
                                {
                                  backgroundColor: "white",
                                  borderRadius: 8,
                                  paddingVertical: 45,
                                  paddingHorizontal: 25,
                                  borderWidth: 1,
                                  marginLeft: 30,
                                  marginEnd: 20,
                                  marginBottom: 10,
                                },
                              ]}
                            >
                              <PaperProvider>
                                <DataTable>
                                  <DataTable.Header>
                                    <DataTable.Title style={{ flex: 1 }}>
                                      Sr.No
                                    </DataTable.Title>
                                    <DataTable.Title style={{ flex: 1 }}>
                                      Level
                                    </DataTable.Title>
                                    <DataTable.Title style={{ flex: 1 }}>
                                      Sequence
                                    </DataTable.Title>
                                    <DataTable.Title style={{ flex: 1 }}>
                                      Having Device
                                    </DataTable.Title>
                                    <DataTable.Title style={{ flex: 1 }}>
                                      Having SubLevel
                                    </DataTable.Title>
                                    <DataTable.Title>Active</DataTable.Title>

                                    <DataTable.Title>Sub Level</DataTable.Title>
                                  </DataTable.Header>
                                  <DataTable.Row key={unit.id}>
                                    <DataTable.Cell>{counter++}</DataTable.Cell>
                                    <DataTable.Cell>
                                      {unit.level_title}
                                    </DataTable.Cell>

                                    <DataTable.Cell>
                                      {unit.sequence}
                                    </DataTable.Cell>
                                    <DataTable.Cell>
                                      {unit.is_device_required == 1
                                        ? "Yes"
                                        : "No"}
                                    </DataTable.Cell>
                                    <DataTable.Cell>
                                      {unit.is_sub_level == 1 ? "Yes" : "No"}
                                    </DataTable.Cell>
                                    <DataTable.Cell>
                                      {unit.active == 1 ? "Yes" : "No"}
                                    </DataTable.Cell>
                                    <DataTable.Cell>
                                      <Icon
                                        name="plus"
                                        size={30}
                                        color="#000"
                                      />
                                    </DataTable.Cell>
                                  </DataTable.Row>
                                </DataTable>
                              </PaperProvider>
                            </View>

                            {unit.childData.map((unit1, key3) => {
                              return (
                                <View key={key3}>
                                  <View
                                    style={[
                                      styles.shadowProp,
                                      {
                                        backgroundColor: "white",
                                        borderRadius: 8,
                                        paddingVertical: 45,
                                        paddingHorizontal: 25,
                                        borderWidth: 1,

                                        marginLeft: 60,
                                      },
                                    ]}
                                  >
                                    <PaperProvider>
                                      <DataTable>
                                        <DataTable.Header>
                                          <DataTable.Title style={{ flex: 1 }}>
                                            Sr.No
                                          </DataTable.Title>
                                          <DataTable.Title style={{ flex: 1 }}>
                                            Level
                                          </DataTable.Title>
                                          <DataTable.Title style={{ flex: 1 }}>
                                            Sequence
                                          </DataTable.Title>
                                          <DataTable.Title style={{ flex: 1 }}>
                                            Having Device
                                          </DataTable.Title>
                                          <DataTable.Title style={{ flex: 1 }}>
                                            Having SubLevel
                                          </DataTable.Title>
                                          <DataTable.Title>
                                            Active
                                          </DataTable.Title>

                                          <DataTable.Title>
                                            Sub Level
                                          </DataTable.Title>
                                        </DataTable.Header>
                                        <DataTable.Row key={unit1.id}>
                                          <DataTable.Cell>
                                            {counter++}
                                          </DataTable.Cell>
                                          <DataTable.Cell>
                                            {unit1.level_title}
                                          </DataTable.Cell>

                                          <DataTable.Cell>
                                            {unit1.sequence}
                                          </DataTable.Cell>
                                          <DataTable.Cell>
                                            {unit1.is_device_required == 1
                                              ? "Yes"
                                              : "No"}
                                          </DataTable.Cell>
                                          <DataTable.Cell>
                                            {unit1.is_sub_level == 1
                                              ? "Yes"
                                              : "No"}
                                          </DataTable.Cell>
                                          <DataTable.Cell>
                                            {unit1.active == 1 ? "Yes" : "No"}
                                          </DataTable.Cell>
                                          <DataTable.Cell>
                                            <Icon
                                              name="plus"
                                              size={30}
                                              color="#000"
                                            />
                                          </DataTable.Cell>
                                        </DataTable.Row>
                                        {/* <Text key={key3}>{unit1.team_name}</Text> */}
                                      </DataTable>
                                    </PaperProvider>
                                  </View>
                                </View>
                              );
                            })}
                          </View>
                        );
                      })}
                    </View>
                  );
                })}
              </View>
            </View>
          </View>
        </View>
        {/* </ScrollView> */}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    // marginTop: 350,
    height: "100%",
  },
  itemStyle: {
    padding: 10,
  },
  textInputStyle: {
    height: 40,
    borderWidth: 1,
    paddingLeft: 20,
    margin: 5,
    borderColor: "#009688",
    backgroundColor: "#FFFFFF",
    width: "20%",
  },

  containeralt: {
    // alignItems: "center",
    backgroundColor: "white",
    borderColor: "#eee",
    borderRadius: 10,
    borderWidth: 1,
    // justifyContent: "center",
    height: 500,
    margin: "auto",
    padding: 30,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    // marginRight: 10,
    // marginRight: 1,
    width: "60%",
  },
  input: {
    borderColor: "gray",
    width: "100%",
    borderWidth: 1,
    borderRadius: 2,

    padding: 10,
    marginTop: 20,
    marginBottom: 20,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  addProjectLevelbtn: {
    alignItems: "center",
    backgroundColor: "#006EB9",
    padding: 10,
    borderWidth: 1,
    borderColor: "#006EB9",
    width: "100%",
    // marginTop: 20,
  },
  saveBtn: {
    alignItems: "center",
    backgroundColor: "#006EB9",
    padding: 10,
    borderWidth: 1,
    borderColor: "#006EB9",
    width: "50%",
    marginRight: 10,
  },
  btnText: {
    color: "#fff",
    fontWeight: "600",
  },
  card: {
    width: "100%",
    flex: 1,
    marginVertical: 10,
  },
  shadowProp: {
    shadowColor: "#171717",
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 15,
  },
  // DropDown CSS
  dropdown: {
    borderColor: "#c3c3c3",
    width: "50%",
    borderWidth: 1,
    borderRadius: 2,
    height: 40,

    padding: 10,
    marginTop: 5,
    marginBottom: 5,
    // borderRadius: 10,
    // padding: 12,
    // shadowColor: "#000",
    // shadowOffset: {
    //   width: 0,
    //   height: 1,
    // },
    // shadowOpacity: 0.2,
    // shadowRadius: 1.41,

    // elevation: 2,
  },
  icon: {
    marginRight: 5,
  },
  item: {
    padding: 9,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  textItem: {
    flex: 1,
    fontSize: 16,
  },
  placeholderStyle: {
    fontSize: 16,
  },
  selectedTextStyle: {
    fontSize: 16,
  },
  iconStyle: {
    width: 20,
    height: 20,
  },
  inputSearchStyle: {
    height: 40,
    fontSize: 16,
    outlineStyle: "none",
  },

  // Error message css
  error_text: {
    color: "red",
    fontSize: 14,
    fontWeight: "600",
    width: "70%",
    marginLeft: 0,
    marginBottom: 10,
  },

  link: {
    marginTop: 15,
    paddingVertical: 15,
  },
  linkText: {
    fontSize: 14,
    color: "#2e78b7",
  },
});
