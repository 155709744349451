/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import { LinkingOptions } from "@react-navigation/native";
import * as Linking from "expo-linking";

import { RootStackParamList } from "../types";

var urlName = window.location.pathname;
var id = urlName.substring(urlName.lastIndexOf("/") + 1);

//  var urlId = 1;
// console.log("urlname id", id);

// console.log("urlname linking", urlName);

const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [Linking.createURL("/")],
  config: {
    screens: {
      Root: {
        screens: {
          TabOne: {
            screens: {
              TabOneScreen: "one",
            },
          },
          TabTwo: {
            screens: {
              TabTwoScreen: "two",
            },
          },
        },
      },
      // FarmerDetails:{
      //       screens:{
      //         EditFarmerDetails:'EditFarmerDetails',
      //       }
      //       },
      HomeFarmer: {
        screens: {
          EditFarmerDetails: "EditFarmerDetails",
        },
      },
      Home: {
        // path: "home",
        screens: {
          Dashboard: "dashboard",
          ValveDetailsReport: "ValveDetailsReport",
          EnergyStatusDetailReport: "EnergyStatusDetailReport",
          DeviceDetailsReport: "DeviceDetailsReport",
          SensorDetailsReport: "SensorDetailsReport",
          DigitalDetailsReport: "DigitalDetailsReport",
          DigitalInputsReport: "DigitalInputsReport",
          // EditFarmerDetails:"EditFarmerDetails",
          Profile: "profile",
          DeviceProfile: {
            //  initialRouteName:'DeviceProfile',
            path: "DeviceProfile",
            screens: {
              DeviceProfile: "DeviceProfile",
              CreateDeviceProfile: "CreateDeviceProfile",
              EditDeviceProfile: "EditDeviceProfile",
            },
          },
          SubzoneProfile: {
            initialRouteName: "SubzoneProfile",
            path: "SubzoneProfile",
            screens: {
              SubzoneProfile: "SubzoneProfile",
              CreateSubzoneProfile: "CreateSubzoneProfile",
              EditSubzoneProfile: "EditSubzoneProfile",
              ViewSubzoneProfile: "ViewSubzoneProfile",
            },
          },
          ZoneProfile: {
            initialRouteName: "ZoneProfile",
            path: "ZoneProfile",
            screens: {
              ZoneProfile: "ZoneProfile",
              CreateZoneProfile: "CreateZoneProfile",
              EditZoneProfile: "EditZoneProfile",
              ViewZoneProfile: "ViewZoneProfile",
            },
          },
          ProjectProfile: {
            initialRouteName: "ProjectProfile",
            path: "ProjectProfile",
            screens: {
              ProjectProfile: "ProjectProfile",
              CreateProjectProfile: "CreateProjectProfile",
              EditProjectProfile: "EditProjectProfile",
              ViewProjectProfile: "ViewProjectProfile",
            },
          },

          FarmerProfile: {
            initialRouteName: "FarmerProfile",
            path: "FarmerProfile",
            screens: {
              FarmerProfile: "FarmerProfile",
              CreateFarmerProfile: "CreateFarmerProfile",
              EditFarmerProfile: "EditFarmerProfile",
            },
          },
          // EditFarmerDetails: {
          //   initialRouteName: "EditFarmerDetails",
          //   path: "EditFarmerDetails",
          //   screens: {
          //     EditFarmerDetails: "EditFarmerDetails",
          //   },
          // },

          // Report: {
          //   initialRouteName: "Report",
          //   path: "Report",
          //   screens: {
          //     Program: "Program",
          //     ValveAccumulation: "ValveAccumulation",
          //     WaterAccumulation: "WaterAccumulation",
          //     EnergtLog: "EnergtLog",
          //     Sensor: "Sensor",
          //   },
          // },
          OmegaDevice: "OmegaDevice",
          Feedback: "Feedback",
          Country: "country",
          City: "city",
          State: "states",
          Tahasil: "tahasil",
          Village: "village",
          DocumentType: "DocumentType",
          ProjectAccess: "ProjectAccess",
          UserAccess: "UserAccess",
          MapView: "MapView",

          Program: "Program",
          ValveAccumulation: "ValveAccumulation",
          WaterAccumulation: "WaterAccumulation",
          EnergyLog: "EnergyLog",
          Sensor: "Sensor",

          // GridView: 'GridView',
          GridView: {
            initialRouteName: "Monitor",
            path: "GridView",
            screens: {
              GridView: "GridView",
              Zone: {
                initialRouteName: "Zone",
                path: "Zone",
                screens: {
                  Zone: "Zone",
                },
              },
              Subzone: "Zone/Subzone",
              ValveView: "ValveView",
            },
          },
          ValveView: "ValveView",

          // Zone:{
          //   initialRouteName:'Zone',
          //   path: "Zone",
          //   screens: {
          //     Subzone:'Subzone',
          //   }
          //            },
          // AccessMaster:'AccessMaster',
          UserType: "UserType",
          UserMaster: {
            initialRouteName: "UserMaster",
            path: "UserMaster",
            screens: {
              UserMaster: "UserMaster",
              CreateUserMaster: "CreateUserMaster",
              EditUserMaster: "EditUserMaster",
            },
          },
          ProjectType: "ProjectType",
          ProjectLevel: {
            initialRouteName: "ProjectLevel",
            path: "ProjectLevel",
            screens: {
              ProjectLevel: "ProjectLevel",
              ProjectLevelView: "ProjectLevelView",
            },
          },

          // AccessMaster: {
          //   initialRouteName: 'AccessMaster',
          //   path: "AccessMaster",
          //   screens: {
          //     ProjectAccess: 'ProjectAccess',
          //     UserAccess: 'UserAccess',
          //   }
          // },
          Alerts: "Alerts",
          Reports: "Reports",
          ScheduleProgram: "ScheduleProgram",
          SchedularMapView: "SchedularMapView",
          UploadSchedule: "UploadSchedule",
          DownloadSchedule: "DownloadSchedule",
        },
      },
      Modal: "modal",
      NotFound: "*",
      Login: "login",
      FarmerLogin: "FarmerLogin",
      // Home: "home",
      // Dashboard: "dashboard",
      Reset: "reset",
      ChangePassword: "ChangePassword/" + id,

      // EditFarmerDetails:'EditFarmerDetails',

      // Home : 'home',
    },
  },
};

export default linking;
