import io from "socket.io-client";
import { baseUrlSocket } from "../../networking/api";
const SOCKET_URL = baseUrlSocket;

class WSService {
  [x: string]: any;
  initializeSocket = async () => {
    try {
      this.socket = io(SOCKET_URL, {
        transports: ["websocket"],
        reconnection: true,
        reconnectionDelay: 500,
        reconnectionAttempts: Infinity,
        // transports: ['polling'],
      });
      // const socket = io.connect("http://localhost:4001");

      console.log("initializing socket", this.socket);
      this.socket.on("connect", (data: any) => {
        console.log("=== socket connected====", data);
      });

      this.socket.on("disconnect", (data: any) => {
        console.log("=== socket disconnected====", data);
      });

      this.socket.on("error", (data: any) => {
        console.log("=== socket error====", data);
      });
    } catch (error) {
      console.log("socket is not initialized", error);
    }
  };
  socket: any;

  emit(event: any, data = {}) {
    this.socket.emit(event, data);
  }
  on(event: any, cb: any) {
    this.socket.on(event, cb);
  }
  removelistener(listenerName: any) {
    this.socket.removelistener(listenerName);
  }
}

const socketServices = new WSService();
export default socketServices;
