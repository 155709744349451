const projectName = (state = true, action) => {

    switch (action.type) {

        case "NAMEPROJECT":

            return !state

        default:

            return state

    }

}

export default projectName