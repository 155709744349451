import Header from "../navigation/header";
import {
  StyleSheet,
  TouchableOpacity,
  Modal,
  Button,
  Switch,
  TextInput as TextInputRN,
  FlatList,
  Alert,
  ScrollView,
  SafeAreaView,
  ActivityIndicator,
  Dimensions
} from "react-native";
import { useFocusEffect } from "@react-navigation/native";
import { Dropdown } from "react-native-element-dropdown";
import { DatePickerModal } from "react-native-paper-dates";

import { Text, View } from "../../components/Themed";
import { RootStackScreenProps } from "../../types"; // @ts-ignore
// import { DataTable } from "react-native-paper";
import Icon from "react-native-vector-icons/AntDesign";
import IconEdit from "react-native-vector-icons/FontAwesome";
import IconEye from "react-native-vector-icons/Entypo";
import React, { useEffect, useState, useMemo, useRef } from "react";
import IconCross from "react-native-vector-icons/Entypo";
import AsyncStorage from "@react-native-async-storage/async-storage";
import AwesomeAlert from "react-native-awesome-alerts";
import { TextInput } from "react-native-paper";
import { baseUrl, postData } from "../../networking/api";
import { Provider as PaperProvider } from "react-native-paper";
import { Item } from "react-native-paper/lib/typescript/components/List/List";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import moment from "moment";
import Victory from "../../constants/victory";
import CustomSwitch from "../../components/CustomSwitch";
import IconFontAwesome from "react-native-vector-icons/FontAwesome";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

export default function WaterAccumulation({ navigation }) {
  const [isVisible, setIsVisible] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [OmegeDeviceIds, setOmegeDeviceId] = useState();
  const [isAdd, setisAdd] = useState(false);
  var [counter, setCounter] = useState(1);
  const [sort, setsort] = useState(0);
  const [editpopup, setEditPopup] = useState(false);
  const [popid, setpopid] = useState("");
  const [popindex, setpopindex] = useState("");
  const [projectId, setProjectId] = useState("");
  const [projectCodeID, setProjectCode] = useState("");

  //omega id dropdown
  const [OmegaIdData, setOmegaIdData] = useState([]);
  const [OmegavalueId, setOmegaValueiD] = useState("");
  const [OmegaId, setOmegaId] = useState("");

  const [OmegaIdValue, setOmegaIdValue] = useState<null | string>(null);;
  const [OmegaError, setOmegaError] = useState("");
  const [isFocus, setIsFocus] = useState(false);

  //graph omega id dropdown
  const [graphOmegavalueId, setGraphOmegaValueiD] = useState("");
  const [graphOmegaId, setGraphOmegaId] = useState("");

  const [graphOmegaIdValue, setGraphOmegaIdValue] = useState(null);
  const [graphOmegaError, setGraphOmegaError] = useState("");
  const [isgraphFocus, setGraphIsFocus] = useState(false);

  //alert type dropdown
  const [alertTypeValue, setAlertTypeValue] = useState("");

  // const toggleSwitch = () => setIsActive(0);
  var idProject = useSelector((state) => state.idUpdating);
  var projectCodeId = useSelector((state) => state.projectCodeId);

  const [showAlert, setAlert] = useState(false);
  const [isOmegeDeviceNameValid, setOmegeDeviceNameDataValid] = useState(false);
  const [isOmegeDeviceNameEntered, setOmegeDeviceNameDataEntered] =
    useState(false);
  const [OmegeDeviceName, setOmegeDeviceNameData] = useState([]);
  const [OmegeDeviceByName, setOmegeDeviceByName] = useState("");
  const [error, setError] = useState("");
  const textOmegeDeviceName = useRef("");
  const [OmegeDeviceLength, setOmegeDeviceLength] = useState(0);
  const [searchData, setSearch] = useState("");
  const [isLoadingSync, setIsLoadingSync] = useState(false);

  const [filterData, setFilteredDataSource] = useState([]);

  const [isGraphicalViewOpen, setisGraphicalViewOpen] = React.useState(false);

  const [graphFromDate, setGraphFromDate] = React.useState<Date | undefined>(new Date());
  const [graphFromOpen, setGraphFromOpen] = React.useState(false);

  const [graphToDate, setGraphToDate] = React.useState<Date | undefined>(new Date());
  const [graphToOpen, setGraphToOpen] = React.useState(false);

  const [graphFromDateError, setGraphFromDateError] = useState("");
  const [graphToDateError, setGraphToDateError] = useState("");

  const [programGraphData, setProgramGraphData] = useState({});

  const [alertData, setAlertData] = useState([
    {
      name: "All",
      value: "0",
    },
    {
      name: "General Error",
      value: "general_error",
    },
    {
      name: "Reset Reason",
      value: "unit_reset",
    },
    {
      name: "Low Battery",
      value: "low_battery",
    },
    {
      name: "Critical Low Battery",
      value: "critical_low_battery",
    },
    {
      name: "Modem Error",
      value: "modem_error",
    },
    {
      name: "SIM Error",
      value: "general_error",
    },
    {
      name: "RSSI + Cellular provider",
      value: "general_error",
    },
    {
      name: "Start FOTA",
      value: "start_fota",
    },
    {
      name: "End FOTA",
      value: "end_fota",
    },
    {
      name: "Modem Connect",
      value: "modem_connect",
    },
    {
      name: "Modem Disconnect",
      value: "modem_disconnect",
    },
    {
      name: "Configuration Error",
      value: "configuration_error",
    },
    {
      name: "Unit Started",
      value: "unit_started",
    },
    {
      name: "Program enqueue",
      value: "program_enqueue",
    },
    {
      name: "Program dequeued",
      value: "program_dequeued",
    },
    {
      name: "Capacitor failure",
      value: "capacitor_failure",
    },
    {
      name: "battery check",
      value: "battery_voltage",
    },
    {
      name: "Analog Error28",
      value: "analog_error",
    },
    {
      name: " Unit disconnect from the server",
      value: "unit_started",
    },
    {
      name: "Modbus Error",
      value: "program_enqueue",
    },
    {
      name: "Open Valve",
      value: "valve_open",
    },
    {
      name: "Close Valve",
      value: "valve_close",
    },
    {
      name: "Open Fertigation",
      value: "fertigation_open",
    },
    {
      name: "Close Fertigation",
      value: "fertigation_close",
    },
  ]);

  const [isPressAlert, setIsPressAlert] = useState(false);
  const [isPressOmega, setIsPressOmega] = useState(false);

  //Error message
  const [FromDateError, setFromDateError] = useState("");
  const [ToDateError, setToDateError] = useState("");

  //Pagination
  const optionsPerPage = [10, 25, 50, 100];

  const [page, setPage] = React.useState(0);
  const [itemsPerPage, setItemsPerPage] = React.useState(optionsPerPage[0]);
  const from = page * itemsPerPage;
  const to = Math.min((page + 1) * itemsPerPage, OmegeDeviceLength);
  const screenwidth = Dimensions.get('window').width
  const isMobile = screenwidth < 768;
  React.useEffect(() => {
    setIsPressOmega(true);
    setPage(0);
    setpopindex("");
    setEditPopup(false);
    setpopid("");
  }, [itemsPerPage]);
  const isCreatable = () => {
    setIsVisible(true);
    setisAdd(true);
  };

  // var alpha_pattern = new RegExp(/^[a-zA-Z]+(\s[a-zA-Z]+)?$/);
  var alpha_pattern = new RegExp(/^[a-zA-Z]+(\s[a-zA-Z]+)?$/);
  // var alpha_pattern = new RegExp(/^(?!\s*$).+/);

  const [FromDate, setFromDate] = React.useState<Date | undefined>(new Date());
  const [FromOpen, setFromOpen] = React.useState(false);

  const onDismissGraphFrom = React.useCallback(() => {
    setGraphFromOpen(false);
  }, [setGraphFromOpen]);

  const onConfirmGraphFrom = React.useCallback(
    (params: { date: React.SetStateAction<Date | undefined> }) => {
      setGraphFromOpen(false);
      setGraphFromDateError("");
      setGraphFromDate(params.date);
    },
    [setGraphFromOpen, setGraphFromDate]
  );

  const onDismissGraphTo = React.useCallback(() => {
    setGraphToOpen(false);
  }, [setGraphToOpen]);

  const onConfirmGraphTo = React.useCallback(
    (params: { date: React.SetStateAction<Date | undefined> }) => {
      setGraphToOpen(false);
      setGraphToDateError("");
      setGraphToDate(params.date);
    },
    [setGraphToOpen, setGraphToDate]
  );

  const onDismissFrom = React.useCallback(() => {
    setFromOpen(false);
  }, [setFromOpen]);

  const onConfirmFrom = React.useCallback(
    async (params: { date: React.SetStateAction<Date | undefined> }) => {
      setFromOpen(false);
      setFromDateError("");
      setFromDate(params.date);
      // let proCodeId = await AsyncStorage.getItem("projectCode");
      // let proCode = JSON.parse(proCodeId);
      // getOmegaDeviceData(OmegaId, proCode);
      // getOmegaDeviceData(OmegaId);
    },
    [setFromOpen, setFromDate]
  );

  const [ToDate, setToDate] = React.useState<Date | undefined>(new Date());
  const [ToOpen, setToOpen] = React.useState(false);

  const onDismissTo = React.useCallback(() => {
    setToOpen(false);
  }, [setToOpen]);

  const onConfirmTo = React.useCallback(
    async (params: { date: React.SetStateAction<Date | undefined> }) => {
      setToOpen(false);
      setToDateError("");
      setToDate(params.date);
      // let proCodeId = await AsyncStorage.getItem("projectCode");
      // let proCode = JSON.parse(proCodeId);
      // getOmegaDeviceData(OmegaId, proCode);
      // getOmegaDeviceData(OmegaId);
    },
    [setToOpen, setToDate]
  );

  const dropdownRefOmegaiD = useRef();
  const closeMenu = () => {
    dropdownRefOmegaiD.current.close();
  };

  useEffect(() => {
    window.addEventListener("scroll", closeMenu);
    return () => window.removeEventListener("scroll", closeMenu);
  }, []);
  useEffect(() => {
    // getAdminData();
    console.log("Inside useEffect - OmegaId:", OmegaId, "projectCodeID:", projectCodeID);
    console.log("Inside useEffect - FromDate:", FromDate, "ToDate:", ToDate);

    // if(OmegaId != null && projectCodeID != null && projectCodeID != undefined){
    //   getOmegaDeviceData(OmegaId,projectCodeID);
    // }
    // getOmegaDeviceData(OmegaId,projectCodeID);
    getOmegaDeviceData(OmegaId);
  // }, [FromDate, ToDate, projectCodeID, OmegaId]);
}, [FromDate, ToDate,]);


  useEffect(() => {
    getProgramGraphData(graphOmegaId);
  }, [graphFromDate, graphToDate]);

  useFocusEffect(
    React.useCallback(() => {
      // getAdminData();
      setIsPressOmega(true);
      searchFilterFunction("");
    }, [projectCodeId, projectCodeID, OmegaId])
  );
  useFocusEffect(
    React.useCallback(() => {
      getAdminData();
    }, [])
  );

  const getAdminData = async () => {
    let jsonValue = await AsyncStorage.getItem("userData");
    let proCodeId = await AsyncStorage.getItem("projectCode");
    let proCode = JSON.parse(proCodeId);
    setProjectCode(proCode);
    getProgramGraphData("");
    // console.log("proCodeId:", proCode);
    let projValue = await AsyncStorage.getItem("projectData");

    // console.log("jsonValue & projValue:", jsonValue, projValue, proCodeId);
    if (projValue != null) {
      // console.log("project id select in zone create", projValue, proCodeId);
      setProjectId(projValue);
      getOmegaDependentByProjectId(projValue);
    } else if (jsonValue != null) {
      var userDetailsresponse = JSON.parse(jsonValue);
      // console.log("userDetailsresponse in zone ==>", userDetailsresponse);
      // setloginId(userDetailsresponse.email_id);
      // setProjectId("cl86ts8rj217140xp5138s7iqm")

      setProjectId(userDetailsresponse.project_selected);
      // setEmpCode(userDetailsresponse.employee_code);
      getOmegaDependentByProjectId(userDetailsresponse.project_selected);
    }
  };

  const getDownloadExcel = async () => {
    // var fromD = moment(FromDate).format("DD-MM-yyyy");
    // var toD = moment(ToDate).format("DD-MM-yyyy");
    // var omegaIds = OmegaId == "" ? 0 : OmegaId;

    // //GET request
    // window.open(
    //   baseUrl +
    //   "reports/exportGetLogsWaterMeterTs/" +
    //   projectCodeID +
    //   "/" +
    //   omegaIds +
    //   "/" +
    //   fromD +
    //   "/" +
    //   toD
    // );
    // return false;
    const filteredData = OmegeDeviceName.map(({date, time, unitName,entityName,sumAmount},index) => ({
      'Sr No': index + 1,
       "Date" : date,
       "Time" : time,
       "Omega name" : unitName,
       "Sensor Name" : entityName,
       "Amount (m³)" : sumAmount
    }));
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, {
      type: 'array',
      bookType: 'xlsx',
    });
    const excelData = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(excelData, 'WaterAccumulation_Report.xlsx');
  };

  const getOmegaDependentByProjectId = async (projectID) => {
    setIsLoading(true);
    try {
      var OmegaIdData = JSON.stringify({
        column: "project_id",
        value: projectID,
        module: "devices_master",
      });
      const OmegaIdList = await postData(
        OmegaIdData,
        "masters/getDependentMasterList"
        // "masters/getOmegaFromBermad"
      );
      setIsLoading(false);
      var code = OmegaIdList.code;
      if (code == 1) {
        // console.log(" Omega list api====>", OmegaIdList.data);
        var mergeData = OmegaIdList.data;
        mergeData.unshift({
          id: 0,
          project_id: projectId,
          omega_id: "",
          device_name: "All",
          lat: "",
          long: "",
          active: 1,
        });
        setOmegaIdValue("All");
        // setOmegaValueiD(0);
        setOmegaIdData(mergeData);
      } else {
        setIsLoading(false);
        // //alert(OmegaIdList.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };
  const renderOmegaIdListItems = (item: any) => {
    return (
      <View style={styles.item}>
        {isAdd == true && (
          <Text style={styles.textItem}>{item.device_name}</Text>
        )}
        {isAdd == false && (
          <Text style={styles.textItem}>{item.device_name}</Text>
        )}
        {item.id === OmegavalueId && (
          <Icon style={styles.icon} color="black" name="Safety" size={20} />
        )}
      </View>
    );
  };

  const renderGraphOmegaIdListItems = (item: any) => {
    return (
      <View style={styles.item}>
        {isAdd == true && (
          <Text style={styles.textItem}>{item.device_name}</Text>
        )}
        {isAdd == false && (
          <Text style={styles.textItem}>{item.device_name}</Text>
        )}
        {item.id === graphOmegavalueId && (
          <Icon style={styles.icon} color="black" name="Safety" size={20} />
        )}
      </View>
    );
  };

  const renderAlertTypeList = (item: any) => {
    return (
      <View style={styles.item}>
        {isAdd == true && <Text style={styles.textItem}>{item.name}</Text>}
        {isAdd == false && <Text style={styles.textItem}>{item.name}</Text>}
        {item.value === alertTypeValue && (
          <Icon style={styles.icon} color="black" name="Safety" size={20} />
        )}
      </View>
    );
  };
  const searchFilterFunction = (text) => {
    // Check if searched text is not blank
    if (text) {
      // Inserted text is not blank
      // Filter the masterDataSource
      // Update FilteredDataSource
      const newData = OmegeDeviceName.filter(function (item) {
        const itemData = item.OmegeDevice
          ? item.OmegeDevice.toUpperCase()
          : "".toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      // console.log("new data search---->", newData);
      setFilteredDataSource(newData);
      setSearch(text);
      setPage(0);
    } else {
      // Inserted text is blank
      // Update FilteredDataSource with masterDataSource
      setFilteredDataSource(OmegeDeviceName);
      setSearch(text);
      setPage(0);
    }
  };
  var counter = 1;
  const customStyles = {
    rows: {
      style: {
        // maxWidth: 50,
        minHeight: "30px", // override the row height
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        backgroundColor: "#319ef0a3",
        minHeight: "40px",
        fontSize: 16,
        fontWeight: "600",
      },
    },
    headCells: {
      style: {
        // paddingLeft: '8px', // override the cell padding for head cells
        // paddingRight: '8px',
        justifyContent: "center",
        minHeight: "20px",

        // borderRightWidth: 2,
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          // fontSize: 16,
          // fontWeight: '600',
          // borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
        justifyContent: "center",

        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          fontSize: 16,
          fontWeight: "400",
          textTransform: "Capitalize",

          // borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };
  const caseInsensitiveSort = (rowA, rowB) => {
    const a = rowA.dateTime.toLowerCase();
    const b = rowB.dateTime.toLowerCase();

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };

  // filterData.forEach((id, index) => { filterData.id = index + 1; });

  const columnsOmega = [
    {
      name: "Date",
      selector: (row) => row.date,
      sortable: true,
      sortFunction: caseInsensitiveSort,
      // width: "100px",
    },
    {
      name: "Time",
      selector: (row) => row.time,
      sortable: true,
      sortFunction: caseInsensitiveSort,
      // width: "100px",
    },
    {
      name: "Omega name",
      selector: (row) => row.unitName,
      // //cell: (row, index) => index + 1,
      // sortable: true,
      // width: "100px"
    },
    {
      name: "Entity Name",
      selector: (row) => row.entityName,
      sortable: true,
      sortFunction: caseInsensitiveSort,
      // width: "100px",
    },
    {
      name: "Amount (m³)",
      selector: (row) => row.sumAmount,
      sortable: true,
      sortFunction: caseInsensitiveSort,
      // width: "100px",
    },
  ];

  // OmegeDevice Get ApiS
  const getOmegaDeviceData = async (omegaId) => {
    setIsLoadingSync(true);

    try {
      let proCodeId = await AsyncStorage.getItem("projectCode");
      let proCode = JSON.parse(proCodeId);
      var OmegeDeviceData = JSON.stringify({
        from: FromDate,
        to: ToDate,
        uid: omegaId,
        pid: proCode,
      });
      console.log("getlogsystem api payload", OmegeDeviceData, new Date().toLocaleTimeString());

      const OmegeDeviceNameResponse = await postData(
        OmegeDeviceData,
        "reports/getLogsWaterMeterTs"
      );
      var code = OmegeDeviceNameResponse.code;
      if (code == 1) {
        setIsPressOmega(true)//alert(false), setIsPressOmega(true);

        setOmegeDeviceNameData(OmegeDeviceNameResponse.data);
        // setFilteredDataSource(OmegeDeviceNameResponse.data);
        setIsLoadingSync(false);
      } else {
        setIsLoadingSync(false);
        setOmegeDeviceNameData(OmegeDeviceNameResponse.data);
        // //alert(OmegeDeviceNameResponse.message);
      }
    } catch (error) {
      setIsLoadingSync(false);
      // console.log(error);
    }
  };

  const getProgramGraphData = async (omega_id) => {
    setIsLoadingSync(true);
    let projValue = await AsyncStorage.getItem("projectData");
    let jsonValue = await AsyncStorage.getItem("userData");
    var userDetailsresponse = JSON.parse(jsonValue);
    console.log("projValue", projValue);
    let from_date = moment(graphFromDate).format("YYYY-MM-DD")
    let to_date = moment(graphToDate).format("YYYY-MM-DD")
    try {
      var OmegeDeviceData = JSON.stringify({
        project_id: projValue !== null ? projValue : userDetailsresponse.project_selected ,
        startDate: from_date,
        endDate: to_date,
        omega_id: omega_id
      });

      console.log("OmegeDeviceData", OmegeDeviceData);
      const programGraphResponse = await postData(
        OmegeDeviceData,
        "reports/graphWaterAccumulation"
      );

      console.log("programGraphResponse", programGraphResponse)
      // setIsLoadingSync(false);
      var code = programGraphResponse.code;
      if (code == 1) {
        setProgramGraphData(programGraphResponse.data);
        console.log("programGraphResponse.data if",programGraphResponse.data);
        
      } else {
        setProgramGraphData(programGraphResponse.data);
        console.log("programGraphResponse.data else",programGraphResponse.data);
        setIsLoadingSync(false);
      }
    } catch (error) {
      setIsLoadingSync(false);
      console.log("error===>", error)
    }
  };

  const onSelectSwitch = index => {
    if (isGraphicalViewOpen) {
      setOmegaId("")
      setGraphOmegaId("")
      setisGraphicalViewOpen(false)
    } else {
      setOmegaId("")
      setGraphOmegaId("")
      setisGraphicalViewOpen(true)
    }
  };

  return (
    <SafeAreaView>
      <ScrollView showsVerticalScrollIndicator={false}>
        <View style={isMobile ? [styles.container, { marginTop: "-15%"}] : [styles.container, { backgroundColor: "#fff" }]}>
          <View style={isMobile ? { flexDirection: "row", width: "100%", marginTop: "65%", backgroundColor: "#fff" } : { flexDirection: "row", width: "100%", marginTop: "10%", backgroundColor: "#fff" }}>
            <View style={isMobile ? { backgroundColor: "#fff" } :{ margin: 10, backgroundColor: "#fff" }}>
              <Text style={styles.lableText}>
                Reports {">"}{" "}
                <Text style={{ color: "#000000" }} onPress={() => navigation.navigate("WaterAccumulation")}>
                  {" "}
                  Water Accumulation{" "}
                </Text>
              </Text>
            </View>
            <View style={isMobile ? { position: 'absolute', right: 0, top: 50, backgroundColor: "#fff", left: "8%" } : { position: 'absolute', right: 0, top: 10, backgroundColor: "#fff" }}>
              <CustomSwitch
                selectionMode={1}
                roundCorner={true}
                option1={'Table View'}
                option2={'Graphical View'}
                onSelectSwitch={onSelectSwitch}
                selectionColor={'#006EB9'}
              />
            </View>
          </View>
          {!isGraphicalViewOpen ?
            <View style={isMobile ? {
              height: "100%",
              width: "100%",
              backgroundColor: "#fff",
              marginTop: 80,
            } : {
              height: "100%",
              width: "100%",
              backgroundColor: "#fff"
            }}>
              <View
                style={isMobile ? {
                  flexDirection: "row",
                  width: "100%",
                  marginTop: "1%",
                  marginLeft: 2,
                  backgroundColor: "#fff"
                } : {
                  flexDirection: "row",
                  width: "100%",
                  marginTop: "0%",
                  marginLeft: 20,
                  backgroundColor: "#fff"
                }}
              >
                <View style={isMobile ? { width: "45%", backgroundColor: "#fff" } : { width: "20%", backgroundColor: "#fff" }}>
                  <Text style={isMobile ? { color: "#000000", fontSize: 12 } : { color: "#000000" }}>From Date</Text>
                  <View style={{ flexDirection: "column", marginTop: 7, backgroundColor: "#fff" }}>
                    <Text style={styles.input} onPress={() => setFromOpen(true)}>
                      {isMobile ? moment(FromDate).format("DD/MM/YY") : moment(FromDate).format("DD/MM/YYYY")}
                    </Text>
                    <Text style={styles.error_text}>{FromDateError}</Text>
                  </View>
                </View>

                <DatePickerModal
                  locale="en"
                  mode="single"
                  visible={FromOpen}
                  onDismiss={onDismissFrom}
                  date={FromDate}
                  onConfirm={onConfirmFrom}
                  label="Select date" // optional
                  closeIcon="close" // optional, default is "close"
                  calendarIcon="calendar" // optional, default is "calendar"
                />

                <View style={isMobile ? { width: "45%", backgroundColor: "#fff", marginLeft: "-10%" } : { width: "20%", backgroundColor: "#fff" }}>
                  <Text style={isMobile ? { color: "#000000", fontSize: 12 } : { color: "#000000" }}>To Date</Text>
                  <View style={{ flexDirection: "column", marginTop: 7, backgroundColor: "#fff" }}>
                    <Text style={styles.input} onPress={() => setToOpen(true)}>
                      {isMobile ? moment(ToDate).format("DD/MM/YY") : moment(ToDate).format("DD/MM/YYYY")}
                    </Text>
                    <Text style={styles.error_text}>{ToDateError}</Text>
                  </View>
                </View>
                <DatePickerModal
                  locale="en"
                  mode="single"
                  visible={ToOpen}
                  onDismiss={onDismissTo}
                  date={ToDate}
                  onConfirm={onConfirmTo}
                  label="Select date" // optional
                  closeIcon="close" // optional, default is "close"
                  calendarIcon="calendar" // optional, default is "calendar"
                />

                <View style={isMobile ? { width: "30%", backgroundColor: "#fff", marginLeft: "-10%" } : { width: "20%", backgroundColor: "#fff" }}>
                  <Text style={isMobile ? { color: "#000000", fontSize: 12 } : { color: "#000000" }}>Select Omega</Text>
                  <Dropdown
                    ref={dropdownRefOmegaiD}
                    style={styles.dropdown}
                    placeholderStyle={isMobile ? { fontSize: 12 } : styles.placeholderStyle}
                    selectedTextStyle={styles.selectedTextStyle}
                    inputSearchStyle={styles.inputSearchStyle}
                    iconStyle={styles.iconStyle}
                    data={OmegaIdData}
                    search
                    maxHeight={300}
                    labelField="device_name"
                    valueField="device_name"
                    placeholder={!isFocus ? "Omega Device Type" : "...."}
                    searchPlaceholder="Search..."
                    value={OmegaIdValue}
                    onChange={(item) => {
                      // if (item.device_name == "All") {
                      //   setOmegaIdValue(item.device_name);
                      // } else {
                      //   // setOmegaIdValue("All");
                      //   setOmegaIdValue(item.device_name);

                      // }
                      setOmegaIdValue(item.device_name);
                      setOmegaValueiD(item.id);
                      if (FromDate === undefined && ToDate === undefined) {
                        setFromDateError("Please select from data");
                        setToDateError("Please select to date");

                      } else {
                        setOmegaId(item.omega_id);
                        // getOmegaDeviceData(item.omega_id, projectCodeID);
                        getOmegaDeviceData(item.omega_id);
                        setFromDateError("");
                        setToDateError("");
                      }
                    }}
                    renderItem={renderOmegaIdListItems}
                  />
                </View>
                <View style={isMobile ? { margin: "20%", alignItems: "flex-end", marginTop: "25%", backgroundColor: "#fff", width: "65%", marginLeft: "-65%" } : { alignItems: "flex-end", margin: 20, flex: 1, backgroundColor: "#fff" }}>
                  {OmegeDeviceName.length > 0 && (
                    <TouchableOpacity
                      style={isMobile ? {
                        alignItems: "center",
                        backgroundColor: "#747474",
                        padding: 10,
                        borderWidth: 1,
                        borderColor: "#747474",
                        width: "65%",
                        // marginTop: 20,
                        borderRadius: 10,
                        marginBottom: "-40%",
                      } : styles.alertBtn}
                      onPress={() => {
                        getDownloadExcel();
                      }}
                    >
                      <Text style={styles.btnText}>
                        <Icon name="exclefile1" size={15} color="#fff" /> Download
                      </Text>
                    </TouchableOpacity>
                  )}
                </View>
              </View>
              <View style={isMobile ? [styles.card, { flexGrow: 1, backgroundColor: "#fff", marginTop: "-3%" }] : [styles.card, { flexGrow: 1, backgroundColor: "#fff" }]}>
                <View
                  style={isMobile ? [
                    styles.shadowProp,
                    {
                      width: "140%",
                      // flex: 1,
                      backgroundColor: "#F6F9F9",
                    },
                  ] : [
                    styles.shadowProp,
                    {
                      width: "100%",
                      // flex: 1,
                      backgroundColor: "#F6F9F9",
                    },
                  ]}
                >

                  <View style={{ backgroundColor: "#fff" }}>
                    {isPressAlert == true && (
                      <DataTable
                        // title="OmegeDevice"
                        columns={columnsAlert}
                        data={filterData}
                        customStyles={customStyles}
                        pagination
                        defaultSortFieldId={"OmegeDevice"}
                        // striped
                        defaultSortAsc={true}
                      />
                    )}
                    {isPressOmega == true &&
                      (isLoadingSync ? (
                        <ActivityIndicator
                          //visibility of Overlay Loading Spinner
                          color="#1DA1F2"
                          visible={isLoadingSync}
                          //Text with the Spinner
                          textContent={"Loading..."}
                          size={100}
                        //  //Text style of the Spinner Text
                        //  textStyle={styles.spinnerTextStyle}
                        />
                      ) : (
                        (OmegeDeviceName.length > 0 ?
                          (
                            <DataTable
                              // title="OmegeDevice"
                              columns={columnsOmega}
                              data={OmegeDeviceName}
                              customStyles={customStyles}
                              pagination
                              defaultSortFieldId={"OmegeDevice"}
                              // striped
                              defaultSortAsc={true}
                            />
                          ) : (
                            // valveDetails.length == 0 && (
                            <View style={{ justifyContent: "center", backgroundColor: "#fff" }}>
                              <Text
                                style={[
                                  styles.emptyListStyle,
                                  { alignSelf: "center", marginTop: 20, color: "#000000" },
                                ]}
                              >
                                No Water Accumulation data Found{" "}
                              </Text>
                            </View>
                            //
                          )
                        )
                      ))}
                  </View>
                  {/* prvider datatable code here */}

                  {/* ended */}

                </View>
                {/* </ScrollView> */}
              </View>
            </View>
            :
            <View style={isMobile ? {
              height: "100%",
              width: "120%",
              marginTop: "20%",
              backgroundColor: "#fff",
            } : {
              height: "100%",
              width: "100%",
              marginBottom: 50,
              backgroundColor: "#fff"
            }}>
              <View style={{
                margin: 20,
                shadowColor: "#171717",
                shadowOffset: { width: -2, height: 4 },
                shadowOpacity: 0.2,
                shadowRadius: 15,
                padding: 20,
                backgroundColor: "#fff"
              }}>
                <View
                  style={isMobile ? {
                    flexDirection: "row",
                    width: "100%",
                    marginTop: "1%",
                    marginLeft: 2,
                    backgroundColor: "#fff"
                  } : {
                    flexDirection: "row",
                    width: "100%",
                    marginTop: "0%",
                    marginLeft: 20,
                    backgroundColor: "#fff"
                  }}
                >
                  <View style={isMobile ? { width: "45%", backgroundColor: "#fff" } : { width: "15%", marginLeft: 20, backgroundColor: "#fff" }}>
                    <Text style={isMobile ? { color: "#000000", fontSize: 12 } : { color: "#000000" }}>From Date</Text>
                    <View style={{ flexDirection: "column", marginTop: 7, backgroundColor: "#fff" }}>
                      <Text style={styles.input} onPress={() => setGraphFromOpen(true)}>
                        {isMobile ? moment(graphFromDate).format("DD/MM/YY") : moment(graphFromDate).format("DD/MM/YYYY")}
                      </Text>
                      <Text style={styles.error_text}>{graphFromDateError}</Text>
                    </View>
                  </View>
                  <DatePickerModal
                    locale="en"
                    mode="single"
                    visible={graphFromOpen}
                    onDismiss={onDismissGraphFrom}
                    date={graphFromDate}
                    onConfirm={onConfirmGraphFrom}
                    label="Select date" // optional
                    closeIcon="close" // optional, default is "close"
                    calendarIcon="calendar" // optional, default is "calendar"
                  />
                  <View style={isMobile ? { width: "45%", backgroundColor: "#fff", marginLeft: "-10%" } : { width: "15%", backgroundColor: "#fff" }}>
                    <Text style={isMobile ? { color: "#000000", fontSize: 12 } : { color: "#000000" }}>To Date</Text>
                    <View style={{ flexDirection: "column", marginTop: 7, backgroundColor: "#fff" }}>
                      <Text style={styles.input} onPress={() => setGraphToOpen(true)}>
                        {isMobile ? moment(graphToDate).format("DD/MM/YY") : moment(graphToDate).format("DD/MM/YYYY")}
                      </Text>
                      <Text style={styles.error_text}>{graphToDateError}</Text>
                    </View>
                  </View>
                  <DatePickerModal
                    locale="en"
                    mode="single"
                    visible={graphToOpen}
                    onDismiss={onDismissGraphTo}
                    date={graphToDate}
                    onConfirm={onConfirmGraphTo}
                    label="Select date" // optional
                    closeIcon="close" // optional, default is "close"
                    calendarIcon="calendar" // optional, default is "calendar"
                  />

                  <View style={isMobile ? { width: "30%", backgroundColor: "#fff", marginLeft: "-10%" } : { width: "20%", backgroundColor: "#fff" }}>
                    <Text style={isMobile ? { color: "#000000", fontSize: 12 } : { color: "#000000" }}>Select Omega</Text>
                    <Dropdown
                      ref={dropdownRefOmegaiD}
                      style={styles.dropdown}
                      placeholderStyle={isMobile ? { fontSize : 12} : styles.placeholderStyle}
                      selectedTextStyle={styles.selectedTextStyle}
                      inputSearchStyle={styles.inputSearchStyle}
                      iconStyle={styles.iconStyle}
                      data={OmegaIdData}
                      search
                      maxHeight={300}
                      labelField="device_name"
                      valueField="device_name"
                      placeholder={!isFocus ? "Omega Device Type" : "...."}
                      searchPlaceholder="Search..."
                      value={OmegaIdValue}
                      onChange={(item) => {
                        setGraphOmegaIdValue(item.device_name);
                        setGraphOmegaValueiD(item.id);
                        if (FromDate === undefined && ToDate === undefined) {
                          setFromDateError("Please select from data");
                          setToDateError("Please select to date");

                        } else {
                          setGraphOmegaId(item.omega_id);
                          console.log("omega selected");
                          getProgramGraphData(item.omega_id);
                          setFromDateError("");
                          setToDateError("");

                          // console.log("FromDate && ToDate", FromDate, ToDate);
                        }

                        // console.log("omega id value---", item.omega_id);
                      }}
                      renderItem={renderGraphOmegaIdListItems}
                    />
                  </View>
                </View>
                {isLoadingSync ? (
                  <ActivityIndicator
                    //visibility of Overlay Loading Spinner
                    color="#1DA1F2"
                    visible={isLoadingSync}
                    //Text with the Spinner
                    textContent={"Loading..."}
                    size={100}
                  />
                ) : (
                  programGraphData.valveData !== undefined && programGraphData.valveData.length > 0 ? (
                    <View style={{
                      height: windowHeight * 0.6,
                      width: windowWidth * 0.9,
                      padding: 20,
                      backgroundColor: "#fff"
                    }}>
                      <Victory.VictoryChart
                        width={programGraphData.dateCountArray.length <= 3 ? windowWidth * 0.4 : windowWidth * 0.9}
                        domainPadding={{ x: 50, y: 25 }}
                        theme={Victory.VictoryTheme.grayscale}
                      >
                        <Victory.VictoryAxis
                          tickValues={programGraphData.dateCountArray}
                          tickFormat={programGraphData.dateArray}
                          fixLabelOverlap
                          label={"Dates"}
                          axisLabelComponent={<Victory.VictoryLabel style={{ fill: "#455A64", fontSize: 13, marginTop: 20 }}
                            textAnchor={"middle"}
                          />}
                          style={{
                            grid: { stroke: "#D5D5D5" },
                            ticks: { stroke: "#D5D5D5", size: 5 },
                            tickLabels: { fontSize: 13, padding: 5 }
                          }}
                        />
                        <Victory.VictoryAxis
                          dependentAxis
                          label={"Total Value, m³"}
                          axisLabelComponent={<Victory.VictoryLabel style={{ fill: "#455A64", fontSize: 13 }}
                            x={-2}
                            textAnchor={"middle"}
                          />}
                          tickFormat={(x) => (x)}
                          style={{
                            grid: { stroke: "#D5D5D5" },
                            ticks: { stroke: "#D5D5D5", size: 5 },
                            tickLabels: { fontSize: 13, padding: 5 }
                          }}
                        />
                        <Victory.VictoryStack
                          colorScale={programGraphData.colorData}
                        >
                          {programGraphData.valveData.map((item, index) => {
                            return (
                              <Victory.VictoryBar
                                padding={{ left: 50 }}
                                labelComponent={<Victory.VictoryTooltip />}
                                alignment="middle"
                                barRatio={0.3}
                                key={index}
                                // Ensure proper mapping of data to the graph
                                data={item.valve}
                                x="date"
                                y="value"
                              />
                            )
                          })}
                        </Victory.VictoryStack>
                      </Victory.VictoryChart>
                      {programGraphData.valveData.length > 0 &&
                        <View style={{ flexDirection: "row", marginBottom: 50 }}>
                          {programGraphData.valveData.map((item, index) => {
                            console.log("programGraphData.valveData[index].valve", programGraphData.valveData[index].valve)
                            return (
                              <View style={{ flexDirection: "row", marginStart: 20 }}>
                                <IconFontAwesome color={programGraphData.colorData[index]} name="square" size={20} />
                                <Text
                                  style={[
                                    styles.btnTextCancel,
                                    { textAlign: "left", marginLeft: 5 },
                                  ]}
                                >{programGraphData.valveData[index].valve[0].valveName}</Text>
                              </View>
                            )
                          })}
                        </View>
                      }
                    </View>
                  ) : (
                  <View style={{
                    height: windowHeight * 0.6,
                    width: windowWidth * 0.7,
                    backgroundColor: "#fff"
                  }}>
                    <View style={{ justifyContent: "center", backgroundColor: "#fff" }}>
                      <Text
                        style={[
                          styles.emptyListStyle,
                          { alignSelf: "center", marginTop: 20 },
                        ]}
                      >
                        No data Found
                      </Text>
                    </View>
                  </View>
                ))}
              </View>
            </View>
          }
        </View>
      </ScrollView>
    </SafeAreaView>

  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    // marginTop: 350,
    height: "100%",
  },
  itemStyle: {
    padding: 10,
  },
  textInputStyle: {
    height: 35,
    borderWidth: 2,
    paddingLeft: 20,
    borderRadius: 5,
    borderColor: "#319ef0",
    backgroundColor: "#FFFFFF",
    width: "40%",
    fontWeight: "400",
  },
  tableHead: {
    fontSize: 14,
    /* FontFamily: 'Muli' ,*/
    fontWeight: "700",
    color: "#000000",
  },

  containeralt: {
    // alignItems: "center",
    backgroundColor: "white",
    borderColor: "#eee",
    borderRadius: 10,
    borderWidth: 1,
    // justifyContent: "center",
    height: 300,
    margin: "auto",
    padding: 30,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    // marginRight: 10,
    // marginRight: 1,
    width: "30%",
  },
  contanerSwitch: {
    backgroundColor: "white",
    borderColor: "#eee",
    borderRadius: 5,
    alignSelf: "center",
    borderWidth: 1,
    // justifyContent: "center",
    height: "25%",
    margin: "auto",
    padding: 10,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    // marginRight: 10,
    // marginRight: 1,
    width: "30%",
  },
  tableHeader: {
    color: "#000",
    fontSize: 14,
    /* FontFamily: 'Muli' ,*/
    fontWeight: "400",
  },
  input: {
    borderColor: "#c3c3c3",
    width: "70%",
    borderWidth: 1,
    borderRadius: 4,
    marginRight: 10,
    color: "#000000",
    padding: 10,
    // marginTop: 4,
    marginBottom: 6,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  alertBtn: {
    alignItems: "center",
    backgroundColor: "#747474",
    padding: 10,
    borderWidth: 1,
    borderColor: "#747474",
    width: "33%",
    // marginTop: 20,
    borderRadius: 10,
  },
  alertBtnAfter: {
    alignItems: "center",
    backgroundColor: "#fff",
    padding: 10,
    borderWidth: 1,
    borderColor: "#747474",
    width: "33%",
    // marginTop: 20,
    borderRadius: 10,
  },
  saveBtn: {
    alignItems: "center",
    backgroundColor: "#006EB9",
    padding: 10,
    borderWidth: 1,
    borderColor: "#006EB9",
    width: "50%",
    marginRight: 10,
  },
  btnText: {
    color: "#fff",
    fontWeight: "600",
  },
  btnTextAfter: {
    color: "#000",
    fontWeight: "600",
  },
  card: {
    width: "70%",
    flex: 1,
    marginVertical: 10,
  },
  shadowProp: {
    shadowColor: "#171717",
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 15,
  },
  // Error message css
  error_text: {
    color: "red",
    fontSize: 14,
    fontWeight: "600",
    width: "70%",
    marginLeft: 0,
    marginBottom: 10,
  },

  link: {
    marginTop: 15,
    paddingVertical: 15,
  },
  linkText: {
    fontSize: 14,
    color: "#2e78b7",
  },

  dropdown: {
    borderColor: "#c3c3c3",
    // width: "33%",
    borderWidth: 1,
    borderRadius: 2,
    height: 40,
    marginLeft: 0,
    outlineStyle: "none",
    padding: 10,
    marginTop: 5,
    marginBottom: 5,
    // borderRadius: 10,
    // padding: 12,
    // shadowColor: "#000",
    // shadowOffset: {
    //   width: 0,
    //   height: 1,
    // },
    // shadowOpacity: 0.2,
    // shadowRadius: 1.41,

    // elevation: 2,
  },
  icon: {
    marginRight: 5,
  },
  item: {
    padding: 9,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  textItem: {
    flex: 1,
    fontSize: 16,
  },
  placeholderStyle: {
    fontSize: 16,
  },
  selectedTextStyle: {
    fontSize: 16,
  },
  iconStyle: {
    width: 20,
    height: 20,
  },
  inputSearchStyle: {
    height: 40,
    fontSize: 16,
    outlineStyle: "none",
  },
  emptyListStyle: {
    padding: 20,
    fontSize: 20,
    textAlign: "center",
  },
  lableText: {
    color: "#484848",
    fontWeight: "700",
    marginTop: 18,
    // fontSize: 21,
  },
  btnTextCancel: {
    color: "#747474",
    fontWeight: "600",
    textAlign: "center",
  },
});
