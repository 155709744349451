import React, { Component } from 'react';
import { StyleSheet, View, Text } from 'react-native';
import MapView from 'react-native-maps';
import { Marker } from 'react-native-maps';
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }) => <View>{text}</View>

export default function SchedularMapView({ }) {

    const defaultProps = {
        center: {
            lat: 10.99835602,
            lng: 77.01502627
        },
        zoom: 11
    };
    return (
        <View style={styles.MainContainer}>
            <View style={{ flexDirection: "row", width: "100%", height: '80vh', marginTop: "0%", marginBottom: '1%' }}>
                <Text>MAP VIEW</Text>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: '' }}
                    defaultCenter={defaultProps.center}
                    defaultZoom={defaultProps.zoom}
                >

                </GoogleMapReact>
            </View>
            {/* <MapView
                style={styles.mapStyle}
                showsUserLocation={false}
                zoomEnabled={true}
                zoomControlEnabled={true}
                initialRegion={{
                    latitude: 28.579660,
                    longitude: 77.321110,
                    latitudeDelta: 0.0922,
                    longitudeDelta: 0.0421,
                }}>

                <Marker
                    coordinate={{ latitude: 28.579660, longitude: 77.321110 }}
                    title={"JavaTpoint"}
                    description={"Java Training Institute"}
                />
            </MapView> */}
            {/* <MapView style={{ height: '50%', width: '100%' }} /> */}

        </View>
    );
}


const styles = StyleSheet.create({
    MainContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        // alignItems: 'center',
        // justifyContent: 'flex-end',
        flex: 1,
        // alignItems: "center",
        // justifyContent: "center",
        padding: 20,
    },
    mapStyle: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    },
});  