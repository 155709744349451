import Header from "../navigation/header";
import {
  StyleSheet,
  TouchableOpacity,
  TextInput,
  Modal,
  Button,
  Switch,
  FlatList,
  Alert,
  ScrollView,
  Dimensions,
} from "react-native";
import { useFocusEffect } from "@react-navigation/native";
import { Dropdown } from "react-native-element-dropdown";
import { List } from "react-native-paper";

import {
  Collapse,
  CollapseHeader,
  CollapseBody,
} from "accordion-collapse-react-native";
import { Text, View } from "../../components/Themed";
import { RootStackScreenProps } from "../../types"; // @ts-ignore
import { DataTable } from "react-native-paper";
import Icon from "react-native-vector-icons/AntDesign";
import IconEdit from "react-native-vector-icons/FontAwesome";
import IconEye from "react-native-vector-icons/Entypo";
import React, { useEffect, useState, useMemo, useRef } from "react";
import IconCross from "react-native-vector-icons/Entypo";
import IconCheck from "react-native-vector-icons/MaterialCommunityIcons";

import AsyncStorage from "@react-native-async-storage/async-storage";
import AwesomeAlert from "react-native-awesome-alerts";
import { baseUrl, postData } from "../../networking/api";
import { Provider as PaperProvider, Checkbox } from "react-native-paper";
import SuccessPopup from "../../components/SuccessPopup";
import { log } from "react-native-reanimated";

export default function UserAccess({ navigation }) {
  const [isVisible, setIsVisible] = useState(false);
  const [issuccessVisible, setissuccessVisible] = useState(false);
  const [responsestatus, setresponsestatus] = useState("");
  const [PopupMessage, setPopupMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [shouldShow, setShouldShow] = useState(true);
  const [iconTick, seticonTick] = useState("checkbox-blank-outline");
  const [iconTickvalId, setIconTickvalId] = useState(0);

  const [openMenu, setOpenMenu] = useState("chevron-up");

  // const toggleSwitch = () => setIsActive(0);
  const [checked, setChecked] = React.useState(false);
  const [expandedProfile, setExpandedProfile] = useState(true);

  const [giveAccessNameData, setgiveAccessNameData] = useState([]);
  const [AccessNameData, setAccessNameData] = useState([]);
  const [MenuNameData, setMenuNameData] = useState([]);
  const [ChildMenuNameData, setChildMenuNameData] = useState([]);

  const [accessValue, setaccessValue] = useState("");
  const [accessValId, setaccessValId] = useState("");
  const [error, setError] = useState("");
  const [UserTypeNameData, setUserTypeNameData] = useState([]);

  const [userTypeValue, setUserTypeValue] = useState(null);
  const [UserTypeValid, setUserTypeValid] = useState(false);
  const [createAccess, setCreateAccess] = useState(0);
  const [viewAccess, setViewAccess] = useState(0);
  const [listingAccess, setListingAccess] = useState(0);

  const [isFocus, setIsFocus] = useState(false);
  const items = [
    { title: "Country ", body: "hello" },
    { title: "City", body: "hello2" },
  ];
  const dropdownRefUserType = useRef();
  const closeMenu = () => {
    dropdownRefUserType.current.close();
  };

  useEffect(() => {
    window.addEventListener("scroll", closeMenu);
    return () => window.removeEventListener("scroll", closeMenu);
  }, []);

  const getAdminData = async () => {
    let jsonValue = await AsyncStorage.getItem("userData");
    let proCodeId = await AsyncStorage.getItem("projectCode");
    // let proCode = JSON.parse(proCodeId);
    // setProjectCode(proCode);
    // // console.log("proCodeId:", proCode);
    let projValue = await AsyncStorage.getItem("projectData");

    // console.log("useraccess", jsonValue);
    if (projValue != null) {
      // console.log("project id select in zone create", projValue, proCodeId);
      // setProjectId(projValue);
      // getOmegaDependentByProjectId(projValue);
    } else if (jsonValue != null) {
      var userDetailsresponse = JSON.parse(jsonValue);

      getUserTypeList(userDetailsresponse.user_type);
      // setloginId(userDetailsresponse.email_id);
      // setProjectId("cl86ts8rj217140xp5138s7iqm")

      // setProjectId(userDetailsresponse.project_selected);
      // setEmpCode(userDetailsresponse.employee_code);
      // getOmegaDependentByProjectId(userDetailsresponse.project_selected);
    }
  };
  const profileItem = [
    { title: "Device Profile ", body: "hello" },
    { title: "Sub Zone", body: "hello2" },
    { title: "Zone/Group Profile", body: "hello" },
    { title: "Project Profile", body: "hello2" },
    { title: "Farmer Profile", body: "hello2" },
  ];

  const setMainMenuIcon = (
    item,
    accessType,
    index,
    parent_id,
    accessTypeValue
  ) => {
    // console.log("all values", accessType);
    // console.log("index====>", index);
    // console.log("accesstype values", item, "and ", accessTypeValue);
    var tempData = [];
    if (accessTypeValue == 0 || accessTypeValue == null) {
      // console.log("if");
      seticonTick("checkbox-marked");
      setIconTickvalId(1);
      var renderData = [...MenuNameData];

      if (accessType === "create_access") {
        MenuNameData[index].create_access = 1;
        MenuNameData[index].view_access = 1;
        MenuNameData[index].listing_access = 1;
        for (let i = 0; i < MenuNameData[index].childMenu.length; i++) {
          MenuNameData[index].childMenu[i].create_access = 1;
          MenuNameData[index].childMenu[i].view_access = 1;
          MenuNameData[index].childMenu[i].listing_access = 1;
        }
        // console.log("rend some data 1", renderData);
      } else if (accessType === "view_access") {
        MenuNameData[index].view_access = 1;
        MenuNameData[index].listing_access = 1;
        for (let i = 0; i < MenuNameData[index].childMenu.length; i++) {
          MenuNameData[index].childMenu[i].view_access = 1;
          MenuNameData[index].childMenu[i].listing_access = 1;
        }

        // console.log("rend some data2", renderData);
      } else if (accessType === "listing_access") {
        MenuNameData[index].listing_access = 1;
        for (let i = 0; i < MenuNameData[index].childMenu.length; i++) {
          MenuNameData[index].childMenu[i].listing_access = 1;
        }
        // console.log("rend some data 3", renderData);
      } else {
        MenuNameData[index].create_access = 1;
        MenuNameData[index].view_access = 1;
        MenuNameData[index].listing_access = 1;

        for (let i = 0; i < MenuNameData[index].childMenu.length; i++) {
          MenuNameData[index].childMenu[i].create_access = 1;
          MenuNameData[index].childMenu[i].view_access = 1;
          MenuNameData[index].childMenu[i].listing_access = 1;
        }
        // console.log(" alll dattaaaaa clicked", renderData);
      }
      // console.log("renderData==>", renderData);
      // tempData.push(renderData);
      setMenuNameData(renderData);
      // // console.log("tempdata==>", tempData);

      return false;

      // else{
      //     // console.log("ELSE INSIDEEEEEEE",ChildMenuNameData)

      //     if (data.menu_id == item.menu_id)
      //     {
      //       MenuNameData[indexOfMainMenu].create_access=1;
      //       MenuNameData[indexOfMainMenu].view_access=1;
      //       MenuNameData[indexOfMainMenu].listing_access=1;
      //       for(let  i = 0 ;i < MenuNameData[indexOfMainMenu].childMenu.length;i++){

      //         MenuNameData[indexOfMainMenu].childMenu[i].create_access = 1;
      //         MenuNameData[indexOfMainMenu].childMenu[i].view_access = 1;
      //         MenuNameData[indexOfMainMenu].childMenu[i].listing_access = 1;

      //     }
      //     }

      // }

      //item.accessType = 1;

      // console.log(iconTickvalId);
    } else {
      // console.log("else");
      seticonTick("checkbox-blank-outline");
      setIconTickvalId(0);
      var renderData = [...MenuNameData];

      if (accessType === "create_access") {
        MenuNameData[index].create_access = 0;
        MenuNameData[index].view_access = 0;
        MenuNameData[index].listing_access = 0;
        for (let i = 0; i < MenuNameData[index].childMenu.length; i++) {
          MenuNameData[index].childMenu[i].create_access = 0;
          MenuNameData[index].childMenu[i].view_access = 0;
          MenuNameData[index].childMenu[i].listing_access = 0;
        }
        // console.log("rend some data 1", renderData);
      } else if (accessType === "view_access") {
        MenuNameData[index].view_access = 0;
        MenuNameData[index].listing_access = 0;
        for (let i = 0; i < MenuNameData[index].childMenu.length; i++) {
          MenuNameData[index].childMenu[i].view_access = 0;
          MenuNameData[index].childMenu[i].listing_access = 0;
        }

        // console.log("rend some data2", renderData);
      } else if (accessType === "listing_access") {
        MenuNameData[index].listing_access = 0;
        for (let i = 0; i < MenuNameData[index].childMenu.length; i++) {
          MenuNameData[index].childMenu[i].listing_access = 0;
        }
        // console.log("rend some data 3", renderData);
      } else {
        MenuNameData[index].create_access = 0;
        MenuNameData[index].view_access = 0;
        MenuNameData[index].listing_access = 0;
        for (let i = 0; i < MenuNameData[index].childMenu.length; i++) {
          MenuNameData[index].childMenu[i].create_access = 0;
          MenuNameData[index].childMenu[i].view_access = 0;
          MenuNameData[index].childMenu[i].listing_access = 0;
        }
        // console.log(" alll dattaaaaa clicked", renderData);
      }
      // console.log("renderData==>", renderData);
      setMenuNameData(renderData);
      return false;

      // console.log(iconTickvalId);
    }
  };
  const setIconValue = (
    item,
    accessType,
    index,
    parent_id,
    accessTypeValue
  ) => {
    // console.log("all values", accessType);
    // console.log("accesstype values", item, "and and ", accessTypeValue);
    if (accessTypeValue == 0 || accessTypeValue == null) {
      // console.log("if me gaya");
      seticonTick("checkbox-marked");
      setIconTickvalId(1);
      var renderData = [...MenuNameData];
      for (let data of renderData) {
        // console.log("menu_id ", data.menu_id);
        // console.log("parent_id", parent_id, "menu child name ", item.menu_desc);
        var indexOfMainMenu = MenuNameData.findIndex(
          (item) => item.menu_id === data.menu_id
        );
        if (data.menu_id == parent_id) {
          // console.log("indexOfMainMenu", indexOfMainMenu);
          // console.log("accessType", accessType);
          // console.log("index", index);
          if (accessType === "create_access") {
            MenuNameData[indexOfMainMenu].childMenu[index].create_access = 1;
            MenuNameData[indexOfMainMenu].childMenu[index].listing_access = 1;

            MenuNameData[indexOfMainMenu].childMenu[index].view_access = 1;

            // console.log("rend some data 1", renderData);
          } else if (accessType === "view_access") {
            MenuNameData[indexOfMainMenu].childMenu[index].view_access = 1;
            MenuNameData[indexOfMainMenu].childMenu[index].listing_access = 1;

            // console.log("rend some data2", renderData);
          } else if (accessType === "listing_access") {
            MenuNameData[indexOfMainMenu].childMenu[index].listing_access = 1;
            // console.log("rend some data 3", renderData);
          } else {
            // MenuNameData[indexOfMainMenu].create_access=1;
            // MenuNameData[indexOfMainMenu].view_access=1;
            // MenuNameData[indexOfMainMenu].listing_access=1;

            MenuNameData[indexOfMainMenu].childMenu[index].create_access = 1;
            MenuNameData[indexOfMainMenu].childMenu[index].view_access = 1;
            MenuNameData[indexOfMainMenu].childMenu[index].listing_access = 1;
            // console.log("rend some data alll dattaaaaa", renderData);
          }
          // console.log("renderData==>", renderData);
          setMenuNameData(renderData);
          return false;
        }
        // else{
        //     // console.log("ELSE INSIDEEEEEEE",ChildMenuNameData)

        //     if (data.menu_id == item.menu_id)
        //     {
        //       MenuNameData[indexOfMainMenu].create_access=1;
        //       MenuNameData[indexOfMainMenu].view_access=1;
        //       MenuNameData[indexOfMainMenu].listing_access=1;
        //       for(let  i = 0 ;i < MenuNameData[indexOfMainMenu].childMenu.length;i++){

        //         MenuNameData[indexOfMainMenu].childMenu[i].create_access = 1;
        //         MenuNameData[indexOfMainMenu].childMenu[i].view_access = 1;
        //         MenuNameData[indexOfMainMenu].childMenu[i].listing_access = 1;

        //     }
        //     }

        // }
      }

      //item.accessType = 1;

      // console.log(iconTickvalId);
    } else {
      // console.log("else me gaya");
      seticonTick("checkbox-blank-outline");
      setIconTickvalId(0);
      var renderData = [...MenuNameData];

      for (let data of renderData) {
        // console.log("menu_id", data.menu_id);
        // console.log("parent_id", parent_id);
        if (data.menu_id == parent_id) {
          var indexOfMainMenu = MenuNameData.findIndex(
            (item) => item.menu_id === data.menu_id
          );
          // console.log("indexOfMainMenu", indexOfMainMenu);
          // console.log("accessType", accessType);
          // console.log("index", index);
          if (accessType === "create_access") {
            MenuNameData[indexOfMainMenu].childMenu[index].create_access = 0;
            MenuNameData[indexOfMainMenu].childMenu[index].listing_access = 0;

            MenuNameData[indexOfMainMenu].childMenu[index].view_access = 0;
          } else if (accessType === "view_access") {
            MenuNameData[indexOfMainMenu].childMenu[index].view_access = 0;
            MenuNameData[indexOfMainMenu].childMenu[index].listing_access = 0;
          } else if (accessType === "listing_access") {
            MenuNameData[indexOfMainMenu].childMenu[index].listing_access = 0;
          } else {
            MenuNameData[indexOfMainMenu].childMenu[index].create_access = 0;
            MenuNameData[indexOfMainMenu].childMenu[index].view_access = 0;
            MenuNameData[indexOfMainMenu].childMenu[index].listing_access = 0;
          }
          setMenuNameData(renderData);
          return false;
        }
      }

      // console.log(iconTickvalId);
    }
  };
  const setIconMenu = () => {
    if (openMenu == "chevron-up") {
      setOpenMenu("chevron-down");
      // console.log("ON");
    } else {
      setOpenMenu("chevron-up");
      // console.log("OFFFFFFFFFFFF");
    }
  };
  //useracesss  Add Api
  const saveAccessData = async () => {
    setIsLoading(true);
    try {
      // var tempAccessData = [];
      // tempAccessData.push(MenuNameData);
      var userAccessData = JSON.stringify({
        module: "user_access_management",
        user_type_id: UserTypeValid,
        data: MenuNameData,
      });
      // console.log("access setting payload===", userAccessData);
      const userAccessMasterResponse = await postData(
        userAccessData,
        "masters/settingAccess"
      );
      setIsLoading(false);
      var code = userAccessMasterResponse.code;
      if (code == 1) {
        // //alert(userAccessMasterResponse.message);
        // setIsVisible(false);
        setShouldShow(false);
        setPopupMessage("Access given Successfully");
        setissuccessVisible(true);
        setresponsestatus("success");
        // console.log("Access method response", userAccessMasterResponse.message);
        navigation.navigate("AccessMaster");
        // navigation.navigate("Access");
      } else {
        setIsLoading(false);
        setPopupMessage(userAccessMasterResponse.message);
        setissuccessVisible(true);
        setresponsestatus("fail");
        //alert(userAccessMasterResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  const getAccessMasterDetails = async (userTypeId) => {
    setIsLoading(true);
    try {
      var UserTypeData = JSON.stringify({
        user_type: userTypeId,
      });
      const UserTypeResponse = await postData(
        UserTypeData,
        "masters/getUserTypeWiseAccess"
      );
      setIsLoading(false);

      var code = UserTypeResponse.code;
      if (code == 1) {
        // //alert(UserTypeResponse.message);
        // console.log("Acesss project  responseee===>", UserTypeResponse.data);
        setShouldShow(false);
        // for (var i = 0; i < UserTypeResponse.data.length; i++) {
        //   setMenuNameData(UserTypeResponse.data[i].menu_desc);
        //   // console.log(" menu list ", UserTypeResponse.data[i].menu_desc);
        //   for (var j = 0; j < UserTypeResponse.data[i].childMenu; j++) {
        //     setChildMenuNameData(
        //       UserTypeResponse.data[i].childMenu[j].menu_desc
        //     );
        //     // console.log(
        //       "child menu",
        //       UserTypeResponse.data[i].childMenu[j].menu_desc
        //     );
        //   }
        // }
        setMenuNameData(UserTypeResponse.data);
        var tempArr = [];

        for (var j = 0; j < UserTypeResponse.data.length; j++) {
          // setChildMenuNameData(UserTypeResponse.data[j].childMenu);
          // // console.log(
          //   "child menu---------",
          //   UserTypeResponse.data[j].childMenu
          // );
          tempArr.push(UserTypeResponse.data[j].childMenu);
        }
        setChildMenuNameData(tempArr);

        // setUserTypeByName("");
        // textUserType.current = "";

        // console.log("response of access details", UserTypeResponse.data);
      } else {
        setIsLoading(false);
        // //alert(UserTypeResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };
  const renderMenuList = (item, index) => {
    // // console.log("renderMenuList", item);
    return (
      <DataTable.Row>
        <DataTable.Cell>{item.menu_desc}</DataTable.Cell>
        <DataTable.Cell style={{ justifyContent: "space-between" }}>
          <TouchableOpacity
            onPress={() => {
              // console.log("All Menu ", item);
              if (
                item.create_access == 0 &&
                item.view_access == 0 &&
                item.listing_access == 0
              ) {
                setMainMenuIcon(item, "All", index, item.menu_id, 0); //master id
                // console.log("inside if of all menu", item.menu_id);
              } else {
                setMainMenuIcon(item, "All", index, item.menu_id, 1);
                // console.log("inside else of all menu", item.menu_id);
              }
            }}
          >
            {/* <IconCheck name={iconTick} size={30} /> */}
            <IconCheck
              color="#1868AE"
              name={
                item.create_access &&
                  item.view_access &&
                  item.listing_access === 1
                  ? "checkbox-marked"
                  : "checkbox-blank-outline"
              }
              size={30}
            />
          </TouchableOpacity>
        </DataTable.Cell>

        <DataTable.Cell>
          <TouchableOpacity
            onPress={() => {
              // console.log("menu item create acces", item);
              setMainMenuIcon(
                item,
                "create_access",
                index,
                item.menu_id,
                item.create_access
              );
            }}
          >
            <IconCheck
              color="#1868AE"
              name={
                item.create_access === 1
                  ? "checkbox-marked"
                  : "checkbox-blank-outline"
              }
              size={30}
            />
          </TouchableOpacity>
        </DataTable.Cell>

        <DataTable.Cell>
          <TouchableOpacity
            onPress={() => {
              // console.log("menu item LISTSSSSSS acces", item);
              setMainMenuIcon(
                item,
                "listing_access",
                index,
                item.menu_id,
                item.listing_access
              );
            }}
          >
            <IconCheck
              color="#1868AE"
              name={
                item.listing_access === 1
                  ? "checkbox-marked"
                  : "checkbox-blank-outline"
              }
              size={30}
            />
          </TouchableOpacity>
        </DataTable.Cell>
        <DataTable.Cell>
          <TouchableOpacity
            onPress={() =>
              setMainMenuIcon(
                item,
                "view_access",
                index,
                item.menu_id,
                item.view_access
              )
            }
          >
            <IconCheck
              color="#1868AE"
              name={
                item.view_access === 1
                  ? "checkbox-marked"
                  : "checkbox-blank-outline"
              }
              size={30}
            />
          </TouchableOpacity>
        </DataTable.Cell>
        {/* <DataTable.Cell >
          <TouchableOpacity
            onPress={() =>{setActive(item.menu_id)}}
          >
            <IconCheck name={Active == item.menu_id ? "chevron-down" : "chevron-up"} size={30} />
          </TouchableOpacity>
        </DataTable.Cell> */}
      </DataTable.Row>
    );
  };
  const renderMasterItem = (item, index) => {
    // // console.log("item======>", item);
    return (
      <DataTable.Row>
        <DataTable.Cell>{item.menu_desc}</DataTable.Cell>
        <DataTable.Cell style={{ justifyContent: "space-between" }}>
          <TouchableOpacity
            onPress={() => {
              // console.log("item create acces 1", item.create_access);
              if (
                item.create_access == 0 &&
                item.view_access == 0 &&
                item.listing_access == 0
              ) {
                setIconValue(item, "All", index, item.parent_id, 0);
              } else {
                setIconValue(item, "All", index, item.parent_id, 1);
              }
            }}
          >
            {/* <IconCheck name={iconTick} size={30} /> */}
            <IconCheck
              color="#1868AE"
              name={
                item.create_access &&
                  item.view_access &&
                  item.listing_access === 1
                  ? "checkbox-marked"
                  : "checkbox-blank-outline"
              }
              size={30}
            />
          </TouchableOpacity>
        </DataTable.Cell>
        <DataTable.Cell>
          <TouchableOpacity
            onPress={() => {
              // console.log("item create acces", item);
              setIconValue(
                item,
                "create_access",
                index,
                item.parent_id,
                item.create_access
              );
            }}
          >
            <IconCheck
              color="#1868AE"
              name={
                item.create_access === 1
                  ? "checkbox-marked"
                  : "checkbox-blank-outline"
              }
              size={30}
            />
          </TouchableOpacity>
        </DataTable.Cell>
        <DataTable.Cell>
          <TouchableOpacity
            onPress={() => {
              setIconValue(
                item,
                "listing_access",
                index,
                item.parent_id,
                item.listing_access
              );
            }}
          >
            <IconCheck
              color="#1868AE"
              name={
                item.listing_access === 1
                  ? "checkbox-marked"
                  : "checkbox-blank-outline"
              }
              size={30}
            />
          </TouchableOpacity>
        </DataTable.Cell>
        <DataTable.Cell>
          <TouchableOpacity
            onPress={() =>
              setIconValue(
                item,
                "view_access",
                index,
                item.parent_id,
                item.view_access
              )
            }
          >
            <IconCheck
              color="#1868AE"
              name={
                item.view_access === 1
                  ? "checkbox-marked"
                  : "checkbox-blank-outline"
              }
              size={30}
            />
          </TouchableOpacity>
        </DataTable.Cell>
      </DataTable.Row>
    );
  };

  var alpha_pattern = new RegExp(/^[a-zA-Z]+(\s[a-zA-Z]+)?$/);

  useFocusEffect(
    React.useCallback(() => {
      getAdminData();
    }, [accessValue])
  );

  const renderUserTypeListItems = (item: any) => {
    // console.log("item---value", item.id, "--------", accessValue);
    return (
      <View>
        <View style={styles.item}>
          <Text style={styles.textItem}>{item.user_type}</Text>
          {item.id === UserTypeValid && (
            <Icon style={styles.icon} color="black" name="Safety" size={20} />
          )}
        </View>
      </View>
    );
  };

  // Usertype Get Api
  const getUserTypeList = async (typeid) => {
    setIsLoading(true);
    try {
      var UserTypeData = JSON.stringify({
        module: "user_type",
        relation: [],
      });
      const UserTypeList = await postData(
        UserTypeData,
        "masters/getMasterList"
      );
      setIsLoading(false);
      var code = UserTypeList.code;
      if (code == 1) {
        // console.log("Userlist api====>", UserTypeList.data);
        // setUserTypeNameData(
        //   UserTypeList.data.filter((item) => item.id != typeid)
        // );
        setUserTypeNameData(UserTypeList.data);
      } else {
        setIsLoading(false);
        // //alert(UserTypeList.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };
  //giveAccess Add Api
  const creategiveAccess = async (giveAccessNames) => {
    // console.log("giveAccessNames", giveAccessNames);
    setIsLoading(true);
    try {
      var giveAccessData = JSON.stringify({
        module: "giveAccess_master",
        data: {
          giveAccess: giveAccessNames,
          active: 1,
        },
      });
      const giveAccessNameResponse = await postData(
        giveAccessData,
        "masters/masterCreateUpdate"
      );

      setIsLoading(false);

      var code = giveAccessNameResponse.code;
      if (code == 1) {
        // //alert(giveAccessNameResponse.message);
        setIsVisible(false);

        setaccessValue("");
        // console.log("Added", giveAccessNameResponse.data);
      } else {
        setIsLoading(false);
        // //alert(giveAccessNameResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };
  const screenwidth = Dimensions.get('window').width
  const isMobile = screenwidth < 768;
  return (
    <View style={styles.container}>
      <SuccessPopup
        visible={issuccessVisible}
        PopupMessage={PopupMessage}
        Responsestatus={responsestatus}
        onChange={() => setissuccessVisible(false)}
      />

      <View style={isMobile ? { flexDirection: "row", width: "100%", marginTop: 230 } :{ flexDirection: "row", width: "100%", marginTop: "6%" }}>
        <View style={{ margin: 10 }}>
          <Text style={isMobile ? [styles.lableText , {marginTop:-50}] : styles.lableText}>Access Master {">"} User Access</Text>
        </View>
      </View>
      <View style={isMobile ? { width: "100%", marginTop: "0%" } : { flexDirection: "row", width: "100%", marginTop: "0%" }}>
        {" "}
        <View style={{ margin: 10, flex: 0.5 }}>
          <Text style={isMobile ? { fontWeight: "700", marginLeft: -10, fontSize: 18 } : { fontWeight: "700", margin: 12, fontSize: 14 }}>
            Designation
          </Text>
        </View>
        <View style={{ flex: 3, margin: 10, marginRight: "6%" }}>
          <Dropdown
            ref={dropdownRefUserType}
            style={isMobile ? {
              borderColor: "#c3c3c3",
              width: "100%",
              borderWidth: 1,
              borderRadius: 2,
              height: 40,
              outlineStyle: "none",
              padding: 10,
              marginTop: 5,
              marginBottom: 5,} : styles.dropdown}
            placeholderStyle={styles.placeholderStyle}
            selectedTextStyle={styles.selectedTextStyle}
            inputSearchStyle={styles.inputSearchStyle}
            iconStyle={styles.iconStyle}
            data={UserTypeNameData}
            search
            maxHeight={300}
            labelField="user_type"
            valueField="user_type"
            placeholder={!isFocus ? "Select User Type" : "...."}
            searchPlaceholder="Search..."
            value={userTypeValue}
            onChange={(item) => {
              setUserTypeValue(item.user_type);
              setUserTypeValid(item.id);
              // console.log("user type value---", item.user_type);
            }}
            renderItem={renderUserTypeListItems}
          />
        </View>
        <View style={isMobile ? { flex: 1, marginLeft: 50 } : { flex: 1, margin: 10 }}>
          <TouchableOpacity
            style={styles.addAccess}
            onPress={() => getAccessMasterDetails(UserTypeValid)}
          >
            <Text style={styles.btnText}>Give Access</Text>
          </TouchableOpacity>
        </View>
      </View>
      {shouldShow ? null : (
        // <ScrollView
        //   style={[styles.card, styles.shadowProp]}
        //   contentContainerStyle={{ flexGrow: 1 }}
        // >
        <View style={[styles.card, { flexGrow: 1 }]}>
          <View
            style={[
              styles.shadowProp,
              {
                backgroundColor: "white",
                borderRadius: 8,
                paddingVertical: 45,
                paddingHorizontal: 25,
              },
            ]}
          >
            <View
              style={{
                width: "100%",
                // flex: 1,
                //backgroundColor: "#F6F9F9",
                borderRadius: 8,
                // paddingVertical: 45,
                // paddingHorizontal: 100,
              }}
            >
              <View
                style={{
                  borderWidth: 1,
                  borderColor: "#e3e0e0",
                  borderRadius: 2,
                }}
              >
                <PaperProvider>
                  <DataTable>
                    <DataTable.Header>
                      <DataTable.Title style={{ flex: 1 }}>
                        Menu Name
                      </DataTable.Title>
                      <DataTable.Title>All</DataTable.Title>

                      <DataTable.Title>Create</DataTable.Title>
                      <DataTable.Title>List</DataTable.Title>

                      <DataTable.Title>View</DataTable.Title>
                    </DataTable.Header>

                    {/* <CollapseHeader>
                      <View style={{ flexDirection: "row" }}>
                        <DataTable.Cell>
                          <Text
                            style={{
                              margin: 10,
                              fontSize: 18,
                              fontWeight: "500",
                            }}
                          >
                            {MenuNameData}
                          </Text>
                        </DataTable.Cell>

                        <DataTable.Cell>
                          <TouchableOpacity onPress={() => setIconValue()}>
                            <IconCheck name={iconTick} size={30} />
                          </TouchableOpacity>
                        </DataTable.Cell>

                        <DataTable.Cell>
                          <TouchableOpacity onPress={() => setIconValue()}>
                            <IconCheck name={iconTick} size={30} />
                          </TouchableOpacity>
                        </DataTable.Cell>

                        <DataTable.Cell>
                          <TouchableOpacity onPress={() => setIconValue()}>
                            <IconCheck name={iconTick} size={30} />
                          </TouchableOpacity>
                        </DataTable.Cell>
                        <DataTable.Cell>
                          <TouchableOpacity onPress={() => setIconMenu()}>
                            <IconCheck name={iconTick} size={30} />
                          </TouchableOpacity>
                        </DataTable.Cell>
                        <DataTable.Cell>
                          <TouchableOpacity>
                            <IconCheck name="chevron-down" size={30} />
                          </TouchableOpacity>
                        </DataTable.Cell>
                      </View>
                    </CollapseHeader> */}

                    {/* <View style={{ flexDirection: "row" }}> */}
                    {/* {MenuNameData.map(renderMenuList)} */}
                    {MenuNameData.map((item, index) => (
                      <Collapse>
                        <CollapseHeader>
                          {renderMenuList(item, index)}
                        </CollapseHeader>
                        <CollapseBody>
                          {item.childMenu.map((item, index) =>
                            renderMasterItem(item, index)
                          )}
                        </CollapseBody>
                      </Collapse>
                    ))}

                    {/* </View> */}
                  </DataTable>
                </PaperProvider>
              </View>
            </View>

            <View
              style={{
                flex: 1,
                width: "100%",
                alignItems: "center",
                marginTop: 10,
              }}
            >
              <TouchableOpacity
                style={styles.saveBtn}
                onPress={() => saveAccessData()}
              >
                <Text style={styles.btnText}>Save</Text>
              </TouchableOpacity>
            </View>

            {/* </ScrollView> */}
          </View>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    // marginTop: 350,
    height: "100%",
  },
  containeralt: {
    // alignItems: "center",
    backgroundColor: "white",
    borderColor: "#eee",
    borderRadius: 10,
    borderWidth: 1,
    // justifyContent: "center",
    height: 300,
    margin: "auto",
    padding: 30,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    // marginRight: 10,
    // marginRight: 1,
    width: "30%",
  },
  input: {
    borderColor: "gray",
    width: "100%",
    borderWidth: 1,
    borderRadius: 2,

    padding: 10,
    marginTop: 20,
    marginBottom: 20,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  addAccess: {
    alignItems: "center",
    backgroundColor: "#006EB9",
    padding: 10,
    borderWidth: 1,
    borderColor: "#006EB9",
    width: "70%",
    // marginTop: 20,
  },

  saveBtn: {
    alignItems: "center",
    backgroundColor: "#006EB9",
    padding: 10,
    borderWidth: 1,
    borderColor: "#006EB9",
    width: "20%",
    marginRight: 10,
  },
  btnText: {
    color: "#fff",
    fontWeight: "600",
  },
  card: {
    width: "100%",
    flex: 1,
    marginVertical: 10,
  },
  accessCard: {
    width: "100%",
    flex: 1,
    marginVertical: 10,
  },
  shadowProp: {
    shadowColor: "#171717",
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 10,
  },
  // DropDown CSS
  dropdown: {
    borderColor: "#c3c3c3",
    width: "30%",
    borderWidth: 1,
    borderRadius: 2,
    height: 40,

    padding: 10,
    marginTop: 5,
    marginBottom: 5,
    // borderRadius: 10,
    // padding: 12,
    // shadowColor: "#000",
    // shadowOffset: {
    //   width: 0,
    //   height: 1,
    // },
    // shadowOpacity: 0.2,
    // shadowRadius: 1.41,

    // elevation: 2,
  },
  icon: {
    marginRight: 5,
  },
  item: {
    padding: 9,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  textItem: {
    flex: 1,
    fontSize: 16,
  },
  placeholderStyle: {
    fontSize: 16,
  },
  selectedTextStyle: {
    fontSize: 16,
  },
  iconStyle: {
    width: 20,
    height: 20,
  },
  inputSearchStyle: {
    height: 40,
    fontSize: 16,
    outlineStyle: "none",
  },
  // Error message css
  error_text: {
    color: "red",
    fontSize: 14,
    fontWeight: "600",
    width: "70%",
    marginLeft: 0,
    marginBottom: 10,
  },

  link: {
    marginTop: 15,
    paddingVertical: 15,
  },
  linkText: {
    fontSize: 14,
    color: "#2e78b7",
  },
  lableText: {
    color: "#484848",
    fontWeight: "700",
    marginTop: 18,
    // fontSize: 21,
  },
});
